import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const ProfileLinks = () => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const redirectTo = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-next-page')
    history.push({
      pathname: `${ProviderLinks.accountprofile}/${previousPage}`,
    })
  }

  return (
    <Fragment>
      <Container>
        <Container className="my-account-wrap">
          <Row>
            {!authContext.user.accountInfo.subRoleId ? (
              <div className="col-12">
                <div className="my-acc-info-box">
                  <h3>Profile Information</h3>
                  <ul className="account-info-list">
                    <li
                      data-next-page="profile-name"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Name</a>
                    </li>
                    <li
                      data-next-page="profile-birthday"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Birthday</a>
                    </li>
                    <li
                      data-next-page="profile-gender"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Sex</a>
                    </li>
                  </ul>
                </div>
                <div className="my-acc-info-box">
                  <h3>Contact Information</h3>
                  <ul className="account-info-list">
                    <li
                      data-next-page="profile-phone"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Phone #</a>
                    </li>
                    <li
                      data-next-page="profile-email"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Email Address</a>
                    </li>
                  </ul>
                </div>
                <div className="my-acc-info-box">
                  <h3>Subscriptions / Payment Information</h3>
                  <ul className="account-info-list">
                    <li
                      data-next-page="manage-subscription"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Manage Subscription</a>
                    </li>
                    <li
                      data-next-page="card-list"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Manage Your Credit Card</a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className="my-acc-info-box">
                  <h3>Profile Information</h3>
                  <ul className="account-info-list">
                    <li
                      data-next-page="profile-name"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Name</a>
                    </li>
                  </ul>
                </div>
                <div className="my-acc-info-box">
                  <h3>Contact Information</h3>
                  <ul className="account-info-list">
                    <li
                      data-next-page="profile-email"
                      onClick={(e) => {
                        redirectTo(e)
                      }}
                    >
                      <a href="\#">Email Address</a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default ProfileLinks
