import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Stars from '../../global/ratingreview/stars'

const claimProfile = (props) => {
  return (
    <Fragment>
      <Container className="claim-profile-modal">
        <Row className="provider-login-wrap service-area-soon">
          <div className="service-area-wrapper">
            <h2>We Found an Existing Profile Using Your Information</h2>
            <p className="sub-heading">
              Select your profile from the below list
            </p>
            <div
              className="col-md-12 mt-4"
              style={{ padding: '0px', width: '100%' }}
            >
              <div className="doctor-description-wrap">
                <div id="doctor-img" className="doctor-img">
                  {/* ProviderLinks.profile + "/" + item.id */}
                  <Link to={'/#'}>
                    <img
                      src={props?.claimProfileData?.accountInfo?.profileImage}
                      alt="Doctor"
                      className="sponsored-docimg"
                    />
                  </Link>
                </div>
                <div className="doctor-desc">
                  <h5>
                    <Link to={'/#'}>
                      {' '}
                      <span>
                        {props.claimProfileData.accountInfo
                          ? props.claimProfileData?.accountInfo?.firstName +
                            ' ' +
                            props.claimProfileData?.accountInfo?.lastName
                          : '--'}
                      </span>
                    </Link>
                  </h5>
                  <span className="doc-designation">
                    {props.claimProfileData.accountInfo
                      ? props.claimProfileData?.medicalSpeciality
                          ?.providerMedicalSpecialitiesValue
                      : '--'}
                  </span>
                  <div className="endorsements">
                    <span>
                      {props.claimProfileData.accountInfo
                        ? props.claimProfileData?.endorsementCount
                        : '--'}{' '}
                      Doctor endorsements
                    </span>
                  </div>
                  <div className="rating-result">
                    <span className="rating">
                      <Stars
                        rating={
                          props.claimProfileData.ratingReviewsAvg
                            ? parseFloat(
                                props.claimProfileData.ratingReviewsAvg
                              ).toFixed(1)
                            : 0
                        }
                      />
                    </span>
                    <span style={{ marginLeft: '8px' }}>
                      {props.claimProfileData.ratingReviewsCount
                        ? props.claimProfileData.ratingReviewsCount
                        : 0}
                      Reviews
                    </span>
                  </div>
                  <div className="result-address-wrap">
                    <div className="address-left">
                      <span
                        className="map-icon"
                        style={{ paddingLeft: '30px' }}
                      >
                        <div className="map-address">
                          {props.claimProfileData.practiceInfo
                            ? props.claimProfileData.practiceInfo
                                .providerPracticeLocations
                              ? props.claimProfileData.practiceInfo
                                  .providerPracticeLocations[0].practiceAddress
                              : '--'
                            : '--'}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="doctor-claim">
                  <div className="next-prev-btn-pro">
                    <input
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        props.responseHandler('claimProfile')
                      }}
                      className="next-btn-pro full-width"
                      value="Claim this profile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="seprator"></div>
            <div className="dont-see">Don’t see your profile?</div>
            <div className="col-md-12 text-center submit-btn">
              <div className="endorse-link endorse-link-patient">
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault()
                    props.responseHandler('continue')
                  }}
                >
                  Continue With New Profile
                </a>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default claimProfile
