import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, memo, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Custommodal from '../../global/modal/modal'
import Global from '../../reactLayer/global'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Stars from '../../global/ratingreview/stars'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import RequestAppointmentModal from '../doctor-search-result/req-appontment-modal'
import SchedulingTime from '../scheduling/scheduling-time'
import SecondaryAddress from './secondary-address'
import {
  allowCallbackRequests,
  isAuthPatient,
  isSponsored,
  isTop,
  schedulingFeatureOrAppointment,
} from './utils'
import BookmarkDoctor from '../bookmarkDoctor/bookmarkDoctor'
import { isPatientFavorite, PatientFavorite } from '../patient-favorite'

const SponsoredDoc = (props) => {
  const {
    userCateType,
    startDate,
    browserAddress,
    isCareteam,
    activeIndex,
    setActiveIndex,
  } = props
  let mapRefs = React.useRef([])
  const authContext = useContext(AuthContext)
  const baseAddress = 'https://www.google.co.in/maps/dir/'
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [seeAllLocation, setSeeAllLocation] = useState(false)
  const modalList = ['reqAppointment']
  const [bookSlot, setbookSlot] = useState(false)
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const isPatient = () => userCateType === 'patient'
  const allowOnline = (item) => item?.providers?.allowOnline === 1
  const allowVirtualVisit = (item) => item?.providers?.hasVirtualVisit === 1
  const allowCallbacks = (item) => item?.providers?.allowedPatient === 1
  const planId = (item) => item?.providerPlanDetails?.planId

  const renderAppointment = (item, locationId) => (
    <div className="request-appoint">
      <input
        type="button"
        onClick={() =>
          isAuthPatient(authContext)
            ? openRequestModal('reqAppointment', { locationId }, item)
            : openLoginModal()
        }
        className="next-btn-pro"
        value="Request Appointment"
      />
    </div>
  )

  const [selectedItem, setselectedItem] = useState(null)

  const openLoginModal = (e) => {
    e && e.preventDefault()
    authContext.showLoginModal({
      modalName: 'login',
      redirectUrl: PatientLinks.doctor_search_result,
    })
  }
  const openRequestModal = (modalName, slot = false, item = null) => {
    setselectedItem(item)
    setbookSlot(slot)
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const bookAppointment = (slot, item = null) => {
    if (isAuthPatient(authContext)) {
      openRequestModal('reqAppointment', slot, item)
    } else {
      openLoginModal()
    }
  }
  const handleLocation = (e) => {
    e.preventDefault()
    setSeeAllLocation(!seeAllLocation)
  }

  const doaskenDorseDoctor = async (drId, type = 'ask') => {
    authContext.setLoader()
    let data = { toUser: drId }
    let url = 'endorsement/ask'
    if (type === 'add') {
      url = 'endorsement/add'
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      props.getSearchResult && props.getSearchResult()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      props.getSearchResult && props.getSearchResult()
    }
    authContext.setHeaderSearchText({
      text: authContext?.headerSearchText?.text,
      actionSubmit: true,
    })
  }

  const requstCallback = async (e, userId) => {
    e.preventDefault()
    authContext.setLoader()
    let data = { userId: userId }
    let url = 'patients/request-callback/submit'

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    // authContext.unsetLoader();
    authContext.unsetLoader()
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      props.getSearchResult && props.getSearchResult()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      props.getSearchResult && props.getSearchResult()
    }
  }
  const askenDorseDoctor = (e, drId) => {
    e && e.preventDefault()
    doaskenDorseDoctor(drId, 'ask')
  }

  const enDorseDoctor = (e, drId) => {
    e && e.preventDefault()
    doaskenDorseDoctor(drId, 'add')
  }

  const renderSchedulingTime = (date, item, locationId) => {
    return (
      <div key={locationId + '-WrapperMain'} className="scheduling-box">
        <SchedulingTime
          key={locationId}
          locationId={locationId}
          item={item}
          startDate={date}
          bookAppointment={bookAppointment}
          isCareteam={isCareteam}
        />
      </div>
    )
  }

  useEffect(() => {
    if (activeIndex !== null) {
      const element = document.getElementsByClassName('search-result-border')
      element[0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [activeIndex])

  useEffect(() => {
    setActiveIndex && setActiveIndex(null)
  }, [props.items])

  const renderComponent = () => {
    return (
      <Fragment>
        <Row>
          {authContext.responseModal && !tabvisibleComponent ? (
            <Msgmodal {...modalProps} />
          ) : (
            <></>
          )}
        </Row>
        {modalList.indexOf(tabvisibleComponent) > '-1' ? (
          <Custommodal
            unsetComponent={settabvisibleComponent}
            componentName={
              tabvisibleComponent === 'reqAppointment' && selectedItem ? (
                <RequestAppointmentModal
                  {...selectedItem}
                  bookSlot={bookSlot}
                  browserAddress={props.browserAddress}
                />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <></>
        )}
        <div className="sponsor-doc-wrapper">
          {props?.items?.map((item, itemIndex) => {
            const providers = item?.providers
            return (
              <>
                <div
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  className="col-12 sponsor-doctor-list"
                >
                  <div
                    style={{
                      textTransform: 'capitalize',
                      marginRight: '20px',
                    }}
                    className={`search-result-box ${
                      isSponsored(item) && 'sponsor-border'
                    } ${isTop(item) && 'top-doc-border'} ${
                      itemIndex === activeIndex && 'search-result-border'
                    }`}
                  >
                    {isSponsored(item) || isTop(item) ? (
                      <>
                        <span
                          className={`${
                            isSponsored(item) ? 'sponsor' : 'top'
                          }-doc-icon`}
                        >
                          {isSponsored(item) ? 'Sponsored' : 'Top'} Doctor
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* doctor profile top */}
                    <div className="justify-content-between">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent:
                            props.bookmarkPosition === 'left'
                              ? 'flex-start'
                              : 'flex-end',
                          margin: '12px 12px 12px 0',
                        }}
                      >
                        {isPatientFavorite(
                          item.totalReviewAvg,
                          item.totalReviewCount
                        ) && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '-22px',
                              left: '20px',
                            }}
                          >
                            <PatientFavorite size="large" />
                          </div>
                        )}
                        <BookmarkDoctor
                          id={item.id}
                          isSaved={item.isSavedDoctor}
                        />
                      </div>
                      <div
                        style={{ display: 'flex' }}
                        className="order-1 order-lg-1 profile-mid-border"
                      >
                        <div
                          style={{ width: '90%' }}
                          className="doctor-description-wrap"
                        >
                          <div className="doctor-img">
                            <Link to={ProviderLinks.profile + '/' + item.id}>
                              <img
                                src={
                                  item?.profileImage ||
                                  item?.users?.profileImage
                                }
                                alt="Doctor"
                                className="sponsored-docimg"
                              />
                            </Link>
                          </div>
                          <div className="doctor-description-right">
                            <h5>
                              <Link to={ProviderLinks.profile + '/' + item.id}>
                                {' '}
                                <span>
                                  {providers?.firstName || ''}&nbsp;
                                  {providers?.middleName && (
                                    <React.Fragment>
                                      {providers.middleName}&nbsp;
                                    </React.Fragment>
                                  )}
                                  {providers?.lastName || ''}
                                  {item.providerMedicalCredientialsValue
                                    ? ', ' +
                                      item.providerMedicalCredientialsValue
                                    : ''}
                                </span>
                              </Link>
                            </h5>
                            <span className="doc-designation">
                              {item.providerMedicalSpecialitiesValue
                                ? item.providerMedicalSpecialitiesValue
                                : ''}
                            </span>
                            <div className="endorsements">
                              <span>
                                {item?.totalEndorsementCount
                                  ? item.totalEndorsementCount
                                  : 0}{' '}
                                Doctor endorsements
                              </span>
                            </div>
                            <div className="rating-result">
                              <span className="rating">
                                <Stars
                                  rating={
                                    item.reviewStars
                                      ? parseFloat(item.reviewStars).toFixed(1)
                                      : 0
                                  }
                                  {...item}
                                  getProfile={props.getProfile}
                                  componentName={
                                    props.isCareteam
                                      ? 'sponsored_doctor'
                                      : 'searchResult'
                                  }
                                />
                              </span>
                            </div>
                            <span>
                              (
                              {item.totalReviewAvg
                                ? parseFloat(item.totalReviewAvg).toFixed(1)
                                : 0}
                              ){' '}
                              {item && item.totalReviewCount
                                ? item.totalReviewCount
                                : 0}{' '}
                              Reviews
                            </span>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', borderBottom: 'none' }}
                          className="col-lg-6 order-3 order-lg-2 profile-mid-border px-0"
                        >
                          <div className="patient-features">
                            <em>
                              Patient features unavailable.
                              <br />
                              Please sign in as a patient if you would like to
                              book an appointment.
                            </em>
                          </div>
                          {/* health dashboard, search */}
                          {isPatient() &&
                            schedulingFeatureOrAppointment(
                              allowOnline(item),
                              allowVirtualVisit(item),
                              allowCallbacks(item),
                              planId(item),
                              () =>
                                renderAppointment(
                                  item,
                                  item?.providerPracticeLocations[0]?.id
                                ),
                              () =>
                                renderSchedulingTime(
                                  startDate,
                                  item,
                                  item?.providerPracticeLocations[0]?.id
                                )
                            )}
                        </div>
                      </div>
                      <div className="doc-actions">
                        <div
                          style={{
                            width: isCareteam ? '41%' : 'unset',
                          }}
                          className="order-2 order-lg-3 profile-mid-border-add"
                        >
                          <div className="result-address-wrap">
                            <div className="address-left">
                              <span
                                className={
                                  item?.providers?.isAcceptingNewPatient ||
                                  item?.isAcceptingNewPatient
                                    ? 'tick-icon'
                                    : 'red-cross-icon'
                                }
                              >
                                Accepting new patients
                              </span>
                              <span
                                className={
                                  item?.providers?.hasVirtualVisit ||
                                  item?.hasVirtualVisit
                                    ? 'video-icon'
                                    : 'grey-cross-icon'
                                }
                              >
                                Virtual visits available
                              </span>
                              {item?.providerPracticeLocations?.length > 1 ? (
                                <>
                                  <div className="see-location-link">
                                    <a href="/#" onClick={handleLocation}>
                                      {seeAllLocation ? 'Close' : 'See'} All
                                      Locations
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 order-4 order-lg-4">
                          <div className="endorse-link">
                            <button
                              onClick={(e) =>
                                enDorseDoctor(e, props.accountInfo.id)
                              }
                              disabled={item.isEndorsed}
                            >
                              <span>
                                {item.isEndorsed
                                  ? 'Endorsed'
                                  : 'Endorse Doctor'}
                              </span>
                            </button>
                            <button
                              onClick={(e) =>
                                askenDorseDoctor(e, props.accountInfo.id)
                              }
                            >
                              Ask Doctor for Endorsement
                            </button>
                          </div>
                          <div className="endorse-link endorse-link-patient">
                            {item.providers.phoneNumber && (
                              <a href={`tel:${item.providers.phoneNumber}`}>
                                {Global.phoneFormator(
                                  item.providers.phoneNumber
                                )}
                              </a>
                            )}
                            {isPatient() &&
                              allowCallbackRequests(
                                planId(item),
                                allowCallbacks(item)
                              ) && (
                                <a
                                  href="/#"
                                  className="cursor-default"
                                  onClick={(e) => {
                                    if (item.isCallbackRequested)
                                      e.preventDefault()
                                    else if (
                                      authContext?.user?.isProfileCompleted
                                    )
                                      requstCallback(e, item.id)
                                    else openLoginModal(e)
                                  }}
                                >
                                  {!item.isCallbackRequested
                                    ? 'Request Callback'
                                    : 'Request Sent'}
                                </a>
                              )}
                            <Link
                              style={{
                                backgroundColor: '#077db4',
                                color: '#fff',
                              }}
                              to={{
                                pathname: PatientLinks.jot_form,
                                search: `?requestedProvider[first]=${providers?.firstName}&requestedProvider[last]=${providers?.lastName}`,
                              }}
                            >
                              Text me to schedule
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {item?.providerPracticeLocations?.length > 1 &&
                      seeAllLocation &&
                      item.providerPracticeLocations.map(
                        (pa, pai) =>
                          pai !== 0 && (
                            <SecondaryAddress
                              bookAppointment={bookAppointment}
                              item={item}
                              address={pa}
                              ref={mapRefs.current[pai]}
                              key={pa.id.toString()}
                              startDate={startDate}
                              browserAddress={browserAddress}
                              baseAddress={baseAddress}
                              scheduling={
                                isPatient() &&
                                schedulingFeatureOrAppointment(
                                  allowOnline(item),
                                  allowVirtualVisit(item),
                                  allowCallbacks(item),
                                  planId(item),
                                  () => renderAppointment(item, pa.id),
                                  () =>
                                    renderSchedulingTime(startDate, item, pa.id)
                                )
                              }
                            />
                          )
                      )}
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </Fragment>
    )
  }
  useEffect(() => {
    renderComponent()
  }, [props])

  return renderComponent()
}

SponsoredDoc.defaultProps = {
  userCateType: '',
  startDate: '',
  items: null,
  browserAddress: null,
}

export default memo(SponsoredDoc)
