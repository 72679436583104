import { Box, Flex, Spinner } from '@chakra-ui/react'
import { statusOptions } from '../../../lib/utils'
import { Typography } from '../../../style/typography'

interface Props {
  providersSuggestions: any
  status: number
  onUpdate: (value: string) => void
}

export const SuggestionsList: React.FC<Props> = ({
  providersSuggestions,
  status,
  onUpdate,
}) => {
  const items = []
  if (providersSuggestions) {
    const { specialityData, conditionData, treatmentData, doctorData } =
      providersSuggestions

    specialityData?.length &&
      items.push({ data: specialityData, title: 'SPECIALTY' })
    conditionData?.length &&
      items.push({ data: conditionData, title: 'CONDITION' })
    treatmentData?.length &&
      items.push({ data: treatmentData, title: 'TREATMENT' })
    doctorData?.length && items.push({ data: doctorData, title: 'DOCTOR' })
  }

  return (
    <>
      {status === statusOptions.loading && (
        <Box>
          <Spinner color="primary" size="sm" />
        </Box>
      )}
      {status === statusOptions.error && (
        <Box>
          <p className="error">
            Please check your spelling or try different keywords.
          </p>
        </Box>
      )}
      {items?.map(({ data, title }, index) => (
        <Flex
          key={`${title} ${index}`}
          direction="column"
          mb={4}
          borderBottom="1px solid"
          borderColor="border-gray"
        >
          <Typography fontSize="16px">{title}</Typography>
          <Flex direction="column" gap={2} pl={3}>
            {data.map((item) => (
              <Typography
                key={item.data}
                onClick={() => onUpdate(item.data)}
                fontSize="14px"
                _hover={{ color: 'primary' }}
                cursor="pointer"
                pb={3}
                pt={2}
              >
                {item.data}
              </Typography>
            ))}
          </Flex>
        </Flex>
      ))}
    </>
  )
}
