import React, { Fragment, Suspense } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

const CreateAccountStep = (props) => {
  const step = props.step ? props.step : '1'
  const list = [
    'Account Information',
    'Demographic Information',
    'Insurance Information',
  ]
  const suspenseLoader = () => <p></p>
  return (
    <Fragment>
      <Suspense fallback={suspenseLoader()}>
        <Container>
          <Row>
            <div className="col">
              <h1 className="text-center mt-md-5 mt-3">Create Account</h1>
            </div>
          </Row>
        </Container>
        <Container>
          <Row className="no-gutters">
            <div className="col">
              <div className="stepper-wrap">
                <ul className="steps">
                  {list.map((d, i) => (
                    <li
                      key={uuidv4()}
                      className={
                        step > i + 1
                          ? 'step is-complete is-active'
                          : step == i + 1
                          ? 'step is-active'
                          : 'step'
                      }
                      data-step={i + 1}
                    >
                      {d}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </Suspense>
    </Fragment>
  )
}

export default CreateAccountStep
