import { Helmet } from 'react-helmet'

export const Seo = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>DocScheduler | Book Doctor Appointments Online </title>
      <meta
        name="description"
        content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
      />
      <link
        rel="canonical"
        href={`https://www.docscheduler.com/patient/doctor-search-result`}
      />
      <meta property="og:site_name" content="DocScheduler" />
      <meta property="og:site" content="www.docscheduler.com" />
      <meta
        property="og:url"
        content={`https://www.docscheduler.com/patient/doctor-search-result`}
      />
      <meta name="twitter:card" content="Dashboard" />
      <meta name="twitter:site" content="@DocScheduler" />
      <meta
        property="og:title"
        content={`DocScheduler | Book Doctor Appointments Online`}
      />
      <meta
        name="twitter:title"
        content={`DocScheduler | Book Doctor Appointments Online`}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://www.docscheduler.com/assets/images/logo-icon.svg"
      />
      <meta
        name="twitter:image"
        content="https://www.docscheduler.com/assets/images/logo-icon.svg"
      />
      <meta
        property="og:image:secure_url"
        content="https://www.docscheduler.com/assets/images/logo-icon.svg"
      />
      <meta
        property="og:description"
        content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
      />
      <meta
        name="twitter:description"
        content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
      />
    </Helmet>
  )
}
