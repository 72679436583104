import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Receipt from '../../components/receipt/receipt'
import HomeHeader from '../../global/header/header'

const PaymentReceipt = (props) => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href)
    window.onpopstate = function () {
      window.history.go(1)
    }
  }, [])
  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader />
      </Container>

      <Container fluid className="">
        <Receipt {...props} />
      </Container>
    </Fragment>
  )
}

export default PaymentReceipt
