import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import ProviderStep from './provider-steps'

const ProviderStep4 = (props) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const currentRunningPlan = authContext.user.providerCurrentPlan
    ? authContext.user.providerCurrentPlan
    : null
  const [plans, setPlans] = useState([])
  const [services, setServices] = useState([])
  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const FREE_TRIAL = {
    on: true,
    off: false,
  }

  const title = props.title ? props.title : 'Select a Plan'

  const getServicesPlan = async () => {
    let result = await NetworkLayer.getRequest({ url: 'providers/plans/get' })
    if (result.status === true) {
      setPlans(result.result.plans)
      setServices(result.result.allservices)
    }
    return result
  }

  const selectPlan = async (e, planId, freeTrial) => {
    window.localStorage.setItem('freeTrial', freeTrial)
    e.preventDefault()
    let selectedPlan = null

    if (planId) {
      plans.filter((plan) => {
        if (planId == plan.id) {
          selectedPlan = plan
        }
      })
    }

    if (selectedPlan) {
      const fData = {
        planId: planId,
        freeTrial: freeTrial,
      }

      if (selectedPlan.subPlans && Object.keys(selectedPlan.subPlans).length) {
        let redirecturl = ProviderLinks.register_step5 + planId
        if (authContext.user.isProfileCompleted) {
          redirecturl =
            ProviderLinks.accountprofile + '/upgrade-subscription/' + planId
        }

        history.push({
          pathname: redirecturl,
        })
      } else {
        authContext.setLoader()
        let url = 'providers/signup/plan/save'
        if (authContext.user.isProfileCompleted) {
          url = 'providers/plan/update'
        }
        const submitData = {
          headers: {
            'Content-Type': 'application/json',
            'access-token': authContext.token,
          },
          url: url,
          body: JSON.stringify(fData),
        }

        let result = await NetworkLayer.postData(submitData)
        authContext.unsetLoader()
        if (result.body.status) {
          let user = result.body.result
          authContext.setUser(user)
          if (authContext.user.isProfileCompleted) {
            setModalProps({
              type: 'success',
              msg: result.body.message,
              closeredirection: ProviderLinks.accountprofile,
            })
          } else {
            setModalProps({
              type: 'success',
              msg: result.body.message,
              redirection: ProviderLinks.twofactauth,
              closeredirection: ProviderLinks.twofactauth,
            })
          }
          authContext.showResponseModal()
        } else {
          setModalProps({
            type: 'error',
            msg: result.body.message,
          })
          authContext.showResponseModal()
        }
      }
    }
  }

  const confirmCancelSub = async () => {
    authContext.setLoader()
    const fData = {}
    let url = 'providers/plan/cancel'

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(fData),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.status) {
      authContext.setUser(result.body.result)
      let redirecturl = ProviderLinks.subscription_canceled
      history.push({
        pathname: redirecturl,
      })

      authContext.unsetLoader()
      authContext.showResponseModal()
      setTimeout(() => {
        history.push({
          pathname: ProviderLinks.subscription_canceled,
        })
      }, 1000)
    } else {
      setModalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.unsetLoader()
      authContext.showResponseModal()
    }
  }
  const cancelSubscription = (e) => {
    e.preventDefault()
    setModalProps({
      autoclose: 'false',
      type: 'question',
      btncancel: 'Keep Subscription',
      msg: 'Are you sure you want to cancel your subscription?',
      btnname: 'Cancel Subscription',
      action: confirmCancelSub,
    })
    authContext.showResponseModal()
  }

  useEffect(() => {
    getServicesPlan()
    return () => {
      authContext.hideResponseModal()
    }
  }, [])

  return (
    <Fragment>
      <Container fluid className="provider-steps-wrap">
        <ProviderStep step="4" />
      </Container>
      <Container className="provider-step4-inner">
        <Row className="no-gutters">
          <div className="col-12">
            {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
            <div className="plan-outer">
              <div className="plan-left">
                <div className="plan-left-top">
                  <h2>{title}</h2>
                  <div className="pro-service hide-pro-serve">
                    Products &amp; Services
                  </div>
                </div>
                {services.map((service) => (
                  <div
                    className="plan-left-bottom info-hg"
                    key={service.name + '-wrapper'}
                  >
                    <div className="info-icon" />
                    {service.name}
                    {service.info ? (
                      <>
                        <div className="info-callout left-right">
                          {' '}
                          {service.info}{' '}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
              <div className="plan-right">
                <div className="plan-right-inner">
                  {plans.map((plan, plan_i) => (
                    <div key={plan.planName + '-container-' + plan_i}>
                      <div
                        className={
                          currentRunningPlan &&
                          currentRunningPlan.planId == plan.id
                            ? 'active upgrade'
                            : 'plan-container'
                        }
                      >
                        <div className="plan-right-top">
                          <h2>
                            {plan.planName}
                            <span>
                              $
                              {plan.subPlans && plan.subPlans.Monthly
                                ? plan.subPlans.Monthly.price
                                : 0}
                              /{'Month'}
                            </span>
                          </h2>

                          {currentRunningPlan && currentRunningPlan.planId ? (
                            <>
                              {currentRunningPlan.planId > plan.id ? (
                                <>
                                  <div
                                    className="try-box active"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      selectPlan(e, plan.id, FREE_TRIAL.off)
                                    }}
                                  >
                                    Change Plan
                                  </div>
                                  <div className="purchase-now">&nbsp;</div>
                                </>
                              ) : currentRunningPlan.planId == plan.id ? (
                                <>
                                  <div className="current-box">
                                    Current Plan
                                  </div>
                                  <div className="purchase-now">&nbsp;</div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="try-box active"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      selectPlan(e, plan.id, FREE_TRIAL.off)
                                    }}
                                  >
                                    Upgrade Plan
                                  </div>
                                  <div className="purchase-now">&nbsp;</div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {Object.keys(plan.subPlans).length ? (
                                <>
                                  <div
                                    onClick={(e) => {
                                      selectPlan(e, plan.id, FREE_TRIAL.on)
                                    }}
                                    className="try-box1"
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Try free for 60 days
                                  </div>
                                  <div className="purchase-now">
                                    or{' '}
                                    <span
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        selectPlan(e, plan.id, FREE_TRIAL.off)
                                      }}
                                    >
                                      purchase now
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    className="try-box"
                                    onClick={(e) => {
                                      selectPlan(e, plan.id, FREE_TRIAL.off)
                                    }}
                                  >
                                    Get started
                                  </div>
                                  <div className="purchase-now">&nbsp;</div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {services.map((service, service_i) => (
                          <div
                            className="plan-right-bottom"
                            key={plan.planName + '-' + service.name}
                          >
                            <div className="left-text-device info-hg">
                              <div className="info-icon" />
                              {service.name}
                              {service.info ? (
                                <>
                                  <div className="info-callout left-right">
                                    {' '}
                                    {service.info}{' '}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            {plan.services.indexOf(service.id) > '-1' ? (
                              <div className="right-icon"></div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                      {currentRunningPlan &&
                      currentRunningPlan.planId === plan.id &&
                      Object.keys(plan.subPlans).length > 0 ? (
                        <>
                          <div className="cancel-link">
                            <a onClick={(e) => cancelSubscription(e)} href="/#">
                              Cancel Subscription
                            </a>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ProviderStep4
