import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import PatientTabs from '../../components/admin/patient/patient-tabs'
import DigitalReputation from '../../components/admin/provider/digital-reputation'
import ManagementAppointment from '../../components/admin/provider/management-appointment'
import MedicalSpecialty from '../../components/admin/provider/medical-specialty'
import PersonalInformation from '../../components/admin/provider/personal-information'
import PracticeInformation from '../../components/admin/provider/practice-information'
import ProEndorsement from '../../components/admin/provider/provider-endorsement'
import Loadermodal from '../../global/loader-modal/loader-modal'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const ProviderDetail = (props) => {
  const authContext = useContext(AuthContext)
  const [visibleComponent, setvisibleComponent] = useState('personalInfo')
  const [providerDetail, setProviderDetails] = useState(null)

  let history = useHistory()

  const tabName = [
    { id: 'personalInfo', name: 'Personal Information' },
    { id: 'medSpe', name: 'Medical Specialty' },
    { id: 'practiceInfo', name: 'Practice Information' },
    { id: 'appointHistory', name: 'Appointment History' },
    { id: 'digitalRepo', name: 'Digital Reputation' },
    { id: 'endorse', name: 'Endorsement' },
  ]

  const getDetails = async () => {
    authContext.setLoader()
    let id = props.match.params.userID
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/view/' + id,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)
    setProviderDetails(result?.result)
    // authContext.setProviderDetails(result ?.result);
    authContext.unsetLoader()
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Row className="bg-white-report patient-details-outer">
            <div className="table-report">
              <div className="heading-wrap">
                <div className="table-heading">Edit Provider Detail</div>
              </div>
              <div className="col-12 mt-3">
                <Link to="#" className="back-link" onClick={goBack}>
                  <span className="back-icon">
                    <BackIcon />
                  </span>
                  Back
                </Link>
              </div>
              <PatientTabs
                visibleComponent={visibleComponent}
                setvisibleComponent={setvisibleComponent}
                tabName={tabName}
              />

              {visibleComponent === 'personalInfo' ? (
                <>
                  <PersonalInformation
                    data={providerDetail}
                    providerDetails={providerDetail}
                    callBack={getDetails}
                  />{' '}
                </>
              ) : visibleComponent === 'medSpe' ? (
                <MedicalSpecialty
                  data={providerDetail}
                  providerDetails={providerDetail}
                  callBack={getDetails}
                />
              ) : visibleComponent === 'practiceInfo' ? (
                <PracticeInformation
                  data={providerDetail}
                  providerDetails={providerDetail}
                  callBack={getDetails}
                />
              ) : visibleComponent === 'appointHistory' ? (
                <ManagementAppointment
                  data={providerDetail}
                  providerDetails={providerDetail}
                  callBack={getDetails}
                />
              ) : visibleComponent === 'digitalRepo' ? (
                <DigitalReputation
                  data={providerDetail}
                  providerDetails={providerDetail}
                  callBack={getDetails}
                />
              ) : visibleComponent === 'endorse' ? (
                <ProEndorsement
                  data={providerDetail}
                  providerDetails={providerDetail}
                  callBack={getDetails}
                />
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default ProviderDetail
