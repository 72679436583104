import { Box, Flex } from '@chakra-ui/react'
import { ProviderLinks } from '../../lib/links'
import { Typography } from '../../style/typography'
import { Link } from 'react-router-dom'
import { HeaderActions } from './Header/HeaderActions'

interface Props {
  mobileMenuVisible?: boolean
}

export const MobileMenu: React.FC<Props> = ({ mobileMenuVisible }) => {
  return (
    <Flex
      width="100%"
      height="100%"
      position="fixed"
      top={0}
      right={0}
      left={0}
      bg="white"
      p={8}
      pt={24}
      zIndex={1000}
      justifyContent="flex-end"
      transition="transform 0.3s ease-in-out"
      display={mobileMenuVisible ? 'flex' : 'none'}
      transform={mobileMenuVisible ? 'translateX(0)' : 'translateX(100%)'}
    >
      <Flex direction="column" gap={4} alignItems="flex-end" width="100%">
        <Box mb={4} mt={4}>
          <Link to={ProviderLinks.freeprofile}>
            <Typography
              fontWeight="bold"
              fontSize="16px"
              color="primary"
              textDecoration="underline"
            >
              List your practice on DocScheduler
            </Typography>
          </Link>
        </Box>
        <HeaderActions
          direction="col"
          setVisibleComponent={() => {
            //
          }}
        />
      </Flex>
    </Flex>
  )
}
