import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import InputValidation from '../../reactLayer/validation'

const ForgotPassword = (props) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const emailErrorMsg = ['Email is required', 'Invalid Email']
  const [errors, setErrors] = useState({
    eemail: false,
  })

  const [loginData, setLoginData] = useState({
    email: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: emailErrorMsg,
      errorKey: 'eemail',
    },
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const formSubmit = async () => {
    const validationResult = InputValidation(loginData)
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })
    } else {
      authContext.setLoader()
      let data = {
        email: loginData.email.value,
      }
      let url = 'forgot-password'

      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        let tempData = loginData
        tempData.email.value = ''
        setLoginData({ ...tempData })
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }
  const dosubmit = (e) => {
    if (e.key === 'Enter') {
      formSubmit(e)
    }
  }

  return (
    <Fragment>
      <Container className="header px-0">
        <HeaderMain />
      </Container>
      <Container className="">
        <Row>
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        </Row>
        <Row className="provider-login-wrap">
          <div className="col-md-12 mb-3">
            <h1 className="mb-3">Forgot Password</h1>
            <div className="col-md-12 mb-3 provider-steps-wrap">
              <div className="medical-info body"></div>
            </div>
            <p className="small col-md-8 offset-md-2 ml-auto mr-auto pt-2">
              Enter your email address and we'll send you a link to reset your
              password.
            </p>
          </div>
          <div className="col-md-12">
            <input
              type="text"
              name="email"
              className={
                errors.eemail ? 'input-pro  error-border' : 'input-pro'
              }
              placeholder="Please enter your email address"
              autoComplete="off"
              onClick={() => setErrors({ ...errors, ...{ eemail: false } })}
              onFocus={() => setErrors({ ...errors, ...{ eemail: false } })}
              onKeyPress={(e) => dosubmit(e)}
              onChange={(e) =>
                setLoginData({
                  ...loginData,
                  ...{
                    email: {
                      value: e.currentTarget.value,
                      validation: ['blank', 'email'],
                      errorMsg: emailErrorMsg,
                      errorKey: 'eemail',
                    },
                  },
                })
              }
              value={loginData.email.value}
            />
            <small className="error-input">{errors.eemail}</small>
          </div>
          <div className="col-md-12 text-center submit-btn">
            <div className="next-prev-btn-pro">
              <input
                type="button"
                onClick={() => {
                  formSubmit()
                }}
                className="next-btn-pro full-width"
                value="Submit"
              />
            </div>
          </div>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default ForgotPassword
