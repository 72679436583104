import 'bootstrap/dist/css/bootstrap.css'
import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ModalProvidersSearch, {
  mapSearchOptionsToInputValue,
} from '../../components/doctor-search-result/modal-provider-search'
import useLocationMod from '../../hooks/useLocationMod'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const SearchMenuModal = ({ isOpen, setIsOpen }) => {
  const locationMod = useLocationMod()
  const authContext = useContext(AuthContext)
  const history = useHistory()

  const redirectToSearchResult = ({
    inputSuggestions,
    findInputValue,
    zip,
    city,
    searchInsuranceName,
    isChildren,
  }) => {
    const mappedValue = mapSearchOptionsToInputValue(
      inputSuggestions,
      findInputValue
    )

    const path =
      authContext.user &&
      authContext?.user?.isProfileCompleted &&
      authContext.user.accountInfo.roleId === 3
        ? ProviderLinks.search_result
        : PatientLinks.doctor_search_result

    const searchParams = new URLSearchParams()
    const params = {
      find: findInputValue,
      city,
      insurance: searchInsuranceName,
    }
    Object.keys(params).forEach((key) => searchParams.append(key, params[key]))

    const queryParams = searchParams.toString()

    history.push(path + '?' + queryParams)
    locationMod.location.state = {
      find: findInputValue,
      focusAreas: mappedValue.focusArea || '',
      zip,
      city,
      insurance_Name: searchInsuranceName,
      isChildren,
    }
  }

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-headerr">
              <Link className="header-logo" to="/">
                <div className="logo-main">
                  <img
                    src="/assets/images/logo-icon.svg"
                    className="logo-icon"
                    alt="DocScheduler"
                  />
                  <span>DocScheduler</span>
                </div>
              </Link>
              <button onClick={toggleModal}>X Close</button>
            </div>
            <div className="modal-body">
              <ModalProvidersSearch
                filter={{}}
                setFilter={() => null}
                hasExtendedFindInput
                hasInsuranceInput
                redirect={redirectToSearchResult}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchMenuModal
