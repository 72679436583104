interface Props {
  timeZone: string
  setShowModal: (value: boolean) => void
}

export const Modal: React.FC<Props> = ({ timeZone, setShowModal }) => {
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: '1000',
        left: 0,
        top: 0,
        background: 'rgba(0,0,0, 0.4)',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          borderRadius: '4px',
          background: '#fff',
          width: '600px',
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>Attention !</h2>
        <p>{`time slots are displayed in the ${timeZone} time zone`}</p>
        <button
          style={{
            background: '#077db4',
            color: '#fff',
            border: '1px solid #fff',
            borderRadius: '4px',
            padding: '16px 40px',
          }}
          onClick={() => setShowModal(false)}
        >
          Ok
        </button>
      </div>
    </div>
  )
}
