import { Component } from 'react'
import NetworkLayer from './Network/Network'

class GlobalFunctions extends Component {
  constructor() {
    super()
    this.languageList = [
      'English',
      'Chinese',
      'Vietnamese',
      'French',
      'German',
      'Haitian Creole',
      'Portuguese',
      'Spanish',
      'Tagalog',
      'Arabic',
      'Korean',
      'Russian',
      'Hindi',
      'Italian',
      'Polish',
      'Urdu',
      'Yiddish',
      'Japanese',
      'Persian',
      'Gujarati',
      'Telugu',
      'Irish',
      'Cambodian',
      'Bengali',
    ]
    this.currentYear = new Date().getFullYear()
    this.phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    this.cardRegex =
      /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})[-. ]?([0-9]{4,5})$/

    this.monthList = [
      { id: 1, name: 'January', days: 31 },
      { id: 2, name: 'February', days: this.currentYear % 4 === 0 ? 29 : 28 },
      { id: 3, name: 'March', days: 31 },
      { id: 4, name: 'April', days: 30 },
      { id: 5, name: 'May', days: 31 },
      { id: 6, name: 'June', days: 30 },
      { id: 7, name: 'July', days: 31 },
      { id: 8, name: 'August', days: 31 },
      { id: 9, name: 'September', days: 30 },
      { id: 10, name: 'October', days: 31 },
      { id: 11, name: 'November', days: 30 },
      { id: 12, name: 'December', days: 31 },
    ]
  }
  getLangList = async () => {
    let response = await NetworkLayer.getRequest({ url: 'languages/get' })
    if (response.status) {
      return response.result.map((each) => each.name)
    } else {
      return []
    }
  }

  convertTime12to24 = (time12h, type = 'timestamp') => {
    const [time, modifier] = time12h.split(' ')
    let [hours, minutes] = time.split(':')
    if (hours === '12') {
      hours = '00'
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }
    if (type === 'timestamp') {
      hours = parseInt(hours) * 60 * 60
      minutes = parseInt(minutes) * 60
      return hours + minutes
    } else {
      return `${hours}:${minutes}`
    }
  }
  getCurrentYear = () => {
    return this.monthList
  }
  getYearList = (number = 70) => {
    const startyear = new Date().getFullYear() - number
    const allyears = Array.from(
      new Array(number + 1),
      (val, index) => index + startyear
    )
    return allyears.reverse()
  }

  getDegreeStartYears = () => {
    const years = this.getYearList()
    return years.slice(2)
  }

  getYearListChild = (number = 26) => {
    const startyear = new Date().getFullYear() - number
    const allyears = Array.from(
      new Array(number + 1),
      (val, index) => index + startyear
    )
    return allyears.reverse()
  }

  getYearListAdult = (number = 70) => {
    const startyear = new Date().getFullYear() - number
    const allyears = Array.from(
      new Array(number - 17),
      (val, index) => index + startyear
    )
    return allyears
  }

  getArray = (fromnumber = 0, number = 10) => {
    const totalLength = number - fromnumber
    const allyears = Array.from(
      new Array(totalLength),
      (val, index) => index + fromnumber
    )
    return allyears
  }

  phoneFormator = (value) => {
    if (value) {
      // if(isNaN(value)){
      //   value = value.slice(0,value.length-1)
      // }
      if (value.length > 14) {
        value = value.slice(0, 14)
      }
      if (value) {
        value = value.replace(/\D+/g, '')
      }
      return value
        ? value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : value
    }
  }
  cardFormator = (value) => {
    return value.replace(this.cardRegex, '$1 $2 $3 $4')
  }
  cardFormatorReset = (value) => {
    return value.replace(this.cardRegex, '$1$2$3$4')
  }
  formatPrice = (value) => {
    return Number(value).toLocaleString('en-US')
  }

  addDays = (date = '', days = 0) => {
    let result
    if (date) {
      result = new Date(date)
    } else {
      result = new Date()
    }
    result.setDate(result.getDate() + days)
    return result
  }

  getGeoLocation = async (success, error) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error)
    }
  }

  getYearMonthDate = (date = new Date(), time = false) => {
    let result = {
      year: date.getFullYear(),
      month: (parseInt(date.getMonth()) + 1).toString().padStart(2, '0'),
      date: date.getDate().toString().padStart(2, '0'),
    }
    if (time) {
      result['hours'] = date.getHours()
      result['minutes'] = date.getMinutes()
      result['seconds'] = date.getSeconds()
    }
    return result
  }
  getDobString = (d) => {
    let date = new Date(d)
    let result =
      (parseInt(date.getMonth()) + 1).toString().padStart(2, '0') +
      '/' +
      date.getDate().toString().padStart(2, '0') +
      '/' +
      date.getFullYear()
    return result
  }
  getUSDate = (d) => {
    let options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    let today = new Date(d)
    return today.toLocaleDateString('en-US', options)
  }
  getStates = async () => {
    let response = await NetworkLayer.getRequest({ url: 'states/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getCities = async (stateId) => {
    let response = await NetworkLayer.getRequest({
      url: 'citiesv2/get?state_id=' + stateId,
    })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getInsurance = async (header = false) => {
    let url = 'insurance/get'
    let submitData = {}
    if (header) {
      submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': header,
        },
        url: url,
      }
    } else {
      submitData = {
        url: url,
      }
    }
    let response = await NetworkLayer.getRequest(submitData)
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getDegree = async () => {
    let response = await NetworkLayer.getRequest({ url: 'degree/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }
  getTraining = async () => {
    let response = await NetworkLayer.getRequest({ url: 'training-types/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getSpecialty = async () => {
    let response = await NetworkLayer.getRequest({ url: 'speciality/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getMedicalCredentials = async () => {
    let response = await NetworkLayer.getRequest({
      url: 'medical-credientials/get',
    })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getMedicalSpeciality = async () => {
    let response = await NetworkLayer.getRequest({
      url: 'medical-speciality/get',
    })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getMedicalFocusArea = async () => {
    let response = await NetworkLayer.getRequest({
      url: 'medical-focusarea/get',
    })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getProgrametypes = async () => {
    let response = await NetworkLayer.getRequest({ url: 'program-types/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getInstitues = async () => {
    let response = await NetworkLayer.getRequest({ url: 'institutes/get' })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  getPatientsTreatedList = async () => {
    let response = await NetworkLayer.getRequest({
      url: 'patients/treated/get',
    })
    if (response.status) {
      return response.result
    } else {
      return []
    }
  }

  render() {
    return ''
  }
}

const Global = new GlobalFunctions()
export default Global
