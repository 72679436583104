import { Questions } from './questions'
import { AddNewQuestion } from './add-new-question'

const renderFunc = (
  type,
  data,
  selectedQuestion,
  handleClick,
  onEditQuestion
) => {
  return (
    <Questions
      questions={data?.filter((d) => d.type === type)}
      selectedQuestion={selectedQuestion}
      onClick={handleClick}
      onEditQuestion={onEditQuestion}
    />
  )
}

const newQuestionFunc = (type, link, onClick) => {
  return (
    <AddNewQuestion
      description={`New ${link.name} question`}
      type={type}
      link={link}
      onClick={onClick}
    />
  )
}

const isActiveFunc = (link, state) => link.value === state[link.type]

export const config = [
  {
    description: 'Admin Messages',
    value: 'admin',
    name: 'Admin',
    type: 'link',
    isActive: isActiveFunc,
    renderQuestions: renderFunc,
    addNewQuestion: newQuestionFunc,
    subLinks: [],
  },
  {
    description: 'Responses and Messages',
    value: 'messages',
    name: 'Messages',
    type: 'link',
    isActive: isActiveFunc,
    renderQuestions: () => <></>,
    addNewQuestion: () => <></>,
    subLinks: [
      {
        description: 'Provider',
        value: 'provider',
        name: 'Provider',
        type: 'subLink',
        isActive: isActiveFunc,
        renderQuestions: renderFunc,
        addNewQuestion: newQuestionFunc,
      },
      {
        description: 'Patient',
        value: 'patient',
        name: 'Patient',
        type: 'subLink',
        isActive: isActiveFunc,
        renderQuestions: renderFunc,
        addNewQuestion: newQuestionFunc,
      },
    ],
  },
]
