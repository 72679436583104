import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const PatientHealthResult = () => {
  const [questionId, setQuestionId] = useState()
  const [itemToEdit, setItemToEdit] = useState(null)
  const [answerId, setAnswerId] = useState()
  const [data, setData] = useState([])
  const authContext = useContext(AuthContext)

  async function getHealthGrades() {
    authContext.setLoader()
    let submitData = {
      headers: {
        'access-token': authContext.token,
        'Content-Type': 'application/json',
      },
      url: 'patients/survey',
    }
    let result = await NetworkLayer.getRequest(submitData)
    setData(result.result)
    authContext.unsetLoader()
  }

  async function updateSurvey() {
    authContext.setLoader()
    const data = {
      questionId: questionId,
      answerId: answerId,
    }
    let submitData = {
      headers: {
        'access-token': authContext.token,
        'Content-Type': 'application/json',
      },
      url: 'patients/survey',
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.patchData(submitData)
    authContext.unsetLoader()
    if (result.body.code === 200) {
      setItemToEdit(null)
      setData(result.body.result)
    }
  }

  useEffect(() => {
    getHealthGrades()
  }, [])

  useEffect(() => {
    if (answerId) {
      updateSurvey()
    }
  }, [answerId])

  function convertToLocalTime(dateString) {
    const date = new Date(dateString)
    return date.toLocaleString()
  }

  return (
    <Fragment>
      <Container fluid className="policy-terms-wrap">
        <Row>
          <Container>
            <Row>
              <div className="col-12">
                <div className="health-grade-wrap">
                  <h2>Health Survey</h2>
                  <h4 className="mb-2">
                    Last updated on: {convertToLocalTime(data.lastUpdated)}{' '}
                  </h4>
                  <div className="health-grade-inner">
                    <label
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '36px',
                      }}
                      className="pro-label info-hg"
                    >
                      Social Determinants of Health (SDOH) 12 Question Survey
                      <div className="info-icon"></div>
                      <div
                        style={{ left: '72%', top: '83%' }}
                        className="info-callout top"
                      >
                        {' '}
                        DocScheduler sends your SDOH survey results to the
                        health care providers you book to help them provide a
                        more personalized care experience.
                      </div>
                    </label>
                    <Row>
                      <div>
                        {data?.questions?.map((item) => {
                          return (
                            <div style={{ width: '100%' }} key={item.id}>
                              <h3 className="mb-1 bold">{item.text}</h3>

                              {item.surveyAnswer === null ||
                              itemToEdit === item.id ? (
                                item.answers.map((answer) => {
                                  const isChecked =
                                    answerId === answer.id ||
                                    item.surveyAnswer === answer.id

                                  return (
                                    <div
                                      style={{
                                        padding: '10px 20px',
                                        border: '1px solid #000',
                                        borderRadius: '4px',
                                        width: '100%',
                                        marginBottom: '32px',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                      key={answer.id}
                                    >
                                      <input
                                        type="radio"
                                        name={item.category + item.id}
                                        value={answer.id}
                                        id={item.category + item.id}
                                        checked={isChecked}
                                        onChange={() => {
                                          setAnswerId(answer.id),
                                            setQuestionId(item.id)
                                        }}
                                      />
                                      <label
                                        style={{ marginLeft: '8px' }}
                                        htmlFor={item.category + item.id}
                                      >
                                        {answer.text}
                                      </label>
                                      {isChecked && (
                                        <div
                                          className="down-arrow-lg"
                                          style={{
                                            marginLeft: 'auto',
                                            transform: 'rotate(90deg)',
                                          }}
                                          onClick={() => setItemToEdit(null)}
                                        ></div>
                                      )}
                                    </div>
                                  )
                                })
                              ) : (
                                <div
                                  style={{
                                    padding: '10px 20px',
                                    border: '1px solid #000',
                                    borderRadius: '4px',
                                    width: '100%',
                                    marginBottom: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name={item.category + item.id}
                                    value={item.surveyAnswer}
                                    id={item.category + item.id}
                                    checked={true}
                                  />
                                  <label
                                    style={{ marginLeft: '8px' }}
                                    htmlFor={item.category + item.id}
                                  >
                                    {
                                      item.answers.find(
                                        (answer) =>
                                          answer.id === item.surveyAnswer
                                      ).text
                                    }
                                  </label>

                                  <span
                                    className="edit-button"
                                    onClick={() => setItemToEdit(item.id)}
                                    style={{
                                      position: 'absolute',
                                      right: '20px',
                                      top: '50%',
                                      transform: 'translate(-20px, -50%)',
                                      cursor: 'pointer',
                                    }}
                                  ></span>
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  )
}

export default PatientHealthResult
