import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import PolicyTwo from '../../components/privacy-terms/policy2'
import HeaderMain from '../../global/header/header-main'
import Footer from '../../global/footer/footer'
import { Helmet } from 'react-helmet'

const AcceptablePolicy = (props) => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Acceptable Policy</title>
        <meta name="description" content="Acceptable Policy" />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/acceptable-policy"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/acceptable-policy`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content={`DocScheduler | Acceptable Terms`} />
        <meta
          name="twitter:title"
          content={`DocScheduler | Acceptable Terms`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta property="og:description" content="Acceptable Policy" />
        <meta name="twitter:description" content="Acceptable Policy" />
      </Helmet>
      <HeaderMain {...props} />
      <PolicyTwo />
      <Footer />
    </Fragment>
  )
}

export default AcceptablePolicy
