import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as ProfileArrowIcon } from '../../admin/assets/images/profile_arrow.svg'
import { AdminLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const AdminHeader = () => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const toggleMenu = (e) => {
    e && e.preventDefault()
    authContext.toggleAdminMenu(authContext.adminMenuActive ? '' : 'activedash')
  }
  const [hidden, setHidden] = useState(true)

  const logout = () => {
    authContext.setAdminToken([])
    authContext.setAdminUser([])
    history.push({ pathname: AdminLinks.admin_dashboard })
  }
  return (
    <Fragment>
      <header className="admin-header">
        <div
          className={
            authContext.adminMenuActive +
            ' admin-logo left-admin-section text-left'
          }
        >
          <Link to={AdminLinks.admin_dashboard}>
            <img
              src="/assets/images/doc-scheduler-logo.png"
              className="logo-normal"
              alt="DocScheduler"
            />
            <img
              src="/assets/images/logo-circle.png"
              alt="DocScheduler"
              className="logo-circle"
            />
          </Link>
        </div>
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' right-admin-section admin-right-header'
          }
        >
          <Row>
            <div className="col-12">
              <div className="header-topright">
                <div className="header-menu-icon">
                  <a className="toggle-menu" onClick={(e) => toggleMenu(e)}>
                    <img
                      src="/assets/images/Menu.svg"
                      className="menu-icon"
                      alt="Menu"
                    />
                  </a>
                </div>
                <div className="header-logo-device d-lg-none">
                  <img
                    src="/assets/images/doc-scheduler-logo.png"
                    alt="DocScheduler"
                  />
                </div>
                {
                  // JSON.stringify(authContext ?.admin)
                }
                <div className="header-profile">
                  <div
                    className="admin-user"
                    onClick={() => setHidden(!hidden)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={
                        authContext?.admin?.profileImage
                          ? authContext?.admin?.profileImage
                          : '/assets/images/profile_user.png'
                      }
                      alt="User"
                      className="user-icon"
                      style={{ width: '36px' }}
                    />
                    <span className="username">{`${authContext?.admin?.admins?.firstName} ${authContext?.admin?.admins?.lastName}`}</span>
                    <i className="profile-arrow">
                      <ProfileArrowIcon />
                    </i>
                  </div>
                  <div hidden={hidden} className="admin-profile-menu-wrap">
                    <div className="admin-profile-menu-inner">
                      <span onClick={logout}>
                        <img
                          src="/assets/images/logout-icon.png"
                          className="logout-icon"
                          alt="logout"
                        />
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </header>
    </Fragment>
  )
}

export default AdminHeader
