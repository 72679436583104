import { Box, Flex } from '@chakra-ui/react'

export const BurgerMenu = ({ mobileMenuVisible, setMobileMenuVisible }) => {
  return (
    <Box display={['block', 'block', 'block', 'none']}>
      <Flex
        direction="column"
        alignItems="flex-end"
        p={2}
        pos="relative"
        zIndex={1100}
        cursor="pointer"
        onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
      >
        <Box
          sx={{
            width: '30px',
            height: '3px',
            backgroundColor: '#333',
            transition: '0.4s',
            margin: '3px 0',
            transform: `${
              mobileMenuVisible ? 'rotate(-45deg) translate(-5px, 6px)' : ''
            }`,
          }}
        ></Box>
        <Box
          sx={{
            opacity: mobileMenuVisible ? 0 : 1,
            width: '30px',
            height: '3px',
            margin: '3px 0',
            backgroundColor: '#333',
            transition: '0.4s',
          }}
        ></Box>
        <Box
          sx={{
            width: `${mobileMenuVisible ? '30px' : '22px'}`,
            height: '3px',
            backgroundColor: '#333',
            transition: '0.4s',
            margin: '3px 0',
            transform: `${
              mobileMenuVisible ? 'rotate(45deg) translate(-6px, -7.5px)' : ''
            }`,
          }}
        ></Box>
      </Flex>
    </Box>
  )
}
