import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { OldSocialLogin as SocialLogin } from 'react-social-login'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import StorageAdaptation from '../../reactLayer/storage/storage'
import InputValidation from '../../reactLayer/validation'

const ProviderLogin = (props) => {
  const location = useLocation()
  const subRoleParam = location?.search
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [tokenExpire, settokenExpire] = useState(null)
  const [passwordExpired, setPasswordExpired] = useState(false)

  const [resetPassword, setResetPassword] = useState('')
  const [resetPasswordAgain, setResetPasswordAgain] = useState('')
  const [resetPasswordError, setResetPasswordError] = useState('')
  const [resetPasswordAgainError, setResetPasswordAgainError] = useState('')
  const [verifyToken, setVerifyToken] = useState('')
  const [modalProps, setmodalProps] = useState({})
  const emailErrorMsg = ['Email is required', 'Invalid Email']
  const passwordErrorMsg = ['Password is required', 'Invalid Password']
  const loginType = props.ltype ? props.ltype : 'provider'
  const [errors, setErrors] = useState({
    eemail: false,
    epassword: false,
    eresponse: false,
  })

  const currentUserRole = window.localStorage.getItem('role')
  const subRole = window.localStorage.getItem('subRole')

  const Validatepages = () => {
    let user = authContext.user ? authContext.user : null

    const accountInfo = user?.accountInfo

    if (currentUserRole === '3') {
      if (accountInfo?.isProfileCompleted && authContext.token) {
        history.push({
          pathname: ProviderLinks.dashboard,
        })
      }
    } else {
      if (accountInfo?.isProfileCompleted && authContext.token) {
        history.push({
          pathname: PatientLinks.home,
        })
      }
    }
  }
  const [loginData, setLoginData] = useState({
    email: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: emailErrorMsg,
      errorKey: 'eemail',
    },
    password: {
      value: '',
      validation: ['blank', 'password'],
      errorMsg: passwordErrorMsg,
      errorKey: 'epassword',
    },
    keep_me: {
      value: 0,
      validation: [],
    },
  })

  const openPatientLogin = (e) => {
    e.preventDefault()
    props.settabvisibleComponent('login')
    authContext.showModal()
  }

  const formSubmit = async (e) => {
    e.preventDefault()
    const validationResult = InputValidation(loginData)

    const otpVerify =
      authContext?.otpVerify?.indexOf(loginData.email.value) > '-1'
        ? false
        : true

    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })
    } else {
      authContext.setLoader()

      let data = {
        email: loginData.email.value,
        password: loginData.password.value,
        keep_me: loginData.keep_me.value,
        otpVerify: otpVerify,
        loginAs:
          currentUserRole === '3' && !subRoleParam && !subRole
            ? 3
            : currentUserRole === '3' && (subRoleParam || subRole)
            ? 5
            : currentUserRole === '3' && subRole
            ? 3
            : null,
      }

      let url = ''
      if (currentUserRole === '3') {
        url = 'providers/login'
      } else if (subRoleParam) {
        url = 'providers/login'
      } else {
        url = 'patients/login'
      }
      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let postResult = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()

      if (postResult.body.status) {
        const result = postResult?.body?.result
        const data = result?.data
        const step = result?.step
        const accountInfo = data?.accountInfo
        const token = result?.token
        const isVerifyAuth = accountInfo?.isVerifyAuth

        const loginModal = authContext.loginModal

        authContext.setToken(token)

        const isNotVerified = isVerifyAuth === 0

        const isVerified = isVerifyAuth === 1

        const profileIsNotCompletedAndStepIsDefined =
          !data?.isProfileCompleted && typeof step == 'number'

        if (loginType === 'provider') {
          if (result?.presentVerificationModal) {
            history.push({ pathname: ProviderLinks.twofactauth })
          }
          if (isNotVerified) {
            authContext.setUser(data)
            if (profileIsNotCompletedAndStepIsDefined) {
              history.push({
                pathname: ProviderLinks['register_step' + step],
              })
            } else {
              history.push({ pathname: ProviderLinks.dashboard })
            }
          }
          if (isVerified) {
            authContext.setUser(data)
            if (profileIsNotCompletedAndStepIsDefined) {
              history.push({
                pathname: ProviderLinks['register_step' + step],
              })
            } else {
              history.push({ pathname: ProviderLinks.dashboard })
            }
          }
        } else {
          if (result?.presentVerificationModal) {
            props.settabvisibleComponent('twostep')
          }
          if (isNotVerified) {
            result.body.result.data['isProfileCompleted'] = 1
            authContext.setUser(data)
            if (loginModal?.redirectUrl) {
              return null
            } else {
              history.push({ pathname: PatientLinks.home })
            }
          }
          if (isVerified) {
            authContext.setUser(data)
            if (profileIsNotCompletedAndStepIsDefined) {
              history.push({
                pathname: PatientLinks['register_step' + step],
              })
            }
          }
        }
      } else {
        const result = postResult?.body?.result
        const body = postResult.body

        const error_type = result?.error_type
        if (error_type) {
          if (error_type === 'password_expired') {
            setVerifyToken(result?.verify_token)
            setPasswordExpired(true)
          } else {
            setErrors({ ...errors, ...{ eresponse: body.message } })
          }
        } else {
          setErrors({ ...errors, ...{ eresponse: body.message } })
        }
      }
    }
  }
  const dosubmit = (e) => {
    if (e.key === 'Enter') {
      formSubmit(e)
    }
  }
  const keepLogin = (e) => {
    if (e.currentTarget.checked === true) {
      setLoginData({
        ...loginData,
        ...{
          keep_me: {
            value: 1,
            validation: [],
          },
        },
      })
    } else {
      setLoginData({
        ...loginData,
        ...{
          keep_me: {
            value: 0,
            validation: [],
          },
        },
      })
    }
  }
  const handleSocialLogin = async (user = null) => {
    if (
      user &&
      user._token &&
      user._token.idToken &&
      user._profile &&
      user._profile.id
    ) {
      let data = {
        oauth_provider: 'google',
        token: user._token.idToken,
      }
      authContext.setLoader()

      let url = 'patients/google/login'
      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        if (result.body.result.data) {
          let user = authContext.user ? authContext.user : {}
          user['token'] = result.body.result.token
          user = { ...user, ...result.body.result.data }
          authContext.setToken(user.token)
          authContext.setUser(user)
          window.localStorage.removeItem('subRole')
          if (result.body.result.step) {
            history.push({
              pathname: PatientLinks['register_step' + result.body.result.step],
            })
          } else if (
            result.body.result.data.accountInfo &&
            result.body.result.data.accountInfo.isProfileCompleted
          ) {
            history.push({ pathname: PatientLinks.dashboard })
          } else {
            history.push({ pathname: PatientLinks['register_step2'] })
          }
        }
      }
    }
  }

  useEffect(() => {
    if (subRoleParam) {
      window.localStorage.setItem('subRole', 3)
    }
  }, [subRoleParam])

  const resetPasswordSubmit = async () => {
    let checkBlank = false
    if (resetPassword === '') {
      checkBlank = true
      setResetPasswordError('Please enter password')
    }
    if (resetPasswordAgain === '') {
      checkBlank = true
      setResetPasswordAgainError('Please enter password again')
    }
    if (checkBlank) {
      return
    }

    const regularExpression =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (regularExpression.test(resetPassword) === false) {
      setResetPasswordError(
        'Password must contain 8 letters, at least 1 number, and at least 1 special character'
      )
      return
    }
    if (resetPassword !== resetPasswordAgain) {
      setResetPasswordAgainError('Passwords does not matched')
      return
    }
    const data = {
      password: resetPassword,
      confirmPassword: resetPasswordAgain,
      resetPasswordToken: verifyToken,
    }
    const url = 'reset-password'
    const submitData = {
      url: url,
      body: JSON.stringify(data),
    }
    authContext.setLoader()
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    if (result.body.status === false) {
      setResetPasswordAgainError(result.body.message)
    } else {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      setPasswordExpired(false)
      setTimeout(() => {
        history.push({
          pathname: ProviderLinks.login,
        })
      }, 1500)
    }
  }

  useEffect(() => {
    authContext.hideResponseModal()
    if (
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') &&
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') ==
        'provider' &&
      !StorageAdaptation.getLocalStorage('token', false, 'local')
    ) {
      settokenExpire(true)
    }
  }, [tokenExpire])

  const suspenseLoader = () => <p></p>
  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        {/* Form start step 1 */}
        <Row>
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        </Row>
        {authContext.user === null ||
        (authContext.user && !authContext.user.isProfileCompleted) ? (
          <>
            <Container className="">
              <Row
                className={
                  loginType === 'provider'
                    ? 'provider-login-wrap'
                    : 'provider-login-wrap patient'
                }
              >
                <div className="col-md-12 mb-3">
                  {currentUserRole === '3' || subRoleParam ? (
                    <>
                      <h1>
                        {!subRole && !subRoleParam
                          ? 'Provider Sign In'
                          : 'Team Member Sign In'}
                      </h1>
                      {props.tokenExpire ? (
                        <>
                          <div className="loggedout-msg">
                            <div className="l-msg">
                              <div className="right-mark"></div>
                            </div>
                            <div className="r-msg">
                              For your safety, we logged you out automatically
                              due to inactivity. Please log in again.
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <p>
                        By continuing, I agree that I am a licensed medical
                        professional and have read and agree to DocScheduler's{' '}
                        <a href={PatientLinks.term_of_user}>Terms of Use</a> and{' '}
                        <a href={PatientLinks.privacy_policy}>
                          Privacy Policy.
                        </a>
                      </p>
                    </>
                  ) : passwordExpired ? (
                    <>
                      <h1>Reset your password</h1>
                      <p>
                        Your need to reset your password for security reasons as
                        it's been 90 days since you updated your password.
                      </p>
                    </>
                  ) : (
                    <>
                      <h1>Patient Sign In</h1>
                      {props.tokenExpire ? (
                        <>
                          <div className="loggedout-msg">
                            <div className="l-msg">
                              <div className="right-mark"></div>
                            </div>
                            <div className="r-msg">
                              For your safety, we logged you out automatically
                              due to inactivity. Please log in again.
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <p>
                        By continuing I agree that I am at least 18 years old
                        and have read and agree to DocScheduler's{' '}
                        <a href={PatientLinks.term_of_user}>Terms of Use</a> and{' '}
                        <a href={PatientLinks.privacy_policy}>
                          Privacy Policy.
                        </a>
                      </p>
                    </>
                  )}
                </div>
                {currentUserRole === '4' ? (
                  passwordExpired ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-md-12 mb-3 provider-steps-wrap text-center">
                        <SocialLogin
                          provider="google"
                          appId="863452583193-635if4fsrtg1d1hq9prc2qseeq18asfk.apps.googleusercontent.com"
                          callback={handleSocialLogin}
                          // onLoginSuccess={handleSocialLogin}
                          // onLoginFailure={handleSocialLoginFailure}
                        >
                          <div className="google-box">
                            <div className="google-icon" alt="Google"></div>
                            <span>Connect with Google</span>
                          </div>
                        </SocialLogin>
                      </div>
                      <div className="col-md-12 mb-3 provider-steps-wrap">
                        <div className="medical-info body">
                          <span>Or</span>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
                {passwordExpired ? (
                  <>
                    <div className="col-md-12 mb-3">
                      <label className="pro-label">Password</label>
                      <input
                        type="password"
                        name="resetPassword"
                        onChange={(e) => {
                          setResetPassword(e.target.value)
                          setResetPasswordError('')
                        }}
                        className={
                          resetPasswordError
                            ? 'input-pro  error-border'
                            : 'input-pro'
                        }
                        placeholder="Enter Password"
                        autoComplete="off"
                        value={resetPassword}
                      />
                      <small className="error-input">
                        {resetPasswordError}
                      </small>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="pro-label">Confirm Password</label>
                      <input
                        type="password"
                        name="resetPasswordAgain"
                        onChange={(e) => {
                          setResetPasswordAgain(e.target.value)
                          setResetPasswordAgainError('')
                        }}
                        className={
                          resetPasswordAgainError
                            ? 'input-pro  error-border'
                            : 'input-pro'
                        }
                        placeholder="Enter Password Again"
                        autoComplete="off"
                        value={resetPasswordAgain}
                      />
                      <small className="error-input">
                        {resetPasswordAgainError}
                      </small>
                    </div>
                    <div className="col-md-12 text-center submit-btn">
                      <div className="next-prev-btn-pro">
                        <input
                          type="button"
                          onClick={(e) => {
                            resetPasswordSubmit(e)
                          }}
                          className="next-btn-pro full-width"
                          value="Reset"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-12 mb-3">
                      <label className="pro-label">Email</label>
                      <input
                        type="text"
                        name="email"
                        className={
                          errors.eemail
                            ? 'input-pro  error-border'
                            : 'input-pro'
                        }
                        placeholder="Email"
                        autoComplete="off"
                        onClick={() =>
                          setErrors({
                            ...errors,
                            ...{ eemail: false, eresponse: false },
                          })
                        }
                        onFocus={() =>
                          setErrors({
                            ...errors,
                            ...{ eemail: false, eresponse: false },
                          })
                        }
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            ...{
                              email: {
                                value: e.currentTarget.value.trim(),
                                validation: ['blank', 'email'],
                                errorMsg: emailErrorMsg,
                                errorKey: 'eemail',
                              },
                            },
                          })
                        }
                        value={loginData.email.value}
                      />
                      <small className="error-input">{errors.eemail}</small>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="pro-label">Password</label>
                      <input
                        type="password"
                        name="password"
                        className={
                          errors.epassword
                            ? 'input-pro  error-border'
                            : 'input-pro'
                        }
                        placeholder="Password"
                        autoComplete="off"
                        onClick={() =>
                          setErrors({
                            ...errors,
                            ...{ epassword: false, eresponse: false },
                          })
                        }
                        onFocus={() =>
                          setErrors({
                            ...errors,
                            ...{ epassword: false, eresponse: false },
                          })
                        }
                        onKeyPress={(e) => dosubmit(e)}
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            ...{
                              password: {
                                value: e.currentTarget.value.trim(),
                                validation: ['blank', 'password'],
                                errorMsg: passwordErrorMsg,
                                errorKey: 'epassword',
                              },
                            },
                          })
                        }
                        value={loginData.password.value}
                      />
                      <small className="error-input">{errors.eresponse}</small>
                      <small className="error-input">{errors.epassword}</small>
                      {errors.epassword && (
                        <div className="pass-rules validation-rules">
                          <div className="head-rules">Password Rules:</div>
                          <ul>
                            <li>Has at least 8 characters</li>
                            <li>
                              Must contain letters, at least 1 number, and at
                              least 1 special character
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 mb-3 keep-log">
                      <div className="cus-check">
                        <input
                          type="checkbox"
                          id="loggedIn"
                          name="loggedIn"
                          onChange={(e) => {
                            keepLogin(e)
                          }}
                        />
                        <label htmlFor="loggedIn">Keep me logged in</label>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 forgot-login">
                      <div className="text-md-right">
                        <Link
                          onClick={authContext.hideModal}
                          to={ProviderLinks.forgot_pass}
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-12 text-center submit-btn">
                      <div className="next-prev-btn-pro">
                        <input
                          type="button"
                          onClick={(e) => {
                            formSubmit(e)
                          }}
                          className="next-btn-pro full-width"
                          value="Sign In"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 text-center link-text">
                      {currentUserRole === '3' ? (
                        <>
                          <div className="bday-use">
                            New to DocScheduler?{' '}
                            <strong>
                              <Link
                                onClick={authContext.hideModal}
                                to={ProviderLinks.freeprofile}
                              >
                                List your Practice on DocScheduler
                              </Link>
                            </strong>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bday-use">
                            New to DocScheduler?{' '}
                            <strong>
                              <Link
                                onClick={authContext.hideModal}
                                to={PatientLinks.register_step1}
                              >
                                Create Account
                              </Link>
                            </strong>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-12 mb-3 text-center link-text">
                      {currentUserRole === '3' ? (
                        <>
                          <div className="bday-use">
                            Looking to sign in as a patient?{' '}
                            <strong>
                              <a href="/#" onClick={(e) => openPatientLogin(e)}>
                                Sign in here
                              </a>
                            </strong>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bday-use">
                            Are you a Doctor or Dentist?{' '}
                            <strong>
                              <Link
                                onClick={authContext.hideModal}
                                to={ProviderLinks.login}
                              >
                                Sign in here
                              </Link>
                            </strong>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </Row>
            </Container>
          </>
        ) : (
          <>{Validatepages()}</>
        )}
      </Fragment>
    </Suspense>
  )
}

export default ProviderLogin
