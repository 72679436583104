import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'
import { practiceInitialState } from './initial-state'
import log from 'eslint-plugin-react/lib/util/log'

const DeleteSection = (props) => {
  return (
    <>
      <div
        onClick={() => props.deleteElement(props.name, props.index)}
        className="delete-icon"
        alt="Delete"
      ></div>
    </>
  )
}

const checkBoxes = [
  { title: 'Are You Accepting New Patients?', name: 'isAcceptingNewPatient' },
  { title: 'Virtual Visit Capability?', name: 'hasVirtualVisit' },
]

const PracticeInformation = (props) => {
  const authContext = useContext(AuthContext)

  const [states, setStates] = useState([])
  const [cities] = useState([])

  const [practiceData, setPracticeData] = useState([practiceInitialState])
  const [appointmentOptions, setAppointmentOptions] = useState([])

  const deleteElement = (elementName, index) => {
    let tempData = []
    if (elementName === 'practiceData') {
      tempData = practiceData
      tempData.splice(index, 1)
      setPracticeData([...tempData])
    }
  }

  const addPracticeFields = (e) => {
    e.preventDefault()
    let tempData = practiceData
    tempData.push(practiceInitialState)
    setPracticeData([...tempData])
  }

  const changeAppointmentOptions = (e) => {
    let name = e.currentTarget.getAttribute('data-name')
    let tempChangeOptions = appointmentOptions
    let arrayIndex = tempChangeOptions.indexOf(name)
    if (arrayIndex > '-1') {
      tempChangeOptions.splice(arrayIndex, 1)
    } else {
      tempChangeOptions.push(name)
    }
    setAppointmentOptions([...tempChangeOptions])
  }

  const modifyPracticeData = async (e, varName = 'practiceData') => {
    let index,
      name,
      value,
      tempData = []

    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    if (varName === 'practiceData') {
      tempData = practiceData
      if (name == 'pNumber') {
        tempData[index][name]['value'] = Global.phoneFormator(value)
      } else {
        tempData[index][name]['value'] = value
      }
      tempData[index][name][name] = ''
      if (name === 'city') {
        tempData[index][name]['value'] = value
        tempData[index]['zipcode']['value'] = ''
        cities.filter((city) => {
          if (city.id == value) {
            tempData[index]['state']['value'] = city.stateId
            tempData[index]['state']['state'] = ''
            return true
          }
        })
        tempData[index]['zipcode']['zipCodes'] = JSON.parse(
          e.target.selectedOptions[0].getAttribute('data-zipcode')
        )
      } else if (name === 'state') {
        tempData[index][name]['value'] = value
        tempData[index]['city']['value'] = ''
        tempData[index]['zipcode']['value'] = ''
        authContext.setLoader()
        let cityResponse = await Global.getCities(value)
        authContext.unsetLoader()
        tempData[index]['city']['cities'] = cityResponse
      } else if (name === 'link') {
        tempData[index][name]['value'] = value.trim()
      } else if (name === 'zipcode') {
        tempData[index]['zipcode']['value'] = value
      }
      setPracticeData([...tempData])
    }
  }

  const validate = () => {
    let temppracticeData = practiceData
    let flag = true
    let setFocus = false
    let practData = []
    temppracticeData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)

      const state = states.find((s) => s.id == currentValue?.state?.value)

      let ms = {
        practiceName: currentValue.pName.value.trim(),
        practiceAddress: currentValue.pAddress.value.trim(),
        city: currentValue.city.value,
        state: state?.name,
        zipCode: currentValue.zipcode.value.trim(),
        practicePhoneNumber: currentValue.pNumber.value.trim(),
        website: currentValue.link.value.trim(),
      }

      practData.push(ms)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          temppracticeData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .focus()
          }
        }
        setPracticeData([...temppracticeData])
      }
    })

    const postData = {
      practiceLocations: practData,
      acceptingNewPatients:
        appointmentOptions.indexOf('isAcceptingNewPatient') > '-1',
      virtualVisits: appointmentOptions.indexOf('hasVirtualVisit') > '-1',
    }

    props.collectData(postData, { practice: !flag })
  }

  const getState_and_getCity = async () => {
    let stateResponse = await Global.getStates()
    setStates(stateResponse)
  }

  useEffect(() => {
    getState_and_getCity()
  }, [])

  useEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  }, [props.shouldValidate])

  return (
    <Fragment>
      <Row>
        <div className="col-12 col-lg-9">
          <div
            className="heading-wrap mt-4 mb-4 col-12"
            style={{ paddingLeft: 0 }}
          >
            <div className="table-heading mt-1">Practice Information</div>
          </div>
          {practiceData?.map((data, index) => (
            <Row
              className="position-relative provider-steps-wrap provider-form-edit"
              key={data + '-main-' + index}
            >
              {index > 0 ? (
                <DeleteSection
                  name="practiceData"
                  index={index}
                  deleteElement={deleteElement}
                />
              ) : (
                <></>
              )}
              <div className="col-md-12 mb-3">
                <label className="pro-label">Practice Name</label>
                <input
                  type="text"
                  name="pName"
                  placeholder=" "
                  autoComplete="off"
                  className={
                    data.pName.pName ? 'input-pro  error-border' : 'input-pro'
                  }
                  value={data.pName.value}
                  data-index={index}
                  data-name="pName"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pName.pName}</small>
              </div>

              <div className="col-md-12 mb-3">
                <label className="pro-label">Practice Address</label>
                <input
                  type="text"
                  name="pAddress"
                  placeholder=" "
                  autoComplete="off"
                  className={
                    data.pAddress.pAddress
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  value={data.pAddress.value}
                  data-index={index}
                  data-name="pAddress"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pAddress.pAddress}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">State</label>
                <div className="myform">
                  <select
                    className={
                      data.state.state ? 'select  error-border' : 'select'
                    }
                    value={data.state.value}
                    data-index={index}
                    data-name="state"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option
                        key={state.id + '-state-' + index}
                        value={state.id}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  <small className="error-input">{data.state.state}</small>
                </div>
              </div>
              <div className="col-md-5 mb-3">
                <label className="pro-label">City</label>
                <div className="myform">
                  <select
                    className={
                      data.city.city ? 'select  error-border' : 'select'
                    }
                    value={data.city.value}
                    data-index={index}
                    data-name="city"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select City</option>
                    {data.city.cities
                      ? Object.keys(data.city.cities.citylist).map((key) => (
                          <option
                            key={data.city.cities.citylist[key][0].id + '-city'}
                            data-zipcode={JSON.stringify(
                              data.city.cities.citylist[key]
                            )}
                            value={key}
                          >
                            {key}
                          </option>
                        ))
                      : ''}
                  </select>
                  <small className="error-input">{data.city.city}</small>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <label className="pro-label">Zip Code</label>
                <div className="myform">
                  <select
                    className={
                      data.zipcode.zipcode ? 'select  error-border' : 'select'
                    }
                    value={data.zipcode.value}
                    data-index={index}
                    data-name="zipcode"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select Zip Code</option>
                    {data.zipcode.zipCodes
                      ? data.zipcode.zipCodes.map((zip, zipIndex) => (
                          <option
                            key={zip.id + '-zip-' + zipIndex}
                            data-city={zip.id}
                            value={zip.zip_code}
                          >
                            {zip.zip_code}
                          </option>
                        ))
                      : ''}
                  </select>
                  <small className="error-input">{data.zipcode.zipcode}</small>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="pro-label">
                  Patient Scheduling Phone Number
                </label>
                <input
                  type="text"
                  name="pS-number"
                  placeholder="(###) ###-####"
                  autoComplete="off"
                  className={
                    data.pNumber.pNumber
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  value={data.pNumber.value}
                  data-index={index}
                  data-name="pNumber"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pNumber.pNumber}</small>
              </div>
            </Row>
          ))}
          <Row className="provider-steps-wrap">
            <div className="col-md-12 mb-4">
              <div className="form-add-link">
                <a
                  onClick={(e) => {
                    addPracticeFields(e)
                  }}
                  href="/#"
                >
                  + Add Another Practice Location
                </a>
              </div>
            </div>
          </Row>
          <Row className="provider-steps-wrap">
            <div className="heading-wrap mt-4 mb-4 col-12">
              <div className="table-heading mt-1">
                Appointment Request Options
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="pro-label">Link to Website</label>
              <input
                type="text"
                data-index="0"
                name="link"
                data-name="link"
                autoComplete="off"
                className="input-pro input-blue"
                onChange={(e) => {
                  modifyPracticeData(e, 'practiceData')
                }}
                defaultValue={practiceData[0].link.value}
              />
              <small className="error-input">{practiceData[0].link.link}</small>
            </div>

            <div className="col-md-12 mb-4">
              {checkBoxes.map((box, index) => {
                return (
                  <div key={box.name} className="appoint-req-box">
                    <span className="info-hg">
                      {box.title}
                      <div className="info-icon"></div>
                    </span>
                    <span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id={`switch-btn${index}`}
                          data-name={box.name}
                          value={index}
                          onChange={(e) => changeAppointmentOptions(e)}
                          checked={appointmentOptions.indexOf(box.name) > '-1'}
                        />
                        <div className="slider round"></div>
                      </label>
                    </span>
                  </div>
                )
              })}
            </div>
          </Row>
        </div>
      </Row>
    </Fragment>
  )
}

export default PracticeInformation
