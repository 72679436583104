import { globalHistory } from '@reach/router'
import { useEffect, useState } from 'react'

const useLocationMod = () => {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  }

  const [state, setState] = useState(initialState)
  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const { location } = params
      const newState = Object.assign({}, initialState, { location })
      setState(newState)
    })
    return () => {
      removeListener()
    }
  }, [])

  return state
}

export default useLocationMod
