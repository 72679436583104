import 'bootstrap/dist/css/bootstrap.css'
import { Loader } from 'google-maps'
import 'promise-polyfill/src/polyfill'
import { Suspense, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { providerAddress } from '../search-results/utils'

const loader = new Loader('AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo', {})

// AIzaSyA_2y4tIq0-KvixCYEWsIFwIH_ANkfuMDw

const Gmaps = (props) => {
  const {
    width,
    height,
    zoomLabel,
    gmapData,
    marginTop,
    sticky,
    items,
    setActiveIndex,
    isExtended,
  } = props

  const mapWrapper = useRef(null)
  let map
  const initMap = async () => {
    const google = await loader.load()
    // const myLatLng = { lat: 31.56391, lng: 147.154312 }
    map = new google.maps.Map(mapWrapper.current, {
      center: {
        lat: gmapData[0].lat,
        lng: gmapData[0].lng,
      },
      zoom: zoomLabel,
      mapTypeControl: false,
      streetViewControl: false,
    })
    let currWindow = null
    gmapData.length &&
      gmapData.map((location, i) => {
        let marker = new google.maps.Marker({
          position: {
            lat: location.lat,
            lng: location.lng,
          },
          map,
          // mapTypeControlOptions: {
          //   mapTypeIds: [
          //     google.maps.MapTypeId.ROADMAP,
          //   ]
          // }
          // label: labels[i % labels.length],
        })

        const infoModal =
          items &&
          ReactDOMServer.renderToString(
            <div className="doctor-description-wrap--modified">
              <div className="doctor-img--modified">
                <img
                  src={items[i]?.profileImage || items[i]?.users?.profileImage}
                  alt="Doctor's photo"
                  className="sponsored-docimg"
                />
                {/* <img src="/assets/images/top-doc-img.png" alt="Doctor" className="top-docimg" />
                                                  <img src="/assets/images/dummy-doc-img.png" alt="Doctor" className="other-docimg" /> */}
              </div>
              <div className="doctor-description-right--modified">
                <h5>
                  {' '}
                  <span>
                    {items[i]?.providers
                      ? items[i].providers.firstName + ' '
                      : items[i]?.firstName + ' '}
                    {items[i]?.providers
                      ? items[i].providers.middleName + ' '
                      : items[i]?.middleName + ' '}
                    {items[i]?.providers
                      ? items[i].providers.lastName + ' '
                      : items[i]?.lastName + ' '}
                    {items[i]?.providerMedicalCredientialsValue
                      ? ', ' + items[i]?.providerMedicalCredientialsValue
                      : ''}
                  </span>
                </h5>
                <span className="doc-designation--modified">
                  {items[i]?.providerMedicalSpecialitiesValue
                    ? items[i]?.providerMedicalSpecialitiesValue
                    : ''}
                </span>
                {items[i]?.providerPracticeLocations.length > 0 &&
                  items[i]?.providerPracticeLocations.map(
                    (pa, pai) =>
                      pai === 0 && (
                        <span
                          className="address--modified"
                          key={'address-' + pai}
                        >
                          {providerAddress(pa)}
                        </span>
                      )
                  )}
                {/* <div className="rating-result">
                <span className="rating">
                  {/* <span
                                className={
                                  item && item.totalReviewAvg
                                    ? "rating" + parseFloat(item.totalReviewAvg).toFixed(1).replace(".", '')
                                    : "rating" + 0
                                }
                              ></span> */}
                {/* <Stars
                    rating={
                      items[i].reviewStars
                        ? parseFloat(items[i].reviewStars).toFixed(1)
                        : 0
                    }
                    {...items[i]}
                    getProfile={getProfile}
                    componentName={
                      isCareTeam ? 'sponsored_doctor' : 'searchResult'
                    }
                  /> */}
                {/* </span>
                <span style={{ marginLeft: '8px' }}>
                  (
                  {items[i].totalReviewAvg
                    ? parseFloat(items[i].totalReviewAvg).toFixed(1)
                    : 0}
                  ){' '}
                  {items[i] && items[i].totalReviewCount
                    ? items[i].totalReviewCount
                    : 0}{' '}
                  Reviews
                </span>
              </div> */}
              </div>
            </div>
          )

        let infoWindow = new google.maps.InfoWindow({
          content: infoModal,
          maxWidth: 300,
          minWidth: 250,
        })
        google.maps.event.addDomListener(marker, 'click', () => {
          setActiveIndex(i)
          if (currWindow !== null) {
            currWindow.close()
          }

          infoWindow.open({
            anchor: marker,
            map,
          })
          currWindow = infoWindow
        })
      })
    // new google.maps.Marker({
    //     position: myLatLng,
    //     map,
    //     title: "Hello World!",
    // });
    // plotMarkers();
  }
  useEffect(() => {
    initMap()
  }, [props.items, gmapData])
  const suspenseLoader = () => <p></p>
  return (
    <Suspense fallback={suspenseLoader()}>
      <div
        style={{
          height:
            width !== 500
              ? width + 'px'
              : isExtended && width === 500
              ? 'calc(100vh - 130px)'
              : 500,
          maxWidth: height ? height + 'px' : '100%',
          top: sticky ? '125px' : 'unset',
          marginTop: marginTop === 0 ? marginTop + 'px' : '20px',
          position: sticky ? 'sticky' : 'relative',
        }}
      >
        <div
          id="map"
          style={{
            height: '100%',
            maxWidth: '100%',
          }}
          ref={mapWrapper}
        ></div>
      </div>
    </Suspense>
  )
}

Gmaps.defaultProps = {
  width: 500,
  height: 500,
  zoomLabel: 17,
  gmapData: [{ lat: 20.5937, lng: 78.9629 }],
}

export default Gmaps
