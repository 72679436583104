import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
// import 'react-widgets/dist/css/react-widgets.css'
import { Container, Row } from 'react-bootstrap'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import InputValidation from '../../reactLayer/validation'
import ProviderStep from './provider-steps'

const ProviderStep2 = () => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [languageList, setLanguageList] = useState([])
  const patientTreatedList = ['Adults', 'Children (Pediatrics)']
  const areaerrorMsg = ['This Field is required']
  const patientTreatederrorMsg = ['This Field is required']
  const languageerrorMsg = ['This Field is required']
  const startyear = new Date().getFullYear() - 80
  const allyears = Global.getYearList() //Array.from(new Array(81), (val, index) => index + startyear).reverse();
  const [degreeStartYears] = useState(Global.getDegreeStartYears())

  const [errors, setErrors] = useState({
    area: false,
    patientTreated: false,
    language: false,
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const [medicalCredentials, setmedicalCredentials] = useState([])
  const [medicalSpeciality, setmedicalSpeciality] = useState([])
  const [medicalprogrametypes, setmedicalprogrametypes] = useState([])
  const [medInstitue, setmedInstitue] = useState([])
  const [trainingList, settrainingList] = useState([])
  const [medicalCredentialCategory, setmedicalCredentialCategory] = useState([])

  const [otherData, setotherData] = useState({
    area: {
      value: [],
      validation: ['favalue'],
      errorMsg: areaerrorMsg,
      errorKey: 'area',
    },
    patientTreated: {
      value: [],
      validation: ['arrayempty'],
      errorMsg: patientTreatederrorMsg,
      errorKey: 'patientTreated',
    },
    language: {
      value: ['english'],
      validation: ['arrayempty'],
      errorMsg: languageerrorMsg,
      errorKey: 'language',
    },
  })
  const [medicalData, setmedicalData] = useState([
    {
      medicalCredential: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        category: '',
        errorKey: 'medicalCredential',
      },
      medicalSpecialty: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalSpecialty',
      },
    },
  ])

  const [degreeData, setdegreeData] = useState([
    {
      pType: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      sName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: [{ compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    },
  ])

  const [trainingData, settrainingData] = useState([
    {
      pType: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      pName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pName',
      },
      sName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: [{ compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    },
  ])

  const addMedicalFields = (e) => {
    e.preventDefault()
    const tempData = medicalData
    tempData.push({
      medicalCredential: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalCredential',
      },
      medicalSpecialty: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalSpecialty',
      },
    })

    setmedicalData([...tempData])
  }

  const addLangData = (e) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute('data-name')
    val = e.currentTarget.value
    data = otherData[name]
    arrayIndex = data.value.indexOf(val)
    if (arrayIndex > '-1') {
      data.value.splice(arrayIndex, 1)
    } else {
      data.value.push(val)
    }
    setotherData({ ...otherData, ...{ [name]: data } })
  }
  const modifyPrgBaseonCategory = (mcategory) => {
    let tempdegreeData = degreeData
    tempdegreeData.map((t, i) => {
      if (t.pType.category && mcategory.indexOf(t.pType.category) == '-1') {
        t.pType.category = ''
        t.pType.value = ''
      }
      if (t.sName.category && mcategory.indexOf(t.sName.category) == '-1') {
        t.sName.category = ''
        t.sName.value = ''
      }
    })
    setdegreeData([...tempdegreeData])
    // console.log('tempdegreeData====>', tempdegreeData);
  }
  const modifyCredCategory = () => {
    // console.log('Medical category', medicalData);
    let mcategory = []
    medicalData.map((d, i) => {
      if (d.medicalCredential.category) {
        mcategory.push(d.medicalCredential.category)
      }
    })
    setmedicalCredentialCategory([...mcategory])
    modifyPrgBaseonCategory(mcategory)
    // console.log('mccategory===>', mcategory);
  }
  const modifyMedicalSpeciality = (e, varName = 'medicalData') => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    //  console.log('varName', varName, index, value, name);
    //  return false;
    if (varName === 'medicalData') {
      tempData = medicalData
      tempData[index][name]['value'] = value
      if (name == 'medicalCredential') {
        let catArray = medicalCredentials.filter((mc) => {
          if (mc.id == value) {
            return true
          }
        })
        if (catArray.length) {
          tempData[index][name]['category'] = catArray[0]['category']
        } else {
          tempData[index][name]['category'] = ''
        }
      }

      tempData[index][name][name] = ''
      setmedicalData([...tempData])
      modifyCredCategory()
    } else if (varName === 'degreeData') {
      tempData = degreeData
      tempData[index][name]['value'] = value
      tempData[index][name][name] = ''
      // console.log('varName', tempData);
      // return false;

      if (name == 'yCompleted' || name == 'yStarted') {
        tempData[index]['yCompleted']['validation'] = [
          'blank',
          { compareDate: { startDate: tempData[index]['yStarted']['value'] } },
        ]
      } else {
        let catArray = medicalprogrametypes.filter((mc) => {
          if (mc.id == value) {
            return true
          }
        })
        if (catArray.length) {
          tempData[index][name]['category'] = catArray[0]['category']
        } else {
          tempData[index][name]['category'] = ''
        }
      }
      setdegreeData([...tempData])
      // console.log('degree data', degreeData);
    } else if (varName === 'trainingData') {
      tempData = trainingData
      tempData[index][name]['value'] = value
      tempData[index][name][name] = ''
      if (name == 'yCompleted' || name == 'yStarted') {
        tempData[index]['yCompleted']['validation'] = [
          'blank',
          { compareDate: { startDate: tempData[index]['yStarted']['value'] } },
        ]
      }
      settrainingData([...tempData])
    }
  }

  const DeleteElement = (elementName, index) => {
    let tempData = []
    if (elementName === 'medicalData') {
      tempData = medicalData
      tempData[index] && tempData.splice(index, 1)
      setmedicalData([...tempData])
      modifyCredCategory()
    } else if (elementName === 'degreeData') {
      tempData = degreeData
      tempData[index] && tempData.splice(index, 1)
      setdegreeData([...tempData])
    } else if (elementName === 'trainingData') {
      tempData = trainingData
      tempData[index] && tempData.splice(index, 1)
      settrainingData([...tempData])
    }
  }
  const DeleteSection = (props) => {
    return (
      <>
        <div
          onClick={() => DeleteElement(props.name, props.index)}
          className="delete-icon"
          alt="Delete"
        ></div>
      </>
    )
  }

  const addDegreeFields = (e) => {
    e.preventDefault()
    const tempData = degreeData
    tempData.push({
      pType: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
        category: '',
      },
      sName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
        category: '',
      },
      yStarted: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: [{ compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    })
    setdegreeData([...tempData])
  }

  const addTrainingFields = (e) => {
    e.preventDefault()
    const tempData = trainingData
    tempData.push({
      pType: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      pName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'pName',
      },
      sName: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: [],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: [{ compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    })

    settrainingData([...tempData])
  }

  const formSubmit = async () => {
    let tempMedicalData = medicalData
    let flag = true
    let setFocus = false
    let medicalSpeciality = [],
      degreeDetails = [],
      trainingDetails = []

    if (errors.patientTreated) {
      document.querySelectorAll('[data-name="medicalCredential"]')[0].focus()
      flag = false
      return
    }

    medicalData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)
      let ms = {
        medicalCredential: currentValue.medicalCredential.value,
        medicalSpeciality: currentValue.medicalSpecialty.value,
        sortOrder: index + 1,
      }
      medicalSpeciality.push(ms)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          tempMedicalData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            let element = document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .focus()
          }
        }
        setmedicalData([...tempMedicalData])
      }
      // console.log('medical current value ', medicalSpeciality);
    })

    let tempdegreeData = degreeData
    degreeData.forEach((currentValue, index) => {
      let degData = {
        educationType: currentValue.pType.value,
        schoolName: currentValue.sName.value,
        yearStarted: currentValue.yStarted.value,
        yearCompleted: currentValue.yCompleted.value,
        sortOrder: index + 1,
      }
      degreeDetails.push(degData)
      let validationResult = InputValidation(currentValue)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          tempdegreeData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            let element = document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .focus()
          }
        }
        setdegreeData([...tempdegreeData])
      } else {
        if (currentValue.yStarted.value > currentValue.yCompleted.value) {
          temptrainingData[index]['yCompleted']['yCompleted'] =
            'Completed year should not be less then started year'
          setdegreeData([...tempdegreeData])
        }
      }
    })

    let temptrainingData = trainingData
    temptrainingData.forEach((currentValue, index) => {
      let tData = {
        educationType: currentValue.pType.value,
        otherSchoolName: currentValue.sName.value,
        programName: currentValue.pName.value,
        yearStarted: currentValue.yStarted.value,
        yearCompleted: currentValue.yCompleted.value,
        sortOrder: index + 1,
      }
      trainingDetails.push(tData)
      let validationResult = InputValidation(currentValue)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          temptrainingData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            let element = document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .focus()
          }
        }
        settrainingData([...temptrainingData])
      } else {
        if (currentValue.yStarted.value > currentValue.yCompleted.value) {
          temptrainingData[index]['yCompleted']['yCompleted'] =
            'Completed year should not be less then started year'
          settrainingData([...temptrainingData])
        }
      }
    })

    let validationResultOtherData = InputValidation(otherData)
    if (Object.keys(validationResultOtherData).length > 0) {
      flag = false
      setErrors({ ...errors, ...validationResultOtherData })
      if (!flag && !setFocus) {
        setFocus = true
        if (errors.area) {
          document.querySelectorAll(`[name="area"]`)[0].focus()
        }
        if (errors.patientTreated) {
          document.querySelectorAll(`[name="patientTreated"]`)[0].focus()
        }
      }
    }

    if (flag) {
      authContext.setLoader()

      const fData = {
        patientTreated: otherData.patientTreated.value.toString(),
        medicalSpeciality: medicalSpeciality,
        degreeDetails: degreeDetails,
        trainingDetails: trainingDetails,
        languageSpoken: otherData.language.value,
      }

      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/signup/medical-speciality',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        let user = result.body.result

        authContext.setUser(user)
        history.push({
          pathname: 'practice-information',
        })
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }
  const preFilledOtherData = (medicalSpeciality) => {
    let focus = []

    medicalSpeciality.providerMedicalFocusAreas.map((d, i) => {
      focus.push(d.focusName)
    })
    // let tempOD = {
    //   area: {
    //       value: focus,
    //       validation: ['arrayempty'],
    //       errorMsg: areaerrorMsg,
    //       errorKey: 'earea'
    //   },
    //   patientTreated : {
    //       value: medicalSpeciality.patientTreated ? medicalSpeciality.patientTreated.split(',') : [],
    //       validation: ['arrayempty'],
    //       errorMsg: patientTreatederrorMsg,
    //       errorKey: 'epatientTreated',
    //   },
    //   language: {
    //       value: medicalSpeciality.languagesSpoken ? medicalSpeciality.languagesSpoken: [],
    //       validation: ['arrayempty'],
    //       errorMsg: languageerrorMsg,
    //       errorKey: 'elanguage',
    //   }
    // }
    setotherData({
      ...{
        area: {
          value: focus,
          validation: ['arrayempty'],
          errorMsg: areaerrorMsg,
          errorKey: 'earea',
        },
        patientTreated: {
          value: medicalSpeciality.patientTreated
            ? medicalSpeciality.patientTreated.split(',')
            : [],
          validation: ['arrayempty'],
          errorMsg: patientTreatederrorMsg,
          errorKey: 'epatientTreated',
        },
        language: {
          value: medicalSpeciality.languagesSpoken
            ? medicalSpeciality.languagesSpoken
            : [],
          validation: ['arrayempty'],
          errorMsg: languageerrorMsg,
          errorKey: 'elanguage',
        },
      },
    })
  }
  const preFilledMedicalData = (medicalSpeciality) => {
    if (medicalSpeciality.providerMedicalSpecialities) {
      let tempMedicalData = []
      medicalSpeciality.providerMedicalSpecialities.map((d, i) => {
        tempMedicalData.push({
          medicalCredential: {
            value: d.medicalCredential,
            validation: ['blank'],
            errorMsg: ['This field is required'],
            category: d.specialties.category ? d.specialties.category : '',
            errorKey: 'medicalCredential',
          },
          medicalSpecialty: {
            value: d.medicalSpeciality,
            validation: ['blank'],
            errorMsg: ['This field is required'],
            errorKey: 'medicalSpecialty',
          },
        })
      })
      //  console.log('tempMedicalData', tempMedicalData);
      setmedicalData([...tempMedicalData])
    }
  }
  const preFilledDegreeData = (medicalSpeciality) => {
    if (medicalSpeciality.providerDegreeDetails) {
      let tempDegreeData = []
      medicalSpeciality.providerDegreeDetails.map((d, i) => {
        tempDegreeData.push({
          pType: {
            value: d.educationType ? d.educationType : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
          },
          sName: {
            value: d.schoolName ? d.schoolName : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
          },
          yStarted: {
            value: d.yearStarted ? d.yearStarted : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
          },
          yCompleted: {
            value: d.yearCompleted ? d.yearCompleted : '',
            validation: [
              {
                compareDate: { startDate: d.yearStarted ? d.yearStarted : '' },
              },
            ],
            errorMsg: [
              'This field is required',
              'Completed year should not be less than started year',
            ],
            errorKey: 'yCompleted',
          },
        })
      })
      //  console.log('tempDegreeData', tempDegreeData);
      setdegreeData([...tempDegreeData])
    }
  }

  const preFilledTrainingData = (medicalSpeciality) => {
    if (medicalSpeciality.providerTrainingDetails) {
      let tempTrainingData = []
      medicalSpeciality.providerTrainingDetails.map((d, i) => {
        tempTrainingData.push({
          pType: {
            value: d.educationType ? d.educationType : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'pType',
          },
          sName: {
            value: d.schoolName ? d.schoolName : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'sName',
          },
          pName: {
            value: d.programName ? d.programName : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'pName',
          },
          yStarted: {
            value: d.yearStarted ? d.yearStarted : '',
            validation: [],
            errorMsg: ['This field is required'],
            errorKey: 'yStarted',
          },
          yCompleted: {
            value: d.yearCompleted ? d.yearCompleted : '',
            validation: [
              {
                compareDate: { startDate: d.yearStarted ? d.yearStarted : '' },
              },
            ],
            errorMsg: [
              'This field is required',
              'Completed year should not be less than started year',
            ],
            errorKey: 'yCompleted',
          },
        })
      })
      //  console.log('tempTrainingData', tempTrainingData);
      settrainingData([...tempTrainingData])
    }
  }

  const getApiData = async () => {
    let medicalCred = await Global.getMedicalCredentials()
    // console.log('medical cred', medicalCred);
    setmedicalCredentials(medicalCred)

    let medicalSpec = await Global.getMedicalSpeciality()
    // console.log('medical speciality',medicalSpec );
    setmedicalSpeciality(medicalSpec)

    let progTp = await Global.getProgrametypes()
    setmedicalprogrametypes(progTp)

    let instList = await Global.getInstitues()
    setmedInstitue(instList)

    let trainingList = await Global.getTraining()
    settrainingList(trainingList)
  }

  useEffect(() => {
    getApiData()
    if (
      authContext.user &&
      authContext.user.medicalSpeciality &&
      Object.keys(authContext.user.medicalSpeciality).length > 0
    ) {
      let medicalSpeciality = authContext.user.medicalSpeciality
      // console.log('medicalSpeciality', JSON.stringify(medicalSpeciality));
      preFilledMedicalData(medicalSpeciality)
      preFilledOtherData(medicalSpeciality)
      preFilledDegreeData(medicalSpeciality)
      preFilledTrainingData(medicalSpeciality)
    }
  }, [])

  useEffect(() => {
    async function getLanData() {
      let data = await Global.getLangList()
      if (data.length > 0) {
        setLanguageList(data)
      }
    }
    getLanData()
  }, [])

  return (
    <Fragment>
      <ProviderStep step="2" />
      {/* Form start step 2 */}

      <form>
        <Container className="provider-form-inner">
          <Row>
            {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          </Row>
          <Row>
            <div className="col-md-12">
              <label htmlFor="gender" className="pro-label">
                Patients Treated
              </label>
            </div>
            <div className="col-md-12 mb-4 mb-md-4">
              <div className="check-patient">
                {patientTreatedList.map((d, ind) => (
                  <div
                    className="cus-check-rev"
                    key={d.toLowerCase() + '-main'}
                  >
                    <input
                      type="checkbox"
                      id={d.toLowerCase() + '-' + ind}
                      name="patientTreated"
                      data-name="patientTreated"
                      onClick={() => {
                        setErrors({ ...errors, ...{ patientTreated: false } })
                      }}
                      value={d.toLowerCase()}
                      onChange={(e) => addLangData(e)}
                      checked={
                        otherData.patientTreated.value.indexOf(
                          d.toLowerCase()
                        ) > '-1'
                          ? true
                          : false
                      }
                    />
                    <label htmlFor={d.toLowerCase() + '-' + ind}>{d}</label>
                  </div>
                ))}
              </div>
              <small className="error-input">{errors.patientTreated}</small>
            </div>
          </Row>
          {medicalData.map((data, index) => (
            <Fragment key={'medical-wrapper-' + index}>
              <Row className="position-relative">
                {index > 0 ? (
                  <DeleteSection name="medicalData" index={index} />
                ) : (
                  <></>
                )}
                <div className="col-md-5 mb-3">
                  <label className="pro-label">Medical Credential</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data.medicalCredential.medicalCredential
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data.medicalCredential.value}
                      data-index={index}
                      data-name="medicalCredential"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e)
                      }}
                    >
                      <option value="">Choose</option>
                      {medicalCredentials.map((mc, mci) => (
                        <option key={mc.id + '-' + index} value={mc.id}>
                          {mc.name}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {data.medicalCredential.medicalCredential}
                    </small>
                  </div>
                </div>
                <div className="col-md-7 mb-3">
                  <label className="pro-label">Medical Specialty</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data.medicalSpecialty.medicalSpecialty
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data.medicalSpecialty.value}
                      data-index={index}
                      data-name="medicalSpecialty"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e)
                      }}
                    >
                      <option value="">Choose</option>
                      {medicalSpeciality.map((ms, msi) => (
                        <>
                          {ms.category != '' ? (
                            <>
                              {data.medicalCredential.category ==
                              ms.category ? (
                                <option key={ms.id + '-' + index} value={ms.id}>
                                  {ms.name}
                                </option>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            <>
                              <option key={ms.id + '-' + index} value={ms.id}>
                                {ms.name}
                              </option>
                            </>
                          )}
                        </>
                      ))}
                    </select>
                    <small className="error-input">
                      {data.medicalSpecialty.medicalSpecialty}
                    </small>
                  </div>
                </div>
              </Row>
            </Fragment>
          ))}

          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-add-link">
                <a
                  href="/#"
                  onClick={(e) => {
                    addMedicalFields(e)
                  }}
                >
                  + Add another Medical Specialty
                </a>
              </div>
            </div>
          </Row>
          {degreeData.map((data1, index) => (
            <Fragment key={'degree-wrapper-' + index}>
              <Row>
                <div className="col-md-12 mb-3 mt-3" key={'degreeIcon' + index}>
                  <div className="sub-head-pro-step2 degree-icon">Degree</div>
                </div>
              </Row>
              <Row className="position-relative">
                {index > 0 ? (
                  <DeleteSection name="degreeData" index={index} />
                ) : (
                  <></>
                )}
                <div className="col-md-12 mb-3">
                  <label className="pro-label">Program Type</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data1.pType.pType ? 'select  error-border' : 'select'
                      }
                      value={data1.pType.value}
                      data-index={index}
                      data-name="pType"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'degreeData')
                      }}
                    >
                      <option value="">Program Type</option>
                      {medicalprogrametypes.map((pt, pti) =>
                        medicalCredentialCategory.indexOf(pt.category) >
                        '-1' ? (
                          <option key={pt.id + '-' + index} value={pt.id}>
                            {pt.title}
                          </option>
                        ) : (
                          <></>
                        )
                      )}
                    </select>
                    <small className="error-input">{data1.pType.pType}</small>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="pro-label">
                    School Name or Institution Name
                  </label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data1.sName.sName ? 'select  error-border' : 'select'
                      }
                      value={data1.sName.value}
                      data-index={index}
                      data-name="sName"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'degreeData')
                      }}
                    >
                      <option value="">School Name or Institution Name</option>
                      {medInstitue.map((it, iti) =>
                        medicalCredentialCategory.indexOf(
                          it.category.toLowerCase()
                        ) > '-1' ? (
                          <option key={it.id + '-' + index} value={it.id}>
                            {it.name}
                          </option>
                        ) : (
                          <></>
                        )
                      )}
                    </select>
                    <small className="error-input">{data1.sName.sName}</small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="pro-label">Year Started</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data1.yStarted.yStarted
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data1.yStarted.value}
                      data-index={index}
                      data-name="yStarted"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'degreeData')
                      }}
                    >
                      <option value="">Year Started</option>
                      {degreeStartYears.map((sy, si) => (
                        <option key={'start-' + index + '-' + si} value={sy}>
                          {sy}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {data1.yStarted.yStarted}
                    </small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="pro-label">Year Completed</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data1.yCompleted.yCompleted
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data1.yCompleted.value}
                      data-index={index}
                      data-name="yCompleted"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'degreeData')
                      }}
                    >
                      <option value="">Year Completed</option>
                      {allyears.map((sy, si) => (
                        <option key={'end-' + index + '-' + si} value={sy}>
                          {sy}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {data1.yCompleted.yCompleted}
                    </small>
                  </div>
                </div>
              </Row>
            </Fragment>
          ))}
          <Row>
            <div className="col-md-12 mb-4">
              <div className="form-add-link">
                <a
                  onClick={(e) => {
                    addDegreeFields(e)
                  }}
                  href="\#"
                >
                  + Add another Education
                </a>
              </div>
            </div>
          </Row>

          {trainingData.map((data, index) => (
            <Fragment key={'training-wrapper-' + index}>
              <Row>
                <div
                  className="col-md-12 mb-3 mt-3"
                  key={'trainingIcon' + index}
                >
                  <div className="sub-head-pro-step2 training-icon">
                    Training
                  </div>
                </div>
              </Row>
              <Row className="position-relative">
                {index > 0 ? (
                  <DeleteSection name="trainingData" index={index} />
                ) : (
                  <></>
                )}
                <div className="col-md-12 mb-3">
                  <label className="pro-label">Training Type</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data.pType.pType ? 'select  error-border' : 'select'
                      }
                      value={data.pType.value}
                      data-index={index}
                      data-name="pType"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'trainingData')
                      }}
                    >
                      <option value="">Training Type</option>
                      {trainingList.map((pt, pti) => (
                        <option key={pt.id + '-' + index} value={pt.id}>
                          {pt.title}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">{data.pType.pType}</small>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="pro-label">
                    School Name or Institution Name
                  </label>
                  <input
                    type="text"
                    name="sNumber"
                    className={
                      data.sName.sName ? 'input-pro  error-border' : 'input-pro'
                    }
                    placeholder="School Name or Institution Name"
                    autoComplete="off"
                    data-index={index}
                    data-name="sName"
                    value={data.sName.value}
                    onChange={(e) => {
                      modifyMedicalSpeciality(e, 'trainingData')
                    }}
                  />
                  <small className="error-input">{data.sName.sName}</small>
                </div>
                {/* <div className="col-md-12 mb-3">
                                    <label className="pro-label">School Name or Institution Name</label>
                                    <div className="myform">
                                        <select className={data.sName.sName ? "select  error-border" : "select"} value={data.sName.value} data-index={index} data-name="sName" onChange={(e) => { modifyMedicalSpeciality(e, 'trainingData') }}>
                                            <option value="">School Name or Institution Name</option>
                                            {medInstitue.map((it, iti) =>
                                                <option key={it.id + '-' + index} value={it.id}>{it.name}</option>
                                            )}
                                        </select>
                                        <small className="error-input">{data.sName.sName}</small>
                                    </div>
                                </div> */}
                <div className="col-md-12 mb-3">
                  <label className="pro-label">Program Name</label>
                  <input
                    type="text"
                    name="pNumber"
                    className={
                      data.pName.pName ? 'input-pro  error-border' : 'input-pro'
                    }
                    placeholder="Program Name"
                    autoComplete="off"
                    data-index={index}
                    data-name="pName"
                    value={data.pName.value}
                    onChange={(e) => {
                      modifyMedicalSpeciality(e, 'trainingData')
                    }}
                  />
                  <small className="error-input">{data.pName.pName}</small>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="pro-label">Year Started</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data.yStarted.yStarted
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data.yStarted.value}
                      data-index={index}
                      data-name="yStarted"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'trainingData')
                      }}
                    >
                      <option value="">Year Started</option>
                      {allyears.map((sy, si) => (
                        <option key={'start-' + index + '-' + si} value={sy}>
                          {sy}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {data.yStarted.yStarted}
                    </small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="pro-label">Year Completed</label>
                  <div className="myform">
                    <select
                      required
                      className={
                        data.yCompleted.yCompleted
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={data.yCompleted.value}
                      data-index={index}
                      data-name="yCompleted"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e, 'trainingData')
                      }}
                    >
                      <option value="">Year Completed</option>
                      {allyears.map((sy, si) => (
                        <option key={'end-' + index + '-' + si} value={sy}>
                          {sy}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {data.yCompleted.yCompleted}
                    </small>
                  </div>
                </div>
              </Row>
            </Fragment>
          ))}

          <Row>
            <div className="col-md-12 mb-4">
              <div className="form-add-link">
                <a
                  onClick={(e) => {
                    addTrainingFields(e)
                  }}
                  href="/#"
                >
                  + Add another Residency, Fellowship, Internship
                </a>
              </div>
            </div>
          </Row>
          <Row>
            {/* Language head */}
            <div className="col-md-12 mb-4 mt-3 mt-md-0">
              <div className="sub-head-pro-step2 language-icon">
                Languages Spoken
              </div>
            </div>
            <div className="col-md-12 mb-3 mb-md-4">
              <div className="language-wrap-step2">
                {languageList.map((d, ind) => (
                  <div className="cus-check-rev" key={uuidv4()}>
                    <input
                      type="checkbox"
                      id={d.toLowerCase() + '-' + ind}
                      name="language"
                      data-name="language"
                      key={d.toLowerCase() + '-' + ind}
                      value={d.toLowerCase()}
                      onChange={(e) => addLangData(e)}
                      onClick={() => {
                        setErrors({ ...errors, ...{ language: false } })
                      }}
                      checked={
                        otherData.language.value.indexOf(d.toLowerCase()) > '-1'
                          ? true
                          : false
                      }
                    />
                    <label htmlFor={d.toLowerCase() + '-' + ind}>{d}</label>
                  </div>
                ))}
              </div>
              <small className="error-input">{errors.language}</small>
            </div>
            <div className="col-md-12 mb-5 text-center">
              <div className="next-prev-btn-pro">
                {/* <a href="/#" data-prev-page="account-information" onClick={(e)=>{ props.goPrevious(e) }}  className="prev-btn-pro">Previous</a>  */}
                <input
                  type="button"
                  className="next-btn-pro"
                  onClick={(e) => {
                    formSubmit()
                  }}
                  value="Next"
                />
              </div>
            </div>
          </Row>
        </Container>
      </form>
    </Fragment>
  )
}

export default ProviderStep2
