import { extendTheme, type ChakraTheme } from '@chakra-ui/react'
import { BOX_SHADOW } from '../reusables'

export const customBreakpoints = {
  base: '0em',
  sm: '22.5em', // Mobile
  md: '52.125em', // iPad Portrait
  lg: '74.625em', // iPad Landscape
  xl: '90em', // MacBook size
  xxl: '120em', // Desktop
}

const theme: Partial<ChakraTheme> = {
  breakpoints: {
    ...customBreakpoints,
  },
  colors: {
    transparent: 'transparent',
    primary: '#077db4',
    'primary-dark': '#09608e',
    black: '#000000',
    white: '#ffffff',
    'text-primary': '#000000',
    'text-secondary': '#606060',
    inactive: '#dfe0e1',
    'text-inactive': '#9f9f9f',
    'border-gray': '#DCDCDC',
    'text-light-gray': '#ABABAB',
    'text-dark-gray': '#545454',
    'secondary-border': '#DCDCDC',
  },
  fontSizes: {
    '3xl': '1.625rem', // 26px
    '4xl': '2.5rem', // 40px
    '5xl': '2.75rem', // 44px
    '6xl': '3.45rem', // 55.2px
  },
  textStyles: {
    h1: {
      fontSize: { base: '3xl', md: '4xl', xl: '4xl', xxl: '6xl' },
      fontWeight: 800,
      lineHeight: { base: '150%', md: '130%' },
      color: 'text-primary',
    },
    h2: {
      fontSize: { base: '1.375rem', md: '1.75rem', xl: '2rem', xxl: '5xl' },
      fontWeight: 800,
      lineHeight: { base: '150%', md: '130%' },
      color: 'text-primary',
    },
    h3: {
      fontSize: { base: 'md', md: 'md', lg: 'xl', xxl: '2xl' },
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-1%',
      color: 'text-primary',
    },
    h4: {
      fontSize: { base: 'md', xl: 'lg' },
      fontWeight: 800,
      lineHeight: { base: '130%', xl: '150%' },
      color: 'text-primary',
    },
    p: {
      fontSize: { md: 'md', lg: 'lg' },
      fontWeight: 400,
      lineHeight: '160%',
      color: 'text-primary',
    },
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  components: {
    Select: {
      baseStyle: {
        transition: 'all 300ms ease',
        textDecoration: 'none',
        border: '1px solid',
        borderColor: 'border-gray',
        letterSpacing: '1px',
        padding: '15px 20px',
        borderRadius: '5px',
        fontWeight: 400,
      },
      variants: {
        primary: {},
        minimal: {
          field: {
            border: 'none',
            letterSpacing: '1px',
            height: '20px',
            color: 'primary',
            padding: '0px',
          },
        },
      },
      defaultProps: {
        variant: 'minimal',
      },
    },
    Input: {
      baseStyle: {
        fontWeight: 500,
      },
      variants: {
        search: {
          field: {
            border: '1px solid',
            borderColor: 'border-gray',
            letterSpacing: '1px',
            padding: '15px 20px',
            borderRadius: '5px',
            height: '64px',
            _placeholder: {
              color: 'border-gray',
            },
          },
        },
      },
      defaultProps: {
        variant: 'search',
      },
    },
    Button: {
      baseStyle: {
        width: '100%',
        padding: '24px 12px',
        fontSize: '16px',
        borderRadius: '5px',
        fontWeight: 500,
        minHeight: '50px',
      },
      variants: {
        primary: {
          color: 'white',
          bg: 'primary',
          _hover: {
            bg: 'primary-dark',
          },
        },
        outline: {
          color: 'primary',
          bg: 'white',
        },
        search: {
          color: 'white',
          bg: 'primary',
          height: '64px',
          _hover: {
            bg: 'primary-dark',
          },
        },
      },
      defaultProps: {
        variant: 'primary',
      },
    },
  },
}

export default extendTheme(theme)
