import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as SchoolIcon } from '../../admin/assets/images/education-grey.svg'
import { ReactComponent as SelectOptionIcon } from '../../admin/assets/images/icon-dropdown.svg'
import { ReactComponent as TrainingIcon } from '../../admin/assets/images/stethoscope-grey.svg'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'

const DeleteSection = (props) => {
  return (
    <>
      <div
        onClick={() => props.deleteElement(props.name, props.index)}
        className="delete-icon"
        alt="Delete"
        style={{ cursor: 'pointer' }}
      ></div>
    </>
  )
}

const MedicalSpecialty = (props) => {
  const startYear = new Date().getFullYear() - 80
  const allYears = Array.from(
    new Array(81),
    (val, index) => index + startYear
  ).reverse()

  const [medicalCredentials, setMedicalCredentials] = useState([])
  const [medicalSpeciality, setMedicalSpeciality] = useState([])
  const [medicalProgramTypes, setMedicalProgramTypes] = useState([])
  const [medInstitute, setMedInstitute] = useState([])
  const [trainingList, setTrainingList] = useState([])

  const [medicalData, setMedicalData] = useState([
    {
      medicalCredential: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        category: '',
        errorKey: 'medicalCredential',
      },
      medicalSpecialty: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalSpecialty',
      },
    },
  ])

  const [degreeData, setDegreeData] = useState([
    {
      pType: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      sName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: ['blank', { compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    },
  ])

  const [trainingData, setTrainingData] = useState([
    {
      pType: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      pName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pName',
      },
      sName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: ['blank', { compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    },
  ])

  const addMedicalFields = (e) => {
    e.preventDefault()
    const tempData = medicalData
    tempData.push({
      medicalCredential: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalCredential',
      },
      medicalSpecialty: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'medicalSpecialty',
      },
    })

    setMedicalData([...tempData])
  }

  const modifyMedicalSpeciality = (e, varName = 'medicalData') => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    if (varName === 'medicalData') {
      tempData = medicalData
      tempData[index][name]['value'] = value
      if (name == 'medicalCredential') {
        let catArray = medicalCredentials.filter((mc) => {
          if (mc.id == value) {
            return true
          }
        })
        if (catArray.length) {
          tempData[index][name]['category'] = catArray[0]['category']
        } else {
          tempData[index][name]['category'] = ''
        }
      }

      tempData[index][name][name] = ''
      setMedicalData([...tempData])
    } else if (varName === 'degreeData') {
      tempData = degreeData
      tempData[index][name]['value'] = value
      tempData[index][name][name] = ''
      if (name == 'yCompleted' || name == 'yStarted') {
        tempData[index]['yCompleted']['validation'] = [
          'blank',
          { compareDate: { startDate: tempData[index]['yStarted']['value'] } },
        ]
      }
      setDegreeData([...tempData])
    } else if (varName === 'trainingData') {
      tempData = trainingData
      tempData[index][name]['value'] = value
      tempData[index][name][name] = ''
      if (name == 'yCompleted' || name == 'yStarted') {
        tempData[index]['yCompleted']['validation'] = [
          'blank',
          { compareDate: { startDate: tempData[index]['yStarted']['value'] } },
        ]
      }
      setTrainingData([...tempData])
    }
  }

  const addDegreeFields = (e) => {
    e.preventDefault()
    const tempData = degreeData
    tempData.push({
      pType: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      sName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: ['blank', { compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    })
    setDegreeData([...tempData])
  }

  const addTrainingFields = (e) => {
    e.preventDefault()
    const tempData = trainingData
    tempData.push({
      pType: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pType',
      },
      pName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'pName',
      },
      sName: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'sName',
      },
      yStarted: {
        value: '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'yStarted',
      },
      yCompleted: {
        value: '',
        validation: ['blank', { compareDate: { startDate: '' } }],
        errorMsg: [
          'This field is required',
          'Completed year should not be less than started year',
        ],
        errorKey: 'yCompleted',
      },
    })

    setTrainingData([...tempData])
  }

  const getApiData = async () => {
    let medicalCred = await Global.getMedicalCredentials()
    setMedicalCredentials(medicalCred)

    let medicalSpec = await Global.getMedicalSpeciality()
    setMedicalSpeciality(medicalSpec)

    let progTp = await Global.getProgrametypes()
    setMedicalProgramTypes(progTp)

    let instList = await Global.getInstitues()
    setMedInstitute(instList)

    let trainingList = await Global.getTraining()
    setTrainingList(trainingList)
  }

  useEffect(() => {
    getApiData()
  }, [])

  const validate = () => {
    let flag = true
    let setFocus = false

    // FORM DATA
    let medicalSpec = []
    // let degreeDetails = []
    // let trainingDetails = []

    let tempMedicalData = medicalData

    medicalData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          tempMedicalData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .scrollIntoView({ block: 'start', behavior: 'smooth' })
          }
        }
        setMedicalData([...tempMedicalData])
      } else {
        let ms = {
          medicalCredential: currentValue.medicalCredential.value,
          medicalSpeciality: currentValue.medicalSpecialty.value,
        }
        medicalSpec.push(ms)
      }
    })

    // let tempdegreeData = degreeData
    // degreeData.forEach((currentValue, index) => {
    //   let degData = {
    //     educationType: currentValue.pType.value,
    //     schoolName: currentValue.sName.value,
    //     yearStarted: currentValue.yStarted.value,
    //     yearCompleted: currentValue.yCompleted.value,
    //   }
    //   degreeDetails.push(degData)
    //   let validationResult = InputValidation(currentValue)
    //
    //   if (Object.keys(validationResult).length > 0) {
    //     flag = false
    //     for (const prop in validationResult) {
    //       tempdegreeData[index][prop][prop] = `${validationResult[prop]}`
    //       if (!flag && !setFocus) {
    //         setFocus = true
    //         document
    //           .querySelectorAll(
    //             `[data-index="${index}"][data-name="${prop}"]`
    //           )[0]
    //           .scrollIntoView({ block: 'start', behavior: 'smooth' })
    //       }
    //     }
    //     setDegreeData([...tempdegreeData])
    //   } else {
    //     if (currentValue.yStarted.value > currentValue.yCompleted.value) {
    //       temptrainingData[index]['yCompleted']['yCompleted'] =
    //         'Completed year should not be less then started year'
    //       setDegreeData([...tempdegreeData])
    //     }
    //   }
    // })

    // let temptrainingData = trainingData
    // temptrainingData.forEach((currentValue, index) => {
    //   let tData = {
    //     educationType: currentValue.pType.value,
    //     schoolName: currentValue.sName.value,
    //     programName: currentValue.pName.value,
    //     yearStarted: currentValue.yStarted.value,
    //     yearCompleted: currentValue.yCompleted.value,
    //   }
    //   trainingDetails.push(tData)
    //   let validationResult = InputValidation(currentValue)
    //
    //   if (Object.keys(validationResult).length > 0) {
    //     flag = false
    //     for (const prop in validationResult) {
    //       temptrainingData[index][prop][prop] = `${validationResult[prop]}`
    //       if (!flag && !setFocus) {
    //         setFocus = true
    //         document
    //           .querySelectorAll(
    //             `[data-index="${index}"][data-name="${prop}"]`
    //           )[0]
    //           .scrollIntoView({ block: 'start', behavior: 'smooth' })
    //       }
    //     }
    //     setTrainingData([...temptrainingData])
    //   } else {
    //     if (currentValue.yStarted.value > currentValue.yCompleted.value) {
    //       temptrainingData[index]['yCompleted']['yCompleted'] =
    //         'Completed year should not be less then started year'
    //       setTrainingData([...temptrainingData])
    //     }
    //   }
    // })

    let specs = []

    medicalSpec.map((s) => {
      const cred = medicalCredentials.find(
        (value) => value.id == s.medicalCredential
      )
      const spec = medicalSpeciality.find(
        (value) => value.id == s.medicalSpeciality
      )
      specs.push({ medicalDegree: cred?.name, providerSpecialty: spec?.name })
    })

    let postData = {
      specialties: specs,
      // specialties: [
      //   {
      // providerSpecialty: medicalSpeciality,
      // medicalDegree: degreeDetails,
      // trainingDetails: trainingDetails,
      // },
      // ],
    }

    props.collectData(postData, { medical: !flag })
  }

  useEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  }, [props.shouldValidate])

  return (
    <Fragment>
      <Row className="table-sort-filter">
        <div className="col-12 col-lg-9">
          <form className="provider-form-edit row mb-3">
            <div className="col-12 mb-1">
              {medicalData?.map((value, index) => (
                <Row
                  className="medical-specility"
                  key={'medical-wrapper-' + index}
                >
                  <div className="col-md-5 mb-1 select-option">
                    <label className="pro-label">Medical Credential</label>
                    <select
                      className={
                        value.medicalCredential.medicalCredential
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={value.medicalCredential.value}
                      data-index={index}
                      data-name="medicalCredential"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e)
                      }}
                    >
                      <option value="">Choose</option>
                      {medicalCredentials.map((mc) => (
                        <option key={mc.id + '-' + index} value={mc.id}>
                          {mc.name}
                        </option>
                      ))}
                    </select>
                    <i className="select-option-icon">
                      <SelectOptionIcon />
                    </i>
                    <small className="error-input">
                      {value.medicalCredential.medicalCredential
                        ? value.medicalCredential.medicalCredential
                        : ''}
                    </small>
                  </div>
                  <div className="col-md-6 mb-1 select-option">
                    <label className="pro-label">Your Medical Speciality</label>
                    <select
                      className={
                        value.medicalSpecialty.medicalSpecialty
                          ? 'select  error-border'
                          : 'select'
                      }
                      value={value.medicalSpecialty.value}
                      data-index={index}
                      data-name="medicalSpecialty"
                      onChange={(e) => {
                        modifyMedicalSpeciality(e)
                      }}
                    >
                      <option value="">Choose</option>
                      {medicalSpeciality?.map((ms) => (
                        <>
                          {ms.category !== '' ? (
                            <>
                              {value.medicalCredential.category ===
                              ms.category ? (
                                <option key={ms.id + '-' + index} value={ms.id}>
                                  {ms.name}
                                </option>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            <>
                              <option key={ms.id + '-' + index} value={ms.id}>
                                {ms.name}
                              </option>
                            </>
                          )}
                        </>
                      ))}
                    </select>
                    <i className="select-option-icon">
                      <SelectOptionIcon />
                    </i>
                    <small className="error-input">
                      {value.medicalSpecialty.medicalSpecialty
                        ? value.medicalSpecialty.medicalSpecialty
                        : ''}
                    </small>
                  </div>
                  {index > 0 ? (
                    <div className="col-md-1 mb-1 select-option">
                      <DeleteSection name="medicalData" index={index} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Row>
              ))}

              <Row className="add-another">
                <div className="col-12 text-right">
                  <Link
                    className="font-13"
                    onClick={(e) => {
                      addMedicalFields(e)
                    }}
                  >
                    + Add Another Medical Specialty
                  </Link>
                </div>
              </Row>
            </div>

            {/*{degreeData.map((data1, index) => (*/}
            {/*  <div key={index} className="col-12 mb-4">*/}
            {/*    <div className="blue-area">*/}
            {/*      <div className="col-11 blue-head mb-3 mt-3">*/}
            {/*        <i className="blue-icon pr-3">*/}
            {/*          <TrainingIcon />*/}
            {/*        </i>*/}
            {/*        SCHOOL*/}
            {/*      </div>*/}
            {/*      <div className="col-1 blue-head mb-3 mt-3">*/}
            {/*        {index > 0 ? (*/}
            {/*          <DeleteSection name="degreeData" index={index} />*/}
            {/*        ) : (*/}
            {/*          <></>*/}
            {/*        )}*/}
            {/*      </div>*/}
            {/*      <div className="col-12 select-option mb-3">*/}
            {/*        <label className="pro-label">Program Type</label>*/}
            {/*        <select*/}
            {/*          className={*/}
            {/*            data1.pType.pType ? 'select  error-border' : 'select'*/}
            {/*          }*/}
            {/*          value={data1.pType.value}*/}
            {/*          data-index={index}*/}
            {/*          data-name="pType"*/}
            {/*          onChange={(e) => {*/}
            {/*            modifyMedicalSpeciality(e, 'degreeData')*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <option value="">Program Type</option>*/}
            {/*          {medicalProgramTypes?.map((pt) => (*/}
            {/*            <option key={pt.id + '-' + index} value={pt.id}>*/}
            {/*              {pt.title}*/}
            {/*            </option>*/}
            {/*          ))}*/}
            {/*        </select>*/}
            {/*        <small className="error-input">{data1.pType.pType}</small>*/}
            {/*        <i className="select-option-icon">*/}
            {/*          <SelectOptionIcon />*/}
            {/*        </i>*/}
            {/*      </div>*/}
            {/*      <div className="col-12 select-option mb-3">*/}
            {/*        <label className="pro-label">*/}
            {/*          School Name or Institution Name*/}
            {/*        </label>*/}
            {/*        <select*/}
            {/*          className={*/}
            {/*            data1.sName.sName ? 'select  error-border' : 'select'*/}
            {/*          }*/}
            {/*          value={data1.sName.value}*/}
            {/*          data-index={index}*/}
            {/*          data-name="sName"*/}
            {/*          onChange={(e) => {*/}
            {/*            modifyMedicalSpeciality(e, 'degreeData')*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <option value="">School Name or Institution Name</option>*/}
            {/*          {medInstitute.map((it) => (*/}
            {/*            <option key={it.id + '-' + index} value={it.id}>*/}
            {/*              {it.name}*/}
            {/*            </option>*/}
            {/*          ))}*/}
            {/*        </select>*/}
            {/*        <small className="error-input">{data1.sName.sName}</small>*/}
            {/*        <i className="select-option-icon">*/}
            {/*          <SelectOptionIcon />*/}
            {/*        </i>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-4 select-option mb-3">*/}
            {/*        <label className="pro-label">Year Started</label>*/}
            {/*        <select*/}
            {/*          className={*/}
            {/*            data1.yStarted.yStarted*/}
            {/*              ? 'select  error-border'*/}
            {/*              : 'select'*/}
            {/*          }*/}
            {/*          value={data1.yStarted.value}*/}
            {/*          data-index={index}*/}
            {/*          data-name="yStarted"*/}
            {/*          onChange={(e) => {*/}
            {/*            modifyMedicalSpeciality(e, 'degreeData')*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <option value="">Year Started</option>*/}
            {/*          {allYears.map((sy, si) => (*/}
            {/*            <option key={'start-' + index + '-' + si} value={sy}>*/}
            {/*              {sy}*/}
            {/*            </option>*/}
            {/*          ))}*/}
            {/*        </select>*/}
            {/*        <small className="error-input">*/}
            {/*          {data1.yStarted.yStarted}*/}
            {/*        </small>*/}
            {/*        <i className="select-option-icon">*/}
            {/*          <SelectOptionIcon />*/}
            {/*        </i>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-4 select-option mb-3">*/}
            {/*        <label className="pro-label">Year Completed</label>*/}
            {/*        <select*/}
            {/*          className={*/}
            {/*            data1.yCompleted.yCompleted*/}
            {/*              ? 'select  error-border'*/}
            {/*              : 'select'*/}
            {/*          }*/}
            {/*          value={data1.yCompleted.value}*/}
            {/*          data-index={index}*/}
            {/*          data-name="yCompleted"*/}
            {/*          onChange={(e) => {*/}
            {/*            modifyMedicalSpeciality(e, 'degreeData')*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          <option value="">Year Completed</option>*/}
            {/*          {allYears.map((sy, si) => (*/}
            {/*            <option key={'end-' + index + '-' + si} value={sy}>*/}
            {/*              {sy}*/}
            {/*            </option>*/}
            {/*          ))}*/}
            {/*        </select>*/}
            {/*        <small className="error-input">*/}
            {/*          {data1.yCompleted.yCompleted}*/}
            {/*        </small>*/}
            {/*        <i className="select-option-icon">*/}
            {/*          <SelectOptionIcon />*/}
            {/*        </i>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*))}*/}

            {/*<div className="add-anither col-12 text-right">*/}
            {/*  <Link*/}
            {/*    className="font-13"*/}
            {/*    onClick={(e) => {*/}
            {/*      addDegreeFields(e)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    + Add another Education*/}
            {/*  </Link>*/}
            {/*</div>*/}

            {/*{trainingData &&*/}
            {/*  trainingData.map((data, index) => (*/}
            {/*    <div key={index} className="col-12 mb-1">*/}
            {/*      <div className="blue-area">*/}
            {/*        <div className="col-11 blue-head mb-3 mt-3">*/}
            {/*          <i className="school-icon pr-3">*/}
            {/*            <SchoolIcon />*/}
            {/*          </i>*/}
            {/*          TRAINING*/}
            {/*        </div>*/}
            {/*        <div className="col-1 blue-head mb-3 mt-3">*/}
            {/*          {index > 0 ? (*/}
            {/*            <DeleteSection name="trainingData" index={index} />*/}
            {/*          ) : (*/}
            {/*            <></>*/}
            {/*          )}*/}
            {/*        </div>*/}

            {/*        <div className="col-12 select-option mb-3">*/}
            {/*          <label className="pro-label">Education Type</label>*/}
            {/*          <select*/}
            {/*            className={*/}
            {/*              data.pType.pType ? 'select  error-border' : 'select'*/}
            {/*            }*/}
            {/*            value={data.pType.value}*/}
            {/*            data-index={index}*/}
            {/*            data-name="pType"*/}
            {/*            onChange={(e) => {*/}
            {/*              modifyMedicalSpeciality(e, 'trainingData')*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <option value="">Training Type</option>*/}
            {/*            {trainingList.map((pt) => (*/}
            {/*              <option key={pt.id + '-' + index} value={pt.id}>*/}
            {/*                {pt.title}*/}
            {/*              </option>*/}
            {/*            ))}*/}
            {/*          </select>*/}
            {/*          <small className="error-input">{data.pType.pType}</small>*/}
            {/*          <i className="select-option-icon">*/}
            {/*            <SelectOptionIcon />*/}
            {/*          </i>*/}
            {/*        </div>*/}
            {/*        <div className="col-12 select-option mb-3">*/}
            {/*          <label className="pro-label">*/}
            {/*            School Name or Institution Name*/}
            {/*          </label>*/}
            {/*          <select*/}
            {/*            className={*/}
            {/*              data.sName.sName ? 'select  error-border' : 'select'*/}
            {/*            }*/}
            {/*            value={data.sName.value}*/}
            {/*            data-index={index}*/}
            {/*            data-name="sName"*/}
            {/*            onChange={(e) => {*/}
            {/*              modifyMedicalSpeciality(e, 'trainingData')*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <option value="">*/}
            {/*              School Name or Institution Name*/}
            {/*            </option>*/}
            {/*            {medInstitute.map((it) => (*/}
            {/*              <option key={it.id + '-' + index} value={it.id}>*/}
            {/*                {it.name}*/}
            {/*              </option>*/}
            {/*            ))}*/}
            {/*          </select>*/}
            {/*          <small className="error-input">{data.sName.sName}</small>*/}
            {/*          <i className="select-option-icon">*/}
            {/*            <SelectOptionIcon />*/}
            {/*          </i>*/}
            {/*        </div>*/}
            {/*        <div className="col-12 mb-3">*/}
            {/*          <label className="pro-label">Program Name</label>*/}
            {/*          <input*/}
            {/*            type="text"*/}
            {/*            name="pNumber"*/}
            {/*            className={*/}
            {/*              data.pName.pName*/}
            {/*                ? 'input-pro  error-border'*/}
            {/*                : 'input-pro'*/}
            {/*            }*/}
            {/*            placeholder="Program Name"*/}
            {/*            autoComplete="off"*/}
            {/*            data-index={index}*/}
            {/*            data-name="pName"*/}
            {/*            value={data.pName.value}*/}
            {/*            onChange={(e) => {*/}
            {/*              modifyMedicalSpeciality(e, 'trainingData')*/}
            {/*            }}*/}
            {/*          />*/}
            {/*          <small className="error-input">{data.pName.pName}</small>*/}
            {/*        </div>*/}
            {/*        <div className="col-md-4 select-option mb-3">*/}
            {/*          <label className="pro-label">Year Started</label>*/}
            {/*          <select*/}
            {/*            className={*/}
            {/*              data.yStarted.yStarted*/}
            {/*                ? 'select  error-border'*/}
            {/*                : 'select'*/}
            {/*            }*/}
            {/*            value={data.yStarted.value}*/}
            {/*            data-index={index}*/}
            {/*            data-name="yStarted"*/}
            {/*            onChange={(e) => {*/}
            {/*              modifyMedicalSpeciality(e, 'trainingData')*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <option value="">Year Started</option>*/}
            {/*            {allYears.map((sy, si) => (*/}
            {/*              <option key={'start-' + index + '-' + si} value={sy}>*/}
            {/*                {sy}*/}
            {/*              </option>*/}
            {/*            ))}*/}
            {/*          </select>*/}
            {/*          <small className="error-input">*/}
            {/*            {data.yStarted.yStarted}*/}
            {/*          </small>*/}
            {/*          <i className="select-option-icon">*/}
            {/*            <SelectOptionIcon />*/}
            {/*          </i>*/}
            {/*        </div>*/}
            {/*        <div className="col-md-4 select-option mb-3">*/}
            {/*          <label className="pro-label">Year Completed</label>*/}
            {/*          <select*/}
            {/*            className={*/}
            {/*              data.yCompleted.yCompleted*/}
            {/*                ? 'select  error-border'*/}
            {/*                : 'select'*/}
            {/*            }*/}
            {/*            value={data.yCompleted.value}*/}
            {/*            data-index={index}*/}
            {/*            data-name="yCompleted"*/}
            {/*            onChange={(e) => {*/}
            {/*              modifyMedicalSpeciality(e, 'trainingData')*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <option value="">Year Completed</option>*/}
            {/*            {allYears.map((sy, si) => (*/}
            {/*              <option key={'end-' + index + '-' + si} value={sy}>*/}
            {/*                {sy}*/}
            {/*              </option>*/}
            {/*            ))}*/}
            {/*          </select>*/}
            {/*          <small className="error-input">*/}
            {/*            {data.yCompleted.yCompleted}*/}
            {/*          </small>*/}
            {/*          <i className="select-option-icon">*/}
            {/*            <SelectOptionIcon />*/}
            {/*          </i>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  ))}*/}

            {/*<div className="add-anither col-12 text-right">*/}
            {/*  <Link*/}
            {/*    className="font-13"*/}
            {/*    onClick={(e) => {*/}
            {/*      addTrainingFields(e)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    + Add Another residency, fellowship, or internship*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </form>
        </div>
      </Row>
    </Fragment>
  )
}

export default MedicalSpecialty
