import 'bootstrap/dist/css/bootstrap.css'
import moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import Stars from '../../global/ratingreview/stars'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const AppointmentHistory = () => {
  const history = useHistory()
  const [rescheduleModal, setRescheduleModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [apptId, setApptId] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [data, setData] = useState([])
  const authContext = useContext(AuthContext)
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const modalList = ['reviewModal']
  const [profile, setProfile] = useState({})

  const [ratingModalProps, setRatingModalProps] = useState({
    type: '',
    msg: '',
  })

  const getAppointmentHistory = async () => {
    authContext.setLoader()
    // FORM DATA
    const formData = {
      offset: 0,
      limit: 5,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/history/list',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.setAppointmentHistory(result.body.result)
    setData(result.body.result)
    authContext.unsetLoader()
  }

  const saveUnsaveFavDoc = async (value, type) => {
    authContext.setLoader()

    let url = ''
    let isSavedDoctor = 1
    if (type == 'save') {
      url = 'patients/favourite/doctor/add'
      isSavedDoctor = 1
    } else {
      url = 'patients/favourite/doctor/remove'
      isSavedDoctor = 0
    }
    // FORM DATA
    const formData = {
      toUser: value?.providerPracticeLocations?.userId,
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(formData),
    }
    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)

    if (result.body.code == 200) {
      var index = data.findIndex((obj) => obj.id === value.id)
      data[index].providerPracticeLocations.users.isSavedDoctor = isSavedDoctor
      setData(data)
    }

    authContext.unsetLoader()
  }

  function convertToLocalDate(dateString) {
    const date = moment(dateString).toDate()
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }

    const localDateString = date.toLocaleDateString(undefined, options)
    const localTimeString = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    })
    return `${localDateString} ${localTimeString}`
  }

  useEffect(() => {
    getAppointmentHistory()
  }, [])

  useEffect(() => {
    if (activeIndex !== null) {
      setApptId(data[activeIndex]?.id)
    } else {
      setApptId(null)
    }
  }, [activeIndex])

  const openreviewModal = (e, values) => {
    e.preventDefault(e)

    // CREATE DATA RELEVANT TO REVIEW POPUP
    let accountDetails = {
      accountInfo: {
        id: values.providerPracticeLocations.users.id,
        firstName: values.providerPracticeLocations.users.providers.firstName,
        middleName: values.providerPracticeLocations.users.providers.middleName,
        lastName: values.providerPracticeLocations.users.providers.lastName,
        profileImage: values.providerPracticeLocations.users.profileImage,
      },
      medicalSpeciality: {
        providerMedicalSpecialitiesValue:
          values.providerMedicalSpecialitiesValue,
      },
    }
    setProfile(accountDetails)
    openProfileTabModal('reviewModal')
  }
  const openProfileTabModal = (modalName) => {
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const getProfile = () => {
    getAppointmentHistory()
  }
  useEffect(() => {
    const showRatingResult = async () => {
      if (ratingModalProps.msg) {
        if (ratingModalProps.type === 'success') {
          await getAppointmentHistory()
        }
        authContext.hideModal()
        authContext.showResponseModal()
      }
    }
    showRatingResult()
  }, [ratingModalProps])

  function parseDate(dateString) {
    // Define an array of month names to help with parsing
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // Split the date string by commas and spaces to extract date components
    const dateComponents = dateString.match(
      /(\w+), (\w+) (\d+)(?:st|nd|rd|th), (\d+)/
    )

    if (dateComponents) {
      const [, dayOfWeek, month, day, year] = dateComponents
      const monthIndex = monthNames.indexOf(month)

      if (monthIndex !== -1) {
        const parsedDate = new Date(year, monthIndex, day)
        if (
          dayOfWeek ===
          parsedDate.toLocaleDateString('en-US', { weekday: 'long' })
        ) {
          return parsedDate
        }
      }
    }

    return null // Return null for an invalid date
  }

  function isDateInPast(dateString) {
    // Create a Date object from the input string
    const parsedDate = parseDate(dateString)
    // const date = new Date(parseDate)
    // Get the current date
    const currentDate = new Date()
    // Compare the input date to the current date
    if (parsedDate < currentDate) {
      return true // The input date is in the past
    } else {
      return false // The input date is in the future or invalid
    }
  }

  const changeSchedue = async (e, id, status) => {
    e && e.preventDefault()
    authContext.setLoader()
    let fData = { id: id, bookingStatus: status }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/book/status/update',
      body: JSON.stringify(fData),
    }
    let result = await NetworkLayer.postData(submitData)
    if (result.body.code === 200) {
      authContext.unsetLoader()
      if (rescheduleModal) {
        const providerId = data[activeIndex].providerPracticeLocations.users.id
        setRescheduleModal(false)
        history.push({
          pathname: ProviderLinks.profile + '/' + providerId,
        })
      } else {
        getAppointmentHistory()
        setCancelModal(false)
      }
    } else {
      authContext.unsetLoader()
    }
  }

  return (
    <Fragment>
      {authContext.responseModal ? <Msgmodal {...ratingModalProps} /> : <></>}
      {profile.accountInfo &&
      profile.accountInfo.id &&
      modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'reviewModal' ? (
              <DoctorReviewModal
                {...profile}
                getProfile={getProfile}
                setModalProps={setRatingModalProps}
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <Container className="patient-appointment-wrap">
        <Row>
          <div className="col-12">
            <div className="appoint-sub-head-wrap">
              <h2>Past Appointment Requests</h2>
            </div>
          </div>
        </Row>

        <Row>
          <div className="col-12 mb-5">
            <div className="appoint-box">
              {/* heading start */}
              <div className="table-heading-wrap">
                <div></div>
                <div>Appointment Request</div>
                <div>Location</div>
                <div>Leave Feedback</div>
              </div>
              {/* Content start */}
              <div className="appoint-box-content">
                {/* heading start */}
                <div className="table-heading-wrap-device">
                  <div></div>
                  <div>Appointment Request</div>
                  <div>Location</div>
                  <div>Leave Feedback</div>
                </div>
                {/* row 1 */}

                {data.length == 0
                  ? 'No past appointment requests available right now'
                  : data.map((values, key) => (
                      <div className="table-content-appointment" key={key}>
                        <div>
                          <Link
                            to={
                              ProviderLinks.profile +
                              '/' +
                              values.providerPracticeLocations.users.id
                            }
                          >
                            <p>
                              <img
                                className="profile-pic"
                                src={
                                  values.providerPracticeLocations.users
                                    .profileImage
                                }
                              />
                            </p>
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={
                              ProviderLinks.profile +
                              '/' +
                              values.providerPracticeLocations.users.id
                            }
                          >
                            <span className="dr-name">{`${values.providerPracticeLocations.users.providers.firstName} ${values.providerPracticeLocations.users.providers.lastName}`}</span>
                          </Link>
                          <p className="mb-2">
                            <span>
                              {values.providerMedicalSpecialitiesValue}
                            </span>
                          </p>

                          <p>
                            <span className="req-by">
                              {values.appointmentDate
                                ? 'Appointment Time:'
                                : 'Requested Date:'}
                            </span>
                          </p>
                          <p>
                            <span>
                              {values.appointmentDate
                                ? `${values.appointmentDate} ${values.preferAppointmentTime} ${values.providerPracticeLocations.timeZone}`
                                : `Callback requested: ${convertToLocalDate(
                                    values.createdAt
                                  )} - Local time`}
                            </span>
                          </p>
                          {values.appointmentDate &&
                            values.bookingStatus !== 4 &&
                            !isDateInPast(values.appointmentDate) && (
                              <div className="reschedule schedule-icons">
                                <button
                                  onClick={() => {
                                    setActiveIndex(key)
                                    setRescheduleModal(true)
                                  }}
                                >
                                  Reschedule
                                </button>
                                <button
                                  onClick={() => {
                                    setActiveIndex(key)
                                    setCancelModal(true)
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          {values.bookingStatus === 4 && (
                            <div className="cancel-schedule-icons">
                              <span>Cancelled</span>
                            </div>
                          )}
                        </div>
                        <div>
                          <p>
                            <span>
                              {values.providerPracticeLocations.practiceName}
                            </span>
                          </p>
                          <p>
                            <span>
                              {values.providerPracticeLocations.practiceAddress}
                            </span>
                          </p>
                          <p>
                            <span>
                              {values.providerPracticeLocations.phoneNumber}
                            </span>
                          </p>
                          <ul className="doctor-status mt-3">
                            {values.providerPracticeLocations.users
                              .isSavedDoctor == 0 ? (
                              <li
                                className="blank"
                                onClick={() => saveUnsaveFavDoc(values, 'save')}
                              >
                                <img
                                  src="../../assets/images/unsave-icon.svg"
                                  alt="save"
                                  className="scale icon-docsave"
                                />
                                Save Doctor
                              </li>
                            ) : (
                              <li
                                className="blank"
                                onClick={() =>
                                  saveUnsaveFavDoc(values, 'unsave')
                                }
                              >
                                <img
                                  src="../../assets/images/save-icon.png"
                                  alt="save"
                                  className="scale icon-docsave"
                                />
                                Unsave Doctor
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="profile-review-box">
                          <div className="rating" style={{ width: '100%' }}>
                            <Stars
                              rating={`${
                                values.providerPracticeLocations.users
                                  .totalReviewAvg
                                  ? parseFloat(
                                      values.providerPracticeLocations.users
                                        .totalReviewAvg
                                    )
                                  : 0
                              }`}
                              {...values}
                              getProfile={getProfile}
                              componentName={'patient_appointment'}
                              setRatingModalProps={setRatingModalProps}
                            />
                          </div>

                          <div className="rating-result mb-4">
                            <span
                              className={`rating${
                                values.providerPracticeLocations.users
                                  .totalReviewAvg
                                  ? parseFloat(
                                      values.providerPracticeLocations.users
                                        .totalReviewAvg
                                    )
                                  : 0
                              }`}
                            ></span>
                            <span>
                              (
                              {values.providerPracticeLocations.users
                                .totalReviewAvg
                                ? parseFloat(
                                    values.providerPracticeLocations.users
                                      .totalReviewAvg
                                  )
                                : 0}
                              ){' '}
                              {
                                values.providerPracticeLocations.users
                                  .totalReviewCount
                              }{' '}
                              Reviews
                            </span>
                          </div>
                          <div className="write-review">
                            <a
                              href="/#"
                              onClick={(e) => openreviewModal(e, values)}
                            >
                              <span>Write a Review</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          {rescheduleModal && (
            <div className="popup-modal">
              <div className="popup-content">
                <h5 style={{ textAlign: 'center' }}>
                  Are you sure you want to reschedule your appointment?
                </h5>
                <div className="popup-buttons">
                  <button
                    className="popup-cancel"
                    onClick={() => {
                      setRescheduleModal(false)
                      setActiveIndex(null)
                    }}
                  >
                    No - Keep appointment
                  </button>
                  <button
                    onClick={(e) => changeSchedue(e, apptId, 4)}
                    className="popup-confirm"
                  >
                    Yes - Reschedule
                  </button>
                </div>
              </div>
            </div>
          )}
          {cancelModal && (
            <div className="popup-modal">
              <div className="popup-content">
                <h5 style={{ textAlign: 'center' }}>
                  Are you sure you want to cancel your appointment?
                </h5>
                <div className="popup-buttons">
                  <button
                    className="popup-cancel"
                    onClick={() => {
                      setActiveIndex(null)
                      setCancelModal(false)
                    }}
                  >
                    No - Keep appointment
                  </button>
                  <button
                    onClick={(e) => changeSchedue(e, apptId, 4)}
                    className="popup-confirm"
                  >
                    Yes - Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </Fragment>
  )
}

export default AppointmentHistory
