import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ProviderLinks } from '../../linkFile'

const Doctorprofile = (props) => {
  const profileImage =
    props.profileImage ||
    props.accountInfo?.profileImage ||
    '/assets/images/top-doc-img.png'
  return (
    <Fragment>
      <div className="provider-detail-box">
        <img
          src="/assets/images/edit-icon.svg"
          className="edit-doc-profile"
          alt="Edit Profile"
        />
        <div className="provider-doc-img">
          <img src={profileImage} alt="Doctor" />
        </div>
        {props.accountInfo ? (
          <>
            <Link to={ProviderLinks.profile + '/' + props?.accountInfo?.id}>
              <h5 style={{ textTransform: 'capitalize' }}>
                {props?.accountInfo?.firstName
                  ? props.accountInfo.firstName + ' '
                  : ''}
                {props?.accountInfo?.middleName
                  ? props.accountInfo.middleName + ' '
                  : ''}
                {props?.accountInfo?.lastName ? props.accountInfo.lastName : ''}
                {props?.medicalSpeciality?.providerMedicalCredientialsValue
                  ? ', ' +
                    props.medicalSpeciality.providerMedicalCredientialsValue
                  : ''}
                <span>Verified</span>
              </h5>
            </Link>
            <div className="provider-designation 1">
              {props?.medicalSpeciality?.providerMedicalSpecialitiesValue
                ? props.medicalSpeciality.providerMedicalSpecialitiesValue
                : ''}
            </div>
            <div className="endorsements">
              <span>
                {props?.endorsementCount ? props.endorsementCount : 0} Doctor
                endorsements
              </span>
            </div>
            <div className="rating-result">
              <span className="rating view">
                <span
                  className={
                    props?.ratingReviewsAvg
                      ? `rating${parseFloat(props.ratingReviewsAvg).toFixed(1)}`
                      : 'rating0'
                  }
                ></span>
              </span>
              <span>
                (
                {props?.ratingReviewsAvg
                  ? parseFloat(props?.ratingReviewsAvg).toFixed(1)
                  : 0}
                ) {props.ratingReviewsCount ? props.ratingReviewsCount : 0}{' '}
                Reviews
              </span>
            </div>
          </>
        ) : (
          <>
            <Link to={ProviderLinks.profile + '/' + props?.id}>
              <h5 style={{ textTransform: 'capitalize' }}>
                {props?.providers?.firstName
                  ? props.providers.firstName + ' '
                  : ''}
                {props?.providers?.middleName
                  ? props.providers.middleName + ' '
                  : ''}
                {props?.providers?.lastName ? props.providers.lastName : ''}
                {props?.providerMedicalCredientialsValue
                  ? ', ' + props.providerMedicalCredientialsValue
                  : ''}
                <span>Verified</span>
              </h5>
            </Link>
            <div className="provider-designation 2">
              {props?.providerMedicalSpecialitiesValue
                ? props.providerMedicalSpecialitiesValue
                : ''}
            </div>
            <div className="endorsements">
              <span>
                {props?.totalEndorsementCount ? props.totalEndorsementCount : 0}{' '}
                Doctor endorsements
              </span>
            </div>
            <div className="rating-result">
              <span className="stars-outer" style={{ height: '25px' }}>
                {[1, 2, 3, 4, 5].map((starNumber, key) => {
                  return (
                    <span
                      key={key}
                      className={
                        props?.totalReviewAvg
                          ? parseFloat(props.totalReviewAvg).toFixed(1) >=
                            starNumber
                            ? 'full star'
                            : 'star'
                          : 'start'
                      }
                      name="rating"
                    ></span>
                  )
                })}
              </span>
              <span>
                (
                {props?.totalReviewAvg
                  ? parseFloat(props?.totalReviewAvg).toFixed(1)
                  : 0}
                ) {props?.totalReviewCount ? props.totalReviewCount : 0} Reviews
              </span>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default Doctorprofile
