import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import MsgmodalTwo from '../../global/modal-response-msg/MsgResponseModalTwo'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const EditCard = (props) => {
  const authContext = useContext(AuthContext)
  let cardDeleteId = 0
  const history = useHistory()
  const [cardList, setcardList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [cardId, setCardId] = useState('')
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const subPlan = Number(
    props.upgradeDetails
      ? props.upgradeDetails.planTypeId
      : authContext.user.providerCurrentPlan.planTypeId
  )
  const userSubPlan = Number(authContext.user.providerCurrentPlan.planTypeId)

  const getCards = async () => {
    authContext.setLoader()
    let url = 'providers/credit-card/list'
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
    }
    let result = await NetworkLayer.getRequest(submitData)
    authContext.unsetLoader()
    if (result.status === true) {
      if (authContext.user.creditCardError === true) {
        setCardId(result.result.find((x) => x.isDefault === 1).id)
      }
      setcardList([...result.result])
    }
  }

  const setDefault = async (e, id) => {
    // e && e.preventDefault();
    authContext.setLoader()
    let url = 'providers/credit-card/default/update'
    let formData = { id: id, isDefault: 1 }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(formData),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.unsetLoader()
      authContext.showResponseModal()
    } else {
      authContext.unsetLoader()
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }
  const deleteCard = async () => {
    authContext.setLoader()
    let url = 'providers/credit-card/delete'
    let formData = { id: cardDeleteId }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(formData),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      getCards()
      authContext.unsetLoader()
      authContext.showResponseModal()
    } else {
      authContext.unsetLoader()
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const confirmModal = (e, cardId) => {
    e.preventDefault()
    cardDeleteId = cardId
    setmodalProps({
      type: 'question',
      msg: 'Do you really want to delete this card?',
      btnname: 'Delete Card',
      redirection: '',
      action: deleteCard,
      btncancel: 'Cancel',
      autoclose: 'false',
      show: true,
      changeShow: setShowModal,
    })
    setShowModal(true)
  }
  const upgradePlan = async () => {
    authContext.setLoader()

    let url = 'providers/plan/update'
    let formData = {
      planId: props.planId,
      planTypeId: props.upgradeDetails.planTypeId,
    }
    if (cardId) {
      formData.card_id = cardId
    }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(formData),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.status) {
      let user = result.body.result
      authContext.setUser(user)
      setmodalProps({
        type: 'success',
        msg: result.body.message,
        redirecturl: ProviderLinks.accountprofile,
      })
      authContext.unsetLoader()
      authContext.showResponseModal()
      setTimeout(() => {
        history.push({
          pathname:
            '/provider/payment-receipt/' +
            user.providerCurrentPlan.purchase_plan_id,
        })
      }, 1000)
    } else {
      authContext.unsetLoader()
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }
  const redirectTOSub = (e) => {
    e && e.preventDefault()
    history.push({
      pathname: ProviderLinks.accountprofile,
    })
  }
  useEffect(() => {
    getCards()
    return () => {
      authContext.hideResponseModal()
    }
  }, [])

  return (
    <Fragment>
      <Suspense>
        <Container>
          <Row>
            {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
            {showModal ? <MsgmodalTwo {...modalProps} /> : <></>}
            <div className="card-detail-outer">
              <div className="card-detail-wrap">
                <div className="card-detail-inner">
                  <div className="card-detail-table">
                    <div className="card-table-head">Default</div>
                    <div className="card-table-head">Credit Card Number</div>
                    <div className="card-table-head">Cardholder Name</div>
                    <div className="card-table-head">Expiration Date</div>
                  </div>
                  {cardList.map((card, i) => (
                    <div key={i} className="card-detail-table">
                      <div className="card-table-body">
                        <div className="cus-radio">
                          <input
                            type="radio"
                            onChange={(e) =>
                              authContext.user.creditCardError === true
                                ? setCardId(card.id)
                                : setDefault(e, card.id)
                            }
                            id={'card-' + card.id}
                            name="card"
                            defaultChecked={card.isDefault ? true : false}
                          />
                          <label htmlFor={'card-' + card.id}></label>
                        </div>
                      </div>
                      <div className="card-table-body">
                        {card.cardType ? card.cardType : 'Card'} ending in{' '}
                        {card.cardNumber.substr(card.cardNumber.length - 4)}
                        <span>
                          This is the card used for you DocScheduler membership
                        </span>
                      </div>
                      <div className="card-table-body">
                        {card.cardHolderName}
                      </div>
                      <div className="card-table-body">{card.expiration}</div>
                      <div className="card-table-body">
                        <div className="dlt-edit-link">
                          <a
                            href="\#"
                            onClick={(e) => confirmModal(e, card.id)}
                          >
                            Delete Card
                          </a>
                          <small>|</small>
                          <Link
                            to={
                              ProviderLinks.accountprofile +
                              '/manage-card/' +
                              card.id
                            }
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* second row */}
                </div>
              </div>
              <div className="add-link-card-detail">
                <div className="add-link">
                  <Link to={ProviderLinks.accountprofile + '/manage-card'}>
                    + Add New Card
                  </Link>
                </div>
              </div>
              {props.planId && cardList.length ? (
                <>
                  <div className="card-buttons">
                    <input
                      type="button"
                      className="rev-btn-pro"
                      onClick={(e) => redirectTOSub(e)}
                      value="Keep Current Plan"
                    />
                    {userSubPlan !== subPlan && (
                      <input
                        type="button"
                        className="next-btn-pro"
                        onClick={(e) => upgradePlan(e)}
                        value={
                          userSubPlan < subPlan ? `Upgrade Plan` : `Change Plan`
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </Suspense>
    </Fragment>
  )
}

export default EditCard
