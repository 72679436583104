import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { LeftIcon, RightIcon } from '../../Icons/icons'
import useLocationMod from '../../hooks/useLocationMod'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import { useGetSearchParams } from '../../new/lib/hooks/useGetSearchParams'
import { generateURLParams } from '../../new/lib/utils'
import AuthContext from '../../reactLayer/auth/AuthContext'
import { useChipInputValues } from '../../reactLayer/contextStore/homepageStore'

const slidesToShow = 4

const MedicalFocusArea = () => {
  const sliderRef = useRef(null)
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const locationMod = useLocationMod()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { insuranceInput, cityInput, zipInput } = useChipInputValues()
  const [focusareaList, setFocusareaList] = useState([])
  const query = useGetSearchParams()

  const goToPrevious = () => {
    sliderRef.current.slickPrev()
    setCurrentSlide((prev) => Math.max(prev - 1, 0))
  }

  const goToNext = () => {
    sliderRef.current.slickNext()
    setCurrentSlide((prev) =>
      Math.min(prev + 1, focusareaList.length - slidesToShow)
    )
  }

  const redirectToSearchResult = useCallback(
    (event, each) => {
      const path =
        authContext.user &&
        authContext?.user?.isProfileCompleted &&
        authContext.user.accountInfo.roleId === 3
          ? ProviderLinks.search_result
          : PatientLinks.doctor_search_result
      history.push({
        pathname: path,
        search: generateURLParams({
          ...query,
          insurance: insuranceInput,
          zip: zipInput,
          city: cityInput,
          focusArea: each,
        }),
        state: {
          find: '',
          insurance_Name: [],
          focusAreas: each,
          currentLocation: true,
        },
      })
      locationMod.location.state = {
        find: '',
        insurance_Name: [],
        focusAreas: each,
      }
    },
    [history, locationMod, query]
  )

  useEffect(() => {
    const data = [
      {
        image:
          '/assets/images/category/Search_for_Primary_Care_Doctors_near_me.webp',
        id: 1,
        title: 'Primary Care',
        altText: 'Search for Primary Care Doctors near me',
        category: null,
      },
      {
        image: '/assets/images/category/Search_for_OB_GYN_Doctors_near_me.webp',
        id: 2,
        title: "Women's Health",
        altText: 'Search for OB/GYN Doctors near me',
        category: null,
      },
      {
        image: '/assets/images/category/Search_for_Dentist_near_me.webp',
        id: 3,
        title: 'Oral Health',
        altText: 'Search for Dentist near me',
        category: null,
      },
      {
        image: '/assets/images/category/Search_for_Pediatricians _near_me.webp',
        id: 5,
        title: 'Pediatrics',
        altText: 'Search for Pediatricians near me',
        category: null,
      },
      {
        image: '/assets/images/category/Search_for_Eye_Doctors_near_me.webp',
        id: 4,
        title: 'Eye Health',
        altText: 'Search for Eye Doctors near me',
        category: null,
      },
      {
        image: '/assets/images/category/Search_for_Dermatologist_near_me.webp',
        id: 6,
        title: 'Dermatology',
        altText: 'Search for Dermatologist near me',
        category: null,
      },
      {
        image:
          '/assets/images/category/Search_for_Mental_Health_doctor_near_me.webp',
        id: 7,
        title: 'Mental Health',
        altText: 'Search for Mental Health doctor near me',
        category: null,
      },
      {
        image:
          '/assets/images/category/Search_for_Cosmetic_Surgery _doctor_near_me.webp',
        id: 8,
        title: 'Aesthetic Medicine',
        altText: 'Search for Cosmetic Surgery doctor near me',
        category: null,
      },
    ]
    setFocusareaList(data)
  }, [])

  const settings = {
    dots: false,
    draggable: false,
    infinite: false,
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  }

  return (
    <div className="col-12">
      <div className="specialty-doc-list-wrap">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              marginBottom: '14px',
              fontSize: '24px',
              fontWeight: '700',
            }}
          >
            Commonly scheduled specialties
          </h1>
          <div className="customArrow">
            <button onClick={goToPrevious} disabled={currentSlide === 0}>
              <LeftIcon color={currentSlide === 0 ? '#DCDCDC' : '#077DB4'} />
            </button>
            <button
              onClick={goToNext}
              disabled={currentSlide >= focusareaList.length - slidesToShow}
            >
              <RightIcon
                color={
                  currentSlide >= focusareaList.length - slidesToShow
                    ? '#DCDCDC'
                    : '#077DB4'
                }
              />
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="loadingContainer">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : focusareaList.length > 0 ? (
          <Slider ref={sliderRef} {...settings}>
            {focusareaList.map((each, index) => (
              <div
                key={index}
                className="img-container"
                onClick={(e) => redirectToSearchResult(e, each.title)}
              >
                <img src={each.image} alt={each.altText} className="cat-img" />
                <div className="overlay-cat"></div>
                <div className="cat-overlay-text">
                  <div className="text">{each.title}</div>
                </div>
                <div className="cat-border-box-bottom"></div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="loadingContainer">
            <span>No data found.</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MedicalFocusArea)
