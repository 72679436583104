import React, { useEffect, useState } from 'react'
// @ts-ignore
import ReactCalendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { Link } from 'react-router-dom'
import { addDays } from '../../lib/utils'
import { Box, Flex, Text } from '@chakra-ui/react'
import { CalendarIcon } from '../../style/icons'

interface Props {
  startDate?: Date
  setStartDate: (value: Date) => void
}

export const Calendar: React.FC<Props> = ({ startDate, setStartDate }) => {
  const [showCal, setShowCal] = useState(false)
  const dateSelect = (value: Date) => {
    setShowCal(false)
    setStartDate(value)
  }

  useEffect(() => {
    setStartDate(new Date())
  }, [setStartDate])

  return (
    <Flex position="relative">
      <Link
        onClick={(e) => {
          e.preventDefault()
          setShowCal(!showCal)
        }}
        to=""
        style={{
          display: 'flex',
          gap: '18px',
        }}
      >
        <Flex alignItems="center" gap={2}>
          <CalendarIcon boxSize={8} />
          <Text mb={0}>Calendar</Text>
        </Flex>
      </Link>
      {showCal && (
        <Box
          zIndex="docked"
          sx={{
            '.react-calendar': {
              top: '70px',
            },
          }}
        >
          <ReactCalendar
            onClick={dateSelect}
            onClickDay={dateSelect}
            value={startDate}
            minDate={new Date()}
            maxDate={addDays(undefined, 45)}
            calendarType={'US'}
          />
        </Box>
      )}
    </Flex>
  )
}
