import { Box } from '@chakra-ui/react'

interface Props {
  item: any
  index: number
  onUpdate: (index: string) => void
}

export const InsuranceList: React.FC<Props> = ({ item, index, onUpdate }) => {
  return (
    <Box
      onClick={() => onUpdate(item.name)}
      key={index}
      borderBottom="1px solid"
      borderColor="border-gray"
      py={4}
      cursor="pointer"
      _hover={{ color: 'primary' }}
    >
      {item.name}
    </Box>
  )
}
