import { Box } from '@chakra-ui/react'

interface Props {
  onClick?: () => void
  disabled?: boolean
  children: JSX.Element
}

export const Slot: React.FC<Props> = ({ onClick, disabled, children }) => {
  return (
    <Box
      as="button"
      disabled={disabled}
      onClick={onClick}
      width="100%"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      border="1px solid transparent"
      height="60px"
      px="4px"
      mb="12px"
      borderRadius="4px"
      fontSize="12px"
      fontWeight="700"
      bg="primary"
      color="white"
      _hover={{ bg: '#ebedf0' }}
      _focus={{
        boxShadow:
          '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
      }}
      _disabled={{
        bg: 'inactive',
        color: 'text-inactive',
      }}
    >
      {children}
    </Box>
  )
}
