export const personalInfoInitialData = {
  firstName: {
    value: null,
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'firstName',
  },
  lastName: {
    value: null,
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'lastName',
  },
  email: {
    value: null,
    validation: [],
  },
  gender: {
    value: null,
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'gender',
  },
  phoneNumber: {
    value: null,
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'phoneNumber',
  },
  extension: {
    value: null,
  },
  phoneType: {
    value: null,
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'phoneType',
  },
  nationalProviderIdentifierNumber: {
    value: null,
    validation: ['blank', 'number'],
    errorMsg: ['This field is required'],
    errorKey: 'nationalProviderIdentifierNumber',
  },
  medicalLicenseNumber: {
    value: null,
    validation: [],
  },
}

export const practiceInitialState = {
  pName: {
    value: '',
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'pName',
  },
  pAddress: {
    value: '',
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'pAddress',
  },
  city: {
    value: '',
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'city',
  },
  state: {
    value: '',
    validation: ['blank'],
    errorMsg: ['This field is required'],
    errorKey: 'state',
  },
  zipcode: {
    value: '',
    validation: ['blank', 'number'],
    errorMsg: ['This field is required', 'Invalid Zip Code'],
    errorKey: 'zipcode',
  },
  pNumber: {
    value: '',
    validation: ['blank', 'indianmobile'],
    errorMsg: ['This field is required', 'Invalid Number'],
    errorKey: 'pNumber',
  },
  extension: {
    value: '',
    validation: [],
    errorMsg: ['This field is required'],
    errorKey: 'extension',
  },
  link: {
    value: '',
    validation: [],
    errorMsg: ['This field is required', 'Invalid Link'],
    errorKey: 'link',
  },
}
