import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import Stars from '../../global/ratingreview/stars'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import AddEducation from '../dashboard-modals/add-education'
import EditIntro from '../dashboard-modals/edit-intro'
import EditLang from '../dashboard-modals/edit-language'
import BookmarkDoctor from '../bookmarkDoctor/bookmarkDoctor'
import { isPatientFavorite, PatientFavorite } from '../patient-favorite'

const ProviderLeftProfile = (props) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [ratingModalProps, setRatingModalProps] = useState({
    type: '',
    msg: '',
  })
  const [visibleComponent, setVisibleComponent] = useState('')
  const [patientRules, setpatientRules] = useState({
    isAcceptingNewPatient:
      props.practiceInfo && props.practiceInfo.isAcceptingNewPatient
        ? props.practiceInfo.isAcceptingNewPatient
        : 0,
    hasVirtualVisit:
      props.practiceInfo && props.practiceInfo.hasVirtualVisit
        ? props.practiceInfo.hasVirtualVisit
        : 0,
    allowedPatient:
      props.practiceInfo && props.practiceInfo.allowedPatient
        ? props.practiceInfo.allowedPatient
        : 0,
    allowOnline:
      props.practiceInfo && props.practiceInfo.allowOnline
        ? props.practiceInfo.allowOnline
        : 0,
  })

  const editable = props.editable ? props.editable : false
  const accountInfo = props.accountInfo ? props.accountInfo : {}
  const medicalInfo = props.medicalSpeciality ? props.medicalSpeciality : {}

  const modalList = [
    'editProfile',
    'addEdu',
    'editEdu',
    'editDegree',
    'editPractice',
    'editLang',
  ]

  const redirectToSeachResult = (e) => {
    e && e.preventDefault()
    history.push({ pathname: ProviderLinks.search_result })
    authContext.setAdvDoctorSearchModal(true)
  }

  const setProfile = () => {
    props.getProfile &&
      typeof props.getProfile === 'function' &&
      props.getProfile()
  }

  const openModal = (modalName) => {
    setVisibleComponent(modalName)
    authContext.showModal()
  }

  useEffect(() => {
    const showRatingResult = async () => {
      if (ratingModalProps.msg) {
        if (ratingModalProps.type === 'success') {
          await setProfile()
        }
        authContext.hideModal()
        authContext.showResponseModal()
      }
    }
    showRatingResult()
  }, [ratingModalProps])

  const changePatientRule = async (e) => {
    if (editable) {
      let name,
        tempData = {}
      tempData = patientRules
      name = e.currentTarget.getAttribute('data-name')
      if (e.currentTarget.checked) {
        tempData[name] = 1
      } else {
        tempData[name] = 0
      }

      authContext.setLoader()
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/other-profile-details/update',
        body: JSON.stringify(tempData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        setpatientRules({ ...tempData })
      }
    }
  }

  useEffect(() => {
    setpatientRules({
      ...{
        isAcceptingNewPatient:
          props.practiceInfo && props.practiceInfo.isAcceptingNewPatient
            ? props.practiceInfo.isAcceptingNewPatient
            : 0,
        hasVirtualVisit:
          props.practiceInfo && props.practiceInfo.hasVirtualVisit
            ? props.practiceInfo.hasVirtualVisit
            : 0,
        allowedPatient:
          props.practiceInfo && props.practiceInfo.allowedPatient
            ? props.practiceInfo.allowedPatient
            : 0,
        allowOnline:
          props.practiceInfo && props.practiceInfo.allowOnline
            ? props.practiceInfo.allowOnline
            : 0,
      },
    })
  }, [props.practiceInfo])

  return (
    <Fragment>
      <Suspense fallback={<div>Loading... </div>}>
        {authContext.responseModal ? <Msgmodal {...ratingModalProps} /> : <></>}
        {modalList.indexOf(visibleComponent) > '-1' ? (
          <Custommodal
            unsetComponent={setVisibleComponent}
            componentName={
              visibleComponent === 'editProfile' ? (
                <EditIntro
                  accountInfo={accountInfo}
                  getProfile={setProfile}
                  medicalInfo={medicalInfo}
                  setVisibleComponent={setVisibleComponent}
                />
              ) : visibleComponent === 'addEdu' ? (
                <AddEducation
                  getProfile={setProfile}
                  modalName={visibleComponent}
                  setVisibleComponent={setVisibleComponent}
                />
              ) : visibleComponent === 'editDegree' ? (
                <AddEducation
                  getProfile={setProfile}
                  medicalData={medicalInfo.providerDegreeDetails}
                  practiceData={medicalInfo.providerTrainingDetails}
                  modalName={visibleComponent}
                  setVisibleComponent={setVisibleComponent}
                />
              ) : visibleComponent === 'editPractice' ? (
                <AddEducation
                  getProfile={setProfile}
                  medicalData={medicalInfo.providerDegreeDetails}
                  practiceData={medicalInfo.providerTrainingDetails}
                  modalName={visibleComponent}
                  setVisibleComponent={setVisibleComponent}
                />
              ) : visibleComponent === 'editLang' ? (
                <EditLang
                  language={medicalInfo.languagesSpoken}
                  getProfile={setProfile}
                  setVisibleComponent={setVisibleComponent}
                />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <></>
        )}
        <div
          className={
            accountInfo.providerTypeValue &&
            accountInfo.providerTypeValue === 'top'
              ? 'pro-profile-box-style provider-detail-left-top top-doc-border mb-4 mb-lg-0'
              : accountInfo.providerTypeValue &&
                accountInfo.providerTypeValue === 'sponsored'
              ? 'pro-profile-box-style provider-detail-left-top sponsor-border mb-4 mb-lg-0'
              : 'pro-profile-box-style provider-detail-left-top mb-4 mb-lg-0'
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '24px',
            }}
          >
            {isPatientFavorite(
              props.ratingReviewsAvg,
              props.ratingReviewsCount
            ) && (
              <div
                style={{
                  position: 'absolute',
                  top: '-22px',
                  left: '20px',
                }}
              >
                <PatientFavorite size="large" />
              </div>
            )}
            <BookmarkDoctor
              id={props?.accountInfo?.id}
              isSaved={props.isSavedDoctor}
            />
          </div>
          <span
            className={
              accountInfo.providerTypeValue &&
              accountInfo.providerTypeValue === 'top'
                ? 'top-doc-icon capitalize'
                : accountInfo.providerTypeValue &&
                  accountInfo.providerTypeValue === 'sponsered'
                ? 'sponsor-doc-icon capitalize'
                : 'capitalize'
            }
          >
            {accountInfo.providerTypeValue &&
            (accountInfo.providerTypeValue === 'top' ||
              accountInfo.providerTypeValue === 'sponsered')
              ? accountInfo.providerTypeValue + ' Doctor'
              : ''}
          </span>
          <div className="provider-detail-box">
            {editable && (
              <div
                style={{ top: 0, transform: 'translateY(-100%)' }}
                onClick={() => {
                  openModal('editProfile')
                }}
                className="edit-icon-position-top"
              ></div>
            )}
            <div className="provider-doc-img">
              {accountInfo.profileImage ? (
                <>
                  <img src={accountInfo.profileImage} alt="Doctor" />
                </>
              ) : (
                <>
                  <img src="/assets/images/top-doc-img.png" alt="Doctor" />
                </>
              )}
            </div>
            <h5 style={{ textTransform: 'capitalize' }}>
              {accountInfo.firstName ? accountInfo.firstName + ' ' : ''}
              {accountInfo.middleName ? accountInfo.middleName + ' ' : ''}
              {accountInfo.lastName ? accountInfo.lastName : ''}
              {medicalInfo.providerMedicalCredientialsValue
                ? ', ' + medicalInfo.providerMedicalCredientialsValue
                : ''}
              {props?.accountInfo?.email && <span>Verified</span>}
            </h5>
            <div className="provider-designation">
              {medicalInfo.providerMedicalSpecialitiesValue
                ? medicalInfo.providerMedicalSpecialitiesValue
                : ''}
            </div>
            <div className="endorsements">
              <span>
                {props.endorsementCount ? props.endorsementCount : 0} Doctor
                endorsements
              </span>
            </div>

            <div className="rating-result">
              <span className="rating">
                <Stars
                  {...props}
                  rating={
                    props?.reviewStars
                      ? parseFloat(props.reviewStars).toFixed(1)
                      : 0
                  }
                  getProfile={setProfile}
                  componentName={props.accountInfo ? 'profile' : ''}
                  setRatingModalProps={setRatingModalProps}
                />
              </span>
              <span>
                ({props.ratingReviewsAvg ? props.ratingReviewsAvg : 0}){' '}
                {props.ratingReviewsCount ? props.ratingReviewsCount : 0}{' '}
                Reviews
              </span>
            </div>
            {!props.secondarySection ? (
              <>
                <div className="endorse-link">
                  <a href="/#" onClick={(e) => redirectToSeachResult(e)}>
                    <span>Ask for a Doctor Endorsement</span>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="endorse-link"></div>
              </>
            )}
          </div>
          <div className="reviews-wrapper">
            <h6>Reviews from the web</h6>
            <div className="reviews">
              <>
                {props?.externalReviews?.map((review, index) => {
                  return (
                    <div className="review" key={index}>
                      <span>{review.source}</span>
                      <h5>{`${review.averageRating}`}</h5>
                      <p>{`${review.numberOfReviews} reviews`}</p>
                    </div>
                  )
                })}
              </>
            </div>
          </div>
          {props.secondarySection ? (
            <>
              {patientRules.isAcceptingNewPatient ||
              patientRules.hasVirtualVisit ? (
                <>
                  <div className="profile-eligible-icons">
                    {patientRules.isAcceptingNewPatient ? (
                      <>
                        <span className="tick-icon">
                          Accepting new patients
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {patientRules.hasVirtualVisit ? (
                      <>
                        <span className="video-icon">
                          Virtual visits available
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4"></div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="doc-profile-switcher-wrap">
                <div className="switcher-inner">
                  <span>Allow Patients to Send You Callback Requests</span>
                  <span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        data-name="allowedPatient"
                        onChange={(e) => changePatientRule(e)}
                        checked={patientRules.allowedPatient ? true : false}
                        id="switch-btn1"
                      />
                      <div className="slider round"></div>
                    </label>
                  </span>
                </div>
                <div className="switcher-inner">
                  <span>Are You Accepting New Patients?</span>
                  <span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        data-name="isAcceptingNewPatient"
                        onChange={(e) => changePatientRule(e)}
                        checked={
                          patientRules.isAcceptingNewPatient ? true : false
                        }
                        id="switch-btn2"
                      />
                      <div className="slider round"></div>
                    </label>
                  </span>
                </div>
                <div className="switcher-inner">
                  <span>Virtual Visit Capability?</span>
                  <span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        data-name="hasVirtualVisit"
                        onChange={(e) => changePatientRule(e)}
                        checked={patientRules.hasVirtualVisit ? true : false}
                        id="switch-btn3"
                      />
                      <div className="slider round"></div>
                    </label>
                  </span>
                </div>
                <div className="switcher-inner">
                  <span>Allow Online Patient Scheduling?</span>
                  <span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        data-name="allowOnline"
                        onChange={(e) => changePatientRule(e)}
                        checked={patientRules.allowOnline ? true : false}
                        id="switch-btn3"
                      />
                      <div className="slider round"></div>
                    </label>
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="edu-lang-wrap">
            <div className="head-edu">
              Languages{' '}
              {editable && (
                <div
                  onClick={() => {
                    openModal('editLang')
                  }}
                  className="edit-icon-position"
                ></div>
              )}
            </div>
            <ul>
              <li className="lang-icon" style={{ textTransform: 'capitalize' }}>
                {medicalInfo.languagesSpoken
                  ? medicalInfo.languagesSpoken.join(', ')
                  : ''}
              </li>
            </ul>
          </div>
        </div>
      </Suspense>
    </Fragment>
  )
}

export default ProviderLeftProfile
