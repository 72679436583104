import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'
import InputValidation from '../../reactLayer/validation'

const ServiceAreaNotification = (props) => {
  const authContext = useContext(AuthContext)
  const emailErrorMsg = ['Email is required', 'Invalid Email id']
  const [errors, setErrors] = useState({
    eemail: false,
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
  })

  const [loginData, setLoginData] = useState({
    email: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: emailErrorMsg,
      errorKey: 'eemail',
    },
  })

  const formSubmit = async () => {
    const validationResult = InputValidation(loginData)

    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })
    } else {
      let data = {
        email: loginData.email.value,
        zip: props.zip,
        city: props.city,
      }
      authContext.setLoader()

      let url = 'save-search-location'
      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status === true) {
        setmodalProps({
          type: 'success',
          msg: 'Thank you! We will notify you once we have health care providers in your area.',
          closeaction: closeinvitemodal,
        })
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
      }
      authContext.showResponseModal()
      props.unsetComponent && props.unsetComponent()
    }
  }
  const closeinvitemodal = () => {
    props.unsetComponent && props.unsetComponent()
  }

  return (
    <Fragment>
      {/* Form start step 1 */}
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      <Container className="">
        <Row className="provider-login-wrap service-area-soon">
          <div className="col-md-12 mt-4 mb-3 bold">
            <h2 className="text-center">Service Area Coming Soon</h2>
          </div>
          <div className="col-12 pl-0 pr-0 mt-2 provider-steps-wrap">
            <div className="medical-info body"></div>
          </div>
          <div className="service-area-wrapper">
            <h4 className="col-md-12 covid-screen-box-font-2">
              Unfortunately, we currently do not have a health care provider in{' '}
              <strong>{props.locationName}</strong>. Please leave your email
              address and select the "Notify Me" button below if you would like
              us to let you know when we launch health care providers in your
              area.
            </h4>

            <div className="col-md-12 mt-4">
              <label className="pro-label">Enter Email Address</label>
              <input
                type="text"
                name="email"
                className={
                  errors.eemail ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="Enter Email Address"
                autoComplete="off"
                onClick={() => setErrors({ ...errors, ...{ eemail: false } })}
                onFocus={() => setErrors({ ...errors, ...{ eemail: false } })}
                onChange={(e) =>
                  setLoginData({
                    ...loginData,
                    ...{
                      email: {
                        value: e.currentTarget.value.trim(),
                        validation: ['blank', 'email'],
                        errorMsg: emailErrorMsg,
                        errorKey: 'eemail',
                      },
                    },
                  })
                }
                value={loginData.email.value}
              />
              <small className="error-input">{errors.eemail}</small>
            </div>

            <div className="col-md-12 text-center submit-btn">
              <div className="next-prev-btn-pro">
                <input
                  type="button"
                  onClick={() => {
                    formSubmit()
                  }}
                  className="next-btn-pro full-width"
                  value="Notify  Me"
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ServiceAreaNotification
