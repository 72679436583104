import 'bootstrap/dist/css/bootstrap.css'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Scheduling from '../../components/scheduling/scheduling'

import moment from 'moment'
import ProviderStep4 from '../../components/provider-steps/provider-step4'
import Error403 from '../../global/errorpages/error-403'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'
import { reminderDays, reminderTimes, timeList } from './constants'

const Schedulingpage = (props) => {
  const authContext = useContext(AuthContext)

  const [calenderTimeList, setCalenderTimeList] = useState([])
  const [key, setKey] = useState(props.tab ? props.tab : '1')
  const profile = props.userprofile ? props.userprofile : ''
  const mailtoEmail = 'g.docscheduler@gmail.com' //authContext.user && authContext.user.accountInfo && authContext.user.accountInfo.email ? authContext.user.accountInfo.email : 'info@docscheduler.com';
  const mailtoFname =
    authContext.user &&
    authContext.user.accountInfo &&
    authContext.user.accountInfo.firstName
      ? authContext.user.accountInfo.firstName + ' '
      : ''
  const mailtoMname =
    authContext.user &&
    authContext.user.accountInfo &&
    authContext.user.accountInfo.middleName
      ? authContext.user.accountInfo.middleName + ' '
      : ''
  const mailtoLname =
    authContext.user &&
    authContext.user.accountInfo &&
    authContext.user.accountInfo.lastName
      ? authContext.user.accountInfo.lastName + ' '
      : ''
  const changeTab = (e) => {
    e.preventDefault()
    setKey(e.currentTarget.dataset.rbeventkey)
  }
  const [slots, setslots] = useState([])
  const practiceDataStart = {
    visitDuration: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'visitDuration',
    },
    padding: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'padding',
    },
    locationId: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'locationId',
    },
    tZone: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'tZone',
    },
    openTime: {
      value: [],
      validation: ['arrayempty'],
      errorMsg: ['This field is required'],
      errorKey: 'openTime',
    },
    breakStart: {
      value: '',
      validation: [],
      breakStart: false,
    },
    breakEnd: {
      value: '',
      validation: [],
      breakEnd: false,
    },
    practiceHours: {
      value: [],
    },
  }
  const [practiceData, setPracticeData] = useState([practiceDataStart])
  const [alllocation, setalllocations] = useState([])
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const userConstData = [
    {
      email: {
        value: '',
        validation: ['email'],
        errorMsg: ['Invalid email'],
        errorKey: 'email',
        email: false,
      },
      admin: {
        value: 0,
        admin: false,
      },
    },
    {
      email: {
        value: '',
        validation: ['email'],
        errorMsg: ['Invalid email'],
        errorKey: 'email',
        email: false,
      },
      admin: {
        value: 0,
        admin: false,
      },
    },
    {
      email: {
        value: '',
        validation: ['email'],
        errorMsg: ['Invalid email'],
        errorKey: 'email',
        email: false,
      },
      admin: {
        value: 0,
        admin: false,
      },
    },
  ]
  const [userData, setuserData] = useState({
    userList: [],
  })

  const openTimehandle = (e) => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    tempData = practiceData
    let temppracticehours = tempData[index]['practiceHours']['value']

    let arrayIndex = tempData[index][name]['value'].indexOf(value)
    if (arrayIndex > '-1') {
      tempData[index][name]['value'].splice(arrayIndex, 1)
      tempData[index][name][name] = ''
      delete temppracticehours[index][value]
    } else {
      tempData[index][name]['value'].push(value)
      tempData[index][name][name] = ''
      temppracticehours[index] = {
        ...temppracticehours[index],
        ...{
          [value]: {
            day: value,
            start: '8:00 AM',
            end: '5:00 PM',
            error: false,
          },
        },
      }
    }
    tempData[index]['practiceHours']['value'] = temppracticehours
    setPracticeData([...tempData])
  }

  const changeOpenTime = (e) => {
    let name = e.currentTarget.getAttribute('data-name')
    let day = e.currentTarget.getAttribute('data-day')
    let index = e.currentTarget.getAttribute('data-index')
    let value = e.currentTarget.value
    let practHours = practiceData[index]['practiceHours']['value'] //practiceHours;
    let tempPH = practHours[index][day]
    tempPH = { ...tempPH, ...{ [name]: value, error: false } }
    practHours[index][day] = tempPH
    let tempPD = practiceData
    tempPD[index]['practiceHours']['value'] = practHours
    setPracticeData([...tempPD])
  }

  const modifyDataST = (e, admin) => {
    let value = '',
      name = '',
      index = ''
    name = e.currentTarget.getAttribute('name')
    index = e.currentTarget.getAttribute('data-index')
    value = e.currentTarget.value
    let tempUserData = userData
    if (admin !== undefined) {
      if (admin === 0) {
        tempUserData.userList[index][name][name] = false
        tempUserData.userList[index][name]['value'] = 1
        setuserData({ ...userData, ...tempUserData })
      } else {
        tempUserData.userList[index][name][name] = false
        tempUserData.userList[index][name]['value'] = 0
        setuserData({ ...userData, ...tempUserData })
      }
    } else {
      tempUserData.userList[index][name][name] = false
      tempUserData.userList[index][name]['value'] = value
      setuserData({ ...userData, ...tempUserData })
    }
  }
  const formDataSubmit = async () => {
    let userList = userData.userList
    let tempUserList = []
    let flag = true
    userList.map((ul) => {
      if (ul.email.value.trim() !== '') {
        tempUserList.push({
          email: ul.email.value.trim(),
          admin: ul.admin.value,
        })
      }
    })
    if (!flag) {
      setuserData({ ...userData, ...{ userList: userList } })

      return false
    }

    const data = {
      schedulingTeam: tempUserList,
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/schedule/teams',
      body: JSON.stringify(data),
    }
    authContext.setLoader()
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: 'Updated Successfully',
      })
      authContext.showResponseModal()
    } else {
      if (result.body.alreadyRegister) {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }
  const formSubmit = () => {
    let tempUserList = userData.userList
    let flag = true

    tempUserList.map((ul, ind) => {
      const validationResult = InputValidation(ul)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          // console.log('prop', tempUserList[ind][prop][prop]);
          tempUserList[ind][prop][prop] = `${validationResult[prop]}`
        }
      }
    })
    setuserData({ ...{ userList: tempUserList } })
    if (flag) {
      formDataSubmit()
    }
  }

  const getUserList = async () => {
    authContext.setLoader()
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/schedule/getTeams',
    }
    let result = await NetworkLayer.getRequest(submitData)
    if (result.status) {
      let tempUsersList = [...userConstData]
      result.result &&
        result.result.schedulingTeam &&
        result.result.schedulingTeam.map((ul, index) => {
          tempUsersList[index]['admin']['value'] = ul.admin
          tempUsersList[index]['email']['value'] = ul.email
        })
      setuserData({
        ...{ userList: tempUsersList },
      })
      authContext.unsetLoader()
    } else {
      let tempUsersList = [...userConstData]
      setuserData({ ...userData, ...{ userList: tempUsersList } })
      authContext.unsetLoader()
    }
  }

  const setPracticeInfo = async (pInfoData) => {
    if (!pInfoData) {
      return
    }

    let practData = []
    let pInfo = pInfoData
    let openT = []
    let pHours = []

    pInfo.practiceHours &&
      pInfo.practiceHours.length &&
      pInfo.practiceHours.map((ph) => {
        openT.push(ph.day)
        pHours[0] = { ...pHours[0], ...{ [ph['day']]: ph } }
      })

    practData.push({
      visitDuration: {
        value: pInfo.visitDuration ? pInfo.visitDuration : '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'visitDuration',
      },
      padding: {
        value: pInfo.padding ? pInfo.padding : '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'padding',
      },
      locationId: {
        value: pInfo.locationId ? pInfo.locationId : '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'locationId',
      },
      tZone: {
        value: pInfo.timezone ? pInfo.timezone : '',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'tZone',
      },
      openTime: {
        value: openT ? openT : [],
        validation: ['arrayempty'],
        errorMsg: ['This field is required'],
        errorKey: 'openTime',
      },
      breakStart: {
        value:
          pInfo.breakTime && pInfo.breakTime.start
            ? pInfo.breakTime.start
            : '-',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'breakStart',
        breakStart: false,
      },
      breakEnd: {
        value:
          pInfo.breakTime && pInfo.breakTime.end ? pInfo.breakTime.end : '-',
        validation: ['blank'],
        errorMsg: ['This field is required'],
        errorKey: 'breakEnd',
        breakEnd: false,
      },
      practiceHours: {
        value: pHours,
      },
      reminderDay: {
        value: pInfo?.reminder?.day,
      },
      reminderTime: {
        value: pInfo?.reminder?.time,
      },
    })

    let beginningTime = ''
    let beginningTime1 = ''
    let minTime = ''
    let maxTime = ''
    if (practData[0].practiceHours) {
      if (practData[0].practiceHours.value[0]) {
        const dateData = practData[0].practiceHours.value[0]
        for (let k in dateData) {
          let endTime = moment(dateData[k].start, 'h:mm a')
          if (beginningTime === '') {
            beginningTime = endTime
            minTime = dateData[k].start
          }
          if (beginningTime.isBefore(endTime)) {
            beginningTime = endTime
            minTime = dateData[k].start
          }

          let endTime1 = moment(dateData[k].end, 'h:mm a')
          if (beginningTime1 === '') {
            beginningTime1 = endTime1
            maxTime = dateData[k].end
          }
          if (!beginningTime1.isBefore(endTime1)) {
            beginningTime1 = endTime1
            maxTime = dateData[k].end
          }
        }
      }
    }
    let calenderTimeListTemp = []
    for (let k = 0; k < timeList.length; k++) {
      if (
        timeList[k] === minTime ||
        timeList[k] === maxTime ||
        (moment(timeList[k], 'h:mm a').isAfter(moment(minTime, 'h:mm a')) &&
          moment(timeList[k], 'h:mm a').isBefore(moment(maxTime, 'h:mm a')))
      ) {
        calenderTimeListTemp.push(timeList[k])
      }
    }
    setCalenderTimeList(calenderTimeListTemp)

    setPracticeData([...practData])
  }

  const getLocationBasedDetails = async (
    locationId,
    date = '',
    updateField = true
  ) => {
    authContext.setLoader()

    if (!locationId) {
      return
    }

    let startFrom, cDate
    if (date) {
      cDate = Global.getYearMonthDate(date)
    } else {
      cDate = Global.getYearMonthDate()
    }
    startFrom = cDate.year + '-' + cDate.month + '-' + cDate.date
    let fData = { startFrom: startFrom, locationId: locationId }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/schedule',
      body: JSON.stringify(fData),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.status) {
      if (result.body.result.location) {
        setPracticeInfo(result.body.result.location)
      } else {
        if (updateField === true) {
          setPracticeData([practiceDataStart])
        }
      }
      if (result.body.result.slots) {
        setslots([...result.body.result.slots])
      } else {
        setslots([])
      }
      result.body.result.slots && setslots([...result.body.result.slots])
      authContext.unsetLoader()
    } else {
      setPracticeData([practiceDataStart])
      authContext.unsetLoader()
    }
  }

  const getAllLocations = async () => {
    authContext.setLoader()
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/getLocations',
    }
    let result = await NetworkLayer.getRequest(submitData)
    if (result.status) {
      setalllocations(result.result)
      let pd = practiceData
      pd[0]['locationId']['value'] = result.result[0]['locationid']

      setPracticeData([...pd])
      getLocationBasedDetails(result.result[0]['locationid'], '', false)
      authContext.unsetLoader()
    } else {
      setalllocations([])
      authContext.unsetLoader()
    }
  }

  const modifyPracticeData = (e) => {
    let index,
      name,
      value,
      tempData = []

    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value

    tempData = practiceData
    tempData[index][name]['value'] = value
    tempData[index][name][name] = ''
    setPracticeData([...tempData])
    if (name === 'locationId') {
      getLocationBasedDetails(value)
    }
  }
  const practiceDataSubmit = async () => {
    let temppracticeData = practiceData
    let flag = true
    let setFocus = false
    let practData = []

    temppracticeData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)
      let tempPractHours = currentValue.practiceHours.value[index] // PractHours[index];
      let practHoursIndex = []
      for (const prop in tempPractHours) {
        practHoursIndex.push({
          day: tempPractHours[prop]['day'],
          start: tempPractHours[prop]['start'],
          end: tempPractHours[prop]['end'],
        })
        let start = Global.convertTime12to24(tempPractHours[prop]['start'])
        let end = Global.convertTime12to24(tempPractHours[prop]['end'])
        if (start >= end) {
          tempPractHours[prop]['error'] =
            'Start time should be less than close time'
          if (!flag && !setFocus) {
            setFocus = true
            flag = false
          }
        }
      }
      let ms = {
        visitDuration: currentValue.visitDuration.value,
        padding: currentValue.padding.value,
        locationId: parseInt(currentValue.locationId.value),
        timeZone: currentValue.tZone.value,
        practiceHours: practHoursIndex, //[{"day":"Monday","start":"8 AM","end":"8 PM"},{"day":"Tuesday","start":"8 AM","end":"8 PM"},{"day":"Wednesday","start":"8 AM","end":"8 PM"}],
        breakTime:
          currentValue.breakStart.value === '-' ||
          currentValue.breakEnd.value === '-'
            ? null
            : currentValue.breakStart === '00:00'
            ? null
            : {
                start: currentValue.breakStart.value,
                end: currentValue.breakEnd.value,
              },
        reminder: {
          start: currentValue?.reminderDay?.value,
          end: currentValue?.reminderTime?.value,
        },
      }
      practData.push(ms)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          temppracticeData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            flag = false
          }
        }
        setPracticeData([...temppracticeData])
      } else {
        let start = Global.convertTime12to24(
          currentValue.breakStart.value.trim()
        )
        let end = Global.convertTime12to24(currentValue.breakEnd.value.trim())
        if (start >= end) {
          temppracticeData[index]['breakStart']['breakStart'] =
            'Start time should be less than close time'
          if (!flag && !setFocus) {
            setFocus = true
          }
          setPracticeData([...temppracticeData])
          flag = false
        }
      }
    })
    if (flag) {
      authContext.setLoader()
      const fData = {
        scheduleInformation: practData[0],
      }

      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'appointment/schedule/practiceSettings',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
        getLocationBasedDetails(practData[0].locationId)
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }

  const changeDate = (date) => {
    let chDate = date

    let sdate = new Date(chDate.setHours(0, 0, 0, 0)).getTime()
    let cdate = new Date().getTime()
    if (sdate < cdate) {
      chDate = new Date()
    }
    getLocationBasedDetails(practiceData[0].locationId.value, chDate, true)
  }

  const getLocationsForMember = async () => {
    await fetch(
      `${process.env.REACT_APP_API_BASEURL}appointment/getLocations` +
        '?' +
        new URLSearchParams({
          providerId: props.selectedProvider,
        }),
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        method: 'GET',
      }
    )
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.code === 200) {
          setalllocations(data.result)
          let pd = practiceData
          pd[0]['locationId']['value'] = data.result[0]['locationid']

          setPracticeData([...pd])
          getLocationBasedDetails(data.result[0]['locationid'], '', false)
          authContext.unsetLoader()
        } else {
          setalllocations([])
          authContext.unsetLoader()
        }
      })
  }

  const enableDisbleSlots = async (sl, dt, stdate) => {
    if (sl && dt) {
      authContext.setLoader()
      const fData = {
        slotTime: sl,
        locationId: practiceData[0].locationId.value,
        appointmentDate: dt,
      }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'appointment/update/slots',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        getLocationBasedDetails(
          practiceData[0].locationId.value,
          '',
          new Date(stdate)
        )
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }

  useEffect(() => {
    if (alllocation.length > 0) {
      getLocationBasedDetails(alllocation[0].locationId)
    }
  }, [alllocation])

  useEffect(() => {
    if (
      !authContext.user.accountInfo.subRoleId ||
      authContext.user.accountInfo.admin
    ) {
      getUserList()
      getAllLocations()
    }
    return () => {
      props.setscheduleTab && props.setscheduleTab(1)
    }
  }, [])

  useEffect(() => {
    if (
      authContext.user.accountInfo.subRoleId &&
      authContext.user.accountInfo.subRoleId === 5
    ) {
      getLocationsForMember()
    }
  }, [props.selectedProvider])

  return profile?.providerCurrentPlan?.providerCurrentServices?.indexOf(4) >
    '-1' ? (
    <>
      <Container>
        <Row className="mb-4">
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          <div className="col-12 mb-4">
            <div className="scheduling-page">
              <div className="profile-tabs">
                <nav className="nav nav-tabs" role="tablist">
                  <a
                    onClick={(e) => changeTab(e)}
                    href="#"
                    role="tab"
                    data-rbeventkey="1"
                    aria-controls="controlled-tab-example-tabpane-profile"
                    aria-selected="true"
                    tabIndex={key == '1' ? '' : '-1'}
                    className={
                      key == '1'
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                  >
                    <div className="tabTitle">
                      <h3>Scheduling Team Members</h3>
                    </div>
                  </a>
                  <a
                    onClick={(e) => changeTab(e)}
                    id="controlled-tab-template"
                    href="#"
                    role="tab"
                    data-rbeventkey="2"
                    aria-controls="controlled-tab-example-tabpane-home"
                    tabIndex={key == '2' ? '' : '-1'}
                    aria-selected="false"
                    className={
                      key == '2'
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                  >
                    <div className="tabTitle">
                      <h3>Scheduling Template</h3>
                    </div>
                  </a>
                </nav>

                <div className="tab-content">
                  <div
                    id="controlled-tab-template"
                    aria-labelledby="controlled-tab-example-tab-home"
                    role="tabpanel"
                    aria-hidden={key == '21' ? 'true' : 'false'}
                    className={
                      key == '2' ? 'fade tab-pane active show' : 'fade tab-pane'
                    }
                  >
                    <Row>
                      <div className="col-12 heading">
                        <h4>
                          Update/Edit your scheduling availability for the next
                          five (5) weeks
                        </h4>
                      </div>
                      {/* section 1 */}
                      <div className="col-lg-6 provider-form-inner">
                        <div className="col-12 col-md-10 mb-3">
                          <label className="pro-label">Location</label>
                          <div className="myform  ">
                            <select
                              onChange={(e) => modifyPracticeData(e)}
                              data-index={0}
                              value={
                                alllocation.length > 0
                                  ? alllocation[0].locationId
                                  : practiceData[0]['locationId']['value']
                              }
                              data-name={'locationId'}
                              className={
                                practiceData[0]['locationId']['locationId']
                                  ? 'select  error-border'
                                  : 'select'
                              }
                            >
                              {alllocation.map((location, locationIndex) => (
                                <option
                                  key={locationIndex}
                                  value={location.locationid}
                                >
                                  {location.practiceName
                                    ? location.practiceName + ' '
                                    : ''}{' '}
                                  {location.practiceAddress
                                    ? location.practiceAddress + ' '
                                    : ''}{' '}
                                  {location.city ? location.city + ' ' : ''}{' '}
                                  {location.state ? location.state + ' ' : ''}{' '}
                                  {location.zipCode ? location.zipCode : ''}
                                </option>
                              ))}
                            </select>
                            <small className="error-input">
                              {practiceData[0]['locationId']['locationId']}
                            </small>
                          </div>
                        </div>
                        <div className="col-12 col-md-10 mb-3">
                          <label className="pro-label">
                            Practice Office Hours
                          </label>
                        </div>
                        {practiceData.map((data, index) => (
                          <>
                            <div className="provider-steps-wrap">
                              {[
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                                'Sunday',
                              ].map((d) => (
                                <Row
                                  className="practice-hour-box"
                                  key={d + '-main-' + index}
                                >
                                  <span>
                                    <div className="cus-check-rev">
                                      <input
                                        type="checkbox"
                                        id={d + '-' + index}
                                        name="lang"
                                        data-index={index}
                                        data-name="openTime"
                                        value={d}
                                        onChange={(e) => openTimehandle(e)}
                                        checked={
                                          data.openTime.value.indexOf(d) > '-1'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label htmlFor={d + '-' + index}>
                                        {d}
                                      </label>
                                    </div>
                                  </span>
                                  {data.openTime.value.indexOf(d) > '-1' ? (
                                    <>
                                      <span>
                                        <div className="myform">
                                          <select
                                            className="select"
                                            value={
                                              data.practiceHours.value[index][
                                                d
                                              ]['start']
                                                ? data.practiceHours.value[
                                                    index
                                                  ][d]['start']
                                                : ''
                                            }
                                            data-day={d}
                                            data-name="start"
                                            data-index={index}
                                            onChange={(e) => {
                                              changeOpenTime(e)
                                            }}
                                          >
                                            {timeList.map((t) => (
                                              <option
                                                key={'start-' + index + '-' + t}
                                                value={t}
                                              >
                                                {t}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </span>
                                      <span>to</span>
                                      <span>
                                        <div className="myform">
                                          <select
                                            className="select"
                                            value={
                                              data.practiceHours.value[index][
                                                d
                                              ]['end']
                                                ? data.practiceHours.value[
                                                    index
                                                  ][d]['end']
                                                : ''
                                            }
                                            data-day={d}
                                            data-name="end"
                                            data-index={index}
                                            onChange={(e) => {
                                              changeOpenTime(e)
                                            }}
                                          >
                                            {timeList.map((t) => (
                                              <option
                                                key={'end-' + index + '-' + t}
                                                value={t}
                                              >
                                                {t}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </span>
                                      <small className="error-input time-error">
                                        {
                                          data.practiceHours.value[index][d][
                                            'error'
                                          ]
                                        }
                                      </small>
                                    </>
                                  ) : (
                                    <>
                                      <span>Not Working Day</span>{' '}
                                    </>
                                  )}
                                </Row>
                              ))}
                              <small className="error-input">
                                {data.openTime.openTime}
                              </small>
                            </div>
                            <div className="col-12 col-md-10 mb-3 margin-30">
                              <label className="pro-label">
                                Visit Duration
                              </label>
                              <div className="myform ">
                                <select
                                  className={
                                    data.visitDuration.visitDuration
                                      ? 'select  error-border'
                                      : 'select'
                                  }
                                  value={
                                    data.visitDuration.value === '00:00'
                                      ? 30
                                      : data.visitDuration.value
                                  }
                                  data-index={index}
                                  data-name="visitDuration"
                                  onChange={(e) => {
                                    modifyPracticeData(e)
                                  }}
                                >
                                  <option key={'visit'} value="">
                                    Select
                                  </option>
                                  <option key={'15-visit'} value="15">
                                    15 Mins
                                  </option>
                                  <option key={'30-visit'} value="30">
                                    30 Mins
                                  </option>
                                  <option key={'45-visit'} value="45">
                                    45 Mins
                                  </option>
                                  <option key={'60-visit'} value="60">
                                    60 Mins
                                  </option>
                                </select>
                                <small className="error-input">
                                  {data.visitDuration.visitDuration}
                                </small>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 mb-3 margin-30 provider-steps-wrap">
                              <label className="pro-label info-hg">
                                Padding
                                <div className="info-icon"></div>
                                <div className="info-callout left-right">
                                  {' '}
                                  Padding is the extra time added before or
                                  after an appointment to give Providers buffer
                                  time between appointments.{' '}
                                </div>
                              </label>
                              <div className="myform practice-hour-box row custom-padding">
                                <label>add</label>

                                <div className="myform ">
                                  <select
                                    className={
                                      data.padding.padding
                                        ? 'select  error-border'
                                        : 'select'
                                    }
                                    value={
                                      data.padding.value === '00:00'
                                        ? 0
                                        : data.padding.value
                                    }
                                    data-index={index}
                                    data-name="padding"
                                    onChange={(e) => {
                                      modifyPracticeData(e)
                                    }}
                                  >
                                    <option key={'padding'} value="">
                                      Select
                                    </option>
                                    <option key={'0-padding'} value="0">
                                      0 Mins
                                    </option>
                                    <option key={'5-padding'} value="5">
                                      5 Mins
                                    </option>
                                    <option key={'10-padding'} value="10">
                                      10 Mins
                                    </option>
                                    <option key={'15-padding'} value="15">
                                      15 Mins
                                    </option>
                                  </select>
                                </div>
                                <label>after each visit</label>
                                <small className="error-input">
                                  {data.padding.padding}
                                </small>
                              </div>
                            </div>

                            <div className="col-12 col-md-10 mb-3 margin-30 provider-steps-wrap">
                              <label className="pro-label info-hg">
                                Break
                                <div className="info-icon"></div>
                                <div className="info-callout left-right">
                                  {' '}
                                  A break is a designated period of time within
                                  a Provider's schedule during which no
                                  appointments are booked.{' '}
                                </div>
                              </label>
                              <div className="myform practice-hour-box row">
                                <span>
                                  <label>Block calendar from</label>
                                </span>
                                <span>
                                  <div className="myform">
                                    <select
                                      className={
                                        data.breakStart.breakStart
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.breakStart?.value}
                                      data-index={index}
                                      data-name="breakStart"
                                      onChange={(e) => {
                                        modifyPracticeData(e)
                                      }}
                                    >
                                      <option value={'-'}>-</option>
                                      {calenderTimeList.map((t) => (
                                        <option
                                          key={'start-' + 'index' + '-' + t}
                                          value={t}
                                        >
                                          {t}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <small className="error-input error-block-cal">
                                    {data.breakStart.breakStart}
                                  </small>
                                </span>
                                <span>to</span>
                                <span>
                                  <div className="myform">
                                    <select
                                      className={
                                        data.breakEnd.breakEnd
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.breakEnd?.value}
                                      data-index={index}
                                      data-name="breakEnd"
                                      onChange={(e) => {
                                        modifyPracticeData(e)
                                      }}
                                    >
                                      <option value={'-'}>-</option>
                                      {calenderTimeList.map((t) => (
                                        <option
                                          key={'end-' + 'index' + '-' + t}
                                          value={t}
                                        >
                                          {t}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <small className="error-input">
                                    {data.breakEnd.breakEnd}
                                  </small>
                                </span>
                              </div>
                            </div>

                            <div className="col-12 col-md-10 mb-3 margin-30 provider-steps-wrap">
                              <label className="pro-label info-hg">
                                Reminder Message
                              </label>
                              <div className="myform practice-hour-box row">
                                <span>
                                  <label>Days before visit</label>
                                </span>
                                <span>
                                  <div className="myform">
                                    <select
                                      className={'select'}
                                      value={data.reminderDay?.value}
                                      data-index={index}
                                      data-name="reminderDay"
                                      onChange={(e) => {
                                        modifyPracticeData(e)
                                      }}
                                    >
                                      <option value={'-'}>-</option>
                                      {reminderDays.map((t) => (
                                        <option
                                          key={'start-' + 'index' + '-' + t}
                                          value={t}
                                        >
                                          {t}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </span>
                                <span>at</span>
                                <span>
                                  <div className="myform">
                                    <select
                                      className={'select'}
                                      value={data.reminderTime?.value}
                                      data-index={index}
                                      data-name="reminderTime"
                                      onChange={(e) => {
                                        modifyPracticeData(e)
                                      }}
                                    >
                                      <option value={'-'}>-</option>
                                      {reminderTimes.map((t) => (
                                        <option
                                          key={'end-' + 'index' + '-' + t}
                                          value={t.value}
                                        >
                                          {t.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </>
                        ))}

                        <div className="col-12 mb-5 mt-5 text-center provider-steps-wrap">
                          <div className="next-prev-btn-pro">
                            <input
                              type="button"
                              className="next-btn-pro mb-4"
                              onClick={practiceDataSubmit}
                              value="Save Changes"
                            />
                          </div>
                        </div>
                      </div>

                      {/* section 2 */}
                      <div className="col-lg-6 provider-form-inner">
                        <div className="col-12 col-md-10 mb-3">
                          <label className="pro-label">
                            USA/Eastern Standard Time (EST)
                          </label>
                          <div className="myform ">
                            <select
                              className={
                                practiceData[0]['tZone']['tZone']
                                  ? 'select  error-border'
                                  : 'select'
                              }
                              value={
                                practiceData[0]['tZone']['value']
                                  ? practiceData[0]['tZone']['value']
                                  : ''
                              }
                              data-index={0}
                              data-name="tZone"
                              onChange={(e) => {
                                modifyPracticeData(e)
                              }}
                            >
                              <option value="">Time Zone</option>
                              <option value="HST">
                                Hawaii Standard Time (HST)
                                {practiceData[0]['tZone']['value']
                                  ? practiceData[0]['tZone']['value']
                                  : ''}
                              </option>
                              <option value="AKST">
                                Alaska Standard Time (AKST)
                              </option>
                              <option value="PST">
                                Pacific Standard Time (PST)
                              </option>
                              <option value="MST">
                                Mountain Standard Time (MST)
                              </option>
                              <option value="CST">
                                Central Standard Time (CST)
                              </option>
                              <option value="EST">
                                Eastern Standard Time (EST)
                              </option>
                            </select>
                            <small className="error-input">
                              {practiceData[0]['tZone']['tZone']
                                ? practiceData[0]['tZone']['tZone']
                                : ''}
                            </small>
                          </div>
                        </div>
                        <p>
                          Click on individual time slots below to identify
                          available (blue) and unavailable (grey) appointment
                          slots. Alternatively, you can have our customer
                          service team set up your scheduling template by
                          uploading screen shots of your scheduling book{' '}
                          <a
                            href={`mailto:${mailtoEmail}?subject=Scheduling Template Request || (${mailtoFname}${mailtoMname}${mailtoLname})`}
                          >
                            here
                          </a>
                          !
                        </p>
                        <Scheduling
                          enableDisbleSlots={enableDisbleSlots}
                          upgrade={false}
                          selectedDate={''}
                          changeDate={changeDate}
                          slots={slots}
                        />
                      </div>
                    </Row>
                  </div>

                  <div
                    id="controlled-tab-team-member"
                    aria-labelledby="controlled-tab-example-tab-home"
                    role="tabpanel"
                    aria-hidden={key == '1' ? 'true' : 'false'}
                    className={
                      key == '1' ? 'fade tab-pane active show' : 'fade tab-pane'
                    }
                  >
                    {authContext.user &&
                    authContext.user.accountInfo &&
                    authContext.user.accountInfo.subRoleId &&
                    authContext.user.accountInfo.subRoleId === 5 ? (
                      <>
                        <Error403 />
                      </>
                    ) : (
                      <>
                        <Row>
                          <div className="col-12 heading">
                            <h4>
                              Add scheduling team members that you want to have
                              access to view your DocScheduler scheduling
                              templates and appointment request
                            </h4>
                          </div>

                          {userData.userList.map((ud, udIndex) => (
                            <Row
                              style={{ position: 'relative' }}
                              className="provider-form-inner member"
                              key={'userList-' + udIndex}
                            >
                              <div className="col-md-4 mb-3 section">
                                <label
                                  htmlFor={'email-' + udIndex}
                                  className="pro-label"
                                >
                                  Email
                                </label>
                                <input
                                  type="text"
                                  id={'email-' + udIndex}
                                  name="email"
                                  placeholder="Email"
                                  autoComplete="off"
                                  className={
                                    ud.email.email
                                      ? 'input-pro  error-border'
                                      : 'input-pro'
                                  }
                                  onChange={(e) => {
                                    modifyDataST(e)
                                  }}
                                  data-index={udIndex}
                                  value={ud.email.value}
                                />
                                <small className="error-input">
                                  {ud.email.email}
                                </small>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '25px',
                                  marginLeft: '100px',
                                }}
                              >
                                <h6 style={{ order: '2' }}>Set as admin</h6>
                                <input
                                  style={{ width: '40px' }}
                                  type="checkbox"
                                  id={'admin-' + udIndex}
                                  name="admin"
                                  placeholder="Check"
                                  autoComplete="off"
                                  className={
                                    ud.admin.admin
                                      ? 'input-pro  error-border'
                                      : 'input-pro'
                                  }
                                  onChange={(e) => {
                                    modifyDataST(e, ud.admin.value)
                                  }}
                                  data-index={udIndex}
                                  value={true}
                                  checked={ud.admin.value === 1 ? true : false}
                                />
                              </div>
                            </Row>
                          ))}
                          <Row
                            className="provider-form-inner member provider-steps-wrap"
                            key={'password'}
                          >
                            <div className="col-12 mb-0 mt-3 mb-md-5 mt-md-5 text-center provider-steps-wrap">
                              <div className="next-prev-btn-pro">
                                <input
                                  type="button"
                                  className="next-btn-pro mb-md-4"
                                  onClick={formSubmit}
                                  value="Save"
                                />
                              </div>
                            </div>
                          </Row>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  ) : (
    <>
      <Container className="appointment-wrap modal-wrapper">
        <Row>
          <div className="col-12 profile-plans">
            <div className="provider-step4-wrap">
              <ProviderStep4 title={'Manage Subscriptions'} />
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Schedulingpage
