import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DashboardHead from '../../components/dashboard-head-tab/dashboard-head'
import PatientHealth from '../../components/patient-health-survey/patient-survey'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import { ProviderLinks } from '../../linkFile'
import AppointmentHistory from './appointment'
import CareTeam from './care-team'

const Dashboard = () => {
  const [visibleComponent, setvisibleComponent] = useState('appointmentHistory')
  const tabName = [
    { id: 'appointmentHistory', name: 'Appointment History' },
    { id: 'careTeam', name: 'Care Team' },
    { id: 'healthSurvey', name: 'Health Survey' },
    { id: 'doctorSearch', name: 'Doctor Search' },
  ]
  const history = useHistory()
  const redirectToHome = () => {
    history.push({
      pathname: ProviderLinks.home,
    })
  }
  return (
    <Fragment>
      <HeaderMain />
      <DashboardHead
        visibleComponent={visibleComponent}
        setvisibleComponent={setvisibleComponent}
        tabName={tabName}
        title={'My Health Dashboard'}
      />

      {visibleComponent === 'appointmentHistory' ? (
        <AppointmentHistory />
      ) : visibleComponent === 'careTeam' ? (
        <CareTeam />
      ) : visibleComponent === 'healthSurvey' ? (
        <PatientHealth />
      ) : visibleComponent === 'doctorSearch' ? (
        <>{redirectToHome()}</>
      ) : (
        <></>
      )}

      {/* {
        visibleComponent === 'appointmentHistory' ? <Error404 />
          :
          visibleComponent === 'careTeam' ? <CareTeam />
            :
            visibleComponent === 'healthGrade' ? <Error404 />
              :
              visibleComponent === 'doctorSearch' ? <><Redirect to="/" /></> : <></>
      } */}

      <Footer />
    </Fragment>
  )
}

export default Dashboard
