import 'bootstrap/dist/css/bootstrap.css'
import googleOneTap from 'google-one-tap'
import React, { Fragment, useContext, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import NewHomePage from '../../components/new-home-page/newhomepage'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const Home = (props) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const options = {
    client_id:
      '851612377290-8agvpnk4a10cg9cashl569qssa9in21u.apps.googleusercontent.com', // required
    auto_select: false, // optional
    cancel_on_tap_outside: true, // optional
    context: 'use', // optional
  }

  googleOneTap(options, async (response) => {
    let data = {
      oauth_provider: 'google',
      token: response.credential,
    }
    authContext.setLoader()

    let url = 'patients/google/login'
    const submitData = {
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    if (result.body.status) {
      if (result.body.result.data) {
        let user = authContext.user ? authContext.user : {}
        user['token'] = result.body.result.token
        user = { ...user, ...result.body.result.data }

        authContext.setToken(user.token)
        authContext.setUser(user)
        if (result.body.result.step) {
          history.push({
            pathname: PatientLinks['register_step' + result.body.result.step],
          })
        } else if (
          result.body.result.data.accountInfo &&
          result.body.result.data.accountInfo.isProfileCompleted
        ) {
          history.push({ pathname: PatientLinks.dashboard })
        } else {
          history.push({ pathname: PatientLinks['register_step2'] })
        }
      }
    }
  })

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          DocScheduler | Video Consultation with Doctors, Book Doctor
          Appointments
        </title>
        <meta
          name="description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <link rel="canonical" href="https://www.docscheduler.com" />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta property="og:url" content={`https://www.docscheduler.com`} />
        <meta
          name="twitter:card"
          content="Video Consultation with Doctors, Book Doctor Appointments"
        />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta
          property="og:title"
          content="DocScheduler | Video Consultation with Doctors, Book Doctor Appointments"
        />
        <meta
          name="twitter:title"
          content="DocScheduler | Video Consultation with Doctors, Book Doctor Appointments"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <meta
          name="twitter:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
      </Helmet>
      <HeaderMain
        mobileMenuVisible={mobileMenuVisible}
        setMobileMenuVisible={setMobileMenuVisible}
        {...props}
      />
      <Container fluid className="home-wrap">
        <NewHomePage />
      </Container>
      <Footer />
    </Fragment>
  )
}

export default Home
