import { Box, Flex } from '@chakra-ui/react'
import { APIProvider, InfoWindow, Map, Marker } from '@vis.gl/react-google-maps'
import React, { useState } from 'react'

import { Typography } from '../../../style/typography'
import { ProviderPracticeLocation, ResultProfile } from '../../../types'

const textStyle = {
  fontSize: '12px',
}

export const providerAddress = (locations: ProviderPracticeLocation[]) => {
  if (!locations.length) {
    return <div></div>
  }

  const { practiceName, practiceAddress, cities, states, zipCode } =
    locations[0]

  return (
    <Flex direction="column" alignItems="flex-start" gap={1}>
      <Typography {...textStyle}>{practiceName}</Typography>
      <Typography {...textStyle}>{practiceAddress}</Typography>
      <Typography {...textStyle}>
        {cities?.name}, {states.code} {zipCode}
      </Typography>
    </Flex>
  )
}

interface Props {
  zoomLabel: number
  mapData: any
  items: ResultProfile[]
  onMarkerClick: any
}

const calculateMarkerPosition = (locations: ProviderPracticeLocation[]) => {
  const location = locations[0]
  return {
    lat: location.latitude,
    lng: location.longitude,
  }
}

export const GoogleMaps: React.FC<Props> = ({
  zoomLabel,
  mapData,
  items,
  onMarkerClick,
}) => {
  const position = mapData[0]
  const [open, setOpen] = useState(false)
  const [activeMarker, setActiveMarker] = useState<ResultProfile | null>(null)

  return (
    <Box width="100%" height="800px">
      <APIProvider apiKey={'AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo'}>
        <Map center={position} zoom={zoomLabel}>
          {items.map((item) => {
            const pos = calculateMarkerPosition(item.providerPracticeLocations)

            return (
              <Marker
                onClick={() => {
                  setOpen(true)
                  setActiveMarker(item)
                  onMarkerClick(item.id)
                }}
                key={item.id}
                position={pos}
              />
            )
          })}
          {open && activeMarker && (
            <InfoWindow
              position={calculateMarkerPosition(
                activeMarker.providerPracticeLocations
              )}
              onCloseClick={() => setOpen(false)}
            >
              <Flex
                width="350px"
                height="100px"
                flexDirection="row"
                gap={4}
                p={1}
              >
                <Box
                  width="80px"
                  height="80px"
                  overflow="hidden"
                  borderRadius="50%"
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    src={activeMarker.profileImage}
                    alt="doc image"
                  />
                </Box>
                <Flex
                  width="70%"
                  direction="column"
                  alignItems="flex-start"
                  gap={2}
                >
                  <Typography {...textStyle} fontWeight={500}>
                    {activeMarker.providerMedicalSpecialitiesValue}
                  </Typography>
                  {providerAddress(activeMarker.providerPracticeLocations)}
                </Flex>
              </Flex>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </Box>
  )
}
