import { memo, useCallback, useContext } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
// import { ReactComponent as Check } from '../../Icons/check-circle.svg'
import Stars from '../../global/ratingreview/stars'
import useLocationMod from '../../hooks/useLocationMod'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import { useGetSearchParams } from '../../new/lib/hooks/useGetSearchParams'
import { generateURLParams } from '../../new/lib/utils'
import AuthContext from '../../reactLayer/auth/AuthContext'
import { useChipInputValues } from '../../reactLayer/contextStore/homepageStore'
import BookmarkDoctor from '../bookmarkDoctor/bookmarkDoctor'
import { PatientFavorite, isPatientFavorite } from '../patient-favorite'

const TopDoctors = ({ city, state, isLoading, searchResults }) => {
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const locationMod = useLocationMod()
  const { insuranceInput, cityInput, zipInput } = useChipInputValues()
  const query = useGetSearchParams()

  const redirectToSearchResult = useCallback(() => {
    const path =
      authContext.user &&
      authContext?.user?.isProfileCompleted &&
      authContext.user.accountInfo.roleId === 3
        ? ProviderLinks.search_result
        : PatientLinks.doctor_search_result
    history.push({
      pathname: path,
      search: generateURLParams({
        ...query,
        insurance: insuranceInput,
        zip: zipInput,
        city: cityInput,
        focusArea: 'Primary Care',
      }),
      state: {
        find: '',
        insurance_Name: [],
        focusAreas: 'Primary Care',
        currentLocation: true,
      },
    })
    locationMod.location.state = {
      find: '',
      insurance_Name: [],
      focusAreas: 'Primary Care',
    }
  }, [history, locationMod, query])
  const settings = {
    dots: false,
    draggable: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4, // Number of items to show at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  }

  return (
    <div className="col-12">
      <div className="top-doc-list-wrap">
        <div className="top-doctors-heading--mobile">
          <h1
            style={{
              marginBottom: '14px',
              fontSize: '24px',
              fontWeight: '700',
            }}
          >
            Top primary care doctors
          </h1>
          <div className="tags">
            <div className="tag">
              <span>{city || 'Washington'}</span>
            </div>
            <div className="tag">
              <span>{state || 'D.C.'}</span>
            </div>
          </div>
        </div>
        <div className="top-doctors-heading">
          <h1
            style={{
              marginBottom: '14px',
              fontSize: '24px',
              fontWeight: '700',
            }}
          >
            Top primary care doctors
          </h1>
          <button onClick={() => redirectToSearchResult()}>See more</button>
        </div>
        {isLoading ? (
          <div className="loadingContainer">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : searchResults.length > 0 ? (
          <div
            style={{
              marginTop: '32px',
            }}
          >
            <Slider {...settings}>
              {searchResults.map((provider, index) => (
                <div key={index} className="top-doctor">
                  {isPatientFavorite(
                    provider.totalReviewAvg,
                    provider.totalReviewCount
                  ) && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-14px',
                        left: '10px',
                        zIndex: 10,
                      }}
                    >
                      <PatientFavorite size="small" />
                    </div>
                  )}
                  <div className="top-doctor--header">
                    <BookmarkDoctor
                      id={provider.id}
                      isSaved={provider.isSavedDoctor}
                    />
                  </div>
                  <Link
                    to={ProviderLinks.profile + '/' + provider.id}
                    className="top-doctor--link"
                  >
                    <div className="top-doctor--img">
                      <img src={provider.profileImage} alt="profile-image" />
                    </div>
                    <div className="top-doctor--info">
                      <span>{`${provider.providers.firstName} ${
                        provider.providers.middleName
                          ? `${''} ${provider.providers.middleName}`
                          : ''
                      } ${provider.providers.lastName}, ${
                        provider.providerMedicalSpecialities[0].degrees
                          .shortName
                      }`}</span>
                      {/* <Check /> */}
                    </div>
                    <div className="top-doctor--title">
                      <span>
                        {
                          provider.providerMedicalSpecialities[0].specialties
                            .name
                        }
                      </span>
                    </div>
                  </Link>
                  <div className="top-doctor--reviews">
                    <span>Reviews from the web</span>
                    <div className="rating">
                      <Stars
                        rating={
                          provider.reviewStars
                            ? parseFloat(provider.reviewStars).toFixed(1)
                            : 0
                        }
                      />
                    </div>
                    <p>
                      {`(${provider.totalReviewAvg}) ${provider.totalReviewCount} Reviews`}
                    </p>
                  </div>
                  <Link
                    to={ProviderLinks.profile + '/' + provider.id}
                    className="top-doctor--button"
                  >
                    Schedule
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="loadingContainer">
            <span>No data found.</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(TopDoctors)
