import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Row } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { Link } from 'react-router-dom'
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg'
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../../global/modal/modal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import Global from '../../../reactLayer/global'
import InputValidation from '../../../reactLayer/validation'
import DigitalReputationInfoModal from './digital-reputation-info-modal'
import { ExternalReviews } from './external-reviews'

const DigitalReputation = (props) => {
  const authContext = useContext(AuthContext)
  const wrapperCateRef = useRef(null)
  const [data, setData] = useState([])
  const [addActive, setAddActive] = useState('active')
  const [reviewActive, setReviewActive] = useState('')
  const [temprating, setTemprating] = useState(0)
  const [ratings, setRatings] = useState([])

  const userId = props.providerDetails.accountInfo.id

  const [newData] = useState({
    name: {
      value: '',
      validation: ['blank'],
      errorMsg: ['Please enter name'],
      errorKey: 'name',
      name: '',
    },
    source: {
      value: 'google',
      validation: ['blank'],
      errorMsg: ['Please enter source'],
      errorKey: 'source',
      source: false,
    },
    createdAt: {
      value: new Date(),
      validation: ['blank'],
      errorMsg: ['Please enter date'],
      errorKey: 'createdAt',
      createdAt: false,
    },
    comment_time: {
      value: moment(new Date()).format('YYYY-MM-DD'),
    },
    rating: {
      value: 0,
      validation: ['blank'],
      errorMsg: ['Please select rating'],
      errorKey: 'rating',
      rating: false,
    },
    comment: {
      value: '',
      validation: ['blank'],
      errorMsg: ['Please enter comment'],
      errorKey: 'comment',
      comment: false,
    },
    userId: {
      value: 0,
      validation: [],
      errorMsg: ['Please enter userId'],
      errorKey: 'userId',
      userId: false,
    },
    ratingId: {
      value: 0,
      validation: [],
      errorMsg: ['Please enter ratingId'],
      errorKey: 'ratingId',
      ratingId: false,
    },
  })
  const [addData, setAddData] = useState(newData)
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [viewdetails, setViewdetails] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [source] = useState([
    'Facebook',
    'Google',
    'HealthGrades',
    'Vitals',
    'Yelp',
    'ZocDoc',
  ])
  const [showCal, setshowCal] = useState(false)
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const [sort, setsort] = useState('ASC')
  const [sortName, setSortName] = useState('Name')

  // FOR POPUP MODAL
  const modalList = ['manageInfo']

  const getList = async () => {
    authContext.setLoader()
    // FORM DATA
    const formData = {
      offset: filter.offset,
      limit: filter.limit,
      userId: props.providerDetails?.accountInfo?.id,
      sortBy: sortName,
      sortOrder: sort,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/digital-reputation/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setData(result?.body?.result?.rows)
    settotalCount(result?.body?.result?.count)
    authContext.unsetLoader()
  }

  const getDetails = async (id) => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/digital-reputation/view/' + id,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)
    setViewdetails(result?.result)
    authContext.unsetLoader()
  }

  const deleteProviderDigitalReputation = async (id) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/digital-reputation/destroy',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    if (
      window.confirm(
        'Are you sure you want to delete this provider digital reputation?'
      )
    ) {
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()

      if (result?.body?.code == 200) {
        successMsg('success', result?.body?.message)
        setTimeout(() => {
          getList()
        }, 3000)
      } else {
        successMsg('error', result?.body?.message)
      }
    }
  }

  const openProfileTabModal = (modalName, value) => {
    getDetails(value?.id).then(() => {
      authContext.showModal()
      settabvisibleComponent(modalName)
    })
  }
  const settemprating = (r) => {
    setTemprating(r)
  }
  const editProviderDigitalReputation = (value) => {
    let editDataValues = {
      name: {
        value: value?.name,
        validation: ['blank'],
        errorMsg: ['Please enter name'],
        errorKey: 'name',
        name: false,
      },
      source: {
        value: value?.source,
        validation: ['blank'],
        errorMsg: ['Please enter source'],
        errorKey: 'source',
        source: false,
      },
      createdAt: {
        value: value?.createdAt,
        validation: ['blank'],
        errorMsg: ['Please enter date'],
        errorKey: 'createdAt',
        createdAt: false,
      },
      rating: {
        value: value?.rating,
        validation: ['blank'],
        errorMsg: ['Please select rating'],
        errorKey: 'rating',
        rating: false,
      },
      comment: {
        value: value?.message,
        validation: ['blank'],
        errorMsg: ['Please enter comment'],
        errorKey: 'comment',
        comment: false,
      },
      userId: {
        value: value?.toUser,
        validation: [],
        errorMsg: ['Please enter userId'],
        errorKey: 'userId',
        userId: false,
      },
      ratingId: {
        value: value?.id,
        validation: [],
        errorMsg: ['Please enter ratingId'],
        errorKey: 'ratingId',
        ratingId: false,
      },
    }
    setAddData(editDataValues)
    setIsEdit(true)
  }

  const closeEditBox = () => {
    setIsEdit(false)
    setAddData(newData)
  }
  const reset = () => {
    newData.name.name = ''
    newData.comment.comment = ''
    newData.rating.rating = ''
    setAddData(newData)
  }

  const dateSelect = (value, event) => {
    setshowCal(false)
    setAddData({
      ...addData,
      comment_time: {
        ...addData.createdAt,
        value: moment(value).format('YYYY-MM-DD'),
      },
    })
  }

  const modifyVal = (e) => {
    let name = e.target.name
    let updatedData = {}
    e.preventDefault()
    if (name == 'comment') {
      if (e.target.value.length <= 500) {
        updatedData = {
          ...addData,
          comment: {
            ...addData.comment,
            value: e.target.value,
            comment: '',
          },
        }
        setAddData(updatedData)
      }
    } else if (name == 'username') {
      if (/^[a-zA-Z ]*$/.test(e.target.value) === false) {
        return
      }
      updatedData = {
        ...addData,
        name: {
          ...addData.name,
          value: e.target.value,
          name: '',
        },
      }
      setAddData(updatedData)
    } else {
      updatedData = {
        ...addData,
        [e.target.name]: {
          ...addData[e.target.name],
          value: e.target.value,
        },
      }

      setAddData(updatedData)
    }
  }

  const modifyRatingVal = (val) => {
    let updatedData = {
      ...addData,
      rating: {
        ...addData.rating,
        value: val,
        rating: '',
      },
    }
    setAddData(updatedData)
  }

  const formSubmit = async (e) => {
    e.preventDefault()
    let validationResult = InputValidation(addData)
    let tempCustIns = addData
    let errorFields = []
    let flag = true
    // return false;
    if (Object.keys(validationResult).length > 0) {
      flag = false
      for (const prop in validationResult) {
        tempCustIns[prop][prop] = `${validationResult[prop]}`
        errorFields.push(prop)
      }
      if (errorFields[0] !== 'rating') {
        document.getElementById(errorFields[0]).focus()
      }
      setAddData({ ...tempCustIns })
    }

    // return false;
    if (!flag) {
      return false
    }

    // FORM DATA
    let formData = {
      toUser: props.providerDetails?.accountInfo?.id,
      name: addData.name.value,
      source: addData.source.value,
      rating: addData.rating.value,
      message: addData.comment.value,
      createdAt: addData.comment_time.value,
      comment_time: addData.comment_time.value,
    }

    // STRUCTURE LOGIN REQUEST DATA
    let url = 'admin/providers/digital-reputation/add'
    if (isEdit) {
      formData = {
        id: addData.ratingId.value,
        toUser: props.providerDetails?.accountInfo?.id,
        name: addData.name.value,
        source: addData.source.value,
        rating: addData.rating.value,
        message: addData.comment.value,
      }
      url = 'admin/providers/digital-reputation/update'
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    authContext.setLoader()
    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.code == 200) {
      successMsg('success', result.body.message)
      closeEditBox()
      setTimeout(() => {
        getList()
      }, 3000)
    } else {
      successMsg('error', result.body.message)
    }
  }

  const successMsg = (type, message) => {
    setmodalProps({
      type: type,
      msg: message,
    })
    authContext.showResponseModal()
  }

  const changeSorting = (e) => {
    let name = e.currentTarget.getAttribute('name')
    setSortName(name)
    setsort(sort == 'ASC' ? 'DESC' : 'ASC')
  }

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    if (wrap && !wrap.contains(event.target)) {
      setshowCal(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    getList()
  }, [filter.offset, sort])

  // PAGINATION CALLBACK
  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  useEffect(() => {
    setRatings(props.providerDetails?.individualRatings)
  }, [])

  return (
    <Fragment>
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'manageInfo' ? (
              <DigitalReputationInfoModal data={viewdetails} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      {/* table start here */}
      <Row>
        {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      </Row>
      <div className="tabs">
        <span
          onClick={() => {
            setReviewActive('')
            setAddActive('active')
          }}
          className={`tab ${addActive}`}
        >
          Add Review
        </span>
        <span
          onClick={() => {
            setReviewActive('active')
            setAddActive('')
          }}
          className={`tab ${reviewActive}`}
        >
          Reviews from the web
        </span>
      </div>
      {addActive === 'active' ? (
        <>
          <Row>
            <div className="col-12">
              {!isEdit && (
                <div className="digital-reputation-wrap">
                  <div className="digital-reputation-top">
                    <Row>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <label className="pro-label">Name</label>
                        <input
                          id="name"
                          name="username"
                          type="text"
                          className="input-pro"
                          placeholder="Enter Name"
                          value={`${addData?.name?.value}`}
                          onChange={(e) => modifyVal(e)}
                        />
                        <small className="error-input">
                          {addData?.name?.name}
                        </small>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <div className="select-option">
                          <label className="pro-label">Source</label>
                          <select
                            className="select"
                            name="source"
                            onChange={(e) => modifyVal(e)}
                          >
                            {source.map((value, index) => (
                              <option
                                key={index}
                                value={value.toLowerCase()}
                                selected={
                                  value.toLowerCase() == addData.source.value
                                    ? 'selected'
                                    : ''
                                }
                              >
                                {value}
                              </option>
                            ))}
                          </select>
                          <i className="select-option-icon">
                            <SelectOptionIcon />
                          </i>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <label className="pro-label">Date &amp; Time</label>
                        <div
                          className="date-field"
                          style={{ 'z-index': '100' }}
                          ref={wrapperCateRef}
                        >
                          {showCal && (
                            <>
                              <Calendar
                                onClick={dateSelect}
                                onClickDay={dateSelect}
                                value={new Date(addData.comment_time.value)}
                                // minDate={new Date()}
                                maxDate={Global.addDays(null, 0)}
                                calendarType={'US'}
                              />
                            </>
                          )}
                          <input
                            type="text"
                            name="createdAt"
                            value={moment(addData.comment_time.value).format(
                              'YYYY-MM-DD'
                            )}
                            className="input-pro"
                            placeholder=""
                            onChange={(e) => modifyVal(e)}
                            onClick={() => {
                              setshowCal(!showCal)
                            }}
                          />
                          <img
                            src="/assets/images/calender-icon.svg"
                            className="admin-cal-icon"
                            alt="calender"
                            onClick={() => {
                              setshowCal(!showCal)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="pro-label">Rating</label>
                        <div className="rating-doctor-review rating">
                          <div className="stars-outer">
                            {[1, 2, 3, 4, 5].map((d, i) => (
                              <span
                                key={i}
                                className={
                                  addData?.rating?.value >= d
                                    ? 'star full'
                                    : temprating >= d
                                    ? 'star full'
                                    : 'star'
                                }
                                name="rating"
                                onClick={(e) => modifyRatingVal(d)}
                                onMouseLeave={(e) => settemprating(0)}
                                onMouseEnter={(e) => settemprating(d)}
                              ></span>
                            ))}
                          </div>
                          <small className="error-input">
                            {addData?.rating?.rating}
                          </small>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-12 provider-form-edit mt-3">
                        <label className="pro-label">Comment</label>
                        <textarea
                          id="comment"
                          name="comment"
                          className="input-pro text-area"
                          value={addData?.comment?.value}
                          onChange={(e) => modifyVal(e)}
                        ></textarea>
                        <br />
                        <small style={{ color: '#979797' }}>
                          {'500 Characters Maximum'}
                        </small>
                        <div>
                          <small className="error-input">
                            {addData?.comment?.comment}
                          </small>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-12 bottom-btns mt-3">
                        <div className="next-prev-btn-pro">
                          <input
                            type="button"
                            className="prev-btn-pro"
                            value="Reset"
                            onClick={() => {
                              reset()
                            }}
                          />
                          <input
                            type="button"
                            className="next-btn-pro"
                            value="Add"
                            onClick={(e) => {
                              formSubmit(e)
                            }}
                          />
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              )}
              {isEdit && (
                <div className="digital-reputation-wrap">
                  <div className="digital-reputation-top">
                    <Row>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <label className="pro-label">Name</label>
                        <input
                          id="username"
                          name="username"
                          type="text"
                          className="input-pro"
                          placeholder="Enter Name"
                          value={`${addData?.name?.value}`}
                          onChange={(e) => modifyVal(e)}
                        />
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <div className="select-option">
                          <label className="pro-label">Source</label>
                          <select
                            className="select"
                            name="source"
                            onChange={(e) => modifyVal(e)}
                          >
                            {source.map((value, index) => (
                              <option
                                key={index}
                                value={value.toLowerCase()}
                                selected={
                                  value.toLowerCase() == addData.source.value
                                    ? 'selected'
                                    : ''
                                }
                              >
                                {value}
                              </option>
                            ))}
                          </select>
                          <i className="select-option-icon">
                            <SelectOptionIcon />
                          </i>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3 mb-md-0 provider-form-edit">
                        <label className="pro-label">Date &amp; Time</label>
                        <div
                          className="date-field"
                          style={{ 'z-index': '100' }}
                          ref={wrapperCateRef}
                        >
                          {showCal && (
                            <>
                              <Calendar
                                onClick={dateSelect}
                                onClickDay={dateSelect}
                                value={new Date()}
                                // minDate={new Date()}
                                maxDate={Global.addDays(null, 0)}
                                calendarType={'US'}
                              />
                            </>
                          )}
                          <input
                            type="text"
                            name="createdAt"
                            value={moment(addData.createdAt.value).format(
                              'YYYY-MM-DD'
                            )}
                            className="input-pro"
                            placeholder=""
                            onChange={(e) => modifyVal(e)}
                            onClick={() => {
                              setshowCal(!showCal)
                            }}
                          />
                          <img
                            src="/assets/images/calender-icon.svg"
                            className="admin-cal-icon"
                            alt="calender"
                            onClick={() => {
                              setshowCal(!showCal)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="pro-label">Rating</label>
                        <div className="rating-doctor-review rating">
                          <div className="stars-outer">
                            {[1, 2, 3, 4, 5].map((d, i) => (
                              <span
                                key={i}
                                className={
                                  addData?.rating?.value >= d
                                    ? 'star full'
                                    : temprating >= d
                                    ? 'star full'
                                    : 'star'
                                }
                                name="rating"
                                onClick={(e) => modifyRatingVal(d)}
                                onMouseLeave={(e) => settemprating(0)}
                                onMouseEnter={(e) => settemprating(d)}
                              ></span>
                            ))}
                            <small className="error-input">
                              {addData?.rating?.rating}
                            </small>
                          </div>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-12 provider-form-edit mt-3">
                        <label className="pro-label">Comment</label>
                        <textarea
                          id="comment"
                          name="comment"
                          className="input-pro text-area"
                          value={addData?.comment?.value}
                          onChange={(e) => modifyVal(e)}
                        ></textarea>
                        <br />
                        <small style={{ color: '#979797' }}>
                          {'500 Characters Maximum'}
                        </small>
                      </div>
                    </Row>

                    <Row>
                      <div className="col-12 bottom-btns mt-3">
                        <div className="next-prev-btn-pro">
                          <input
                            type="button"
                            className="prev-btn-pro"
                            value="Cancel"
                            onClick={() => {
                              closeEditBox()
                            }}
                          />
                          <input
                            type="button"
                            className="next-btn-pro"
                            value="Update"
                            onClick={(e) => {
                              formSubmit(e)
                            }}
                          />
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </Row>
          <div className="table-report-scroll mt-0">
            <table className="table">
              <thead>
                <tr>
                  <th className="data-sort" style={{ minWidth: '150px' }}>
                    <span className="sort-outer">
                      Reviewed by
                      <span
                        className="data-sort-icon"
                        name="name"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>Source</th>
                  <th className="data-sort">
                    <span className="sort-outer">
                      Date &amp; Time
                      <span
                        className="data-sort-icon"
                        name="createdAt"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th className="data-sort">
                    <span className="sort-outer">
                      Rating
                      <span
                        className="data-sort-icon"
                        name="rating"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>Comment</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length == 0 && (
                  <tr>
                    <td>No Results Found</td>
                  </tr>
                )}
                {data &&
                  data.map((value, index) => (
                    <tr key={index}>
                      <td>{`${value?.name}`}</td>
                      <td>{value.source}</td>
                      <td>{moment(value.createdAt).format('MM/DD/YYYY')}</td>
                      <td>
                        <span className="rating">
                          <span
                            className={`rating${parseFloat(value.rating)}`}
                          ></span>
                        </span>
                      </td>
                      <td>
                        <div className="comment-digital-repo">
                          {value.message}
                        </div>
                      </td>
                      <td>
                        <Link
                          className="table-action view-all"
                          onClick={() => {
                            openProfileTabModal('manageInfo', value)
                          }}
                        >
                          View
                        </Link>
                        <Link
                          className="table-action view-all"
                          onClick={() => {
                            editProviderDigitalReputation(value)
                          }}
                        >
                          Edit
                        </Link>
                        <Link
                          className="table-action view-all"
                          onClick={() => {
                            deleteProviderDigitalReputation(value.id)
                          }}
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="report-page mt-4">
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
            />
          </div>
        </>
      ) : (
        <ExternalReviews ratings={ratings} userId={userId} />
      )}
    </Fragment>
  )
}

export default DigitalReputation
