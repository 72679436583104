export const isPremium = (planId) => [3, 4].includes(planId)
export const isPaidAccount = (planId) => [2, 3, 4].includes(planId)
export const isAuthPatient = ({ user }) =>
  user?.isProfileCompleted && user?.accountInfo?.roleId === 4
export const isTop = (item) =>
  item?.providers?.providerType === providerTypes.top
export const isSponsored = (item) =>
  item?.providers?.providerType === providerTypes.sponsored
export const schedulingFeatureOrAppointment = (
  allowOnline,
  allowVirtualVisit,
  allowCallbacks,
  planId,
  renderAppointment,
  renderCalendar
) => {
  if (isPremium(planId) && allowVirtualVisit && allowCallbacks) {
    return renderCalendar()
  } else if (isPaidAccount(planId) && allowOnline && allowCallbacks) {
    return renderAppointment()
  } else {
    return (
      <div className="scheduling-features">
        <em>Online Scheduling Is Not Available For This Provider</em>
      </div>
    )
  }
}

export const allowCallbackRequests = (planId, allowCallbacks) =>
  isPaidAccount(planId) && allowCallbacks

export const providerTypes = {
  sponsored: 1,
  top: 2,
  normal: 3,
}
export const providerAddress = ({
  practiceName,
  practiceAddress,
  cities,
  states,
  zipCode,
}) => (
  <>
    {practiceName}
    <br />
    {practiceAddress}
    <br />
    {cities?.name}, {states.code} {zipCode}
  </>
)
