import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, memo, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Pagination from '../../components/pagination/pagination'
import SearchFilter from '../../components/search-filter-modal/search-modal'
import Custommodal from '../../global/modal/modal'
import useLocationMod from '../../hooks/useLocationMod'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import AllFilters from '../doctor-search-result/all-filter'
import Gmaps from '../googlemap/googlemap'
import ServiceAreaComingSoonModal from '../provider-login/service-area-coming-soon'
import SchedulingDate from '../scheduling/scheduling-date'
import SchedulingTitle from '../scheduling/scheduling-title'
import SponsoredDoc from './sponsored-doctor'

let headers = { 'Content-Type': 'application/json' }

const SearchResults = (props) => {
  const { userCateType, filter, setFilter } = props
  const locationMod = useLocationMod()
  const [isLoading, setIsLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [mapData, setMapData] = useState([])
  const authContext = useContext(AuthContext)
  const [searchResults, setSearchResults] = useState([])
  const [filteredResults, setFilteredResults] = useState([])
  const [page, setPage] = useState()
  const [areaSupported, setAreaSupported] = useState(true)
  const [browserAddress] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [sort, setSort] = useState('distance')
  const [startDate, setStartDate] = useState('')
  const [modalForLocationShown, setModalForLocationShown] = useState('')
  const [mapZoomed, setMapZoomed] = useState(false)
  const [serviceAreaComingSoonModal, setServiceAreaComingSoonModal] =
    useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [latlng] = useState({
    latitude: null,
    longitude: null,
  })
  const [totalCount, setTotalCount] = useState(0)
  let locations = []

  const locationName = locationMod?.location?.state?.locationName

  const focusArea = locationMod?.location?.state?.focusAreas

  const submitData = {
    headers: headers,
    url: 'providers/search/get/all',
    body: JSON.stringify({
      ...filter,
      focusAreas: focusArea
        ? focusArea
        : filter.searchValue
        ? null
        : 'Primary Care',
      order: sort,
      latitude: authContext.browserLatLng.latitude
        ? authContext.browserLatLng.latitude
        : window.localStorage.getItem('latitude'),
      longitude: authContext.browserLatLng.longitude
        ? authContext.browserLatLng.longitude
        : window.localStorage.getItem('longitude'),
    }),
  }

  const changeLimit = (page) => {
    setFilter({ ...filter, callApi: true, ...{ offset: parseInt(page) } })
    localStorage.setItem('offset', parseInt(page))
  }

  const getSearchResult = async () => {
    if (authContext?.headerSearchText?.actionSubmit) {
      authContext.setHeaderSearchText({
        text: authContext?.headerSearchText?.text,
        actionSubmit: false,
      })
    }
    authContext.setLoader()
    setIsLoading(true)
    if (authContext.token) {
      headers['access-token'] = authContext.token
    }
    const result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    setIsLoading(false)
    const { totalCount, data, page } = result.body.result

    if (filter.available72hours) {
      if (data.length > 0) {
        setFilteredResults([...filteredResults, ...data])
      } else {
        setButtonDisabled(true)
      }
    } else {
      setSearchResults(data)
    }
    setAreaSupported(data.length !== 0)
    setTotalCount(totalCount)
    setPage(page)
    if (
      userCateType === 'patient' &&
      data.length === 0 &&
      modalForLocationShown !== locationName &&
      (filter.zip || filter.city)
    ) {
      showServiceAreaModal()
      setModalForLocationShown(locationName)
    }
  }

  useEffect(() => {
    if (!authContext.loading) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 200)
    }
  }, [authContext.loading])

  useEffect(() => {
    getSearchResult()
  }, [props.filter, filter, latlng, sort])

  useEffect(() => {
    if (authContext.headerSearchText?.actionSubmit) {
      setFilter((preState) => ({
        ...preState,
        callApi: true,
        searchValue: authContext?.headerSearchText?.text,
      }))
    }
  }, [authContext.headerSearchText])

  useEffect(() => {
    const isFromProfile = localStorage.getItem('fromProfile')
    const offset = parseInt(localStorage.getItem('offset'))
    if (isFromProfile) {
      localStorage.removeItem('fromProfile')
      setFilter({ ...filter, offset })
    }
  }, [])
  useEffect(() => {
    setMapData([])
    if (filteredResults.length && searchResults.length == 0) {
      filteredResults.map((item) =>
        locations.push({
          lat: parseFloat(item.providerPracticeLocations[0]?.latitude),
          lng: parseFloat(item.providerPracticeLocations[0]?.longitude),
        })
      )
    } else if (searchResults.length && filteredResults.length == 0) {
      searchResults.map((item) =>
        locations.push({
          lat: parseFloat(item.providerPracticeLocations[0]?.latitude),
          lng: parseFloat(item.providerPracticeLocations[0]?.longitude),
        })
      )
    }
    setMapData(locations)
  }, [filteredResults, searchResults])

  useEffect(() => {
    if (activeIndex !== null) {
      const element = document.getElementsByClassName('search-result-border')
      element[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [activeIndex])

  useEffect(() => {
    setActiveIndex(null)
  }, [filteredResults, searchResults])

  useEffect(() => {
    if (filter.city !== '' || filter.zip !== '' || filter.latitude !== null) {
      setMapZoomed(true)
    } else if (
      filter.city === '' &&
      filter.zip === '' &&
      filter.latitude === null
    ) {
      setMapZoomed(false)
    }
  }, [filter.city, filter.zip, filter.latitude])

  const handleLoadMore = (e) => {
    e.preventDefault()
    setFilter((preState) => ({
      ...preState,
      offset: page * 10,
    }))
  }

  const dateSelect = (value = false, event, type = false) => {
    let cdate = value
    if (type) {
      if (type == 'prev') {
        cdate = Global.addDays(startDate, -5)
      } else {
        cdate = Global.addDays(startDate, 5)
      }
    }
    setStartDate(cdate)
  }

  useEffect(() => {
    return () => {
      if (authContext?.headerSearchText?.text) {
        authContext.setHeaderSearchText({
          text: '',
          actionSubmit: false,
        })
      }
    }
  }, [authContext])

  const showServiceAreaModal = () => {
    setServiceAreaComingSoonModal(true)
    authContext.showModal()
  }

  const unsetServiceAreaModal = () => {
    setServiceAreaComingSoonModal(false)
  }

  const showFilterModal = (e) => {
    e.preventDefault()
    setFilterModal(true)
    authContext.showModal()
  }

  const unsetFilterModal = () => {
    setFilterModal(false)
  }

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          maxWidth: '1230px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {serviceAreaComingSoonModal ? (
          <Custommodal
            componentName={
              <ServiceAreaComingSoonModal
                zip={filter.zip}
                city={filter.city}
                locationName={locationName}
                unsetComponent={unsetServiceAreaModal}
              />
            }
            unsetComponent={unsetServiceAreaModal}
          />
        ) : (
          <></>
        )}
        <Helmet>
          <meta charSet="utf-8" />
          <title>DocScheduler | Book Doctor Appointments Online </title>
          <meta
            name="description"
            content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
          />
          <link
            rel="canonical"
            href={`https://www.docscheduler.com/patient/doctor-search-result`}
          />
          <meta property="og:site_name" content="DocScheduler" />
          <meta property="og:site" content="www.docscheduler.com" />
          <meta
            property="og:url"
            content={`https://www.docscheduler.com/patient/doctor-search-result`}
          />
          <meta name="twitter:card" content="Dashboard" />
          <meta name="twitter:site" content="@DocScheduler" />
          <meta
            property="og:title"
            content={`DocScheduler | Book Doctor Appointments Online`}
          />
          <meta
            name="twitter:title"
            content={`DocScheduler | Book Doctor Appointments Online`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://www.docscheduler.com/assets/images/logo-icon.svg"
          />
          <meta
            name="twitter:image"
            content="https://www.docscheduler.com/assets/images/logo-icon.svg"
          />
          <meta
            property="og:image:secure_url"
            content="https://www.docscheduler.com/assets/images/logo-icon.svg"
          />
          <meta
            property="og:description"
            content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
          />
          <meta
            name="twitter:description"
            content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
          />
        </Helmet>
        <div
          style={{ width: '65%' }}
          className={
            userCateType === 'patient' ? 'doctor-search-wrap' : 'search-wrap'
          }
        >
          {userCateType === 'patient' ? (
            <>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                <div style={{ margin: '10px 0' }} className="col-12">
                  <span
                    style={{ color: '#666666' }}
                  >{`${totalCount} results`}</span>
                </div>
              </Row>
              <AllFilters filter={filter} setfilter={setFilter} />
            </>
          ) : (
            <div className="sticky-provider">
              <Row>
                <div className="col-12">
                  <div className="search-result-heading-wrap">
                    <h1>Provider Search Results</h1>
                    <div className="filter-tab">
                      <Link onClick={(e) => showFilterModal(e)} to="#">
                        All Filters
                      </Link>

                      {filterModal && (
                        <Custommodal
                          unsetComponent={unsetFilterModal}
                          componentName={
                            <SearchFilter
                              filter={filter}
                              setfilter={setFilter}
                              unsetModal={unsetFilterModal}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          )}
          <div className="sticky-patient">
            <Row className="justify-content-end">
              {userCateType === 'patient' && searchResults.length > 0 && (
                <div
                  className="col-lg-12 col-xl-12"
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-end',
                      marginBottom: '10px',
                    }}
                  >
                    <select
                      style={{
                        order: '2',
                        borderColor: 'transparent',
                        lineHeight: '1.5',
                        maxWidth: '135px',
                        fontWeight: 'bold',
                        opacity: '0.7',
                        marginBottom: '1px',
                      }}
                      className="select-sort"
                      id="select-sort"
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="distance">Distance</option>
                      <option value="reviewCount">Most Reviewed</option>
                      <option value="reviewAverage">Highest Rated</option>
                    </select>
                    <span
                      style={{ color: '#000', opacity: '0.6' }}
                      htmlFor="select-sort"
                    >
                      Sort:
                    </span>
                  </div>
                  <div
                    className="schedule-shrinked"
                    style={{ width: '50%', marginRight: '20px' }}
                  >
                    <SchedulingTitle
                      startDate={startDate}
                      setStartDate={setStartDate}
                      dateSelect={dateSelect}
                      key={'schedulingTitle'}
                    />
                    <SchedulingDate
                      startDate={startDate}
                      setStartDate={setStartDate}
                      key={'schedulingDate'}
                    />
                  </div>
                </div>
              )}
            </Row>
          </div>
          <Row style={{ marginLeft: 0, marginRight: 0 }}>
            <div className="col-12 px-0">
              <div className="patient-doc-search-list">
                <div
                  style={{
                    justifyContent: searchResults.length > 0 ? null : 'center',
                    alignItems: searchResults.length > 0 ? null : 'center',
                  }}
                >
                  {filter.available72hours ? (
                    <>
                      {filteredResults.length > 0 ? (
                        <SponsoredDoc
                          mapZoomed={mapZoomed}
                          items={filteredResults}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          userCateType={userCateType}
                          startDate={startDate}
                          getProfile={getSearchResult}
                          componentName={'searchResult'}
                          getSearchResult={getSearchResult}
                          browserAddress={browserAddress}
                          key={'sponDoc'}
                        />
                      ) : areaSupported && !isLoading ? (
                        <h2 className="no-service">
                          <a href="/#" onClick={(e) => e.preventDefault()}>
                            No results
                          </a>
                        </h2>
                      ) : (
                        <h2 className="no-service">
                          <a href="/#" onClick={(e) => e.preventDefault()}>
                            Service Area Coming Soon
                          </a>
                        </h2>
                      )}
                    </>
                  ) : (
                    <>
                      {!isLoading ? (
                        <>
                          {searchResults.length > 0 ? (
                            // searchResults.map((each, index) => (

                            // )
                            <SponsoredDoc
                              mapZoomed={mapZoomed}
                              items={searchResults}
                              activeIndex={activeIndex}
                              setActiveIndex={setActiveIndex}
                              userCateType={userCateType}
                              startDate={startDate}
                              getProfile={getSearchResult}
                              componentName={'searchResult'}
                              getSearchResult={getSearchResult}
                              browserAddress={browserAddress}
                              key={'sponDoc'}
                            />
                          ) : // <></>
                          areaSupported ? (
                            <h2 className="no-service">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                No results
                              </a>
                            </h2>
                          ) : (
                            <h2 className="no-service">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                Service Area Coming Soon
                              </a>
                            </h2>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 px-0 paging-doctor-search">
              {filter.available72hours ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '30px',
                  }}
                >
                  <button
                    disabled={buttonDisabled}
                    className="loadMore"
                    type="button"
                    style={{
                      cursor: 'pointer',
                      backgroundColor: '#077db4',
                      color: '#fff',
                      fontSize: '16px',
                      borderRadius: '5px',
                      border: 'none',
                      padding: '10px 20px',
                    }}
                    onClick={(e) => handleLoadMore(e)}
                  >
                    Load more
                  </button>
                </div>
              ) : (
                <Pagination
                  filter={filter}
                  total={totalCount}
                  page={page}
                  limit={filter.limit}
                  range={4}
                  offset={filter.offset}
                  callBack={changeLimit}
                  key={'pagination'}
                />
              )}
            </div>
          </Row>
        </div>
        <div className="docs-map-wrapper">
          {mapData.length > 0 && (
            <Gmaps
              isExtended={true}
              isCareTeam={props.isCareteam}
              getProfile={props.getProfile}
              setActiveIndex={setActiveIndex}
              items={searchResults.length > 0 ? searchResults : filteredResults}
              gmapData={mapData}
              sticky={true}
              marginTop={0}
              zoomLabel={!mapZoomed ? 3 : 10}
              // ref={mapPrimayRefs.current[index]}
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default memo(SearchResults)
