import { Box } from '@chakra-ui/react'
import React from 'react'

interface PopUpCard {
  children: React.ReactNode
  name: 'create' | 'sign'
}

export const PopUpCard: React.FC<PopUpCard> = ({ children, name }) => {
  return (
    <Box
      pos={['static', 'static', 'static', 'absolute']}
      right={name === 'sign' ? 0 : 36}
      top={20}
      flexDirection="column"
      border={['none', 'none', 'none', '1px solid']}
      borderRadius="4px"
      borderColor="border-gray"
      p="20px 25px"
      width={['100%', '100%', '100%', 'none']}
      zIndex={10}
      bg="white"
      gap={4}
      boxShadow={['none', 'none', 'none', '0px 4px 14px 0px #E4EEF3']}
      // _before={{
      //   backgroundColor: '#fff',
      //   border: '1px solid #dfdfdf',
      //   borderWidth: '0 1px 1px 0',
      //   content: '',
      //   display: 'inline-block',
      //   padding: '5px',
      //   position: 'absolute',
      //   right: '27px',
      //   top: '-6px',
      //   transform: 'rotate(-135deg)',
      // }}
    >
      {children}
    </Box>
  )
}
