import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Youtubevideo from '../../components/youtube-video/youtube-video'
import Footer from '../../global/footer/footer'
import HomeHeader from '../../global/header/header'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'

const HowWorks = () => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState([])

  const getDetails = async (id) => {
    authContext.setLoader()

    let formData = {
      id: 7,
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: 'content-management/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setData(result.body?.result)
    authContext.unsetLoader()
  }

  useEffect(() => {
    getDetails()
  }, [])
  return (
    <Fragment>
      {/* header */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | How we works</title>
        <meta name="description" content="DocScheduler &#x27;s how we work" />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/how-docscheduler-works"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/how-docscheduler-works`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content={`DocScheduler | How we works`} />
        <meta name="twitter:title" content={`DocScheduler | How we works`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="DocScheduler &#x27;s how we work"
        />
        <meta
          name="twitter:description"
          content="DocScheduler &#x27;s how we work"
        />
      </Helmet>
      <Container fluid className="header px-0">
        <HomeHeader />
      </Container>

      <Container>
        <Row>
          <div className="col-12">
            <div className="how-works-wrap">
              <h1>{data.page_title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              ></div>
              <Youtubevideo />
            </div>
          </div>
        </Row>
      </Container>

      <Footer />
    </Fragment>
  )
}

export default HowWorks
