import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PatientLinks } from '../../linkFile'

const RedirectionPage = (props) => {
  // const location = useLocation();
  const goBack = (e) => {
    e && e.preventDefault()
    props.history.goBack()
  }

  useEffect(() => {
    setTimeout(() => {
      if (props.location.state && props.location.state.detail) {
        window.location.replace(props.location.state.detail)
      }
    }, 5000)
  })
  return (
    <Fragment>
      {/* header */}

      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
              <div className="col-12 contactus no-border no-shadow">
                <Row>
                  <div className="error-404 suc-msg">
                    {/* <h3 className="italic error-msg-404">
                        The page you're looking for can't be found.
                      </h3> */}
                    <h3 className="error-msg-404">
                      You are leaving DocScheduler and will be automatically
                      redirected.
                    </h3>
                    <ul>
                      <li>
                        <a onClick={goBack}> Previous Page</a>
                      </li>
                      <li>
                        <Link to={PatientLinks.home}> Home Page</Link>
                      </li>
                      <li>
                        <Link to={PatientLinks.contactus}> Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  )
}

export default RedirectionPage
