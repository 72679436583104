import React, { useContext, useEffect, useState } from 'react'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { Row } from 'react-bootstrap'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'

const externalReviewers = [
  {
    name: 'HealthGrades',
    source: 'healthgrades',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
  {
    name: 'Vitals',
    source: 'vitals',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
  {
    name: 'ZocDoc',
    source: 'zocdoc',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
  {
    name: 'Google',
    source: 'google',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
  {
    name: 'Yelp',
    source: 'yelp',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
  {
    name: 'Facebook',
    source: 'facebook',
    averageRating: null,
    numberOfReviews: null,
    url: '',
  },
]

export const ExternalReviews = ({ userId }) => {
  const authContext = useContext(AuthContext)

  const [totals, setTotals] = useState()

  const [reviews, setReviews] = useState(externalReviewers)

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
  })

  const [validationErrors, setValidationErrors] = useState([])

  const fetchExternalReviews = async (userId) => {
    authContext.setLoader()

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/digital-reputation/external/' + userId,
    }

    let data = await NetworkLayer.getRequest(submitData)
    const result = data?.result
    setTotals(result?.totals)
    const externalReviews = result?.externalReviews
    if (externalReviews) {
      const mergedArray = externalReviewers.map((item) => {
        const matchedObject = externalReviews.find(
          (obj) => obj.source === item.source
        )
        return { ...item, ...matchedObject }
      })
      setReviews(mergedArray)
    }
    authContext.unsetLoader()
  }

  const successMsg = (type, message) => {
    setModalProps({
      type: type,
      msg: message,
    })
    authContext.showResponseModal()
  }

  const validateInputs = () => {
    const errors = []

    const nonEmptyInputs = reviews.filter((rev) => {
      if (rev.averageRating || rev.numberOfReviews || rev.url) {
        return rev
      }
    })

    nonEmptyInputs.map((rev) => {
      if (!rev.averageRating || !rev.numberOfReviews) {
        errors.push(rev)
      }
    })

    setValidationErrors(errors)

    if (!errors.length) {
      saveReviews(nonEmptyInputs, userId)
        .then(() => {
          fetchExternalReviews(userId)
        })
        .catch((error) => {
          console.log(error.message)
        })
    }
  }

  const saveReviews = async (validateReviews, userId) => {
    authContext.unsetLoader()
    const formData = {
      userId: userId,
      reviews: validateReviews,
    }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/digital-reputation/external',
      body: JSON.stringify(formData),
    }

    let data = await NetworkLayer.postData(submitData)
    if (data.body.code === 200) {
      successMsg('success', data.body.message)
    } else {
      successMsg('error', data.body.message)
    }
    authContext.unsetLoader()
  }

  const onChange = (e) => {
    const { name, id, value } = e.currentTarget
    const activeInput = reviews.find((review) => review.source === id)
    activeInput[name] = value
    const newState = reviews.map((obj) =>
      obj.source === activeInput.source ? { ...obj, completed: true } : obj
    )
    setReviews(newState)
  }

  const onSubmit = () => {
    validateInputs(userId)
  }

  useEffect(() => {
    fetchExternalReviews(userId)
  }, [])

  return (
    <div className="reviews-table">
      <Row>
        {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      </Row>
      <div className="reviews-headings">
        <div className="empty" />
        <span className="reviews-heading">Average Review</span>
        <span className="reviews-heading">Number of Reviews</span>
        <span className="reviews-heading">Website link</span>
      </div>
      <div className="reviews-content">
        {reviews.map((rating, index) => {
          const input = validationErrors.find(
            (error) => error.source === rating.source
          )

          return (
            <div className="review" key={index}>
              <span className="review-title">{rating.name}</span>
              <div className="review-container">
                <input
                  id={rating.source}
                  name="averageRating"
                  type="text"
                  className="input-pro"
                  placeholder=""
                  value={rating.averageRating}
                  onChange={(e) => onChange(e)}
                />
                <small className="error-input mt-1">
                  {input && !input?.averageRating ? 'Field is required' : ''}
                </small>
              </div>
              <div className="review-container">
                <input
                  id={rating.source}
                  name="numberOfReviews"
                  type="text"
                  className="input-pro"
                  placeholder=""
                  value={rating.numberOfReviews}
                  onChange={(e) => onChange(e)}
                />
                <small className="error-input mt-1">
                  {input && !input?.numberOfReviews ? 'Field is required' : ''}
                </small>
              </div>
              <div className="review-container">
                <input
                  id={rating.source}
                  name="url"
                  type="text"
                  className="input-pro"
                  placeholder=""
                  value={rating.url}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
          )
        })}
        <div className="review">
          <span className="review-title">DocScheduler</span>
          <div className="review-container">
            <div className="review-value">
              <span>{totals?.docSchedulerAverage}</span>
            </div>
          </div>
          <div className="review-container">
            <div className="review-value">
              <span>{totals?.docSchedulerCount}</span>
            </div>
          </div>
        </div>
        <div className="review">
          <span className="review-title">
            <strong style={{ fontSize: '18px' }}>TOTALS</strong>
          </span>
          <div className="review-container">
            <div className="totals-value">
              <span>{totals?.externalAverage}</span>
            </div>
          </div>
          <div className="review-container">
            <div className="totals-value">
              <span>{totals?.externalCount}</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="save-button">
            <input
              type="button"
              className="subscription-btn next-btn-pro"
              value="Save"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
