import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import RatingFilter from '../../../components/admin/rating-review-admin/rating-filter'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import Custommodal from '../../../global/modal/modal'
import { AdminLinks } from '../../../linkFile'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import RatingCommnent from './rating-detail-modal'

let _dateFilter = ''
let searchText = ''
const RatingTable = () => {
  const authContext = useContext(AuthContext)
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [data, setData] = useState([])
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [viewdetails, setViewdetails] = useState([])
  const [search, setSearch] = useState('')
  const [sort, setsort] = useState('ASC')
  const [sortName, setSortName] = useState('status')

  // FOR POPUP MODAL
  const modalList = ['ratingComment']
  const openProfileTabModal = (modalName, value) => {
    getDetails(value?.id).then(() => {
      settabvisibleComponent(modalName)
      authContext.showModal()
    })
  }

  const getList = async (value) => {
    authContext.setLoader()
    // FORM DATA
    let formData = {}
    if (value || searchText) {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        searchValue: searchText,
        sortOrder: sort,
        sortBy: sortName,
      }
    } else {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        sortOrder: sort,
        sortBy: sortName,
      }
    }

    if (_dateFilter) {
      const dates = _dateFilter.split(' - ')
      formData.startdate = dates[0]
      formData.enddate = dates[1]
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/rating/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    try {
      let result = await NetworkLayer.postData(submitData)
      setData(result?.body?.result?.rows)
      settotalCount(result?.body?.result?.count)
    } catch (err) {
      console.log('ERROR :', err)
    }

    authContext.unsetLoader()
  }

  const getDetails = async (id) => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/rating/get/' + id,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)
    setViewdetails(result?.result)
    authContext.unsetLoader()
  }

  const updateStatus = async (id, status) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: id,
      status: status,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/rating/updateStatus/' + id,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    getList()
  }

  const deleteRecord = async (id) => {
    // FORM DATA
    const formData = {
      id: id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/rating/delete/' + id,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    if (window.confirm('Are you sure you want to delete this provider?')) {
      authContext.setLoader()
      try {
        let result = await NetworkLayer.postData(submitData)
        getList()
      } catch (err) {
        console.log(err)
      }
      authContext.unsetLoader()
    }
  }

  useEffect(() => {
    getList()
    return () => {
      _dateFilter = ''
    }
  }, [filter.offset, sort])

  // PAGINATION CALLBACK
  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  const toggleStatus = (values) => {
    let status = values.status == 1 ? 0 : 1
    let id = values.id
    updateStatus(id, status)
  }

  const changeSorting = (e) => {
    let name = e.currentTarget.getAttribute('name')
    setSortName(name)
    setsort(sort == 'ASC' ? 'DESC' : 'ASC')
  }

  const searchFilter = (value, dateFilter = '') => {
    _dateFilter = dateFilter
    setfilter({ offset: 0, limit: 10 })
    searchText = value
    getList(value)
  }

  return (
    <Fragment>
      <RatingFilter search={searchFilter} />
      <Loadermodal />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'ratingComment' ? (
              <RatingCommnent data={viewdetails} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <div className="table-report-scroll mt-0">
        <table className="table">
          <thead>
            <tr>
              <th>Provider</th>
              <th>Patient</th>
              <th className="data-sort">
                <span className="sort-outer">
                  Ratings
                  <span
                    className="data-sort-icon"
                    name="rating"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th>Comment</th>
              <th>
                <div className="sort-doubleline">
                  <span className="sort-outer" style={{ width: '131px' }}>
                    Submission Date
                    <span
                      className="data-sort-icon"
                      name="createdAt"
                      onClick={changeSorting}
                    >
                      <DataSortIcon />
                    </span>
                  </span>
                </div>
              </th>
              <th>
                <span className="sort-outer">
                  Reviewed
                  <span
                    className="data-sort-icon"
                    name="status"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length == 0 && (
              <tr>
                <td>No Records Found</td>
              </tr>
            )}
            {data &&
              data.map((value, index) => (
                <tr key={index}>
                  <td>{`${value.providerInfo?.providerName?.firstName} ${value.providerInfo?.providerName?.lastName}`}</td>
                  <td>{`${value.patientsInfo?.patientName?.firstName} ${value.patientsInfo?.patientName?.lastName}`}</td>
                  <td>
                    <span className="rating" style={{ width: '140px' }}>
                      <span
                        className={`rating${parseFloat(
                          value.rating.replace('.', '')
                        )}`}
                      ></span>
                    </span>
                  </td>
                  <td>
                    <div className="">
                      {value.message.length > 25
                        ? `${value.message.substring(0, 25)}...`
                        : value.message}
                    </div>
                  </td>
                  <td>{moment(value?.createdAt).format('MM/DD/YYYY')}</td>
                  <td align="center">
                    <div className="radio-tick radio-tick-center">
                      <input
                        type="radio"
                        name={`tick${value.id}`}
                        id={`tick${value.id}`}
                        checked={value.status}
                        onClick={() => toggleStatus(value)}
                      />
                      <label className="checkbox" htmlFor={`tick${value.id}`}>
                        <span></span>
                      </label>
                    </div>
                  </td>
                  <td>
                    <Link
                      onClick={(e) => {
                        e.preventDefault()
                        openProfileTabModal('ratingComment', value)
                      }}
                      className="table-action view-all"
                    >
                      View
                    </Link>
                    <Link
                      to={`${AdminLinks.admin_rating_management}/${value.id}`}
                      className="table-action view-all"
                    >
                      Edit
                    </Link>
                    <Link
                      className="table-action view-all"
                      onClick={() => {
                        deleteRecord(value.id)
                      }}
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="report-page mt-4">
        <Pagination
          total={totalCount}
          limit={filter.limit}
          range={4}
          offset={filter.offset}
          callBack={changeLimit}
        />
      </div>
    </Fragment>
  )
}

export default RatingTable
