import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useRef, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import exportFromJSON from 'export-from-json'
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg'

import AuthContext from '../../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../../reactLayer/Network/Network'

const InsuranceExport = (props) => {
  const { type, subtype, isdegree, isschool } = props
  const authContext = useContext(AuthContext)
  const [data, setData] = useState('')
  const [selectsubtype, setSelectsubtype] = useState(props.subtype)
  const csvLinkEl = useRef()
  const [header, setHeader] = useState([{ label: '', key: 'name' }])

  let text = 'Insurance'

  if (type == 'Speciality') {
    text = 'Speciality'

    if (isdegree == 1) {
      text = 'Degree'
    }

    if (isschool == 1) {
      text = 'School'
    }
  }

  const getExportList = async () => {
    let url = ''
    let formData = {}

    if (type == 'Speciality') {
      // Add Speciality

      if (isdegree == 1) {
        url = 'admin/degree/export'
        // Add Medical OR Dental Degree
        formData = {
          category: selectsubtype,
        }
        setHeader([
          { label: 'id', key: 'id' },
          { label: selectsubtype + ' Degree List', key: 'name' },
        ])
      }
      if (isdegree == 0) {
        url = 'admin/specialities/export'
        // Add Medical OR Dental Speciality
        formData = {
          category: selectsubtype,
        }
        setHeader([
          { label: 'id', key: 'id' },
          { label: selectsubtype + ' Specialities List', key: 'name' },
        ])
      }

      if (isschool == 1) {
        url = 'admin/schools/export'
        // Add Medical OR Dental Speciality
        formData = {
          category: selectsubtype,
        }
        setHeader([
          { label: 'id', key: 'id' },
          { label: selectsubtype + ' School List', key: 'name' },
        ])
      }
    }

    if (type == 'Insurance') {
      url = 'admin/insurance/export'
      // Add Insurance
      formData = { category: selectsubtype }
      setHeader([
        { label: 'id', key: 'id' },
        { label: selectsubtype + ' Insurance List', key: 'name' },
      ])
    }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    authContext.setLoader()

    try {
      let result = await NetworkLayer.postData(submitData)
      setData(result.body.result)
      authContext.hideModal()
      authContext.unsetLoader()
      return result.body.result
    } catch (err) {
      console.log(err)
    }
    authContext.unsetLoader()
  }

  const downloadReport = async () => {
    await getExportList().then((res) => {
      if (isschool == 1) {
        const fileName = 'MedicalSchools'
        const exportType = exportFromJSON.types.xls

        const data = res

        exportFromJSON({ data, fileName, exportType })
      } else {
        csvLinkEl.current.link.click()
      }
    })
  }

  const closeModal = () => {
    authContext.hideModal()
  }

  const name = isschool ? 'School Data' : type

  return (
    <Fragment>
      <Container>
        <div className="search-modal-wrap">
          <div className="edit-all-filter">
            <Row>
              <div className="col-12 px-0">
                <div className="modal-head">Export {name}</div>
              </div>
            </Row>
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12">
                  <div className="rating-export-wrap provider-form-edit">
                    <div className="rating-export-inner select-option">
                      <label className="pro-label">{text} Type</label>
                      <select
                        className="select"
                        onChange={(e) => {
                          setSelectsubtype(e.target.value)
                        }}
                      >
                        <option value="Medical">Medical</option>
                        <option value="Dental" selected={subtype == 'Dental'}>
                          Dental
                        </option>
                        <option value="All">All</option>
                      </select>
                      <i className="select-option-icon">
                        <SelectOptionIcon />
                      </i>
                    </div>
                    <Row>
                      <div className="col-12 mb-4 all-fltrbtn">
                        <input
                          type="button"
                          className="rev-btn-pro"
                          value="Cancel"
                          onClick={closeModal}
                        />
                        <input
                          type="button"
                          className="next-btn-pro"
                          value="Submit"
                          onClick={downloadReport}
                        />
                        <CSVLink
                          headers={header}
                          filename={`${selectsubtype}-${type}-list.csv`}
                          data={data}
                          ref={csvLinkEl}
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default InsuranceExport
