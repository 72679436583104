import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ListGroup, Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import { ReactComponent as FilterIcon } from '../../../admin/assets/images/filter.svg'
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg'
import { AdminLinks } from '../../../linkFile'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../../reactLayer/Network/Network'

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

const ManagementFilter = (props) => {
  const wrapperCateRef = useRef(null)
  const authContext = useContext(AuthContext)
  const { search } = props
  const [filtervalue, setFiltervalue] = useState()
  const [displayvalue, setDisplayvalue] = useState()
  const [suggestionOptions, setSuggestionOptions] = useState([])
  const [displayCate, setDisplayCate] = useState(false)
  const [data, setData] = useState([])
  const csvLinkEl = useRef()
  const [header, setHeader] = useState([{ label: '', key: 'name' }])

  const onInputChange = (e, type) => {
    e.preventDefault()
    if (type == 'typing') {
      setFiltervalue(e.target.value)
      setDisplayvalue(e.target.value)
    }
    if (e.target.value == '') {
      search(e.target.value)
    }
  }

  const getAllFindResult = useCallback(async (input) => {
    if (input.length >= 3) {
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.adminToken,
        },
        url: 'admin/appointment-history/suggestion',
        body: JSON.stringify({
          searchValue: input,
          userId: props.patientId,
        }),
      }

      authContext.setLoader()
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()

      if (result?.body?.status) {
        let providersList = result.body.result.map(({ id, providers }) => ({
          id: id,
          data: providers,
        }))

        if (providersList.length > 0) {
          setSuggestionOptions(providersList)
          setDisplayCate(true)
        }
      }
    }
  }, [])

  const debounceGet = useRef(
    debounce((nextValue) => getAllFindResult(nextValue), 1000)
  ).current

  const getExportList = async () => {
    setHeader([
      { label: 'id', key: 'id' },
      { label: ' Patient Name', key: 'patientName' },
      { label: ' Age', key: 'age' },
      { label: ' Sex', key: 'gender' },
      { label: ' Joined Date', key: 'joining_date' },
      { label: 'Email', key: 'email' },
      { label: ' Medical Ins. Name', key: 'medicalIns' },
      { label: ' Dental Ins. Name', key: 'dentalIns' },
      { label: 'Location', key: 'location' },
      { label: 'Last Appt. Request Date', key: 'lastAppointmentDate' },
    ])

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/exportPatientCSV',
    }

    // SEND REQUEST

    authContext.setLoader()
    try {
      let result = await NetworkLayer.getRequest(submitData)

      let datares = result?.result.map((item) => ({
        id: item.id,
        email: item.email,
        patientName: `${item?.patients?.firstName} ${item?.patients?.lastName}`,
        dob: item?.patients?.dob,
        gender: item?.patients?.gender,
        phoneNumber: item?.patients?.phoneNumber,
        age: item?.patients?.age,
        medicalIns: item?.patientMedicalInsurances[0]?.insurances?.name,
        dentalIns: item?.patientDentalInsurances[0]?.insurances?.name,
        joining_date: item?.patients?.joining_date,
        location: `${item?.patients?.cities?.name} ${item?.patients?.cities?.states?.name}`,
        lastAppointmentDate: item?.patients?.appointmentDate,
      }))

      setData(datares)
    } catch (err) {
      console.log(err)
    }
    authContext.unsetLoader()
  }

  const downloadReport = async () => {
    let result = await getExportList()
    csvLinkEl.current.link.click()
  }

  useEffect(() => {
    if (filtervalue == '' && search) {
      debounceGet('')
    } else {
      if (filtervalue && filtervalue.length > 2) {
        debounceGet(filtervalue)
      }
    }
  }, [filtervalue])

  const _renderListItem = (title = 'PROVIDERS', items) => {
    return (
      <ListGroup.Item>
        {title}
        <ListGroup className="borderless">
          {items.map((each, index) => (
            <ListGroup.Item
              className="cateFindItem"
              key={title + '_' + index}
              onClick={() => updateCateDex(each.data)}
            >
              {`${each.data.firstName} ${each.data.lastName}`}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ListGroup.Item>
    )
  }

  const updateCateDex = (each) => {
    setDisplayCate(false)
    setDisplayvalue(`${each.firstName} ${each.lastName}`)
    search(`${each.firstName} ${each.lastName}`)
  }

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    if (wrap && !wrap.contains(event.target)) {
      setDisplayCate(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <Fragment>
      <Row className="table-sort-filter">
        <div className="col-xl-9 filter-left-wrap">
          <form>
            <div className="sort-filterwrap" ref={wrapperCateRef}>
              <span className="filter-input mb-3 mr-md-2">
                <input
                  type="text"
                  className="input-pro"
                  name="usernae"
                  id="username"
                  placeholder="Search provider by name, phone, location"
                  autoComplete="off"
                  value={displayvalue}
                  onChange={(e) => onInputChange(e, 'typing')}
                />
                <i className="search-icon" style={{ cursor: 'pointer' }}>
                  <SearchIcon onClick={() => search(filtervalue)} />
                </i>
              </span>
              {displayCate && suggestionOptions.length > 0 && (
                <div className="autoContainerCate" style={{ width: '52%' }}>
                  <div className="autoContenrCate">
                    <ListGroup>
                      {suggestionOptions.length > 0 &&
                        _renderListItem('PROVIDERS', suggestionOptions)}
                    </ListGroup>
                  </div>
                </div>
              )}
              {/* <span className="sort-select mb-3 mr-2">
                                <select class="input-pro sort-select-inner">
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                    <option value="">Sort By</option>
                                </select>
                                <span className="select-icon"><SelectIcon /></span>
                            </span> */}
              <span className="sort-select mb-3">
                <button className="filter-btn" type="button">
                  <span className="filer-icon">
                    <FilterIcon />
                  </span>
                  <span>All Filters</span>
                </button>
              </span>
            </div>
          </form>
        </div>
        <div className="col-xl-3 text-right filter-right-wrap">
          <Link
            to={AdminLinks.admin_patient_add_detail}
            className="add-patient-btn"
          >
            +Add Patient
          </Link>
          <Link to={'#'} className="link-btn report-btn mb-3">
            Report
          </Link>
          <Link
            to={'#'}
            className="link-btn export-btn ml-2 mb-3"
            onClick={downloadReport}
          >
            Export
          </Link>
        </div>
        <CSVLink
          headers={header}
          filename={`Patients-list.csv`}
          data={data}
          ref={csvLinkEl}
        />
      </Row>
    </Fragment>
  )
}

export default ManagementFilter
