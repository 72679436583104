import { useContext, useEffect, useRef, useState } from 'react'
import AuthContext from '../../reactLayer/auth/AuthContext'

let autoComplete

const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

function handleScriptLoad(setCity, setZip, setQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ['(regions)'], componentRestrictions: { country: 'us' } }
  )
  //   autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener('place_changed', () => {
    const place = autoComplete.getPlace()

    const geo = place.geometry.location

    getCurrentLocationZipCode(geo.lat(), geo.lng(), setZip)

    setCity(place.name)
    setQuery(place.formatted_address)
  })
}

const getCurrentLocationZipCode = async (lat, lng, setZipCode) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&result_type=postal_code`
  try {
    const result = await fetch(url)
    const json = await result.json()
    const zipCode = json.results[0]?.address_components[0].short_name
    setZipCode(zipCode)
  } catch (e) {
    console.log(e)
  }
}

const SearchLocationInput = ({
  setCity,
  setZip,
  setLocationName,
  locationName,
}) => {
  const [userZip, setUserZip] = useState('')
  const autoCompleteRef = useRef(null)
  const { browserLatLng } = useContext(AuthContext)

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&libraries=places&region=us`,
      () => handleScriptLoad(setCity, setZip, setLocationName, autoCompleteRef)
    )
  }, [])

  useEffect(() => {
    const { latitude, longitude } = browserLatLng
    if (latitude && longitude) {
      getCurrentLocationZipCode(latitude, longitude, setUserZip)
    }
  }, [browserLatLng, setUserZip])

  return (
    <div>
      <input
        className="input-home"
        placeholder="Zip Code or City"
        name="find"
        ref={autoCompleteRef}
        type="text"
        onChange={(event) => {
          setLocationName(event.target.value)
        }}
        value={locationName}
      />
    </div>
  )
}

export default SearchLocationInput
