import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
// Component Improt From Here
import Twostepauth from '../../components/provider-login/twostep'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'

const Twofactauth = () => {
  return (
    <Fragment>
      <HeaderMain />
      <Twostepauth ltype={'provider'} />
      <Footer />
    </Fragment>
  )
}

export default Twofactauth
