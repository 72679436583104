import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import { ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'
import ProfileLinks from './profile-links'
import SecurityLinks from './security-links'

const MyAccount = (props) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [key, setKey] = useState()
  const changeTab = (e) => {
    e.preventDefault()
    // setKey(e.currentTarget.dataset.rbeventkey);
    let path =
      e.currentTarget.dataset.rbeventkey === '1'
        ? ProviderLinks.accountprofile
        : ProviderLinks.accountsecurity
    history.push({
      pathname: `${path}`,
    })
  }

  useEffect(() => {
    setKey(
      props.match.params.page && props.match.params.page == 'profile'
        ? '1'
        : '2'
    )
  }, [props.match.params.page])
  return (
    <Fragment>
      {/* header */}
      <HeaderMain />
      <Container className="my-account-wrap">
        <Row>
          <div className="col-12">
            <h1>My Account</h1>
          </div>
          <div className="col-12">
            <div className="my-acc-top-head">
              <div
                onClick={(e) => changeTab(e)}
                role="tab"
                data-rbeventkey="1"
                aria-controls="controlled-tab-account-information"
                aria-selected="true"
                tabIndex={key == '1' ? '' : '-1'}
                className={key == '1' ? 'active' : ''}
              >
                <img
                  src="/assets/images/myacc-icon.svg"
                  className="acc-info-icon"
                />
                <span className="under-line">Account Information</span>
              </div>
              {!authContext.user.accountInfo.subRoleId && (
                <div
                  onClick={(e) => changeTab(e)}
                  role="tab"
                  data-rbeventkey="2"
                  aria-controls="controlled-tab-security"
                  aria-selected="true"
                  tabIndex={key == '2' ? '' : '-1'}
                  className={key == '2' ? 'active' : ''}
                >
                  <img
                    src="/assets/images/security-icon.svg"
                    className="acc-info-icon"
                  />
                  <span className="under-line">Security</span>
                </div>
              )}
            </div>
          </div>
        </Row>
      </Container>
      {key == '1' ? (
        <div
          aria-labelledby="controlled-tab-account-information"
          role="tabpanel"
          aria-hidden={key == '1' ? 'true' : 'false'}
        >
          <ProfileLinks />
        </div>
      ) : (
        <div
          aria-labelledby="controlled-tab-account-information"
          role="tabpanel"
          aria-hidden={key == '1' ? 'true' : 'false'}
        >
          <SecurityLinks />
        </div>
      )}
      <Footer />
    </Fragment>
  )
}

export default MyAccount
