import { Link } from 'react-router-dom'
import AuthContext from '../../../../reactLayer/auth/AuthContext'
import React, { useContext, useState } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { PatientLinks, ProviderLinks } from '../../../lib/links'
import { Typography } from '../../../style/typography'
import { ChevronLeftIcon } from '../../../style/icons'
import { PopUpCard } from './PopUpCard'

interface Props {
  direction?: 'row' | 'col'
  setVisibleComponent: (value: string) => void
}

const typeStyles = {
  fontWeight: 'bold',
  fontSize: '20px',
  color: 'black',
}

const flexStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: 'gray',
  paddingBottom: '12px',
}

const Links: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <Typography
      fontSize="20px"
      _hover={{ color: 'primary' }}
      cursor={'pointer'}
    >
      {children}
    </Typography>
  )
}

type Menu = 'create' | 'sign' | ''

export const HeaderActions: React.FC<Props> = ({
  direction = 'row',
  setVisibleComponent,
}) => {
  const authContext = useContext(AuthContext)

  const [openMenu, setOpenMenu] = useState<Menu>('')

  const openLoginModal = (e, modalName: string) => {
    // authContext.hideModal()
    e && e.preventDefault()
    setVisibleComponent(modalName)
    authContext.showModal()
  }

  return (
    <Flex
      direction={direction === 'col' ? 'column' : 'row'}
      alignItems="flex-end"
      position="relative"
      gap={[4, 4, 4, 1]}
      width="100%"
    >
      <Flex
        direction="column"
        alignItems="center"
        p="8px 30px 8px 10px"
        cursor="pointer"
        onClick={() => setOpenMenu(openMenu === 'create' ? '' : 'create')}
        gap={8}
      >
        <Flex gap={2}>
          <Typography fontSize="16px" fontWeight={500} color="text-dark-gray">
            Create Account
          </Typography>
          <Box
            sx={{
              transform: 'rotate(-90deg)',
            }}
          >
            <ChevronLeftIcon />
          </Box>
        </Flex>
      </Flex>
      {openMenu === 'create' && (
        <PopUpCard name="create">
          <Flex {...flexStyles} gap={4}>
            <Typography {...typeStyles}>Patients</Typography>
            <Link to={PatientLinks.register_step1}>
              <Links>
                <>Create Account</>
              </Links>
            </Link>
          </Flex>
          <Flex {...flexStyles} borderBottom="none" gap={4}>
            <Typography {...typeStyles}>Doctors</Typography>
            <Link to={ProviderLinks.freeprofile}>
              <Links>
                <>List Your Practice</>
              </Links>
            </Link>
          </Flex>
        </PopUpCard>
      )}
      <Flex direction="column">
        <Button
          variant="outline"
          minH="40px"
          p={2}
          onClick={() => setOpenMenu(openMenu === 'sign' ? '' : 'sign')}
          rightIcon={
            <Box
              sx={{
                transform: 'rotate(-90deg)',
              }}
            >
              <ChevronLeftIcon />
            </Box>
          }
        >
          Sign In
        </Button>
      </Flex>
      {openMenu === 'sign' && (
        <PopUpCard name="sign">
          <Flex {...flexStyles} gap={4}>
            <Typography {...typeStyles}>Patients</Typography>
            <div
              onClick={(e) => {
                window.localStorage.setItem('role', String(4))
                window.localStorage.removeItem('subRole')
                openLoginModal(e, 'login')
              }}
            >
              <Links>
                <>Sign In</>
              </Links>
            </div>
          </Flex>
          <Flex width="100%" {...flexStyles} gap={4}>
            <Typography {...typeStyles}>Doctors</Typography>
            <div
              onClick={() => {
                window.localStorage.setItem('role', String(3))
                window.localStorage.removeItem('subRole')
              }}
            >
              <Link to={ProviderLinks.login}>
                <Links>
                  <>Sign In</>
                </Links>
              </Link>
            </div>
          </Flex>

          <Flex {...flexStyles} borderBottom="none" gap={4}>
            <Typography {...typeStyles}>Team Members</Typography>
            <div
              onClick={() => {
                window.localStorage.setItem('role', String(3))
                window.localStorage.setItem('subRole', String(3))
              }}
            >
              <Link to={ProviderLinks.login}>
                <Links>
                  <>Sign In</>
                </Links>
              </Link>
            </div>
          </Flex>
        </PopUpCard>
      )}
    </Flex>
  )
}
