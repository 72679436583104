import React, { useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'

const noop = () => {}

const bookmarkDoctor = (props) => {
  const callback = props.callback || noop

  const authContext = useContext(AuthContext)

  const [showModal, setShowModal] = useState(false)
  const [isBookmarked, setIsBookmarked] = useState()
  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    redirection: '',
  })

  useEffect(() => {
    setIsBookmarked(props.isSaved)
  }, [props.isSaved])

  const toggleFavoriteDoc = async (id, type = 'save', e) => {
    e && e.preventDefault()
    if (authContext.token !== null) {
      let url = ''
      if (type === 'save') {
        setIsBookmarked(true)
        url = 'patients/favourite/doctor/add'
      } else {
        setIsBookmarked(false)
        url = 'patients/favourite/doctor/remove'
      }
      // FORM DATA
      const formData = {
        toUser: id,
      }
      // STRUCTURE LOGIN REQUEST DATA
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: url,
        body: JSON.stringify(formData),
      }
      // SEND REQUEST
      let result = await NetworkLayer.postData(submitData)

      if (result.body.code === 200) {
        setModalProps({
          type: 'success',
          msg: 'Doctor saved successfully',
        })
      } else {
        setModalProps({
          type: 'error',
          msg: 'Doctor save failed',
        })
        setIsBookmarked(!isBookmarked)
      }

      callback()
      setShowModal(true)
    } else {
      openLoginModal(e)
    }
  }

  const openLoginModal = (e) => {
    e && e.preventDefault()
    authContext.showLoginModal({
      modalName: 'login',
    })
  }

  return (
    <div className="">
      <Row>{showModal ? <Msgmodal {...modalProps} /> : <></>}</Row>
      {isBookmarked ? (
        <div
          className="save-doc-bookmark"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            toggleFavoriteDoc(props.id, 'unsave', e)
          }}
        >
          <img
            src="/assets/images/save-icon.png"
            alt="save"
            className="scale icon-docsave"
          />
        </div>
      ) : (
        <div
          className="save-doc-bookmark"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            toggleFavoriteDoc(props.id, 'save', e)
          }}
        >
          <img
            src="/assets/images/unsave-icon.svg"
            alt="save"
            className="scale icon-docsave"
          />
        </div>
      )}
    </div>
  )
}

export default bookmarkDoctor
