import { Box, Flex } from '@chakra-ui/react'
import { BurgerMenu } from '../../Layout/BurgerMenu'

interface Props {
  onClick: () => void
  children: JSX.Element
}

export const MobileSearchWrapper: React.FC<Props> = ({ onClick, children }) => {
  return (
    <Flex
      position="fixed"
      bg="white"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={9999}
      p={8}
      direction="column"
      gap={8}
    >
      <Box>
        <BurgerMenu mobileMenuVisible setMobileMenuVisible={onClick} />
      </Box>
      {children}
    </Flex>
  )
}
