import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import PaymentDetails from '../payment-plan/payment-details'
import PaymentPlans from '../payment-plan/payment-plan'
import ProviderStep from './provider-steps'

const ProviderStep5 = (props) => {
  const [plans, setplans] = useState({})
  const [selectedplan, setselectedplan] = useState(2)
  const getSelectedPlan = async (id) => {
    let result = await NetworkLayer.getRequest({
      url: 'providers/plan/details/get/' + id,
    })
    if (result.status === true) {
      setplans(result.result.planDetails)
      setselectedplan(id)
    }
  }
  useEffect(() => {
    if (props.match.params.id && parseInt(props.match.params.id)) {
      getSelectedPlan(props.match.params.id)
    }
  }, [])

  return (
    <Fragment>
      <Container fluid className="provider-steps-wrap">
        <ProviderStep step="5" />
      </Container>
      {Object.keys(plans).length ? (
        <>
          <PaymentPlans
            {...{ plan: plans, ...props, setselectedplan: setselectedplan }}
          />
          <PaymentDetails
            {...{ plan: plans, ...props, selectedplan: selectedplan }}
          />
        </>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default ProviderStep5
