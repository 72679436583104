import { Flex } from '@chakra-ui/react'
import { BOX_SHADOW } from '../../../style/reusables'

export const InputDropdown = ({ children }) => {
  return (
    <Flex
      position="absolute"
      top="40px"
      bg="white"
      zIndex={10000}
      direction="column"
      p={4}
      maxH="250px"
      width="100%"
      overflow="hidden"
      overflowY="scroll"
      {...BOX_SHADOW}
    >
      {children}
    </Flex>
  )
}
