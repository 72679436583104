import Loadermodal from '../../../global/loader-modal/loader-modal'
import AdminHeader from '../admin-header'
import AdminNav from '../admin-nav'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import NetworkLayer from '../../../reactLayer/Network/Network'

export const UploadLocations = () => {
  const authContext = useContext(AuthContext)

  const [file, setFile] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileError, setFileError] = useState('')

  const importFile = async () => {
    if (file === '') {
      setFileError('Please upload file to import')
    } else {
      authContext.setLoader()
      let formData = new FormData()
      formData.append('locations', file.target.files[0])
      const submitData = {
        headers: {
          'access-token': authContext.adminToken,
        },
        url: 'admin/locations/upload',
        body: formData,
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status === true) {
        if (result.body.result.status === true) {
          setModalProps({
            type: 'success',
            msg: result.body.message,
          })
        } else {
          setModalProps({
            type: 'error',
            msg: result.body.message,
          })
        }
      }
    }
  }

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive + ' admin-dashboard right-admin-section'
          }
        >
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          <Row>
            <div className="col-12 px-lg-0">
              <form encType="multipart/form-data" method="post">
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div
                      style={{
                        color: 'orange',
                      }}
                    >
                      Please make sure stateId, name and zip_code columns
                      contain valid data.
                    </div>
                    <div>
                      <label className="pro-label">Upload XLSX file</label>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '24px',
                        }}
                      >
                        <input
                          type="file"
                          onChange={(e) => {
                            setFileName(e.target.value)
                            setFile(e)
                            setFileError('')
                          }}
                          value={fileName}
                          name="importFile"
                          accept=".xlsx"
                          className="input-pro"
                          style={{
                            width: '400px',
                          }}
                        />
                        {file ? (
                          <div
                            onClick={() => {
                              setFile('')
                              setFileName('')
                            }}
                            style={{
                              width: '80px',
                              textAlign: 'center',
                              border: '1px solid #297db4',
                              padding: '5px',
                              borderRadius: '5px',
                              color: '#297db4',
                              cursor: 'pointer',
                            }}
                          >
                            Remove
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <small className="error-input">{fileError}</small>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 bottom-btns mt-3">
                <div className="next-prev-btn-pro">
                  <input
                    type="button"
                    className="next-btn-pro"
                    onClick={() => importFile(false)}
                    value="Import"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}
