import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import InputValidation from '../../reactLayer/validation'

const ForgotPassword = (props) => {
  const resetToken =
    props.match.params && props.match.params.token
      ? props.match.params.token
      : null
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const passwordErrorMsg = ['Password is required', 'Invalid Password']
  const cpasswordErrorMsg = [
    'Password is required',
    'Invalid Password',
    'Confirm Password Not Matched',
  ]

  const [errors, setErrors] = useState({
    epassword: false,
    ecpassword: false,
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const [loginData, setLoginData] = useState({
    password: {
      value: '',
      validation: ['blank', 'password'],
      errorMsg: passwordErrorMsg,
      errorKey: 'epassword',
    },
    cpassword: {
      value: '',
      validation: ['blank', 'password', { confirmPassword: { password: '' } }],
      errorMsg: cpasswordErrorMsg,
      errorKey: 'ecpassword',
    },
  })

  const formSubmit = async () => {
    const validationResult = InputValidation(loginData)
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })
    } else {
      authContext.setLoader()
      let data = {
        password: loginData.password.value,
        confirmPassword: loginData.cpassword.value,
        resetPasswordToken: resetToken,
      }
      let url = 'reset-password'

      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        let ldata = loginData
        ldata.password.value = ''
        ldata.cpassword.value = ''
        setLoginData({ ...ldata })
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
        setTimeout(() => {
          history.push({
            pathname: PatientLinks.login,
          })
        }, 1500)
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }

  const verifyTocken = async () => {
    authContext.setLoader()
    if (resetToken) {
      let url = 'verify-reset-token/' + resetToken
      const submitData = {
        url: url,
      }
      let result = await NetworkLayer.getRequest(submitData)
      // debugger;
      if (result.status === true) {
        authContext.unsetLoader()
      } else {
        authContext.unsetLoader()
        setmodalProps({
          type: 'error',
          msg: result.message,
          autoclose: 'false',
        })
        authContext.showResponseModal()
      }
    } else {
      authContext.unsetLoader()
      setmodalProps({
        type: 'error',
        msg: 'Tocken Invalid',
        autoclose: 'false',
      })
      authContext.showResponseModal()
    }
  }
  const dosubmit = (e) => {
    // console.log('fsaffsaf',e.key);
    if (e.key === 'Enter') {
      formSubmit(e)
    }
  }
  useEffect(() => {
    verifyTocken()
  }, [])
  return (
    <Fragment>
      <Container className="header px-0">
        <HeaderMain />
      </Container>
      <Container className="">
        <Row>
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        </Row>
        <Row className="provider-login-wrap twostep">
          <div className="col-md-12 mb-3">
            <h1 className="mb-3">Reset Password</h1>
            <div className="col-md-12 mb-3 provider-steps-wrap">
              <div className="medical-info body"></div>
            </div>
            <p className="small col-md-8 offset-md-2 ml-auto mr-auto pt-2">
              Please enter your new password
            </p>
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="password"
              name="pass"
              placeholder="New Password"
              autoComplete="off"
              className={
                errors.epassword ? 'input-pro  error-border' : 'input-pro'
              }
              onClick={() => setErrors({ ...errors, ...{ epassword: false } })}
              onFocus={() => setErrors({ ...errors, ...{ epassword: false } })}
              onChange={(e) =>
                setLoginData({
                  ...loginData,
                  ...{
                    password: {
                      value: e.currentTarget.value,
                      validation: ['blank', 'password'],
                      errorMsg: passwordErrorMsg,
                      errorKey: 'epassword',
                    },
                    cpassword: {
                      value: loginData.cpassword.value,
                      validation: [
                        'blank',
                        'password',
                        {
                          confirmPassword: { password: e.currentTarget.value },
                        },
                      ],
                      errorMsg: cpasswordErrorMsg,
                      errorKey: 'ecpassword',
                    },
                  },
                })
              }
              value={loginData.password.value}
            />
            <small className="error-input">{errors.epassword}</small>
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="password"
              name="pass"
              placeholder="Confirm New Password"
              autoComplete="off"
              className={
                errors.ecpassword ? 'input-pro  error-border' : 'input-pro'
              }
              onClick={() => setErrors({ ...errors, ...{ ecpassword: false } })}
              onFocus={() => setErrors({ ...errors, ...{ ecpassword: false } })}
              onKeyPress={(e) => dosubmit(e)}
              onChange={(e) =>
                setLoginData({
                  ...loginData,
                  ...{
                    cpassword: {
                      value: e.currentTarget.value,
                      validation: [
                        'blank',
                        'password',
                        {
                          confirmPassword: {
                            password: loginData.password.value,
                          },
                        },
                      ],
                      errorMsg: cpasswordErrorMsg,
                      errorKey: 'ecpassword',
                    },
                  },
                })
              }
              value={loginData.cpassword.value}
            />
            <small className="error-input">{errors.ecpassword}</small>
          </div>
          <div className="col-md-12">
            <div className="pass-rules">
              <div className="head-rules">Password Rules:</div>
              <ul>
                <li>Has at least 8 characters</li>
                <li>
                  Must contain letters, at least 1 number, and at least 1
                  special character
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 text-center submit-btn">
            <div className="next-prev-btn-pro">
              <input
                type="button"
                onClick={() => {
                  formSubmit()
                }}
                className="next-btn-pro full-width"
                value="Reset Password"
              />
            </div>
          </div>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default ForgotPassword
