import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const Digitalscorecard = (props) => {
  const {
    totalReviewsAverageRating,
    totalReviews,
    recentReviewsAverageRating,
    recentReviews,
    sourceList,
  } = props
  return (
    <Fragment>
      <Helmet>
        <title>Digital Scorecard</title>
        <meta name="description" content={'Total Reviews : ' + totalReviews} />
        <meta
          property="og:url"
          content="https://www.your-domain.com/your-page.html"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Digital Scorecard" />
        <meta
          property="og:description"
          content={'Total Reviews : ' + totalReviews}
        />
        <meta
          property="og:image"
          content={window.location.origin + '/assets/images/logo-icon.svg'}
        />
      </Helmet>
      <Container>
        <Row className="mb-4">
          <div className="col-12">
            <div className="digital-scorecard">
              <Row>
                {/* <div className="head-section"> */}
                <div className="col-12 col-lg-6 heading-p">
                  Digital Scorecard
                </div>
                <div className="col-12 col-lg-6 heading-s">
                  <div className="share-score">
                    <span>Share Your Scorecard:</span>
                    <span>
                      <a
                        href={
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                          window.location.href
                        }
                        target="_blank"
                        className="fbtxt"
                        rel="noreferrer"
                      >
                        <strong>SHARE</strong>
                      </a>
                    </span>
                    <span>
                      <a
                        href={
                          'https://twitter.com/share?url=' +
                          encodeURIComponent(window.location.href)
                        }
                        target="_blank"
                        className="twitxt"
                        rel="noreferrer"
                      >
                        <strong>TWEET</strong>
                      </a>
                    </span>
                  </div>
                </div>
                {/* </div> */}

                <div className="box">
                  <div className="rect">
                    <div className="title">Total Reviews Average Rating</div>
                    <span className="digit">
                      {parseFloat(totalReviewsAverageRating).toFixed(1)}
                    </span>
                  </div>

                  <div className="rect">
                    <div className="title">Total Reviews</div>
                    <span className="digit">{totalReviews}</span>
                  </div>
                  <div className="rect">
                    <div className="title">Recent Reviews Average Rating</div>
                    <span className="digit">
                      {parseFloat(recentReviewsAverageRating).toFixed(1)}
                    </span>
                  </div>
                  <div className="rect">
                    <div className="title">Recent Reviews</div>
                    <span className="digit">{recentReviews}</span>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Digitalscorecard
