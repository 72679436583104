import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const SecurityLinks = () => {
  const history = useHistory()
  const authContext = useContext(AuthContext)
  // const [flag] = useState(false)
  const redirectTo = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-next-page')
    history.push({
      pathname: `${ProviderLinks.accountsecurity}/${previousPage}`,
    })
  }
  const [modalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  // const deleteProfile = async () => {
  //   if (flag) {
  //     authContext.setLoader()

  //     let url = 'providers/account-information/update'
  //     let formData = { formType: 'isDeleted', isDeleted: 1 }

  //     const submitData = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'access-token': authContext.token,
  //       },
  //       url: url,
  //       body: JSON.stringify(formData),
  //     }
  //     let result = await NetworkLayer.postData(submitData)
  //     authContext.unsetLoader()
  //     if (result.body.status) {
  //       setmodalProps({
  //         type: 'success',
  //         msg: result.body.message,
  //         btnname: ProviderLinks.home,
  //         redirection: ProviderLinks.home,
  //       })
  //       authContext.logout()
  //       authContext.showResponseModal()
  //     } else {
  //       setmodalProps({
  //         type: 'error',
  //         msg: result.body.message,
  //       })
  //       authContext.showResponseModal()
  //     }
  //   }
  // }
  // const responseDelete = async () => {
  //   setflag(true)
  //   deleteProfile()
  // }
  // const confirmDelete = async () => {
  //   setmodalProps({
  //     type: 'question',
  //     msg: 'Are you sure to delete your profile',
  //     btnname: 'Yes',
  //     redirection: '',
  //     action: responseDelete,
  //     btncancel: 'No',
  //     autoclose: 'false',
  //   })
  //   authContext.showResponseModal()
  // }

  return (
    <Fragment>
      <Container>
        <Container className="my-account-wrap">
          <Row>
            {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
            <div className="col-12">
              <div className="my-acc-info-box">
                <h3>Signing into DocScheduler</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="change-password"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Change Password</a>
                  </li>
                  <li
                    data-next-page="step-verification"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">2-Step Verification</a>
                  </li>
                </ul>
              </div>
              <div className="my-acc-info-box">
                <h3>Ways we can verify it`s you</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="recovery-phoneno"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Recovery Phone #</a>
                  </li>
                  <li
                    data-next-page="recovery-email"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Recovery Email</a>
                  </li>
                  <li
                    data-next-page="medical-npi"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">National Provider Identifier #</a>
                  </li>
                  <li
                    data-next-page="medical-license"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Medical License #</a>
                  </li>
                </ul>
              </div>
              <div className="dlt-profile-security">
                <div className="card-buttons">
                  <input
                    data-next-page="delete-profile"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                    type="button"
                    className="rev-btn-pro"
                    value="Delete Profile"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default SecurityLinks
