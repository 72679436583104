export const AnswerForm = ({ answer, question, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '24px',
      }}
    >
      <div style={{ width: '100%' }} className="meta-descr-box mb-3">
        <label htmlFor="title" className="pro-label mb-3">
          {question ? `Answer for ${question}` : ''}
        </label>
        <input
          type="text"
          name="title"
          className="answer-input big"
          value={answer?.description || ''}
          onChange={(e) => onChange(e, 'description')}
        />
      </div>
      <div style={{ width: '30%' }} className="meta-descr-box mb-3">
        <label htmlFor="actionType" className="pro-label">
          Action Type
        </label>
        <select
          className="input-pro"
          onChange={(e) => {
            onChange(e, 'actionType')
          }}
        >
          <option value="none" selected={!answer?.actionType || ''}>
            None
          </option>
          <option
            value="goto"
            selected={answer?.actionType == 'goto' ? 'Checked' : ''}
          >
            goto
          </option>
          <option
            value="mailto"
            selected={answer?.actionType == 'mailto' ? 'Checked' : ''}
          >
            mailto
          </option>
          <option
            value="welcome"
            selected={answer?.actionType == 'welcome' ? 'Checked' : ''}
          >
            welcome
          </option>
        </select>
      </div>
      <div style={{ width: '30%' }} className="meta-descr-box">
        <label htmlFor="action" className="pro-label">
          Action
        </label>
        <input
          type="text"
          name="action"
          className="input-pro"
          value={answer?.action || ''}
          disabled={answer?.actionType == 'none'}
          onChange={(e) => onChange(e, 'action')}
        />
      </div>
    </div>
  )
}
