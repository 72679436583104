import React, { useEffect, useState } from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { addDays } from '../../lib/utils'
import { days, monthList } from '../../lib/consts'
import { Box, Flex } from '@chakra-ui/react'
import { ChevronLeftIcon } from '../../style/icons'
import { Typography } from '../../style/typography'

interface Props {
  startDate: Date
  setStartDate: (date: Date) => void
}

interface Times {
  day: string
  monthDate: string
}

export const DayPicker: React.FC<Props> = ({ startDate, setStartDate }) => {
  const size = useWindowSize()
  const [listOfDates, setListOfDates] = useState<Times[]>([])
  const [prevSelection, setPrevSelection] = useState(false)
  const [nextSelection, setNextSelection] = useState(true)

  const timeFrom = (date: Date, x = 5) => {
    const dates = []
    for (let i = 0; i < x; i++) {
      let addedDate: Date
      if (i === 0) {
        addedDate = new Date(date.setDate(date.getDate()))
      } else {
        addedDate = new Date(date.setDate(date.getDate() + 1))
      }

      const day = days[addedDate.getDay()]
      const month = monthList[addedDate.getMonth()].name.substring(0, 3)
      const monthDate = month + ' ' + addedDate.getDate()
      dates.push({ day: day, monthDate: monthDate })
    }
    return dates
  }

  enum Timeline {
    prev,
    next,
  }

  const dateSelect = (type: Timeline) => {
    if (type === Timeline.prev) {
      const date = addDays(startDate, size?.width > 1220 ? -5 : -4)
      setStartDate(date)
    } else {
      const date = addDays(startDate, size?.width > 1220 ? 5 : 4)
      setStartDate(date)
    }
  }

  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate.setHours(0, 0, 0, 0))
      const screen = size.width > 1220 ? 5 : 4
      const times = timeFrom(date, screen)
      setListOfDates(times)
      const start = startDate.setHours(0, 0, 0, 0)
      const last = addDays(undefined, 45).setHours(0, 0, 0, 0)
      const now = new Date().getTime()

      if (start > now) {
        setPrevSelection(true)
      } else {
        setPrevSelection(false)
      }

      if (start < last) {
        setNextSelection(true)
      } else {
        setNextSelection(false)
      }
    }
  }, [startDate, size.width])

  return (
    <Flex width="100%" mb="12px" alignItems="center" gap={4}>
      {prevSelection && (
        <Box onClick={() => dateSelect(Timeline.prev)} cursor="pointer">
          <ChevronLeftIcon boxSize={8} color="primary" />
        </Box>
      )}

      {listOfDates.map((date, index) => {
        const color = index === 0 ? 'black' : 'text-light-gray'
        return (
          <Flex direction="column" key={index} alignItems="center">
            <Typography fontSize="14px" color={color}>
              {date.day}
            </Typography>
            <Typography fontSize="16px" fontWeight={500} color={color}>
              {date.monthDate}
            </Typography>
          </Flex>
        )
      })}
      {nextSelection && (
        <Box
          onClick={() => dateSelect(Timeline.next)}
          sx={{
            transform: 'rotate(180deg)',
          }}
          cursor="pointer"
        >
          <ChevronLeftIcon boxSize={8} color="primary" />
        </Box>
      )}
    </Flex>
  )
}
