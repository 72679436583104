import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import Loadermodal from '../../global/loader-modal/loader-modal'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'
import EditorText from './editor'

const WebsiteContent = () => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState()
  const [showArr, setShowArr] = useState()
  const [faqs, setFaqs] = useState([{ question: '', answer: '' }])
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const getList = async () => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/content-management/get',
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)

    if (result.status) {
      let showhideArr = []
      result.result.map((item) => {
        showhideArr.push(0)
      })
      setShowArr(showhideArr)
      let tempFaq = []
      result.result[5].description.split('|--------|').map((faq) => {
        const faqData = faq.split(' |--| ')
        tempFaq.push({ question: faqData[0], answer: faqData[1] })
        return null
      })
      setFaqs(tempFaq)
      setData(result.result)
    }

    authContext.unsetLoader()
  }

  const showhideEditBox = (index) => {
    let showhideArr = [...showArr]
    for (let i = 0; i < showhideArr.length; i++) {
      if (i != index) {
        showhideArr[i] = 0
      }
    }

    showhideArr[index] = !showhideArr[index]
    setShowArr(showhideArr)
  }
  const editDescription = (e, index, type) => {
    let value = e
    let tempData = [...data]
    tempData[index].description = value
    setData(tempData)
  }

  const editData = (e, index, type) => {
    let value = e.target.value
    let tempData = [...data]
    if (type == 'metatitle') {
      tempData[index].meta_title = value
    } else if (type == 'metadescription') {
      tempData[index].meta_description = value
    }
    setData(tempData)
  }
  const callUpdateApi = async (data) => {
    authContext.setLoader()

    if (data.id === 6) {
      let faqDescription = []
      faqs.map((faq) => {
        faqDescription.push(faq.question + ' |--| ' + faq.answer)
        return null
      })
      data.description = faqDescription.join(' |--------| ')
    }

    // FORM DATA
    const formData = {
      id: data.id,
      description: data.description,
      meta_description: data.meta_description,
      meta_title: data.meta_title,
      page_title: data.page_title,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/content-management/update',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      getList()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const saveAction = (value) => {
    authContext.setLoader()
    callUpdateApi(value)
  }

  const handleFaq = (e, index, type) => {
    let tempFaq = JSON.parse(JSON.stringify(faqs))
    tempFaq[index][type] = e.target.value

    setFaqs(tempFaq)
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive + ' admin-dashboard right-admin-section'
          }
        >
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          <Row className="bg-white-report">
            <div className="table-report">
              <div className="heading-wrap">
                <div className="table-heading">Content Management</div>
              </div>

              {/* Bottom container content */}
              <div className="content-management-outer">
                <div className="content-mange-head-wrap">
                  <div className="content-head">Page Name</div>
                  <div className="content-head">Action</div>
                </div>
                {data &&
                  data.map((value, index) => (
                    <div className="content-manage-outer" key={index}>
                      <div
                        className={` content-manage-inner ${
                          showArr[index] == 1 ? 'highlight' : ''
                        }`}
                      >
                        <div className="content-style">
                          <span>{value.page_title}</span>
                        </div>
                        <div className="content-style">
                          {showArr[index] == 1 ? (
                            <div className="bottom-btns">
                              <div className="next-prev-btn-pro">
                                <input
                                  type="button"
                                  className="website-content-btn prev-btn-pro"
                                  value="Cancel"
                                  onClick={() => showhideEditBox(index)}
                                />
                                <input
                                  type="button"
                                  className="website-content-btn next-btn-pro"
                                  value="Save"
                                  onClick={() => saveAction(value)}
                                />
                              </div>
                            </div>
                          ) : (
                            <Link onClick={() => showhideEditBox(index)}>
                              Edit
                            </Link>
                          )}
                        </div>
                      </div>
                      {showArr[index] == 1 && (
                        <div className="content-manage-detail mb-3">
                          {index === 5 ? (
                            <div>
                              {faqs.map((faq, key) => {
                                return (
                                  <div
                                    key={key}
                                    className="meta-descr-box mb-3"
                                  >
                                    <label
                                      htmlFor="faqQuestion"
                                      className="pro-label"
                                    >
                                      Question {key + 1}{' '}
                                    </label>
                                    <input
                                      type="text"
                                      name="faqQuestion"
                                      className="input-pro"
                                      onChange={(e) =>
                                        handleFaq(e, key, 'question')
                                      }
                                      value={faq.question}
                                    />
                                    <textarea
                                      style={{ marginTop: '10px' }}
                                      name="faqAnswer"
                                      onChange={(e) =>
                                        handleFaq(e, key, 'answer')
                                      }
                                      value={faq.answer}
                                      className="input-pro"
                                    ></textarea>
                                  </div>
                                )
                              })}
                            </div>
                          ) : (
                            <EditorText
                              data={value.description}
                              onChange={editDescription}
                              index={index}
                            />
                          )}
                          <div className="meta-descr-box mb-3">
                            <label htmlFor="metaTitle" className="pro-label">
                              Meta Title
                            </label>
                            <input
                              type="text"
                              name="metaTitle"
                              className="input-pro"
                              value={value.meta_title}
                              onChange={(e) => editData(e, index, 'metatitle')}
                            />
                          </div>
                          <div className="meta-descr-box">
                            <label htmlFor="metaDes" className="pro-label">
                              Meta Description
                            </label>
                            <input
                              type="text"
                              name="metaDes"
                              className="input-pro"
                              value={value.meta_description}
                              onChange={(e) =>
                                editData(e, index, 'metadescription')
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default WebsiteContent
