import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { CSVLink } from 'react-csv'
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../../reactLayer/Network/Network'

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

const AppointmentFilter = (props) => {
  const { search } = props
  const wrapperCateRef = useRef(null)
  const authContext = useContext(AuthContext)
  const [data, setData] = useState('')
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const modalList = ['ratingExport']
  const openProfileTabModal = (modalName) => {
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const csvLinkEl = useRef()
  const [header, setHeader] = useState([{ label: '', key: 'name' }])

  const [filtervalue, setFiltervalue] = useState()

  const [showCal, setshowCal] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [formstartDate, setFormStartDate] = useState('')
  const [formendDate, setFormEndDate] = useState('')

  const onInputChange = (e) => {
    e.preventDefault()
    setFiltervalue(e.target.value)

    setStartDate('')
    setFormStartDate('')
    setEndDate('')
    setFormEndDate('')
  }

  const debounceGet = useRef(
    debounce((nextValue) => search(nextValue), 1000)
  ).current

  useEffect(() => {
    if (filtervalue == '' && search) {
      debounceGet(filtervalue)
    } else {
      if (filtervalue && filtervalue.length > 2) {
        debounceGet(filtervalue)
      }
    }
  }, [filtervalue])

  const getExportList = async () => {
    let url = ''
    let formData = { searchValue: filtervalue }

    url = 'admin/appointment/export'
    // Add Insurance
    setHeader([
      { label: 'Provider', key: 'providername' },
      { label: 'Provider Number', key: 'providernumber' },
      { label: 'Patient', key: 'patientname' },
      { label: 'Patient Number', key: 'patientnumber' },
      { label: 'Appointment Category', key: 'appointment_category' },
      { label: 'Speciality', key: 'speciality' },
      { label: 'Request Type', key: 'request_type' },
      { label: 'Request Date & Time', key: 'request_date' },
      { label: 'Appointment Date & Time', key: 'appointment_date' },
      { label: 'Description', key: 'description' },
    ])

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      let datares = result.body?.result.map((item) => ({
        patientname: `${item.users?.patients?.firstName} ${item.users?.patients?.lastName}`,
        patientnumber: `${item.users?.patients?.homeNumber}`,
        providername: `${item.providerPracticeLocations?.users?.providers?.firstName} ${item.providerPracticeLocations?.users?.providers?.lastName}`,
        providernumber: `${item.providerPracticeLocations?.phoneNumber}`,
        appointment_category: item.appointmentsCategory,
        speciality:
          item.providerPracticeLocations?.users?.providerMedicalSpecialities[0]
            ?.specialties?.name,
        request_type: item.requestType,
        request_date: item.createdAt,
        appointment_date: item.appointmentDate,
        description: item.appointmentDescription,
      }))
      setData(datares)
    } catch (err) {
      console.log(err)
    }
    authContext.unsetLoader()
  }
  const getSpeciality = (speciality) => {
    let spl = []
    if (speciality) {
      speciality.map((d) => {
        if (d.specialties && d.specialties.name) {
          spl.push(d.specialties.name)
        }
      })
    }
    return spl.join(', ')
  }
  const getExportCallbackList = async () => {
    let url = ''
    let formData = { searchValue: filtervalue }

    url = 'admin/appointment/callbackListExport'
    // Add Insurance
    setHeader([
      { label: 'Patient Name', key: 'patientname' },
      { label: 'Patient Number', key: 'patientnumber' },
      { label: 'Provider Name', key: 'providername' },
      { label: 'Speciality', key: 'speciality' },
      { label: 'Request Type', key: 'requesttype' },
      { label: 'Call Back Request Date', key: 'request_date' },
      { label: 'Time', key: 'request_time' },
    ])

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      let datares = result.body?.result.map((item) => ({
        patientname: item.usersFrom?.patients
          ? `${item.usersFrom?.patients?.firstName} ${item.usersFrom?.patients?.lastName}`
          : '',
        patientnumber: item.usersFrom?.patients?.phoneNumber
          ? `${item.usersFrom?.patients?.phoneNumber}`
          : '',
        providername: item.usersTo?.providers
          ? `${item.usersTo?.providers?.firstName} ${item.usersTo?.providers?.lastName}`
          : '',
        speciality:
          item.usersTo && item.usersTo.providerMedicalSpecialities
            ? getSpeciality(item.usersTo.providerMedicalSpecialities)
            : '',
        requesttype: `Call Back Request`,
        request_date: moment(`${new Date(item.createdAt)}`).format(
          'MM-DD-YYYY'
        ),
        request_time: moment(`${new Date(item.createdAt)}`).format('HH:MM'),
      }))
      setData(datares)
    } catch (err) {
      console.log(err)
    }
    authContext.unsetLoader()
  }
  const downloadReport = async () => {
    let result
    if (props.callBack) {
      result = await getExportCallbackList()
    } else {
      result = await getExportList()
    }
    csvLinkEl.current.link.click()
  }

  const onChangeDate = (value, event) => {
    setStartDate(moment(value[0]).format('M/D/Y'))
    setFormStartDate(moment(value[0]).format('Y-M-D'))
    setEndDate(moment(value[1]).format('M/D/Y'))
    setFormEndDate(moment(value[1]).format('Y-M-D'))
    setshowCal(!showCal)
    search(
      '',
      moment(value[0]).format('YYYY-MM-DD') +
        ' - ' +
        moment(value[1]).format('YYYY-MM-DD')
    )
  }

  const hideCal = () => {
    if (showCal) {
      setshowCal(false)
    }
  }

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    if (wrap && !wrap.contains(event.target)) {
      setshowCal(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <Fragment>
      <div className="rating-filter-box">
        <div className="rating-search">
          <div className="table-sort-filter">
            <span className="filter-input">
              <input
                type="text"
                className="input-pro"
                name="search"
                id="search"
                value={filtervalue}
                placeholder="Search by provider name, patient name"
                autoComplete="off"
                onChange={(e) => onInputChange(e)}
              />
              <i className="search-icon">
                <SearchIcon onClick={() => search(filtervalue)} />
              </i>
            </span>
          </div>
        </div>
        <div className="rating-filter" ref={wrapperCateRef}>
          <div className="table-sort-filter">
            <span
              className="filter-input"
              onClick={() => {
                setshowCal(!showCal)
              }}
            >
              <input
                type="text"
                className="input-pro"
                name="date"
                id="date"
                placeholder="Filter By Date"
                value={startDate ? `${startDate} to ${endDate}` : ''}
              />
              <div className="search-icon">
                <div className="calender-icon"></div>
              </div>
            </span>
          </div>
          {showCal && (
            <div
              className="ratings-calendar"
              style={{ zIndex: '10', position: 'relative', bottom: '-1em' }}
            >
              <Calendar
                onChange={onChangeDate}
                maxDate={new Date()}
                calendarType={'US'}
                selectRange={true}
                returnValue={'range'}
              />
            </div>
          )}
        </div>
        {/* <div className="rating-sort">
                    <div className="table-sort-filter">
                        <span className="sort-select">
                            <select class="input-pro sort-select">
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                                <option value="">Sort By</option>
                            </select>
                            <span className="select-icon"><SelectIcon /></span>
                        </span>
                    </div>
                </div> */}
        <div className="rating-export">
          <div className="table-sort-filter">
            <a className="link-btn" onClick={downloadReport}>
              Export
            </a>
          </div>

          <CSVLink
            headers={header}
            filename={
              props.callBack
                ? `CallBack-Request-list.csv`
                : `Appointment-list.csv`
            }
            data={data}
            ref={csvLinkEl}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default AppointmentFilter
