import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

const ProviderManagementInfoModal = (props) => {
  const { data } = props

  return (
    <Fragment>
      <Container>
        <div className="search-modal-wrap provider-info-modal-outer">
          <div className="edit-all-filter">
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 px-lg-0">
                  <div className="provider-info-modal-wrap provider-form-edit">
                    {/* doctor profile */}

                    <div className="provider-detail-left-top">
                      <div className="provider-detail-box ml-auto mr-auto">
                        <div className="provider-doc-img">
                          <img
                            src={data?.accountInfo?.profileImage}
                            alt="Doctor"
                          />
                        </div>
                        <div className="provider-doc-details">
                          <h5>
                            {`${data?.accountInfo?.firstName} ${data?.accountInfo?.lastName}`}
                            ,{' '}
                            {
                              data?.medicalSpeciality
                                ?.providerMedicalCredientialsValue
                            }
                          </h5>
                          <div className="provider-designation">
                            Speciality:{' '}
                            <span>
                              {
                                data?.medicalSpeciality
                                  ?.providerMedicalSpecialitiesValue
                              }
                            </span>
                          </div>
                          <div className="rating-result">
                            {/*<span className="rating"><span className={`rating${data ?.ratingReviewsAvg}`}></span></span>*/}

                            <span
                              className="stars-outer"
                              style={{ height: '25px', margin: '0px' }}
                            >
                              {[1, 2, 3, 4, 5].map((starNumber, key) => {
                                return (
                                  <span
                                    key={key}
                                    className={
                                      data?.ratingReviewsAvg >= starNumber
                                        ? 'full star'
                                        : 'star'
                                    }
                                    name="rating"
                                  ></span>
                                )
                              })}
                            </span>

                            <span className="review-text">
                              {data?.ratingReviewsCount} Reviews
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Appointment Request Options */}
                    <div className="heading-appointment-req">
                      Appointment Request Options
                    </div>
                    <div className="appointment-req-area">
                      <div className="appoint-areabox">
                        <div className="modal-appoint-left">
                          Allow Patients to Send You Callback Requests
                        </div>
                        <div className="modal-appoint-right">
                          {data?.practiceInfo?.allowedPatient ? 'Yes' : 'No'}
                        </div>
                      </div>
                      <div className="appoint-areabox">
                        <div className="modal-appoint-left">
                          Are You Accepting New Patients?
                        </div>
                        <div className="modal-appoint-right">
                          {data?.practiceInfo?.isAcceptingNewPatient
                            ? 'Yes'
                            : 'No'}
                        </div>
                      </div>
                      <div className="appoint-areabox">
                        <div
                          className="modal-appoint-left"
                          style={{ width: '60%' }}
                        >
                          Link to Online Booking Website:
                        </div>
                        <div
                          style={{ width: '30%' }}
                          className="modal-appoint-right"
                        >
                          {data?.practiceInfo?.providerPracticeLocations
                            ? data?.practiceInfo?.providerPracticeLocations[0]
                                ?.website
                            : ''}
                        </div>
                      </div>
                      <div className="appoint-areabox">
                        <div className="modal-appoint-left">
                          Virtual Visit Capability?
                        </div>
                        <div className="modal-appoint-right">
                          {data?.practiceInfo?.hasVirtualVisit ? 'Yes' : 'No'}
                        </div>
                      </div>

                      {/* Medical Education and Training */}
                      <div className="heading-appointment-req mhead-medical">
                        Medical Education and Training
                      </div>
                      <div className="provider-detail-left-top modal-med-edu">
                        <div className="edu-lang-wrap">
                          <ul>
                            <li className="desig-icon">
                              <span>Degree</span>
                              {data?.medicalSpeciality
                                ?.providerDegreeDetails && (
                                <>
                                  {
                                    data?.medicalSpeciality
                                      ?.providerDegreeDetails[0]?.schools?.name
                                  }
                                  <br />
                                  {`(${data?.medicalSpeciality?.providerDegreeDetails[0]?.yearStarted}-${data?.medicalSpeciality?.providerDegreeDetails[0]?.yearCompleted})`}
                                </>
                              )}
                            </li>

                            <li className="resi-icon">
                              <span>Residency</span>
                              {data?.medicalSpeciality
                                ?.providerTrainingDetails && (
                                <>
                                  {
                                    data?.medicalSpeciality
                                      ?.providerTrainingDetails[0]?.schools
                                      ?.name
                                  }
                                  <br />
                                  {`(${data?.medicalSpeciality?.providerTrainingDetails[0]?.yearStarted}-${data?.medicalSpeciality?.providerDegreeDetails[0]?.yearCompleted})`}
                                </>
                              )}
                            </li>
                            <li className="lang-icon">
                              <span>Languages</span>
                              {data?.medicalSpeciality?.languagesSpoken && (
                                <>
                                  {data?.medicalSpeciality?.languagesSpoken
                                    .map((t) => <>{t}</>)
                                    .reduce((prev, curr) => [prev, ', ', curr])}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* Areas of Focus */}
                      <div className="heading-appointment-req mhead-medical">
                        Areas of Focus
                      </div>
                      <div className="pro-modal-focus-area">
                        <div className="profile-tabs">
                          <ul className="focus-area-list">
                            {data?.medicalSpeciality
                              ?.providerMedicalFocusAreas &&
                              data?.medicalSpeciality?.providerMedicalFocusAreas.map(
                                (t, index) => <li key={index}>{t.focusName}</li>
                              )}
                          </ul>
                        </div>
                      </div>
                      {/* Locations / Contact Information */}
                      <div className="heading-appointment-req location-head-medical">
                        Locations / Contact Information
                      </div>
                      <div className="pro-modal-focus-area pro-modal-location">
                        <div className="profile-tabs">
                          {data?.practiceInfo?.providerPracticeLocations &&
                            data?.practiceInfo?.providerPracticeLocations.map(
                              (t, index) => (
                                <ul
                                  key={index}
                                  className="location-add-profile"
                                >
                                  <li className="map-icon">
                                    <div className="location-head">
                                      Primary Location
                                    </div>
                                    {t.practiceName}
                                    <br />
                                    {t.practiceAddress}
                                    <br />
                                    {t.phoneNumber}
                                  </li>
                                  <li>
                                    <div className="location-head">Hours</div>
                                    {t.practiceHours.map((t, index) => (
                                      <div key={index} className="loca-hours">
                                        <span>{t.day}</span>
                                        <span>{`${t.start} - ${t.end}`}</span>
                                      </div>
                                    ))}
                                  </li>
                                </ul>
                              )
                            )}
                        </div>
                      </div>
                      {/* Major Insurance Accepted */}
                      <div className="heading-appointment-req location-head-medical">
                        Major Insurance Accepted
                      </div>
                      <div className="pro-modal-focus-area pro-modal-insurance">
                        <div className="profile-tabs">
                          <ul className="profile-ins-list">
                            {data?.practiceInfo?.insuranceAccepted &&
                              data?.practiceInfo?.insuranceAccepted.map(
                                (t, index) => <li key={index}>{t}</li>
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default ProviderManagementInfoModal
