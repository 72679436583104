import React, { Fragment, useContext, useEffect, useState } from 'react'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import Custommodal from '../../../global/modal/modal'
import DoctorReviewModal from '../../../components/doctor-review-modal/doctor-review-modal'
import { Box, Flex } from '@chakra-ui/react'

const HALF = 0.5

const ABSOLUTE_STRETCH = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

export const Stars = (props) => {
  const authContext = useContext(AuthContext)
  const doctor = props?.users || props?.providers

  const roleNumberThree =
    authContext.user &&
    authContext.user.accountInfo &&
    authContext.user.accountInfo.roleId == '3'

  const getData = () => {
    let accountDetails = {}
    if (props.componentName && props.componentName == 'patient_appointment') {
      accountDetails = {
        accountInfo: {
          id: props.providerPracticeLocations.users.id,
          firstName: props.providerPracticeLocations.users.providers.firstName,
          middleName:
            props.providerPracticeLocations.users.providers.middleName,
          lastName: props.providerPracticeLocations.users.providers.lastName,
          profileImage: props.providerPracticeLocations.users.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.providerMedicalSpecialitiesValue,
        },
      }
    } else if (
      props.componentName &&
      (props.componentName == 'sponsored_doctor' ||
        props.componentName == 'searchResult')
    ) {
      accountDetails = {
        accountInfo: {
          id: props.id,
          firstName: doctor.firstName,
          middleName: doctor.middleName,
          lastName: doctor.lastName,
          profileImage: props.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.providerMedicalSpecialitiesValue,
        },
      }
    } else if (props.componentName && props.componentName == 'profile') {
      accountDetails = {
        accountInfo: {
          id: props.accountInfo.id,
          firstName: props.accountInfo.firstName,
          middleName: props.accountInfo.middleName,
          lastName: props.accountInfo.lastName,
          profileImage: props.accountInfo.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.medicalSpeciality.providerMedicalSpecialitiesValue,
        },
      }
    }
    return accountDetails
  }

  const accountData = getData()
  const modalList = ['reviewModal']
  const [visibleComponent, setVisibleComponent] = useState('')
  const [userRating, setUserRating] = useState<{
    value: number
    tempValue: number
  }>({
    value: props.rating,
    tempValue: 0,
  })

  const setTempRating = (value: number) => {
    setUserRating({ ...userRating, tempValue: value })
  }

  const openModal = (e) => {
    if (
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId == '4'
    ) {
      openProfileTabModal('reviewModal')
    } else if (roleNumberThree) {
      e.preventDefault()
    } else {
      // openLoginModal();
      authContext.showLoginModal({
        modalName: 'login',
        redirectUrl: window.location.href,
      })
    }
  }

  const openProfileTabModal = (modalName) => {
    setVisibleComponent(modalName)
    authContext.showModal()
  }

  return (
    <Fragment>
      {modalList.indexOf(visibleComponent) > -1 && (
        <Custommodal
          unsetComponent={setVisibleComponent}
          componentName={
            visibleComponent === 'reviewModal' && (
              <DoctorReviewModal
                {...accountData}
                getProfile={props.getProfile}
                setModalProps={props.setRatingModalProps}
              />
            )
          }
        />
      )}
      <Flex gap={2}>
        {[1, 2, 3, 4, 5].map((num, i) => {
          const rating = userRating?.tempValue || userRating?.value

          const isFill = rating >= num
          const isHalfAStar = rating - num === HALF
          const starWidth = isHalfAStar ? 'half' : 'full'

          const width = starWidth === 'half' ? '50%' : '100%'

          return (
            <Box
              width="27px"
              height="27px"
              position="relative"
              color="white"
              bg="#d6d6d6"
              borderRadius="5px"
              key={i}
              p={1}
              style={{
                cursor: roleNumberThree ? 'default' : 'pointer',
              }}
              onMouseLeave={(e) =>
                roleNumberThree ? e.preventDefault() : setTempRating(0)
              }
              onMouseEnter={(e) =>
                roleNumberThree ? e.preventDefault() : setTempRating(num)
              }
              onClick={(e) => {
                window.localStorage.setItem('role', String(4))
                window.localStorage.removeItem('subRole')
                openModal(e)
              }}
            >
              {/*// @ts-ignore*/}
              <Box
                zIndex={1}
                textAlign="center"
                borderRadius="5px"
                overflow="hidden"
                {...ABSOLUTE_STRETCH}
                top="1px"
              >
                &#9733;
              </Box>
              {/*// @ts-ignore*/}
              <Box
                width={isFill ? width : '0%'}
                overflow="hidden"
                borderRadius="5px"
                bg="#ffcc00"
                {...ABSOLUTE_STRETCH}
              ></Box>
            </Box>
          )
        })}
      </Flex>
    </Fragment>
  )
}
