import { Fragment, useContext, useState } from 'react'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { LayoutContainer } from '../Layout/LayoutContainer'
import { ResultsList } from './ResultsList'
import { SearchHeader } from './SearchHeader'
import { useGetSearchParams } from './hooks/useGetSearchParams'
import { Filter } from './types'

const SearchResults = () => {
  const authContext = useContext(AuthContext)

  const { find, city, insurance, focusArea, zip } = useGetSearchParams()

  const [userCategoryType] = useState(() => {
    const roleId = authContext?.user?.accountInfo?.roleId
    if (roleId === 3) {
      return 'provider'
    } else {
      return 'patient'
    }
  })

  const [filter, setFilter] = useState<Filter>({
    callApi: false,
    firstName: '',
    lastName: '',
    city: city || '',
    zip: zip || '',
    speciality: [],
    languages: ['english'],
    insurances: [insurance || ''],
    focusAreas: focusArea,
    searchValue: find || '',
    hasVirtualVisit: 0,
    isAcceptingNewPatient: 0,
    available72hours: 0,
    isTopShow: 0,
    isSponsoredShow: 0,
    isGender: '',
    isChildren: '',
    offset: 0,
    limit: 10,
    latitude: authContext.browserLatLng.latitude,
    longitude: authContext.browserLatLng.longitude,
    locationName: '',
    type: [],
  })

  return (
    <Fragment>
      <LayoutContainer>
        <SearchHeader filter={filter} setFilter={setFilter} />
        <ResultsList
          filter={filter}
          updateFilter={setFilter}
          userCategoryType={userCategoryType}
        />
      </LayoutContainer>
    </Fragment>
  )
}

export default SearchResults
