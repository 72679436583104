export const Questions = ({
  questions,
  selectedQuestion,
  onClick,
  onEditQuestion,
}) => {
  return (
    <div>
      {questions?.map((q) => {
        return (
          <div key={q.id} className={`question gray mb-3`}>
            {selectedQuestion === q.id && <div className="link-active"></div>}
            <div style={{ cursor: 'pointer' }} onClick={() => onClick(q)}>
              {q.description}
            </div>
            <div className="question-edit" onClick={() => onEditQuestion(q)}>
              Edit
            </div>
          </div>
        )
      })}
    </div>
  )
}
