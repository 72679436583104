export const statusOptions = {
  initial: 0,
  success: 1,
  error: 2,
  loading: 3,
}
export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}
