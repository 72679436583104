import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import AddAccountInfo from '../../components/admin/patient/add-account-information'
import AddInsuranceInfo from '../../components/admin/patient/add-insurance-information'
import Loadermodal from '../../global/loader-modal/loader-modal'
import { AdminLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const PatientAddDetail = (props) => {
  const authContext = useContext(AuthContext)
  const [visibleComponent, setvisibleComponent] = useState('accInfo')
  const [successResponsedata, setSuccessResponse] = useState({})
  const tabName = [
    { id: 'accInfo', name: 'Account information' },
    { id: 'insInfo', name: 'Insurance Information' },
  ]

  const changeTab = () => {
    setvisibleComponent('insInfo')
  }

  const successResponse = (data) => {
    setSuccessResponse(data)
  }

  useEffect(() => {}, [])

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Row className="bg-white-report patient-details-outer">
            <div className="table-report">
              <div className="heading-wrap">
                <div className="table-heading">Add Patient Detail</div>
              </div>
              <div className="col-12 mt-3">
                <Link
                  to={AdminLinks.admin_patient_management}
                  className="back-link"
                >
                  <span className="back-icon">
                    <BackIcon />
                  </span>
                  Back
                </Link>
              </div>
              <div className="patient-tabs-outer">
                <div className="tabs-admin-outer">
                  <ul className="edit-tab-list row mt-3 pl-3 pr-3">
                    {tabName.map((data, index) => (
                      <li
                        key={data.id}
                        className={visibleComponent == data.id ? 'active' : ''}
                      >
                        {data.name}{' '}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {visibleComponent === 'accInfo' ? (
                <>
                  <AddAccountInfo
                    changeTab={changeTab}
                    setSuccessResponse={setSuccessResponse}
                  />{' '}
                </>
              ) : visibleComponent === 'insInfo' ? (
                <>
                  <AddInsuranceInfo data={successResponsedata} />{' '}
                </>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default PatientAddDetail
