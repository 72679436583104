import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Row } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import { ReactComponent as FilterIcon } from '../../../admin/assets/images/filter.svg'
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg'
import { AdminLinks } from '../../../linkFile'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

const ManagementFilter = (props) => {
  console.log(props)
  const authContext = useContext(AuthContext)
  const { search } = props
  const [filtervalue, setFiltervalue] = useState()
  const [data, setData] = useState([])
  const csvLinkEl = useRef()
  const [header, setHeader] = useState([{ label: '', key: 'name' }])

  const onInputChange = (e) => {
    e.preventDefault()
    setFiltervalue(e.target.value)
  }

  const debounceGet = useRef(
    debounce((nextValue) => search(nextValue), 1000)
  ).current

  const getExportList = async () => {
    setHeader([
      { label: 'Accepting New Patients', key: 'Accepting New Patients' },
      { label: 'City', key: 'City' },
      { label: 'Degree Type', key: 'Degree Type' },
      { label: 'Email', key: 'Email' },
      { label: 'First Name', key: 'First Name' },
      { label: 'Gender', key: 'Gender' },
      { label: 'Insurance', key: 'Insurance' },
      { label: 'Languages Spoken', key: 'Languages Spoken' },
      { label: 'Last Name', key: 'Last Name' },
      { label: 'Medical Credential', key: 'Medical Credential' },
      { label: 'Medical Degree Period', key: 'Medical Degree Period' },
      { label: 'Medical License Number', key: 'Medical License Number' },
      { label: 'NPI', key: 'NPI' },
      { label: 'Phone Type', key: 'Phone Type' },
      { label: 'Practice Address', key: 'Practice Address' },
      { label: 'Practice Name', key: 'Practice Name' },
      { label: 'Provider Specialty', key: 'Provider Specialty' },
      {
        label: 'School Name or Institution Name',
        key: 'School Name or Institution Name',
      },
      { label: 'State', key: 'State' },
      {
        label: 'Virtual Visits Available',
        key: 'Virtual Visits Available',
      },
      { label: 'ZIP Code', key: 'ZIP Code' },
    ])

    let formData = { searchValue: filtervalue }
    if (filtervalue) {
      formData = {
        searchValue: filtervalue,
      }
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/export',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      let rows = result?.body?.result?.data?.rows

      setData(rows)
    } catch (err) {
      console.log(err)
    }
    authContext.unsetLoader()
  }

  const downloadReport = async () => {
    let result = await getExportList()
    csvLinkEl.current.link.click()
  }

  useEffect(() => {
    getExportList()
  }, [])

  const importPopup = () => {}

  useEffect(() => {
    if (filtervalue == '' && search) {
      debounceGet('')
    } else {
      if (filtervalue && filtervalue.length > 2) {
        debounceGet(filtervalue)
      }
    }
  }, [filtervalue])

  return (
    <Fragment>
      <Row className="table-sort-filter">
        <div className="col-xl-9 filter-left-wrap">
          <form>
            <div className="sort-filterwrap">
              <span className="filter-input mb-3 mr-md-2">
                <input
                  type="text"
                  className="input-pro"
                  name="usernae"
                  id="username"
                  placeholder="Search provider by name, phone, location"
                  autoComplete="off"
                  value={filtervalue}
                  onChange={(e) => onInputChange(e)}
                />
                <i className="search-icon" style={{ cursor: 'pointer' }}>
                  <SearchIcon onClick={() => search(filtervalue)} />
                </i>
              </span>
              <span className="sort-select mb-3">
                <button className="filter-btn" type="button">
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FilterIcon />
                    <span>All Filters</span>
                  </div>
                </button>
              </span>
            </div>
          </form>
        </div>
        <div className="col-xl-3 text-right">
          <Link
            to={AdminLinks.admin_provider_add_detail}
            className="add-patient-btn"
          >
            + Add Provider
          </Link>
          <Link
            to={'#'}
            className="link-btn export-btn ml-2 mb-3"
            onClick={() => props.importPopup()}
          >
            Import
          </Link>
          <CSVLink
            className="link-btn export-btn ml-2 mb-3"
            headers={header}
            filename={`Providers-list.csv`}
            data={data}
            ref={csvLinkEl}
          >
            Export
          </CSVLink>
        </div>
      </Row>
    </Fragment>
  )
}

export default ManagementFilter
