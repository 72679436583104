import type { As, BoxProps } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'

interface TypographyProps extends BoxProps {
  as?: As
}

export const Typography = ({
  as = 'p',
  children,
  ...rest
}: TypographyProps) => {
  const textStyle = as as string
  return (
    <Text as={as} textStyle={textStyle} {...rest} mb={0}>
      {children}
    </Text>
  )
}
