import React, { Fragment, useContext, useState, useEffect } from 'react'
import AuthContext from '../../reactLayer/auth/AuthContext'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import { Helmet } from 'react-helmet'

const PolicyTwo = () => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState([])
  const [pageTitle, setPageTitle] = useState('Doc Scheduler')
  const [metaDesc, setMetaDesc] = useState('')
  const getDetails = async (id) => {
    authContext.setLoader()

    let formData = {
      id: 3,
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: 'content-management/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setPageTitle(result.body.result.meta_title)
    setMetaDesc(result.body.result.meta_description)
    setData(result.body?.result)
    authContext.unsetLoader()
  }

  useEffect(() => {
    getDetails()
  }, [])
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <Container fluid className="policy-terms-wrap">
        <Row>
          <Container>
            <Row>
              <div className="col-12">
                <div className="privacy-terms-heading-wrap">
                  <h1>{data.page_title}</h1>
                  <div className="sub-head-terms">
                    Effective Date: <span>May 1, 2021</span>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12">
                <div
                  className="privacy-bodytext"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                ></div>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  )
}

export default PolicyTwo
