import 'bootstrap/dist/css/bootstrap.css'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const FALLBACK_WELCOME_MESSAGE =
  'Hi there. I am Doc Assistant, your virtual AI assistant. Are you a patient or a healthcare provider?'

const AnswerType = ({ actionType, action, type, description }) => {
  const [mailState, setMailState] = useState({
    name: '',
    number: '',
  })

  const [responseMessage, setResponseMessage] = useState()

  const submitMailForm = async (e) => {
    e.preventDefault()
    const { name, number } = mailState

    const formData = {
      name,
      number,
      email: action,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: `/qa/send-email`,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)

    if (result?.body?.status) {
      setResponseMessage(result.body.message)
      setTimeout(() => {
        setResponseMessage('')
      }, [2000])
    }
  }

  const handleChange = (e) => {
    const value = e.currentTarget.value
    const key = e.currentTarget.name

    setMailState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      }
    })
  }
  if (type === 'patient' && description === 'Schedule an Appointment') {
    if (responseMessage) {
      return <div className="chat-text mt-3">{responseMessage}</div>
    }
    return (
      <div className="jot-wrapper">
        <Link className="jot-form--link" to={PatientLinks.jot_form}>
          Care Coordination Consent Form
        </Link>
      </div>
    )
  } else if (actionType === 'mailto' && type === 'provider') {
    return (
      <>
        <div className="mailto-form mt-3">
          <label>Name</label>
          <input
            type="text"
            name="name"
            className="chat-input"
            value={mailState.name}
            onChange={handleChange}
          />

          <label>Cell number</label>
          <input
            type="text"
            name="number"
            className="chat-input"
            value={mailState.number}
            onChange={handleChange}
          />
        </div>

        <div className="mailto-submit mt-3">
          <Link to="#" className="mail-submit" onClick={submitMailForm}>
            Submit
          </Link>
        </div>
        {responseMessage === 'Your message has been sent' && (
          <div className="submit-message">
            <span className="submit-content">
              "Thanks! A sales rep will reach out to you shortly"
            </span>
          </div>
        )}
      </>
    )
  }

  if (actionType === 'goto') {
    window.open(action, '_blank')
    return null
  }
}

const Chat = ({ onClick }) => {
  const authContext = useContext(AuthContext)

  const [type, setType] = useState()

  const [questions, setQuestions] = useState([])
  const [currentAnswer, setCurrentAnswer] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState([])

  const [welcome, setWelcome] = useState({})

  const scrollToBottom = () => {
    const contentDiv = document.getElementById('content-wrapper')
    contentDiv.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  const getQuestions = async (type) => {
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: `/qa/questions/${type}`,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)

    if (result.status) {
      setQuestions(result.result.questions)
      if (type === 'welcome') {
        setWelcome(result.result.questions[0].answers[0])
      }
      setType(type)
    }
    scrollToBottom()
  }

  const getWelcomeMessage = async () => {
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: `/qa/answers/welcome`,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)

    if (result.status) {
      setWelcome(result.result.message)
    }
  }

  const handleClick = (question) => {
    setCurrentQuestion(question)
    const answer = question?.answers
    if (answer[0]) {
      setCurrentAnswer(answer[0])
    }
    scrollToBottom()
  }

  useEffect(() => {
    getWelcomeMessage()
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [currentAnswer])

  return (
    <div className="chat-wrapper">
      <div className="chat-top">
        <div>DocAssist</div>
        <div onClick={onClick} className="close-button">
          ×
        </div>
      </div>
      <div className="chat-content--wrapper">
        <div className="chat-text mb-3">
          {welcome?.description
            ? welcome.description
            : FALLBACK_WELCOME_MESSAGE}{' '}
        </div>
        <div className="chat-questions">
          <input
            type="button"
            className={`chat-input mb-3 ${type === 'patient' ? 'active' : ''}`}
            value="Patient"
            onClick={() => getQuestions('patient')}
          />
          <input
            type="button"
            className={`chat-input ${type === 'provider' ? 'active' : ''}`}
            value="Provider"
            onClick={() => getQuestions('provider')}
          />
          {type && (
            <div className="chat-text mt-3 mb-3">Great! How can I help?</div>
          )}
        </div>
        <div className="chat-content" id="content-wrapper">
          {questions?.map((q) => {
            const isSelected = currentQuestion?.id === q.id

            const style = isSelected ? '' : 'none'

            return (
              <input
                key={q.id}
                type="button"
                className={`chat-input mb-3 ${isSelected ? 'active' : ''}`}
                value={q.description}
                onClick={() => handleClick(q)}
                style={{
                  display: currentQuestion.length ? style : '',
                }}
              />
            )
          })}
          {currentAnswer && (
            <>
              <div className="chat-text mt-3">{currentAnswer.description}</div>

              {currentAnswer.actionType && (
                <AnswerType
                  description={currentQuestion.description}
                  type={type}
                  actionType={currentAnswer.actionType}
                  action={currentAnswer.action}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Chat
