import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Global from '../../reactLayer/global'

const Pagination = (props) => {
  const { total, limit, offset, range, callBack, filter, page } = props
  const [list, setList] = useState([])
  const [prevPage, setprevPage] = useState(false)
  const [nextPage, setnextPage] = useState(false)
  const [beforeprevPage, setbeforeprevPage] = useState(false)
  const [beforenextPage, setbeforenextPage] = useState(false)

  const getList = async () => {
    let snumber,
      start = 0,
      end = range,
      arrLength
    let list = total / limit
    snumber = offset / limit

    let listInteger = parseInt(list)
    if (listInteger < list) {
      listInteger = listInteger + 1
    }

    if (snumber % range) {
      if (snumber >= range) {
        start = snumber - (snumber % range)
      }
      // start = end - (snumber % limit);
      end = start + end
      if (end > listInteger) {
        end = listInteger
      }
    } else {
      if (snumber > 0) {
        start = snumber
      }
      end = start + end
      if (end > listInteger) {
        end = listInteger
      }
    }

    if (parseInt(snumber) > 0) {
      setprevPage((parseInt(snumber) - 1).toString())
    } else {
      setprevPage(false)
    }

    if (parseInt(snumber) < listInteger - 1) {
      setnextPage(snumber + 1)
    } else {
      setnextPage(false)
    }

    if (parseInt(start) >= parseInt(range)) {
      setbeforeprevPage((start - range).toString())
    } else {
      setbeforeprevPage(false)
    }
    if (parseInt(end) < parseInt(listInteger)) {
      setbeforenextPage(end)
    } else {
      setbeforenextPage(false)
    }
    arrLength = await Global.getArray(start, end)
    setList([...arrLength])
  }
  const changePage = (e) => {
    e.preventDefault()
    let pageno = e.currentTarget.getAttribute('data-id')
    callBack(parseInt(pageno) * limit)
  }

  useEffect(() => {
    getList()
  }, [props])

  return (
    <Fragment>
      <Container>
        <Row>
          <div className="col-12">
            <div className="pagination-wrap">
              <ul className="pagination pg-blue justify-content-center">
                {prevPage ? (
                  <>
                    <li key={'prevPage'} className="page-item prev-space">
                      <a
                        data-id={prevPage}
                        onClick={(e) => changePage(e)}
                        className="page-link"
                        href="/#"
                      >
                        &lt; Previous
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      key={'prevPage'}
                      className="page-item prev-space"
                      style={{ opacity: 0 }}
                    >
                      <a data-id={prevPage} className="page-link" href="/#">
                        &lt; Previous
                      </a>
                    </li>
                  </>
                )}
                {beforeprevPage ? (
                  <>
                    <li
                      key={'beforeprevPage'}
                      data-id={beforeprevPage}
                      onClick={(e) => changePage(e)}
                      className="page-item dot-pagination"
                    >
                      ...
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      key={'beforeprevPage'}
                      data-id={beforeprevPage}
                      className="page-item dot-pagination"
                      style={{ opacity: 0 }}
                    >
                      ...
                    </li>
                  </>
                )}

                {list.map((l, li) =>
                  l * limit === offset ? (
                    <>
                      <li key={'paging-' + li} className="page-item active">
                        <a
                          className="page-link"
                          data-id={l}
                          onClick={(e) => e.preventDefault()}
                          href="/#"
                        >
                          {l + 1}
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li key={'paging-' + li} className="page-item">
                        <a
                          className="page-link"
                          data-id={l}
                          onClick={(e) => changePage(e)}
                          href="/#"
                        >
                          {l + 1}
                        </a>
                      </li>
                    </>
                  )
                )}

                {beforenextPage ? (
                  <>
                    <li
                      key={'beforenextPage'}
                      data-id={beforenextPage}
                      onClick={(e) => changePage(e)}
                      className="page-item dot-pagination"
                    >
                      ...
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      key={'beforenextPage'}
                      data-id={beforenextPage}
                      style={{ opacity: 0 }}
                      className="page-item dot-pagination"
                    >
                      ...
                    </li>
                  </>
                )}
                {nextPage ? (
                  <>
                    <li key={'nextPage'} className="page-item next-space">
                      <a
                        data-id={nextPage}
                        onClick={(e) => changePage(e)}
                        className="page-link"
                        href="/#"
                      >
                        Next &gt;
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li key={'nextPage'} className="page-item next-space">
                      <a
                        data-id={nextPage}
                        style={{ opacity: 0 }}
                        className="page-link"
                        href="/#"
                      >
                        Next &gt;
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Pagination
