import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { Container, Row } from 'react-bootstrap'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'
import Loadermodal from '../../global/loader-modal/loader-modal'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { Answers } from './answers'
import { QuestionForm } from './question-form'
import { Links } from './links'
import { config } from './config'

const DocAssist = () => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState()

  const [selectedQuestion, setSelectedQuestion] = useState()
  const [presentQuestionForm, setPresentQuestionForm] = useState(false)

  function reducer(state, action) {
    if (action.type === 'link') {
      return {
        ...state,
        link: action.payload,
        type: action.payload,
      }
    }
    if (action.type === 'subLink') {
      return {
        ...state,
        subLink: action.payload,
        type: action.payload,
      }
    }
    throw Error('Unknown action.')
  }

  const [state, dispatch] = useReducer(reducer, {
    link: '',
    subLink: '',
    type: '',
  })

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const getList = async () => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/qa/questions',
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)

    if (result.status) {
      setData(result.result.questions)
    }

    authContext.unsetLoader()
  }

  const onNewQuestionClick = (type, payload) => {
    setSelectedQuestion(null)
    dispatch({ type: type, payload: payload })
    setPresentQuestionForm(true)
  }

  const handleLinkClick = (type, value) => {
    setPresentQuestionForm(false)
    setSelectedQuestion(null)
    dispatch({
      type: type,
      payload: value,
    })
  }

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question)
    setPresentQuestionForm(false)
  }

  const onEditQuestion = (question) => {
    setSelectedQuestion(question)
    setPresentQuestionForm(true)
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive + ' admin-dashboard right-admin-section'
          }
        >
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          <Row className="bg-white-report">
            <div className="col-3">
              {config.map((link) => {
                const subLinks = link.subLinks

                const buildProps = (link) => ({
                  type: link.type,
                  link: link,
                  isActive: link.isActive(link, state),
                  onClick: handleLinkClick,
                  addNewQuestion: link.addNewQuestion(
                    link.type,
                    link,
                    onNewQuestionClick
                  ),
                  renderQuestions: link.renderQuestions(
                    link.value,
                    data,
                    selectedQuestion?.id,
                    handleQuestionClick,
                    onEditQuestion
                  ),
                })

                return (
                  <Links
                    key={link.value}
                    {...buildProps(link)}
                    SubLinks={() => {
                      return subLinks?.map((subLink) => (
                        <Links key={subLink.value} {...buildProps(subLink)} />
                      ))
                    }}
                  />
                )
              })}
            </div>
            <div className="col-9">
              {presentQuestionForm && (
                <div className="mb-3">
                  <QuestionForm
                    currentQuestion={selectedQuestion}
                    type={state.type}
                    fetchList={getList}
                  />
                </div>
              )}
              {selectedQuestion && !presentQuestionForm && (
                <Answers
                  question={selectedQuestion}
                  fetchList={getList}
                  questionType={state.type}
                />
              )}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default DocAssist
