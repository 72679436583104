import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import { AdminLinks } from '../../../linkFile'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import ManagementFilter from '../provider/filter'
import ImportModal from './import-modal'
import ProviderManagementInfoMadal from './provider-manage-info-modal'

import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../../global/modal/modal'

let searchText = ''
const Management = () => {
  const authContext = useContext(AuthContext)
  const [showSpecialities, setShowSpecialities] = useState(false)
  const [specialities, setSpecialities] = useState(undefined)
  const [selectedSpecialities, setSelectedSpecialities] = useState([])
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [data, setData] = useState([])
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [viewdetails, setViewdetails] = useState([])
  const [sort, setsort] = useState('ASC')
  const [sortName, setSortName] = useState('name')
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const currentUrl = window.location.href.split('/').slice(-1).pop()

  // FOR POPUP MODAL
  const modalList = ['manageInfo', 'importPopup']
  const openProfileTabModal = (modalName, value) => {
    getDetails(value?.id).then(() => {
      settabvisibleComponent(modalName)
      authContext.showModal()
    })
  }

  const getSpecialities = async (value) => {
    authContext.setLoader()
    let formData = {}
    if (value || searchText) {
      formData = {
        offset: filter.offset,
        limit: 50,
        searchValue: searchText,
        category: 'medical',
      }
    } else {
      formData = {
        offset: filter.offset,
        limit: 50,
        category: 'medical',
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/specialities/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    try {
      let result = await NetworkLayer.postData(submitData)
      setSpecialities(result?.body?.result)
    } catch (err) {
      console.log('ERROR :', err)
    }

    authContext.unsetLoader()
  }

  const handleCheckboxChange = (speciality) => {
    // Check if the speciality is already in the selectedSpecialities array
    if (selectedSpecialities.includes(speciality)) {
      // If it is, remove it
      setSelectedSpecialities((prevSelected) =>
        prevSelected.filter((item) => item !== speciality)
      )
    } else {
      // If it's not, add it
      setSelectedSpecialities((prevSelected) => [...prevSelected, speciality])
    }
  }

  useEffect(() => {
    handleFilterSpecs()
  }, [selectedSpecialities])

  const handleFilterSpecs = async () => {
    authContext.setLoader()
    // FORM DATA
    let formData = {
      offset: filter.offset,
      limit: filter.limit,
      sortOrder: sort,
      sortBy: sortName,
      selectedSpecialities:
        selectedSpecialities.length > 0 ? selectedSpecialities : null,
    }

    if (currentUrl === 'incomplete-registered-providers') {
      formData.actionType = 'incompleted'
    }

    if (currentUrl === 'latest-providers') {
      formData.actionType = 'latest'
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setData(result?.body?.result?.data)
    settotalCount(result?.body?.result?.totalRecords)
    authContext.unsetLoader()
  }

  useEffect(() => {
    getSpecialities()
  }, [])

  const getList = async (value) => {
    authContext.setLoader()
    // FORM DATA
    let formData = {}
    if (value || searchText) {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        searchValue: searchText,
        sortOrder: sort,
        sortBy: sortName,
      }
    } else {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        sortOrder: sort,
        sortBy: sortName,
      }
    }

    if (currentUrl === 'incomplete-registered-providers') {
      formData.actionType = 'incompleted'
    }

    if (currentUrl === 'latest-providers') {
      formData.actionType = 'latest'
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setData(result?.body?.result?.data)
    settotalCount(result?.body?.result?.totalRecords)
    authContext.unsetLoader()
  }

  const getDetails = async (id) => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/view/' + id,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)
    setViewdetails(result?.result)
    authContext.unsetLoader()
  }

  const updateStatus = async (id, status) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: id,
      status: status,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/status/update',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    getList()
  }

  const deleteProvider = async (id) => {
    authContext.setLoader()

    const formData = {
      id: id,
    }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/delete',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    if (window.confirm('Are you sure you want to delete this provider?')) {
      let result = await NetworkLayer.postData(submitData)
      setmodalProps({
        type: 'success',
        msg: 'Provider deleted successfully',
      })
      authContext.showResponseModal()
      getList()
    }

    authContext.unsetLoader()
  }

  useEffect(() => {
    getList()
  }, [filter.offset, sort])

  // PAGINATION CALLBACK
  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  const toggleStatus = (values) => {
    let status = values.status === 1 ? 0 : 1
    let id = values.id
    updateStatus(id, status)
  }

  const changeSorting = (e) => {
    let name = e.currentTarget.getAttribute('name')
    setSortName(name)
    setsort(sort == 'ASC' ? 'DESC' : 'ASC')
  }

  const searchFilter = (value) => {
    setfilter({ offset: 0, limit: 10 })
    searchText = value
    getList(value)
  }
  const importPopup = () => {
    settabvisibleComponent('importPopup')
    authContext.showModal()
  }

  const importResponse = (response) => {
    const message = response.message
    const result = response.result
    if (response.status === true) {
      setmodalProps({
        type: 'success',
        msg: `${message} ${JSON.stringify(result)}`,
      })
      getList()
    } else {
      setmodalProps({
        type: 'error',
        msg: response.message,
      })
    }
    authContext.showResponseModal()
  }

  return (
    <Fragment>
      <Loadermodal />
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}

      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'manageInfo' ? (
              <ProviderManagementInfoMadal data={viewdetails} />
            ) : tabvisibleComponent === 'importPopup' ? (
              <ImportModal
                responseHandler={importResponse}
                unsetComponent={settabvisibleComponent}
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <Row className="bg-white-report">
        <div className="table-report provider-management-outer">
          <div className="heading-wrap">
            <div className="table-heading">Provider Management</div>
          </div>
          <ManagementFilter importPopup={importPopup} search={searchFilter} />
          <div className="table-report-scroll mt-0">
            <table className="table">
              <thead>
                <tr>
                  <th className="data-sort">
                    <span className="sort-outer">
                      Provider Name
                      <span
                        className="data-sort-icon"
                        name="name"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>Years Exp.</th>
                  <th style={{ 'min-width': '130px' }}>
                    <span className="sort-outer">
                      Joined Date
                      <span
                        className="data-sort-icon"
                        name="createdAt"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="sort-outer">
                      {showSpecialities && (
                        <div className="specialities-container">
                          {specialities.specialties.rows.map((speciality) => (
                            <div key={speciality.id}>
                              <input
                                type="checkbox"
                                id={speciality.id}
                                checked={selectedSpecialities.includes(
                                  speciality.id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(speciality.id)
                                }
                              />
                              <label htmlFor={speciality.id}>
                                {speciality.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                      <button
                        className="spec-button"
                        onClick={() => setShowSpecialities(!showSpecialities)}
                      >
                        Speciality
                      </button>
                      <span
                        className="data-sort-icon"
                        name="speciality"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>Email</th>
                  <th>Location</th>
                  <th style={{ 'min-width': '150px' }}>Account Type</th>
                  <th style={{ 'min-width': '200px' }}>
                    <span className="sort-outer">
                      Last Sign In Date
                      <span
                        className="data-sort-icon"
                        name="lastLogin"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th style={{ 'min-width': '250px' }}>
                    <span className="sort-outer">
                      Last Appt. Request Date
                      <span
                        className="data-sort-icon"
                        name="appointmentDate"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th style={{ 'min-width': '120px' }}>Team Size</th>
                  <th style={{ 'min-width': '150px' }}>
                    <span className="sort-outer">
                      # Reviews from DS
                      <span
                        className="data-sort-icon"
                        name="dsReviewCount"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th style={{ 'min-width': '150px' }}>
                    <span className="sort-outer">
                      # Reviews from Web
                      <span
                        className="data-sort-icon"
                        name="totalReviewCount"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="sort-outer">
                      Active
                      <span
                        className="data-sort-icon"
                        name="status"
                        onClick={changeSorting}
                      >
                        <DataSortIcon />
                      </span>
                    </span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="table-providers">
                {data.length > 0 ? (
                  <>
                    {data.map((value, index) => (
                      <tr key={index}>
                        <td>{`${value.providers.firstName} ${value.providers.lastName}`}</td>
                        <td>{value.provider_exp}</td>
                        <td>{moment(value.createdAt).format('MM/DD/YYYY')}</td>
                        <td>{value.speciality}</td>
                        <td>{value.email}</td>
                        <td>
                          {value.practiceLocation}
                          {value.providerPracticeLocations &&
                          value.providerPracticeLocations[0] &&
                          value.providerPracticeLocations[0].cities
                            ? value.providerPracticeLocations[0].cities.name
                            : ''}
                          {value.providerPracticeLocations &&
                          value.providerPracticeLocations[0] &&
                          value.providerPracticeLocations[0].states
                            ? ', ' +
                              value.providerPracticeLocations[0].states.name
                            : ''}
                        </td>
                        <td>{value.userType}</td>
                        <td>{moment(value.lastLogin).format('MM/DD/YYYY')}</td>
                        <td>
                          {value.appointmentDate
                            ? moment(value.appointmentDate).format('MM/DD/YYYY')
                            : ''}
                        </td>
                        <td>{value.totalTeamMembersCount}</td>
                        <td>{value.dsReviewCount}</td>
                        <td>{value.totalReviewCount}</td>
                        <td>
                          <label className="active-toggle">
                            <input
                              type="checkbox"
                              checked={value.status == 1 ? 'checked' : ''}
                              onChange={() => {
                                console.log(value, 'value')
                                toggleStatus(value)
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <Link
                            onClick={() => {
                              openProfileTabModal('manageInfo', value)
                            }}
                            className="table-action view-all"
                          >
                            View
                          </Link>
                          <Link
                            to={`${AdminLinks.admin_provider_detail}/${value.id}`}
                            className="table-action view-all"
                          >
                            Edit
                          </Link>
                          <Link
                            className="table-action view-all"
                            onClick={() => {
                              deleteProvider(value.id)
                            }}
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="no-results">
                    <td>
                      <span>No results</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="report-page mt-4">
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
            />
          </div>
        </div>
      </Row>
    </Fragment>
  )
}

export default Management
