export const AddNewQuestion = ({ description, type, link, onClick }) => {
  return (
    <div className="form-add-link mb-3">
      <span
        style={{ color: '#077db4', cursor: 'pointer' }}
        onClick={() => {
          onClick(type, link.value)
        }}
      >
        {description}
      </span>
    </div>
  )
}
