import 'bootstrap/dist/css/bootstrap.css'
import { Fragment, memo, Suspense, useCallback, useRef, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { UserCheckIcon2, VirtualRecorderIcon } from '../../new/style/icons'
import { PatientFavorite } from '../patient-favorite'

const AllFilterModal = (props) => {
  const {
    filter,
    setfilter,
    specialtyList,
    langList,
    listPatientsTreated,
    closeModal,
  } = props
  const onSubmitLink = useRef()
  const [height, setHeight] = useState(0)
  const [advfilter, setAdvfilter] = useState({
    firstName: filter?.firstName,
    lastName: filter?.lastName,
    isChildren: filter?.isChildren,
    hasVirtualVisit: filter?.hasVirtualVisit,
    available72hours: filter?.available72hours,
    isTopShow: filter?.isTopShow,
    isSponsoredShow: filter?.isSponsoredShow,
    isAcceptingNewPatient: filter?.isAcceptingNewPatient,
    isGender: filter?.isGender,
    speciality: filter?.speciality,
    languages: filter?.languages,
    type: filter?.type,
  })

  const selectedLangItem = (mainData, selectiTems, type) => {
    if (mainData?.length > 0 && selectiTems?.length > 0) {
      return selectiTems.filter((node) =>
        mainData.filter((each) => each.toLowerCase() === node.toLowerCase())
      )
    }
    return []
  }

  const [otherData, setotherData] = useState({
    language: {
      value: selectedLangItem(langList, advfilter?.languages),
    },
    specialties: {
      value: advfilter?.speciality,
    },
  })

  const onChange = useCallback((name, value) => {
    setAdvfilter((preState) => ({
      ...preState,
      [name]: value,
    }))
  }, [])

  const addLangData = (e) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute('data-name')
    val = e.currentTarget.value
    data = otherData[name]
    arrayIndex = data.value.indexOf(val)
    if (arrayIndex > '-1') {
      data.value.splice(arrayIndex, 1)
    } else {
      data.value.push(val)
    }
    setotherData({ ...otherData, ...{ [name]: data } })
  }

  const addOptionChangeData = (e, id) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute('data-name')
    val = id
    data = otherData[name]
    arrayIndex = data.value.indexOf(val)
    if (arrayIndex > '-1') {
      data.value.splice(arrayIndex, 1)
    } else {
      data.value.push(val)
    }
    setotherData({ ...otherData, ...{ [name]: data } })
  }

  const changeOption = (id) => {
    if (id === 'hasVirtualVisit') {
      setAdvfilter({
        ...advfilter,
        ...{ hasVirtualVisit: advfilter?.hasVirtualVisit === 0 ? 1 : 0 },
      })
    }
    if (id === 'isAcceptingNewPatient') {
      setAdvfilter({
        ...advfilter,
        ...{
          isAcceptingNewPatient: advfilter?.isAcceptingNewPatient === 0 ? 1 : 0,
        },
      })
    }
    if (id === 'isTopShow') {
      setAdvfilter({
        ...advfilter,
        ...{ isTopShow: advfilter?.isTopShow === 0 ? 1 : 0 },
        // ...{
        //   type:
        //     advfilter?.isTopShow === 1
        //       ? advfilter?.type.filter((i) => {
        //           return i !== 'top'
        //         })
        //       : [...advfilter?.type, 'top'],
        // },
      })
    }
    if (id === 'isSponsoredShow') {
      setAdvfilter({
        ...advfilter,
        ...{ isSponsoredShow: filter?.isSponsoredShow === 0 ? 1 : 0 },
        ...{
          type:
            advfilter?.isSponsoredShow === 1
              ? advfilter?.type.filter((i) => {
                  return i !== 'sponsored'
                })
              : [...advfilter?.type, 'sponsored'],
        },
      })
    }
    if (id === 'available72hours') {
      setAdvfilter({
        ...advfilter,
        ...{ available72hours: filter?.available72hours === 0 ? 1 : 0 },
      })
    }
  }

  const changeTreatedOption = (val) => {
    setAdvfilter({
      ...advfilter,
      ...{ isChildren: val },
    })
  }

  const changeGenderOption = (val) => {
    setAdvfilter({
      ...advfilter,
      ...{ isGender: val },
    })
  }

  const onSubmit = useCallback(
    (e) => {
      setfilter((preState) => ({
        ...preState,
        callApi: true,
        offset: 0,
        limit: 10,
        ...advfilter,
        ...{
          speciality: otherData.specialties.value,
          languages: otherData.language.value,
        },
      }))
      closeModal(e, 'allFilterModal')
    },
    [advfilter, setfilter, otherData, closeModal]
  )

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const onPressEnter = (e) => {
    if (e && e.keyCode == 13) {
      onSubmitLink.current.click()
    }
  }
  const suspenseLoader = () => <p></p>

  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        <Container>
          <div className="search-modal-wrap">
            <div className="edit-all-filter">
              <div className="allfilter-innerbox">
                <Row>
                  <div className="col-12 value-box mb-3 mb-md-4">
                    <div className="virtual-quick-wrap">
                      <div className="virtual-left">
                        <label
                          className="pro-label"
                          style={{ marginLeft: '-40px', marginBottom: '24px' }}
                        >
                          Filter By
                        </label>
                        <div className="virtual-inner">
                          <span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="switch-btn6"
                                defaultChecked={
                                  advfilter?.hasVirtualVisit === 0
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  changeOption('hasVirtualVisit')
                                }
                              />
                              <div className="slider round"></div>
                            </label>
                          </span>
                          <div className="virtual-icon">
                            <VirtualRecorderIcon
                              boxSize="medium"
                              color="text-dark-gray"
                            />
                            <span>Virtual visits available</span>
                          </div>
                        </div>
                      </div>
                      <div className="quick-right">
                        <div className="quick-inner">
                          <span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="switch-btn7"
                                defaultChecked={
                                  advfilter?.available72hours === 0
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  changeOption('available72hours')
                                }
                              />
                              <div className="slider round"></div>
                            </label>
                          </span>
                          <span className="video-icon">
                            Available in the next 72 hours
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 mb-md-4">
                    <label className="pro-label"></label>
                    <div className="modal-filterby">
                      <div className="filterby-outer">
                        <div className="icons-inner">
                          <span
                            style={{
                              marginTop: '2px',
                            }}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="switch-btn8"
                                defaultChecked={
                                  advfilter?.type &&
                                  advfilter?.type.includes('top')
                                }
                                onChange={(e) => changeOption('isTopShow')}
                              />
                              <div className="slider round"></div>
                            </label>
                          </span>
                          <PatientFavorite size="small" />
                        </div>
                      </div>
                      <div className="filterby-outer">
                        <div className="icons-inner">
                          <span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="switch-btn9"
                                defaultChecked={
                                  advfilter?.type &&
                                  advfilter?.type.includes('sponsored')
                                }
                                onChange={(e) =>
                                  changeOption('isSponsoredShow')
                                }
                              />
                              <div className="slider round"></div>
                            </label>
                          </span>
                          <span>
                            Sponsored <div className="info-icon"></div>
                          </span>
                        </div>
                      </div>
                      <div className="filterby-outer">
                        <div className="icons-inner">
                          <span>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="switch-btn10"
                                defaultChecked={
                                  advfilter?.isAcceptingNewPatient === 0
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  changeOption('isAcceptingNewPatient')
                                }
                              />
                              <div className="slider round"></div>
                            </label>
                          </span>
                          <div className="patients-icon">
                            <UserCheckIcon2
                              boxSize="medium"
                              color="text-dark-gray"
                            />
                            <span>Accepting new patients</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3 mb-md-4">
                    <label className="pro-label">Gender</label>
                    <div className="gender-allfilter">
                      <div className="cus-radio">
                        <input
                          type="radio"
                          id="all"
                          name="all"
                          value=""
                          checked={advfilter.isGender === ''}
                          onClick={(e) =>
                            changeGenderOption(e.currentTarget.value)
                          }
                        />
                        <label htmlFor="all">All</label>
                      </div>
                      <div className="cus-radio">
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="male"
                          checked={advfilter.isGender === 'male'}
                          onClick={(e) =>
                            changeGenderOption(e.currentTarget.value)
                          }
                        />
                        <label htmlFor="male">Male</label>
                      </div>
                      <div className="cus-radio">
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="female"
                          checked={advfilter.isGender === 'female'}
                          onClick={(e) =>
                            changeGenderOption(e.currentTarget.value)
                          }
                        />
                        <label htmlFor="female">Female</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="pro-label">Language</label>
                    <div className="filter-language">
                      {langList.map((d, ind) => (
                        <div className="cus-check-rev" key={uuidv4()}>
                          <input
                            type="checkbox"
                            id={d.toLowerCase() + '-' + ind}
                            name="lang"
                            data-name="language"
                            key={d.toLowerCase() + '-' + ind}
                            value={d.toLowerCase()}
                            onChange={(e) => addLangData(e)}
                            defaultChecked={
                              otherData.language.value.indexOf(
                                d.toLowerCase()
                              ) > '-1'
                                ? true
                                : false
                            }
                          />
                          <label htmlFor={d.toLowerCase() + '-' + ind}>
                            {d}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 mb-4 all-fltrbtn">
                    <input
                      type="button"
                      className="next-btn-pro"
                      value="Search"
                      onClick={(e) => onSubmit(e)}
                      ref={onSubmitLink}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    </Suspense>
  )
}

AllFilterModal.defaultProps = {
  filter: null,
  setfilter: null,
  specialtyList: [],
  langList: [],
  listPatientsTreated: [],
  closeModal: null,
}

export default memo(AllFilterModal)
