import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { generateURLParams } from '../../../lib/utils'
import { BOX_SHADOW } from '../../../style/reusables'
import { Typography } from '../../../style/typography'
import { useGetSearchParams } from '../hooks/useGetSearchParams'

let autoComplete = null

const loadScript = (url, callback) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'

  // @ts-ignore
  if (script.readyState) {
    // @ts-ignore
    script.onreadystatechange = function () {
      // @ts-ignore
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        // @ts-ignore
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = () => callback()
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

const handleScriptLoad = (setCity, setZip, setInputValue, autoCompleteRef) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ['(regions)'], componentRestrictions: { country: 'us' } }
  )
  autoComplete.addListener('place_changed', () => {
    const place = autoComplete.getPlace()

    setCity(place?.name)

    const geo = place?.geometry?.location

    const coordinates = {
      lat: geo?.lat(),
      lng: geo?.lng(),
    }

    if (coordinates.lat && coordinates.lng) {
      getCurrentLocationZipCode(coordinates, place?.name, setZip)
    }

    setInputValue(place?.formatted_address)
  })
}

const getCurrentLocationZipCode = async (
  coordinates: { lat: number; lng: number },
  cityName: string,
  setZipCode: (zip: string, cityName: string) => void
) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&result_type=postal_code`
  try {
    const result = await fetch(url)
    const json = await result.json()
    const zipCode = json.results[0]?.address_components[0].short_name
    setZipCode(zipCode, cityName)
  } catch (e) {
    console.log(e)
  }
}

export const InputWithLocationSearch = () => {
  const history = useHistory()
  const location = useLocation()

  const query = useGetSearchParams()

  const autoCompleteRef = useRef(null)
  // const { browserLatLng } = useContext(AuthContext)

  const [inputValue, setInputValue] = useState(query.city || query.zip || '')

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&libraries=places&region=us`,
      () =>
        handleScriptLoad(updateCity, updateZip, setInputValue, autoCompleteRef)
    )
  }, [])

  // useEffect(() => {
  //   const { latitude, longitude } = browserLatLng
  //   if (latitude && longitude) {
  //     getCurrentLocationZipCode(latitude, longitude)
  //   }
  // }, [browserLatLng])

  const updateCity = (value: string) => {
    history.replace({
      pathname: location.pathname,
      search: generateURLParams({ ...query, city: value }),
    })
  }

  const updateZip = (zip: string, cityName: string) => {
    history.replace({
      pathname: location.pathname,
      search: generateURLParams({ ...query, zip: zip, city: cityName }),
    })
  }

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Typography fontSize="15px" color="primary" fontWeight={700}>
          Near:
        </Typography>
      </InputLeftElement>
      <Input
        variant="search"
        textIndent="40px"
        placeholder="Zip Code or City"
        name="find"
        ref={autoCompleteRef}
        type="text"
        {...BOX_SHADOW}
        onChange={(event) => {
          setInputValue(event.target.value)
        }}
        value={inputValue}
      />
    </InputGroup>
  )
}
