import { Box, Flex, Select } from '@chakra-ui/react'
import { Typography } from '../../style/typography'
import { ChevronLeftIcon } from '../../style/icons'

interface Props {
  setSort: (value: string) => void
}

export const Sort: React.FC<Props> = ({ setSort }) => {
  return (
    <Flex alignItems="center" gap={4}>
      <Typography color="text-light-gray" fontSize="md">
        Sort:
      </Typography>
      <Box>
        <Select
          onChange={(e) => setSort(e.target.value)}
          icon={
            <Box
              sx={{
                transform: 'rotate(-90deg)',
              }}
            >
              <ChevronLeftIcon color="primary" />
            </Box>
          }
        >
          <option value="distance">Distance</option>
          <option value="reviewCount">Most Reviewed</option>
          <option value="reviewAverage">Highest Rated</option>
        </Select>
      </Box>
    </Flex>
  )
}
