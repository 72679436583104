import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import MedicalSpecialty from './medical-specialty'
import PersonalInformation from './personal-information'
import PracticeInformation from './practice-information'
import Loadermodal from '../../global/loader-modal/loader-modal'
import { AdminLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'

const CreateProvider = (props) => {
  const authContext = useContext(AuthContext)
  const [postData, setPostData] = useState({})
  const [errors, setErrors] = useState([])
  const [validate, setValidate] = useState(false)

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const saveData = async (data) => {
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'providers/import',
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)

    if (result.body.code == 200) {
      props.callBack && props.callBack()
      successMsg('success', 'Record Updated Successfully.')
      authContext.unsetLoader()
    } else {
      successMsg('error', result.body.message)
      authContext.unsetLoader()
    }
  }

  const successMsg = (type, message) => {
    setModalProps({
      type: type,
      msg: message,
    })
    authContext.showResponseModal()
  }

  const collectData = (data, error) => {
    setErrors((prevState) => {
      return { ...prevState, ...error }
    })
    setPostData((prevState) => {
      return { ...prevState, ...data }
    })
    setValidate(false)
  }

  useEffect(() => {
    const error = Object.values(errors).filter((value) => value)

    const data = Object.values(postData).length

    if (!error.length && data) {
      saveData(postData)
    }
  }, [errors])

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Row className="bg-white-report patient-details-outer">
            <div className="table-report">
              {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
              <div className="heading-wrap">
                <div className="table-heading">Add Provider Detail</div>
              </div>
              <div className="col-12 mt-3">
                <Link
                  to={AdminLinks.admin_provider_management}
                  className="back-link"
                >
                  <span className="back-icon">
                    <BackIcon />
                  </span>
                  Back
                </Link>
              </div>
              <PersonalInformation
                collectData={collectData}
                shouldValidate={validate}
              />
              <MedicalSpecialty
                collectData={collectData}
                shouldValidate={validate}
              />
              <PracticeInformation
                shouldValidate={validate}
                collectData={collectData}
              />
            </div>
          </Row>
          <Row>
            <div className="action col-1 text-left mt-3">
              <input
                type="button"
                className="next-btn-pro"
                value="Save"
                onClick={() => setValidate(true)}
              />
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default CreateProvider
