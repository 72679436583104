import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Error403 from './global/errorpages/error-403'
import { AdminLinks, PatientLinks, ProviderLinks } from './linkFile'
import AuthContext from './reactLayer/auth/AuthContext'

const ProviderPages = ({ children }) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const checkPermission = (type = false) => {
    if (
      type &&
      authContext.user &&
      authContext.user.isProfileCompleted &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId === type
    ) {
      return true
    } else if (
      type &&
      authContext.user &&
      authContext.user.isProfileCompleted &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId !== type
    ) {
      return false
    } else {
      history.push({
        pathname: ProviderLinks.home,
      })
    }
  }

  return checkPermission(3) ? children : <Error403 />
}

const PatientPages = ({ children }) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const checkPermission = (type = false) => {
    if (
      type &&
      authContext.user &&
      authContext.user.isProfileCompleted &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId === type
    ) {
      return true
    } else if (
      type &&
      authContext.user &&
      authContext.user.isProfileCompleted &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId !== type
    ) {
      return false
    } else {
      history.push({
        pathname: PatientLinks.home,
      })
    }
  }

  return checkPermission(4) ? children : <Error403 />
}

const AdminPages = ({ children }) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()

  const checkPermission = (type = false) => {
    if (type && authContext.admin && authContext.admin.roleId === type) {
      return true
    } else if (type && authContext.admin && authContext.admin.roleId === 2) {
      return true
    } else {
      history.push({
        pathname: AdminLinks.admin_login,
      })
    }
  }

  return checkPermission(1) ? children : <Error403 />
}

export { ProviderPages, PatientPages, AdminPages }
