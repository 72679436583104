import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

const Referfrined = () => {
  return (
    <Fragment>
      <Container>
        <Row className="mb-4">
          <div className="col-12 px-0 px-md-3">
            <div className="refer-friend">
              <Row>
                {/* <div className="head-section"> */}
                <div className="heading">
                  Invite a friend or colleague to DocScheduler
                </div>

                {/* </div> */}
                <div className="offset-lg-2 col-lg-8 msg-box px-0 px-md-3">
                  <textarea
                    className="row=50 col=50"
                    placeholder="Enter email addresses separated by commas"
                  ></textarea>
                </div>
                <div className="offset-lg-2 col-lg-8 msg-box refer-radio px-0 px-md-3">
                  <div className="cus-radio">
                    <input type="radio" id="terms" name="terms" value="1" />
                    <label htmlFor="terms"></label>
                  </div>
                  <div>
                    I'd like you to join Doc Scheduler to help grow you clinic's
                    digital brand and increase patient volume and revenue. They
                    offer a 60 day free trail, if you are interested please
                    follow the link below or google DocScheduler.com to learn
                    more.
                  </div>
                </div>
                <div className="offset-lg-2 col-lg-8 msg-box refer-radio px-0 px-md-3">
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="personalmsg"
                      name="terms"
                      value="1"
                    />
                    <label htmlFor="personalmsg"></label>
                  </div>
                  <textarea
                    className="row=50 col=50"
                    placeholder="Enter your own personal message"
                  ></textarea>
                </div>

                <div className="col-12 mb-2 mb-md-5 text-center">
                  <input
                    type="button"
                    className="next-btn-pro"
                    value="Invite"
                  />
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Referfrined
