import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { ListGroup, Row } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

const SearchFilter = (props) => {
  const { setfilter, type, filter, resetInsurance } = props
  const [searchCate, setSearchCate] = useState('')
  const [isSearchingCate, setIsSearchingCate] = useState(false)
  const [displayCate, setDisplayCate] = useState(false)
  const [optionsCate, setOptionsCate] = useState([
    {
      specialityData: [],
      conditionData: [],
      treatmentData: [],
      doctorData: [],
    },
  ])
  const wrapperCateRef = useRef(null)
  const [displayInsuranceName, setDisplaInsuranceName] = useState(false)
  const [optionsInsuranceName, setOptionsInsuranceName] = useState([])
  const [searchInsuranceName, setSearchInsuranceName] = useState('')
  const [searchzipCity, setSearchZipCity] = useState('')
  const wrapperInsuranceRef = useRef(null)
  const [optionsInsuranceCategory, setOptionsInsuranceCategory] = useState([])
  const [seletedInsuranceCategory, setSeletedInsuranceCategory] = useState('')

  const getAllFindResult = useCallback(async (input) => {
    if (input.length >= 3) {
      setIsSearchingCate(true)
      setDisplayCate(true)
      let dataNode = [
        {
          specialityData: [],
          conditionData: [],
          treatmentData: [],
          doctorData: [],
        },
      ]
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'providers/search/suggestion/get',
        body: JSON.stringify({
          searchValue: input,
        }),
      }
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        if (result.body.result) {
          setIsSearchingCate(false)
          const { specialityData, conditionData, treatmentData, doctorData } =
            result.body.result
          let specialityNode = specialityData.map(({ id, speciality }) => ({
            id: id,
            data: speciality,
          }))
          let doctorListNode = doctorData.map(
            ({ id, firstName, lastName }) => ({
              id: id,
              data:
                (firstName ? firstName : '') + (lastName ? ' ' + lastName : ''),
            })
          )
          dataNode[0].specialityData = specialityNode
          dataNode[0].conditionData = conditionData
          dataNode[0].treatmentData = treatmentData
          dataNode[0].doctorData = doctorListNode
          if (
            specialityNode.length === 0 &&
            conditionData.length === 0 &&
            treatmentData.length === 0 &&
            doctorData.length === 0
          ) {
            setDisplayCate(false)
          }
        }
      } else {
        setIsSearchingCate(false)
      }
      setOptionsCate(dataNode)
    }
  }, [])

  const debounceGet = useRef(
    debounce((nextValue) => getAllFindResult(nextValue), 1000)
  ).current

  const handleChange = (event) => {
    const nextValue = event?.target?.value
    setSearchCate(nextValue)
    debounceGet(nextValue)
  }

  const updateCateDex = (each) => {
    setSearchCate(each)
    setDisplayCate(false)
  }

  const _renderListItem = (title = 'SPECIALTY', items) => {
    return (
      <ListGroup.Item>
        {title}
        <ListGroup className="borderless">
          {items.map((each, index) => (
            <ListGroup.Item
              className="cateFindItem"
              key={title + '_' + index}
              onClick={() => updateCateDex(each.data)}
            >
              {each.data}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ListGroup.Item>
    )
  }

  const updateInsuranceDex = (each) => {
    setSearchInsuranceName(each)
    setDisplaInsuranceName(false)
  }

  const _renderInsuranceListItem = (item, index) => {
    return (
      <ListGroup.Item
        className="option insuranceItem"
        onClick={() => updateInsuranceDex(item.name)}
        key={index}
      >
        {item.name}
      </ListGroup.Item>
    )
  }

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    const { current: wrapInsurance } = wrapperInsuranceRef
    if (wrap && !wrap.contains(event.target)) {
      setDisplayCate(false)
    }
    if (wrapInsurance && !wrapInsurance.contains(event.target)) {
      setDisplaInsuranceName(false)
    }
  }

  const handleClickInsuType = useCallback(
    (item) => {
      setSeletedInsuranceCategory(item)
      resetInsurance(item)
    },
    [resetInsurance]
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    async function getAllInsuranceResult() {
      let data = []
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'home/insurance/get',
      }
      let result = await NetworkLayer.getRequest(submitData)
      if (
        result.result?.data.length > 0 ||
        result.result?.customInsuranceData > 0
      ) {
        data = [].concat(
          [...result.result?.data],
          [...result.result?.customInsuranceData]
        )
      }
      setOptionsInsuranceName(data)
    }
    ;(type === 'language' || type === 'speciality') && getAllInsuranceResult()
  }, [type])

  useEffect(() => {
    async function getAllInsuranceCategoryResult() {
      let data = []
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'insurance/category/get',
      }
      let result = await NetworkLayer.getRequest(submitData)
      if (result.result?.length > 0) {
        data = [].concat(
          [...result.result].map((node) => {
            return {
              id: node,
              data: node,
            }
          })
        )
      }
      setOptionsInsuranceCategory(data)
    }
    type === 'insurance' && getAllInsuranceCategoryResult()
  }, [type])

  useEffect(() => {
    setfilter((preState) => ({
      ...preState,
      callApi: false,
      insurances: searchInsuranceName ? [].concat(searchInsuranceName) : [],
    }))
  }, [setfilter, searchInsuranceName])

  useEffect(() => {
    setfilter((preState) => ({
      ...preState,
      callApi: false,
      searchValue: searchCate ? searchCate : '',
    }))
  }, [setfilter, searchCate])

  useEffect(() => {
    setfilter((preState) => ({
      ...preState,
      callApi: false,
      cityOrZip: searchzipCity ? searchzipCity : '',
    }))
  }, [setfilter, searchzipCity])

  useEffect(() => {
    setfilter((preState) => ({
      ...preState,
      callApi: false,
      insurancesType: seletedInsuranceCategory ? seletedInsuranceCategory : '',
    }))
  }, [setfilter, seletedInsuranceCategory])

  useEffect(() => {
    if (filter?.callApi) {
      setSearchCate('')
      setSearchZipCity('')
      setSearchInsuranceName('')
      setSeletedInsuranceCategory('')
    }
  }, [filter])

  useEffect(() => {
    if (optionsInsuranceCategory.length > 0) {
      handleClickInsuType(optionsInsuranceCategory[0].data)
    }
  }, [handleClickInsuType, optionsInsuranceCategory])

  return (
    <Fragment>
      <Row>
        <div className="col-12">
          <div className="home-top-fields searchFilter">
            {type !== 'speciality' && (
              <span ref={wrapperCateRef}>
                <input
                  type="text"
                  name="find"
                  placeholder="Condition, Specialty, Treatment, or Doctor Name"
                  className="input-home input-find-searchby"
                  autoComplete="off"
                  value={searchCate}
                  onChange={handleChange}
                />
                <div className="find-home">Find:</div>
                {isSearchingCate && (
                  <div className="cateSearch">Searching ...</div>
                )}
                {displayCate &&
                  (optionsCate[0].specialityData.length > 0 ||
                    optionsCate[0].conditionData.length > 0 ||
                    optionsCate[0].treatmentData.length ||
                    optionsCate[0].doctorData.length > 0) && (
                    <div className="autoContainerCate">
                      <div className="autoContenrCate">
                        <ListGroup>
                          {optionsCate[0].specialityData.length > 0 &&
                            _renderListItem(
                              'SPECIALTY',
                              optionsCate[0].specialityData
                            )}
                          {optionsCate[0].conditionData.length > 0 &&
                            _renderListItem(
                              'CONDITION',
                              optionsCate[0].conditionData
                            )}
                          {optionsCate[0].treatmentData.length > 0 &&
                            _renderListItem(
                              'TREATMENT',
                              optionsCate[0].treatmentData
                            )}
                          {optionsCate[0].doctorData.length > 0 &&
                            _renderListItem(
                              'DOCTOR',
                              optionsCate[0].doctorData
                            )}
                        </ListGroup>
                      </div>
                    </div>
                  )}
                <div className="find-home">Find:</div>
              </span>
            )}
            <span>
              <input
                type="text"
                name="find"
                placeholder="Zip Code or City"
                className="input-home input-near-searchby"
                autoComplete="off"
                onChange={(event) => {
                  setSearchZipCity(event?.target?.value)
                }}
                value={searchzipCity}
              />
              <div className="find-home">Near:</div>
            </span>
            {(type === 'language' || type === 'speciality') && (
              <span ref={wrapperInsuranceRef}>
                <input
                  type="text"
                  name="insurance_name"
                  onClick={() => {
                    setDisplaInsuranceName(!displayInsuranceName)
                  }}
                  placeholder="Insurance Name"
                  autoComplete="off"
                  className="input-home input-ins-name-searchby"
                  value={searchInsuranceName}
                  onChange={(event) => {
                    setSearchInsuranceName(event?.target?.value)
                  }}
                />
                <ListGroup>
                  {displayInsuranceName && optionsInsuranceName.length > 0 && (
                    <div className="autoContainerInsuranceName">
                      <div className="autoContentInsuranceName">
                        {optionsInsuranceName
                          .filter(
                            ({ name }) =>
                              name
                                .toLowerCase()
                                .indexOf(searchInsuranceName.toLowerCase()) > -1
                          )
                          .map((value, i) =>
                            _renderInsuranceListItem(value, i)
                          )}
                      </div>
                    </div>
                  )}
                </ListGroup>
              </span>
            )}
            {type === 'insurance' && (
              <span>
                <div className="col-md-12 px-0">
                  <div className="myform">
                    <select
                      className="select-service"
                      onChange={(e) => handleClickInsuType(e?.target?.value)}
                    >
                      {optionsInsuranceCategory.map((node, index_index) => (
                        <option value={node.id} key={index_index}>
                          {node.data}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </span>
            )}
          </div>
        </div>
      </Row>
    </Fragment>
  )
}

SearchFilter.defaultProps = {
  type: '',
  setfilter: null,
  filter: null,
  resetInsurance: null,
}

export default memo(SearchFilter)
