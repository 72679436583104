import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ProviderLeftProfile from '../../components/pro-doc-profile-left/pro-doc-profile-left'
import ProviderRightProfile from '../../components/pro-doc-profile-right/pro-doc-profile-right'
import ProfileTabs from '../../components/profile-tabs/profile-tabs'
import ProviderHeading from '../../components/provider-heading/provider-heading'
import Ratingandreview from '../../components/rating-review/rating-review'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Claimform_modal from './Claimform_modal'

const Profile = (props) => {
  const authContext = useContext(AuthContext)
  const UserId =
    props.match && props.match.params && props.match.params.userID
      ? props.match.params.userID
      : null
  const [claim, setClaim] = useState(false)
  const [profile, setProfile] = useState({})
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
    claimProfile: false,
  })

  const changeClaimForm = () => {
    setClaim(true)
    authContext.showModal()
  }
  const closeClaimForm = () => {
    setClaim(false)
    authContext.hideModal()
  }

  const getProfile = async () => {
    let userId =
      props.match && props.match.params && props.match.params.userID
        ? props.match.params.userID
        : null
    if (userId) {
      authContext.setLoader()
      let headers = { 'Content-Type': 'application/json' }
      if (authContext.token) {
        headers['access-token'] = authContext.token
      }
      let fData = {}
      if (
        authContext.browserLatLng &&
        authContext.browserLatLng.latitude &&
        authContext.browserLatLng.longitude
      ) {
        fData['latitude'] = authContext.browserLatLng.latitude
        fData['longitude'] = authContext.browserLatLng.longitude
      }
      const submitData = {
        headers: headers,
        url: 'providers/get/' + userId,
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        let temp_result = result.body.result
        if (result.body.addressInfo) {
          temp_result['addressInfo'] = result.body.addressInfo
        }
        setProfile(temp_result)
      }
      authContext.unsetLoader()
    }
  }

  const forgetPassword = async () => {
    authContext.setLoader()
    let data = {
      email: profile.accountInfo.email,
    }

    let url = 'forgot-password'

    const submitData = {
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
        claimProfile: true,
      })
      authContext.showResponseModal()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
        claimProfile: true,
      })
      authContext.showResponseModal()
    }
    setTimeout(() => {
      setmodalProps({ claimProfile: false })
    }, 4000)
  }

  useEffect(() => {
    getProfile()
  }, [props])

  return (
    <Fragment>
      {/* header */}
      <Row>
        {authContext.responseModal && modalProps.claimProfile ? (
          <Msgmodal {...modalProps} />
        ) : (
          <></>
        )}
      </Row>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Book Doctor Appointments Online </title>
        <meta
          name="description"
          content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
        />
        <link
          rel="canonical"
          href={`https://www.docscheduler.com/profile/${UserId}`}
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/profile/${UserId}`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta
          property="og:title"
          content={`DocScheduler | Book Doctor Appointments Online`}
        />
        <meta
          name="twitter:title"
          content={`DocScheduler | Book Doctor Appointments Online`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
        />
        <meta
          name="twitter:description"
          content="Find &amp; book appointments with doctors, diagnostic labs, clinics, hospitals. Ask free health questions to doctors or get free tips from health experts."
        />
      </Helmet>
      <HeaderMain />
      {/* provider heading */}
      <ProviderHeading
        {...props}
        forgetPassword={forgetPassword}
        claimProfile={profile}
        setClaim={changeClaimForm}
      />
      <Container>
        {/* Profile section start */}
        <Row className="mb-4">
          <div className="col-12">
            <div className="provider-profile-inner">
              {claim === true ? (
                <Custommodal
                  unsetComponent={closeClaimForm}
                  componentName={
                    <Claimform_modal
                      userId={props.match.params.userID}
                      closeClaimForm={closeClaimForm}
                    />
                  }
                />
              ) : (
                <></>
              )}

              <ProviderLeftProfile
                {...profile}
                getProfile={getProfile}
                editable={false}
                secondarySection={true}
              />
              <ProviderRightProfile {...profile} getProfile={getProfile} />
            </div>
          </div>
        </Row>
        {/* tabs start here */}
        <ProfileTabs {...profile} editable={false} getProfile={getProfile} />
        {/* Review section start */}
        <Ratingandreview
          sourceList={profile.allSource ? profile.allSource : []}
          profile={profile}
          getProfile={getProfile}
          ratingReviews={profile.ratingReviews ? profile.ratingReviews : []}
        />
      </Container>

      <Footer />
    </Fragment>
  )
}

export default Profile
