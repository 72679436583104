import { Box, Button, Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { LogoIcon, UserCheckIcon, VirtualRecorderIcon } from '../../style/icons'
import { Typography } from '../../style/typography'
import { ResultProfile } from '../../types'
import { DoctorReview } from '../DoctorReview/DoctorReview'
import { PatientLinks } from '../../lib/links'
import { Scheduler } from '../Scheduler/Scheduler'
import Custommodal from '../../../global/modal/modal'
import RequestAppointmentModal from '../../../components/doctor-search-result/req-appontment-modal'
import { isAuthPatient } from '../../../components/search-results/utils'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { useContext, useState } from 'react'

interface Props {
  provider: ResultProfile
  selected?: boolean
  startDate: Date
}

export const DoctorCard: React.FC<Props> = ({
  provider,
  selected,
  startDate,
}) => {
  const authContext = useContext(AuthContext)

  const [presentBookModal, setPresentBookModal] = useState(false)
  const [bookSlot, setBookSlot] = useState(false)

  const person = provider.providers

  const location = provider.providerPracticeLocations[0]

  const openLoginModal = () => {
    authContext.showLoginModal({
      modalName: 'login',
      redirectUrl: PatientLinks.doctor_search_result,
    })
  }

  const openRequestModal = (slot: any) => {
    setPresentBookModal(true)
    setBookSlot(slot)
    authContext.showModal()
  }

  const bookAppointment = (slot) => {
    if (isAuthPatient(authContext)) {
      openRequestModal(slot)
    } else {
      openLoginModal()
    }
  }

  return (
    <Flex
      border="1px solid"
      borderColor={selected ? 'primary' : 'secondary-border'}
      borderRadius="12px"
      p={6}
      flexDirection="column"
      gap={4}
      width="100%"
      className={`card-${provider.id}`}
    >
      {presentBookModal && (
        <Custommodal
          componentName={
            provider && (
              <RequestAppointmentModal
                {...provider}
                bookSlot={bookSlot}
                // browserAddress={props.browserAddress}
              />
            )
          }
        />
      )}
      <Flex
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        gap={8}
        mb={4}
      >
        <Flex
          direction="column"
          gap={2}
          alignItems={['center', 'center', 'flex-start']}
        >
          <Box bg="border-gray" width="120px" height="120px" borderRadius="50%">
            <img
              style={{ width: '100%', height: '100%' }}
              src={provider?.profileImage}
              alt="Doctor image"
            />
          </Box>
          <Link to={`/profile/${provider.id}`}>
            <Typography as={'h4'} color="primary">
              {person.firstName} {person.lastName},{' '}
              {provider.providerMedicalCredientialsValue}
            </Typography>
          </Link>
          <Typography fontSize="14px" fontWeight="bold">
            {provider.providerMedicalSpecialitiesValue}
          </Typography>
          <Flex alignItems="center" gap={2}>
            <LogoIcon />
            <Typography fontSize="14px">
              {provider.totalEndorsementCount} Doctor Endorsements
            </Typography>
          </Flex>
          <Typography fontSize="12px" fontWeight="bold">
            Reviews From The Web
          </Typography>
          <DoctorReview
            reviewStars={provider.reviewStars}
            totalReviewAvg={provider.totalReviewAvg}
            totalReviewCount={provider.totalReviewCount}
          />
        </Flex>
        <Flex
          gap={4}
          flexDirection="column"
          width={['100%', '100%', '50%']}
          height="100%"
        >
          {person.allowOnline ? (
            <Scheduler
              locationId={location.id}
              startDate={startDate || new Date()}
              timeZone={'MST'}
              bookAppointment={bookAppointment}
            />
          ) : (
            <Flex height="100%" justifyContent="center" alignItems="center">
              <Typography textAlign="center">
                Online scheduling is not available for this provider
              </Typography>
            </Flex>
          )}
          <Link to={PatientLinks.jot_form}>
            <Button variant="primary">Text me to schedule</Button>
          </Link>
          {location.phoneNumber && (
            <Button variant="outline">{location?.phoneNumber}</Button>
          )}
        </Flex>
      </Flex>
      <Flex
        gap={4}
        borderTop="1px solid"
        borderColor="gray.200"
        pt={6}
        direction={['column', 'column', 'row']}
      >
        {person.isAcceptingNewPatient && (
          <Flex alignItems="center" gap={2}>
            <UserCheckIcon boxSize="medium" color="text-dark-gray" />
            <Typography
              fontSize="16px"
              color="text-light-gray"
              fontWeight={500}
            >
              Accepting New Patients
            </Typography>
          </Flex>
        )}
        {person.hasVirtualVisit && (
          <Flex alignItems="center" gap={2}>
            <VirtualRecorderIcon boxSize="medium" color="text-dark-gray" />
            <Typography
              fontSize="16px"
              color="text-light-gray"
              fontWeight={500}
            >
              Virtual Visit available
            </Typography>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
