import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'

import HeaderMain from '../../global/header/header-main'
import Footer from '../../global/footer/footer'
import TermsTwo from '../../components/privacy-terms/terms2'
import { Helmet } from 'react-helmet'

const AdditionalTerms = (props) => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Additional Terms</title>
        <meta name="description" content="Additional Policy" />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/additional-terms"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/additional-terms`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content={`DocScheduler | Additional Terms`} />
        <meta
          name="twitter:title"
          content={`DocScheduler | Additional Terms`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta property="og:description" content="Additional Policy" />
        <meta name="twitter:description" content="Additional Policy" />
      </Helmet>
      <HeaderMain {...props} />
      <TermsTwo />
      <Footer />
    </Fragment>
  )
}

export default AdditionalTerms
