import 'bootstrap/dist/css/bootstrap.css'
import moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row, Spinner } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { ReactComponent as FilterIcon } from '../../admin/assets/images/filter.svg'
import ProviderStep4 from '../../components/provider-steps/provider-step4'
import Custommodal from '../../global/modal/modal'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import { AppointmentRequestFilter } from './AppointmentRequestFilter'
import { timeValues } from './utils'

const Appointments = (props) => {
  const [allappointment, setallappointment] = useState([])
  // const [currentProvider, setCurrentProvider] = useState(
  //   props.userprofile?.accountInfo?.managedProviders[0]?.id
  // )
  const profile = props.userprofile ? props.userprofile : ''
  const authContext = useContext(AuthContext)
  const [prevAppointments, setprevAppointments] = useState(false)
  const [modal, setModal] = useState(false)
  const [period, setPeriod] = useState(timeValues[0])
  const [indexToEdit, setIndexToEdit] = useState(null)

  const getAllappointments = async (selectedPeriod) => {
    let days = period.value
    if (selectedPeriod) {
      if (selectedPeriod.value === period.value) {
        closeModal()
        return 0
      } else {
        setPeriod(selectedPeriod)
        days = selectedPeriod.value
      }
    }
    authContext.setLoader()
    let fData = {
      days: days,
      providerId: props.selectedProvider ? props.selectedProvider : null,
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'providers/appointment/request/get',
      body: JSON.stringify(fData),
    }
    let result = await NetworkLayer.postData(submitData)
    if (result.body.status) {
      setallappointment(result.body.result)
      authContext.unsetLoader()
    } else {
      setallappointment([])
      authContext.unsetLoader()
    }
    closeModal()
  }

  const getPrevMonthappointments = async () => {
    // authContext.setLoader();
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'providers/appointment/request/previous-month/get',
    }
    let result = await NetworkLayer.getRequest(submitData)
    if (result.status) {
      try {
        let appointmentdata = []
        let header = [
          { label: 'Requested Date', key: 'requestedDate' },
          { label: 'Name', key: 'name' },
          { label: 'Gender', key: 'gender' },
          { label: 'Preferred Pronouns', key: 'preferredPronoun' },
          { label: 'New Patient', key: 'newwPatient' },
          { label: 'Phone', key: 'phone' },
          { label: 'Preferred Appt Time', key: 'appt_time' },
          { label: 'Preferred Appt Date', key: 'appt_date' },
          { label: 'Appointment Status', key: 'appt_status' },
        ]
        result.result &&
          result.result.map((ud) => {
            let data = []
            ud.data &&
              ud.data.map((d) => {
                data.push({
                  requestedDate: d.createdAt ? d.createdAt : '',
                  name:
                    d.users.patients && d.users.patients.firstName
                      ? d.users.patients.firstName + ' '
                      : '' + d.users.patients && d.users.patients.middleName
                      ? d.users.patients.middleName + ' '
                      : '' + d.users.patients && d.users.patients.lastName
                      ? d.users.patients.lastName + ' '
                      : '',
                  gender:
                    d.users.patients && d.users.patients.gender
                      ? d.users.patients.gender
                      : '',
                  preferredPronoun:
                    d.users.patients && d.users.patients.preferredPronoun
                      ? d.users.patients.preferredPronoun
                      : '',
                  newwPatient: d.isNewPatient ? 'Yes' : 'No',
                  reason: d.visitType ? d.visitType : '',
                  phone:
                    d.users.patients && d.users.patients.phoneType === 'cell'
                      ? d.users.patients.homeNumber
                        ? Global.phoneFormator(d.users.patients.homeNumber)
                        : ''
                      : d.users.patients.phoneNumber &&
                        Global.phoneFormator(d.users.patients.phoneNumber),
                  appt_time: d.preferAppointmentTime
                    ? d.preferAppointmentTime
                    : '',
                  appt_date: d.appointmentDayType
                    ? d.appointmentDayType
                    : d.appointmentDate
                    ? d.appointmentDate
                    : '',
                  appt_status: d.bookingStatus ? 'Schedule' : 'Unschedule',
                })
              })
            appointmentdata.push({
              header: header,
              data: data,
              practiceName: {
                monthName: ud.monthName,
                practiceName: ud.practiceName,
                year: ud.year,
              },
            })
          })
        setprevAppointments([...appointmentdata])
      } catch (e) {
        setprevAppointments([])
      }
    } else {
      // authContext.unsetLoader();
      setprevAppointments([])
    }
  }

  const changeSchedue = async (e, id, status) => {
    e && e.preventDefault()
    authContext.setLoader()
    let fData = { id: id, bookingStatus: status }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'appointment/book/status/update',
      body: JSON.stringify(fData),
    }
    let result = await NetworkLayer.postData(submitData)
    if (result.body.status) {
      getAllappointments()
      setIndexToEdit(null)
      authContext.unsetLoader()
    } else {
      authContext.unsetLoader()
    }
  }
  const openModal = () => {
    setModal(true)
    authContext.showModal()
  }

  const closeModal = () => {
    setModal(false)
    authContext.hideModal()
  }

  // useEffect(() => {
  //   if (allappointment.length > 0) {
  //     getSurvey(allappointment[0].users.patients.id)
  //   }
  // }, [allappointment])

  function convertToLocalDate(dateString) {
    const date = moment(dateString, 'dddd, MMMM Do, YYYY h:mm A').toDate()
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }

    const localDateString = date.toLocaleDateString(undefined, options)
    const localTimeString = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    })

    return `${localDateString} ${localTimeString}`
  }

  useEffect(() => {
    getAllappointments()
    getPrevMonthappointments()
  }, [props.selectedProvider])
  return (
    <Fragment>
      {modal && (
        <Custommodal
          unsetComponent={closeModal}
          componentName={
            <AppointmentRequestFilter
              period={period}
              setPeriod={setPeriod}
              getAllAppointments={getAllappointments}
            />
          }
        />
      )}
      {(profile &&
        profile.providerCurrentPlan &&
        profile.providerCurrentPlan.providerCurrentServices &&
        profile.providerCurrentPlan.providerCurrentServices.indexOf(3) >
          '-1') ||
      props.selectedProvider ? (
        <>
          <Container className="appointment-wrap">
            <Row>
              <div className="col-12">
                <div className="appoint-sub-head-wrap">
                  <h2>Appointment Requests</h2>
                  <div className="filter-tab">
                    <button
                      className="filter-btn"
                      type="button"
                      onClick={(e) => {
                        openModal(e, 'allFilterModal')
                      }}
                    >
                      <span className="filter-icon">
                        <FilterIcon />
                      </span>
                      <span>All Filters</span>
                    </button>
                  </div>
                </div>
              </div>
            </Row>

            <Row>
              <div className="col-md-6">
                <div className="appoint-req-record">
                  <span>
                    <div className="svgtime-icon"></div>
                    {period.label}
                  </span>
                  <span>
                    {allappointment ? allappointment.length : 0} Appointment
                    Requests
                  </span>
                </div>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="schedule-icons">
                  <span>Scheduled</span>
                  <span>Unscheduled</span>
                  <span>Cancelled</span>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12 mb-5">
                <div className="appoint-box">
                  {/* heading start */}
                  <div className="table-heading-wrap">
                    <div>Request Date</div>
                    <div>Patient Information</div>
                    <div>Insurance Information</div>
                    <div>Appointment Status</div>
                  </div>
                  {/* Content start */}
                  <div className="appoint-box-content">
                    {/* heading start */}
                    <div className="table-heading-wrap-device">
                      <div>Request Date</div>
                      <div>Patient Information</div>
                      <div>Insurance Information</div>
                      <div>Appointment Status</div>
                    </div>
                    {/* row 1 */}
                    {allappointment.map((ad, ai) => (
                      <div key={ai} className="table-content-appointment">
                        <div>
                          <p>
                            <span>
                              {ad.createdAt
                                ? convertToLocalDate(ad.createdAt)
                                : ad.appointmentDate}
                            </span>
                          </p>
                        </div>
                        <div>
                          {ad.users.patients ? (
                            <>
                              <p>
                                <span>Name:</span>
                                <span>
                                  {ad.childId
                                    ? ad.users.childInsurance.find(
                                        (x) => x.id === ad.childId
                                      ).firstName
                                    : ad.users.patients.firstName
                                    ? ad.users.patients.firstName + ' '
                                    : ''}
                                  {ad.childId
                                    ? ad.users.childInsurance.find(
                                        (x) => x.id === ad.childId
                                      ).middleName
                                      ? ' ' +
                                        ad.users.childInsurance.find(
                                          (x) => x.id === ad.childId
                                        ).middleName
                                      : ''
                                    : ad.users.patients.middleName
                                    ? ad.users.patients.middleName + ' '
                                    : ''}
                                  {ad.childId
                                    ? ad.users.childInsurance.find(
                                        (x) => x.id === ad.childId
                                      ).lastName
                                      ? ' ' +
                                        ad.users.childInsurance.find(
                                          (x) => x.id === ad.childId
                                        ).lastName
                                      : ''
                                    : ad.users.patients.lastName
                                    ? ad.users.patients.lastName + ' '
                                    : ''}
                                </span>
                              </p>
                            </>
                          ) : (
                            <></>
                          )}

                          <p>
                            <span>Gender:</span>
                            <span>
                              {ad.users.patients && ad.users.patients.gender
                                ? ad.users.patients.gender
                                : ''}
                            </span>
                          </p>
                          <p>
                            <span>Preferred Pronouns:</span>
                            <span>
                              {ad.users.patients &&
                              ad.users.patients.preferredPronoun
                                ? ad.users.patients.preferredPronoun
                                : ''}
                            </span>
                          </p>
                          <p>
                            <span>New Patient:</span>
                            <span>{ad.isNewPatient ? 'Yes' : 'No'}</span>
                          </p>
                          <p>
                            <span>Visit Reason:</span>
                            <span>
                              {ad.appointmentDescription
                                ? ad.appointmentDescription
                                : 'No'}
                            </span>
                          </p>
                          <p>
                            <span>Phone:</span>
                            <span>
                              {ad.users.patients &&
                              ad.users.patients.phoneType === 'cell' &&
                              ad.users.patients.homeNumber
                                ? Global.phoneFormator(
                                    ad.users.patients.homeNumber
                                  )
                                : ad.users.patients &&
                                  ad.users.patients.phoneNumber
                                ? Global.phoneFormator(
                                    ad.users.patients.phoneNumber
                                  )
                                : ''}
                            </span>
                          </p>
                          <p>
                            <span>Preferred Appt Time:</span>
                            <span>
                              {ad.preferAppointmentTime
                                ? `${ad.preferAppointmentTime} ${ad.providerPracticeLocations.timeZone}`
                                : ''}
                            </span>
                          </p>
                          <p>
                            <span>Preferred Appt Date:</span>
                            <span>
                              {ad.appointmentDayType
                                ? ad.appointmentDayType
                                : ad.appointmentDate
                                ? ad.appointmentDate
                                : ''}
                            </span>
                          </p>
                        </div>
                        <div>
                          {ad.users.patientInsurances &&
                            ad.users.patientInsurances.map((ins, insIndex) => (
                              <ul className="mb-2" key={insIndex}>
                                <li style={{ width: '100%' }}>
                                  <p>
                                    <span>Insurance Name:</span>
                                    <span>
                                      {ins.insurances && ins.insurances.name
                                        ? ins.insurances.name
                                        : ''}
                                    </span>
                                  </p>
                                  <p>
                                    <span>Plan Name:</span>
                                    <span>
                                      {ins.planName ? ins.planName : ''}
                                    </span>
                                  </p>
                                  <p>
                                    <span>Group #:</span>
                                    <span>{ins.group ? ins.group : ''}</span>
                                  </p>
                                  <p>
                                    <span>Membership ID:</span>
                                    <span>
                                      {ins.membershipId ? ins.membershipId : ''}
                                    </span>
                                  </p>
                                  <p>
                                    <span>Health Survey:</span>
                                    <span>PDF</span>
                                  </p>
                                </li>
                              </ul>
                            ))}
                        </div>
                        <div>
                          <ul className="schedule-status">
                            {indexToEdit !== ai ? (
                              <>
                                <li
                                  className={
                                    ad.bookingStatus === 1
                                      ? 'scheduled'
                                      : ad.bookingStatus === 2
                                      ? 'unscheduled'
                                      : ad.bookingStatus === 4
                                      ? 'cancelled'
                                      : 'unscheduled'
                                  }
                                >
                                  {ad.bookingStatus === 1
                                    ? 'Scheduled'
                                    : ad.bookingStatus === 2
                                    ? 'Unscheduled'
                                    : ad.bookingStatus === 4
                                    ? 'Cancelled'
                                    : 'Unconfirmed'}
                                </li>
                                <span className="switch-link">
                                  <button
                                    style={{
                                      background: 'transparent',
                                      border: 'none',
                                      color: '#077db4',
                                    }}
                                    onClick={() => setIndexToEdit(ai)}
                                  >
                                    Change status
                                  </button>
                                </span>
                              </>
                            ) : ad.bookingStatus === 0 ? (
                              <>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 1)}
                                >
                                  Schedule
                                </li>
                                <li
                                  className="unscheduled"
                                  onClick={() => setIndexToEdit(null)}
                                >
                                  Unconfirmed
                                </li>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 4)}
                                >
                                  Cancel
                                </li>
                              </>
                            ) : ad.bookingStatus === 1 ? (
                              <>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 2)}
                                >
                                  Unschedule
                                </li>
                                <li
                                  className="scheduled"
                                  onClick={() => setIndexToEdit(null)}
                                >
                                  Scheduled
                                </li>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 4)}
                                >
                                  Cancel
                                </li>
                              </>
                            ) : ad.bookingStatus === 2 ? (
                              <>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 1)}
                                >
                                  Schedule
                                </li>
                                <li
                                  className="unscheduled"
                                  onClick={() => setIndexToEdit(null)}
                                >
                                  Unscheduled
                                </li>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 4)}
                                >
                                  Cancel
                                </li>
                              </>
                            ) : (
                              <>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 1)}
                                >
                                  Schedule
                                </li>
                                <li
                                  className="cancelled"
                                  onClick={() => setIndexToEdit(null)}
                                >
                                  Canceled
                                </li>
                                <li
                                  className="blank"
                                  onClick={(e) => changeSchedue(e, ad.id, 2)}
                                >
                                  Unschedule
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12">
                <div className="appoint-sub-head-wrap prev-head">
                  <h2>Previous Month Appointment Request File(s)</h2>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12">
                <div className="prev-file-wrap">
                  {!prevAppointments ? (
                    <>
                      <div className="loadingContainer">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    </>
                  ) : (
                    <>
                      {prevAppointments &&
                        prevAppointments.map((d, i) => (
                          <Row className="prev-file-box" key={i}>
                            <div className="col-lg-4 col-md-6 text-center text-lg-left">
                              Provider/
                              {`${d.practiceName.practiceName}  ${d.practiceName.monthName} ${d.practiceName.year}`}{' '}
                            </div>
                            <div className="col-lg-4 col-md-6 text-center text-lg-left"></div>
                            <div className="col-lg-4 col-md-12 text-center text-lg-left mt-4 mt-lg-0">
                              <CSVLink
                                data={d.data}
                                headers={d.header}
                                filename={`${d.practiceName.practiceName}-${d.practiceName.monthName}-${d.practiceName.year}.csv`}
                              >
                                <img
                                  src="/assets/images/xls-icon.png"
                                  className="xls-icon"
                                  alt="Download CSV"
                                />
                                Download File
                              </CSVLink>
                            </div>
                          </Row>
                        ))}
                    </>
                  )}

                  {/* <Row className="prev-file-box">
                                <div className="col-lg-4 col-md-6 text-center text-lg-left">Provider/Practice Name_Month</div>
                                <div className="col-lg-4 col-md-6 text-center text-lg-left">10/12/2019 12:25 PM</div>
                                <div className="col-lg-4 col-md-12 text-center text-lg-left mt-4 mt-lg-0"><a href="/#"><img src="/assets/images/xls-icon.png" className="xls-icon" alt="Download XLS"/>Download File</a></div>
                            </Row> */}
                </div>
              </div>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Container className="appointment-wrap modal-wrapper">
            <Row>
              <div className="col-12 profile-plans">
                <div className="provider-step4-wrap">
                  <ProviderStep4 title={'Manage Subscriptions'} />
                </div>
              </div>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

export default Appointments
