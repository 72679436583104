import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container } from 'react-bootstrap'
import SearchResults from '../../components/search-results/search-results'
import Footer from '../../global/footer/footer'
import Header from '../../global/header/header'
import useLocationMod from '../../hooks/useLocationMod'
import AuthContext from '../../reactLayer/auth/AuthContext'

const SearchResult = (props) => {
  const locationMod = useLocationMod()
  const authContext = useContext(AuthContext)
  const searchText = (findHeaderTextFind, find) => {
    let data = ''
    if (find) {
      data = find
    }
    if (findHeaderTextFind) {
      data = findHeaderTextFind
    }
    return data
  }

  const [filter, setFilter] = useState({
    callApi: false,
    firstName: '',
    lastName: '',
    city: locationMod.location?.state?.city,
    zip: locationMod.location?.state?.zip,
    speciality:
      locationMod.location.state && locationMod.location.state.speciality
        ? [].concat(locationMod.location.state.speciality)
        : [],
    languages:
      locationMod.location.state && locationMod.location.state.languages
        ? [].concat(locationMod.location.state.languages)
        : ['english'],
    insurances:
      locationMod.location.state && locationMod.location.state.insurance_Name
        ? [].concat(locationMod.location.state.insurance_Name)
        : [],
    focusAreas: locationMod?.location?.state?.focusArea || '',
    searchValue: searchText(
      locationMod.location.state &&
        locationMod.location.state.findHeaderTextFind
        ? locationMod.location.state.findHeaderTextFind
        : '',
      locationMod.location.state && locationMod.location.state.find
        ? locationMod.location.state.find
        : ''
    ),
    hasVirtualVisit: 0,
    isAcceptingNewPatient: 0,
    available72hours: 0,
    isTopShow: 0,
    isSponsoredShow: 0,
    isGender: '',
    isChildren:
      locationMod.location.state && locationMod.location.state.isChildren
        ? locationMod.location.state.isChildren
        : false,
    offset: 0,
    limit: 10,
    latitude: authContext.browserLatLng.latitude,
    longitude: authContext.browserLatLng.longitude,
    type: [],
  })

  return (
    <Fragment>
      <Container fluid className="header px-0">
        <Header />
      </Container>
      <SearchResults
        filter={filter}
        setFilter={setFilter}
        userCateType={'provider'}
        setopenLogin={props.setopenLogin ? props.setopenLogin : ''}
      />
      <Footer />
    </Fragment>
  )
}

export default SearchResult
