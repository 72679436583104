import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Image } from '@chakra-ui/react'

import AuthContext from '../../../reactLayer/auth/AuthContext'
import StorageAdaptation from '../../../reactLayer/storage/storage'
import Custommodal from '../../../global/modal/modal'
import Notifyregister from '../../../components/provider-login/Notifyregistersuccess'
import Login from '../../../components/provider-login/provider-login'
import ServiceComingSoon from '../../../components/provider-login/service-area-coming-soon'
import Twostep from '../../../components/provider-login/twostep'
import { HeaderActions } from '../Layout/Header/HeaderActions'
import { Box, Flex } from '@chakra-ui/react'
import { SearchInput } from './SearchInput/SearchInput'
import { Filter } from './types'
import { mobileVisibility } from '../../style/reusables'
import { MobileMenu } from '../Layout/MobileMenu'
import { BurgerMenu } from '../Layout/BurgerMenu'
import { LoggedInActions } from '../Layout/Header/LoggedInActions'

interface Props {
  filter: Filter
  setFilter: (filter: Filter) => void
}

export const SearchHeader: React.FC<Props> = ({ filter, setFilter }) => {
  const authContext = useContext(AuthContext)
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [tokenExpire, settokenExpire] = useState(false)
  const modalList = [
    'login',
    'twostep',
    'service-comming-soon',
    'notify-register',
  ]

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const openLoginModal = (e, modalName) => {
    // authContext.hideModal()
    e && e.preventDefault()
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const closeModals = () => {
    authContext.showLoginModal(false)
    settabvisibleComponent('')
  }

  useEffect(() => {
    // @ts-ignore
    if (location && location.state && location.state.modalName) {
      // @ts-ignore
      openLoginModal('', location.state.modalName)
    } else if (authContext.loginModal && authContext.loginModal.modalName) {
      openLoginModal('', authContext.loginModal.modalName)
    }

    if (
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') &&
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') ===
        'patient' &&
      !StorageAdaptation.getLocalStorage('token', false, 'local')
    ) {
      settokenExpire(true)
      openLoginModal('', 'login')
      StorageAdaptation.removeLocalStorage('tokenExpire', 'session')
    }

    return () => {
      settabvisibleComponent('')
    }
  }, [authContext.loginModal])

  const isLoggedIn = authContext.user

  return (
    <Box py={8}>
      {modalList.indexOf(tabvisibleComponent) > -1 && (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'login' ? (
              <Login
                ltype={'patient'}
                closeModals={closeModals}
                settabvisibleComponent={settabvisibleComponent}
                tokenExpire={tokenExpire}
              />
            ) : tabvisibleComponent === 'twostep' ? (
              <Twostep
                ltype={'patient'}
                settabvisibleComponent={settabvisibleComponent}
              />
            ) : tabvisibleComponent === 'service-comming-soon' ? (
              <ServiceComingSoon ltype={'patient'} />
            ) : tabvisibleComponent === 'notify-register' ? (
              <Notifyregister ltype={'patient'} />
            ) : (
              <></>
            )
          }
        />
      )}
      <Flex gap={4} alignItems="center">
        <Link to="/">
          <Image
            minWidth="48px"
            height="48px"
            src="/assets/images/logo-icon.svg"
            alt="DocScheduler"
          />
        </Link>
        <SearchInput
          filter={filter}
          setFilter={setFilter}
          hasExtendedFindInput=""
          hasInsuranceInput=""
        />
        <BurgerMenu
          mobileMenuVisible={mobileMenuVisible}
          setMobileMenuVisible={setMobileMenuVisible}
        />
        <MobileMenu mobileMenuVisible={mobileMenuVisible} />
        {isLoggedIn ? (
          <Box display={mobileVisibility}>
            {/*<LoggedInActions />*/}
            <div></div>
          </Box>
        ) : (
          <Box display={mobileVisibility}>
            <HeaderActions
              direction="row"
              setVisibleComponent={settabvisibleComponent}
            />
          </Box>
        )}
      </Flex>
    </Box>
  )
}
