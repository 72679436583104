import { Link } from 'react-router-dom'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import { useContext, useState } from 'react'
import AuthContext from '../../reactLayer/auth/AuthContext'

export const BurgerMenu = ({ mobileMenuVisible, setMobileMenuVisible }) => {
  const authContext = useContext(AuthContext)
  const [createAccMenuVisible, setCreateAccMenuVisible] = useState(false)
  const [signInMenuVisible, setSignInMenuVisible] = useState(false)

  const toggleCreateAccMenu = () => {
    setCreateAccMenuVisible(!createAccMenuVisible)
    // Close signInMenu when createAccMenu is toggled
    setSignInMenuVisible(false)
  }

  const toggleSignInMenu = () => {
    setSignInMenuVisible(!signInMenuVisible)
    // Close createAccMenu when signInMenu is toggled
    setCreateAccMenuVisible(false)
  }

  const openLoginModal = (e) => {
    // authContext.hideModal()
    e && e.preventDefault()
    authContext.showModal()
  }

  return (
    <>
      <div
        className={`burger-menu ${mobileMenuVisible ? 'menu-visible' : ''}`}
        onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
      >
        <div className="bar bar-top"></div>
        <div className="bar bar-middle"></div>
        <div className="bar bar-bottom"></div>
      </div>

      <div
        className={`mobile-menu
                      ${mobileMenuVisible ? 'mobile-menu--visible' : ''}`}
      >
        <div className="header-practice-link">
          <Link to={ProviderLinks.freeprofile} className="under-line">
            List your practice on DocScheduler
          </Link>
        </div>
        <div className="create-acc-block" onClick={toggleCreateAccMenu}>
          <a href="/#" onClick={(e) => e.preventDefault()}>
            Create Account
          </a>
          {createAccMenuVisible && (
            <div className="home-menu--mobile">
              <div className="home-menu-inner">
                <Link to={PatientLinks.register_step1}>
                  <div className="home-menu-links">
                    <span>Patients</span>
                    <span>
                      <a href="/#">Create Account</a>
                    </span>
                  </div>
                </Link>
                <Link to={ProviderLinks.freeprofile}>
                  <div className="home-menu-links no-border">
                    <span>Doctors</span>
                    <span>
                      <a href="/#">List Your Practice</a>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="sign-in-block" onClick={toggleSignInMenu}>
          <a
            className="sign-in-href"
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Sign in
          </a>
          {signInMenuVisible && (
            <div className="home-menu--mobile">
              <div className="home-menu-inner">
                <Link to="/">
                  <div className="home-menu-links">
                    <span>Patients</span>
                    <span>
                      <a
                        href="/#"
                        onClick={(e) => {
                          window.localStorage.setItem('role', 4)
                          window.localStorage.removeItem('subRole')
                          openLoginModal(e, 'login')
                        }}
                      >
                        Sign in
                      </a>
                    </span>
                  </div>
                </Link>
                <Link to={ProviderLinks.login}>
                  <div className="home-menu-links">
                    <span>Doctors</span>
                    <span>
                      <a
                        onClick={() => {
                          window.localStorage.setItem('role', 3)
                          window.localStorage.removeItem('subRole')
                        }}
                        href="/#"
                      >
                        Sign in
                      </a>
                    </span>
                  </div>
                </Link>
                <Link to={ProviderLinks.login}>
                  <div className="home-menu-links">
                    <span>Team Members</span>
                    <span>
                      <a
                        onClick={() => {
                          window.localStorage.setItem('role', 3)
                          window.localStorage.setItem('subRole', 3)
                        }}
                        href="/#"
                      >
                        Sign in
                      </a>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
