import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import { AdminLinks } from '../../../linkFile'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import Global from '../../../reactLayer/global'
import NetworkLayer from '../../../reactLayer/Network/Network'
import InputValidation from '../../../reactLayer/validation'

const randompassword = (length) => {
  var result = []

  var charactersAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var charactersSymbol = '@#'
  var charactersNumber = '123456789'
  var characters = 'abcdefghijklmnopqrstuvwxyz'
  var charactersAlphaLength = charactersAlpha.length
  var charactersSymbolLength = charactersSymbol.length
  var charactersNumberLength = charactersNumber.length
  var charactersLength = characters.length

  for (var i = 0; i < length; i++) {
    if (i == 0) {
      result.push(
        charactersAlpha.charAt(
          Math.floor(Math.random() * charactersAlphaLength)
        )
      )
    } else if (i == 3) {
      result.push(
        charactersSymbol.charAt(
          Math.floor(Math.random() * charactersSymbolLength)
        )
      )
    } else if (i == 4 || i == 6) {
      result.push(
        charactersNumber.charAt(
          Math.floor(Math.random() * charactersNumberLength)
        )
      )
    } else {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      )
    }
  }
  return result.join('')
}

const AddAccountInfo = (props) => {
  const history = useHistory()
  const authContext = useContext(AuthContext)

  const [zipCodes, setZipCodes] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [filterCities, setfilterCities] = useState([])
  const emailErrorMsg = ['Email is required', 'Invalid Email']
  const fnameErrorMsg = ['First Name is required', 'Invalid First Name']
  const mnameErrorMsg = ['Invalid Midle Name']
  const lnameErrorMsg = ['Last Name is required', 'Invalid Last Name']

  const monthErrorMsg = ['Month is required']
  const dateErrorMsg = ['Day is required']
  const yearErrorMsg = ['Year is required']
  const genderErrorMsg = ['Gender is required']
  const prferredpronounErrorMsg = ['Preferred Pronoun is required']
  const phoneErrorMsg = ['Phone is required', 'Invalid Number']
  const phonetypeErrorMsg = ['Phone Type is required']
  const addressErrorMsg = ['Address is required']
  const cityErrorMsg = ['City is required']
  const stateErrorMsg = ['State is required']
  const zipErrorMsg = ['Zipcode is required']
  const monthList = Global.getCurrentYear()
  const yearList = Global.getYearList()

  const [errors, setErrors] = useState({
    eemail: false,
    efname: false,
    emname: false,
    elname: false,
    emonth: false,
    edate: false,
    eyear: false,
    egender: false,
    eprferredpronoun: false,
    ephone: false,
    ephonetype: false,
    eaddress: false,
    ecity: false,
    estate: false,
    ezipcode: false,
  })

  const [userData, setUserData] = useState({
    email: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: emailErrorMsg,
      errorKey: 'eemail',
    },
    fname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: fnameErrorMsg,
      errorKey: 'efname',
    },
    mname: {
      value: '',
      validation: ['name'],
      errorMsg: mnameErrorMsg,
      errorKey: 'emname',
    },
    lname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: lnameErrorMsg,
      errorKey: 'elname',
    },
    month: {
      value: '',
      validation: ['blank'],
      errorMsg: monthErrorMsg,
      errorKey: 'emonth',
    },
    year: {
      value: '',
      validation: ['blank'],
      errorMsg: yearErrorMsg,
      errorKey: 'eyear',
    },
    date: {
      value: '',
      validation: ['blank'],
      errorMsg: dateErrorMsg,
      errorKey: 'edate',
    },
    gender: {
      value: '',
      validation: ['blank'],
      errorMsg: genderErrorMsg,
      errorKey: 'egender',
    },
    prferredpronoun: {
      value: '',
      validation: ['blank'],
      errorMsg: prferredpronounErrorMsg,
      errorKey: 'eprferredpronoun',
    },
    phone: {
      value: '',
      validation: ['blank', 'indianmobile'],
      errorMsg: phoneErrorMsg,
      errorKey: 'ephone',
    },
    phonetype: {
      value: 'cell',
      validation: ['blank'],
      errorMsg: phonetypeErrorMsg,
      errorKey: 'ephonetype',
    },
    address: {
      value: '',
      validation: [],
      errorMsg: addressErrorMsg,
      errorKey: 'eaddress',
    },
    city: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: cityErrorMsg,
      errorKey: 'ecity',
    },
    state: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: stateErrorMsg,
      errorKey: 'estate',
    },
    zipcode: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: zipErrorMsg,
      errorKey: 'ezipcode',
    },
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const formDataSubmit = async () => {
    const data = {
      email: userData.email.value.trim(),
      password: randompassword(8),
      firstName: userData.fname.value.trim(),
      middleName: userData.mname.value.trim(),
      lastName: userData.lname.value.trim(),
      dob:
        userData.month.value.toString().padStart(2, '0') +
        '-' +
        userData.date.value.toString().padStart(2, '0') +
        '-' +
        userData.year.value,
      gender: userData.gender.value.trim(),
      preferredPronoun: userData.prferredpronoun.value.trim(),
      phoneNumber: userData.phone.value.trim(),
      phoneType: userData.phonetype.value.trim(),
      address: userData.address.value.trim(),
      cityId: userData.city.value,
      stateId: userData.state.value,
      zipcode: userData.zipcode.value.trim(),
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/createPatient',
      body: JSON.stringify(data),
    }

    authContext.setLoader()

    try {
      let result = await NetworkLayer.postData(submitData)

      if (result.body.status) {
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
        setTimeout(() => {
          props.setSuccessResponse(result.body)
          props.changeTab()
          //history.push(AdminLinks.admin_patient_management);
        }, 4000)
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    } catch (err) {
      setmodalProps({
        type: 'error',
        msg: 'Something Went Wrong',
      })
      authContext.showResponseModal()
    }
    authContext.unsetLoader()
  }
  const formSubmit = () => {
    let errorFilds = []
    let cdate = new Date()
    let todayDate =
      cdate.getFullYear() +
      '-' +
      (parseInt(cdate.getMonth()) + 1).toString().padStart(2, '0') +
      '-' +
      cdate.getDate().toString().padStart(2, '0') +
      ' 00:00'
    let currentDate = new Date(todayDate).getTime()

    const validationResult = InputValidation(userData)
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })

      setTimeout(() => {
        errorFilds = document.querySelectorAll('.error-border')
        if (errorFilds.length) {
          errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    } else {
      let userDob1 =
        userData.year.value +
        '-' +
        userData.month.value.toString().padStart(2, '0') +
        '-' +
        userData.date.value.toString().padStart(2, '0') +
        ' 00:00'
      let userDob = new Date(userDob1).getTime()
      if (currentDate < userDob) {
        setErrors({ ...errors, ...{ eyear: 'Invalid Date of Birth' } })
        setTimeout(() => {
          errorFilds = document.querySelectorAll('.error-border')
          if (errorFilds.length) {
            errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
          }
        }, 100)
        return false
      }
      formDataSubmit()
    }
  }

  const modifyPracticeData = async (e) => {
    let index,
      name,
      value,
      tempData = []

    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value

    tempData = userData
    tempData[name]['value'] = value
    tempData[name][name] = ''
    if (name === 'city') {
      tempData[name]['value'] = value
      tempData['zipcode']['value'] = ''

      setZipCodes(
        JSON.parse(e.target.selectedOptions[0].getAttribute('data-zipcode'))
      )
    } else if (name === 'state') {
      tempData['city']['value'] = ''
      tempData['zipcode']['value'] = ''
      tempData[name]['value'] = value
      if (value != '') {
        authContext.setLoader()
        let cityResponse = await Global.getCities(value)
        authContext.unsetLoader()

        setfilterCities(cityResponse)
      } else {
        setfilterCities(cities)
      }
    }
    setUserData({ ...tempData })

    // formSubmit();
  }
  const getState_and_getCity = async () => {
    let stateResponse = await Global.getStates()
    setStates(stateResponse)
    let cityResponse = await Global.getCities()
    setCities(cityResponse)
    setfilterCities(cityResponse)
  }

  useEffect(() => {
    getState_and_getCity()
  }, [])

  return (
    <Fragment>
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      <Row className="table-sort-filter">
        <div className="col-12 col-lg-9">
          <form className="provider-form-edit">
            <Row className="row">
              <div className="col-md-4 mb-3">
                <label className="pro-label">Email</label>
                <input
                  type="text"
                  name="email"
                  className={
                    errors.eemail ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="Email"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ eemail: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ eemail: false } })}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        email: {
                          value: e.currentTarget.value,
                          validation: ['blank', 'email'],
                          errorMsg: emailErrorMsg,
                          errorKey: 'eemail',
                        },
                      },
                    })
                  }
                  value={userData.email.value}
                />
                <small className="error-input">{errors.eemail}</small>
              </div>
            </Row>
            <Row className="row">
              <div className="col-md-4 mb-3">
                <label className="pro-label">First Name</label>
                <input
                  type="text"
                  name="fName"
                  className={
                    errors.efname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="First Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ efname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ efname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          fname: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'name'],
                            errorMsg: fnameErrorMsg,
                            errorKey: 'efname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.fname.value}
                />
                <small className="error-input">{errors.efname}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">Middle Name</label>
                <input
                  type="text"
                  name="mName"
                  className={
                    errors.emname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="Middle Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ emname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ emname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          mname: {
                            value: e.currentTarget.value,
                            validation: ['name'],
                            errorMsg: mnameErrorMsg,
                            errorKey: 'emname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.mname.value}
                />
                <small className="error-input">{errors.emname}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">Last Name</label>
                <input
                  type="text"
                  name="lName"
                  className={
                    errors.elname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="Last Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ elname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ elname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          lname: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'name'],
                            errorMsg: lnameErrorMsg,
                            errorKey: 'elname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.lname.value}
                />
                <small className="error-input">{errors.elname}</small>
              </div>
            </Row>

            <Row className="row">
              <div className="col-12">
                <label className="pro-label">Date Of Birth</label>
              </div>
              <div className="col-md-4 mb-3 select-option">
                <div className="myform">
                  <select
                    required
                    className={
                      errors.emonth ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ emonth: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ emonth: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          month: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: monthErrorMsg,
                            errorKey: 'emonth',
                          },
                        },
                      })
                    }
                    value={userData.month.value}
                  >
                    <option value="">Month</option>
                    {monthList.map((month, index_index) => (
                      <option key={month.id + '-month'} value={month.id}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.emonth}</small>
              </div>
              <div className="col-md-4 mb-3 select-option">
                <div className="myform">
                  <select
                    required
                    className={errors.edate ? 'select  error-border' : 'select'}
                    onClick={() =>
                      setErrors({ ...errors, ...{ edate: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ edate: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          date: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: dateErrorMsg,
                            errorKey: 'edate',
                          },
                        },
                      })
                    }
                    value={userData.date.value}
                  >
                    <option value="">Day</option>
                    {[...new Array(31)].map((day, day_index) => (
                      <option
                        key={1 + day_index + '-days'}
                        value={day_index + 1}
                      >
                        {day_index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.edate}</small>
              </div>
              <div className="col-md-4 mb-3 select-option">
                <div className="myform">
                  <select
                    required
                    className={
                      errors.emonth ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ eyear: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ eyear: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          year: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: yearErrorMsg,
                            errorKey: 'eyear',
                          },
                        },
                      })
                    }
                    value={userData.year.value}
                  >
                    <option value="">Year</option>
                    {yearList.map((year) => (
                      <option key={year + '-year'} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.eyear}</small>
              </div>
            </Row>

            <Row>
              <div className="col-12 mb-3">
                <label className="pro-label">Sex</label>
                <label className="radio-button mt-2 mr-2" htmlFor="male">
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    checked={userData.gender.value == 'male' ? true : false}
                    onClick={() =>
                      setErrors({ ...errors, ...{ egender: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ egender: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          gender: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: fnameErrorMsg,
                            errorKey: 'egender',
                          },
                        },
                      })
                    }
                    value={'male'}
                  />
                  <span className="radio-check"></span>
                  Male
                </label>
                <label className="radio-button mt-2 mr-2" htmlFor="female">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    checked={userData.gender.value == 'female' ? true : false}
                    onClick={() =>
                      setErrors({ ...errors, ...{ egender: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ egender: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          gender: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: fnameErrorMsg,
                            errorKey: 'egender',
                          },
                        },
                      })
                    }
                    value={'female'}
                  />
                  <span className="radio-check"></span>
                  Female
                </label>
              </div>
            </Row>
            <small className="error-input">{errors.egender}</small>

            <Row>
              <div className="col-12 mb-3">
                <label className="pro-label">Preferred Pronoun</label>
                <label className="radio-button mt-2 mr-2" htmlFor="hhh">
                  <input
                    type="radio"
                    id="hhh"
                    name="prePronoun"
                    checked={
                      userData.prferredpronoun.value == 'he/him/his'
                        ? true
                        : false
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          prferredpronoun: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: prferredpronounErrorMsg,
                            errorKey: 'eprferredpronoun',
                          },
                        },
                      })
                    }
                    value={'he/him/his'}
                  />
                  <span className="radio-check"></span>
                  he/him/his
                </label>
                <label className="radio-button mt-2 mr-2" htmlFor="shh">
                  <input
                    type="radio"
                    id="shh"
                    name="prePronoun"
                    checked={
                      userData.prferredpronoun.value == 'she/her/hers'
                        ? true
                        : false
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          prferredpronoun: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: prferredpronounErrorMsg,
                            errorKey: 'eprferredpronoun',
                          },
                        },
                      })
                    }
                    value={'she/her/hers'}
                  />
                  <span className="radio-check"></span>
                  she/her/hers
                </label>
                <label className="radio-button mt-2 mr-2" htmlFor="ttt">
                  <input
                    type="radio"
                    id="ttt"
                    name="prePronoun"
                    checked={
                      userData.prferredpronoun.value == 'they/them/theirs'
                        ? true
                        : false
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ eprferredpronoun: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          prferredpronoun: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: prferredpronounErrorMsg,
                            errorKey: 'eprferredpronoun',
                          },
                        },
                      })
                    }
                    value={'they/them/theirs'}
                  />
                  <span className="radio-check"></span>
                  they/them/theirs
                </label>
              </div>
            </Row>
            <small className="error-input">{errors.eprferredpronoun}</small>

            <Row>
              <div className="col-md-7 mb-3">
                <label className="pro-label">Phone Number</label>
                <input
                  type="text"
                  name="pNumber"
                  className="input-pro"
                  placeholder="(###) ###-####"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ ephone: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ ephone: false } })}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        phone: {
                          value: Global.phoneFormator(e.currentTarget.value),
                          validation: ['blank', 'indianmobile'],
                          errorMsg: phoneErrorMsg,
                          errorKey: 'ephone',
                        },
                      },
                    })
                  }
                  value={userData.phone.value}
                />
                <small className="error-input">{errors.ephone}</small>
              </div>
              <div className="col-md-5 mb-3 select-option">
                <label className="pro-label">Phone Type</label>
                <select
                  required
                  className={
                    errors.ephonetype ? 'select  error-border' : 'select'
                  }
                  onClick={() =>
                    setErrors({ ...errors, ...{ ephonetype: false } })
                  }
                  onFocus={() =>
                    setErrors({ ...errors, ...{ ephonetype: false } })
                  }
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        phonetype: {
                          value: e.currentTarget.value,
                          validation: ['blank'],
                          errorMsg: phonetypeErrorMsg,
                          errorKey: 'ephonetype',
                        },
                      },
                    })
                  }
                  value={userData.phonetype.value}
                >
                  <option value="cell">Cell</option>
                  <option value="home">Home</option>
                </select>
                <i className="select-option-icon">
                  <SelectOptionIcon />
                </i>
              </div>
            </Row>

            <Row>
              <div className="col-12 mb-3">
                <label className="pro-label">Address (Optional)</label>
                <textarea
                  maxLength={500}
                  name="address"
                  rows=""
                  className="input-pro textarea-ca"
                  placeholder="Enter Address"
                  onClick={() =>
                    setErrors({ ...errors, ...{ eaddress: false } })
                  }
                  onFocus={() =>
                    setErrors({ ...errors, ...{ eaddress: false } })
                  }
                  defaultValue={userData.address.value}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        address: {
                          value: e.currentTarget.value,
                          validation: [],
                          errorMsg: addressErrorMsg,
                          errorKey: 'eaddress',
                        },
                      },
                    })
                  }
                ></textarea>
                <small style={{ color: '#979797' }}>
                  {'500 Characters Maximum'}
                </small>
              </div>
            </Row>

            <Row>
              <div className="col-md-4 mb-3">
                <label className="pro-label">State</label>
                <select
                  required
                  className={errors.estate ? 'select  error-border' : 'select'}
                  onClick={() => setErrors({ ...errors, ...{ estate: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ estate: false } })}
                  data-name="state"
                  onChange={(e) => {
                    modifyPracticeData(e)
                  }}
                  value={userData.state.value}
                >
                  <option value="">Select State</option>
                  {states.map((state, state_index) => (
                    <option key={state.id + '-state'} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <small className="error-input">{errors.estate}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">City</label>
                <select
                  required
                  className={errors.ecity ? 'select  error-border' : 'select'}
                  onClick={() => setErrors({ ...errors, ...{ ecity: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ ecity: false } })}
                  data-name="city"
                  onChange={(e) => {
                    modifyPracticeData(e)
                  }}
                  value={userData.city.value}
                >
                  <option value="">Select City</option>
                  {filterCities.citylist
                    ? Object.keys(filterCities.citylist).map((key) => (
                        <option
                          key={filterCities.citylist[key][0].id + '-city'}
                          data-zipcode={JSON.stringify(
                            filterCities.citylist[key]
                          )}
                          value={filterCities.citylist[key][0].id}
                        >
                          {key}
                        </option>
                      ))
                    : ''}
                </select>
                <small className="error-input">{errors.ecity}</small>
              </div>

              <div className="col-md-4 mb-3">
                <label className="pro-label">Zip Code</label>
                <div className="myform">
                  <select
                    className={'select'}
                    onClick={() =>
                      setErrors({ ...errors, ...{ ezipcode: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ ezipcode: false } })
                    }
                    value={userData.zipcode.value}
                    data-name="zipcode"
                    onChange={(e) => {
                      modifyPracticeData(e)
                    }}
                  >
                    <option value="">Select Zip Code</option>
                    {zipCodes.map((zip, zipIndex) => (
                      <option
                        key={zip.id + '-zip-' + zipIndex}
                        data-city={zip.id}
                        value={zip.zip_code}
                      >
                        {zip.zip_code}
                      </option>
                    ))}
                  </select>
                  <small className="error-input">{errors.ezipcode}</small>
                </div>
              </div>

              <div className="col-12 bottom-btns mt-3">
                <div className="next-prev-btn-pro">
                  <input
                    type="button"
                    className="prev-btn-pro"
                    value="Cancel"
                    onClick={() =>
                      history.push({
                        pathname: AdminLinks.admin_patient_management,
                      })
                    }
                  />
                  <input
                    type="button"
                    className="next-btn-pro"
                    value="Save"
                    onClick={() => {
                      formSubmit()
                    }}
                  />
                </div>
              </div>
            </Row>
          </form>
        </div>
      </Row>
    </Fragment>
  )
}

export default AddAccountInfo
