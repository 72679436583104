import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as SearchIcon } from '../../../admin/assets/images/search.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../../reactLayer/Network/Network'

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}
let pageNumber = 1
let searchText = ''
const HistoryNotification = () => {
  const authContext = useContext(AuthContext)
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [data, setData] = useState([])
  const [filtervalue, setFiltervalue] = useState()
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const getList = async () => {
    authContext.setLoader()
    // FORM DATA

    let url = 'admin/notifications/notificationHistory?page=' + pageNumber
    if (searchText) {
      url += '&search=' + searchText
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
    }

    // SEND REQUEST
    try {
      let response = await NetworkLayer.getRequest(submitData)

      setData(response.result.notificationData.rows)
      settotalCount(response.result.notificationData.count)
    } catch (err) {
      console.log('ERROR :', err)
    }

    authContext.unsetLoader()
  }

  useEffect(() => {
    getList()
  }, [filter.offset])

  // PAGINATION CALLBACK
  const changeLimit = (page, pageno) => {
    pageNumber = parseInt(pageno) + 1
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  const searchFilter = (value) => {
    pageNumber = 1
    searchText = value
    setfilter({ ...filter, ...{ offset: parseInt(0) } })
    getList()
  }

  const debounceGet = useRef(
    debounce((nextValue) => searchFilter(nextValue), 1000)
  ).current

  const onInputChange = (e) => {
    e.preventDefault()
    setFiltervalue(e.target.value)
  }

  useEffect(() => {
    if (filtervalue == '') {
      searchFilter('')
    } else {
      if (filtervalue && filtervalue.length > 2) {
        debounceGet(filtervalue)
      }
    }
  }, [filtervalue])

  const deleteRecord = async (id) => {
    return
  }

  const resendNotification = async (id) => {
    // FORM DATA
    const formData = {
      id: id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/notifications/rensendNotifications/' + id,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status === true) {
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    } catch (err) {
      console.log(err)
    }
    setTimeout(() => {
      authContext.unsetLoader()
    }, 1000)
  }

  return (
    <Fragment>
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      <div className="notification-wrap-admin">
        <Row className="mt-2 mb-3 mb-md-4">
          <div className="col-12">
            <div className="table-sort-filter">
              <span className="filter-input">
                <input
                  type="text"
                  className="input-pro"
                  name="search"
                  id="search"
                  placeholder="Search"
                  autoComplete="off"
                  onChange={(e) => onInputChange(e)}
                />
                <i className="search-icon">
                  <SearchIcon />
                </i>
              </span>
            </div>
          </div>
        </Row>
        {/* Bottom Table section start here */}
        <div className="notification-users border-0">
          <div className="table-report-scroll table-notihistory mt-0">
            <table className="table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Sent Date</th>
                  <th>Sent To</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length == 0 && (
                  <tr>
                    <td> No Records Found </td>
                  </tr>
                )}
                {data &&
                  data.map((value, index) => (
                    <tr key={index}>
                      <td>
                        <div className="noti-history-title">{value.title}</div>
                      </td>
                      <td>
                        <div className="noti-history-descr">
                          {value.message}
                        </div>
                      </td>
                      <td>{value.send_date}</td>
                      <td>
                        {value.userType === 1 ? 'All Users' : ''}
                        {value.userType === 2 ? (
                          <span>
                            Selected
                            <br />
                            Users
                          </span>
                        ) : (
                          ''
                        )}
                        {value.userType === 3 ? (
                          <span>
                            Provider
                            <br />
                            Only
                          </span>
                        ) : (
                          ''
                        )}
                        {value.userType === 4 ? (
                          <span>
                            Patients
                            <br />
                            Only
                          </span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {value.notificationType === 1 ? (
                          <span>
                            Push
                            <br />
                            Notification
                          </span>
                        ) : (
                          ''
                        )}
                        {value.notificationType === 2 ? (
                          <span>
                            Email
                            <br />
                            Notification
                          </span>
                        ) : (
                          ''
                        )}
                        {value.notificationType === 3 ? (
                          <span>
                            Push &amp;
                            <br />
                            Email
                            <br />
                            Notification
                          </span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        <Link
                          className="table-action view-all"
                          onClick={(e) => {
                            e.preventDefault()
                            resendNotification(value.id)
                          }}
                        >
                          Resend
                        </Link>
                        <Link
                          className="table-action view-all"
                          onClick={(e) => {
                            e.preventDefault()
                            deleteRecord(value.id)
                          }}
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="report-page mt-4">
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HistoryNotification
