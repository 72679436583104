import { useEffect, useState } from 'react'
import JotformEmbed from 'react-jotform-embed'
import { useLocation } from 'react-router-dom'

const JotForm = () => {
  const location = useLocation()
  const [params, setParams] = useState(undefined)

  useEffect(() => {
    setParams(location.search)
  }, [])
  return (
    <>
      {params ? (
        <JotformEmbed
          src={`https://form.jotform.com/233015750429149${params}`}
        />
      ) : (
        <JotformEmbed src={`https://form.jotform.com/233015750429149`} />
      )}
    </>
  )
}

export default JotForm
