import React, { Fragment, useState, useContext, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'

const EditLang = (props) => {
  const authContext = useContext(AuthContext)
  const [languageList, setLanguageList] = useState([])
  const selectedLang = props.language ? props.language : []
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const [otherData, setotherData] = useState({
    language: {
      value: selectedLang,
      validation: ['arrayempty'],
      errorMsg: ['This Field is required'],
      errorKey: 'language',
    },
  })
  const [errors, setErrors] = useState({
    language: false,
  })

  const addLangData = (e) => {
    let data,
      val,
      arrayIndex,
      name = e.currentTarget.getAttribute('data-name')
    val = e.currentTarget.value
    data = otherData[name]
    arrayIndex = data.value.indexOf(val)
    if (arrayIndex > '-1') {
      data.value.splice(arrayIndex, 1)
    } else {
      data.value.push(val)
    }
    setotherData({ ...otherData, ...{ [name]: data } })
  }

  useEffect(() => {
    async function getLanData() {
      let data = await Global.getLangList()
      if (data.length > 0) {
        setLanguageList(data)
      }
    }
    getLanData()
  }, [])

  const formSubmit = async () => {
    let flag = true
    let validationResultOtherData = InputValidation(otherData)
    if (Object.keys(validationResultOtherData).length > 0) {
      flag = false
      setErrors({ ...errors, ...validationResultOtherData })
    }

    if (flag) {
      authContext.setLoader()
      const fData = {
        languageSpoken: otherData.language.value,
      }

      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/languages/update',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      // console.log('language response', result);
      if (result.body.status) {
        props.getProfile &&
          typeof props.getProfile === 'function' &&
          props.getProfile()
        setmodalProps({
          type: 'success',
          msg: result.body.message,
        })
        authContext.showResponseModal()
        setTimeout(() => {
          props.setVisibleComponent && props.setVisibleComponent('')
        }, 3000)
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }
  return (
    <Fragment>
      <Container>
        <Row>
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        </Row>
        <div className="dashboard-modal-wrap">
          <Row>
            <div className="col-12 px-0">
              <div className="modal-head">Edit Languages</div>
            </div>
          </Row>
          <div className="add-edu-box">
            <Row>
              <div className="col-12">
                <div className="sub-head mb-4">Practice Languages</div>
                <div className="lang-opt-wrap">
                  {languageList.map((d, ind) => (
                    <div className="cus-check-rev" key={uuidv4()}>
                      <input
                        type="checkbox"
                        id={d.toLowerCase() + '-' + ind}
                        name="language"
                        data-name="language"
                        key={d.toLowerCase() + '-' + ind}
                        value={d.toLowerCase()}
                        onChange={(e) => addLangData(e)}
                        onClick={() => {
                          setErrors({ ...errors, ...{ language: false } })
                        }}
                        checked={
                          otherData.language.value.indexOf(d.toLowerCase()) >
                          '-1'
                            ? true
                            : false
                        }
                      />
                      <label htmlFor={d.toLowerCase() + '-' + ind}>{d}</label>
                    </div>
                  ))}
                  <small className="error-input">{errors.language}</small>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-12 text-center mt-3 mt-md-4">
                <input
                  type="button"
                  onClick={formSubmit}
                  className="next-btn-pro"
                  value="Save"
                />
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default EditLang
