import 'bootstrap/dist/css/bootstrap.css'
import React, { memo, useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { useWindowSize } from '../../hooks/useWindowSize'
import Global from '../../reactLayer/global'

const SchedulingDate = (props) => {
  const size = useWindowSize()
  const { startDate, setStartDate } = props
  const [listOfDates, setListOfDates] = useState([])
  const [prevSel, setprevSel] = useState(false)
  const [nextSel, setnextSel] = useState(true)
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const monthList = Global.getCurrentYear()

  const timeFrom = (sdate, x = 5) => {
    let dates = []
    for (let I = 0; I < x; I++) {
      let addeddate
      if (I === 0) {
        addeddate = new Date(sdate.setDate(sdate.getDate() + 0))
      } else {
        addeddate = new Date(sdate.setDate(sdate.getDate() + 1))
      }

      // console.log('timeFrom ===>', sdate);
      let day = days[addeddate.getDay()]
      let month = monthList[addeddate.getMonth()].name.substring(0, 3)
      let monthDate = month + ' ' + addeddate.getDate()
      dates.push({ day: day, monthDate: monthDate })
    }
    // console.log('timeFrom ===>', dates);
    return dates
  }

  const dateSelect = (type = false) => {
    // console.log('dateSelect==============>',startDate);
    let cdate
    if (type) {
      if (type == 'prev') {
        cdate = Global.addDays(startDate, size.width > 1220 ? -5 : -4)
      } else {
        cdate = Global.addDays(startDate, size.width > 1220 ? 5 : 4)
        // console.log('cdate=====>', cdate);
      }
      setStartDate(cdate)
    }
  }
  useEffect(() => {
    if (startDate) {
      // alert( startDate );
      // console.log('under useeffect startDate', startDate);
      setListOfDates(
        timeFrom(
          new Date(startDate.setHours(0, 0, 0, 0)),
          size.width > 1220 ? 5 : 4
        )
      )
      let sdate = startDate.setHours(0, 0, 0, 0)
      let ldate = Global.addDays(null, 45).setHours(0, 0, 0, 0)
      let cdate = new Date().getTime()
      // console.log('under useeffect sdate', sdate,cdate);

      if (sdate > cdate) {
        setprevSel(true)
      } else {
        setprevSel(false)
      }

      if (sdate < ldate) {
        setnextSel(true)
      } else {
        setnextSel(false)
      }
    }
  }, [startDate, size.width])

  return (
    <div className="scheduling-component-main scheduling-date-doctor-search">
      <div className="date-area-months">
        {prevSel ? (
          <>
            <img
              onClick={() => dateSelect('prev')}
              className="left-arrow"
              src="/assets/images/left-arrow-icon.png"
              alt="Left-arrow"
            />
          </>
        ) : (
          <></>
        )}
        {nextSel ? (
          <>
            <img
              onClick={() => dateSelect('next')}
              className="right-arrow"
              src="/assets/images/right-arrow-icon.png"
              alt="Time Down"
            />
          </>
        ) : (
          <></>
        )}

        {listOfDates.map((d, l_index) => (
          <div className="date-column" key={l_index}>
            <div className="date-heading">
              <span>{d.day}</span>
              <span>{d.monthDate}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

SchedulingDate.defaultProps = {
  startDate: '',
}

export default memo(SchedulingDate)
