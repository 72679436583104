import 'bootstrap/dist/css/bootstrap.css'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Notifyregister from '../../components/provider-login/Notifyregistersuccess'
import Login from '../../components/provider-login/provider-login'
import ServiceComingSoon from '../../components/provider-login/service-area-coming-soon'
import Twostep from '../../components/provider-login/twostep'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'
import StorageAdaptation from '../../reactLayer/storage/storage'
import Custommodal from '../modal/modal'
import { BurgerMenu } from './burger-menu'
import SearchMenuModal from './search-menu-modal'

const HomeHeader = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const [showCookieConcent, setshowCookieConcent] = useState(false)
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [tokenExpire, settokenExpire] = useState(null)

  const modalList = [
    'login',
    'twostep',
    'service-comming-soon',
    'notify-register',
  ]

  const openLoginModal = (e, modalName) => {
    // authContext.hideModal()
    e && e.preventDefault()
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const closeModals = () => {
    authContext.showLoginModal(false)
    settabvisibleComponent('')
  }

  useEffect(() => {
    if (showCookieConcent === false) {
      setTimeout(() => {
        setshowCookieConcent(true)
      }, 1000)
    }

    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.modalName
    ) {
      openLoginModal('', props.location.state.modalName)
    } else if (authContext.loginModal && authContext.loginModal.modalName) {
      openLoginModal('', authContext.loginModal.modalName)
    }

    if (
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') &&
      StorageAdaptation.getLocalStorage('tokenExpire', false, 'session') ===
        'patient' &&
      !StorageAdaptation.getLocalStorage('token', false, 'local')
    ) {
      settokenExpire(true)
      openLoginModal('', 'login')
      StorageAdaptation.removeLocalStorage('tokenExpire', 'session')
    }

    return () => {
      props = {}
      settabvisibleComponent('')
    }
  }, [authContext.loginModal])

  return (
    <Fragment>
      <SearchMenuModal isOpen={isOpen} setIsOpen={setIsOpen} />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'login' ? (
              <Login
                ltype={'patient'}
                closeModals={closeModals}
                settabvisibleComponent={settabvisibleComponent}
                tokenExpire={tokenExpire}
              />
            ) : tabvisibleComponent === 'twostep' ? (
              <Twostep
                ltype={'patient'}
                {...props}
                settabvisibleComponent={settabvisibleComponent}
              />
            ) : tabvisibleComponent === 'service-comming-soon' ? (
              <ServiceComingSoon ltype={'patient'} />
            ) : tabvisibleComponent === 'notify-register' ? (
              <Notifyregister ltype={'patient'} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <header>
        {/* Home page header     */}

        {!authContext.cookie_concent ? (
          <>
            <div
              className={
                showCookieConcent
                  ? 'cookie-concent-wrapper'
                  : 'cookie-concent-wrapper hidden'
              }
            >
              <p>
                By continuing to use this website, you are agreeing to
                DocScheduler's <strong>Privacy Policy and Terms of use.</strong>
              </p>
              <p>
                <strong>Important notice regarding use of cookies:</strong> We
                have updated our Privacy Policy to reflect our use of cookies to
                collect and process data. By continuing to use this website, you
                agree to our use of cookies and similar technologies as
                described in our Privacy Policy.{' '}
                <strong>Privacy Policy.</strong> More about our use of cookies{' '}
              </p>
              <div
                onClick={() => {
                  authContext.setCookieConent(true)
                }}
                className="next-prev-btn-pro"
              >
                <a
                  className="next-btn-pro full-width"
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                >
                  Accept and hide this message
                </a>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <Container style={{ maxWidth: '1440px' }}>
          <Row>
            <div className="col-12">
              <div className="header-inner-before-login">
                <Link className="header-logo" to="/">
                  <div className="logo-main">
                    <img
                      src="/assets/images/logo-icon.svg"
                      className="logo-icon"
                      alt="DocScheduler"
                    />
                    <span className="logo-text">DocScheduler</span>
                  </div>
                </Link>
                <div
                  className="header-input"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className="first-span">Find:</span>
                  <span className="second-span">Primary Care</span>
                </div>

                {/* mobile menu */}
                <BurgerMenu
                  mobileMenuVisible={props.mobileMenuVisible}
                  setMobileMenuVisible={props.setMobileMenuVisible}
                />
                <div className="header-right--wrapper">
                  <div className="create-acc-block">
                    <a href="/#" onClick={(e) => e.preventDefault()}>
                      Create Account
                    </a>
                    <div className="home-menu">
                      <div className="home-menu-inner">
                        <Link to={PatientLinks.register_step1}>
                          <div className="home-menu-links">
                            <span>Patients</span>
                            <span>
                              <a href="/#">Create Account</a>
                            </span>
                          </div>
                        </Link>
                        <Link to={ProviderLinks.freeprofile}>
                          <div className="home-menu-links no-border">
                            <span>Doctors</span>
                            <span>
                              <a href="/#">List Your Practice</a>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="sign-in-block">
                    <a
                      className="sign-in-href"
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                    >
                      Sign in
                    </a>
                    <div className="home-menu">
                      <div className="home-menu-inner">
                        <Link to="/">
                          <div className="home-menu-links">
                            <span>Patients</span>
                            <span>
                              <a
                                href="/#"
                                onClick={(e) => {
                                  window.localStorage.setItem('role', 4)
                                  window.localStorage.removeItem('subRole')
                                  openLoginModal(e, 'login')
                                }}
                              >
                                Sign in
                              </a>
                            </span>
                          </div>
                        </Link>
                        <Link to={ProviderLinks.login}>
                          <div className="home-menu-links">
                            <span>Doctors</span>
                            <span>
                              <a
                                onClick={() => {
                                  window.localStorage.setItem('role', 3)
                                  window.localStorage.removeItem('subRole')
                                }}
                                href="/#"
                              >
                                Sign in
                              </a>
                            </span>
                          </div>
                        </Link>
                        <Link to={ProviderLinks.login}>
                          <div className="home-menu-links">
                            <span>Team Members</span>
                            <span>
                              <a
                                onClick={() => {
                                  window.localStorage.setItem('role', 3)
                                  window.localStorage.setItem('subRole', 3)
                                }}
                                href="/#"
                              >
                                Sign in
                              </a>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </header>
    </Fragment>
  )
}

export default HomeHeader
