import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Loadermodal from '../../global/loader-modal/loader-modal'
import { AdminLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'

const VerifyAdmin = (props) => {
  let admin_verify_token = props.match.params.token
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [data, setData] = useState([])

  const verifyAdmin = async () => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': admin_verify_token,
      },
      url: 'admin/verify/',
    }

    // SEND REQUEST
    try {
      let result = await NetworkLayer.getRequest(submitData)
      setData(result)
    } catch (err) {
      console.log(err)
    }

    authContext.unsetLoader()
  }

  useEffect(() => {
    verifyAdmin()
  }, [])
  return (
    <Fragment>
      {/* header */}
      <Loadermodal />

      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
              <div
                className="col-12 contactus no-border no-shadow"
                style={{ 'text-align': 'center' }}
              >
                <Link
                  onClick={() => {
                    history.push({ pathname: AdminLinks.admin_dashboard })
                  }}
                >
                  <img
                    src="/assets/images/doc-scheduler-logo.png"
                    alt="DocScheduler"
                  />
                </Link>

                <Row>
                  <div className="error-404 suc-msg">
                    <h3 className="italic error-msg-404">{data.message}</h3>
                  </div>
                </Row>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
    </Fragment>
  )
}

export default VerifyAdmin
