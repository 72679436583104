import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import NetworkLayer from '../../../reactLayer/Network/Network'
import { addDays, getYearMonthDate } from '../../lib/utils'
import { Box, Flex } from '@chakra-ui/react'
import { Slot } from './Slot'

interface Props {
  locationId: number
  startDate?: Date
  timeZone: string
  bookAppointment: (slot: any, item: any) => void
}

interface Slots {
  startTime: string
  isDisabled: boolean
  isBooked: boolean
  isRequested: boolean
}

interface Slot {
  SDate: any
  slots: Slots[]
}

export const Scheduler: React.FC<Props> = ({
  locationId,
  startDate,
  bookAppointment,
}) => {
  const [slots, setSlots] = useState<Slot[]>()
  const [showMoreSlots, setShowMoreSlots] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [maxSlots, setMaxSlots] = useState(0)
  const [timeZone, setTimeZone] = useState()
  const dateWrapper = useRef(null)

  const bookSlot = (slotInfo: any, index: number) => {
    if (!slotInfo.isDisabled && !slotInfo.isBooked) {
      let selectedDate
      // eslint-disable-next-line prefer-const
      selectedDate = getYearMonthDate(addDays(startDate, index))
      slotInfo.locationId = locationId
      slotInfo.disabledLocation = true
      slotInfo.date =
        selectedDate.year + '-' + selectedDate.month + '-' + selectedDate.date
      bookAppointment?.(slotInfo, {})
    }
  }

  const doctorSchedulingTime = async (locationId: number, startDate: Date) => {
    const data = {
      startFrom: moment(startDate).format('YYYY-MM-DD'),
      locationId: locationId,
    }
    const url = 'appointment/schedule'
    const submitData = {
      url: url,
      body: JSON.stringify(data),
    }

    const result = await NetworkLayer.postData(submitData)
    if (result.body.status) {
      let totalSlots = 0
      setTimeZone(result.body.result.location.timezone)
      if (result.body.result.slots) {
        result.body.result.slots.map((slot: { slots: string | any[] }) => {
          if (slot.slots.length > 0 && totalSlots < slot.slots.length) {
            totalSlots = slot.slots.length
          }
          return null
        })
      }
      setMaxSlots(totalSlots)
      setSlots(result.body.result.slots)
    } else {
      setSlots([])
    }
  }

  useEffect(() => {
    locationId && startDate && doctorSchedulingTime(locationId, startDate)
  }, [locationId, startDate])

  useMemo(() => {
    return () => {
      setShowMoreSlots(false)
    }
  }, [])

  const isTimeslotDisabled = (
    doctorTimezone: string,
    date: Date,
    timeslot?: string
  ) => {
    // Get the current date and time in the browser's local timezone
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const browserCurrentDateTime = new Date().toLocaleString('en-US', {
      timeZone: browserTimezone,
    })

    // Combine the date and timeslot into a single date object in the doctor's timezone
    const doctorDateTime = new Date(`${date} ${timeslot} ${doctorTimezone}`)

    // Convert the doctor's datetime to the browser's local timezone
    const browserDateTime = new Date(
      doctorDateTime.toLocaleString('en-US', { timeZone: browserTimezone })
    )

    // Calculate the time difference between the browser's current date and time and the doctor's timeslot
    // @ts-ignore
    const timeDifference = browserDateTime - new Date(browserCurrentDateTime)

    // Check if the timeslot is in the past or up to 2 hours in the future
    return timeDifference <= 0 || timeDifference <= 2 * 60 * 60 * 1000
  }

  return (
    <div>
      <Flex
        gap={2}
        ref={dateWrapper}
        height={showMoreSlots ? 'auto' : '230px'}
        overflow="hidden"
      >
        {slots?.map((slot, index) => (
          <Box width="20%" key={index}>
            {[...Array(maxSlots).keys()].map((x, i) => {
              const sl = slot.slots[i]
              if (sl) {
                return (
                  <Slot
                    onClick={() => bookSlot(sl, index)}
                    key={sl.startTime}
                    disabled={
                      sl.isDisabled ||
                      sl.isBooked ||
                      sl.isRequested ||
                      isTimeslotDisabled(sl.startTime, slot.SDate, timeZone)
                    }
                  >
                    <>{sl.startTime}</>
                  </Slot>
                )
              } else
                return (
                  <Slot disabled key={i}>
                    <strong>---</strong>
                  </Slot>
                )
            })}
          </Box>
        ))}
      </Flex>
      <Flex gap={2} mt={4}>
        {slots?.map((slot, index) => (
          <Slot
            key={index}
            onClick={() =>
              slot.slots.length > 2 ? setShowMoreSlots(!showMoreSlots) : null
            }
          >
            <Flex direction="column" gap={1} alignItems="center">
              <span>{showMoreSlots ? 'Less' : 'More'}</span>
              <Box
                borderLeft="10px solid transparent"
                borderRight="10px solid transparent"
                borderTop="10px solid white"
                width={0}
                height={0}
                transform={`rotate(${showMoreSlots ? '180deg' : '0deg'})`}
              ></Box>
            </Flex>
          </Slot>
        ))}
      </Flex>
    </div>
  )
}
