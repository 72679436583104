import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row, Col } from 'react-bootstrap'

const Dochowworks = () => {
  return (
    <Fragment>
      <Container>
        <Row className="mb-4">
          <div className="col-12">
            <div className="dochowwork">
              <Row>
                <div className="box">
                  <div className="rect">
                    <img src="/assets/images/free-pro-img1.png" />
                    <div className="title">Create a DocScheduler Account</div>
                  </div>
                  <div className="rect">
                    <img src="/assets/images/free-pro-img2.png" />
                    <div className="title">Add Scheduling Team Members</div>
                  </div>
                  <div className="rect">
                    <img src="/assets/images/free-pro-img3.png" />
                    <div className="title">
                      Receive Appointment Request via DocScheduler
                    </div>
                  </div>
                  <div className="rect">
                    <img src="/assets/images/free-pro-img4.png" />
                    <div className="title">
                      Doctor's Office Scheduling Team Reviews and Books
                      Appointment
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Dochowworks
