import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const PatientTabs = (props) => {
  const tabName = props.tabName
  const changeTab = (e, tabId) => {
    e.preventDefault()
    props.setvisibleComponent(tabId)
  }

  return (
    <Fragment>
      <div className="tabs-admin-outer">
        <ul className="edit-tab-list row mt-3 pl-3 pr-3">
          {tabName.map((data, index) => (
            <li
              key={data.id}
              className={props.visibleComponent == data.id ? 'active' : ''}
              onClick={(e) => {
                changeTab(e, data.id)
              }}
            >
              {data.name}{' '}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default PatientTabs
