import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'

const EditSpecialityModal = (props) => {
  const { getList, type, subtype, isdegree, isschool } = props
  const authContext = useContext(AuthContext)
  const [viewdata, setViewdata] = useState(props?.viewdata)
  const [name, setName] = useState(props?.viewdata?.name)
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const onChangeHandle = (e) => {
    setName(e.target.value)
  }

  const saveRecord = async () => {
    authContext.setLoader()

    let url = ''
    let formData = {}

    if (type == 'Speciality') {
      // Add Speciality
      url = 'admin/specialities/update'
      if (isdegree == 1) {
        // Add Medical OR Dental Degree
        formData = {
          id: props?.viewdata?.id,
          formType: 'degree',
          category: subtype.toLowerCase(),
          name: name,
        }
      }
      if (isdegree == 0) {
        // Add Medical OR Dental Speciality
        formData = {
          id: props?.viewdata?.id,
          formType: 'speciality',
          category: subtype.toLowerCase(),
          name: name,
        }
      }

      if (isschool == 1) {
        url = 'admin/schools/update'
        // Medical OR Dental Speciality
        formData = {
          id: props?.viewdata?.id,
          category: subtype.toLowerCase(),
          name: name,
          city: props?.viewdata?.city,
          state: props?.viewdata?.state,
        }
      }
    }

    if (type == 'Insurance') {
      url = 'admin/insurance/update'
      // Add Insurance
      formData = {
        id: props?.viewdata?.id,
        name: name,
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    setmodalProps({
      type: 'success',
      msg: 'Updated Successfully',
    })
    authContext.showResponseModal()
    getList()
  }

  return (
    <Fragment>
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      <Loadermodal />
      <Container>
        <div className="search-modal-wrap speciality-modal-wrap">
          <div className="edit-all-filter">
            <Row>
              <div className="col-12 px-0">
                <div className="modal-head">Edit {type} </div>
              </div>
            </Row>
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 mb-4">
                  <label className="pro-label mb-4">Edit {type}</label>
                  <input
                    type="text"
                    className="input-pro"
                    value={name}
                    onChange={(e) => onChangeHandle(e)}
                  />
                </div>
              </Row>
              <Row>
                <div className="col-12 mb-4 all-fltrbtn">
                  <input
                    type="button"
                    className="next-btn-pro"
                    value="Save"
                    onClick={saveRecord}
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default EditSpecialityModal
