import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'

import { Typography } from '../../../style/typography'
import { BOX_SHADOW } from '../../../style/reusables'
import { SuggestionsList } from './SuggestionsList'
import { InputDropdown } from './InputDropdown'
import { generateURLParams, statusOptions } from '../../../lib/utils'
import { useGetProviderSuggestions } from '../hooks/useGetProviderSuggestions'

interface Props {
  hasExtendedFindInput: string
  query: any
}

export const FindInput: React.FC<Props> = ({ hasExtendedFindInput, query }) => {
  const history = useHistory()
  const location = useLocation()

  const [findInputValue, setFindInputValue] = useState(query.find || '')
  const [findInputValueActive, setFindInputValueActive] = useState(false)
  const [isChildren, setIsChildren] = useState(0)

  const [status, setStatus] = useState(statusOptions.initial)

  const wrapperCateRef = useRef(null)

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    if (wrap && !wrap.contains(event.target)) {
      setStatus(statusOptions.initial)
    }
  }

  const providersSuggestions = useGetProviderSuggestions(
    findInputValue,
    setStatus
  )

  const updateFindInput = (value: string) => {
    setFindInputValue(value)
    setStatus(statusOptions.initial)
    history.replace({
      pathname: location.pathname,
      // @ts-ignore
      search: generateURLParams({ ...query, find: value }),
    })
    setFindInputValueActive(false)
  }

  const handleChange = (event) => {
    const nextValue = event?.target?.value
    setFindInputValue(nextValue)
    setFindInputValueActive(true)
  }

  const selectChildren = (e) => {
    if (e.currentTarget.checked) {
      setIsChildren(1)
    } else {
      setIsChildren(0)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Box position="relative">
      <InputGroup ref={wrapperCateRef}>
        <InputLeftElement pointerEvents="none">
          <Typography fontSize="15px" color="primary" fontWeight={700}>
            Find:
          </Typography>
        </InputLeftElement>
        <Input
          variant="search"
          textIndent="40px"
          type="text"
          name="find"
          placeholder="Condition, Specialty, Treatment, or Doctor Name"
          autoComplete="off"
          {...BOX_SHADOW}
          value={findInputValue}
          onChange={handleChange}
        />
      </InputGroup>
      {findInputValueActive && (
        <InputDropdown>
          <SuggestionsList
            providersSuggestions={providersSuggestions}
            status={status}
            onUpdate={(value) => updateFindInput(value)}
          />
        </InputDropdown>
      )}
      {hasExtendedFindInput && (
        <Flex>
          <Input
            type="checkbox"
            id="hipppa"
            name="docHippa"
            checked={!!isChildren}
            onChange={(e) => selectChildren(e)}
          />
          <label htmlFor="hipppa">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Select check box to search for children's doctors
          </label>
        </Flex>
      )}
    </Box>
  )
}
