import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as BackIcon } from '../../admin/assets/images/back_icon.svg'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import DentalInsurance from '../../components/admin/insurance-admin/dental-insurance'
import MedicalInsurance from '../../components/admin/insurance-admin/medical-insurance'
import PatientTabs from '../../components/admin/patient/patient-tabs'
import { AdminLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'

const InsuranceDetail = () => {
  const authContext = useContext(AuthContext)
  const [visibleComponent, setvisibleComponent] = useState('medicalIns')
  const tabName = [
    { id: 'medicalIns', name: 'Medical Insurance' },
    { id: 'dentalIns', name: 'Dental Insurance' },
  ]

  return (
    <Fragment>
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Row className="bg-white-report patient-details-outer">
            <div className="table-report">
              <div className="heading-wrap">
                <div className="table-heading">Insurances</div>
              </div>
              <div className="col-12 mt-3">
                <Link to={AdminLinks.admin_dashboard} className="back-link">
                  <span className="back-icon">
                    <BackIcon />
                  </span>
                  Back
                </Link>
              </div>
              <div className="insurance-tabs-outer">
                <PatientTabs
                  visibleComponent={visibleComponent}
                  setvisibleComponent={setvisibleComponent}
                  tabName={tabName}
                />
              </div>
              {visibleComponent === 'medicalIns' ? (
                <>
                  <MedicalInsurance />{' '}
                </>
              ) : visibleComponent === 'dentalIns' ? (
                <DentalInsurance />
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default InsuranceDetail
