import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import RequestAppointmentModal from '../doctor-search-result/req-appontment-modal'
import SchedulingDate from '../scheduling/scheduling-date'
import SchedulingTime from '../scheduling/scheduling-time'
import SchedulingTitle from '../scheduling/scheduling-title'
import {
  allowCallbackRequests,
  isAuthPatient,
  schedulingFeatureOrAppointment,
} from '../search-results/utils'

const ProviderRightProfile = (props) => {
  const authContext = useContext(AuthContext)
  const [startDate, setStartDate] = useState('')
  const [showModal, setShowModal] = useState(false)
  const browserAddress =
    props.addressInfo && props.addressInfo.formattedAddress
      ? props.addressInfo.formattedAddress
      : false
  const [bookSlot, setbookSlot] = useState(false)
  const [selectedItem, setselectedItem] = useState()
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const modalList = ['reqAppointment']
  const isGuest = authContext?.user === null
  const isPatient = authContext?.user?.accountInfo?.roleId === 4
  const allowOnline = props?.practiceInfo?.allowOnline === 1
  const allowVirtualVisit = props?.practiceInfo?.hasVirtualVisit === 1
  const allowCallbacks = props?.practiceInfo?.allowedPatient === 1
  const phoneNumber = props?.accountInfo?.phoneNumber
  const planId = props?.providerCurrentPlan?.planId

  const [slots, setslots] = useState([])
  const [locationId, setlocationId] = useState(null)
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
    rightSection: false,
  })

  useEffect(() => {
    setShowModal(true)
  }, [])
  const renderAppointment = (item) => (
    <div className="request-appoint">
      <input
        type="button"
        onClick={() =>
          isAuthPatient(authContext)
            ? openRequestModal('reqAppointment', false, item)
            : openLoginModal()
        }
        className="next-btn-pro"
        value="Request Appointment"
      />
    </div>
  )
  const renderSchedulingTime = () => (
    <>
      {showModal && (isPatient || isGuest) && (
        <div
          style={{
            position: 'fixed',
            zIndex: '1000',
            left: 0,
            top: 0,
            background: 'rgba(0,0,0, 0.4)',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              borderRadius: '4px',
              background: '#fff',
              width: '600px',
              height: '400px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Attention !</h2>
            <p>{`time slots are displayed in the ${props.practiceInfo.providerPracticeLocations[0].timeZone} time zone`}</p>
            <button
              style={{
                background: '#077db4',
                color: '#fff',
                border: '1px solid #fff',
                borderRadius: '4px',
                padding: '16px 40px',
              }}
              onClick={() => setShowModal(false)}
            >
              Ok
            </button>
          </div>
        </div>
      )}
      <div className="book-appointment-wrap">
        <h2>Book an appointment</h2>
        <div className="subhead-profile capitalize">
          with {props.accountInfo?.firstName} {props.accountInfo?.middleName}{' '}
          {props.accountInfo?.lastName}{' '}
          {props.medicalSpeciality?.providerMedicalCredientialsValue}
        </div>
        <div className="appointment-location">Appointment Location</div>
        <div className="location-select">
          <div className="myform">
            <select
              className="select"
              onChange={(e) => setlocationId(e.currentTarget.value)}
              value={locationId}
            >
              {props.practiceInfo?.providerPracticeLocations?.map(
                (location, index) => (
                  <option key={index} value={location.id}>
                    {location.practiceName} {location.practiceAddress}{' '}
                    {location.cities.name} {location.states.code}{' '}
                    {location.zipCode}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="calender-wrap">
        <SchedulingTitle
          startDate={startDate}
          setStartDate={setStartDate}
          dateSelect={dateSelect}
        />
        <SchedulingDate startDate={startDate} setStartDate={setStartDate} />
        <SchedulingTime
          timeZone={props.practiceInfo.providerPracticeLocations[0].timeZone}
          key={'locationId'}
          locationId={locationId}
          item={slots}
          startDate={startDate}
          bookAppointment={bookAppointment}
        />
      </div>
    </>
  )
  const openLoginModal = (e) => {
    e && e.preventDefault()
    authContext.showLoginModal({
      modalName: 'login',
      redirectUrl: PatientLinks.doctor_search_result,
    })
  }
  const openRequestModal = (modalName, slot = false, item = null) => {
    // authContext.hideModal()
    setselectedItem(item)
    setbookSlot(slot)
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const bookAppointment = (slot, item = null) => {
    if (isAuthPatient(authContext)) {
      openRequestModal('reqAppointment', slot, item)
    } else {
      openLoginModal()
    }
  }
  const doaskenDorseDoctor = async (drId, type = 'ask') => {
    authContext.setLoader()
    let data = { toUser: drId }
    let url = 'endorsement/ask'
    if (type === 'add') {
      url = 'endorsement/add'
    }
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    // authContext.unsetLoader();
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
        rightSection: true,
      })
      authContext.showResponseModal()
      props.getProfile && props.getProfile()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
        rightSection: true,
      })
      authContext.showResponseModal()
      props.getProfile && props.getProfile()
    }
  }
  const askenDorseDoctor = (e, drId) => {
    e && e.preventDefault()
    doaskenDorseDoctor(drId, 'ask')
  }

  const enDorseDoctor = (e, drId) => {
    e && e.preventDefault()
    doaskenDorseDoctor(drId, 'add')
  }
  const requstCallback = async (e, userId) => {
    e.preventDefault()
    if (props.isCallbackRequested) return
    authContext.setLoader()
    let data = { userId: userId }
    let url = 'patients/request-callback/submit'

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    // authContext.unsetLoader();
    authContext.unsetLoader()
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
        rightSection: true,
      })
      authContext.showResponseModal()
      props.getProfile && props.getProfile()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
        rightSection: true,
      })
      authContext.showResponseModal()
      props.getProfile && props.getProfile()
    }
  }
  const dateSelect = (value = false, event, type = false) => {
    let cdate = value
    if (type) {
      if (type == 'prev') {
        cdate = Global.addDays(startDate, -5)
      } else {
        cdate = Global.addDays(startDate, 5)
      }
    }
    setStartDate(cdate)
  }
  const getLocationBasedDetails = useCallback(async () => {
    authContext.setLoader()
    let startFrom, cDate
    if (startDate) {
      cDate = Global.getYearMonthDate(startDate)
    } else {
      cDate = Global.getYearMonthDate()
    }
    startFrom = cDate.year + '-' + cDate.month + '-' + cDate.startDate
    if (locationId) {
      let fData = { startFrom: startFrom, locationId: locationId }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'appointment/schedule',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        if (result.body.result.slots) {
          setslots([...result.body.result.slots])
        } else {
          setslots([])
        }
        authContext.unsetLoader()
      } else {
        setslots([])
        authContext.unsetLoader()
      }
    }
  }, [locationId, startDate])

  useEffect(() => {
    if (
      locationId === null &&
      props?.practiceInfo?.providerPracticeLocations?.length
    ) {
      setlocationId(
        props.practiceInfo.providerPracticeLocations[0].id.toString()
      )
    }
    props && setselectedItem({ ...props })
  }, [props])

  console.log(props)

  const suspenseLoader = () => <p></p>
  return (
    <Suspense fallback={suspenseLoader()}>
      <div className="pro-profile-box-style provider-detail-right-top">
        {modalList.indexOf(tabvisibleComponent) > '-1' ? (
          <Custommodal
            unsetComponent={settabvisibleComponent}
            componentName={
              tabvisibleComponent === 'reqAppointment' && selectedItem ? (
                <RequestAppointmentModal
                  {...props}
                  bookSlot={bookSlot}
                  browserAddress={browserAddress}
                  type={'profile'}
                />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <></>
        )}
        {authContext.responseModal && modalProps.rightSection ? (
          <Msgmodal {...modalProps} />
        ) : (
          <></>
        )}
        {isPatient || isGuest ? (
          schedulingFeatureOrAppointment(
            allowOnline,
            allowVirtualVisit,
            allowCallbacks,
            planId,
            () => renderAppointment(),
            () => renderSchedulingTime()
          )
        ) : (
          <div className="patient-features">
            <em>
              Patient features unavailable.
              <br />
              Please sign in as a patient if you would like to book an
              appointment.
            </em>
          </div>
        )}

        {isPatient || isGuest ? (
          <div className="endorse-link endorse-link-patient">
            {phoneNumber && (
              <a href={`tel:${phoneNumber}`}>
                {Global.phoneFormator(phoneNumber)}
              </a>
            )}
            {allowCallbackRequests(planId, allowCallbacks) && (
              <a
                href="/#"
                className="cursor-default"
                onClick={(e) => {
                  if (props.isCallbackRequested) e.preventDefault()
                  else if (authContext?.user?.isProfileCompleted)
                    requstCallback(e, props.accountInfo?.id)
                  else openLoginModal(e)
                }}
              >
                {!props.isCallbackRequested
                  ? 'Request Callback'
                  : 'Request Sent'}
              </a>
            )}
            <Link
              style={{
                backgroundColor: '#077db4',
                color: '#fff',
              }}
              to={{
                pathname: PatientLinks.jot_form,
                search: `?requestedProvider[first]=${props.accountInfo?.firstName}&requestedProvider[last]=${props.accountInfo?.lastName}`,
              }}
            >
              Text me to schedule
            </Link>
          </div>
        ) : (
          <div className="endorse-link">
            <button
              onClick={(e) => enDorseDoctor(e, props.accountInfo?.id)}
              disabled={props.isEndorsed}
            >
              <span>{props.isEndorsed ? 'Endorsed' : 'Endorse Doctor'}</span>
            </button>
            <button onClick={(e) => askenDorseDoctor(e, props.accountInfo?.id)}>
              Ask Doctor for Endorsement
            </button>
          </div>
        )}
      </div>
    </Suspense>
  )
}

export default ProviderRightProfile
