import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'

const AddSpecialityModal = (props) => {
  const { getRefreshList, type, subtype, isdegree, isschool } = props
  const authContext = useContext(AuthContext)
  const [name, setName] = useState('')
  const onChangeHandle = (e) => {
    setName(e.target.value)
  }
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  let title = ''

  if (type == 'Speciality') {
    if (isdegree == 1) {
      title = subtype + ' Degree'
    }
    if (isdegree == 0) {
      title = subtype + ' Speciality'
    }

    if (isschool == 1) {
      title = subtype + ' School'
    }
  }
  if (type == 'Insurance') {
    title = subtype + ' Insurance'
  }

  const saveRecord = async () => {
    authContext.setLoader()

    let url = ''
    let formData = {}

    if (type == 'Speciality') {
      // Add Speciality
      url = 'admin/specialities/add'
      if (isdegree == 1) {
        // Add Medical OR Dental Degree
        formData = {
          formType: 'degree',
          category: subtype.toLowerCase(),
          name: name,
        }
      }
      if (isdegree == 0) {
        // Add Medical OR Dental Speciality
        formData = {
          formType: 'speciality',
          category: subtype.toLowerCase(),
          name: name,
        }
      }

      if (isschool == 1) {
        url = 'admin/schools/add'
        // Add Medical OR Dental Speciality
        formData = {
          category: subtype.toLowerCase(),
          name: name,
          city: 1,
          state: 1,
        }
      }
    }

    if (type == 'Insurance') {
      url = 'admin/insurance/add'
      // Add Insurance
      formData = {
        category: subtype.toLowerCase(),
        name: name,
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: url,
      body: JSON.stringify(formData),
    }

    // console.log(url, formData);
    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)

    if (result?.body?.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }

    setTimeout(() => {
      authContext.unsetLoader()
      authContext.hideModal()
      getRefreshList()
    }, 2000)
  }

  return (
    <Fragment>
      {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      <Container>
        <div className="search-modal-wrap speciality-modal-wrap">
          <div className="edit-all-filter">
            <Row>
              <div className="col-12 px-0">
                <div className="modal-head">Add New {title}</div>
              </div>
            </Row>
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 mb-4">
                  <label className="pro-label mb-4">{title} Name</label>
                  <input
                    type="text"
                    className="input-pro"
                    placeholder={`Enter ${title} Name`}
                    value={name}
                    onChange={(e) => onChangeHandle(e)}
                  />
                </div>
              </Row>
              <Row>
                <div className="col-12 mb-4 all-fltrbtn">
                  <input
                    type="button"
                    className="next-btn-pro"
                    value="Save"
                    onClick={saveRecord}
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default AddSpecialityModal
