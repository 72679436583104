const getDaysForCurrentYear = () => {
  const today = new Date()
  const start = new Date(today.getFullYear(), 0, 0)
  const diff =
    today -
    start +
    (start.getTimezoneOffset() - today.getTimezoneOffset()) * 60 * 1000
  const oneDay = 1000 * 60 * 60 * 24
  return Math.floor(diff / oneDay)
}
export const timePeriod = {
  last30days: 30,
  last90days: 90,
  currentYear: getDaysForCurrentYear(),
  priorYear: -1,
}

export const timeValues = [
  { label: 'Last 30 days', value: timePeriod.last30days },
  { label: 'Last 90 days', value: timePeriod.last90days },
  { label: 'Current year', value: timePeriod.currentYear },
  { label: 'Prior year', value: timePeriod.priorYear },
]
