import React from 'react'
import { createRoot } from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import './index.css'
import App from './App'
import theme from './new/style/theme/theme'
import AuthState from './reactLayer/auth/AuthState'

const domNode = document.getElementById('root')
const root = createRoot(domNode)

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthState>
        <App />
      </AuthState>
    </ChakraProvider>
  </React.StrictMode>
)
