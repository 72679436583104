import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { timeValues } from './utils'

export const AppointmentRequestFilter = ({ period, getAllAppointments }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(period)

  const onSubmit = (e) => {
    e.preventDefault()
    getAllAppointments(selectedPeriod)
  }

  return (
    <Container>
      <div className="search-modal-wrap">
        <div className="edit-all-filter">
          <Row>
            <div className="col-12 px-0">
              <div className="modal-head">All Filters</div>
            </div>
          </Row>
          <div className="allfilter-innerbox">
            <Row>
              <form onSubmit={onSubmit}>
                <label className="pro-label">Time period</label>
                {timeValues.map((time) => (
                  <div className="cus-radio py-1 px-3" key={time.label}>
                    <input
                      type="radio"
                      id={time.label}
                      checked={time.value === selectedPeriod.value}
                      onChange={() => setSelectedPeriod(time)}
                    />
                    <label htmlFor={time.label}>{time.label}</label>
                  </div>
                ))}
                <input type="submit" className="next-btn-pro my-4" value="OK" />
              </form>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  )
}
