import { ReactComponent as ArrowIcon } from '../../admin/assets/images/down-arrow.svg'

const color = {
  link: '',
  subLink: 'blue',
}

export const Links = ({
  link,
  type,
  isActive,
  addNewQuestion,
  renderQuestions,
  onClick,
  SubLinks,
}) => {
  const handleClick = () => {
    onClick(type, link.value)
  }

  return (
    <div>
      <div
        className={`question ${color[type]} mb-3`}
        onClick={() => handleClick()}
      >
        {isActive && <div className="link-active"></div>}
        <div>{link?.description}</div>
        {SubLinks && (
          <div className={`arrow ${isActive ? 'active' : ''}`}>
            <ArrowIcon />
          </div>
        )}
      </div>
      {isActive && addNewQuestion}
      {SubLinks && isActive && <SubLinks />}
      {isActive && renderQuestions}
    </div>
  )
}
