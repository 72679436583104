import React, { Fragment, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import * as moment from 'moment'

const DigitalReputationInfoModal = (props) => {
  const { data } = props

  return (
    <Fragment>
      <Container>
        <div className="search-modal-wrap patient-info-modal">
          <div className="edit-all-filter">
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 mb-3 mb-md-4">
                  <div className="provider-wrap">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" colSpan="2">
                            Digital Reputation Detail
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" width="150">
                            Reviewed By :
                          </th>
                          <td>{`${data?.patientsInfo?.patientName?.firstName} ${data?.patientsInfo?.patientName?.lastName}`}</td>
                        </tr>
                        <tr>
                          <th scope="row" width="150">
                            Source :
                          </th>
                          <td>{data?.source}</td>
                        </tr>
                        <tr>
                          <th scope="row">Date & Time</th>
                          <td>
                            {moment(data.createdAt).format('D MMM Y, h:mA')}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" width="150">
                            Rating :
                          </th>
                          <td>
                            <span className="rating">
                              <span
                                className={`rating${parseFloat(data.rating)}`}
                              ></span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Comment</th>
                          <td>{data.message}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default DigitalReputationInfoModal
