import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'

const Notifyregistersuccess = (props) => {
  return (
    <Fragment>
      {/* Form start step 1 */}
      <Container className="">
        <Row className="provider-login-wrap twostep">
          <div className="suc-msg text-center">
            <div className="cus-check mb-4">
              <input
                type="checkbox"
                checked="true"
                disabled="true"
                id="children (pediatrics)-1"
                name="patientTreated"
                data-name="patientTreated"
                value=""
              />
              <label htmlFor="children (pediatrics)-1"></label>
            </div>
            <h3 className="">
              Thank you! We will notify you once we have health care providers
              in your area.
            </h3>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Notifyregistersuccess
