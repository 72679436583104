import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import { ReactComponent as Checkmark } from '../../Icons/checkmark.svg'

const Msgmodal = (props) => {
  return (
    <Fragment>
      <Container className="modal-container">
        <Row>
          <div className="col-12 contactus modal-msg">
            <Row>
              <div className="suc-msg">
                {props.type === 'success' ? (
                  <>
                    {/* <div className="cus-check mb-4">
                            <input type="checkbox" checked="true"  disabled="true" id="children (pediatrics)-1" name="patientTreated" data-name="patientTreated" value="" />
                            <label htmlFor="children (pediatrics)-1"></label>
                        </div> */}
                    {/* <div className="right-mark"></div> */}
                    <div className="checkmar-outer mb-3">
                      <Checkmark />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {props.msg ? (
                  <>
                    <h3 className="">{props.msg}</h3>{' '}
                  </>
                ) : (
                  <></>
                )}
                {props.btnname ? (
                  <>
                    <div className="col-md-12 text-center">
                      {props.btncancel ? (
                        <>
                          <span>
                            <input
                              type="button"
                              onClick={props.btnclick}
                              className="next-btn-pro delete-btn"
                              value={props.btnname}
                            />
                          </span>
                          <span>
                            <input
                              type="button"
                              onClick={props.close}
                              className="next-btn-pro cancel-btn"
                              value={props.btncancel}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <input
                            type="button"
                            onClick={props.btnclick}
                            className="next-btn-pro pl-4 pr-4"
                            value={props.btnname}
                          />
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Row>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Msgmodal
