import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'

import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Custommodal from '../modal/modal'

const HALF = -0.5

const Starts = (props) => {
  const authContext = useContext(AuthContext)
  const doctor = props?.users || props?.providers
  const getData = () => {
    let accountDetails = {}
    if (props.componentName && props.componentName == 'patient_appointment') {
      accountDetails = {
        accountInfo: {
          id: props.providerPracticeLocations.users.id,
          firstName: props.providerPracticeLocations.users.providers.firstName,
          middleName:
            props.providerPracticeLocations.users.providers.middleName,
          lastName: props.providerPracticeLocations.users.providers.lastName,
          profileImage: props.providerPracticeLocations.users.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.providerMedicalSpecialitiesValue,
        },
      }
    } else if (
      props.componentName &&
      (props.componentName == 'sponsored_doctor' ||
        props.componentName == 'searchResult')
    ) {
      accountDetails = {
        accountInfo: {
          id: props.id,
          firstName: doctor.firstName,
          middleName: doctor.middleName,
          lastName: doctor.lastName,
          profileImage: props.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.providerMedicalSpecialitiesValue,
        },
      }
    } else if (props.componentName && props.componentName == 'profile') {
      accountDetails = {
        accountInfo: {
          id: props.accountInfo.id,
          firstName: props.accountInfo.firstName,
          middleName: props.accountInfo.middleName,
          lastName: props.accountInfo.lastName,
          profileImage: props.accountInfo.profileImage,
        },
        medicalSpeciality: {
          providerMedicalSpecialitiesValue:
            props.medicalSpeciality.providerMedicalSpecialitiesValue,
        },
      }
    }
    return accountDetails
  }
  const accountData = getData()
  const modalList = ['reviewModal']
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [userRating, setuserRating] = useState({ rating: { value: '0' } })

  useEffect(() => {
    setuserRating({
      rating: {
        value: props.rating ? props.rating : '',
        tempvalue: '',
        validation: ['blank'],
        errorMsg: ['Please rate'],
        errorKey: 'rating',
        rating: false,
      },
    })
  }, [props])

  const settemprating = (r) => {
    let urating = userRating
    urating['rating']['tempvalue'] = r
    urating['rating']['rating'] = false
    setuserRating({ ...urating })
  }

  const openModal = (e) => {
    if (
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId == '4'
    ) {
      openProfileTabModal('reviewModal')
    } else if (
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.roleId == '3'
    ) {
      e.preventDefault()
    } else {
      // openLoginModal();
      authContext.showLoginModal({
        modalName: 'login',
        redirectUrl: window.location.href,
      })
    }
  }

  const openProfileTabModal = (modalName) => {
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  return (
    <Fragment>
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'reviewModal' ? (
              <DoctorReviewModal
                {...accountData}
                getProfile={props.getProfile}
                setModalProps={props.setRatingModalProps}
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <div className="stars-outer">
        {[1, 2, 3, 4, 5].map((d, i) => {
          const rating = userRating.rating.tempvalue || userRating.rating.value

          const star = rating >= d ? 'star full' : 'star'

          const isHalfAStar = rating - d === HALF

          const className = isHalfAStar ? 'star half' : star

          return (
            <span
              key={i}
              style={{
                cursor:
                  authContext.user &&
                  authContext.user.accountInfo &&
                  authContext.user.accountInfo.roleId == '3'
                    ? 'default'
                    : 'pointer',
              }}
              className={className}
              onMouseLeave={(e) =>
                authContext.user &&
                authContext.user.accountInfo &&
                authContext.user.accountInfo.roleId == '3'
                  ? e.preventDefault()
                  : settemprating(0)
              }
              onMouseEnter={(e) =>
                authContext.user &&
                authContext.user.accountInfo &&
                authContext.user.accountInfo.roleId == '3'
                  ? e.preventDefault()
                  : settemprating(d)
              }
              onClick={(e) => {
                window.localStorage.setItem('role', 4)
                window.localStorage.removeItem('subRole')
                openModal(e)
              }}
            ></span>
          )
        })}
      </div>
    </Fragment>
  )
}

export default Starts
