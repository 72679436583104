const InputValidaton = (Props) => {
  const emailRegex =
    /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|"|"(?=$|\.|@)|[ .](?=.*"))(?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|\.){0,62})(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/gm

  const numberRegex = /^[0-9]+$/
  const nameRegex = /^[a-zA-Z ]*$/
  const alphanumericRegex = /^[a-zA-Z0-9]*$/
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-zA-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  const weblinkRegex =
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  const cardRegex =
    /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})[-. ]?([0-9]{4,5})$/

  let errorList = {}
  const checkValidataion = (key, value, validation, errorMsg, errorKey) => {
    if (validation && validation.length) {
      var vc
      for (vc = 0; vc < validation.length; vc++) {
        if (typeof validation[vc] === 'string') {
          let validateResult =
            key !== ('middleName' && 'extension' && 'medicalLicenseNumber') &&
            validateValue(value, validation[vc])
          if (validateResult) {
            errorList = { ...errorList, ...{ [errorKey]: errorMsg[vc] } }
            break
          }
        } else {
          for (const key in validation[vc]) {
            let validateResult = validateValue(value, key, validation[vc][key])
            if (validateResult) {
              errorList = { ...errorList, ...{ [errorKey]: errorMsg[vc] } }
              break
            }
          }
        }
      }
    }
  }

  // eslint-disable-next-line no-undef
  const validateValue = (value, type, obj = {}) => {
    switch (type) {
      case 'blank':
        if (value == '' || value === undefined || value === null) {
          return true
        } else {
          return false
        }
      case 'expiry':
        if (value == '' || value === undefined) {
          return true
        } else {
          let arr = value.split('/')
          if (arr.length == 2) {
            let month = parseInt(arr[0])
            let year = parseInt(arr[1])
            let currentyear = new Date().toLocaleDateString('en', {
              year: '2-digit',
            })
            let currentmonth = new Date().getMonth() + 1
            if (
              month &&
              year &&
              month > 0 &&
              month < 13 &&
              year >= currentyear
            ) {
              if (currentyear == year && month < currentmonth) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          } else {
            return true
          }
        }
      case 'email':
        if (value !== '' && value !== undefined && !emailRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'weblink':
        if (value !== '' && value !== undefined && !weblinkRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'name':
        if (!nameRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'alphanumeric':
        if (
          value !== '' &&
          value !== undefined &&
          !alphanumericRegex.test(value)
        ) {
          return true
        } else {
          return false
        }
      case 'emailorphone':
        if (
          !emailRegex.test(value) &&
          (!numberRegex.test(value) || value.length != '10')
        ) {
          return true
        } else {
          return false
        }
      case 'number':
        if (!numberRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'indianmobile':
        if (!phoneRegex.test(value) && value != '') {
          return true
        } else {
          return false
        }
      case 'cvv':
        if (!numberRegex.test(value) || value.length != '3') {
          return true
        } else {
          return false
        }
      case 'card':
        if (!cardRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'password':
        if (value !== '' && value !== undefined && !passwordRegex.test(value)) {
          return true
        } else {
          return false
        }
      case 'arrayempty':
        if (!value.length) {
          return true
        } else {
          return false
        }
      case 'objectempty':
        if (Object.keys(value).length <= 0) {
          return true
        } else {
          return false
        }
      case 'image':
        if (value && value.name !== null && value.name !== undefined) {
          try {
            if (
              obj.img_type !== undefined &&
              typeof obj.img_type == 'object' &&
              obj.img_type.length
            ) {
              let ext
              ext = value.name.split('.')
              ext = ext[ext.length - 1].toLowerCase()
              if (obj.img_type.indexOf(ext) == '-1') {
                return true
              }
            }
            if (
              obj.img_min_size !== undefined &&
              obj.img_min_size > value.size
            ) {
              return true
            }
            if (
              obj.img_max_size !== undefined &&
              obj.img_max_size < value.size
            ) {
              return true
            }
            return false
          } catch (e) {
            return true
          }
        } else if (
          obj.old_image !== undefined &&
          obj.old_image != '' &&
          obj.old_image != null
        ) {
          return false
        } else {
          return true
        }
      case 'confirmPassword':
        if (obj.password != value) {
          return true
        } else {
          return false
        }
      case 'confirmEmail':
        if (obj.email != value) {
          return true
        } else {
          return false
        }
      case 'compareDate':
        if (obj.startDate > value) {
          return true
        } else {
          return false
        }
    }
  }
  for (const key in Props) {
    let value = Props[key].value
    if (typeof value === 'string') {
      value = Props[key].value.trim()
    }
    const validation = Props[key].validation
    validation &&
      validation.length &&
      checkValidataion(
        key,
        value,
        validation,
        Props[key].errorMsg,
        Props[key].errorKey
      )
  }
  return errorList
}
export default InputValidaton
