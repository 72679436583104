import React, { Fragment, useContext, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import AuthContext from '../../reactLayer/auth/AuthContext'
import PatientTabs from '../../components/admin/patient/patient-tabs'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import SendNotification from '../../components/admin/notification-admin/send-notification'
import HistoryNotification from '../../components/admin/notification-admin/notification-history'
import Loadermodal from '../../global/loader-modal/loader-modal'

const NotificationDetail = () => {
  const authContext = useContext(AuthContext)
  const [visibleComponent, setvisibleComponent] = useState('sendNoti')
  const tabName = [
    { id: 'sendNoti', name: 'Send Notification' },
    { id: 'historyNoti', name: 'History' },
  ]

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Row className="bg-white-report patient-details-outer">
            <div className="table-report">
              <div className="heading-wrap mb-lg-4">
                <div className="table-heading">Notifications</div>
              </div>
              <div className="notification-tabs-outer">
                <PatientTabs
                  visibleComponent={visibleComponent}
                  setvisibleComponent={setvisibleComponent}
                  tabName={tabName}
                />
              </div>
              {visibleComponent === 'sendNoti' ? (
                <>
                  <SendNotification />{' '}
                </>
              ) : visibleComponent === 'historyNoti' ? (
                <HistoryNotification />
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default NotificationDetail
