import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'

import HeaderMain from '../../global/header/header-main'
import Footer from '../../global/footer/footer'
import TermsOne from '../../components/privacy-terms/terms1'
import { Helmet } from 'react-helmet'

const TermsOfUse = (props) => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Terms of use</title>
        <meta
          name="description"
          content="List of Terms and conditions to abide by while using DocScheduler Services."
        />
        <link rel="canonical" href="https://www.docscheduler.com/term-of-use" />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/term-of-use`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content={`DocScheduler | Terms of use`} />
        <meta name="twitter:title" content={`DocScheduler | Terms of use`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="List of Terms and conditions to abide by while using DocScheduler Services."
        />
        <meta
          name="twitter:description"
          content="List of Terms and conditions to abide by while using DocScheduler Services."
        />
      </Helmet>
      <HeaderMain {...props} />
      <TermsOne />
      <Footer />
    </Fragment>
  )
}

export default TermsOfUse
