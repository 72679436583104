import 'bootstrap/dist/css/bootstrap.css'
import { ReactComponent as ChatIcon } from '../../admin/assets/images/chat.svg'

const ChatBubble = ({ onClick }) => {
  return (
    <div className="chat-bubble" onClick={onClick}>
      <ChatIcon />
    </div>
  )
}

export default ChatBubble
