import { useEffect, useState } from 'react'
import NetworkLayer from '../../../../reactLayer/Network/Network'

export const getPatientsTreatedList = async () => {
  const response = await NetworkLayer.getRequest({
    url: 'patients/treated/get',
  })
  if (response.status) {
    // @ts-ignore
    return response.result
  } else {
    return []
  }
}

export const getMedicalSpeciality = () => {
  const [data, setData] = useState([])

  const getData = async () => {
    const response = await NetworkLayer.getRequest({
      url: 'medical-speciality/get',
    })
    if (response.status) {
      // @ts-ignore
      setData(response.result)
    } else {
      setData([])
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}

export const getLangList = async () => {
  const response = await NetworkLayer.getRequest({ url: 'languages/get' })
  if (response.status) {
    // @ts-ignore
    return response.result.map((each) => each.name)
  } else {
    return []
  }
}
