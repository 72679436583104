import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { useParams } from 'react-router-dom'
import { Content } from './Content'
import { DoctorProfile } from '../../types'

type Params = {
  providerId: string
}

export const Page = () => {
  const authContext = useContext(AuthContext)
  const [profile, setProfile] = useState<DoctorProfile>()
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
    claimProfile: false,
  })

  const { providerId } = useParams<Params>()

  const getProfile = async () => {
    if (providerId) {
      authContext.setLoader()
      const headers: any = { 'Content-Type': 'application/json' }
      if (authContext.token) {
        headers['access-token'] = authContext.token
      }
      const fData: any = {}
      if (
        authContext.browserLatLng?.latitude &&
        authContext.browserLatLng.longitude
      ) {
        fData['latitude'] = authContext.browserLatLng.latitude
        fData['longitude'] = authContext.browserLatLng.longitude
      }
      const submitData = {
        headers: headers,
        url: 'providers/get/' + providerId,
        body: JSON.stringify(fData),
      }
      const result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        const temp_result = result.body.result
        if (result.body.addressInfo) {
          temp_result['addressInfo'] = result.body.addressInfo
        }
        setProfile(temp_result)
      }
      authContext.unsetLoader()
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  // @ts-ignore
  return (
    <Fragment>
      {profile && <Content doctor={profile} getProfile={getProfile} />}
    </Fragment>
  )
}
