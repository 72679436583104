import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Dochowwork from '../../components/doc-how-work/doc-how-work'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const Customaccordion = (props) => {
  const authContext = useContext(AuthContext)
  const [activeKey, setActiveKey] = useState('')
  const [data, setData] = useState([])
  const onAccordionToggle = (e) => {
    e.preventDefault()
    let key = e.currentTarget.getAttribute('data-controls')
    if (key == activeKey) {
      setActiveKey('')
    } else {
      setActiveKey(key)
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  const getDetails = async (id) => {
    authContext.setLoader()

    let formData = {
      id: 6,
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: 'content-management/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    let resultBody = result.body.result.description

    let tempFaq = []
    resultBody.split('|--------|').map((faq, index) => {
      const faqData = faq.split(' |--| ')
      if (index === 1) {
        tempFaq.push({ name: faqData[0], description: <Dochowwork /> })
      } else {
        tempFaq.push({ name: faqData[0], description: faqData[1] })
      }
      return null
    })
    setData(tempFaq)

    //{ name: "What is DocScheduler?", description: "DocScheduler is a digital health care scheduling service that enables users to search for and request doctors appointments from local health care providers. DocScheduler helps health care providers build, understand and improve their online reputation and obtain new patients to grow their business!" }
    authContext.unsetLoader()
  }

  return (
    <Fragment>
      <div id="" className="accordion" role="tablist">
        <h2 className="accortion-heading">Frequently Asked Questions</h2>
        {data.map((item, index) => (
          <div key={'accordion-' + index} className="accordion-group">
            <div
              className={
                activeKey == 'panel-' + index
                  ? 'panel-title active'
                  : 'panel-title'
              }
              data-controls={'panel-' + index}
              onClick={(e) => {
                onAccordionToggle(e)
              }}
            >
              <a
                href="/#"
                aria-expanded={
                  activeKey == 'panel-' + index ? 'true ' : 'false'
                }
                aria-controls={'panel-' + index}
                className="control-panel-title"
                id={'control-panel-' + index}
                data-bodyid={'control-panel'}
              >
                <h3 className="mg-dark h5 mb-0">{item.name}</h3>
                {/* <div className="plus or minus">+</div> */}
              </a>
            </div>
            <div
              className="panel-content"
              id={'panel-' + index}
              aria-labelledby={'control-panel-' + index}
              role="tablist"
              aria-hidden={activeKey == 'panel-' + index ? 'false ' : 'true'}
              style={{ display: activeKey == 'panel-' + index ? '' : 'none' }}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default Customaccordion
