import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Global from '../../../reactLayer/global'

const Pagination = (props) => {
  const { total, limit, offset, range, callBack } = props
  const [list, setList] = useState([])
  const [prevPage, setprevPage] = useState(false)
  const [nextPage, setnextPage] = useState(false)
  const [beforeprevPage, setbeforeprevPage] = useState(false)
  const [beforenextPage, setbeforenextPage] = useState(false)

  let history = useHistory()

  const query = new URLSearchParams(history.location.search)

  const getList = async () => {
    let snumber,
      start = 0,
      end = range,
      arrLength
    let list = total / limit
    snumber = offset / limit

    let listInteger = parseInt(list)
    if (listInteger < list) {
      listInteger = listInteger + 1
    }

    if (snumber % range) {
      if (snumber >= range) {
        start = snumber - (snumber % range)
      }
      // start = end - (snumber % limit);
      end = start + end
      if (end > listInteger) {
        end = listInteger
      }
    } else {
      if (snumber > 0) {
        start = snumber
      }
      end = start + end
      if (end > listInteger) {
        end = listInteger
      }
    }

    if (parseInt(snumber) > 0) {
      setprevPage((parseInt(snumber) - 1).toString())
    } else {
      setprevPage(false)
    }

    if (parseInt(snumber) < listInteger - 1) {
      setnextPage(snumber + 1)
    } else {
      setnextPage(false)
    }

    if (parseInt(start) >= parseInt(range)) {
      setbeforeprevPage((start - range).toString())
    } else {
      setbeforeprevPage(false)
    }
    if (parseInt(end) < parseInt(listInteger)) {
      setbeforenextPage(end)
    } else {
      setbeforenextPage(false)
    }
    arrLength = await Global.getArray(start, end)
    setList([...arrLength])
  }
  const changePage = (e) => {
    e.preventDefault()
    let pageno = e.currentTarget.getAttribute('data-id')
    history.replace(`${history.location.pathname}?page=${Number(pageno) + 1}`)
    callBack(parseInt(pageno) * limit, pageno)
  }

  useEffect(() => {
    getList()
  }, [props])

  useEffect(() => {
    const page = query.get('page')
    if (page) {
      const currentPage = page - 1
      callBack(parseInt(currentPage) * limit, currentPage)
    }
  }, [])

  return (
    <Fragment>
      <span>
        Showing {total == 0 ? 0 : offset + 1} to{' '}
        {total > offset + limit ? offset + limit : total} of {total} entries
      </span>
      <span className="pagenation-wrapper">
        <ul className="list">
          {prevPage ? (
            <>
              <Link
                data-id={prevPage}
                onClick={(e) => changePage(e)}
                className="prev"
                href="/#"
              >
                Previous
              </Link>
            </>
          ) : (
            <>
              <Link
                data-id={prevPage}
                onClick={(e) => changePage(e)}
                className="prev"
                href="/#"
                style={{ opacity: 0, 'pointer-events': 'none' }}
              >
                Previous
              </Link>
            </>
          )}
          {beforeprevPage ? (
            <>
              <li data-id={beforeprevPage} onClick={(e) => changePage(e)}>
                ...
              </li>
            </>
          ) : (
            <>
              <li
                data-id={beforeprevPage}
                style={{ opacity: 0, 'pointer-events': 'none' }}
              >
                ...
              </li>
            </>
          )}
          {list.map((l, li) =>
            l * limit === offset ? (
              <>
                <li key={'paging-' + li}>
                  <Link
                    data-id={l}
                    className="active"
                    onClick={(e) => e.preventDefault()}
                    href="/#"
                  >
                    {l + 1}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li key={'paging-' + li}>
                  <Link
                    data-id={l}
                    className=""
                    onClick={(e) => changePage(e)}
                    href="/#"
                  >
                    {l + 1}
                  </Link>
                </li>
              </>
            )
          )}

          {beforenextPage ? (
            <>
              <li
                key={'beforenextPage'}
                data-id={beforenextPage}
                onClick={(e) => changePage(e)}
                className="page-item dot-pagination"
              >
                ...
              </li>
            </>
          ) : (
            <>
              <li
                key={'beforenextPage'}
                data-id={beforenextPage}
                style={{ opacity: 0, 'pointer-events': 'none' }}
                className="page-item dot-pagination"
              >
                ...
              </li>
            </>
          )}

          {nextPage ? (
            <>
              <Link
                data-id={nextPage}
                onClick={(e) => changePage(e)}
                className="next"
              >
                Next
              </Link>
            </>
          ) : (
            <>
              <Link
                style={{ opacity: 0, 'pointer-events': 'none' }}
                data-id={nextPage}
              >
                Next
              </Link>
            </>
          )}
        </ul>
      </span>
    </Fragment>
  )
}

export default Pagination
