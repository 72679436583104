import { useLocation } from 'react-router-dom'

export const useGetSearchParams = () => {
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const find = searchParams.get('findInputValue')
  const city = searchParams.get('city')
  const zip = searchParams.get('zip')
  const insurance = searchParams.get('searchInsuranceName')
  const focusArea = searchParams.get('focusArea')

  return { find, city, zip, insurance, focusArea }
}
