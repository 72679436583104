import React, { useContext, useState } from 'react'
import './TeamRegister.scss'

import { useHistory, useParams } from 'react-router-dom'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const TeamMemberReg = () => {
  const history = useHistory()
  const params = useParams()
  const userId = params.memberId
  const authContext = useContext(AuthContext)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Perform form validation
    const validationErrors = {}

    if (firstName.trim() === '') {
      validationErrors.firstName = 'First name is required'
    }

    if (lastName.trim() === '') {
      validationErrors.lastName = 'Last name is required'
    }

    if (password.trim() === '') {
      validationErrors.password = 'Password is required'
    }

    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0 && userId) {
      authContext.setLoader()

      let data = {
        firstName: firstName,
        lastName: lastName,
        password: password,
        memberUID: userId,
      }

      const submitData = {
        url: 'appointment/schedule/teams/register',
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.message === 'Team member successfully registered.') {
        history(ProviderLinks.dashboard)
      }
    }
  }

  return (
    <div className="form-wrapper">
      <form className="team-form" onSubmit={handleSubmit}>
        <div>
          <label className="reg-label" htmlFor="firstName">
            First Name
          </label>
          <input
            className="reg-input"
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {errors.firstName && <div className="error">{errors.firstName}</div>}
        </div>

        <div>
          <label className="reg-label" htmlFor="lastName">
            Last Name
          </label>
          <input
            className="reg-input"
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors.lastName && <div className="error">{errors.lastName}</div>}
        </div>

        <div>
          <label className="reg-label" htmlFor="password">
            Password
          </label>
          <input
            className="reg-input"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <div className="error">{errors.password}</div>}
        </div>

        <button className="reg-button" type="submit">
          Register
        </button>
      </form>
    </div>
  )
}

export default TeamMemberReg
