import { Component } from 'react'
import { AdminLinks, ProviderLinks } from '../../linkFile'
import StorageAdaptation from '../storage/storage'

class Network extends Component {
  constructor(props) {
    super(props)
    this.ajaxUrl = process.env.REACT_APP_API_BASEURL
    this.requestList = []
    this.getRequestConfig = {
      url: '',
      data: '',
      type: 'GET',
    }
    ;(this.postRequestConfig = {
      url: '',
      method: 'POST',
      cache: 'no-cache',
      body: {},
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
      (this.deleteRequestConfig = {
        url: '',
        method: 'DELETE',
        cache: 'no-cache',
        body: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      (this.patchRequestConfig = {
        url: '',
        method: 'PATCH',
        cache: 'no-cache',
        body: {},
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
      (this.puthRequestConfig = {
        url: '',
        method: 'PUT',
        cache: 'no-cache',
        body: {},
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
  }

  postData = async (postReqData) => {
    let config = { ...this.postRequestConfig, ...postReqData }
    let postUrl

    // Modify/concatinate the URL
    if (config.fullUrl && config.fullUrl !== '') {
      postUrl = config.fullUrl
      config.fullUrl && delete config.fullUrl
    } else {
      postUrl = this.ajaxUrl + config.url
    }
    // Modify/mange formdate in multipart/form-data
    // const formData = config.data

    // let data = {};
    // if (Object.keys(formData).length > 0) {
    //   data = new FormData();
    //   for (var key of Object.keys(formData)) {
    //     data.append(key, formData[key]);
    //   }
    // }

    config.url && delete config.url
    let response = await fetch(postUrl, config)
    let body,
      headers = {}
    for (let [key, value] of response.headers) {
      headers[key] = value
    }
    body = await response.json()

    if (body.code == 413) {
      let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
      window.localStorage.removeItem('admin')
      window.localStorage.removeItem('adminToken')
      if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 4
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 3
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.login
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 2
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = AdminLinks.login
      } else {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      }
    }
    return { headers: headers, body: body }
  }

  deleteData = async (deleteReqData) => {
    let config = { ...this.deleteRequestConfig, ...deleteReqData }
    let deleteUrl

    // Modify/concatinate the URL
    if (config.fullUrl && config.fullUrl !== '') {
      deleteUrl = config.fullUrl
      config.fullUrl && delete config.fullUrl
    } else {
      deleteUrl = this.ajaxUrl + config.url
    }
    // Modify/mange formdate in multipart/form-data
    // const formData = config.data

    // let data = {};
    // if (Object.keys(formData).length > 0) {
    //   data = new FormData();
    //   for (var key of Object.keys(formData)) {
    //     data.append(key, formData[key]);
    //   }
    // }

    config.url && delete config.url
    let response = await fetch(deleteUrl, config)
    let body,
      headers = {}
    for (let [key, value] of response.headers) {
      headers[key] = value
    }
    body = await response.json()

    if (body.code == 413) {
      let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
      window.localStorage.removeItem('admin')
      window.localStorage.removeItem('adminToken')
      if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 4
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 3
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.login
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 2
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = AdminLinks.login
      } else {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      }
    }
    return { headers: headers, body: body }
  }

  putData = async (postReqData) => {
    let config = { ...this.puthRequestConfig, ...postReqData }
    let postUrl

    // Modify/concatinate the URL
    if (config.fullUrl && config.fullUrl !== '') {
      postUrl = config.fullUrl
      config.fullUrl && delete config.fullUrl
    } else {
      postUrl = this.ajaxUrl + config.url
    }
    // Modify/mange formdate in multipart/form-data
    // const formData = config.data

    // let data = {};
    // if (Object.keys(formData).length > 0) {
    //   data = new FormData();
    //   for (var key of Object.keys(formData)) {
    //     data.append(key, formData[key]);
    //   }
    // }

    config.url && delete config.url
    let response = await fetch(postUrl, config)
    let body,
      headers = {}
    for (let [key, value] of response.headers) {
      headers[key] = value
    }
    body = await response.json()

    if (body.code == 413) {
      let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
      if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 4
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 3
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.login
      } else {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      }
    }
    return { headers: headers, body: body }
  }

  patchData = async (postReqData) => {
    let config = { ...this.patchRequestConfig, ...postReqData }
    let postUrl

    // Modify/concatinate the URL
    if (config.fullUrl && config.fullUrl !== '') {
      postUrl = config.fullUrl
      config.fullUrl && delete config.fullUrl
    } else {
      postUrl = this.ajaxUrl + config.url
    }
    // Modify/mange formdate in multipart/form-data
    // const formData = config.data

    // let data = {};
    // if (Object.keys(formData).length > 0) {
    //   data = new FormData();
    //   for (var key of Object.keys(formData)) {
    //     data.append(key, formData[key]);
    //   }
    // }

    config.url && delete config.url
    let response = await fetch(postUrl, config)
    let body,
      headers = {}
    for (let [key, value] of response.headers) {
      headers[key] = value
    }
    body = await response.json()

    if (body.code == 413) {
      let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
      if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 4
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 3
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.login
      } else {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      }
    }
    return { headers: headers, body: body }
  }

  getRequest = async (getReqData) => {
    let config = { ...this.getRequestConfig, ...getReqData }
    const { url } = config

    let getUrl

    if (config.fullUrl && config.fullUrl !== '') {
      getUrl = config.fullUrl
    } else {
      getUrl = this.ajaxUrl + url
    }

    let response = await fetch(getUrl, config)
    response = await response.json()
    if (response.code == 413 && url != 'admin/verify/') {
      let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
      if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 4
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      } else if (
        userData &&
        userData.accountInfo &&
        userData.accountInfo.roleId &&
        userData.accountInfo.roleId === 3
      ) {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'provider',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.login
      } else {
        StorageAdaptation.setLocalStorage(
          'tokenExpire',
          'patient',
          'false',
          'session'
        )
        StorageAdaptation.removeLocalStorage('user', 'local')
        StorageAdaptation.removeLocalStorage('token', 'local')
        window.location.href = ProviderLinks.home
      }
    }
    return response
  }

  render() {
    return ''
  }
}

const NetworkLayer = new Network()
export default NetworkLayer
