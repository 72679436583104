import { createContext, useContext, useState } from 'react'

const ChipInputValuesContext = createContext({
  cityInput: '',
  insuranceInput: '',
  setCityInput: (value: any) => value,
  setInsuranceInput: (value: any) => value,
  zipInput: '',
  setZipInput: (value: any) => value,
  long: '',
  setLong: (value: any) => value,
  lat: '',
  setLat: (value: any) => value,
})

export const useChipInputValues = () => {
  return useContext(ChipInputValuesContext)
}

export const ChipInputValuesProvider = ({ children }) => {
  const [cityInput, setCityInput] = useState('')
  const [insuranceInput, setInsuranceInput] = useState('Uninsured')
  const [zipInput, setZipInput] = useState('')
  const [long, setLong] = useState(null)
  const [lat, setLat] = useState(null)

  return (
    <ChipInputValuesContext.Provider
      value={{
        cityInput,
        insuranceInput,
        setCityInput,
        setInsuranceInput,
        zipInput,
        setZipInput,
        long,
        setLong,
        lat,
        setLat,
      }}
    >
      {children}
    </ChipInputValuesContext.Provider>
  )
}
