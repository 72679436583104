import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { ReactComponent as SelectOptionIcon } from '../../admin/assets/images/icon-dropdown.svg'
import { personalInfoInitialData } from './initial-state'
import InputValidation from '../../reactLayer/validation'

const PersonalInformation = (props) => {
  const [data, setData] = useState(personalInfoInitialData)

  const validate = () => {
    let flag = true
    let validationResult = InputValidation(data)

    let tempCustIns = data
    let errorFields = []
    if (Object.keys(validationResult).length > 0) {
      flag = false
      for (const prop in validationResult) {
        tempCustIns[prop][prop] = `${validationResult[prop]}`
        errorFields.push(prop)
      }
      document.getElementById(errorFields[0])?.focus()
      setData({ ...tempCustIns })
    }

    let postData = {
      firstName: data.firstName.value,
      lastName: data.lastName.value,
      phoneNumber: data.phoneNumber.value,
      email: data.email.value,
      gender: data.gender.value,
      phoneType: data.phoneType.value,
      nationalProviderIdentifierNumber:
        data.nationalProviderIdentifierNumber.value,
      medicalLicenseNumber: data.medicalLicenseNumber.value,
    }

    props.collectData(postData, { personal: !flag })
  }

  const changeInputValue = (type, value) => {
    let val = value
    if (type == 'nationalProviderIdentifierNumber') {
      if (!isNaN(val)) {
        val = value
      } else {
        val = value.slice(0, -1)
      }
    }
    setData({
      ...data,
      ...{
        [type]: {
          value: val,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: [type],
        },
      },
    })
  }

  useEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  }, [props.shouldValidate])

  return (
    <Fragment>
      <Row className="table-sort-filter">
        <div className="heading-wrap mt-4 mb-4 col-12">
          <div className="table-heading mt-1">Provider Details</div>
        </div>
        <div className="col-12 col-lg-9">
          <form className="provider-form-edit row mb-3">
            <div className="col-md-4 mb-3">
              <label className="pro-label">First Name</label>
              <input
                type="text"
                className="input-pro"
                id="firstName"
                placeholder="First Name"
                value={data?.firstName?.value}
                onChange={(e) =>
                  changeInputValue('firstName', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.firstName ? data.firstName.firstName : ''}
              </small>
            </div>
            <div className="col-md-4 mb-3">
              <label className="pro-label">Last Name</label>
              <input
                type="text"
                className="input-pro"
                id="lastName"
                placeholder="Last Name"
                value={data?.lastName?.value}
                onChange={(e) =>
                  changeInputValue('lastName', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.lastName ? data.lastName.lastName : ''}
              </small>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Work Email Address</label>
              <input
                type="email"
                className="input-pro"
                id="email"
                placeholder="Work Email Address"
                value={data?.email?.value}
                onChange={(e) =>
                  changeInputValue('email', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.email ? data.email.email : ''}
              </small>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Sex</label>
              <label className="radio-button mt-2 mr-2" htmlFor="male">
                <input
                  type="radio"
                  name="sex"
                  id="male"
                  value={'male'}
                  checked={data?.gender?.value == 'male' ? 'Checked' : ''}
                  onChange={(e) =>
                    changeInputValue('gender', e.currentTarget.value)
                  }
                />
                <span className="radio-check"></span>
                Male
              </label>
              <label className="radio-button mt-2 mr-2" htmlFor="female">
                <input
                  type="radio"
                  name="sex"
                  id="female"
                  value={'female'}
                  checked={data?.gender?.value == 'female' ? 'Checked' : ''}
                  onChange={(e) =>
                    changeInputValue('gender', e.currentTarget.value)
                  }
                />
                <span className="radio-check"></span>
                Female
              </label>
            </div>
            <div className="col-md-5 mb-3">
              <label className="pro-label">Phone Number</label>
              <input
                type="tel"
                className="input-pro"
                placeholder="Phone Number"
                id="phoneNumber"
                value={data?.phoneNumber?.value}
                onChange={(e) =>
                  changeInputValue('phoneNumber', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.phoneNumber ? data.phoneNumber.phoneNumber : ''}
              </small>
            </div>
            <div className="col-md-4 mb-3 select-option">
              <label className="pro-label">Phone Type</label>
              <select
                className="select"
                onChange={(e) =>
                  changeInputValue('phoneType', e.currentTarget.value)
                }
              >
                <option value="cell" selected={!data?.phoneType?.value}>
                  None
                </option>
                <option
                  value="cell"
                  selected={data?.phoneType?.value == 'cell' ? 'Checked' : ''}
                >
                  Cell Phone
                </option>
                <option
                  value="home"
                  selected={data?.phoneType?.value == 'home' ? 'Checked' : ''}
                >
                  Home Phone
                </option>
              </select>
              <i className="select-option-icon">
                <SelectOptionIcon />
              </i>
              <small className="error-input">
                {data.phoneType ? data.phoneType.phoneType : ''}
              </small>
            </div>
            <div className="heading-wrap mt-4 mb-4 col-12">
              <div className="table-heading mt-1">
                Medical Verification Information
              </div>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">
                National Provider Identifier (NPI) Number
              </label>
              <input
                type="tel"
                className="input-pro"
                id="nationalProviderIdentifierNumber"
                placeholder="National Provider Identifier (NPI) Number"
                value={data?.nationalProviderIdentifierNumber?.value}
                onChange={(e) =>
                  changeInputValue(
                    'nationalProviderIdentifierNumber',
                    e.currentTarget.value
                  )
                }
              />
              <small className="error-input">
                {data.nationalProviderIdentifierNumber
                  ? data.nationalProviderIdentifierNumber
                      .nationalProviderIdentifierNumber
                  : ''}
              </small>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Medical License Number</label>
              <input
                type="text"
                className="input-pro"
                id="medicalLicenseNumber"
                placeholder="Medical License Number"
                value={data?.medicalLicenseNumber?.value}
                onChange={(e) =>
                  changeInputValue(
                    'medicalLicenseNumber',
                    e.currentTarget.value
                  )
                }
              />
              <small className="error-input">
                {data.medicalLicenseNumber
                  ? data.medicalLicenseNumber.medicalLicenseNumber
                  : ''}
              </small>
            </div>
          </form>
        </div>
      </Row>
    </Fragment>
  )
}

export default PersonalInformation
