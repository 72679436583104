import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import Custommodal from '../../../global/modal/modal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import Pagination from '../pagination/pagination'
import ProviderManagementInfoMadal from './provider-manage-info-modal'

const RevenueMngt = (props) => {
  const authContext = useContext(AuthContext)
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [data, setData] = useState([])
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [viewdetails, setViewdetails] = useState([])
  const [search, setSearch] = useState('')
  const [sort, setsort] = useState('ASC')
  const [sortName, setSortName] = useState('Name')

  const currentUrl = window.location.href.split('/').slice(-1).pop()

  // FOR POPUP MODAL
  const modalList = ['manageInfo']

  const getList = async (value) => {
    authContext.setLoader()
    // FORM DATA
    let formData = {}
    if (value) {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        searchValue: value,
        sortOrder: sort,
        sortBy: sortName,
      }
    } else {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        sortOrder: sort,
        sortBy: sortName,
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/revenue/getProvidersRevenueData',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    // console.log("result==>", result.body.result);
    setData(result?.body?.result?.result)
    settotalCount(result?.body?.result?.resultCount)
    authContext.unsetLoader()
  }

  const openProfileTabModal = (modalName, value) => {
    getDetails(value?.id).then(() => {
      settabvisibleComponent(modalName)
      authContext.showModal()
    })
  }

  const getDetails = async (id) => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/view/' + id,
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)
    setViewdetails(result?.result)
    authContext.unsetLoader()
  }

  const updateStatus = async (id, status) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: id,
      status: status,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/status/update',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    getList()
  }

  const deleteProvider = async (id) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/delete',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    if (window.confirm('Are you sure you want to delete this provider?')) {
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        getList('', ['graph', 'donut'])
      }
    }

    authContext.unsetLoader()
  }

  useEffect(() => {
    getList()
  }, [filter.offset, sort])

  // PAGINATION CALLBACK
  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  // const getAccountType = (val) => {
  //     switch (val) {
  //         case 1:
  //             return 'free';
  //         case 2:
  //             return 'Basic';
  //         case 3:
  //             return 'Premium';
  //         case 4:
  //             return 'Premium+';
  //         default:
  //             return 'free';
  //     }
  // };

  const toggleStatus = (values) => {
    let status = values.status == 1 ? 0 : 1
    let id = values.id
    updateStatus(id, status)
  }

  const changeSorting = (e) => {
    let name = e.currentTarget.getAttribute('name')
    setSortName(name)
    setsort(sort == 'ASC' ? 'DESC' : 'ASC')
  }

  const searchFilter = (value) => {
    getList(value)
  }
  return (
    <Fragment>
      <Loadermodal />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'manageInfo' ? (
              <ProviderManagementInfoMadal data={viewdetails} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <Row className="bg-white-report">
        <div className="table-report provider-management-outer">
          <div className="heading-wrap" style={{ marginBottom: '20px' }}>
            <div className="table-heading">Revenue Management</div>
          </div>
          {/* <ManagementFilter search={searchFilter} /> */}
          <div className="table-report-scroll mt-0">
            <table className="table">
              <thead>
                <tr>
                  <th>Provider Name</th>
                  <th>Account Type</th>
                  <th>
                    Credit Card
                    <br />
                    Expiration
                  </th>
                  <th>First Trans Data</th>
                  <th>Last Trans Data</th>
                  <th>Total Revenue</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((value) => (
                    <tr key={`irp-${value.id}`}>
                      <td>{`${value.data?.providers?.firstName} ${value.data?.providers?.lastName}`}</td>
                      <td>{'--'}</td>
                      <td>
                        {
                          value.data?.userCurrentPlans?.planPurchaseDetails
                            ?.cardExpiry
                        }
                      </td>
                      <td>{moment(value?.firstTransDate).format('D/MM/Y')}</td>
                      <td>{moment(value?.lastTransDate).format('D/MM/Y')}</td>
                      <td>
                        $
                        {value.totalRevenue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td>
                        <label className="active-toggle">
                          <input
                            type="checkbox"
                            checked={value?.data?.status == 1 ? 'checked' : ''}
                            onChange={() => {
                              toggleStatus(value.data)
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <Link
                          className="table-action view-all"
                          onClick={() => {
                            openProfileTabModal('manageInfo', value.data)
                          }}
                        >
                          View
                        </Link>
                        <Link
                          className="table-action view-all"
                          onClick={() => {
                            deleteProvider(value.data.id)
                          }}
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="report-page mt-4">
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
            />
          </div>
        </div>
      </Row>
    </Fragment>
  )
}

export default RevenueMngt
