import { useEffect, useState } from 'react'
import NetworkLayer from '../../../../reactLayer/Network/Network'
import { InputSuggestion } from '../types'
import { statusOptions } from '../../../lib/utils'

export const useGetProviderSuggestions = (
  input: string,
  setStatus: (status: number) => void
): InputSuggestion => {
  const [data, setData] = useState<InputSuggestion>()

  const fetchSuggestions = async (input) => {
    setStatus(statusOptions.success)
    if (input.length >= 3) {
      setStatus(statusOptions.loading)
      const dataNode = {
        specialityData: [],
        conditionData: [],
        treatmentData: [],
        doctorData: [],
      }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'providers/search/suggestion/get',
        body: JSON.stringify({
          searchValue: input,
        }),
      }
      const result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        if (result.body.result) {
          const { specialityData, conditionData, treatmentData, doctorData } =
            result.body.result
          const specialityNode = specialityData.map(
            ({ id, speciality, focusArea }) => ({
              id: id,
              data: speciality,
              focusArea,
            })
          )
          const doctorListNode = doctorData.map(
            ({ id, firstName, lastName }) => ({
              id: id,
              data:
                (firstName ? firstName : '') + (lastName ? ' ' + lastName : ''),
            })
          )
          dataNode.specialityData = specialityNode
          dataNode.conditionData = conditionData
          dataNode.treatmentData = treatmentData
          dataNode.doctorData = doctorListNode
          if (
            specialityNode.length === 0 &&
            conditionData.length === 0 &&
            treatmentData.length === 0 &&
            doctorData.length === 0
          ) {
            setStatus(statusOptions.error)
          } else {
            setStatus(statusOptions.success)
          }
        }
      } else {
        setStatus(statusOptions.error)
      }
      // @ts-ignore
      setData(dataNode)
    }
  }

  useEffect(() => {
    fetchSuggestions(input)
  }, [input])

  return data
}
