import React, {
  Fragment,
  memo,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import Loadermodal from '../../global/loader-modal/loader-modal'
import Custommodal from '../../global/modal/modal'
import AuthContext from '../../reactLayer/auth/AuthContext'

import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import NoResultsModal from '../../components/search-results/no-result-modal'
import Global from '../../reactLayer/global'
import AllFilterModal from './all-filter-modal'

const AllFilters = (props) => {
  const { filter, setfilter } = props
  const authContext = useContext(AuthContext)
  const [visibleComponent, setVisibleComponent] = useState()
  const [specialtyList, setSpecialtyList] = useState([])
  const [langList, setLangList] = useState([])
  const [listPatientsTreated, setListPatientsTreated] = useState([])
  const modalList = ['allFilterModal', 'invitemodal']
  const openModal = (e, modalName) => {
    // authContext.hideModal()
    e.preventDefault()
    setVisibleComponent(modalName)
    authContext.showModal()
  }

  const closeModal = (e) => {
    e && e.preventDefault()
    setVisibleComponent('')
    authContext.hideModal()
  }

  const changeOption = (id) => {
    if (id === 'hasVirtualVisit') {
      setfilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(0) },
        ...{ hasVirtualVisit: filter?.hasVirtualVisit === 0 ? 1 : 0 },
      })
    }
    if (id === 'isAcceptingNewPatient') {
      setfilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(0) },
        ...{
          isAcceptingNewPatient: filter?.isAcceptingNewPatient === 0 ? 1 : 0,
        },
      })
    }
    if (id === 'isTopShow') {
      setfilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(0) },
        ...{ isTopShow: filter?.isTopShow === 0 ? 1 : 0 },
        ...{
          type:
            filter?.isTopShow === 1
              ? filter?.type.filter((i) => {
                  return i !== 'top'
                })
              : [...filter?.type, 'top'],
        },
      })
    }
  }

  useEffect(() => {
    const getPatientsTreatedList = async () => {
      let result = await Global.getPatientsTreatedList()
      if (result.length > 0) {
        setListPatientsTreated(result)
      }
    }

    const getMedicalSpeciality = async () => {
      let result = await Global.getMedicalSpeciality()
      let formatResult = result.map(({ id, name }) => {
        return {
          id,
          name,
        }
      })
      if (formatResult.length > 0) {
        setSpecialtyList(formatResult)
      }
    }

    const getLangListData = async () => {
      let result = await Global.getLangList()
      setLangList(result)
    }

    getPatientsTreatedList()
    getMedicalSpeciality()
    getLangListData()
  }, [])

  const suspenseLoader = () => <p></p>
  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        {modalList.indexOf(visibleComponent) > '-1' ? (
          <Custommodal
            unsetComponent={closeModal}
            componentName={
              // visibleComponent === 'editProfile' ? <EditLocation />
              visibleComponent === 'allFilterModal' ? (
                <AllFilterModal
                  filter={filter}
                  setfilter={setfilter}
                  specialtyList={specialtyList}
                  langList={langList}
                  listPatientsTreated={listPatientsTreated}
                  closeModal={closeModal}
                />
              ) : visibleComponent === 'invitemodal' ? (
                <NoResultsModal closeModal={closeModal} />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <></>
        )}
        <Loadermodal />
        <Container>
          <Row>
            <div style={{ padding: 0 }} className="col-12">
              <div className="all-filter-wrap">
                <div className="filter-tab">
                  <a
                    href="/#"
                    onClick={(e) => {
                      openModal(e, 'allFilterModal')
                    }}
                  >
                    All Filters
                  </a>
                </div>
                <div className="visit-available">
                  <div className="visit-inner">
                    <span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="switch-btn2"
                          checked={filter?.hasVirtualVisit === 0 ? false : true}
                          onChange={(e) => changeOption('hasVirtualVisit')}
                        />
                        <div className="slider round"></div>
                      </label>
                    </span>
                    <span className="video-icon">Virtual visits available</span>
                  </div>
                </div>
                <div className="new-patient-search">
                  <div className="new-pat-inner">
                    <span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="switch-btn2"
                          checked={
                            filter?.isAcceptingNewPatient === 0 ? false : true
                          }
                          onChange={(e) =>
                            changeOption('isAcceptingNewPatient')
                          }
                        />
                        <div className="slider round"></div>
                      </label>
                    </span>
                    <span className="tick-icon">Accepting new patients</span>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Fragment>
    </Suspense>
  )
}

AllFilters.defaultProps = {
  filter: null,
  setfilter: null,
}

export default memo(AllFilters)
