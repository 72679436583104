import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useChipInputValues } from '../../../reactLayer/contextStore/homepageStore'
import { generateURLParams } from '../../lib/utils'
import { ChipInputIcon, ChipInputXIcon } from '../../style/icons'
import { BOX_SHADOW } from '../../style/reusables'
import { InputDropdown } from '../SearchResults/SearchInput/InputDropdown'
import { InsuranceList } from '../SearchResults/SearchInput/InsuranceList'
import { useGetInsurance } from '../SearchResults/hooks/useGetInsurance'
import { useGetSearchParams } from '../SearchResults/hooks/useGetSearchParams'

export const InsuranceChipInput: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const insuranceData = useGetInsurance()
  const query = useGetSearchParams()
  const { insuranceInput, setInsuranceInput, zipInput, cityInput } =
    useChipInputValues()

  const [displayInsuranceName, setDisplayInsuranceName] = useState(false)

  useEffect(() => {
    setInsuranceInput(query.insurance || 'Uninsured')
  }, [])

  const updateInsuranceInput = (value: string) => {
    setInsuranceInput(value)
    setDisplayInsuranceName(false)

    history.replace({
      pathname: location.pathname,
      // @ts-ignore
      search: generateURLParams({
        ...query,
        insurance: value,
        zip: zipInput,
        city: cityInput,
      }),
    })
  }
  const removeInsurance = () => {
    setInsuranceInput('')
    history.replace({
      pathname: location.pathname,
      // @ts-ignore
      search: generateURLParams({
        ...query,
        insurance: null,
        zip: zipInput,
        city: cityInput,
      }),
    })
  }

  return (
    <Flex position="relative">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          style={{ height: '40px', width: '48px' }}
        >
          <ChipInputIcon />
        </InputLeftElement>
        <Input
          style={{
            maxWidth: '180px',
            height: '40px',
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '10px 32px 10px 42px',
            borderRadius: '4px',
            border: '1px solid #077db4',
            color: '#077db4',
          }}
          variant="search"
          type="text"
          name="insurance_name"
          onClick={() => {
            setDisplayInsuranceName(!displayInsuranceName)
          }}
          placeholder="Insurance Name"
          autoComplete="off"
          {...BOX_SHADOW}
          value={insuranceInput}
          onChange={(event) => {
            setInsuranceInput(event?.target?.value)
          }}
        />
        {displayInsuranceName && insuranceData.length > 0 && (
          <InputDropdown>
            {insuranceData
              .filter(
                ({ name }) =>
                  name.toLowerCase().indexOf(insuranceInput.toLowerCase()) > -1
              )
              .map((value, i) => (
                <InsuranceList
                  key={i}
                  item={value}
                  index={i}
                  onUpdate={updateInsuranceInput}
                />
              ))}
          </InputDropdown>
        )}
        {insuranceInput !== '' && (
          <InputRightElement
            style={{ cursor: 'pointer', height: '40px', width: '48px' }}
          >
            <div onClick={removeInsurance}>
              <ChipInputXIcon />
            </div>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
}
