import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { AdminLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'
import { ReactComponent as RobotIcon } from '../../admin/assets/images/robot.svg'

const AdminNav = (props) => {
  const history = useHistory()
  const location = useLocation()
  const initialValue = {
    dashboard: {
      value: 0,
    },
    revenue: {
      value: 0,
    },
    providers: {
      value: 0,
    },
    patients: {
      value: 0,
    },
    ratings: {
      value: 0,
    },
    subscriptions: {
      value: 0,
    },
    appointments: {
      value: 0,
    },
    websitecontent: {
      value: 0,
    },
    admin: {
      value: 0,
    },
    notifications: {
      value: 0,
    },
    insurance: {
      value: 0,
    },
    speciality: {
      value: 0,
    },
  }

  const [permissions, setPermissions] = useState(initialValue)
  const authContext = useContext(AuthContext)
  const toggleMenu = (e) => {
    e && e.preventDefault()
    authContext.toggleAdminMenu(authContext.adminMenuActive ? '' : 'activedash')
  }
  useEffect(() => {
    let data = authContext?.admin?.userPermissions
    let tempOtherData = permissions
    // Show only assigned modules to the admin
    for (let index = 0; index < data?.length; index++) {
      let temp = {}
      let element = data[index]

      if (element.id == 1) {
        temp = {
          dashboard: {
            ...permissions.dashboard,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }

      if (element.id == 2) {
        temp = {
          revenue: {
            ...permissions.revenue,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 3) {
        temp = {
          providers: {
            ...permissions.providers,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }

      if (element.id == 4) {
        temp = {
          patients: {
            ...permissions.patients,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 5) {
        temp = {
          ratings: {
            ...permissions.ratings,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 6) {
        temp = {
          subscriptions: {
            ...permissions.subscriptions,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 7) {
        temp = {
          appointments: {
            ...permissions.appointments,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 8) {
        temp = {
          websitecontent: {
            ...permissions.websitecontent,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 9) {
        temp = {
          admin: {
            ...permissions.admin,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 10) {
        temp = {
          notifications: {
            ...permissions.notifications,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 11) {
        temp = {
          insurance: {
            ...permissions.insurance,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
      if (element.id == 12) {
        temp = {
          speciality: {
            ...permissions.speciality,
            ...{
              value: 1,
            },
          },
        }
        tempOtherData = { ...tempOtherData, ...temp }
      }
    }

    // If sub admin is not assigned with the dashboard module then redirect to the next assigned module
    if (
      tempOtherData.dashboard.value == 0 &&
      location.pathname == AdminLinks.admin_dashboard
    ) {
      let redirection_url = AdminLinks.admin_dashboard
      if (tempOtherData.revenue.value == 1) {
        redirection_url = AdminLinks.admin_revenue
      } else if (tempOtherData.providers.value == 1) {
        redirection_url = AdminLinks.admin_provider_management
      } else if (tempOtherData.patients.value == 1) {
        redirection_url = AdminLinks.admin_patient_management
      } else if (tempOtherData.ratings.value == 1) {
        redirection_url = AdminLinks.admin_rating_review
      } else if (tempOtherData.subscriptions.value == 1) {
        redirection_url = AdminLinks.admin_subscription
      } else if (tempOtherData.appointments.value == 1) {
        redirection_url = AdminLinks.admin_appointments
      } else if (tempOtherData.websitecontent.value == 1) {
        redirection_url = AdminLinks.admin_content_management
      } else if (tempOtherData.admin.value == 1) {
        redirection_url = AdminLinks.admin_manage_sub_admin
      } else if (tempOtherData.notifications.value == 1) {
        redirection_url = AdminLinks.admin_notification
      } else if (tempOtherData.insurance.value == 1) {
        redirection_url = AdminLinks.admin_insurance
      } else if (tempOtherData.speciality.value == 1) {
        redirection_url = AdminLinks.admin_speciality
      }

      history.push({
        pathname: redirection_url,
      })
    }
    setPermissions(tempOtherData)
  }, [])

  return (
    <Fragment>
      <nav
        className={authContext.adminMenuActive + ' left-admin-section left-nav'}
      >
        <div className="closenav">
          <a className="crossicon" onClick={(e) => toggleMenu(e)}>
            <span></span>
          </a>
        </div>
        <div className="left-nav-inner">
          <ul className="nav-link-wrapper">
            {permissions?.dashboard?.value == 1 && (
              <li>
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link dashboard-icon"
                  to={AdminLinks.admin_dashboard}
                >
                  <i className="icon g-fill g-stroke"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}
            {permissions.revenue.value == 1 && (
              <li>
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link revenue-icon"
                  to={AdminLinks.admin_revenue}
                >
                  <i className="icon g-stroke"></i>
                  <span>Revenue</span>
                </NavLink>
              </li>
            )}

            {permissions.providers.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link provider-icon"
                  to={AdminLinks.admin_provider_management}
                >
                  <i className="icon fill path-stroke"></i>
                  <span>Providers</span>
                </NavLink>
              </li>
            )}

            {permissions.patients.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link patient-icon"
                  to={AdminLinks.admin_patient_management}
                >
                  <i className="icon g-stroke"></i>
                  <span>Patients</span>
                </NavLink>
              </li>
            )}

            {permissions.ratings.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link rating-icon"
                  to={AdminLinks.admin_rating_review}
                >
                  <i className="icon path-stroke"></i>
                  <span>Ratings &amp; Comments</span>
                </NavLink>
              </li>
            )}

            {permissions.subscriptions.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link subscription-icon"
                  to={AdminLinks.admin_subscription}
                >
                  <i className="icon path-stroke text-fill"></i>
                  <span>Subscription</span>
                </NavLink>
              </li>
            )}

            {permissions.appointments.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link appointments-icon"
                  to={AdminLinks.admin_appointments}
                >
                  <i className="icon path-stroke"></i>
                  <span>Appointments</span>
                </NavLink>
              </li>
            )}

            {permissions.websitecontent.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link website-icon"
                  to={AdminLinks.admin_content_management}
                >
                  <i className="icon g-stroke"></i>
                  <span>Website Content</span>
                </NavLink>
              </li>
            )}

            {permissions.admin.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link admin-icon"
                  to={AdminLinks.admin_manage_sub_admin}
                >
                  <i className="g-stroke icon path-fill"></i>
                  <span>Admin</span>
                </NavLink>
              </li>
            )}

            {permissions.notifications.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link notification-icon"
                  to={AdminLinks.admin_notification}
                >
                  <i className="icon path-fill path-stroke"></i>
                  <span>Notifications</span>
                </NavLink>
              </li>
            )}

            {permissions.insurance.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link insurance-icon"
                  to={AdminLinks.admin_insurance}
                >
                  <i className="icon path-stroke"></i>
                  <span>Insurances</span>
                </NavLink>
              </li>
            )}

            {permissions.speciality.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link speciality-icon"
                  to={AdminLinks.admin_speciality}
                >
                  <i className="icon g-stroke"></i>
                  <span>Specialty</span>
                </NavLink>
              </li>
            )}

            {permissions.websitecontent.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link website-icon"
                  to={AdminLinks.admin_doc_assist}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <RobotIcon />
                  <span>DocAssist Bot</span>
                </NavLink>
              </li>
            )}

            {permissions.websitecontent.value == 1 && (
              <li>
                <NavLink
                  activeClassName="active"
                  className="nav-link website-icon"
                  to={AdminLinks.upload_locations}
                >
                  <i className="icon g-stroke"></i>
                  <span>Upload locations</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </Fragment>
  )
}

export default AdminNav
