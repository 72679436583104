import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'
import CreateAccountStep from './create-acc-step'

const CreateAccountStep2 = (props) => {
  const history = useHistory()
  const authContext = useContext(AuthContext)

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [filterCities, setfilterCities] = useState([])
  const [zipCodes, setZipCodes] = useState([])
  const fnameErrorMsg = ['First Name is required', 'Invalid First Name']
  const mnameErrorMsg = ['Invalid Midle Name']
  const lnameErrorMsg = ['Last Name is required', 'Invalid Last Name']

  const monthErrorMsg = ['Month is required']
  const dateErrorMsg = ['Day is required']
  const yearErrorMsg = ['Year is required']
  const genderErrorMsg = ['Gender is required']
  const prferredpronounErrorMsg = ['Preferred Pronoun is required']
  const phoneErrorMsg = ['Phone is required', 'Invalid Number']
  const phonetypeErrorMsg = ['Phone Type is required']
  const addressErrorMsg = ['Address is required']
  const cityErrorMsg = ['City is required']
  const stateErrorMsg = ['State is required']
  const zipErrorMsg = ['Zipcode is required']
  const monthList = Global.getCurrentYear()
  const yearList = Global.getYearListAdult()

  const [errors, setErrors] = useState({
    efname: false,
    emname: false,
    elname: false,
    emonth: false,
    edate: false,
    eyear: false,
    egender: false,
    eprferredpronoun: false,
    ephone: false,
    ephonetype: false,
    eaddress: false,
    ecity: false,
    estate: false,
    ezipcode: false,
  })

  const [userData, setUserData] = useState({
    fname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: fnameErrorMsg,
      errorKey: 'efname',
    },
    mname: {
      value: '',
      validation: ['name'],
      errorMsg: mnameErrorMsg,
      errorKey: 'emname',
    },
    lname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: lnameErrorMsg,
      errorKey: 'elname',
    },
    month: {
      value: '',
      validation: ['blank'],
      errorMsg: monthErrorMsg,
      errorKey: 'emonth',
    },
    year: {
      value: '',
      validation: ['blank'],
      errorMsg: yearErrorMsg,
      errorKey: 'eyear',
    },
    date: {
      value: '',
      validation: ['blank'],
      errorMsg: dateErrorMsg,
      errorKey: 'edate',
    },
    gender: {
      value: '',
      validation: ['blank'],
      errorMsg: genderErrorMsg,
      errorKey: 'egender',
    },
    prferredpronoun: {
      value: '',
      validation: ['blank'],
      errorMsg: prferredpronounErrorMsg,
      errorKey: 'eprferredpronoun',
    },
    phone: {
      value: '',
      validation: ['blank', 'indianmobile'],
      errorMsg: phoneErrorMsg,
      errorKey: 'ephone',
    },
    phonetype: {
      value: 'cell',
      validation: ['blank'],
      errorMsg: phonetypeErrorMsg,
      errorKey: 'ephonetype',
    },
    address: {
      value: '',
      validation: [],
      errorMsg: addressErrorMsg,
      errorKey: 'eaddress',
    },
    city: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: cityErrorMsg,
      errorKey: 'ecity',
    },
    state: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: stateErrorMsg,
      errorKey: 'estate',
    },
    zipcode: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: zipErrorMsg,
      errorKey: 'ezipcode',
    },
    cityTemp: '',
  })
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const formDataSubmit = async () => {
    //    console.log('userData', userData);
    //    return false;
    const data = {
      firstName: userData.fname.value.trim(),
      middleName: userData.mname.value.trim(),
      lastName: userData.lname.value.trim(),
      dob:
        userData.month.value.toString().padStart(2, '0') +
        '-' +
        userData.date.value.toString().padStart(2, '0') +
        '-' +
        userData.year.value,
      gender: userData.gender.value.trim(),
      preferredPronoun: userData.prferredpronoun.value.trim(),
      phoneNumber: userData.phone.value.trim(),
      phoneType: userData.phonetype.value.trim(),
      // address: userData.address.value.trim(),
      // cityId: userData.cityTemp,
      // stateId: userData.state.value,
      // zipcode: userData.zipcode.value.trim(),
    }

    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'patients/demographic-information',
      body: JSON.stringify(data),
    }
    authContext.setLoader()
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    // console.log('result', result);
    if (result.body.status) {
      let user = result.body.result
      authContext.setUser(user)
      history.push({
        pathname: PatientLinks.register_step3,
      })
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
    // console.log('result', result.status);
  }
  const formSubmit = () => {
    // console.log('userData', userData);
    let errorFilds = []
    let cdate = new Date()
    let todayDate =
      cdate.getFullYear() +
      '-' +
      (parseInt(cdate.getMonth()) + 1).toString().padStart(2, '0') +
      '-' +
      cdate.getDate().toString().padStart(2, '0') +
      ' 00:00'
    let currentDate = new Date(todayDate).getTime()

    const validationResult = InputValidation(userData)
    // console.log( 'validationResult-->',validationResult )
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult })

      setTimeout(() => {
        errorFilds = document.querySelectorAll('.error-border')
        // console.log('errorFilds',errorFilds);
        if (errorFilds.length) {
          errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    } else {
      let userDob1 =
        userData.year.value +
        '-' +
        userData.month.value.toString().padStart(2, '0') +
        '-' +
        userData.date.value.toString().padStart(2, '0') +
        ' 00:00'
      let userDob = new Date(userDob1).getTime()
      //    console.log('current year', currentDate,'todayDate',todayDate, 'userDob1', userDob1, 'user dob', userDob);
      if (currentDate < userDob) {
        setErrors({ ...errors, ...{ eyear: 'Invalid Date of Birth' } })
        setTimeout(() => {
          errorFilds = document.querySelectorAll('.error-border')
          // console.log('errorFilds',errorFilds);
          if (errorFilds.length) {
            errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
          }
        }, 100)
        return false
      }
      formDataSubmit()
    }
  }
  const modifyPracticeData = async (e) => {
    let index,
      name,
      value,
      tempData = []

    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value

    tempData = userData
    tempData[name]['value'] = value
    tempData[name][name] = ''
    if (name === 'city') {
      tempData[name]['value'] = value
      cities.filter((city, city_index) => {
        if (city.id == value) {
          tempData['state']['value'] = city.stateId
          setErrors({ ...errors, ...{ estate: false } })
          return true
        }
      })
      setZipCodes(
        JSON.parse(e.target.selectedOptions[0].getAttribute('data-zipcode'))
      )
      //tempData['zipcode']['value'] = ;
    } else if (name === 'state') {
      tempData[name]['value'] = value
      authContext.setLoader()
      let cityResponse = await Global.getCities(value)
      authContext.unsetLoader()
      setfilterCities(cityResponse)
    } else if (name === 'zipcode') {
      tempData['zipcode']['value'] = value
      tempData['cityTemp'] =
        e.target.selectedOptions[0].getAttribute('data-city')
    }
    setUserData({ ...tempData })

    // formSubmit();
  }
  const getState_and_getCity = async () => {
    let stateResponse = await Global.getStates()
    setStates(stateResponse)
    /*let cityResponse = await Global.getCities();
        setCities(cityResponse);*/
    //setfilterCities(cityResponse);
  }
  useEffect(() => {
    getState_and_getCity()
    // console.log('check for account info', authContext.user.accountInfo);
    if (
      authContext.user &&
      Object.keys(authContext.user.accountInfo).length > 0
    ) {
      let accountInfo = authContext.user.accountInfo

      let dob = { year: '', month: '', date: '' }
      if (accountInfo.dob && accountInfo.dob != '') {
        let date = new Date(accountInfo.dob)

        if (date instanceof Date) {
          dob.year = date.getFullYear()
          dob.month = date.getMonth() + 1
          dob.date = date.getDate()
        }
      }
      let userFillData = {
        fname: {
          value: accountInfo.firstName ? accountInfo.firstName : '',
          validation: ['blank', 'name'],
          errorMsg: fnameErrorMsg,
          errorKey: 'efname',
        },
        mname: {
          value: accountInfo.middleName ? accountInfo.middleName : '',
          validation: ['name'],
          errorMsg: mnameErrorMsg,
          errorKey: 'emname',
        },
        lname: {
          value: accountInfo.lastName ? accountInfo.lastName : '',
          validation: ['blank', 'name'],
          errorMsg: lnameErrorMsg,
          errorKey: 'elname',
        },
        month: {
          value: dob.month,
          validation: ['blank'],
          errorMsg: monthErrorMsg,
          errorKey: 'emonth',
        },
        year: {
          value: dob.year,
          validation: ['blank'],
          errorMsg: yearErrorMsg,
          errorKey: 'eyear',
        },
        date: {
          value: dob.date,
          validation: ['blank'],
          errorMsg: dateErrorMsg,
          errorKey: 'edate',
        },
        gender: {
          value: accountInfo.gender ? accountInfo.gender : '',
          validation: ['blank'],
          errorMsg: genderErrorMsg,
          errorKey: 'egender',
        },
        prferredpronoun: {
          value: accountInfo.preferredPronoun
            ? accountInfo.preferredPronoun
            : '',
          validation: ['blank'],
          errorMsg: prferredpronounErrorMsg,
          errorKey: 'eprferredpronoun',
        },
        phone: {
          value: accountInfo.phoneNumber ? accountInfo.phoneNumber : '',
          validation: ['blank', 'indianmobile'],
          errorMsg: phoneErrorMsg,
          errorKey: 'ephone',
        },
        phonetype: {
          value: accountInfo.phoneType ? accountInfo.phoneType : 'cell',
          validation: ['blank'],
          errorMsg: phonetypeErrorMsg,
          errorKey: 'ephonetype',
        },
        // address: {
        //   value: accountInfo.address ? accountInfo.address : '',
        //   validation: [],
        //   errorMsg: addressErrorMsg,
        //   errorKey: 'eaddress',
        // },
        // city: {
        //   value: accountInfo.cityId ? accountInfo.cityId : '',
        //   validation: ['blank', 'number'],
        //   errorMsg: cityErrorMsg,
        //   errorKey: 'ecity',
        // },
        // state: {
        //   value: accountInfo.stateId ? accountInfo.stateId : '',
        //   validation: ['blank', 'number'],
        //   errorMsg: stateErrorMsg,
        //   errorKey: 'estate',
        // },
        // zipcode: {
        //   value: accountInfo.zipcode ? accountInfo.zipcode : '',
        //   validation: ['blank', 'number'],
        //   errorMsg: zipErrorMsg,
        //   errorKey: 'ezipcode',
        // },
      }
      setUserData({ ...userFillData })
    }
  }, [])
  return (
    <Fragment>
      {/* Form start step 1 */}
      <Suspense>
        <CreateAccountStep step="2" />
        <Container className="create-acc-steps-wrap">
          <div className="create-acc-inner-step2">
            <Row>
              {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
            </Row>
            <Row>
              <div className="col-12">
                <div className="seaprator-title">
                  <span>
                    <img
                      src="/assets/images/lock-icon.png"
                      className="scale lock"
                      alt="lock"
                    />{' '}
                    Encrypted Account Data
                  </span>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-md-4 mb-3">
                <label className="pro-label">First Name</label>
                <input
                  type="text"
                  name="fName"
                  className={
                    errors.efname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="First Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ efname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ efname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          fname: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'name'],
                            errorMsg: fnameErrorMsg,
                            errorKey: 'efname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.fname.value}
                />
                <small className="error-input">{errors.efname}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">Middle Name</label>
                <input
                  type="text"
                  name="mName"
                  className={
                    errors.emname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="Middle Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ emname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ emname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          mname: {
                            value: e.currentTarget.value,
                            validation: ['name'],
                            errorMsg: mnameErrorMsg,
                            errorKey: 'emname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.mname.value}
                />
                <small className="error-input">{errors.emname}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">Last Name</label>
                <input
                  type="text"
                  name="lName"
                  className={
                    errors.elname ? 'input-pro  error-border' : 'input-pro'
                  }
                  placeholder="Last Name"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ elname: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ elname: false } })}
                  onChange={(e) => {
                    if (/^[a-zA-Z-+()]*$/.test(e.currentTarget.value)) {
                      setUserData({
                        ...userData,
                        ...{
                          lname: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'name'],
                            errorMsg: lnameErrorMsg,
                            errorKey: 'elname',
                          },
                        },
                      })
                    }
                  }}
                  value={userData.lname.value}
                />
                <small className="error-input">{errors.elname}</small>
              </div>

              <div className="col-12">
                <label className="pro-label">Date of Birth</label>
              </div>
              <div className="col-sm-4 mb-3 mb-sm-0">
                <div className="myform">
                  <select
                    required
                    className={
                      errors.emonth ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ emonth: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ emonth: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          month: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: monthErrorMsg,
                            errorKey: 'emonth',
                          },
                        },
                      })
                    }
                    value={userData.month.value}
                  >
                    <option value="">Month</option>
                    {monthList.map((month, index_index) => (
                      <option key={month.id + '-month'} value={month.id}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.emonth}</small>
              </div>
              <div className="col-sm-4 mb-3 mb-sm-0">
                <div className="myform">
                  <select
                    required
                    className={errors.edate ? 'select  error-border' : 'select'}
                    onClick={() =>
                      setErrors({ ...errors, ...{ edate: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ edate: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          date: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: dateErrorMsg,
                            errorKey: 'edate',
                          },
                        },
                      })
                    }
                    value={userData.date.value}
                  >
                    <option value="">Day</option>
                    {[...new Array(31)].map((day, day_index) => (
                      <option
                        key={1 + day_index + '-days'}
                        value={day_index + 1}
                      >
                        {day_index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.edate}</small>
              </div>
              <div className="col-sm-4">
                <div className="myform">
                  <select
                    required
                    className={
                      errors.emonth ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ eyear: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ eyear: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          year: {
                            value: e.currentTarget.value,
                            validation: ['blank', 'number'],
                            errorMsg: yearErrorMsg,
                            errorKey: 'eyear',
                          },
                        },
                      })
                    }
                    value={userData.year.value}
                  >
                    <option value="">Year</option>
                    {yearList.reverse().map((year) => (
                      <option key={year + '-year'} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.eyear}</small>
              </div>
              <div className="col-12 mb-3">
                <div className="bday-use info-hg right">
                  <a href="/#" onClick={(e) => e.preventDefault()}>
                    How will my birthday be used?
                  </a>
                  <div className="info-callout right">
                    Providing your birthday helps DocScheduler customize your
                    healthcare experience and allows us to send your doctors
                    your information to help book appointments.
                  </div>
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="gender" className="pro-label">
                  Sex
                </label>
              </div>
              <div className="col-12 mb-3">
                <div className="radio-gender">
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      className={
                        errors.egender ? 'cus-radio error-border' : 'cus-radio'
                      }
                      checked={userData.gender.value == 'male' ? true : false}
                      onClick={() =>
                        setErrors({ ...errors, ...{ egender: false } })
                      }
                      onFocus={() =>
                        setErrors({ ...errors, ...{ egender: false } })
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ...{
                            gender: {
                              value: e.currentTarget.value,
                              validation: ['blank'],
                              errorMsg: fnameErrorMsg,
                              errorKey: 'egender',
                            },
                          },
                        })
                      }
                      value={'male'}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      checked={userData.gender.value == 'female' ? true : false}
                      onClick={() =>
                        setErrors({ ...errors, ...{ egender: false } })
                      }
                      onFocus={() =>
                        setErrors({ ...errors, ...{ egender: false } })
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ...{
                            gender: {
                              value: e.currentTarget.value,
                              validation: ['blank'],
                              errorMsg: fnameErrorMsg,
                              errorKey: 'egender',
                            },
                          },
                        })
                      }
                      value={'female'}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>
                <small className="error-input">{errors.egender}</small>
              </div>

              <div className="col-12">
                <label className="pro-label">Preferred Pronoun</label>
              </div>
              <div className="col-12 mb-4">
                <div className="radio-gender">
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="hhh"
                      name="prePronoun"
                      checked={
                        userData.prferredpronoun.value == 'he/him/his'
                          ? true
                          : false
                      }
                      onClick={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onFocus={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ...{
                            prferredpronoun: {
                              value: e.currentTarget.value,
                              validation: ['blank'],
                              errorMsg: prferredpronounErrorMsg,
                              errorKey: 'eprferredpronoun',
                            },
                          },
                        })
                      }
                      value={'he/him/his'}
                    />
                    <label htmlFor="hhh">he/him/his</label>
                  </div>
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="shh"
                      name="prePronoun"
                      checked={
                        userData.prferredpronoun.value == 'she/her/hers'
                          ? true
                          : false
                      }
                      onClick={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onFocus={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ...{
                            prferredpronoun: {
                              value: e.currentTarget.value,
                              validation: ['blank'],
                              errorMsg: prferredpronounErrorMsg,
                              errorKey: 'eprferredpronoun',
                            },
                          },
                        })
                      }
                      value={'she/her/hers'}
                    />
                    <label htmlFor="shh">she/her/hers</label>
                  </div>
                  <div className="cus-radio">
                    <input
                      type="radio"
                      id="ttt"
                      name="prePronoun"
                      checked={
                        userData.prferredpronoun.value == 'they/them/theirs'
                          ? true
                          : false
                      }
                      onClick={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onFocus={() =>
                        setErrors({ ...errors, ...{ eprferredpronoun: false } })
                      }
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          ...{
                            prferredpronoun: {
                              value: e.currentTarget.value,
                              validation: ['blank'],
                              errorMsg: prferredpronounErrorMsg,
                              errorKey: 'eprferredpronoun',
                            },
                          },
                        })
                      }
                      value={'they/them/theirs'}
                    />
                    <label htmlFor="ttt">they/them/theirs</label>
                  </div>
                </div>
                <small className="error-input">{errors.eprferredpronoun}</small>
              </div>

              <div className="col-md-6 mb-3">
                <label className="pro-label">Phone Number</label>
                <input
                  type="text"
                  name="pNumber"
                  className="input-pro"
                  placeholder="(###) ###-####"
                  autoComplete="off"
                  onClick={() => setErrors({ ...errors, ...{ ephone: false } })}
                  onFocus={() => setErrors({ ...errors, ...{ ephone: false } })}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        phone: {
                          value: Global.phoneFormator(e.currentTarget.value),
                          validation: ['blank', 'indianmobile'],
                          errorMsg: phoneErrorMsg,
                          errorKey: 'ephone',
                        },
                      },
                    })
                  }
                  value={userData.phone.value}
                />
                <small className="error-input">{errors.ephone}</small>
              </div>
              <div className="col-md-6 mb-3">
                <label className="pro-label">Phone Type</label>
                <div className="myform">
                  <select
                    required
                    className={
                      errors.ephonetype ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ ephonetype: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ ephonetype: false } })
                    }
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          phonetype: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: phonetypeErrorMsg,
                            errorKey: 'ephonetype',
                          },
                        },
                      })
                    }
                    value={userData.phonetype.value}
                  >
                    <option value="cell">Cell</option>
                    <option value="home">Home</option>
                  </select>
                </div>
                <small className="error-input">{errors.ephonetype}</small>
              </div>

              {/* <div className="col-12">
                <label className="pro-label">Address (Optional)</label>
                <textarea
                  name="address"
                  rows=""
                  className="input-pro textarea-ca"
                  placeholder="Enter Address"
                  onClick={() =>
                    setErrors({ ...errors, ...{ eaddress: false } })
                  }
                  onFocus={() =>
                    setErrors({ ...errors, ...{ eaddress: false } })
                  }
                  defaultValue={userData.address.value}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        address: {
                          value: e.currentTarget.value,
                          validation: [],
                          errorMsg: addressErrorMsg,
                          errorKey: 'eaddress',
                        },
                      },
                    })
                  }
                >
                  {/* {userData.address.value} */}
              {/* </textarea>
                <small className="error-input">{errors.eaddress}</small>
              </div> */}
              {/* <div className="col-12 mb-3">
                <div className="bday-use info-hg right">
                  <a href="/#" onClick={(e) => e.preventDefault()}>
                    How will my address be used?
                  </a>
                  <div className="info-callout right">
                    Providing your address helps DocScheduler find doctors
                    within your area and allows us to send your information to
                    doctors to help book appointments.
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-4 mb-3">
                <label className="pro-label">State</label>
                <div className="myform">
                  <select
                    required
                    className={
                      errors.estate ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ estate: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ estate: false } })
                    }
                    data-name="state"
                    onChange={(e) => {
                      modifyPracticeData(e)
                    }}
                    value={userData.state.value}
                  >
                    <option value="">Select State</option>
                    {states.map((state, state_index) => (
                      <option key={state.id + '-state'} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="error-input">{errors.estate}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">City</label>
                <div className="myform">
                  <select
                    required
                    className={errors.ecity ? 'select  error-border' : 'select'}
                    onClick={() =>
                      setErrors({ ...errors, ...{ ecity: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ ecity: false } })
                    }
                    data-name="city"
                    onChange={(e) => {
                      modifyPracticeData(e)
                    }}
                    value={userData.city.value}
                  >
                    <option value="">Select City</option>
                    {filterCities.citylist
                      ? Object.keys(filterCities.citylist).map((key) => (
                          <option
                            key={filterCities.citylist[key][0].id + '-city'}
                            data-zipcode={JSON.stringify(
                              filterCities.citylist[key]
                            )}
                            value={filterCities.citylist[key][0].id}
                          >
                            {key}
                          </option>
                        ))
                      : ''}
                  </select>
                </div>
                <small className="error-input">{errors.ecity}</small>
              </div>

              <div className="col-md-4 mb-3">
                <label className="pro-label">Zip Code</label>
                <div className="myform">
                  <select
                    required
                    className={
                      errors.ezipcode ? 'select  error-border' : 'select'
                    }
                    onClick={() =>
                      setErrors({ ...errors, ...{ ezipcode: false } })
                    }
                    onFocus={() =>
                      setErrors({ ...errors, ...{ ezipcode: false } })
                    }
                    data-name="zipcode"
                    onChange={(e) => {
                      modifyPracticeData(e)
                    }}
                    value={userData.zipcode.value}
                  >
                    <option value="">Select Zip Code</option>
                    {zipCodes.map((zip, key) => {
                      return (
                        <option
                          key={key}
                          value={zip.zip_code}
                          data-city={zip.id}
                        >
                          {zip.zip_code}
                        </option>
                      )
                    })}
                  </select>
                  <small className="error-input">{errors.ezipcode}</small>
                </div>
                {/*
                        <input type="text" readOnly={true} name="zCode" className="input-pro" placeholder="Zip Code" autoComplete="off" 
                                onClick={()=> setErrors({...errors, ...{ezipcode: false}})}
                                onFocus={()=> setErrors({...errors, ...{ezipcode: false}})}
                                onChange={(e)=>setUserData({
                                    ...userData,
                                    ...{
                                        zipcode: {
                                            value: e.currentTarget.value.trim(),
                                            validation: ['blank', 'number'],
                                            errorMsg: zipErrorMsg,
                                            errorKey: 'ezipcode',
                                            }
                                        }
                                    })
                                }
                                value={userData.zipcode.value}
                        />
                        */}
              {/* </div> */}

              <div className="col-12 mb-3 mb-md-5 mt-3 mt-md-4 text-center">
                <div className="next-prev-btn-pro">
                  {/* <a href="/#" data-prev-page={PatientLinks.register_setp1} onClick={(e)=>{ props.goPrevious(e) }}  className="prev-btn-pro">Previous</a>  */}
                  <input
                    type="button"
                    className="next-btn-pro"
                    onClick={() => {
                      formSubmit()
                    }}
                    value="Next"
                  />
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </Suspense>
    </Fragment>
  )
}

export default CreateAccountStep2
