import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import Management from '../../components/admin/provider/revenue-mngt'
import AuthContext from '../../reactLayer/auth/AuthContext'

const RevenueManagement = (props) => {
  const authContext = useContext(AuthContext)
  useEffect(() => {}, [])
  return (
    <Fragment>
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive +
            ' admin-dashboard right-admin-section patient-management-outer'
          }
        >
          <Management />
        </Container>
      </div>
    </Fragment>
  )
}

export default RevenueManagement
