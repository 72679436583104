import { useEffect, useState } from 'react'

const url = (lat, lng) =>
  'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
  lat +
  ',' +
  lng +
  '&key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo&sensor=true'

const parseGeoData = (data) => {
  const values = {}
  data.map((item) => {
    if (item.types.includes('locality') || item.types.includes('postal_town')) {
      values.city = item.long_name
    }
    if (item.types.includes('administrative_area_level_1')) {
      values.state = item.short_name
    }
    if (item.types.includes('postal_code')) {
      values.zipCode = item.short_name
    }
  })
  return values
}

export const useGetCityData = (coordinates) => {
  const lat = coordinates?.lat
  const lng = coordinates?.lng

  const [data, setData] = useState({ city: '', state: '', zipCode: '' })

  const fetchLocation = async () => {
    if (!lat) {
      return
    }
    try {
      const response = fetch(url(lat, lng))
      const data = await (await response).json()
      const parsedData = parseGeoData(data.results[0].address_components)
      setData(parsedData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchLocation()
  }, [lat])

  return data || { city: '', state: '', zipCode: '' }
}
