import React, { Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../reactLayer/auth/AuthContext'

import { ProviderLinks, PatientLinks } from '../../linkFile'

import HeaderMain from '../../global/header/header'
import Footer from '../../global/footer/footer'
import CreateAccountStep1 from '../../components/create-account/create-acc-step1'
import CreateAccountStep2 from '../../components/create-account/create-acc-step2'
import CreateAccountStep3 from '../../components/create-account/create-acc-step3'
import Error403 from '../../global/errorpages/error-403'
import Error404 from '../../global/errorpages/error-404'

import ProviderLogin from '../../components/provider-login/provider-login'

import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row, Col, Tabs } from 'react-bootstrap'

const PatientRegister = (props) => {
  const history = useHistory()
  const validLinks = [
    'account-information',
    'demographic-information',
    'insurance-information',
  ]
  const authContext = useContext(AuthContext)
  const user = authContext.user

  const goPrevious = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-prev-page')
    history.push({
      pathname: previousPage,
    })
  }
  const Validatepages = () => {
    if (validLinks.indexOf(props.match.params.type) > '-1') {
      if (props.match.params.type === 'account-information') {
        if (user == null || user.step) {
          return (
            <Container fluid className="provider-steps-wrap">
              <CreateAccountStep1 />
            </Container>
          )
        } else {
          if (
            user &&
            user.accountInfo.isProfileCompleted &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId === 4
          ) {
            history.push({
              pathname: PatientLinks.dashboard,
            })
          } else if (
            user &&
            user.accountInfo &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId !== 4
          ) {
            authContext.logout()
            history.push({
              pathname: PatientLinks.register_step1,
            })
          } else {
            if (user.step) {
              history.push({
                pathname: PatientLinks['register_step' + user.step],
              })
            } else {
              //  authContext.logout();
              return (
                <Container fluid className="provider-steps-wrap">
                  <CreateAccountStep1 />
                </Container>
              )
            }
          }
        }
      } else {
        if (
          user &&
          user.accountInfo.isProfileCompleted &&
          !user.accountInfo &&
          user.accountInfo.roleId &&
          user.accountInfo.roleId === 4
        ) {
          history.push({
            pathname: PatientLinks.dashboard,
          })
        } else if (
          (user &&
            user.accountInfo &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId != 4) ||
          !user
        ) {
          authContext.logout()
          history.push({
            pathname: PatientLinks.register_step1,
          })
        } else {
          if (props.match.params.type === 'demographic-information') {
            return (
              <Container fluid className="provider-steps-wrap">
                <CreateAccountStep2 {...{ goPrevious: goPrevious }} />
              </Container>
            )
          } else {
            return (
              <Container fluid className="provider-steps-wrap">
                <CreateAccountStep3 {...{ goPrevious: goPrevious }} />
              </Container>
            )
          }
        }
      }
    } else {
      return <Error404 />
    }
  }
  return (
    <Fragment>
      {/* header */}
      <Container className="header px-0">
        <HeaderMain />
      </Container>
      {Validatepages()}
      {/* {   
          props.match.params.type === 'account-information' ? <Container fluid className="provider-steps-wrap"><CreateAccountStep1/></Container>  : 
          props.match.params.type === 'demographic-information' ? <Container fluid className="provider-steps-wrap"><CreateAccountStep2 {...{goPrevious : goPrevious}} /></Container>  : 
          props.match.params.type === 'insurance-information' ? <Container fluid className="provider-steps-wrap"><CreateAccountStep3 {...{goPrevious : goPrevious}}/></Container>  : <></>
        } */}

      {/* footer */}
      <Footer />
    </Fragment>
  )
}

export default PatientRegister
