import { useEffect, useState } from 'react'
import NetworkLayer from '../../../../reactLayer/Network/Network'

export const useGetInsurance = () => {
  const [data, setData] = useState([])

  const fetchData = async () => {
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: 'home/insurance/get',
    }
    const result = await NetworkLayer.getRequest(submitData)
    if (
      // @ts-ignore
      result.result?.data.length > 0 ||
      // @ts-ignore
      result.result?.customInsuranceData > 0
    ) {
      const mergedData = [].concat(
        // @ts-ignore
        [...result?.result?.data],
        // @ts-ignore
        [...result?.result?.customInsuranceData]
      )
      setData(mergedData)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return data
}
