import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import Header from '../../global/header/header'

const ReactiveAccount = () => {
  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <Header />
      </Container>
      <Container className="my-account-wrap reactive-wrap">
        <Row>
          <div className="col-12">
            <h1>Subscription Cancelled</h1>
          </div>
          <Row className="position-relative pb-4 pb-md-5">
            <div className="col-12">
              <div className="my-acc-info-box">
                <div className="reactive-acc-txt">
                  <p>
                    This is confirmation that your subscription has been
                    cancelled at your request.
                  </p>
                  <p>
                    To start connecting your practice with patients digitally
                    again, you can reactivate your account at any time. We hope
                    you decide to come back soon.
                  </p>
                </div>
                <div className="reactive-btn">
                  <input
                    type="button"
                    className="next-btn-pro"
                    value="Reactivate Subscription"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ReactiveAccount
