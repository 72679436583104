import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import DashboardHead from '../../components/dashboard-head-tab/dashboard-head'
import DashboardProfile from '../../components/dashboard-profile/dashboard-profile'
import Error403 from '../../global/errorpages/error-403'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Appointments from './appointment'
import DigitalPage from './digital_page'
import SchedulePage from './scheduling_page'
// import Custommodal from '../../global/modal/modal';

const Dashboard = (props) => {
  const authContext = useContext(AuthContext)
  const [providerName, setProviderName] = useState()
  const [selectedProvider, setSelectedProvider] = useState()
  const [visibleComponent, setvisibleComponent] = useState(
    authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.subRoleId &&
      authContext.user.accountInfo.subRoleId === 5
      ? 'appointmentPage'
      : 'schedulePage'
  )
  const tabName = [
    { id: 'providerProfile', name: 'Provider Profile' },
    { id: 'schedulePage', name: 'Scheduling Manager' },
    { id: 'appointmentPage', name: 'Appointment Requests' },
    { id: 'digitalPage', name: 'Patient Surveys' },
  ]
  const [userprofile, setuserProfile] = useState({})
  const [scheduleTab, setscheduleTab] = useState(
    authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.subRoleId &&
      authContext.user.accountInfo.subRoleId === 5
      ? '2'
      : '1'
  )

  const getUserProfile = async () => {
    authContext.setLoader()
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'providers/profile-details/get',
    }
    let result = await NetworkLayer.getRequest(submitData)
    if (result.status) {
      setuserProfile(result.result)
    }
    authContext.unsetLoader()
  }

  const getProfile = async () => {
    let userId =
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.providerId
        ? authContext.user.accountInfo.providerId
        : authContext.user &&
          authContext.user.accountInfo &&
          authContext.user.accountInfo.managedProviders
        ? authContext.user.accountInfo.managedProviders[0].id
        : null
    if (userId) {
      authContext.setLoader()

      const formData = {}

      // STRUCTURE LOGIN REQUEST DATA
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/get/' + userId,
        body: JSON.stringify(formData),
      }

      // SEND REQUEST
      let result = await NetworkLayer.postData(submitData)
      // console.log('result in get profile by id', result);
      if (result.body.status) {
        // console.log("Child Provider ", result.body.result);
        setuserProfile(result.body.result)
      }
      authContext.unsetLoader()
    }
  }
  const redirectToSchedule = () => {
    setscheduleTab('2')
    setvisibleComponent('schedulePage')
  }
  useEffect(() => {
    if (
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.subRoleId &&
      authContext.user.accountInfo.subRoleId === 5
    ) {
      getProfile()
    } else {
      getUserProfile()
    }
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.response &&
      props.location.state.response == 'appointment-page'
    ) {
      setvisibleComponent('appointmentPage')
    }
  }, [])

  useEffect(() => {
    if (authContext?.user.accountInfo.managedProviders) {
      setSelectedProvider(authContext.user.accountInfo.managedProviders[0].id)
    }
  }, [])

  useEffect(() => {
    if (authContext.user.accountInfo?.managedProviders && selectedProvider) {
      let currentPro = authContext.user.accountInfo?.managedProviders?.find(
        (provider) => provider.id == selectedProvider
      )
      setProviderName(`${currentPro.firstName} ${currentPro.lastName}`)
    }
  }, [selectedProvider])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Dashboard</title>
        <meta
          name="description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/provider/dashboard"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/provider/dashboard`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content="DocScheduler | Dashboard" />
        <meta name="twitter:title" content="DocScheduler | Dashboard" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <meta
          name="twitter:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
      </Helmet>
      <HeaderMain />

      <DashboardHead
        managedProviders={authContext.user.accountInfo?.managedProviders}
        providerName={providerName}
        setSelectedProvider={setSelectedProvider}
        selectedProvider={selectedProvider}
        visibleComponent={visibleComponent}
        setvisibleComponent={setvisibleComponent}
        tabName={tabName}
        title={'Provider Dashboard'}
      />

      {visibleComponent === 'providerProfile' ? (
        <>
          {authContext.user &&
          authContext.user.accountInfo &&
          authContext.user.accountInfo.subRoleId &&
          authContext.user.accountInfo.subRoleId === 5 ? (
            <Error403 />
          ) : (
            <DashboardProfile
              redirectToSchedule={redirectToSchedule}
              visibleComponent={visibleComponent}
              setvisibleComponent={setvisibleComponent}
            />
          )}
        </>
      ) : visibleComponent === 'digitalPage' ? (
        <>
          {authContext.user &&
          authContext.user.accountInfo &&
          authContext.user.accountInfo.subRoleId &&
          authContext.user.accountInfo.subRoleId === 5 ? (
            <Error403 />
          ) : (
            <DigitalPage userprofile={userprofile} />
          )}
        </>
      ) : visibleComponent === 'schedulePage' &&
        userprofile.providerCurrentPlan ? (
        <SchedulePage
          selectedProvider={selectedProvider}
          setscheduleTab={setscheduleTab}
          userprofile={userprofile}
          tab={scheduleTab}
        />
      ) : visibleComponent === 'appointmentPage' ? (
        <Appointments
          selectedProvider={selectedProvider}
          userprofile={userprofile}
        />
      ) : (
        <></>
      )}

      <Footer />
    </Fragment>
  )
}

export default Dashboard
