import 'bootstrap/dist/css/bootstrap.css'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import useLocationMod from '../../hooks/useLocationMod'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const Header = () => {
  const authContext = useContext(AuthContext)
  const locationMod = useLocationMod()
  const history = useHistory()
  const headerSearch = useRef(null)
  const [menu] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [totalNotifications, setTotalNotifications] = useState(0)
  let intervalObject

  const RedirectToHome = () => {
    history.push({
      pathname: PatientLinks.login,
    })
  }

  const RedirectToSearch = () => {
    if (history.location?.pathname !== ProviderLinks.search_result) {
      authContext.setHeaderSearchText({
        text: searchText,
        actionSubmit: false,
      })
      history.push({
        pathname: ProviderLinks.search_result,
        state: {
          findHeaderTextFind: searchText ? searchText : '',
        },
      })
      locationMod.location.state = {
        findHeaderTextFind: searchText ? searchText : '',
      }
    } else {
      authContext.setHeaderSearchText({
        text: searchText,
        actionSubmit: true,
      })
    }
  }

  const checkKey = (e) => {
    if (e.key === 'Enter') {
      RedirectToSearch()
    }
  }

  const DoLogout = (e) => {
    e.preventDefault()
    authContext.logout()
    RedirectToHome()
  }

  const getNotificationCount = async () => {
    const userData = authContext.user
    if (userData.creditCardError !== true) {
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'patients/notifications/newnotifications',
      }

      const response = await NetworkLayer.getRequest(submitData)
      if (response.status === true) {
        if (response.result.newNotificationData) {
          if (response.result.newNotificationData.creditCardError === true) {
            userData.creditCardError = true
            authContext.setUser(userData)
            window.location.reload()
          }
          setTotalNotifications(response.result.newNotificationData.total)
        }
      }
    }
  }

  useEffect(() => {
    if (authContext.user) {
      if (authContext.user.creditCardError === true) {
        const locationValue = window.location.href.split('/')
        if (
          !locationValue.includes('manage-subscription') ||
          !locationValue.includes('upgrade-subscription')
        ) {
          history.push({
            pathname: ProviderLinks.accountprofile + '/manage-subscription',
          })
        }
      }
    }

    if (authContext.user) {
      getNotificationCount()
      intervalObject = setInterval(() => {
        getNotificationCount()
      }, 10000)
    }

    setSearchText(
      authContext?.headerSearchText?.text
        ? authContext?.headerSearchText?.text
        : ''
    )

    return () => {
      clearInterval(intervalObject)
    }
  }, [])

  return (
    <Fragment>
      {/* <SearchMenu /> */}
      <header>
        <Container>
          <Row>
            <div className="col-12">
              <div className="header-after-login">
                <div className="header-logo">
                  <Link to="/">
                    <div className="logo-main">
                      <img
                        src="/assets/images/logo-icon.svg"
                        className="logo-icon"
                        alt="DocScheduler"
                      />
                      <span>DocScheduler</span>
                    </div>
                  </Link>
                </div>
                {authContext.user && authContext?.user?.isProfileCompleted ? (
                  <>
                    {authContext.user.accountInfo.roleId === 3 &&
                    authContext.user.creditCardError !== true ? (
                      <>
                        <div className="header-search hide-for-mobile">
                          <input
                            ref={headerSearch}
                            onKeyUp={(e) => checkKey(e)}
                            type="text"
                            name="mInsName"
                            className="input-search"
                            placeholder="Search Provider"
                            autoComplete="off"
                            value={searchText}
                            onChange={(e) => setSearchText(e?.target?.value)}
                          />
                          <input
                            type="submit"
                            name=""
                            onClick={RedirectToSearch}
                            value=""
                            className="search-btn"
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div
                      className={
                        menu ? 'header-right-log open-menu' : 'header-right-log'
                      }
                    >
                      <div className="account-icons-wrap">
                        <div className="dashboard-sec">
                          {authContext.user &&
                          authContext.user.accountInfo.roleId === 3 ? (
                            <>
                              {authContext.user.creditCardError === true ? (
                                ''
                              ) : (
                                <Link to={ProviderLinks.dashboard}>
                                  <div className="dash-board-icon"></div>
                                  <span className="menu-name">
                                    My Health Dashboard
                                  </span>
                                </Link>
                              )}
                            </>
                          ) : (
                            <>
                              <Link to={PatientLinks.dashboard}>
                                <div className="dash-board-icon"></div>
                                <span className="menu-name">
                                  My Health Dashboard
                                </span>
                              </Link>
                            </>
                          )}
                        </div>
                        <div className="notification-sec">
                          <Link
                            to={
                              authContext.user &&
                              authContext.user.accountInfo.roleId === 3
                                ? ProviderLinks.notification
                                : PatientLinks.notification
                            }
                          >
                            <div className="notify-icon">
                              {totalNotifications !== 0 ? (
                                <div className="notification-circle">
                                  {totalNotifications}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <span className="menu-name">Notifications</span>
                          </Link>
                        </div>
                        <div className="account-sec">
                          <span className="menu-arrow-icon">
                            <div className="myacc-icon"></div>
                            <span className="menu-name">My Account</span>
                          </span>
                          <div className="home-menu">
                            <div className="home-menu-inner">
                              {authContext.user &&
                              authContext.user.accountInfo.roleId === 3 ? (
                                <>
                                  {!authContext.user.accountInfo.subRoleId ||
                                  authContext.user.accountInfo.subRoleId !==
                                    5 ? (
                                    <>
                                      <Link to={ProviderLinks.accountprofile}>
                                        <span>Account Info</span>
                                      </Link>
                                      {authContext.user.accountInfo
                                        .subRoleId !== 5 && (
                                        <Link
                                          to={ProviderLinks.accountsecurity}
                                        >
                                          <span>Security</span>
                                        </Link>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Link to={PatientLinks.accountprofile}>
                                    <span>Account Info</span>
                                  </Link>
                                  <Link to={PatientLinks.accountsecurity}>
                                    <span>Security</span>
                                  </Link>
                                </>
                              )}

                              <a href="/#" onClick={(e) => DoLogout(e)}>
                                <span>Sign-out</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {
                      // RedirectToHome()
                    }
                  </>
                )}
              </div>
              {authContext.user && authContext.user.accountInfo.roleId === 3 ? (
                <>
                  <div className="header-search hide-for-tablet hide-for-desktop">
                    <input
                      ref={headerSearch}
                      onKeyUp={(e) => checkKey(e)}
                      type="text"
                      name="mInsName"
                      className="input-search"
                      placeholder="Search Provider"
                      autoComplete="off"
                      value={searchText}
                      onChange={(e) => setSearchText(e?.target?.value)}
                    />
                    <input
                      type="submit"
                      name=""
                      onClick={RedirectToSearch}
                      value=""
                      className="search-btn"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Row>
        </Container>
      </header>
    </Fragment>
  )
}

export default Header
