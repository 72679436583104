import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'
import ClaimProfileModal from './claim-profile-modal'
import ProviderStep from './provider-steps'

const ProviderStep1 = () => {
  const baseUrl = process.env.REACT_APP_BASEURL
  const authContext = useContext(AuthContext)

  const history = useHistory()
  const fnameErrorMsg = ['First Name is required', 'Invalid First Name']
  const mnameErrorMsg = ['Invalid Midle Name']
  const lnameErrorMsg = ['Last Name is required', 'Invalid Last Name']
  const emailErrorMsg = ['Email is required', 'Invalid Email']
  const passwordErrorMsg = ['Password is required', 'Invalid Password']
  const phoneErrorMsg = ['Phone is required', 'Invalid Number']
  const genderErrorMsg = ['Gender is required']
  const extensionErrorMsg = ['Extension is required', 'Invalid Number']
  const phonetypeErrorMsg = ['Phone Type is required']
  const npinumberErrorMsg = ['NPI is required', 'Invalid Number']
  const medicalnumberErrorMsg = [
    'Medical License Number is required',
    'Invalid Medical License Number',
  ]
  const [passwordInputType, setpasswordInputType] = useState('password')
  const changepasswordInputType = () => {
    setpasswordInputType(passwordInputType === 'text' ? 'password' : 'text')
  }
  const [userData, setUserData] = useState({
    id: {
      value: '',
    },
    fname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: fnameErrorMsg,
      errorKey: 'fname',
      fname: false,
    },
    mname: {
      value: '',
      validation: ['name'],
      errorMsg: mnameErrorMsg,
      errorKey: 'mname',
      mname: false,
    },
    lname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: lnameErrorMsg,
      errorKey: 'lname',
      lname: false,
    },
    email: {
      value: '',
      validation: ['blank', 'email'],
      errorMsg: emailErrorMsg,
      errorKey: 'email',
      email: false,
    },
    password: {
      value: '',
      validation: ['blank', 'password'],
      errorMsg: passwordErrorMsg,
      errorKey: 'password',
      password: false,
    },
    phone: {
      value: '',
      validation: ['blank', 'indianmobile'],
      errorMsg: phoneErrorMsg,
      errorKey: 'phone',
      phone: false,
    },
    phonetype: {
      value: 'cell',
      validation: ['blank'],
      errorMsg: phonetypeErrorMsg,
      errorKey: 'phonetype',
      phonetype: false,
    },
    gender: {
      value: '',
      validation: ['blank'],
      errorMsg: genderErrorMsg,
      errorKey: 'gender',
      gender: false,
    },
    npinumber: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: npinumberErrorMsg,
      errorKey: 'npinumber',
      npinumber: false,
    },
    medicalnumber: {
      value: '',
      validation: ['blank', 'alphanumeric'],
      errorMsg: medicalnumberErrorMsg,
      errorKey: 'medicalnumber',
      medicalnumber: false,
    },
  })
  const [type, setType] = useState('success')
  const [claimProfileModalShow, setClaimProfileModalShow] = useState(false)
  const [claimProfileData, setClaimProfileData] = useState({})
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const redirectTostep2 = () => {}
  const formSubmit = () => {
    let tempuserData = userData
    const validationResult = InputValidation(userData)
    // console.log( 'validationResult-->',validationResult )
    if (Object.keys(validationResult).length > 0) {
      for (const prop in validationResult) {
        tempuserData[prop][prop] = `${validationResult[prop]}`
      }
      setUserData({ ...tempuserData })
      setTimeout(() => {
        let errorFilds = document.querySelectorAll('.error-border')
        // console.log('errorFilds',errorFilds);
        if (errorFilds.length) {
          errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    } else {
      formDataSubmit()
      //    console.log('submit successfully');
    }
  }

  const formDataSubmit = async (check) => {
    const data = {
      email: userData.email.value.trim(),
      password: userData.password.value.trim(),
      role: 1,
      firstName: userData.fname.value.trim(),
      middleName: userData.mname.value.trim(),
      lastName: userData.lname.value.trim(),
      gender: userData.gender.value.trim(),
      phoneNumber: userData.phone.value.trim(),
      phoneType: userData.phonetype.value.trim(),
      nationalProviderIdentifierNumber: userData.npinumber.value.trim(),
      medicalLicenseNumber: userData.medicalnumber.value.trim(),
    }

    if (check) {
      data.continue_with_new = 'yes'
    }

    const submitData = {
      url: 'providers/signup/account-information',
      body: JSON.stringify(data),
    }
    authContext.setLoader()
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      if (result.body.result.claim_profile) {
        setClaimProfileModalShow(true)
        setClaimProfileData(result.body.result.providerData)
        authContext.showModal()
      } else {
        let user = result.body.result.data
        let token = result.body.result.token
        authContext.setToken(token)
        authContext.setUser(user)

        history.push({
          pathname: ProviderLinks.register_step2,
        })
      }
    } else {
      if (result.body.alreadyRegister) {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
          btnname: 'Sign In',
          redirection: ProviderLinks.login,
        })
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }

  const forgotPasswordSubmit = async () => {
    authContext.setLoader()
    let data = {
      email: claimProfileData.accountInfo.email
        ? claimProfileData.accountInfo.email
        : userData.email.value.trim(),
      id: claimProfileData.accountInfo.id,
      nationalProviderIdentifierNumber:
        claimProfileData.accountInfo.nationalProviderIdentifierNumber,
    }
    let url = 'forgot-password-claimprofile'
    const submitData = {
      url: url,
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()
    authContext.hideModal()
    setClaimProfileModalShow(false)
    if (result.body.status) {
      setmodalProps({
        type: 'success',
        msg: result.body.message,
        btnname: 'Sign In',
        redirection: ProviderLinks.login,
      })
      authContext.showResponseModal()
      setTimeout(() => {
        history.push({
          pathname: ProviderLinks.login,
        })
      }, 2000)
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const claimProfileResponse = (response) => {
    if (response === 'continue') {
      authContext.hideModal()
      formDataSubmit(true)
      setTimeout(() => {
        setClaimProfileModalShow(false)
      }, 1000)
    } else {
      forgotPasswordSubmit()
    }
  }

  useEffect(() => {
    if (authContext.user) {
      let user = authContext.user.accountInfo
      setUserData({
        id: {
          value: user.id,
        },
        fname: {
          value: user.firstName,
          validation: ['blank', 'name'],
          errorMsg: fnameErrorMsg,
          errorKey: 'fname',
          fname: false,
        },
        mname: {
          value: user.middleName,
          validation: ['name'],
          errorMsg: mnameErrorMsg,
          errorKey: 'mname',
          mname: false,
        },
        lname: {
          value: user.lastName,
          validation: ['blank', 'name'],
          errorMsg: lnameErrorMsg,
          errorKey: 'lname',
          lname: false,
        },
        email: {
          value: user.email,
          validation: ['blank', 'email'],
          errorMsg: emailErrorMsg,
          errorKey: 'email',
          email: false,
        },
        password: {
          value: '',
          validation: ['blank', 'password'],
          errorMsg: passwordErrorMsg,
          errorKey: 'password',
          password: false,
        },
        phone: {
          value: user.phoneNumber,
          validation: ['blank', 'indianmobile'],
          errorMsg: phoneErrorMsg,
          errorKey: 'phone',
          phone: false,
        },
        phonetype: {
          value: user.phoneType,
          validation: ['blank'],
          errorMsg: phonetypeErrorMsg,
          errorKey: 'phonetype',
          phonetype: false,
        },
        gender: {
          value: user.gender,
          validation: ['blank'],
          errorMsg: genderErrorMsg,
          errorKey: 'gender',
          gender: false,
        },
        npinumber: {
          value: user.nationalProviderIdentifierNumber,
          validation: ['blank', 'number'],
          errorMsg: npinumberErrorMsg,
          errorKey: 'npinumber',
          npinumber: false,
        },
        medicalnumber: {
          value: user.medicalLicenseNumber,
          validation: ['blank', 'alphanumeric'],
          errorMsg: medicalnumberErrorMsg,
          errorKey: 'medicalnumber',
          medicalnumber: false,
        },
      })
    }
  }, [modalProps])

  return (
    <Fragment>
      {/* Form start step 1 */}
      <Container fluid className="provider-steps-wrap">
        <ProviderStep />
      </Container>
      {claimProfileModalShow ? (
        <Custommodal
          componentName={
            <ClaimProfileModal
              claimProfileData={claimProfileData}
              responseHandler={claimProfileResponse}
            />
          }
        />
      ) : (
        ''
      )}
      <Container className="provider-form-inner">
        {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        <form>
          <Row>
            <div className="col-md-4 mb-3 mb-3">
              <label htmlFor="fName" className="pro-label">
                First Name
              </label>
              <input
                type="text"
                name="fName"
                className={
                  userData.fname.fname ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="First Name"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      fname: {
                        value: e.currentTarget.value,
                        validation: ['blank', 'name'],
                        errorMsg: fnameErrorMsg,
                        errorKey: 'fname',
                        fname: false,
                      },
                    },
                  })
                }
                value={userData.fname.value}
              />
              <small className="error-input">{userData.fname.fname}</small>
            </div>
            <div className="col-md-4 mb-3 mb-3">
              <label htmlFor="mName" className="pro-label">
                Middle Name
              </label>
              <input
                type="text"
                name="mName"
                className={
                  userData.mname.mname ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="Middle Name"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      mname: {
                        value: e.currentTarget.value,
                        validation: ['name'],
                        errorMsg: mnameErrorMsg,
                        errorKey: 'mname',
                        mname: false,
                      },
                    },
                  })
                }
                value={userData.mname.value}
              />
              <small className="error-input">{userData.mname.mname}</small>
            </div>
            <div className="col-md-4 mb-3 mb-3">
              <label htmlFor="lName" className="pro-label">
                Last Name
              </label>
              <input
                type="text"
                name="lName"
                className={
                  userData.lname.lname ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="Last Name"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      lname: {
                        value: e.currentTarget.value,
                        validation: ['blank', 'name'],
                        errorMsg: lnameErrorMsg,
                        errorKey: 'lname',
                        lname: false,
                      },
                    },
                  })
                }
                value={userData.lname.value}
              />
              <small className="error-input">{userData.lname.lname}</small>
            </div>
            <div className="col-md-12 mb-3 mb-3">
              <label htmlFor="wMail" className="pro-label">
                Email
              </label>
              <input
                type="text"
                name="wMail"
                className={
                  userData.email.email ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="Email required for account verification"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      email: {
                        value: e.currentTarget.value,
                        validation: ['blank', 'email'],
                        errorMsg: emailErrorMsg,
                        errorKey: 'email',
                        email: false,
                      },
                    },
                  })
                }
                value={userData.email.value}
              />
              <small className="error-input">{userData.email.email}</small>
            </div>
            <div className="col-md-12 mb-2">
              <label htmlFor="password" className="pro-label">
                Create a Password
              </label>
              <input
                type={passwordInputType}
                name="password"
                className={
                  userData.password.password
                    ? 'input-pro  error-border'
                    : 'input-pro'
                }
                placeholder=" "
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      password: {
                        value: e.currentTarget.value,
                        validation: ['blank', 'password'],
                        errorMsg: passwordErrorMsg,
                        errorKey: 'password',
                        password: false,
                      },
                    },
                  })
                }
                value={userData.password.value}
              />
              <div
                className="eye-icon"
                onClick={() => {
                  changepasswordInputType()
                }}
              >
                <div
                  className={
                    passwordInputType === 'text'
                      ? 'pass-show-eye-icon'
                      : 'pass-hide-eye-icon'
                  }
                ></div>
              </div>
              <small className="error-input">
                {userData.password.password}
              </small>
            </div>
            <div className="col-md-12 mb-3 mb-3">
              <div className="pass-rules">
                <div className="head-rules">Password Rules:</div>
                <ul>
                  <li>Has at least 8 characters</li>
                  <li>
                    Must contain letters, at least 1 number, and at least 1
                    special character
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="gender" className="pro-label">
                Sex
              </label>
            </div>
            <div className="col-md-12 mb-3">
              <div className="radio-gender">
                <div
                  className={
                    userData.gender.gender
                      ? 'cus-radio error-border'
                      : 'cus-radio'
                  }
                >
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          gender: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: genderErrorMsg,
                            errorKey: 'gender',
                            gender: false,
                          },
                        },
                      })
                    }
                    value="male"
                    checked={userData.gender.value == 'male' ? true : false}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div className="cus-radio">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        ...{
                          gender: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: genderErrorMsg,
                            errorKey: 'gender',
                            gender: false,
                          },
                        },
                      })
                    }
                    value="female"
                    checked={userData.gender.value == 'female' ? true : false}
                  />
                  <label htmlFor="female">Female</label>
                </div>
              </div>
              <small className="error-input">{userData.gender.gender}</small>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="pNumber" className="pro-label">
                Phone Number
              </label>
              <input
                type="text"
                name="pNumber"
                className={
                  userData.phone.phone ? 'input-pro  error-border' : 'input-pro'
                }
                placeholder="(###) ###-####"
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      phone: {
                        value: Global.phoneFormator(e.currentTarget.value),
                        validation: ['blank', 'indianmobile'],
                        errorMsg: phoneErrorMsg,
                        errorKey: 'phone',
                        phone: false,
                      },
                    },
                  })
                }
                value={userData.phone.value}
              />
              <small className="error-input">{userData.phone.phone}</small>
            </div>

            <div className="col-md-6 mb-5">
              <label htmlFor="phType" className="pro-label">
                Phone Type
              </label>
              <div className="myform">
                <select
                  required
                  className={
                    userData.phonetype.phonetype
                      ? 'select  error-border'
                      : 'select'
                  }
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      ...{
                        phonetype: {
                          value: e.currentTarget.value,
                          validation: ['blank'],
                          errorMsg: phonetypeErrorMsg,
                          errorKey: 'phonetype',
                          phonetype: false,
                        },
                      },
                    })
                  }
                  value={userData.phonetype.value}
                >
                  <option value="">Phone Type</option>
                  <option value="cell">Cell</option>
                  <option value="home">Office</option>
                </select>
              </div>
              <small className="error-input">
                {userData.phonetype.phonetype}
              </small>
            </div>
            <div className="col-md-12 mb-4">
              <div className="medical-info">
                <span>Medical Verification Information</span>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="npiNumber" className="pro-label">
                National Provider Identifier (NPI) Number
              </label>
              <input
                type="text"
                name="npiNumber"
                className={
                  userData.npinumber.npinumber
                    ? 'input-pro  error-border'
                    : 'input-pro'
                }
                placeholder=" "
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      npinumber: {
                        value: !isNaN(e.currentTarget.value)
                          ? e.currentTarget.value
                          : e.currentTarget.value.slice(0, -1),
                        validation: ['blank', 'number'],
                        errorMsg: npinumberErrorMsg,
                        errorKey: 'npinumber',
                        npinumber: false,
                      },
                    },
                  })
                }
                value={userData.npinumber.value}
              />
              <small className="error-input error-input-stick">
                {userData.npinumber.npinumber}
              </small>
              <span className="input-bottom-link info-hg right">
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  How will my NPI # be used?
                </a>
                <div className="info-callout right">
                  Providing your NPI number allows DocScheduler to verify that
                  you are a HIPAA–covered entity/person.
                </div>
              </span>
            </div>
            <div className="col-md-12 mb-5">
              <label htmlFor="mlNumber" className="pro-label">
                Medical License Number
              </label>
              <input
                type="text"
                name="mlNumber"
                className={
                  userData.medicalnumber.medicalnumber
                    ? 'input-pro  error-border'
                    : 'input-pro'
                }
                placeholder=" "
                autoComplete="off"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    ...{
                      medicalnumber: {
                        value: e.currentTarget.value,
                        validation: ['blank', 'alphanumeric'],
                        errorMsg: npinumberErrorMsg,
                        errorKey: 'medicalnumber',
                        medicalnumber: false,
                      },
                    },
                  })
                }
                value={userData.medicalnumber.value}
              />
              <small className="error-input error-input-stick">
                {userData.medicalnumber.medicalnumber}
              </small>
              <span className="input-bottom-link info-hg right">
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  How will my medical license # be used?
                </a>
                <div className="info-callout right">
                  Providing your medical license number allows DocScheduler to
                  verify that your license is valid and active.
                </div>
              </span>
            </div>
            <div className="col-md-12 mb-5 text-center">
              <input
                type="button"
                className="next-btn-pro"
                onClick={() => {
                  formSubmit()
                }}
                value="Next"
              />
            </div>
          </Row>
        </form>
      </Container>
    </Fragment>
  )
}

export default ProviderStep1
