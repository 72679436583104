import React, { useState } from 'react'
import { InputWithLocationSearch } from './InputWithLocationSearch'
import { Box, Button, Flex, useBreakpointValue } from '@chakra-ui/react'

import { Filter } from '../types'
import { useGetInsurance } from '../hooks/useGetInsurance'
import { useGetSearchParams } from '../hooks/useGetSearchParams'
import { FindInput } from './FindInput'
import { InsuranceInput } from './InsuranceInput'
import { MobileSearchWrapper } from './MobileSearchWrapper'
import { mobileVisibility } from '../../../style/reusables'

interface Props {
  filter: Filter
  setFilter: (filter: Filter) => void
  hasInsuranceInput: string
  hasExtendedFindInput: string
}

export const SearchInput: React.FC<Props> = ({
  filter,
  setFilter,
  hasExtendedFindInput,
}) => {
  const isMobile = useBreakpointValue({
    sm: true,
    md: true,
    lg: false,
  })
  const query = useGetSearchParams()

  const [presentMobileSearchMenu, setPresentMobileSearchMenu] = useState(false)

  const insuranceData = useGetInsurance()

  const updateFilter = () => {
    setFilter({
      ...filter,
      callApi: true,
      city: query.city,
      zip: query.zip,
      searchValue: query.find,
    })
  }

  return (
    <>
      {presentMobileSearchMenu && (
        <MobileSearchWrapper onClick={() => setPresentMobileSearchMenu(false)}>
          <>
            <FindInput
              query={query}
              hasExtendedFindInput={hasExtendedFindInput}
            />
            <InputWithLocationSearch />
            <InsuranceInput insuranceData={insuranceData} query={query} />
            <Box>
              <Button
                variant="search"
                onClick={() => {
                  updateFilter()
                  setPresentMobileSearchMenu(false)
                }}
              >
                Search
              </Button>
            </Box>
          </>
        </MobileSearchWrapper>
      )}
      <Flex gap={4} grow={2} justifyContent="space-evenly">
        <Box
          onClick={() => isMobile && setPresentMobileSearchMenu(true)}
          width="100%"
        >
          <FindInput
            query={query}
            hasExtendedFindInput={hasExtendedFindInput}
          />
        </Box>
        <Flex display={mobileVisibility} width="100%">
          <InputWithLocationSearch />
        </Flex>
        <Flex display={mobileVisibility} width="100%">
          <InsuranceInput insuranceData={insuranceData} query={query} />
        </Flex>
        <Box>
          <Button
            variant="search"
            onClick={() => {
              updateFilter()
            }}
          >
            Search
          </Button>
        </Box>
      </Flex>
    </>
  )
}
