import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { Row } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as SelectOptionIcon } from '../../../admin/assets/images/icon-dropdown.svg'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import InputValidation from '../../../reactLayer/validation'

const PersonalInformation = (props) => {
  const [scale, setScale] = useState(1.2)
  const editor = useRef(null)
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const [data, setData] = useState({})
  const [importFile, setImportFile] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [daysLeft, setDaysLeft] = useState(0)
  const [editedImage, setEditedImage] = useState(undefined)
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const [file, setFile] = useState('')
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    onDrop: (acceptedFiles) => {
      handleChange(acceptedFiles[0])
      setFile(acceptedFiles[0].name)
    },
  })

  const onSelect = (e) => {
    handleChange(e)
    setFile(e.target.files[0].name)
  }

  useEffect(() => {
    if (data.plan_end_date && data.plan_start_date) {
      const a = moment(data.plan_end_date.value)
      const b = moment(data.plan_start_date.value)
      const diff = a.diff(b, 'days') // 1
      setDaysLeft(diff)
    }
  }, [data])

  const changeInputValue = (type, value) => {
    let val = value
    if (type == 'nationalProviderIdentifierNumber') {
      if (!isNaN(val)) {
        val = value
      } else {
        val = value.slice(0, -1)
      }
    }
    setData({
      ...data,
      ...{
        [type]: {
          value: val,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: [type],
        },
      },
    })
  }

  const savePersonalInfo = async (event) => {
    event.preventDefault()
    let flag = true
    let validationResult = InputValidation(data)

    let tempCustIns = data
    let errorFields = []
    // return false;
    if (Object.keys(validationResult).length > 0) {
      for (const prop in validationResult) {
        tempCustIns[prop][prop] = `${validationResult[prop]}`

        if (prop !== ('middleName' && 'extension' && 'medicalLicenseNumber')) {
          errorFields.push(prop)
        }
      }
      document.getElementById(errorFields[0])?.focus()
      setData({ ...tempCustIns })
    }

    // return false;
    if (!flag) {
      return false
    }
    authContext.setLoader()
    // FORM DATA
    let datasubmit = {
      userId: props.providerDetails?.accountInfo?.id,
      firstName: data.firstName.value,
      middleName: data.middleName.value,
      lastName: data.lastName.value,
      phoneNumber: data.phoneNumber.value,
      email: data.email.value,
      gender: data.gender.value,
      extension: data.extension.value,
      phoneType: data.phoneType.value,
      nationalProviderIdentifierNumber:
        data.nationalProviderIdentifierNumber.value,
      medicalLicenseNumber: data.medicalLicenseNumber.value,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/providers/account-information/update',
      body: JSON.stringify(datasubmit),
    }
    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)

    if (result.body.code == 200) {
      props.callBack && props.callBack()
      successMsg('success', 'Record Updated Successfully.')
      authContext.unsetLoader()
    } else {
      successMsg('error', result.body.message)
      authContext.unsetLoader()
    }
  }

  const personalInfo = {
    firstName: {
      value: props.providerDetails?.accountInfo?.firstName,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'firstName',
    },
    middleName: {
      value: props.providerDetails?.accountInfo?.middleName,
      // validation: ['blank'],
      // errorMsg: ['This field is required'],
      // errorKey: 'middleName',
    },
    lastName: {
      value: props.providerDetails?.accountInfo?.lastName,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'lastName',
    },
    email: {
      value: props.providerDetails?.accountInfo?.email,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'email',
    },
    gender: {
      value: props.providerDetails?.accountInfo?.gender,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'gender',
    },
    phoneNumber: {
      value: props.providerDetails?.accountInfo?.phoneNumber,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'phoneNumber',
    },
    extension: {
      value: props.providerDetails?.accountInfo?.extension,
      // validation: ['blank'],
      // errorMsg: ['This field is required'],
      // errorKey: 'extension',
    },
    phoneType: {
      value: props.providerDetails?.accountInfo?.phoneType,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'phoneType',
    },
    nationalProviderIdentifierNumber: {
      value:
        props.providerDetails?.accountInfo?.nationalProviderIdentifierNumber,
      validation: ['blank', 'number'],
      errorMsg: ['This field is required'],
      errorKey: 'nationalProviderIdentifierNumber',
    },
    medicalLicenseNumber: {
      value: props.providerDetails?.accountInfo?.medicalLicenseNumber,
      // validation: ['blank', 'alphanumeric'],
      // errorMsg: ['This field is required', 'Invalid Medical License Number'],
      // errorKey: 'medicalLicenseNumber',
    },
    plan_start_date: {
      value: props.providerDetails?.providerCurrentPlan?.plan_start_date,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'plan_start_date',
    },
    plan_end_date: {
      value: props.providerDetails?.providerCurrentPlan?.plan_end_date,
    },
    planName: {
      value: props.providerDetails?.providerCurrentPlan?.planName,
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'planName',
    },
    planType: {
      value: props.providerDetails?.providerCurrentPlan?.paid_amount,
    },
    planCurrency: {
      value: props.providerDetails?.providerCurrentPlan?.paid_amount_currency,
    },
  }

  useEffect(() => {
    setData(personalInfo)
  }, [props])

  const successMsg = (type, message) => {
    setmodalProps({
      type: type,
      msg: message,
    })
    authContext.showResponseModal()
  }

  const goBack = () => {
    history.push({
      pathname: '../../provider/management',
    })
  }

  const handleChange = (event) => {
    let file = ''
    if (event?.target) {
      file = event.target.files[0]
    } else {
      file = event
    }

    setImportFile(file)
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => setImageUrl(String(reader.result)),
      false
    )
    reader.readAsDataURL(file)
  }

  const submitPhoto = async (blob) => {
    let url = 'admin/providers/account-information/upload'
    const formData = new FormData()

    formData.append('file', blob, 'userPhoto.jpeg')
    formData.append('userId', props.providerDetails?.accountInfo?.id)

    if (!importFile) {
      successMsg('error', 'Please select file')
      return false
    }

    const submitData = {
      headers: {
        'access-token': authContext.adminToken,
      },
      url: url,
      body: formData,
    }

    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        setFile(undefined)
        setImageUrl('')
        props.callBack && props.callBack()
      } else {
        successMsg('error', result.body.message)
      }
    } catch (err) {
      successMsg('success', 'Something went wrong.')
    }
    authContext.unsetLoader()
  }

  useEffect(() => {
    if (editedImage) {
      submitPhoto(editedImage)
    }
  }, [editedImage])

  const removePhoto = async () => {
    let url = 'admin/providers/account-information/upload'
    const formDataNew = new FormData()

    formDataNew.append('userId', props.providerDetails?.accountInfo?.id)

    const submitData = {
      headers: {
        'access-token': authContext.adminToken,
      },
      url: url,
      body: formDataNew,
    }

    authContext.setLoader()
    try {
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        setFile(undefined)
        setImageUrl('')
        props.callBack && props.callBack()
      } else {
        successMsg('error', result.body.message)
      }
    } catch (err) {
      successMsg('success', 'Something went wrong.')
    }
    authContext.unsetLoader()
  }

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value)
    setScale(scale)
  }

  const defaultImg =
    'https://docscheduler-stage.s3-us-west-2.amazonaws.com/default-user.png'

  return (
    <Fragment>
      <Row className="table-sort-filter">
        {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
        <div className="col-12 col-lg-9">
          <form className="provider-form-edit row mb-3">
            <div className="col-12 mb-3">
              <h6 className="pro-label">Image</h6>

              {!file && (
                <>
                  <img
                    style={{
                      objectFit: 'cover',
                      width: '120px',
                      height: '120px',
                      borderRadius: '50%',
                    }}
                    src={
                      props?.providerDetails?.accountInfo.profileImage
                        ? props?.providerDetails?.accountInfo.profileImage
                        : defaultImg
                    }
                    alt="profile-img"
                  />
                  <div
                    style={{
                      width: '120px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                    className="icons"
                  >
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '10px',
                      }}
                    >
                      <img
                        style={{
                          objectFit: 'cover',
                          cursor: 'pointer',
                        }}
                        src="/assets/images/plus-icon.png"
                        alt="add-img"
                      />
                    </div>
                    <img
                      onClick={removePhoto}
                      style={{
                        objectFit: 'cover',
                        width: '20px',
                        height: '21px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                      src="/assets/images/del-icon.png"
                      alt="delete-img"
                    />
                    <div
                      style={{
                        position: 'absolute',
                        width: '20px',
                        height: '20px',
                        left: '44px',
                      }}
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps()}
                        name="file"
                        onChange={onSelect}
                      />
                    </div>
                  </div>
                </>
              )}
              {file && (
                <div
                  style={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '80%',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <img
                      src="/assets/images/popup-close.png"
                      onClick={() => {
                        setFile(undefined)
                        setImageUrl('')
                      }}
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      background: '#000',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      width: '80%',
                      height: '220px',
                    }}
                  >
                    <AvatarEditor
                      style={{ position: 'absolute' }}
                      showGrid={true}
                      ref={editor}
                      image={imageUrl}
                      width={120}
                      height={120}
                      scale={scale}
                      rotate={0}
                      border={50}
                      borderRadius={80}
                      color={[0, 0, 0, 0.4]}
                      backgroundColor="#fff"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '60%',
                      marginTop: '10px',
                    }}
                  >
                    <span style={{ marginLeft: '10px' }}>Zoom</span>
                    <input
                      name="scale"
                      type="range"
                      onChange={handleScale}
                      min={'1'}
                      max="2"
                      step="0.01"
                      defaultValue="1.2"
                    />
                  </div>
                  <button
                    style={{
                      background: '#077db4',
                      color: '#fff',
                      borderRadius: '8px',
                      borderColor: 'transparent',
                      padding: '12px 36px',
                      marginTop: '8px',
                      marginBottom: '20px',
                    }}
                    type="button"
                    onClick={() => {
                      if (editor.current) {
                        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
                        // drawn on another canvas, or added to the DOM.
                        const canvas = editor.current.getImage()
                        canvas.toBlob(function (blob) {
                          setEditedImage(blob)
                        })
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-4 mb-3">
              <label className="pro-label">First Name</label>
              <input
                type="text"
                className="input-pro"
                id="firstName"
                placeholder="First Name"
                value={data?.firstName?.value}
                onChange={(e) =>
                  changeInputValue('firstName', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.firstName ? data.firstName.firstName : ''}
              </small>
            </div>
            <div className="col-md-4 mb-3">
              <label className="pro-label">Middle Name</label>
              <input
                type="text"
                className="input-pro"
                placeholder="Middle Name"
                value={data?.middleName?.value}
                onChange={(e) =>
                  changeInputValue('middleName', e.currentTarget.value)
                }
              />
              {/* <small className="error-input">
                {data.middleName ? data.middleName.middleName : ''}
              </small> */}
            </div>
            <div className="col-md-4 mb-3">
              <label className="pro-label">Last Name</label>
              <input
                type="text"
                className="input-pro"
                id="lastName"
                placeholder="Last Name"
                value={data?.lastName?.value}
                onChange={(e) =>
                  changeInputValue('lastName', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.lastName ? data.lastName.lastName : ''}
              </small>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Work Email Address</label>
              <input
                type="email"
                className="input-pro"
                id="email"
                placeholder="Work Email Address"
                value={data?.email?.value}
                onChange={(e) =>
                  changeInputValue('email', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.email ? data.email.email : ''}
              </small>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Sex</label>
              <label className="radio-button mt-2 mr-2" htmlFor="male">
                <input
                  type="radio"
                  name="sex"
                  id="male"
                  value={'male'}
                  checked={data?.gender?.value == 'male' ? 'Checked' : ''}
                  onChange={(e) =>
                    changeInputValue('gender', e.currentTarget.value)
                  }
                />
                <span className="radio-check"></span>
                Male
              </label>
              <label className="radio-button mt-2 mr-2" htmlFor="female">
                <input
                  type="radio"
                  name="sex"
                  id="female"
                  value={'female'}
                  checked={data?.gender?.value == 'female' ? 'Checked' : ''}
                  onChange={(e) =>
                    changeInputValue('gender', e.currentTarget.value)
                  }
                />
                <span className="radio-check"></span>
                Female
              </label>
            </div>
            <div className="col-md-5 mb-3">
              <label className="pro-label">Phone Number</label>
              <input
                type="tel"
                className="input-pro"
                placeholder="Phone Number"
                id="phoneNumber"
                value={data?.phoneNumber?.value}
                onChange={(e) =>
                  changeInputValue('phoneNumber', e.currentTarget.value)
                }
              />
              <small className="error-input">
                {data.phoneNumber ? data.phoneNumber.phoneNumber : ''}
              </small>
            </div>
            <div className="col-md-3 mb-3">
              <label className="pro-label">Extention</label>
              <input
                type="tel"
                className="input-pro"
                placeholder="Extention"
                id="extension"
                value={data?.extension?.value}
                onChange={(e) =>
                  changeInputValue('extension', e.currentTarget.value)
                }
              />
              {/* <small className="error-input">
                {data.extension ? data.extension.extension : ''}
              </small> */}
            </div>
            <div className="col-md-4 mb-3 select-option">
              <label className="pro-label">Phone Type</label>
              <select
                className="select"
                onChange={(e) =>
                  changeInputValue('phoneType', e.currentTarget.value)
                }
              >
                <option value="cell" selected={!data?.phoneType?.value}>
                  None
                </option>
                <option
                  value="cell"
                  selected={data?.phoneType?.value == 'cell' ? 'Checked' : ''}
                >
                  Cell Phone
                </option>
                <option
                  value="home"
                  selected={data?.phoneType?.value == 'home' ? 'Checked' : ''}
                >
                  Home Phone
                </option>
              </select>
              <i className="select-option-icon">
                <SelectOptionIcon />
              </i>
              <small className="error-input">
                {data.phoneType ? data.phoneType.phoneType : ''}
              </small>
            </div>
            <div className="heading-wrap mt-4 mb-4 col-12">
              <div className="table-heading mt-1">
                Medical Verification Information
              </div>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">
                National Provider Identifier (NPI) Number
              </label>
              <input
                type="tel"
                className="input-pro"
                id="nationalProviderIdentifierNumber"
                placeholder="National Provider Identifier (NPI) Number"
                value={data?.nationalProviderIdentifierNumber?.value}
                onChange={(e) =>
                  changeInputValue(
                    'nationalProviderIdentifierNumber',
                    e.currentTarget.value
                  )
                }
              />
              <small className="error-input">
                {data.nationalProviderIdentifierNumber
                  ? data.nationalProviderIdentifierNumber
                      .nationalProviderIdentifierNumber
                  : ''}
              </small>
              <div className="text-right font-13 mt-1">
                {/* <Link>How will my NPI # be used?</Link> */}
              </div>
            </div>
            <div className="col-12 mb-3">
              <label className="pro-label">Medical License Number</label>
              <input
                type="text"
                className="input-pro"
                id="medicalLicenseNumber"
                placeholder="Medical License Number"
                value={data?.medicalLicenseNumber?.value}
                onChange={(e) =>
                  changeInputValue(
                    'medicalLicenseNumber',
                    e.currentTarget.value
                  )
                }
              />
              {/* <small className="error-input">
                {data.medicalLicenseNumber
                  ? data.medicalLicenseNumber.medicalLicenseNumber
                  : ''}
              </small> */}
              <div className="text-right font-13 mt-1">
                {/* <Link>How will my medical license number # be used?</Link> */}
              </div>
            </div>
            <div className="heading-wrap mt-4 mb-3 col-12">
              <div className="table-heading">Plan & Payment</div>
            </div>
            <div className="col-12">
              Purchase Date:{' '}
              {moment(data?.plan_start_date?.value).format('d MMM, Y')}
            </div>
            <div className="col-12 plan-payment mt-3 mb-4">
              <Row>
                <div className="col-md-4 text-center blue-area mb-3 mb-md-0">
                  <div className="plan">Plan Name</div>
                  <div className="payment">
                    {data.planName
                      ? data.planName.value
                        ? data.planName.value
                        : 'N/A'
                      : '--'}
                  </div>
                </div>
                <div className="col-md-4 blue-area text-center mb-3 mb-md-0">
                  <div className="plan">Plan Type</div>
                  <div className="payment">
                    {data.planCurrency?.value
                      ? data.planCurrency?.value.toUpperCase()
                      : ''}{' '}
                    {data.planType?.value ? data.planType?.value : 'N/A'}
                  </div>
                </div>
                <div className="col-md-4 blue-area text-center">
                  <div className="plan">Plan Expiration</div>
                  {daysLeft > 0 ? (
                    <div className="payment">{daysLeft} Days Remaining</div>
                  ) : (
                    <div className="payment">
                      {data.plan_end_date ? (
                        data.plan_end_date.value ? (
                          <span>
                            Plan is expired on{' '}
                            {moment(data?.plan_end_date?.value).format(
                              'd MMM, Y'
                            )}
                          </span>
                        ) : (
                          <span>N/A</span>
                        )
                      ) : (
                        '--'
                      )}
                    </div>
                  )}
                </div>
              </Row>
            </div>
            <div className="action col-12 text-left mt-3">
              <Link className="link-btn link-btn-white mr-2" onClick={goBack}>
                Cancel
              </Link>
              <Link className="link-btn" onClick={(e) => savePersonalInfo(e)}>
                Save
              </Link>
            </div>
          </form>
        </div>
      </Row>
    </Fragment>
  )
}

export default PersonalInformation
