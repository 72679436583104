import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import EditName from '../../components/account-info/edit-name'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'

const AccountProfile = (props) => {
  const history = useHistory()

  const goToBack = (e) => {
    e.preventDefault()
    history.goBack()
  }
  const SetProfileName = () => {
    let name = ''
    if (
      props.match.params.type === 'profile-name' ||
      props.match.params.type === 'profile-birthday' ||
      props.match.params.type === 'profile-gender'
    ) {
      name = 'Profile Information'
    } else if (
      props.match.params.type === 'profile-phone' ||
      props.match.params.type === 'profile-email' ||
      props.match.params.type === 'profile-address'
    ) {
      name = 'Contact Information'
    } else if (
      props.match.params.type === 'manage-subscription' ||
      props.match.params.type === 'upgrade-subscription' ||
      props.match.params.type === 'manage-card' ||
      props.match.params.type === 'card-list'
    ) {
      name = 'Subscription & Payment Information'
    } else if (
      props.match.params.type === 'change-password' ||
      props.match.params.type === 'step-verification'
    ) {
      name = 'Signing into DocScheduler'
    } else if (
      props.match.params.type === 'recovery-phoneno' ||
      props.match.params.type === 'recovery-email' ||
      props.match.params.type === 'medical-npi' ||
      props.match.params.type === 'medical-license'
    ) {
      name = `Ways we can verify it's you`
    } else if (props.match.params.type === 'preferred-pronoun') {
      name = 'Profile Information'
    } else if (
      props.match.params.type === 'insurance-medical' ||
      props.match.params.type === 'insurance-dental' ||
      props.match.params.type === 'insurance-vision' ||
      props.match.params.type === 'insurance-child'
    ) {
      name = ` Insurance Information`
    } else if (props.match.params.type === 'delete-profile') {
      name = 'My Account'
    }
    return name
  }
  return (
    <Fragment>
      {/* header */}

      <HeaderMain />
      <Container>
        <Container className="my-account-wrap my-acc-inner">
          <Row>
            <div className="col-12">
              <h1>
                <SetProfileName />
              </h1>
              <div className="back-btn">
                <a
                  data-next-page="my-account"
                  onClick={(e) => {
                    goToBack(e)
                  }}
                  href="/#"
                >
                  <img
                    src="/assets/images/bk-arrow.svg"
                    className="bk-arrow"
                    alt="Back to My Account"
                  />{' '}
                  Back
                </a>
              </div>
            </div>
          </Row>
          {props.match.params.type === 'profile-name' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'profile-birthday' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'profile-gender' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'profile-phone' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'profile-address' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'profile-email' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'manage-subscription' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'upgrade-subscription' ? (
            <EditName
              {...{
                elementName: props.match.params.type,
                planId: props.match.params.cardId,
              }}
            />
          ) : props.match.params.type === 'manage-card' ? (
            <EditName
              {...{
                elementName: props.match.params.type,
                cardId: props.match.params.cardId,
              }}
            />
          ) : props.match.params.type === 'card-list' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'preferred-pronoun' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : //security tabs

          props.match.params.type === 'change-password' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'step-verification' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'recovery-phoneno' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'recovery-email' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'medical-npi' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'medical-license' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'insurance-medical' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'insurance-dental' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'insurance-vision' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'insurance-child' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : props.match.params.type === 'delete-profile' ? (
            <EditName {...{ elementName: props.match.params.type }} />
          ) : (
            <></>
          )}
        </Container>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default AccountProfile
