import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container } from 'react-bootstrap'

import Referfriend from '../../components/refer-friend/refer-friend'
import Footer from '../../global/footer/footer'
import HomeHeader from '../../global/header/header'

const ReferFriendPage = () => {
  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader />
      </Container>

      <Container fluid className="">
        <Referfriend />
      </Container>

      {/* footer */}
      <Container fluid className="footer">
        <Container>
          <Footer />
        </Container>
      </Container>
    </Fragment>
  )
}

export default ReferFriendPage
