import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import AuthContext from '../../../../reactLayer/auth/AuthContext'
import Custommodal from '../../../../global/modal/modal'
import AllFilterModal from '../../../../components/doctor-search-result/all-filter-modal'
import NoResultsModal from '../../../../components/search-results/no-result-modal'
import Loadermodal from '../../../../global/loader-modal/loader-modal'
import {
  SliderIcon,
  UserCheckIcon,
  VirtualRecorderIcon,
} from '../../../style/icons'
import { Typography } from '../../../style/typography'
import {
  getLangList,
  getMedicalSpeciality,
  getPatientsTreatedList,
} from './hooks'
import { Filter } from '../types'

interface Props {
  filter: Filter
  setFilter: (filter: Filter) => void
}

export const SearchFilters: React.FC<Props> = ({ filter, setFilter }) => {
  const authContext = useContext(AuthContext)

  const [visibleComponent, setVisibleComponent] = useState('')
  const [specialtyList, setSpecialtyList] = useState([])
  const [langList, setLangList] = useState([])
  const [listPatientsTreated, setListPatientsTreated] = useState([])

  const modalList = ['allFilterModal', 'invitemodal']

  const openModal = (e, modalName) => {
    e.preventDefault()
    setVisibleComponent(modalName)
    authContext.showModal()
  }

  const closeModal = (e) => {
    e && e.preventDefault()
    setVisibleComponent('')
    authContext.hideModal()
  }

  const changeOption = (value: string) => {
    if (value === 'hasVirtualVisit') {
      setFilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(String(0)) },
        ...{ hasVirtualVisit: filter?.hasVirtualVisit === 0 ? 1 : 0 },
      })
    }
    if (value === 'isAcceptingNewPatient') {
      setFilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(String(0)) },
        ...{
          isAcceptingNewPatient: filter?.isAcceptingNewPatient === 0 ? 1 : 0,
        },
      })
    }
    if (value === 'isTopShow') {
      setFilter({
        ...filter,
        callApi: true,
        ...{ offset: parseInt(String(0)) },
        ...{ isTopShow: filter?.isTopShow === 0 ? 1 : 0 },
        ...{
          type:
            filter?.isTopShow === 1
              ? filter?.type.filter((i) => {
                  return i !== 'top'
                })
              : [...filter?.type, 'top'],
        },
      })
    }
  }

  const medicalSpeciality = getMedicalSpeciality()

  useEffect(() => {
    const getPatientsTreatedListData = async () => {
      const result = await getPatientsTreatedList()
      if (result.length > 0) {
        setListPatientsTreated(result)
      }
    }

    const formatResult = medicalSpeciality.map(({ id, name }) => {
      return {
        id,
        name,
      }
    })
    if (formatResult.length > 0) {
      setSpecialtyList(formatResult)
    }

    const getLangListData = async () => {
      const result = await getLangList()
      setLangList(result)
    }

    getPatientsTreatedListData()
    getLangListData()
  }, [])

  return (
    <Fragment>
      {modalList.indexOf(visibleComponent) > -1 ? (
        <Custommodal
          unsetComponent={closeModal}
          componentName={
            visibleComponent === 'allFilterModal' ? (
              <AllFilterModal
                filter={filter}
                setfilter={setFilter}
                specialtyList={specialtyList}
                langList={langList}
                listPatientsTreated={listPatientsTreated}
                closeModal={closeModal}
              />
            ) : visibleComponent === 'invitemodal' ? (
              <NoResultsModal closeModal={closeModal} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <Loadermodal />
      <Flex gap={4}>
        <Flex
          gap={4}
          borderRight="1px solid"
          borderColor="gray"
          pr={4}
          cursor="pointer"
          onClick={(e) => {
            openModal(e, 'allFilterModal')
          }}
        >
          <SliderIcon />
          <Typography>Filter</Typography>
        </Flex>
        <Box
          cursor="pointer"
          onClick={() => changeOption('isAcceptingNewPatient')}
        >
          <UserCheckIcon
            boxSize="large"
            color={filter.isAcceptingNewPatient ? 'primary' : 'text-dark-gray'}
          />
        </Box>
        <Box cursor="pointer" onClick={() => changeOption('hasVirtualVisit')}>
          <VirtualRecorderIcon
            boxSize="large"
            color={filter.hasVirtualVisit ? 'primary' : 'text-dark-gray'}
          />
        </Box>
      </Flex>
    </Fragment>
  )
}
