import { Flex, Input } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { BOX_SHADOW } from '../../../style/reusables'
import { InputDropdown } from './InputDropdown'
import { InsuranceList } from './InsuranceList'
import { useState } from 'react'
import { generateURLParams } from '../../../lib/utils'

interface Props {
  insuranceData: any
  query: any
}

export const InsuranceInput: React.FC<Props> = ({ insuranceData, query }) => {
  const history = useHistory()
  const location = useLocation()

  const [displayInsuranceName, setDisplayInsuranceName] = useState(false)

  const [searchInsuranceName, setSearchInsuranceName] = useState(
    query.insurance || ''
  )

  const updateInsuranceInput = (value: string) => {
    setSearchInsuranceName(value)
    setDisplayInsuranceName(false)

    history.replace({
      pathname: location.pathname,
      // @ts-ignore
      search: generateURLParams({ ...query, insurance: value }),
    })
  }

  return (
    <Flex position="relative">
      <Input
        variant="search"
        type="text"
        name="insurance_name"
        onClick={() => {
          setDisplayInsuranceName(!displayInsuranceName)
        }}
        placeholder="Insurance Name"
        autoComplete="off"
        {...BOX_SHADOW}
        value={searchInsuranceName}
        onChange={(event) => {
          setSearchInsuranceName(event?.target?.value)
        }}
      />
      {displayInsuranceName && insuranceData.length > 0 && (
        <InputDropdown>
          {insuranceData
            .filter(
              ({ name }) =>
                name.toLowerCase().indexOf(searchInsuranceName.toLowerCase()) >
                -1
            )
            .map((value, i) => (
              <InsuranceList
                key={i}
                item={value}
                index={i}
                onUpdate={updateInsuranceInput}
              />
            ))}
        </InputDropdown>
      )}
    </Flex>
  )
}
