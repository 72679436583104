import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import { Container, Row } from 'react-bootstrap'

const DashboardHead = (props) => {
  const tabName = props.tabName
  const title = props.title
  const changeTab = (e, tabId) => {
    e.preventDefault()
    props.setvisibleComponent(tabId)
  }

  const changeProvider = (e) => {
    props.setSelectedProvider(e.target.value)
  }

  return (
    <Container>
      <Row>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className="col-12"
        >
          <div className="dashboard-heading">
            {props.selectedProvider ? (
              <h1>Team dashboard - {props.providerName}</h1>
            ) : (
              <h1>{title}</h1>
            )}
          </div>
          {props.managedProviders && (
            <div>
              {' '}
              <select
                style={{ padding: '10px' }}
                className="select-provider"
                id="select-provider"
                onChange={(e) => changeProvider(e)}
              >
                {props.managedProviders.map((provider) => {
                  return (
                    <option key={provider.id} value={provider.id}>
                      {provider.firstName + provider.lastName}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <div className="dashboard-top-tabs-outer">
            <div className="dashboard-top-tabs">
              {tabName.map((data, index) => (
                <div
                  key={data.id}
                  className={props.visibleComponent == data.id ? 'active' : ''}
                >
                  <a
                    href="/#"
                    onClick={(e) => {
                      changeTab(e, data.id)
                    }}
                    className="under-line"
                  >
                    {data.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default DashboardHead
