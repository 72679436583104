import 'bootstrap/dist/css/bootstrap.css'
import { memo, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { Link } from 'react-router-dom'
import Global from '../../reactLayer/global'

const SchedulingTitle = (props) => {
  const { startDate, setStartDate } = props
  const [showCal, setshowCal] = useState(false)
  const dateSelect = (value) => {
    setshowCal(false)
    setStartDate(value)
  }

  useEffect(() => {
    setStartDate(new Date())
  }, [setStartDate])

  return (
    <div className="scheduling-component-main scheduling-title-doctor-search">
      <Row className="title-area">
        <div className="col-3 col-md-6 cal text-right position-relative">
          {showCal ? (
            <>
              <Calendar
                onClick={dateSelect}
                onClickDay={dateSelect}
                value={startDate}
                minDate={new Date()}
                maxDate={Global.addDays(null, 45)}
                calendarType={'US'}
              />
              <span
                onClick={() => {
                  setshowCal(false)
                }}
                className="close-cal"
              ></span>
            </>
          ) : (
            <>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  setshowCal(true)
                }}
                className="cal-link"
                to=""
              >
                <img src="/assets/images/calender-icon.png" alt="Calender" />
                <span className="view-cal-title">View Calendar</span>
              </Link>
            </>
          )}
        </div>
      </Row>
    </div>
  )
}

SchedulingTitle.defaultProps = {
  startDate: '',
  setStartDate: () => {},
}

export default memo(SchedulingTitle)
