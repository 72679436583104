export const addDays = (date?: Date | string, days = 0) => {
  let result
  if (date) {
    result = new Date(date)
  } else {
    result = new Date()
  }
  result.setDate(result.getDate() + days)
  return result
}

export const getYearMonthDate = (date = new Date(), time = false) => {
  const result = {
    year: date.getFullYear(),
    month: (parseInt(String(date.getMonth())) + 1).toString().padStart(2, '0'),
    date: date.getDate().toString().padStart(2, '0'),
  }
  if (time) {
    // @ts-ignore
    result['hours'] = date.getHours()
    // @ts-ignore
    result['minutes'] = date.getMinutes()
    // @ts-ignore
    result['seconds'] = date.getSeconds()
  }
  return result
}

export const phoneFormatter = (value: string) => {
  if (value) {
    if (value.length > 14) {
      value = value.slice(0, 14)
    }
    if (value) {
      value = value.replace(/\D+/g, '')
    }
    return value ? value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : value
  }
}

export const getDobString = (d: string) => {
  const date = new Date(d)
  return (
    (parseInt(String(date.getMonth())) + 1).toString().padStart(2, '0') +
    '/' +
    date.getDate().toString().padStart(2, '0') +
    '/' +
    date.getFullYear()
  )
}

export const getArray = (fromNumber = 0, number = 10) => {
  const totalLength = number - fromNumber
  return Array.from(new Array(totalLength), (val, index) => index + fromNumber)
}

export const debounce = (func, wait) => {
  let timeout
  return function (...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

export const statusOptions = {
  initial: 0,
  success: 1,
  error: 2,
  loading: 3,
}

export const generateURLParams = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams()
  Object.keys(params).forEach((key) => searchParams.append(key, params[key]))

  return searchParams.toString()
}
