import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import Loadermodal from '../../global/loader-modal/loader-modal'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'

const SubscriptionManagementAdmin = () => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState()
  const [showArr, setShowArr] = useState()
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const getDetails = async () => {
    authContext.setLoader()

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/plan/list',
    }

    // SEND REQUEST
    let result = await NetworkLayer.getRequest(submitData)

    if (result.status) {
      let showhideArr = []
      result.result.map(() => {
        showhideArr.push(0)
      })
      setShowArr(showhideArr)
      setData(result.result)
    }

    authContext.unsetLoader()
  }

  const updatePlans = async (data) => {
    authContext.setLoader()
    // FORM DATA

    let subPlans = []
    data.subPlans.map((plan) => {
      plan.price = plan.price.replaceAll(',', '')
      subPlans.push(plan)
      return null
    })

    const formData = {
      id: data.id,
      planName: data.planName,
      price: data.price,
      subPlans: subPlans,
    }
    // return false;
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/plan/update',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setShowArr([0, 0, 0])
      setmodalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      setTimeout(() => {
        getDetails()
      }, 1000)
    } else {
      setmodalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const toggleEditBox = (index) => {
    let showhideArr = [...showArr]

    for (let i = 0; i < showhideArr.length; i++) {
      if (i != index) {
        showhideArr[i] = 0
      }
    }

    showhideArr[index] = !showhideArr[index]

    setShowArr(showhideArr)
  }

  const formatPrice = (value) => {
    if (value) {
      return parseFloat(value)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        .substring(1)
        .split('.')[0]
    } else {
      return ''
    }
  }

  const editData = (e, index, subindex, type) => {
    let value = e.target.value.replaceAll(',', '')
    let tempData = [...data]
    if (type == 'price') {
      if (isNaN(value)) {
        return
      }

      tempData[index].subPlans[subindex].price = value
    } else if (type == 'discount') {
      tempData[index].subPlans[subindex].discount_percentage = value
    } else if (type == 'mainprice') {
      tempData[index].planName = value
    }

    setData(tempData)
  }

  const saveSubscriptions = (value) => {
    authContext.setLoader()
    updatePlans(value)
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <Fragment>
      <Loadermodal />
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive + ' admin-dashboard right-admin-section'
          }
        >
          {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          <Row className="bg-white-report">
            <div className="table-report">
              <div className="heading-wrap">
                <div className="table-heading">Subscription Management</div>
              </div>

              {/* Bottom container content */}

              <div className="content-management-outer">
                <div className="content-mange-head-wrap">
                  <div className="content-head">Name</div>
                  <div className="content-head">Action</div>
                </div>
                {data &&
                  data.map((value, index) => (
                    <div className="content-manage-outer" key={index}>
                      <div
                        className={` content-manage-inner ${
                          showArr[index] == 1 ? 'highlight-subscription' : ''
                        }`}
                      >
                        {showArr[index] == 1 ? (
                          <input
                            type="text"
                            className="input-pro subscription-input"
                            name="planName"
                            id={`planName${index}`}
                            value={value.planName}
                            autoComplete="off"
                            onChange={(e) => editData(e, index, 0, 'mainprice')}
                          />
                        ) : (
                          <div className="content-style">
                            <span>{value.planName}</span>
                          </div>
                        )}

                        <div className="content-style">
                          {showArr[index] == 1 ? (
                            <div className="bottom-btns">
                              <div className="next-prev-btn-pro">
                                <input
                                  type="button"
                                  className="subscription-btn prev-btn-pro"
                                  value="Cancel"
                                  onClick={() => toggleEditBox(index)}
                                />
                                <input
                                  type="button"
                                  className="subscription-btn next-btn-pro"
                                  value="Save"
                                  onClick={() => saveSubscriptions(value)}
                                />
                              </div>
                            </div>
                          ) : (
                            <a
                              style={{ color: '#077db4', cursor: 'pointer' }}
                              onClick={() => toggleEditBox(index)}
                            >
                              Edit
                            </a>
                          )}
                        </div>
                      </div>
                      {showArr[index] == 1 && (
                        <div className="billing-detail-subscription">
                          <div className="billing-detail-inner-tophead">
                            <div className="billing-head">Billing Period</div>
                            <div className="billing-head">Price</div>
                            <div className="billing-head">Discount </div>
                          </div>

                          {value.subPlans.map((subvalue, subindex) => (
                            <div key={subindex} className="billing-detail-body">
                              <div className="billing-body">
                                <div className="table-sort-filter">
                                  <input
                                    type="text"
                                    className="input-pro billing-period"
                                    name="subplanname"
                                    id="subplanname"
                                    value={subvalue.name}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="billing-body">
                                <span className="table-sort-filter">
                                  <div
                                    className="symbol"
                                    style={{
                                      'line-height': 'normal',
                                      'font-family': 'Lato, sans-serif',
                                    }}
                                  >
                                    $
                                  </div>
                                  <input
                                    type="text"
                                    className="input-pro billing-price"
                                    name="search"
                                    id="search"
                                    value={formatPrice(
                                      value.subPlans[subindex].price
                                    )}
                                    autoComplete="off"
                                    onChange={(e) =>
                                      editData(e, index, subindex, 'price')
                                    }
                                    style={{ padding: '10px 25px' }}
                                  />
                                </span>
                              </div>
                              <div className="billing-body">
                                <span className="table-sort-filter">
                                  <input
                                    type="text"
                                    className="input-pro billing-discount"
                                    name="search"
                                    id="search"
                                    autoComplete="off"
                                    value={
                                      value.subPlans[subindex]
                                        .discount_percentage
                                    }
                                    onChange={(e) =>
                                      editData(e, index, subindex, 'discount')
                                    }
                                  />
                                  <div className="percent">%</div>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default SubscriptionManagementAdmin
