import 'bootstrap/dist/css/bootstrap.css'
import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PatientLinks } from '../../linkFile'

const ProviderHeading = (props) => {
  const changeStatus = () => {
    props && props.setClaim()
  }

  useEffect(() => {
    localStorage.setItem('fromProfile', true)
  }, [])

  return (
    <Container>
      <Row>
        <div className="col-12">
          <div className="provider-profile-heading">
            <div className="back-btn">
              <Link to={PatientLinks.doctor_search_result}>
                <img
                  src="/assets/images/bk-arrow.svg"
                  className="bk-arrow"
                  alt="Doctor"
                />{' '}
                Back to search results
              </Link>
              {!props?.claimProfile?.accountInfo?.email ? (
                <span>
                  Is this your profile? <a href="/contactus">Claim it here</a>
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default ProviderHeading
