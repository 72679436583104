// @ts-nocheck
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './App.scss'

import { AdminLinks, PatientLinks, ProviderLinks } from './linkFile'

// Provider Files

import ManageAddress from './components/profile-tabs/manage-address'
import ProviderDashboard from './provider/dashboard/dashboard'
import FreeProfile from './provider/free-profile/free-profile'
import AccountProfile from './provider/my-account/account-profile'
import MyAccount from './provider/my-account/my-account'
import ReactiveAccount from './provider/my-account/reactive-account'
import SubscriptionCancel from './provider/my-account/subscription-canceled'
import PaymentReceipt from './provider/payment-confirmed/payment-receipt'
import ProviderProfile from './provider/profile/profile'
import ProviderLogin from './provider/provider-login/provider-login'
import ProviderTwoFactAuth from './provider/provider-login/twosteppage'
import ProviderRegistration from './provider/provider-register/provider-register'
import ReferFriendPage from './provider/refer-friend/refer-friend'
import SearchResult from './provider/search-result/search-result'

// Patient Files
import { AdminPages, PatientPages, ProviderPages } from './PrivatePages'
import PatientHealth from './components/patient-health-survey/patient-survey'
import Error404 from './global/errorpages/error-404'
import ProfileDeleted from './global/errorpages/profile-deleted'
import RedirectionPage from './global/errorpages/redirectionPage'
import Aboutus from './patient/aboutus/aboutus'
import AppointmentRequestSubmit from './patient/contactus/appointmentRequestSubmit'
import Contactus from './patient/contactus/contactus'
import CareTeam from './patient/dashboard/care-team'
import PatientDashboard from './patient/dashboard/dashboard'
import HowWorks from './patient/how-it-works/how-it-works'
import JotForm from './patient/jot-form/jot-form'
import PatientAccount from './patient/my-account/my-account'
import Notification from './patient/notification/notification'
import PatientRegistration from './patient/patient-register/patient-register'
import AcceptablePolicy from './patient/privacy-policy/acceptable-policy'
import Additionalterms from './patient/privacy-policy/additional-terms'
import PrivacyPolicy from './patient/privacy-policy/privacy-policy'
import TermsOfUse from './patient/privacy-policy/term-of-use'
import SearchBy from './patient/searchBy/searchBy'
import AccountPatientProfile from './provider/my-account/account-profile'
import TestComponent from './provider/test/test'

// import Doctor
import ForgotPassword from './components/provider-login/forgot-password'
import ResetPassword from './components/provider-login/reset-password'
import Cookies from './patient/cookies/accept-cookies'
import DoctorProfile from './patient/doctor-profile/doctor-profile'

//import Admin
import AdminLogin from './admin/admin-login/admin-login'
import AdminAppointments from './admin/appointments/appointments'
import CreateProvider from './admin/create-provider/create-provider'
import AdminDashboard from './admin/dashboard/dashboard'
import DocAssist from './admin/doc-assist/doc-assist'
import InsuranceDetail from './admin/insurance/insurance-detail'
import ManageSubAdmin from './admin/manage-admin/manage-admin'
import NotificationDetail from './admin/notification/notification-detail'
import PatientAddDetail from './admin/patient-admin/patient-adddetail'
import PatientDetail from './admin/patient-admin/patient-detail'
import AdminPatientManagement from './admin/patient-admin/patient-management'
import ProviderDetail from './admin/provider/provider-detail'
import ProviderManagement from './admin/provider/provider-management'
import RatingManagement from './admin/rating-review/rating-management'
import RatingReview from './admin/rating-review/rating-review'
import RevenueAdmin from './admin/revenue/revenue-admin'
import RevenueManagement from './admin/revenue/revenue-management'
import SpecialityDetail from './admin/speciality/speciality-detail'
import SubscriptionManagementAdmin from './admin/subscription/subscription'
import VerifyAdmin from './admin/verify/verify-admin'
import WebsiteContent from './admin/website-content/website-content'
import { UploadLocations } from './components/admin/upload/upload-locations'
import { Page } from './new/components/ScheduleNow/Page'
import TeamLogin from './teamMember/login/TeamLogin'
import TeamMemberReg from './teamMember/register/TeamRegister'

import SearchResults from './new/components/SearchResults/SearchResults'
import Home from './provider/home/home'
import { ChipInputValuesProvider } from './reactLayer/contextStore/homepageStore'

const App = () => {
  return (
    <Router>
      <ChipInputValuesProvider>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* Provider Used Routing  */}
          <Route
            exact
            path={ProviderLinks.register}
            component={ProviderRegistration}
          />

          <Route exact path={ProviderLinks.login} component={ProviderLogin} />
          <Route
            exact
            path={ProviderLinks.twofactauth}
            component={ProviderTwoFactAuth}
          />
          <Route exact path="/test" component={TestComponent} />
          <Route
            exact
            path={ProviderLinks.dashboard}
            render={(props) => (
              <ProviderPages>
                {' '}
                <ProviderDashboard {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.team_register}
            render={(props) => <TeamMemberReg {...props} />}
          />
          <Route exact path={ProviderLinks.team_login} component={TeamLogin} />
          <Route
            exact
            path={ProviderLinks.myaccount}
            render={(props) => (
              <ProviderPages>
                {' '}
                <MyAccount {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.account_profile}
            render={(props) => (
              <ProviderPages>
                {' '}
                <AccountProfile {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.forgot_pass}
            component={ForgotPassword}
          />
          <Route
            exact
            path={ProviderLinks.reset_pass}
            component={ResetPassword}
          />
          <Route
            exact
            path={ProviderLinks.subscription_canceled}
            render={(props) => (
              <ProviderPages>
                {' '}
                <SubscriptionCancel {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.freeprofile}
            component={FreeProfile}
          />
          <Route
            exact
            path={ProviderLinks.edit_address}
            render={(props) => (
              <ProviderPages>
                {' '}
                <ManageAddress {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.add_address}
            render={(props) => (
              <ProviderPages>
                {' '}
                <ManageAddress {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.schedule_now}
            render={(props) => (
              <>
                <Page {...props} />{' '}
              </>
            )}
          />
          {/* Patient Used Routing  */}
          <Route
            exact
            path={PatientLinks.dashboard}
            render={(props) => (
              <PatientPages>
                {' '}
                <PatientDashboard {...props} />{' '}
              </PatientPages>
            )}
          />
          <Route
            exact
            path={PatientLinks.register}
            component={PatientRegistration}
          />
          <Route
            exact
            path={PatientLinks.redirection}
            component={RedirectionPage}
          />
          <Route exact path={PatientLinks.login} component={ProviderLogin} />
          <Route
            exact
            path={PatientLinks.myaccount}
            render={(props) => (
              <PatientPages>
                {' '}
                <PatientAccount {...props} />{' '}
              </PatientPages>
            )}
          />
          <Route
            exact
            path={PatientLinks.account_profile}
            render={(props) => (
              <PatientPages>
                {' '}
                <AccountPatientProfile {...props} />{' '}
              </PatientPages>
            )}
          />
          <Route
            exact
            path={PatientLinks.appointment_success}
            render={(props) => (
              <PatientPages>
                {' '}
                <AppointmentRequestSubmit {...props} />{' '}
              </PatientPages>
            )}
          />
          {/* Provider unused Routing  */}
          <Route
            exact
            path={ProviderLinks.search_result}
            component={SearchResult}
          />
          <Route
            exact
            path={ProviderLinks.profile + '/:userID'}
            component={ProviderProfile}
          />
          <Route
            exact
            path={ProviderLinks.refer_friend}
            component={ReferFriendPage}
          />
          <Route
            exact
            path={ProviderLinks.reactive_account}
            component={ReactiveAccount}
          />
          <Route
            exact
            path={ProviderLinks.payment_receipt}
            component={PaymentReceipt}
          />

          {/* Patient Unused Routing  */}

          <Route
            exact
            path={PatientLinks.privacy_policy}
            component={PrivacyPolicy}
          />
          <Route exact path={PatientLinks.aboutus} component={Aboutus} />
          <Route
            exact
            path={PatientLinks.profile_deleted}
            component={ProfileDeleted}
          />
          <Route exact path={PatientLinks.contactus} component={Contactus} />
          <Route
            exact
            path={PatientLinks.acceptable_policy}
            component={AcceptablePolicy}
          />
          <Route
            exact
            path={PatientLinks.additional_terms}
            component={Additionalterms}
          />
          <Route
            exact
            path={PatientLinks.term_of_user}
            component={TermsOfUse}
          />

          <Route exact path={PatientLinks.searchby} component={SearchBy} />
          <Route exact path={PatientLinks.how_works} component={HowWorks} />

          <Route
            exact
            path={PatientLinks.health_grade}
            component={PatientHealth}
          />
          <Route exact path={PatientLinks.care_team} component={CareTeam} />
          <Route
            exact
            path={PatientLinks.notification}
            component={Notification}
          />
          <Route
            exact
            path={PatientLinks.notification}
            render={(props) => (
              <PatientPages>
                {' '}
                <Notification {...props} />{' '}
              </PatientPages>
            )}
          />
          <Route
            exact
            path={ProviderLinks.notification}
            render={(props) => (
              <ProviderPages>
                {' '}
                <Notification {...props} />{' '}
              </ProviderPages>
            )}
          />
          <Route
            path={PatientLinks.doctor_search_result}
            exact
            component={SearchResults}
          />
          <Route
            exact
            path={PatientLinks.doctor_profile}
            component={DoctorProfile}
          />
          <Route exact path={PatientLinks.accept_cookies} component={Cookies} />
          <Route exact path={PatientLinks.jot_form} component={JotForm} />

          {/* Admin Routing  */}
          <Route exact path={AdminLinks.admin_root} component={AdminLogin} />
          <Route exact path={AdminLinks.admin_login} component={AdminLogin} />
          <Route
            exact
            path={AdminLinks.admin_dashboard}
            render={(props) => (
              <AdminPages>
                {' '}
                <AdminDashboard {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_provider_management}
            render={(props) => (
              <AdminPages>
                {' '}
                <ProviderManagement {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_provider_management + '/:type'}
            render={(props) => (
              <AdminPages>
                {' '}
                <ProviderManagement {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_revenue_management}
            render={(props) => (
              <AdminPages>
                {' '}
                <RevenueManagement {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_patient_management}
            render={(props) => (
              <AdminPages>
                {' '}
                <AdminPatientManagement {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_provider_detail + '/:userID'}
            render={(props) => (
              <AdminPages>
                {' '}
                <ProviderDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_patient_detail + '/:userID'}
            render={(props) => (
              <AdminPages>
                {' '}
                <PatientDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_patient_add_detail}
            render={(props) => (
              <AdminPages>
                {' '}
                <PatientAddDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_provider_add_detail}
            render={(props) => (
              <AdminPages>
                {' '}
                <CreateProvider {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_speciality}
            render={(props) => (
              <AdminPages>
                {' '}
                <SpecialityDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_insurance}
            render={(props) => (
              <AdminPages>
                {' '}
                <InsuranceDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_content_management}
            render={(props) => (
              <AdminPages>
                {' '}
                <WebsiteContent {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_rating_review}
            render={(props) => (
              <AdminPages>
                {' '}
                <RatingReview {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_rating_management + '/:ratingID'}
            render={(props) => (
              <AdminPages>
                {' '}
                <RatingManagement {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_notification}
            render={(props) => (
              <AdminPages>
                {' '}
                <NotificationDetail {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_appointments}
            render={(props) => (
              <AdminPages>
                {' '}
                <AdminAppointments {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_subscription}
            render={(props) => (
              <AdminPages>
                {' '}
                <SubscriptionManagementAdmin {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_manage_sub_admin}
            render={(props) => (
              <AdminPages>
                {' '}
                <ManageSubAdmin {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_revenue}
            render={(props) => (
              <AdminPages>
                {' '}
                <RevenueAdmin {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.admin_verify + '/:token'}
            render={(props) => <VerifyAdmin {...props} />}
          />
          <Route
            exact
            path={AdminLinks.admin_doc_assist}
            render={(props) => (
              <AdminPages>
                {' '}
                <DocAssist {...props} />{' '}
              </AdminPages>
            )}
          />
          <Route
            exact
            path={AdminLinks.upload_locations}
            render={(props) => (
              <AdminPages>
                {' '}
                <UploadLocations {...props} />{' '}
              </AdminPages>
            )}
          />

          <Route path="*" component={Error404} />
        </Switch>
      </ChipInputValuesProvider>
    </Router>
  )
}

export default App
