import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import { DoctorProfile, PracticeInfo, Slot } from '../../types'
import NetworkLayer from '../../../reactLayer/Network/Network'
import {
  Box,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Typography } from '../../style/typography'

interface Props {
  doctor: DoctorProfile
  browserAddress?: string
  bookSlot: Slot
}

const TextProps = {
  fontSize: 'md',
  fontWeight: '500',
}

export const AppointmentModal: React.FC<Props> = ({
  doctor,
  bookSlot,
  browserAddress,
}) => {
  const authContext = useContext(AuthContext)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const onSubmit = (data: any) => console.log(data)

  const { practiceInfo, medicalSpeciality } = doctor

  const noNewPatients = practiceInfo?.isAcceptingNewPatient?.toString() === '0'
  const appDate = bookSlot?.date?.split('-') || ''
  const [providerLocations, setProviderLocations] = useState<PracticeInfo>()

  useEffect(() => {
    if (practiceInfo) {
      setProviderLocations(practiceInfo)
    }
  }, [])

  return (
    <Stack gap={4} p={8}>
      <Box>
        {/*{authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}*/}
      </Box>
      <Box>
        <Typography as="h3">Book Appointment</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={5}>
          <Stack>
            <Typography {...TextProps}>Who is this appointment for?</Typography>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>
              Will you/your child be a new patient?
            </Typography>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>
              Will you/your child be a new patient?
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <Input {...field} />}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>
              Will you/your child be a new patient?
            </Typography>
            <Controller
              name="isNewPatient"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>Preferred Location</Typography>
            <Controller
              name="locationId"
              control={control}
              disabled={bookSlot.disabledLocation}
              render={({ field }) => (
                <Select {...field}>
                  {providerLocations?.providerPracticeLocations.map(
                    (practice, i) => (
                      <option key={i + 1} value={practice.id}>
                        Location #{i + 1} {practice.practiceName}
                      </option>
                    )
                  )}
                </Select>
              )}
            />
          </Stack>

          <Stack>
            <Typography {...TextProps}>Appointment Time</Typography>
            <Controller
              name="preferAppointmentTime"
              control={control}
              render={({ field }) => (
                <Input {...field} value={bookSlot.startTime} disabled />
              )}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>Preferred appointment type?</Typography>
            <Controller
              name="preferAppointmentType"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <option value="In-person">In-person</option>
                  {practiceInfo.hasVirtualVisit ? (
                    <>
                      <option value="Virtual Visit">Virtual Visit</option>
                    </>
                  ) : (
                    <></>
                  )}
                </Select>
              )}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>Visit type?</Typography>
            <Controller
              name="visitType"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <option value="Routine Checkup">Routine Checkup</option>
                  <option value="Follow-up on Previous Visit">
                    Follow-up on Previous Visit
                  </option>
                  <option value="Sick or Injured Visit">
                    Sick or Injured Visit
                  </option>
                  <option value="Consulation">Consultation</option>
                </Select>
              )}
            />
          </Stack>
          <Stack>
            <Typography {...TextProps}>
              Short description of reason for visit?{' '}
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <Textarea {...field} placeholder="" />}
            />
            <Typography {...TextProps}>Note: 300 character limit</Typography>
          </Stack>
          <Input variant="primary" type="submit" />
        </Stack>
      </form>
    </Stack>
  )
}
