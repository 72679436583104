import React, { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { PatientLinks } from '../../linkFile'

const SecurityLinks = (props) => {
  const history = useHistory()
  const redirectTo = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-next-page')
    history.push({
      pathname: `${PatientLinks.accountsecurity}/${previousPage}`,
    })
  }

  return (
    <Fragment>
      <Container>
        <Container className="my-account-wrap">
          <Row>
            <div className="col-12">
              <div className="my-acc-info-box">
                <h3>Signing into DocScheduler</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="change-password"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Change Password</a>
                  </li>
                  <li
                    data-next-page="step-verification"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">2-Step Verification</a>
                  </li>
                </ul>
              </div>
              <div className="my-acc-info-box">
                <h3>Ways we can verify it's you</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="recovery-phoneno"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Recovery Phone #</a>
                  </li>
                  <li
                    data-next-page="recovery-email"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Recovery Email</a>
                  </li>
                </ul>
              </div>
              <div className="dlt-profile-security">
                <div className="card-buttons">
                  <input
                    data-next-page="delete-profile"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                    type="button"
                    className="rev-btn-pro"
                    value="Delete Profile"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default SecurityLinks
