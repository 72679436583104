import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useCallback, useContext } from 'react'
import { Container, Row } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
import { ReactComponent as Checkmark } from '../../Icons/checkmark.svg'
import DoctorProfile from '../../components/doctor-profile/doctor-profile'
import Error404 from '../../global/errorpages/error-404'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import useLocationMod from '../../hooks/useLocationMod'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'

const AppointmentRequestSubmit = (props) => {
  const history = useHistory()
  const baseAddress = 'https://www.google.co.in/maps/dir/'
  const authContext = useContext(AuthContext)
  const locationMod = useLocationMod()

  const locationId = authContext.currentAppointment.locationId
  const browserAddress = authContext.currentAppointment.browserAddress

  const doctorProfile = authContext.currentAppointment.drProfile

  const redirectToSearchResult = useCallback(
    (event, each) => {
      event.preventDefault()
      const path =
        authContext.user &&
        authContext?.user?.isProfileCompleted &&
        authContext.user.accountInfo.roleId === 3
          ? ProviderLinks.search_result
          : PatientLinks.doctor_search_result
      history.push({
        pathname: path,
        state: {
          find: '',
          insurance_Name: [],
          focusAreas: each,
        },
      })
      locationMod.location.state = {
        find: '',
        insurance_Name: [],
        focusAreas: each,
      }
    },
    [history, locationMod]
  )

  const providerPracticeLocations = doctorProfile?.providerPracticeLocations

  return (
    <Fragment>
      {doctorProfile && locationId ? (
        <>
          <HeaderMain />
          <Container className="free-profile appointment-submit">
            <Container>
              <Row>
                <div className="col-12">
                  <h2>Appointment Request Submitted</h2>
                </div>

                <div className="col-12 contactus">
                  <Row>
                    <div className="suc-msg">
                      <div className="checkmar-outer mb-3">
                        <Checkmark />
                      </div>
                      {doctorProfile.bookSlot &&
                      doctorProfile.bookSlot.startTime ? (
                        <h3 className="">
                          Your appointment is confirmed with Dr.{' '}
                          <span className="capitalize">
                            {doctorProfile?.providers?.firstName &&
                              doctorProfile?.providers?.firstName}
                            {doctorProfile?.accountInfo?.firstName &&
                              doctorProfile?.accountInfo?.firstName}{' '}
                            !
                          </span>
                          <br />
                          <strong>
                            {`${doctorProfile?.bookSlot?.date || ''}
                            at ${doctorProfile?.bookSlot?.startTime}
                            ${
                              providerPracticeLocations &&
                              providerPracticeLocations[0]?.timeZone
                                ? providerPracticeLocations[0]?.timeZone
                                : ''
                            }`}
                          </strong>
                          <br />
                          {providerPracticeLocations &&
                            [providerPracticeLocations].map((l, li) =>
                              locationId == l.id ? (
                                <React.Fragment key={li}>
                                  <strong>
                                    {l.practiceAddress}, {l.cities.name}
                                  </strong>
                                  <br />
                                </React.Fragment>
                              ) : (
                                <></>
                              )
                            )}
                        </h3>
                      ) : (
                        <h3 className="">
                          Your request has been submitted! Thank you for using
                          DocScheduler! Dr.{' '}
                          <span className="capitalize">
                            {doctorProfile?.providers?.firstName &&
                              doctorProfile?.providers?.firstName}
                            {doctorProfile?.accountInfo?.firstName &&
                              doctorProfile?.accountInfo?.firstName}
                          </span>
                          `s office will contact you within the next 24-36 hours
                          to schedule your appointment.
                        </h3>
                      )}

                      <div className="request-appointment">
                        <h5>Request another appointment?</h5>
                        <ul>
                          <li>
                            <a
                              href="/#"
                              key={'Primary Care'}
                              onClick={(e) =>
                                redirectToSearchResult(e, 'Primary Care')
                              }
                            >
                              {' '}
                              Primary Care Doctor
                            </a>
                          </li>
                          <li>
                            <a
                              href="/#"
                              key={'Oral Health'}
                              onClick={(e) =>
                                redirectToSearchResult(e, 'Oral Health')
                              }
                            >
                              {' '}
                              General Dentist
                            </a>
                          </li>
                          <li>
                            <a
                              href="/#"
                              key={'Dermatology'}
                              onClick={(e) =>
                                redirectToSearchResult(e, 'Dermatology')
                              }
                            >
                              {' '}
                              Dermatologist
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Row>
                  <Row className="pro-profile-box-style provider-detail-left-top mb-3 mt-3 mb-md-5 mt-md-5">
                    <DoctorProfile {...doctorProfile} />
                    <div className="profile-tabs border-0 mt-3 pt-3 mt-md-5 pt-md-5">
                      <ul className="location-add-profile">
                        {providerPracticeLocations &&
                          [providerPracticeLocations].map((l, li) =>
                            locationId === l.id ? (
                              <>
                                <li key={'location-' + li} className="map-icon">
                                  <div className="location-head">
                                    {li === 0
                                      ? 'Primary Location'
                                      : `Location #${li + 1}`}
                                  </div>
                                  {l.practiceAddress}, {l.cities.name},{' '}
                                  {l.zipCode}
                                  <br />
                                  {Global.phoneFormator(l.phoneNumber)}
                                  <div className="location-head">Hours</div>
                                  {l.practiceHours.map((pinfo, pinfoIndex) => (
                                    <div
                                      key={'pinfo-' + li + '-' + pinfoIndex}
                                      className="loca-hours"
                                    >
                                      <span>{pinfo.day}</span>
                                      <span>
                                        {pinfo.start} - {pinfo.end}
                                      </span>
                                    </div>
                                  ))}
                                  <div className="location-link">
                                    {browserAddress ? (
                                      <>
                                        <a
                                          target="_blank"
                                          href={`${baseAddress}${browserAddress}/${l.practiceAddress} ${l.cities.name} ${l.states.name} ${l.zipCode}`}
                                          rel="noreferrer"
                                        >
                                          Directions
                                        </a>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {l.website ? (
                                      <>
                                        <a
                                          target="_blank"
                                          href={l.website}
                                          rel="noreferrer"
                                        >
                                          Website
                                        </a>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </li>
                              </>
                            ) : (
                              <></>
                            )
                          )}
                        {providerPracticeLocations &&
                          [providerPracticeLocations].map((l, li) =>
                            locationId == l.id ? (
                              <>
                                <li key={'location-' + li} className="map-icon">
                                  <div className="location-head">
                                    {li === 0
                                      ? 'Primary Location'
                                      : `Location #${li + 1}`}
                                  </div>
                                  {l.practiceAddress}, {l.cities.name},{' '}
                                  {l.zipCode}
                                  <br />
                                  {Global.phoneFormator(l.phoneNumber)}
                                  <div className="location-head">Hours</div>
                                  {l.practiceHours.map((pinfo, pinfoIndex) => (
                                    <div
                                      key={'pinfo-' + li + '-' + pinfoIndex}
                                      className="loca-hours"
                                    >
                                      <span>{pinfo.day}</span>
                                      <span>
                                        {pinfo.start} - {pinfo.end}
                                      </span>
                                    </div>
                                  ))}
                                  <div className="location-link">
                                    {browserAddress ? (
                                      <>
                                        <a
                                          target="_blank"
                                          href={`${baseAddress}${browserAddress}/${l.practiceAddress} ${l.cities.name} ${l.states.name} ${l.zipCode}`}
                                          rel="noreferrer"
                                        >
                                          Directions
                                        </a>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {l.website ? (
                                      <>
                                        <a
                                          target="_blank"
                                          href={l.website}
                                          rel="noreferrer"
                                        >
                                          Website
                                        </a>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </li>
                              </>
                            ) : (
                              <></>
                            )
                          )}
                      </ul>
                    </div>
                  </Row>
                  <Row></Row>
                </div>
              </Row>
            </Container>
          </Container>
          <Footer />
        </>
      ) : (
        <Error404 />
      )}
    </Fragment>
  )
}

export default AppointmentRequestSubmit
