import 'bootstrap/dist/css/bootstrap.css'
import * as moment from 'moment'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'

const PatientManagementInfoMadal = (props) => {
  const { data } = props
  return (
    <Fragment>
      <Container>
        <div className="search-modal-wrap patient-info-modal">
          <div className="edit-all-filter">
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 mb-3 mb-md-4">
                  <div className="demo-medical-wrap">
                    <div className="demo-left">
                      <div className="head">Account Information</div>
                      <div className="demo-medical-inner">
                        <div className="dmleft">Name:</div>
                        <div className="dmright">{`${data?.patientInfo?.accountInfo?.firstName} ${data?.patientInfo?.accountInfo?.lastName}`}</div>

                        <div className="dmleft">Sex:</div>
                        <div className="dmright">
                          {data?.patientInfo?.accountInfo?.gender}
                        </div>

                        <div className="dmleft">Preferred Pronoun:</div>
                        <div className="dmright">
                          {data?.patientInfo?.accountInfo?.preferredPronoun}
                        </div>

                        <div className="dmleft">Email Address:</div>
                        <div className="dmright">
                          {data?.patientInfo?.accountInfo?.email}
                        </div>

                        <div className="dmleft">Phone Number:</div>
                        <div className="dmright">
                          {data?.patientInfo?.accountInfo?.phoneNumber
                            ? data?.patientInfo?.accountInfo?.phoneNumber
                            : data?.patientInfo?.accountInfo?.recoveryPhone}
                        </div>

                        <div className="dmleft">Birth Date:</div>
                        <div className="dmright">
                          {moment(data?.patientInfo?.accountInfo?.dob).format(
                            'd MMM, Y'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="medical-right">
                      <div className="head">Medical Insurance Information</div>
                      <div className="demo-medical-inner">
                        <div className="dmleft">Insurance Name:</div>
                        <div className="dmright">
                          {
                            data?.patientInfo?.patientInsurances?.medical[0]
                              ?.insurances?.name
                          }
                        </div>

                        <div className="dmleft">Plan Name:</div>
                        <div className="dmright">
                          {
                            data?.patientInfo?.patientInsurances?.medical[0]
                              ?.planName
                          }
                        </div>

                        <div className="dmleft">Group ID#:</div>
                        <div className="dmright">
                          {
                            data?.patientInfo?.patientInsurances?.medical[0]
                              ?.group
                          }
                        </div>

                        <div className="dmleft">Member ID#:</div>
                        <div className="dmright">
                          {
                            data?.patientInfo?.patientInsurances?.medical[0]
                              ?.membershipId
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default PatientManagementInfoMadal
