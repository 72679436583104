import { useContext, useEffect, useReducer, useState } from 'react'
import NetworkLayer from '../../../../reactLayer/Network/Network'
import AuthContext from '../../../../reactLayer/auth/AuthContext'
import { Filter } from '../types'

const headers = { 'Content-Type': 'application/json' }

function reducer(state, action: any) {
  if (action.type === 'updateFilteredResults') {
    return {
      ...state,
      filteredResults: action.value,
    }
  }
  if (action.type === 'updateSearchResults') {
    return {
      ...state,
      searchResults: action.value,
    }
  }
  if (action.type === 'setTotalCount') {
    return {
      ...state,
      totalCount: action.value,
    }
  }
  if (action.type === 'setAreaSupported') {
    return {
      ...state,
      areaSupported: action.value,
    }
  }
  if (action.type === 'setPage') {
    return {
      ...state,
      page: action.value,
    }
  }
  throw Error('Unknown action.')
}

export const useGetSearchData = (filter: Filter, order: string) => {
  const authContext = useContext(AuthContext)

  const data = {
    ...filter,
    order: order,
    latitude: authContext.browserLatLng.latitude
      ? authContext.browserLatLng.latitude
      : window.localStorage.getItem('latitude'),
    longitude: authContext.browserLatLng.longitude
      ? authContext.browserLatLng.longitude
      : window.localStorage.getItem('longitude'),
  }

  const requestData = {
    headers: headers,
    url: 'providers/search/get/all',
    body: JSON.stringify(data),
  }

  const [state, dispatch] = useReducer(reducer, {
    filteredResults: [],
    searchResults: [],
    areaSupported: true,
    totalCount: 1,
    page: 1,
  })

  const getSearchResult = async () => {
    if (authContext?.headerSearchText?.actionSubmit) {
      authContext.setHeaderSearchText({
        text: authContext?.headerSearchText?.text,
        actionSubmit: false,
      })
    }
    authContext.setLoader()
    if (authContext.token) {
      headers['access-token'] = authContext.token
    }
    const result = await NetworkLayer.postData(requestData)
    authContext.unsetLoader()
    const { totalCount, data, page } = result.body.result

    if (filter.available72hours) {
      if (data.length > 0) {
        dispatch({ type: 'updateFilteredResults', value: data })
      }
    } else {
      dispatch({ type: 'updateSearchResults', value: data })
    }
    dispatch({ type: 'setAreaSupported', value: data.length !== 0 })
    dispatch({ type: 'setTotalCount', value: totalCount })
    dispatch({ type: 'setPage', value: page })
    // if (
    //   userCateType === 'patient' &&
    //   data.length === 0 &&
    //   modalForLocationShown !== locationName &&
    //   (filter.zip || filter.city)
    // ) {
    //   setModalForLocationShown(locationName)
    // }
  }

  useEffect(() => {
    getSearchResult()
  }, [filter, order])

  return {
    searchResults: state.searchResults,
    filteredResults: state.filteredResults,
    totalCount: state.totalCount,
    page: state.page,
    areaSupported: state.areaSupported,
  }
}
