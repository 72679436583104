import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'

const RequestAppointmentModal = (props) => {
  const history = useHistory()
  const authContext = useContext(AuthContext)
  const [userId, setUserId] = useState('')
  const [user] = useState(authContext.user)
  const [appointmentFor, setAppointmentFor] = useState([])
  const [providerSpecialities, setProviderSpecialities] = useState([])
  const noNewPatients =
    props?.providers?.isAcceptingNewPatient?.toString() === '0' ||
    props?.practiceInfo?.isAcceptingNewPatient?.toString() === '0'
  const appDate =
    props.bookSlot && props.bookSlot.date
      ? props.bookSlot.date.split('-')
      : null
  const [providerLocations, setproviderLocations] = useState([])
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const [patientReq, setpatientReq] = useState({
    pname: {
      value: '',
      validation: ['blank', 'name'],
      errorMsg: ['This filed is required', 'Invalid Name'],
      errorKey: 'pname',
      pname: false,
    },
    isNewPatient: {
      value: noNewPatients ? '0' : '',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'isNewPatient',
      isNewPatient: false,
    },
    locationId: {
      value: props.bookSlot.locationId,
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'locationId',
      locationId: false,
    },
    preferAppointmentTime: {
      value: 'morning',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'preferAppointmentTime',
      preferAppointmentTime: false,
    },
    preferAppointmentType: {
      value: 'In-person',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'preferAppointmentType',
      preferAppointmentType: false,
    },
    appointmentDayType: {
      value: 'Next 7 Days',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'appointmentDayType',
      appointmentDayType: false,
    },
    visitType: {
      value: 'Routine Checkup',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'visitType',
      visitType: false,
    },
    appointmentDescription: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This filed is required'],
      errorKey: 'appointmentDescription',
      appointmentDescription: false,
      totalWords: 0,
    },
  })

  const modifyPracticeData = (e) => {
    let name,
      value,
      tempData = patientReq
    name = e.currentTarget.getAttribute('name')
    value = e.currentTarget.value

    if (name === 'appointmentDescription') {
      if (value.length <= 300) {
        tempData[name]['value'] = value
        tempData[name]['totalWords'] = 300 - value.length
        tempData[name][name] = false
      }
      setpatientReq({ ...tempData })
    } else {
      tempData[name]['value'] = value
      tempData[name][name] = false
      setpatientReq({ ...tempData })
    }
  }
  const callBackSubmit = async () => {
    let temppracticeData,
      flag = true
    temppracticeData = patientReq
    let validationResult = InputValidation(patientReq)
    if (Object.keys(validationResult).length > 0) {
      for (const prop in validationResult) {
        temppracticeData[prop][prop] = `${validationResult[prop]}`

        flag = false
      }
      setpatientReq({ ...temppracticeData })
    }
    if (flag) {
      authContext.setLoader()
      const fData = {
        isNewPatient: patientReq.isNewPatient.value,
        locationId: patientReq.locationId.value,
        preferAppointmentTime: patientReq.preferAppointmentTime.value,
        preferAppointmentType: patientReq.preferAppointmentType.value,
        visitType: patientReq.visitType.value,
        appointmentDescription: patientReq.appointmentDescription.value,
      }
      if (props.bookSlot.date) {
        fData.appointmentDate = patientReq.appointmentDayType.value
      } else {
        fData.appointmentDayType = patientReq.appointmentDayType.value
      }

      if (userId) {
        fData.childId = userId
      }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'appointment/book',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        authContext.setCurrentAppointment({
          drProfile: {
            ...props,
            bookSlot: {
              ...props.bookSlot,
              date: result.body.result.fulfillmentValue.appointmentDate,
            },
          },
          locationId: patientReq.locationId.value,
          browserAddress: props.browserAddress,
        })
        history.push({
          pathname: PatientLinks.appointment_success,
        })
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }

  useEffect(() => {
    let allSpecialities = []
    if (props?.providerMedicalSpecialities?.length) {
      props.providerMedicalSpecialities.map((speciality) => {
        speciality.specialties &&
          allSpecialities.push(speciality.specialties.category)
        return null
      })
    }
    setProviderSpecialities(allSpecialities)
    const usersToSelect = [
      { name: authContext.user.accountInfo.firstName, id: '' },
    ]
    if (authContext.user.patientInsurances.child) {
      authContext.user.patientInsurances.child.map((childData) => {
        usersToSelect.push({ name: childData.firstName, id: childData.id })
        return null
      })
    }
    setAppointmentFor(usersToSelect)

    let pName, tempPatientData
    pName =
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.firstName
        ? authContext.user.accountInfo.firstName + ' '
        : ''
    pName +=
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.middleName
        ? authContext.user.accountInfo.middleName + ' '
        : ''
    pName +=
      authContext.user &&
      authContext.user.accountInfo &&
      authContext.user.accountInfo.lastName
        ? authContext.user.accountInfo.lastName
        : ''

    if (props.providerPracticeLocations) {
      setproviderLocations(props.providerPracticeLocations)
    } else if (
      props.practiceInfo &&
      props.practiceInfo.providerPracticeLocations &&
      props.practiceInfo.providerPracticeLocations.length
    ) {
      setproviderLocations(props.practiceInfo.providerPracticeLocations)
    }

    tempPatientData = patientReq
    tempPatientData.pname.value = pName

    if (props.providerPracticeLocations) {
      tempPatientData.locationId.value = props.providerPracticeLocations.id
    } else if (
      props.practiceInfo &&
      props.practiceInfo.providerPracticeLocations &&
      props.practiceInfo.providerPracticeLocations.length
    ) {
      tempPatientData.locationId.value =
        props.practiceInfo.providerPracticeLocations[0].id
    }
    tempPatientData.pname.value = pName
    if (props.bookSlot?.startTime) {
      tempPatientData.preferAppointmentTime.value = props.bookSlot.startTime
    }
    if (props.bookSlot?.date) {
      tempPatientData.appointmentDayType.value = props.bookSlot.date
    }
    if (!tempPatientData.locationId.value) {
      tempPatientData.locationId.value = props?.bookSlot?.locationId
    }
    setpatientReq({ ...tempPatientData })
  }, [props])

  const getLocations = () => {
    return (
      <select
        name="locationId"
        className="select-service"
        onChange={modifyPracticeData}
        disabled={props.bookSlot.disabledLocation}
        value={patientReq.locationId.value}
      >
        {providerLocations.map((l, i) => (
          <option key={i + 1} value={l.id}>
            Location #{i + 1} {l.practiceName}
          </option>
        ))}
      </select>
    )
  }

  return (
    <Fragment>
      <Container>
        <div className="search-modal-wrap">
          <div className="edit-all-filter">
            <Row>
              {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
            </Row>
            <Row>
              <div className="col-12 px-0">
                <div className="modal-head">
                  {props.bookSlot ? 'Book' : 'Request'} Appointment
                </div>
              </div>
            </Row>
            <div className="allfilter-innerbox">
              <Row>
                <div className="col-12 mb-3 mb-lg-4">
                  <label className="pro-label">
                    Who is this appointment for?
                  </label>
                  <div className="myform req-appoint">
                    <select
                      className="select-service"
                      onChange={(e) => setUserId(e.target.value)}
                    >
                      {appointmentFor.length ? (
                        appointmentFor.map((user, index) => {
                          return (
                            <option key={index} value={user.id}>
                              {user.name}
                            </option>
                          )
                        })
                      ) : (
                        <option>Not Available</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-12 mb-3 mb-md-4">
                  <label className="pro-label">
                    Will you/your child be a new patient?
                  </label>
                  <fieldset
                    className="gender-allfilter isNewPatient"
                    disabled={noNewPatients}
                  >
                    <div className="cus-radio">
                      <input
                        type="radio"
                        id="yes"
                        onChange={modifyPracticeData}
                        name="isNewPatient"
                        value="1"
                        checked={patientReq.isNewPatient.value === '1'}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="cus-radio">
                      <input
                        type="radio"
                        id="no"
                        onChange={modifyPracticeData}
                        name="isNewPatient"
                        value="0"
                        checked={patientReq.isNewPatient.value === '0'}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </fieldset>
                  <small className="error-input">
                    {patientReq.isNewPatient.isNewPatient}
                  </small>
                </div>
                <div className="col-12 value-box mb-3">
                  <label className="pro-label">Preferred location?</label>
                  <div className="myform req-appoint">{getLocations()}</div>
                </div>
                <div className="col-12 value-box mb-3">
                  <label className="pro-label">
                    Preferred appointment time?
                  </label>
                  <div className="myform req-appoint">
                    <select
                      className="select-service"
                      name="preferAppointmentTime"
                      onChange={modifyPracticeData}
                      disabled={props.bookSlot.startTime ? true : false}
                    >
                      {props.bookSlot && props.bookSlot.startTime ? (
                        <>
                          <option value={props.bookSlot.startTime}>
                            {props.bookSlot.startTime}
                          </option>
                        </>
                      ) : (
                        <>
                          <option value="morning">Morning</option>
                          <option value="afternoon">Afternoon</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-12 value-box mb-3">
                  <label className="pro-label">
                    Preferred appointment type?
                  </label>
                  <div className="myform req-appoint">
                    <select
                      className="select-service"
                      name="preferAppointmentType"
                      onChange={modifyPracticeData}
                    >
                      <option value="In-person">In-person</option>
                      {(props.providers && props.providers.hasVirtualVisit) ||
                      (props.practiceInfo &&
                        props.practiceInfo.hasVirtualVisit) ? (
                        <>
                          <option value="Virtual Visit">Virtual Visit</option>
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-12 value-box mb-3">
                  <label className="pro-label">
                    When do you want this appointment?
                  </label>
                  <div className="myform req-appoint">
                    <select
                      className="select-service"
                      name="appointmentDayType"
                      onChange={modifyPracticeData}
                      disabled={props.bookSlot.date ? true : false}
                    >
                      {props.bookSlot && props.bookSlot.date ? (
                        <>
                          <option
                            value={props.bookSlot.date}
                          >{`${appDate[1]}/${appDate[2]}/${appDate[0]}`}</option>
                        </>
                      ) : (
                        <>
                          <option value="Next 7 Days">Next 7 Days</option>
                          <option value="Next 14 Days">Next 14 Days</option>
                          <option value="Next 30 Days">Next 30 Days</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-12 value-box mb-3">
                  <label className="pro-label">Visit type?</label>
                  <div className="myform req-appoint">
                    <select
                      className="select-service"
                      name="visitType"
                      onChange={modifyPracticeData}
                    >
                      <option value="Routine Checkup">Routine Checkup</option>
                      <option value="Follow-up on Previous Visit">
                        Follow-up on Previous Visit
                      </option>
                      <option value="Sick or Injured Visit">
                        Sick or Injured Visit
                      </option>
                      <option value="Consulation">Consulation</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 mb-2 textarea-description">
                  <label className="pro-label">
                    Short description of reason for visit?{' '}
                    <span>(Required)</span>
                  </label>
                  <textarea
                    id="description"
                    name="appointmentDescription"
                    onChange={modifyPracticeData}
                    className={
                      patientReq.appointmentDescription.appointmentDescription
                        ? 'input-pro  error-border'
                        : 'input-pro'
                    }
                    rows=""
                    cols=""
                  ></textarea>
                  <div className="textarea-note">
                    Note:{' '}
                    {patientReq.appointmentDescription.value
                      ? 300 - patientReq.appointmentDescription.value.length
                      : '300'}{' '}
                    character limit
                  </div>
                  <small className="error-input">
                    {patientReq.appointmentDescription.appointmentDescription}
                  </small>
                </div>

                <div className="col-12 mt-3 mb-4">
                  <div className="seaprator-title">
                    <span>
                      <img
                        src="/assets/images/lock-icon.png"
                        className="scale lock"
                        alt="lock"
                      />{' '}
                      Encrypted Account Data
                    </span>
                  </div>
                </div>
                {user && user.accountInfo ? (
                  <>
                    <div className="col-12 mb-3 mb-md-4">
                      <div className="demo-medical-wrap">
                        <div className="demo-left">
                          <div className="head">Demographic Information</div>
                          <div className="demo-medical-inner">
                            <div className="dmleft">Name:</div>
                            <div className="dmright">
                              {user.accountInfo.firstName
                                ? user.accountInfo.firstName + ' '
                                : ''}
                              {user.accountInfo.middleName
                                ? user.accountInfo.middleName + ' '
                                : ''}
                              {user.accountInfo.lastName
                                ? user.accountInfo.lastName
                                : ''}
                            </div>

                            <div className="dmleft">Sex:</div>
                            <div className="dmright capitalize">
                              {user.accountInfo.gender}
                            </div>

                            <div className="dmleft">Preferred Pronoun:</div>
                            <div className="dmright">
                              {user.accountInfo.preferredPronoun}
                            </div>

                            <div className="dmleft">Email Address:</div>
                            <div className="dmright">
                              {user.accountInfo.email}
                            </div>

                            <div className="dmleft">Phone Number:</div>
                            <div className="dmright">
                              {Global.phoneFormator(
                                user.accountInfo.phoneNumber
                              )}
                            </div>

                            <div className="dmleft">Birth Date:</div>
                            <div className="dmright">
                              {user.accountInfo.dob &&
                                Global.getDobString(user.accountInfo.dob)}
                            </div>
                          </div>
                        </div>

                        <div className="medical-right">
                          {providerSpecialities.includes('medical')
                            ? user.patientInsurances &&
                              user.patientInsurances.medical &&
                              user.patientInsurances.medical.map((mi, ins) => (
                                <>
                                  {ins === 0 ? (
                                    <div className="head">
                                      Medical Insurance Information
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="demo-medical-inner">
                                    <div className="dmleft">
                                      Insurance Name:
                                    </div>
                                    <div className="dmright">
                                      {mi.insurances ? mi.insurances.name : ''}
                                    </div>

                                    <div className="dmleft">Plan Name:</div>
                                    <div className="dmright">{mi.planName}</div>

                                    <div className="dmleft">Group ID#:</div>
                                    <div className="dmright">{mi.group}</div>

                                    <div className="dmleft">Member ID#:</div>
                                    <div className="dmright">
                                      {mi.membershipId}
                                    </div>
                                  </div>
                                </>
                              ))
                            : ''}

                          {providerSpecialities.includes('dental')
                            ? user.patientInsurances &&
                              user.patientInsurances.dental &&
                              user.patientInsurances.dental.map((mi, ins) => (
                                <>
                                  {ins === 0 ? (
                                    <>
                                      <div className="head">
                                        Dental Insurance Information
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="demo-medical-inner">
                                    <div className="dmleft">
                                      Insurance Name:
                                    </div>
                                    <div className="dmright">
                                      {mi.insurances ? mi.insurances.name : ''}
                                    </div>

                                    <div className="dmleft">Plan Name:</div>
                                    <div className="dmright">{mi.planName}</div>

                                    <div className="dmleft">Group ID#:</div>
                                    <div className="dmright">{mi.group}</div>

                                    <div className="dmleft">Member ID#:</div>
                                    <div className="dmright">
                                      {mi.membershipId}
                                    </div>
                                  </div>
                                </>
                              ))
                            : ''}
                          {providerSpecialities.includes('vision')
                            ? user.patientInsurances &&
                              user.patientInsurances.vision &&
                              user.patientInsurances.vision.map((mi, ins) => (
                                <>
                                  {ins === 0 ? (
                                    <>
                                      <div className="head">
                                        Vision Insurance Information
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="demo-medical-inner">
                                    <div className="dmleft">
                                      Insurance Name:
                                    </div>
                                    <div className="dmright">
                                      {mi.insurances ? mi.insurances.name : ''}
                                    </div>

                                    <div className="dmleft">Plan Name:</div>
                                    <div className="dmright">{mi.planName}</div>

                                    <div className="dmleft">Group ID#:</div>
                                    <div className="dmright">{mi.group}</div>

                                    <div className="dmleft">Member ID#:</div>
                                    <div className="dmright">
                                      {mi.membershipId}
                                    </div>
                                  </div>
                                </>
                              ))
                            : ''}
                          {providerSpecialities.includes('child')
                            ? user.patientInsurances &&
                              user.patientInsurances.child &&
                              user.patientInsurances.child.map((mi, ins) => (
                                <>
                                  {ins === 0 ? (
                                    <>
                                      <div className="head">
                                        Child Insurance Information
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="demo-medical-inner">
                                    <div className="dmleft">
                                      Insurance Name:
                                    </div>
                                    <div className="dmright">
                                      {mi.insurances ? mi.insurances.name : ''}
                                    </div>

                                    <div className="dmleft">Plan Name:</div>
                                    <div className="dmright">{mi.planName}</div>

                                    <div className="dmleft">Group ID#:</div>
                                    <div className="dmright">{mi.group}</div>

                                    <div className="dmleft">Member ID#:</div>
                                    <div className="dmright">
                                      {mi.membershipId}
                                    </div>
                                  </div>
                                </>
                              ))
                            : ''}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-12 mb-4 all-fltrbtn">
                  <input
                    type="button"
                    className="next-btn-pro"
                    onClick={callBackSubmit}
                    value={
                      props.bookSlot
                        ? 'Book Appointment'
                        : 'Request Appointment'
                    }
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default RequestAppointmentModal
