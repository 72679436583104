import React, { Fragment } from 'react'
import { Row } from 'react-bootstrap'

export const ChildrenInsurance = ({
  child,
  updateData,
  modifyData,
  SubmitButton,
  yearListChild,
  convertDob,
  monthList,
  insuranceList,
  changeFields,
  getInsuranceName,
  editable,
}) => {
  return (
    <>
      {editable ? (
        <Row className="no-gutters justify-content-between pb-4 pb-md-5">
          <div className="col-lg-8 col-md-7">
            <div className="account-edit-box">
              <div className="edit-caption">First Name:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="firstName"
                  placeholder=""
                  defaultValue={child ? child.firstName : ''}
                  autoComplete="off"
                  className={
                    updateData.child.firstName.firstName
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.firstName.firstName}
                </small>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Middle Name:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="middleName"
                  placeholder=""
                  defaultValue={child ? child.middleName : ''}
                  autoComplete="off"
                  className={
                    updateData.child.middleName.middleName
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.middleName.middleName}
                </small>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Last Name:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="lastName"
                  placeholder=""
                  defaultValue={child ? child.lastName : ''}
                  autoComplete="off"
                  className={
                    updateData.child.lastName.lastName
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.lastName.lastName}
                </small>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Child/Dependant Birthday:</div>
              <div className="account-birth-fields edit-field">
                <div>
                  <div className="myform">
                    <select
                      name="month"
                      defaultValue={child ? convertDob(child.dob).month : ''}
                      autoComplete="off"
                      className={
                        updateData.child.month.month
                          ? 'select  error-border'
                          : 'select'
                      }
                      onChange={(e) => modifyData(e)}
                    >
                      <option value="">Month</option>
                      {monthList.map((month) => (
                        <option key={month.id + '-month'} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {updateData.child.month.month}
                    </small>
                  </div>
                </div>
                <div>
                  <div className="myform">
                    <select
                      name="day"
                      defaultValue={child ? convertDob(child.dob).day : ''}
                      autoComplete="off"
                      className={
                        updateData.child.day.day
                          ? 'select  error-border'
                          : 'select'
                      }
                      onChange={(e) => modifyData(e)}
                    >
                      <option value="">Day</option>
                      {[...new Array(31)].map((day, day_index) => (
                        <option
                          key={1 + day_index + '-days'}
                          value={day_index + 1}
                        >
                          {day_index + 1}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {updateData.child.day.day}
                    </small>
                  </div>
                </div>
                <div>
                  <div className="myform">
                    <select
                      name="year"
                      defaultValue={child ? convertDob(child.dob).year : ''}
                      autoComplete="off"
                      className={
                        updateData.child.year.year
                          ? 'select  error-border'
                          : 'select'
                      }
                      onChange={(e) => modifyData(e)}
                    >
                      <option value="">Year</option>
                      {yearListChild.map((year) => (
                        <option key={year + '-year'} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <small className="error-input">
                      {updateData.child.year.year}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Insurance Name:</div>
              <div className="edit-field">
                <div className="myform">
                  <select
                    type="text"
                    name="insuranceId"
                    defaultValue={child ? child.insuranceId : ''}
                    autoComplete="off"
                    className={
                      updateData.child.insuranceId.insuranceId
                        ? 'select  error-border'
                        : 'select'
                    }
                    onChange={(e) => modifyData(e)}
                  >
                    <option value=""></option>
                    {insuranceList.map((mc, mci) => (
                      <Fragment key={mci}>
                        {
                          <option key={'child-' + mc.id} value={mc.id}>
                            {mc.name}
                          </option>
                        }
                      </Fragment>
                    ))}
                  </select>
                  <small className="error-input">
                    {updateData.child.insuranceId.insuranceId}
                  </small>
                </div>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Plan Name:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="planName"
                  placeholder=""
                  defaultValue={child ? child.planName : ''}
                  autoComplete="off"
                  className={
                    updateData.child.planName.planName
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.planName.planName}
                </small>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Group #:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="group"
                  placeholder=""
                  defaultValue={child ? child.group : ''}
                  autoComplete="off"
                  className={
                    updateData.child.group.group
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.group.group}
                </small>
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption"> Membership ID#:</div>
              <div className="edit-field">
                <input
                  type="text"
                  name="membershipId"
                  placeholder=""
                  defaultValue={child ? child.membershipId : ''}
                  autoComplete="off"
                  className={
                    updateData.child.membershipId.membershipId
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  onChange={(e) => modifyData(e)}
                />
                <small className="error-input">
                  {updateData.child.membershipId.membershipId}
                </small>
              </div>
            </div>
          </div>
          {SubmitButton()}
        </Row>
      ) : (
        <Row className="no-gutters position-relative">
          <div
            onClick={(e) => {
              changeFields(e, child)
            }}
            className="edit-icon-position-top"
          ></div>
          <div className="col-md-7">
            <div className="account-edit-box">
              <div className="edit-caption">First Name:</div>
              <div className="edit-field">{child ? child.firstName : ''}</div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Middle Name:</div>
              <div className="edit-field">{child ? child.middleName : ''}</div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Last Name:</div>
              <div className="edit-field">{child ? child.lastName : ''}</div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Child/Dependant Birthday:</div>
              <div className="edit-field">
                {child ? convertDob(child.dob, true) : <></>}
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Insurance Name:</div>
              <div className="edit-field">
                {child ? getInsuranceName(child.insuranceId) : ''}
              </div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Plan Name:</div>
              <div className="edit-field">{child ? child.planName : ''}</div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption">Group #:</div>
              <div className="edit-field">{child ? child.group : ''}</div>
            </div>
            <div className="account-edit-box">
              <div className="edit-caption"> Membership ID#:</div>
              <div className="edit-field">
                {child ? child.membershipId : ''}
              </div>
            </div>
          </div>
        </Row>
      )}
    </>
  )
}
