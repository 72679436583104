import 'bootstrap/dist/css/bootstrap.css'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../../global/modal/modal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import RequestAppointmentModal from '../../../components/doctor-search-result/req-appontment-modal'
import { Box, Select, Stack, Text } from '@chakra-ui/react'
import { Calendar } from '../Calendar/Calendar'
import { Scheduler } from '../Scheduler/Scheduler'
import { DayPicker } from '../DayPicker/DayPicker'
import { Modal } from './Modal'
import { DoctorProfile, Slot } from '../../types'
import { AppointmentModal } from './AppointmentModal'

interface Props {
  getProfile: () => void
  doctor: DoctorProfile
}

export const Content: React.FC<Props> = ({ doctor }) => {
  const authContext = useContext(AuthContext)

  const { addressInfo, practiceInfo, accountInfo, medicalSpeciality } = doctor

  const [startDate, setStartDate] = useState<Date>()
  const [showModal, setShowModal] = useState(false)
  const browserAddress = addressInfo?.formattedAddress
    ? addressInfo?.formattedAddress
    : undefined

  const [bookSlot, setBookSlot] = useState<Slot>()
  const [showAppointmentModal, setShowAppointmentModal] = useState(false)

  const [locationId, setLocationId] = useState<string>()
  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
    rightSection: false,
  })

  useEffect(() => {
    setShowModal(true)
  }, [])

  const openRequestModal = (slot?: Slot) => {
    setBookSlot(slot)
    setShowAppointmentModal(true)
    authContext.showModal()
  }

  useEffect(() => {
    setLocationId(practiceInfo.providerPracticeLocations[0].id.toString())
  }, [])

  const suspenseLoader = () => <p></p>

  return (
    <Suspense fallback={suspenseLoader()}>
      <Box p={8}>
        {showAppointmentModal ? (
          <Custommodal
            unsetComponent={setShowAppointmentModal}
            componentName={
              showAppointmentModal && bookSlot ? (
                <AppointmentModal
                  doctor={doctor}
                  bookSlot={bookSlot}
                  browserAddress={browserAddress}
                />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <></>
        )}
        <>
          {showModal && (
            <Modal
              timeZone={practiceInfo.providerPracticeLocations[0].timeZone}
              setShowModal={setShowModal}
            />
          )}
          <Stack gap={0}>
            <Text fontSize="24px" fontWeight="900" letterSpacing="1.1px">
              Book an appointment
            </Text>
            <Text fontSize="20px" color="text-secondary">
              with {accountInfo?.firstName} {accountInfo?.middleName}{' '}
              {accountInfo?.lastName}{' '}
              {medicalSpeciality?.providerMedicalCredientialsValue}
            </Text>
            <Text fontSize="20px" fontWeight="900" letterSpacing="1.1px">
              Appointment Location
            </Text>
            <Select
              className="select"
              onChange={(e) => setLocationId(e.currentTarget.value)}
              value={locationId ?? ''}
            >
              {practiceInfo?.providerPracticeLocations?.map(
                (location, index) => (
                  <option key={index} value={location.id}>
                    {location.practiceName} {location.practiceAddress}{' '}
                    {location.cities.name} {location.states.code}{' '}
                    {location.zipCode}
                  </option>
                )
              )}
            </Select>
          </Stack>
          <Stack>
            <Calendar startDate={startDate} setStartDate={setStartDate} />
            <DayPicker startDate={startDate} setStartDate={setStartDate} />
            <Scheduler
              timeZone={practiceInfo.providerPracticeLocations[0].timeZone}
              locationId={Number(locationId)}
              startDate={startDate}
              bookAppointment={openRequestModal}
            />
          </Stack>
        </>
        {authContext.responseModal && modalProps.rightSection ? (
          <Msgmodal {...modalProps} />
        ) : (
          <></>
        )}
      </Box>
    </Suspense>
  )
}
