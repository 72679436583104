import { Box, Flex, Stack } from '@chakra-ui/react'
import React, { useEffect, useReducer } from 'react'
import Pagination from '../../../components/pagination/pagination'
import { mobileVisibility } from '../../style/reusables'
import { Typography } from '../../style/typography'
import { Calendar } from '../Calendar/Calendar'
import { DoctorCard } from '../Cards/DoctorCard'
import { DayPicker } from '../DayPicker/DayPicker'
import { GoogleMaps } from '../ExternalServices/google/GoogleMaps'
import { SearchFilters } from './SearchFilters/SearchFilters'
import { Seo } from './Seo'
import { Sort } from './Sort'
import { useGetSearchData } from './hooks/useGetSearchData'
import { Filter } from './types'

interface State {
  activeDoctorProfileId: number
  startDate: Date
  isMapZoomed: boolean
  sort: string
  mapData: []
}

function reducer(state: State, action: any) {
  if (action.type === 'updateActiveDoctorId') {
    return {
      ...state,
      activeDoctorProfileId: action.value,
    }
  }
  if (action.type === 'setStartDate') {
    return {
      ...state,
      startDate: action.value,
    }
  }
  if (action.type === 'setIsMapZoomed') {
    return {
      ...state,
      isMapZoomed: action.value,
    }
  }
  if (action.type === 'setSort') {
    return {
      ...state,
      sort: action.value,
    }
  }
  if (action.type === 'setMapData') {
    return {
      ...state,
      mapData: action.value,
    }
  }
  throw Error('Unknown action.')
}

interface Props {
  userCategoryType: string
  filter: Filter
  updateFilter: (filter: Filter) => void
}

export const ResultsList: React.FC<Props> = ({ filter, updateFilter }) => {
  const [state, dispatch] = useReducer(reducer, {
    activeDoctorProfileId: null,
    startDate: new Date(),
    isMapZoomed: false,
    sort: 'distance',
    mapData: [],
  })

  const { searchResults, filteredResults, areaSupported, totalCount, page } =
    useGetSearchData(filter, state.sort)

  const changeLimit = (page: string) => {
    updateFilter({ ...filter, callApi: true, ...{ offset: parseInt(page) } })
    localStorage.setItem('offset', String(parseInt(page)))
  }

  useEffect(() => {
    const isFromProfile = localStorage.getItem('fromProfile')
    const offset = parseInt(localStorage.getItem('offset'))
    if (isFromProfile) {
      localStorage.removeItem('fromProfile')
      updateFilter({ ...filter, offset })
    }
  }, [])

  useEffect(() => {
    const locations = []

    dispatch({ type: 'setMapData', value: [] })
    if (filteredResults.length && searchResults.length == 0) {
      filteredResults.map((item) =>
        locations.push({
          lat: parseFloat(item.providerPracticeLocations[0]?.latitude),
          lng: parseFloat(item.providerPracticeLocations[0]?.longitude),
        })
      )
    } else if (searchResults.length && filteredResults.length == 0) {
      searchResults.map((item) =>
        locations.push({
          lat: parseFloat(item.providerPracticeLocations[0]?.latitude),
          lng: parseFloat(item.providerPracticeLocations[0]?.longitude),
        })
      )
    }
    dispatch({ type: 'setMapData', value: locations })
  }, [filteredResults, searchResults])

  useEffect(() => {
    if (state.activeDoctorProfileId !== null) {
      const element = document.getElementsByClassName(
        `card-${state.activeDoctorProfileId}`
      )
      element[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }
  }, [state.activeDoctorProfileId])

  useEffect(() => {
    dispatch({ type: 'updateActiveDoctorId', value: null })
  }, [filteredResults.length, searchResults.length])

  useEffect(() => {
    if (filter.city !== '' || filter.zip !== '' || filter.latitude !== null) {
      dispatch({ type: 'setIsMapZoomed', value: true })
    } else if (filter.city === '' && filter.zip === '') {
      dispatch({ type: 'setIsMapZoomed', value: false })
    }
  }, [filter.city, filter.zip, filter.latitude])

  const handleLoadMore = (e) => {
    e.preventDefault()
    updateFilter({ ...filter, offset: page * 10 })
  }

  const result = filter.available72hours ? filteredResults : searchResults

  return (
    <Flex gap={8} mt={8} direction="row">
      <Flex width={'100%'} direction="column">
        <Seo />
        <Flex direction="column">
          <Box borderBottom="1px solid" borderColor="border-gray" mb={8} pb={2}>
            <Typography
              color="text-dark-gray"
              fontWeight={500}
            >{`${totalCount} results`}</Typography>
          </Box>
          <Flex justifyContent="space-between" alignItems="center">
            <SearchFilters filter={filter} setFilter={updateFilter} />
            <Calendar setStartDate={() => searchResults} />
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mb={8}
            position="sticky"
            top={0}
            bg="white"
            zIndex={2}
          >
            <Box mt={4}>
              <Sort setSort={(value) => dispatch({ type: 'setSort', value })} />
            </Box>
            <Box mb={4} display={mobileVisibility} mt={8}>
              <DayPicker
                startDate={state.startDate}
                setStartDate={(value) =>
                  dispatch({ type: 'setStartDate', value })
                }
              />
            </Box>
          </Flex>
          <Stack gap={8}>
            {!areaSupported ? (
              <Box m={24}>
                <h1>Service Area Coming Soon</h1>
              </Box>
            ) : (
              result?.map((item) => {
                return (
                  <DoctorCard
                    key={item.id}
                    provider={item}
                    selected={state.activeDoctorProfileId === item.id}
                    startDate={state.startDate}
                  />
                )
              })
            )}
          </Stack>
        </Flex>
        <Box>
          {filter.available72hours ? (
            <Box>
              <button onClick={(e) => handleLoadMore(e)}>Load more</button>
            </Box>
          ) : (
            <Pagination
              total={totalCount}
              limit={filter.limit}
              range={4}
              offset={filter.offset}
              callBack={changeLimit}
            />
          )}
        </Box>
      </Flex>
      <Box mt={9} display={mobileVisibility} width={'50%'}>
        {state.mapData.length > 0 && (
          <GoogleMaps
            onMarkerClick={(value: string) =>
              dispatch({ type: 'updateActiveDoctorId', value: value })
            }
            items={searchResults.length > 0 ? searchResults : filteredResults}
            mapData={state.mapData}
            zoomLabel={!state.isMapZoomed ? 3 : 10}
          />
        )}
      </Box>
    </Flex>
  )
}
