import AuthContext from '../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../reactLayer/Network/Network'
import { useContext, useEffect, useState } from 'react'
import { AnswerForm } from './answer-form'

const getInitialState = (type) => {
  const actionType = type === 'admin' ? 'welcome' : undefined
  return {
    description: '',
    actionType: actionType,
    action: '',
  }
}

export const Answers = ({ question, fetchList, questionType }) => {
  const authContext = useContext(AuthContext)

  const propsAnswer = question?.answers || []

  const initialState = getInitialState(questionType)

  const [answer, setAnswer] = useState(initialState)

  useEffect(() => {
    if (propsAnswer) {
      setAnswer(propsAnswer[0])
    } else {
      setAnswer(initialState)
    }
  }, [question?.answers])

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const onDelete = (id) => {
    deleteAnswer(id)
  }

  const saveAnswer = async () => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: answer.id,
      questionId: answer.questionId,
      description: answer.description,
    }

    if (answer.actionType) {
      formData.actionType = answer.actionType
    }
    if (answer.action) {
      formData.action = answer.action
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/qa/answers',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setModalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      fetchList()
    } else {
      setModalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const deleteAnswer = async (id) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/qa/answers',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.deleteData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setModalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      fetchList()
    } else {
      setModalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const handleAnswerChange = (e, type) => {
    const value = e?.currentTarget?.value
    const obj = {
      questionId: question.id,
      ...answer,
      ...{ [type]: value },
    }
    setAnswer(obj)
  }

  return (
    <div>
      <div className="content-management-outer">
        <div className={` content-manage-inner `}>
          <AnswerForm
            answer={answer}
            question={question?.description}
            onChange={handleAnswerChange}
          />
          <div className="content-style answer">
            <span
              style={{
                color: '#077db4',
                cursor: 'pointer',
              }}
              onClick={saveAnswer}
            >
              Save Change
            </span>
            &nbsp; / &nbsp;
            <span
              style={{
                color: '#077db4',
                cursor: 'pointer',
              }}
              onClick={() => onDelete(answer.id)}
            >
              Delete Answer
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
