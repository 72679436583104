import { useEffect, useState } from 'react'

const url =
  'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCMyxndSOM0V7TUyaI2gT2U6SNX1LE3dgo'

export const useIPLocation = () => {
  const [data, setData] = useState()
  const fetchLocation = async () => {
    try {
      const response = fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          considerIp: true,
        }),
      })
      const data = await (await response).json()
      setData(data?.location)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchLocation()
  }, [])

  return {
    location: data,
  }
}
