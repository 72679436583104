import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row, Col } from 'react-bootstrap'

const Recentreview = (props) => {
  const { recentReview, positive, negative, lastUpdate } = props

  const positivePersent = (positive / recentReview) * 100
  const negativePersent = (negative / recentReview) * 100

  return (
    <Fragment>
      <Container>
        <Row className="mb-4">
          <div className="col-12">
            <div className="recent-review">
              <Row>
                <div className="col-md-6 heading-p">Recent Reviews</div>
                <div className="col-md-6 heading-s">
                  Last update: {lastUpdate}
                  <div className="pn-txt">
                    <span className="info-hg">
                      <div className="positive"></div>
                      Positive
                      <div className="info-icon"></div>
                      <div className="info-callout bottom-left post">
                        {' '}
                        Positive reviews are ratings of 3 stars or higher from
                        patients.{' '}
                      </div>
                    </span>
                    <span className="info-hg">
                      <div className="negative"></div>
                      Negative
                      <div className="info-icon"></div>
                      <div className="info-callout bottom-left">
                        {' '}
                        Negative reviews are ratings of 2 stars or lower from
                        patients.{' '}
                      </div>
                    </span>
                  </div>
                </div>

                <div className="col-md-3 slider-txt-container">
                  <div className="title">Recent Reviews</div>
                  <span className="digit">{recentReview}</span>
                </div>

                <div className="col-md-8 offset-md-1 slider-container">
                  <div
                    className="slider-positive"
                    style={{ width: positivePersent + '%' }}
                  >
                    {positive}
                  </div>
                  <div
                    className="slider-negative"
                    style={{ width: negativePersent + '%' }}
                  >
                    {negative}
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Recentreview
