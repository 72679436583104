import React, { Fragment, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../reactLayer/auth/AuthContext'

import 'bootstrap/dist/css/bootstrap.css'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ProviderStep1 from '../../components/provider-steps/provider-step1'
import ProviderStep2 from '../../components/provider-steps/provider-step2'
import ProviderStep3 from '../../components/provider-steps/provider-step3'
import ProviderStep4 from '../../components/provider-steps/provider-step4'
import ProviderStep5 from '../../components/provider-steps/provider-step5'
import Error404 from '../../global/errorpages/error-404'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header'
import { ProviderLinks } from '../../linkFile'

const ProviderRegister = (props) => {
  const validLinks = [
    'account-information',
    'medical-specialty',
    'practice-information',
    'select-plan',
    'payment',
  ]
  const authContext = useContext(AuthContext)
  const user = authContext.user
  const history = useHistory()

  const goPrevious = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-prev-page')
    history.push({
      pathname: previousPage,
    })
  }

  const Validatepages = () => {
    if (validLinks.indexOf(props.match.params.type) > '-1') {
      if (props.match.params.type === 'account-information') {
        if (authContext.user == null || authContext.user.step) {
          return (
            <Container fluid className="provider-steps-wrap">
              <ProviderStep1 />
            </Container>
          )
        } else {
          if (
            user &&
            user.accountInfo.isProfileCompleted &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId === 3
          ) {
            history.push({
              pathname: ProviderLinks.dashboard,
            })
            return
          } else if (
            user &&
            user.accountInfo &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId !== 3
          ) {
            authContext.logout()
            history.push({
              pathname: ProviderLinks.register_step1,
            })
          } else {
            if (user.step) {
              history.push({
                pathname: ProviderLinks['register_step' + user.step],
              })
            } else {
              return (
                <Container fluid className="provider-steps-wrap">
                  <ProviderStep1 />
                </Container>
              )
            }

            // return <Container fluid className="provider-steps-wrap"><ProviderStep1/></Container>;
          }
        }
      } else {
        if (
          user &&
          user.accountInfo.isProfileCompleted &&
          !user.accountInfo &&
          user.accountInfo.roleId &&
          user.accountInfo.roleId === 3
        ) {
          history.push({
            pathname: ProviderLinks.dashboard,
          })
          return
        } else if (
          (user &&
            user.accountInfo &&
            user.accountInfo.roleId &&
            user.accountInfo.roleId != 3) ||
          !user
        ) {
          authContext.logout()
          history.push({
            pathname: ProviderLinks.register_step1,
          })
        } else {
          if (props.match.params.type === 'medical-specialty') {
            return (
              <Container fluid className="provider-steps-wrap">
                <ProviderStep2 {...{ goPrevious: goPrevious }} />
              </Container>
            )
          } else if (props.match.params.type === 'practice-information') {
            return (
              <Container fluid className="provider-steps-wrap">
                <ProviderStep3 {...{ goPrevious: goPrevious }} />
              </Container>
            )
          } else if (props.match.params.type === 'select-plan') {
            return (
              <Container fluid className="provider-step4-wrap">
                <ProviderStep4 {...{ goPrevious: goPrevious }} />
              </Container>
            )
          } else {
            return (
              <Container fluid className="provider-steps-wrap">
                <ProviderStep5 {...{ goPrevious: goPrevious, ...props }} />
              </Container>
            )
          }
        }
      }
    } else {
      return <Error404 />
    }
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | DocScheduler Accounts </title>
        <meta
          name="description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile "
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/provider/register/${props.match.params.type}`}
        />
        <meta name="twitter:card" content="DocScheduler Accounts" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta
          property="og:title"
          content="DocScheduler | DocScheduler Accounts"
        />
        <meta
          name="twitter:title"
          content="DocScheduler | DocScheduler Accounts"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile "
        />
        <meta
          name="twitter:description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile "
        />
        <link
          rel="canonical"
          href={`https://www.docscheduler.com/provider/register/${props.match.params.type}`}
        />
      </Helmet>
      <Container className="header px-0">
        <HeaderMain />
      </Container>
      {Validatepages()}
      <Footer />
    </Fragment>
  )
}

export default ProviderRegister
