import 'bootstrap/dist/css/bootstrap.css'
import React, {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Container, Row } from 'react-bootstrap'
import { PatientLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'

import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import Custommodal from '../../global/modal/modal'
import InputValidation from '../../reactLayer/validation'
import Twostep from '../provider-login/twostep'
import CreateAccountStep from './create-acc-step'

const CreateAccountStep3 = (props) => {
  const authContext = useContext(AuthContext)
  const [insuranceList, setinsuranceList] = useState([])
  const monthList = Global.getCurrentYear()
  const yearList = Global.getYearListChild()
  const getInsurance = async () => {
    let insResponse = await Global.getInsurance()
    setinsuranceList(insResponse)
  }
  const [buttonName, setbuttonName] = useState('')
  const [patientInsCategory, setpatientInsCategory] = useState({
    medical: [],
    dental: [],
    vision: [],
    child: [],
  })

  const [privacyCheck, setprivacyCheck] = useState({
    privacy: {
      value: '',
      validation: ['blank'],
      errorMsg: ['Accept Privacy Policy'],
      errorKey: 'privacy',
      privacy: false,
    },
    hippa: {
      value: '',
      validation: ['blank'],
      errorMsg: ['Accept HIPPA Policy'],
      errorKey: 'hippa',
      hippa: false,
    },
  })

  const [visibleComponent, setVisibleComponent] = useState('')

  const getFields = (e, category = 'medical') => {
    e && e.preventDefault()
    let fields = {
      insuranceId: {
        value: '',
        validation: [],
        errorMsg: [],
        errorKey: 'insuranceId',
        insuranceId: false,
      },
      membershipId: {
        value: '',
        validation: ['alphanumeric'],
        errorMsg: ['Invalid Membership Id'],
        errorKey: 'membershipId',
        membershipId: false,
      },
      planName: {
        value: '',
        validation: [],
        errorMsg: [],
        errorKey: 'planName',
        planName: false,
      },
      group: {
        value: '',
        validation: [],
        errorMsg: [],
        errorKey: 'group',
        group: false,
      },
    }
    if (category === 'child') {
      let childFields = {
        fname: {
          value: '',
          validation: ['blank', 'name'],
          errorMsg: ['This field is required', 'Invalid First Name'],
          errorKey: 'fname',
          fname: false,
        },
        mname: {
          value: '',
          validation: ['name'],
          errorMsg: ['Invalid Middle Name'],
          errorKey: 'mname',
          mname: false,
        },
        lname: {
          value: '',
          validation: ['blank', 'name'],
          errorMsg: ['This field is required', 'Invalid Last Name'],
          errorKey: 'lname',
          lname: false,
        },
        day: {
          value: '',
          validation: ['blank', 'number'],
          errorMsg: ['This field is required'],
          errorKey: 'day',
          dob: false,
        },
        month: {
          value: '',
          validation: ['blank', 'number'],
          errorMsg: ['This field is required'],
          errorKey: 'month',
          dob: false,
        },
        year: {
          value: '',
          validation: ['blank', 'number'],
          errorMsg: ['This field is required'],
          errorKey: 'year',
          dob: false,
        },
      }
      fields = { ...fields, ...childFields }
    }
    let temp = patientInsCategory
    temp[category].push(fields)
    setpatientInsCategory({ ...temp })
  }
  const DeleteElement = (elementName, index) => {
    let tempData = {}
    tempData = patientInsCategory
    tempData[elementName][index] && tempData[elementName].splice(index, 1)
    setpatientInsCategory({ ...tempData })
  }
  const DeleteSection = (props) => {
    return (
      <>
        <div
          onClick={() => DeleteElement(props.name, props.index)}
          className="delete-icon"
          alt="Delete"
        ></div>
      </>
    )
  }
  const modifyData = (e, varName) => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')

    if (name == 'privacy' || name == 'hippa') {
      value = e.currentTarget.value
      tempData = privacyCheck
      if (e.currentTarget.checked === true) {
        tempData[name]['value'] = value
      } else {
        tempData[name]['value'] = ''
      }
      tempData[name][name] = ''
      setprivacyCheck({ ...tempData })
      setTimeout(() => {
        let errorFilds = document.querySelectorAll('.error-border')
        if (errorFilds.length) {
          errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    } else {
      value = e.currentTarget.value
      tempData = patientInsCategory
      tempData[varName][index][name]['value'] = value
      tempData[varName][index][name][name] = ''

      setpatientInsCategory({ ...tempData })
    }
  }
  const formSubmit = async () => {
    let tempData = patientInsCategory
    let date = new Date()
    let todayDate =
      date.getFullYear() +
      '-' +
      (parseInt(date.getMonth()) + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0') +
      ' 00:00'
    let currentDate = new Date(todayDate).getTime()
    let userDob = ''
    let formDataIns = {
      medical: [],
      dental: [],
      vision: [],
      child: [],
    }
    let flag = true
    let setFocus = false
    for (const data in tempData) {
      tempData[data].forEach((currentValue, index) => {
        let validationResult = InputValidation(currentValue)
        let tempFormData = {}
        if (data == 'child') {
          tempFormData.firstName = currentValue.fname.value
          tempFormData.middleName = currentValue.mname.value
          tempFormData.lastName = currentValue.lname.value
          tempFormData.dob =
            currentValue.month.value.toString().padStart(2, '0') +
            '-' +
            currentValue.day.value.toString().padStart(2, '0') +
            '-' +
            currentValue.year.value
          userDob =
            currentValue.year.value +
            '-' +
            currentValue.month.value.toString().padStart(2, '0') +
            '-' +
            currentValue.day.value.toString().padStart(2, '0') +
            ' 00:00'
          userDob = new Date(userDob).getTime()
        }
        tempFormData.insuranceId = currentValue.insuranceId.value
        tempFormData.membershipId = currentValue.membershipId.value
        tempFormData.planName = currentValue.planName.value
        tempFormData.group = currentValue.group.value
        formDataIns[data].push(tempFormData)
        if (Object.keys(validationResult).length > 0) {
          flag = false
          for (const prop in validationResult) {
            tempData[data][index][prop][prop] = `${validationResult[prop]}`
            if (!flag && !setFocus) {
              setFocus = true
            }
          }
        } else {
          if (data == 'child') {
            if (userDob > currentDate) {
              flag = false
              tempData[data][index]['year']['year'] = `Invalid Date of Birth`
            }
          }
        }
        setpatientInsCategory({ ...tempData })
      })
    }
    let validationResult = InputValidation(privacyCheck)
    if (Object.keys(validationResult).length > 0) {
      flag = false
      let tempData = privacyCheck
      for (const prop in validationResult) {
        tempData[prop][prop] = `${validationResult[prop]}`
        if (!flag && !setFocus) {
          setFocus = true
        }
      }
      setprivacyCheck({ ...tempData })
    }

    if (flag) {
      authContext.setLoader()
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'patients/insurance-information',
        body: JSON.stringify({
          patient_insurances: formDataIns,
          isRegister: true,
          isSocialProvider: authContext.user.accountInfo.oauth_provider,
        }),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()

      if (result.body.status) {
        let user = result.body.result
        authContext.setUser(user)
        if (user.accountInfo && user.accountInfo.oauth_provider) {
          setmodalProps({
            type: 'success',
            msg: result.body.message,
            closeredirection: PatientLinks.dashboard,
          })
          authContext.showResponseModal()
        } else {
          setVisibleComponent('twostep')
          authContext.showModal()
        }
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    } else {
      setTimeout(() => {
        let errorFilds = document.querySelectorAll('.error-border')
        if (errorFilds.length) {
          errorFilds[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 100)
    }
  }
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  useEffect(() => {
    getInsurance()
    getFields()
  }, [])

  const checkButtonName = () => {
    if (patientInsCategory.medical.length) {
      if (
        patientInsCategory.medical[0].insuranceId.value ||
        patientInsCategory.medical[0].membershipId.value ||
        patientInsCategory.medical[0].planName.value ||
        patientInsCategory.medical[0].group.value
      ) {
        return 'Next'
      }
    }

    if (patientInsCategory.vision.length) {
      if (
        patientInsCategory.vision[0].insuranceId.value ||
        patientInsCategory.vision[0].membershipId.value ||
        patientInsCategory.vision[0].planName.value ||
        patientInsCategory.vision[0].group.value
      ) {
        return 'Next'
      }
    }

    if (patientInsCategory.dental.length) {
      if (
        patientInsCategory.dental[0].insuranceId.value ||
        patientInsCategory.dental[0].membershipId.value ||
        patientInsCategory.dental[0].planName.value ||
        patientInsCategory.dental[0].group.value
      ) {
        return 'Next'
      }
    }

    if (patientInsCategory.child.length) {
      if (
        patientInsCategory.child[0].insuranceId.value ||
        patientInsCategory.child[0].membershipId.value ||
        patientInsCategory.child[0].planName.value ||
        patientInsCategory.child[0].group.value
      ) {
        return 'Next'
      }
      if (
        patientInsCategory.child[0].fname.value ||
        patientInsCategory.child[0].mname.value ||
        patientInsCategory.child[0].lname.value ||
        patientInsCategory.child[0].day.value ||
        patientInsCategory.child[0].month.value ||
        patientInsCategory.child[0].year.value
      ) {
        return 'Next'
      }
    }
    return 'Skip'
  }
  useEffect(() => {
    setbuttonName(checkButtonName())
  }, [patientInsCategory])

  return (
    <Fragment>
      {/* Form start step 1 */}
      <Suspense>
        <CreateAccountStep step="3" />
        <Container className="create-acc-steps-wrap">
          <div className="create-acc-inner-step2 create-acc-inner-step3">
            <Row>
              {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
              <Custommodal
                unsetComponent={setVisibleComponent}
                componentName={
                  visibleComponent === 'twostep' && (
                    <Twostep
                      settabvisibleComponent={setVisibleComponent}
                      ltype={'patient'}
                      {...props}
                    />
                  )
                }
              />
            </Row>
            <Row>
              <div className="col-12">
                <div className="seaprator-title">
                  <span>
                    <img
                      src="/assets/images/lock-icon.png"
                      className="scale lock"
                      alt="lock"
                    />{' '}
                    Encrypted Account Data
                  </span>
                </div>
              </div>
            </Row>

            <Row>
              <div className="col-12 mb-4">
                <div className="ca3-add-links">
                  {patientInsCategory.medical.length ? (
                    <>
                      {patientInsCategory.medical.map((data, index) => (
                        <Row key={'medical-' + index}>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">
                              Medical Insurance Name
                            </label>
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.insuranceId.insuranceId
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                value={data.insuranceId.value}
                                data-index={index}
                                data-name="insuranceId"
                                onChange={(e) => modifyData(e, 'medical')}
                              >
                                <option value="">Choose</option>
                                {insuranceList.map((mc, mci) => (
                                  <>
                                    {mc.category == 'medical' ? (
                                      <>
                                        <option
                                          key={'medical-' + mc.id + '-' + index}
                                          value={mc.id}
                                        >
                                          {mc.name}
                                        </option>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </select>
                              <small className="error-input">
                                {data.insuranceId.insuranceId}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">Plan Name</label>
                            <input
                              type="text"
                              name="pName"
                              data-name={'planName'}
                              data-index={index}
                              placeholder="Plan Name"
                              autoComplete="off"
                              className={
                                data.planName.planName
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'medical')}
                              value={data.planName.value}
                            />
                            <small className="error-input">
                              {data.planName.planName}
                            </small>
                          </div>

                          <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                            <label className="pro-label">Group (GRP) #</label>
                            <input
                              type="text"
                              name="mId"
                              data-name={'group'}
                              data-index={index}
                              placeholder="Group#"
                              autoComplete="off"
                              className={
                                data.group.group
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'medical')}
                              value={data.group.value}
                            />
                            <small className="error-input">
                              {data.group.group}
                            </small>
                          </div>
                          <div className="col-md-6 no-padding-for-small">
                            <label className="pro-label">Membership ID#</label>
                            <input
                              type="text"
                              name="grp"
                              data-name={'membershipId'}
                              data-index={index}
                              placeholder="Membership ID#"
                              autoComplete="off"
                              className={
                                data.membershipId.membershipId
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'medical')}
                              value={data.membershipId.value}
                            />
                            <small className="error-input">
                              {data.membershipId.membershipId}
                            </small>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="bday-use info-hg right">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                How will my insurance be used?
                              </a>
                              <div className="info-callout right">
                                Providing your insurance information helps
                                DocScheduler find doctors who accept your health
                                insurance and allows us to send your information
                                to doctors to help book appointments.
                              </div>
                            </div>
                          </div>
                        </Row>
                      ))}
                    </>
                  ) : (
                    <>
                      <span className="col-12 no-padding-for-small">
                        <a
                          href="/#"
                          onClick={(e) => getFields(e, 'medical')}
                          className="under-line"
                        >
                          + Add Medical Insurance
                        </a>
                      </span>
                    </>
                  )}
                  {patientInsCategory.dental.length ? (
                    <>
                      {patientInsCategory.dental.map((data, index) => (
                        <Row
                          className="position-relative"
                          key={'dental-' + index}
                        >
                          <>
                            <div className="col-12 no-padding-for-small">
                              <a
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                                className=""
                              >
                                Dental Insurance
                              </a>
                            </div>
                          </>
                          {index >= 0 ? (
                            <DeleteSection name="dental" index={index} />
                          ) : (
                            <></>
                          )}
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">
                              Dental Insurance Name
                            </label>
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.insuranceId.insuranceId
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                value={data.insuranceId.value}
                                data-index={index}
                                data-name="insuranceId"
                                onChange={(e) => modifyData(e, 'dental')}
                              >
                                <option value="">Choose</option>
                                {insuranceList.map((mc, mci) => (
                                  <>
                                    {mc.category == 'dental' ? (
                                      <>
                                        <option
                                          key={'dental-' + mc.id + '-' + index}
                                          value={mc.id}
                                        >
                                          {mc.name}
                                        </option>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </select>
                              <small className="error-input">
                                {data.insuranceId.insuranceId}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">Plan Name</label>
                            <input
                              type="text"
                              name="pName"
                              data-name={'planName'}
                              data-index={index}
                              placeholder="Plan Name"
                              autoComplete="off"
                              className={
                                data.planName.planName
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'dental')}
                              value={data.planName.value}
                            />
                            <small className="error-input">
                              {data.planName.planName}
                            </small>
                          </div>

                          <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                            <label className="pro-label">Group (GRP) #</label>
                            <input
                              type="text"
                              name="mId"
                              data-name={'group'}
                              data-index={index}
                              placeholder="Group#"
                              autoComplete="off"
                              className={
                                data.group.group
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'dental')}
                              value={data.group.value}
                            />
                            <small className="error-input">
                              {data.group.group}
                            </small>
                          </div>
                          <div className="col-md-6 no-padding-for-small">
                            <label className="pro-label">Membership ID#</label>
                            <input
                              type="text"
                              name="grp"
                              data-name={'membershipId'}
                              data-index={index}
                              placeholder="Membership ID#"
                              autoComplete="off"
                              className={
                                data.membershipId.membershipId
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'dental')}
                              value={data.membershipId.value}
                            />
                            <small className="error-input">
                              {data.membershipId.membershipId}
                            </small>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="bday-use info-hg right">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                How will my insurance be used?
                              </a>
                              <div className="info-callout right">
                                Providing your insurance information helps
                                DocScheduler find doctors who accept your health
                                insurance and enables us to send your
                                information to doctors to help book
                                appointments.
                              </div>
                            </div>
                          </div>
                        </Row>
                      ))}
                    </>
                  ) : (
                    <>
                      <span className="col-12 no-padding-for-small">
                        <a
                          href="/#"
                          onClick={(e) => getFields(e, 'dental')}
                          className="under-line"
                        >
                          + Add Dental Insurance
                        </a>
                      </span>
                    </>
                  )}
                  {patientInsCategory.vision.length ? (
                    <>
                      {patientInsCategory.vision.map((data, index) => (
                        <Row
                          className="position-relative"
                          key={'vision-' + index}
                        >
                          <>
                            <div className="col-12 no-padding-for-small">
                              <a
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                                className=""
                              >
                                Vision Insurance
                              </a>
                            </div>
                          </>
                          {index >= 0 ? (
                            <DeleteSection name="vision" index={index} />
                          ) : (
                            <></>
                          )}
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">
                              Vision Insurance Name
                            </label>
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.insuranceId.insuranceId
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                value={data.insuranceId.value}
                                data-index={index}
                                data-name="insuranceId"
                                onChange={(e) => modifyData(e, 'vision')}
                              >
                                <option value="">Choose</option>
                                {insuranceList.map((mc, mci) => (
                                  <option
                                    key={'vision-' + mc.id + '-' + index}
                                    value={mc.id}
                                  >
                                    {mc.name}
                                  </option>
                                ))}
                              </select>
                              <small className="error-input">
                                {data.insuranceId.insuranceId}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">Plan Name</label>
                            <input
                              type="text"
                              name="pName"
                              data-name={'planName'}
                              data-index={index}
                              placeholder="Plan Name"
                              autoComplete="off"
                              className={
                                data.planName.planName
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'vision')}
                              value={data.planName.value}
                            />
                            <small className="error-input">
                              {data.planName.planName}
                            </small>
                          </div>

                          <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                            <label className="pro-label">Group (GRP) #</label>
                            <input
                              type="text"
                              name="mId"
                              data-name={'group'}
                              data-index={index}
                              placeholder="Group#"
                              autoComplete="off"
                              className={
                                data.group.group
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'vision')}
                              value={data.group.value}
                            />
                            <small className="error-input">
                              {data.group.group}
                            </small>
                          </div>
                          <div className="col-md-6 no-padding-for-small">
                            <label className="pro-label">Membership ID#</label>
                            <input
                              type="text"
                              name="grp"
                              data-name={'membershipId'}
                              data-index={index}
                              placeholder="Membership ID#"
                              autoComplete="off"
                              className={
                                data.membershipId.membershipId
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'vision')}
                              value={data.membershipId.value}
                            />
                            <small className="error-input">
                              {data.membershipId.membershipId}
                            </small>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="bday-use info-hg right">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                How will my insurance be used?
                              </a>
                              <div className="info-callout right">
                                Providing your insurance information helps
                                DocScheduler find doctors who accept your health
                                insurance and enables us to send your
                                information to doctors to help book
                                appointments.
                              </div>
                            </div>
                          </div>
                        </Row>
                      ))}
                    </>
                  ) : (
                    <>
                      <span className="col-12 no-padding-for-small">
                        <a
                          href="/#"
                          onClick={(e) => getFields(e, 'vision')}
                          className="under-line"
                        >
                          + Add Vision Insurance
                        </a>
                      </span>
                    </>
                  )}
                  {patientInsCategory.child.length ? (
                    <>
                      {patientInsCategory.child.map((data, index) => (
                        <Row
                          className="position-relative"
                          key={'child-' + index}
                        >
                          <>
                            <div className="col-12 no-padding-for-small">
                              <a
                                href="/#"
                                onClick={(e) => e.preventDefault()}
                                className=""
                              >
                                Child and Child's Insurance
                              </a>
                            </div>
                          </>
                          {index >= 0 ? (
                            <DeleteSection name="child" index={index} />
                          ) : (
                            <></>
                          )}
                          <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                            <label className="pro-label">First Name</label>
                            <input
                              type="text"
                              name="fname"
                              data-name={'fname'}
                              data-index={index}
                              placeholder="First Name"
                              autoComplete="off"
                              className={
                                data.fname.fname
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z-+()]*$/.test(e.currentTarget.value)
                                ) {
                                  modifyData(e, 'child')
                                }
                              }}
                              value={data.fname.value}
                            />
                            <small className="error-input">
                              {data.fname.fname}
                            </small>
                          </div>
                          <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                            <label className="pro-label">Middle Name</label>
                            <input
                              type="text"
                              name="mname"
                              data-name={'mname'}
                              data-index={index}
                              placeholder="Middle Name"
                              autoComplete="off"
                              className={
                                data.mname.mname
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z-+()]*$/.test(e.currentTarget.value)
                                ) {
                                  modifyData(e, 'child')
                                }
                              }}
                              value={data.mname.value}
                            />
                            <small className="error-input">
                              {data.mname.mname}
                            </small>
                          </div>
                          <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                            <label className="pro-label">Last Name</label>
                            <input
                              type="text"
                              name="lname"
                              data-name={'lname'}
                              data-index={index}
                              placeholder="Last Name"
                              autoComplete="off"
                              className={
                                data.lname.lname
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z-+()]*$/.test(e.currentTarget.value)
                                ) {
                                  modifyData(e, 'child')
                                }
                              }}
                              value={data.lname.value}
                            />
                            <small className="error-input">
                              {data.lname.lname}
                            </small>
                          </div>

                          <div className="col-12">
                            <label className="pro-label">Date of Birth</label>
                          </div>
                          <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.month.month
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                data-index={index}
                                data-name="month"
                                onChange={(e) => modifyData(e, 'child')}
                                value={data.month.value}
                              >
                                <option value="">Month</option>
                                {monthList.map((month, index_index) => (
                                  <option
                                    key={month.id + '-month'}
                                    value={month.id}
                                  >
                                    {month.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <small className="error-input">
                              {data.month.month}
                            </small>
                          </div>
                          <div className="col-sm-4 no-padding-for-small mb-3 mb-sm-0">
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.day.day
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                data-index={index}
                                data-name="day"
                                onChange={(e) => modifyData(e, 'child')}
                                value={data.day.value}
                              >
                                <option value="">Day</option>
                                {[...new Array(31)].map((day, day_index) => (
                                  <option
                                    key={1 + day_index + '-days'}
                                    value={day_index + 1}
                                  >
                                    {day_index + 1}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <small className="error-input">
                              {data.day.day}
                            </small>
                          </div>
                          <div className="col-sm-4 no-padding-for-small">
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.year.year
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                data-index={index}
                                data-name="year"
                                onChange={(e) => modifyData(e, 'child')}
                                value={data.year.value}
                              >
                                <option value="">Year</option>
                                {yearList.map((year) => (
                                  <option key={year + '-year'} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <small className="error-input">
                              {data.year.year}
                            </small>
                          </div>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">
                              Medical Insurance Name
                            </label>
                            <div className="myform">
                              <select
                                required
                                className={
                                  data.insuranceId.insuranceId
                                    ? 'select  error-border'
                                    : 'select'
                                }
                                value={data.insuranceId.value}
                                data-index={index}
                                data-name="insuranceId"
                                onChange={(e) => modifyData(e, 'child')}
                              >
                                <option value="">Choose</option>
                                {insuranceList.map((mc, mci) => (
                                  <option
                                    key={'child-' + mc.id + '-' + index}
                                    value={mc.id}
                                  >
                                    {mc.name}
                                  </option>
                                ))}
                              </select>
                              <small className="error-input">
                                {data.insuranceId.insuranceId}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-6 no-padding-for-small mb-3">
                            <label className="pro-label">Plan Name</label>
                            <input
                              type="text"
                              name="pName"
                              data-name={'planName'}
                              data-index={index}
                              placeholder="Plan Name"
                              autoComplete="off"
                              className={
                                data.planName.planName
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'child')}
                              value={data.planName.value}
                            />
                            <small className="error-input">
                              {data.planName.planName}
                            </small>
                          </div>

                          <div className="col-md-6 no-padding-for-small mb-3 mb-md-0">
                            <label className="pro-label">Group (GRP) #</label>
                            <input
                              type="text"
                              name="mId"
                              data-name={'group'}
                              data-index={index}
                              placeholder="Group#"
                              autoComplete="off"
                              className={
                                data.group.group
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'child')}
                              value={data.group.value}
                            />
                            <small className="error-input">
                              {data.group.group}
                            </small>
                          </div>
                          <div className="col-md-6 no-padding-for-small">
                            <label className="pro-label">Membership ID#</label>
                            <input
                              type="text"
                              name="grp"
                              data-name={'membershipId'}
                              data-index={index}
                              placeholder="Membership ID#"
                              autoComplete="off"
                              className={
                                data.membershipId.membershipId
                                  ? 'input-pro  error-border'
                                  : 'input-pro'
                              }
                              onChange={(e) => modifyData(e, 'child')}
                              value={data.membershipId.value}
                            />
                            <small className="error-input">
                              {data.membershipId.membershipId}
                            </small>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="bday-use info-hg right">
                              <a href="/#" onClick={(e) => e.preventDefault()}>
                                How will my insurance be used?
                              </a>
                              <div className="info-callout right">
                                Providing your insurance information helps
                                DocScheduler find doctors who accept your health
                                insurance and enables us to send your
                                information to doctors to help book
                                appointments.
                              </div>
                            </div>
                          </div>
                        </Row>
                      ))}
                    </>
                  ) : (
                    <>
                      <span className="col-12 no-padding-for-small">
                        <a
                          href="/#"
                          onClick={(e) => getFields(e, 'child')}
                          className="under-line"
                        >
                          + Add Child and Child's Insurance
                        </a>
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 px-0 px-md-3">
                <Row>
                  <div className="col-12 mb-2">
                    <div className="cus-check">
                      <input
                        type="checkbox"
                        id="docTc"
                        name="privacy"
                        data-name={'privacy'}
                        checked={privacyCheck.privacy.value ? true : false}
                        onChange={(e) => modifyData(e)}
                      />
                      <label htmlFor="docTc">
                        I have read and accept DocScheduler's{' '}
                        <a href={PatientLinks.term_of_user} target="blank">
                          {' '}
                          Terms of Use{' '}
                        </a>{' '}
                        and{' '}
                        <a
                          href={PatientLinks.privacy_policy}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy.
                        </a>
                      </label>
                    </div>
                    <small className="error-input">
                      {privacyCheck.privacy.privacy}
                    </small>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="cus-check">
                      <input
                        type="checkbox"
                        id="docHippa"
                        name="hippa"
                        data-name={'hippa'}
                        checked={privacyCheck.hippa.value ? true : false}
                        onChange={(e) => modifyData(e)}
                        className={
                          privacyCheck.hippa.hippa ? 'error-border' : ''
                        }
                      />
                      <label htmlFor="docHippa">
                        I have read and accept DocScheduler's{' '}
                        <a
                          target="_blank"
                          href={PatientLinks.privacy_policy}
                          rel="noreferrer"
                        >
                          HIPPA Authorization.
                        </a>
                      </label>
                    </div>
                    <small className="error-input">
                      {privacyCheck.hippa.hippa}
                    </small>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="cus-check">
                      <input type="checkbox" id="loggedIn" name="loggedIn" />
                      <label htmlFor="loggedIn">Keep me logged in</label>
                    </div>
                  </div>
                </Row>
              </div>
              <div className="col-12 mb-3 mb-md-5 mt-3 mt-md-4 text-center">
                <div className="next-prev-btn-pro">
                  <a
                    href="/#"
                    data-prev-page={PatientLinks.register_step2}
                    onClick={(e) => {
                      props.goPrevious(e)
                    }}
                    className="prev-btn-pro"
                  >
                    Previous
                  </a>
                  <input
                    onClick={() => formSubmit()}
                    type="button"
                    className="next-btn-pro"
                    value={buttonName}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </Suspense>
    </Fragment>
  )
}

export default CreateAccountStep3
