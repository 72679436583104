import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import ProviderLeftProfile from '../../components/pro-doc-profile-left/pro-doc-profile-left'
import ProviderRightProfile from '../../components/pro-doc-profile-right/pro-doc-profile-right'
import ProfileTabs from '../../components/profile-tabs/profile-tabs'
import ProviderHeading from '../../components/provider-heading/provider-heading'
import Ratingandreview from '../../components/rating-review/rating-review'
import Header from '../../global/header/header'

const DoctorProfile = () => {
  return (
    <Fragment>
      {/* header */}
      <Container fluid className="header px-0">
        <Header />
      </Container>
      {/* provider heading */}
      <div className="doctor-profile-wrap">
        <ProviderHeading />
      </div>
      <Container>
        {/* Profile section start */}
        <Row className="mb-4">
          <div className="col-12">
            <div className="provider-profile-inner doctor-profile-wrap">
              <ProviderLeftProfile />
              <ProviderRightProfile />
            </div>
          </div>
        </Row>
        {/* tabs start here */}
        <ProfileTabs />
        {/* Review section start */}
        <Ratingandreview />
      </Container>
    </Fragment>
  )
}

export default DoctorProfile
