import 'bootstrap/dist/css/bootstrap.css'
import {
  Fragment,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ListGroup, Spinner } from 'react-bootstrap'
import useLocationMod from '../../hooks/useLocationMod.js'
import NetworkLayer from '../../reactLayer/Network/Network.js'
import SearchLocationInput from '../googlemap/google_map_autocomplete.js'
import { debounce, statusOptions } from '../homepage/utils.js'

export const mapSearchOptionsToInputValue = (options, inputValue) => {
  let foundSearchValue = {}

  Object.entries(options).map(([key, array]) => {
    const value = array.filter((value) => value.data == inputValue)

    if (value.length) {
      foundSearchValue = value[0]
    }
  })

  return foundSearchValue
}

const ModalProvidersSearch = (props) => {
  const {
    filter,
    setFilter,
    hasInsuranceInput,
    hasExtendedFindInput,
    redirect,
  } = props
  const [findInputValue, setFindInputValue] = useState(
    filter.searchValue ? filter.searchValue : ''
  )
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const locationMod = useLocationMod()
  const [isChildren, setIsChildren] = useState(0)
  const [locationName, setLocationName] = useState(
    locationMod.location?.state?.locationName
  )
  const [status, setStatus] = useState(statusOptions.initial)
  const [inputSuggestions, setInputSuggestions] = useState({
    specialityData: [],
    conditionData: [],
    treatmentData: [],
    doctorData: [],
  })
  const [displayInsuranceName, setDisplayInsuranceName] = useState(false)
  const [optionsInsuranceName, setOptionsInsuranceName] = useState([])
  const [searchInsuranceName, setSearchInsuranceName] = useState('')

  const wrapperCateRef = useRef(null)
  const wrapperInsuranceRef = useRef(null)

  const renderListItems = () => {
    const items = []
    if (inputSuggestions) {
      const { specialityData, conditionData, treatmentData, doctorData } =
        inputSuggestions

      specialityData?.length &&
        items.push({ data: specialityData, title: 'SPECIALTY' })
      conditionData?.length &&
        items.push({ data: conditionData, title: 'CONDITION' })
      treatmentData?.length &&
        items.push({ data: treatmentData, title: 'TREATMENT' })
      doctorData?.length && items.push({ data: doctorData, title: 'DOCTOR' })
    }

    return (
      <>
        {status === statusOptions.loading && (
          <ListGroup.Item>
            <Spinner animation="border" role="status" size="sm" />
          </ListGroup.Item>
        )}
        {status === statusOptions.error && (
          <ListGroup.Item>
            <p className="error">
              Please check your spelling or try different keywords.
            </p>
          </ListGroup.Item>
        )}
        {status !== statusOptions.initial &&
          items &&
          items.map(({ data, title }, index) => (
            <ListGroup.Item key={`${title} ${index}`}>
              {title}
              <ListGroup className="borderless">
                {data.map((item) => (
                  <ListGroup.Item
                    className="cateFindItem"
                    key={item.data}
                    onClick={() => updateCateDex(item.data)}
                  >
                    {item.data}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </ListGroup.Item>
          ))}
      </>
    )
  }

  const renderInsuranceListItem = (item, index) => {
    return (
      <ListGroup.Item
        className="option insuranceItem"
        onClick={() => updateInsuranceDex(item.name)}
        key={index}
      >
        {item.name}
      </ListGroup.Item>
    )
  }

  const redirectToSearchResult = () => {
    if (redirect) {
      redirect({
        inputSuggestions,
        findInputValue,
        zip,
        city,
        searchInsuranceName,
        isChildren,
      })
    } else {
      const mappedValue = mapSearchOptionsToInputValue(
        inputSuggestions,
        findInputValue
      )
      setFilter({
        ...filter,
        callApi: true,
        city,
        zip,
        locationName,
        searchValue: findInputValue,
      })
      locationMod.location.state = {
        find: findInputValue,
        focusAreas: mappedValue.focusArea || '',
        zip,
        city,
        locationName,
        isChildren: isChildren,
      }
    }
  }

  const getAllFindResult = useCallback(async (input) => {
    setStatus(statusOptions.success)
    if (input.length >= 3) {
      setStatus(statusOptions.loading)
      let dataNode = {
        specialityData: [],
        conditionData: [],
        treatmentData: [],
        doctorData: [],
      }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'providers/search/suggestion/get',
        body: JSON.stringify({
          searchValue: input,
        }),
      }
      let result = await NetworkLayer.postData(submitData)
      if (result.body.status) {
        if (result.body.result) {
          const { specialityData, conditionData, treatmentData, doctorData } =
            result.body.result
          let specialityNode = specialityData.map(
            ({ id, speciality, focusArea }) => ({
              id: id,
              data: speciality,
              focusArea,
            })
          )
          let doctorListNode = doctorData.map(
            ({ id, firstName, lastName }) => ({
              id: id,
              data:
                (firstName ? firstName : '') + (lastName ? ' ' + lastName : ''),
            })
          )
          dataNode.specialityData = specialityNode
          dataNode.conditionData = conditionData
          dataNode.treatmentData = treatmentData
          dataNode.doctorData = doctorListNode
          if (
            specialityNode.length === 0 &&
            conditionData.length === 0 &&
            treatmentData.length === 0 &&
            doctorData.length === 0
          ) {
            setStatus(statusOptions.error)
          } else {
            setStatus(statusOptions.success)
          }
        }
      } else {
        setStatus(statusOptions.error)
      }
      setInputSuggestions(dataNode)
    }
  }, [])

  const debounceGet = useRef(
    debounce((nextValue) => getAllFindResult(nextValue), 1000)
  ).current

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperCateRef
    if (wrap && !wrap.contains(event.target)) {
      setStatus(statusOptions.initial)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const updateCateDex = (each) => {
    setFindInputValue(each)
    setStatus(statusOptions.initial)
  }

  const handleChange = (event) => {
    const nextValue = event?.target?.value
    setFindInputValue(nextValue)
    debounceGet(nextValue)
  }

  const selectChildren = (e) => {
    if (e.currentTarget.checked) {
      setIsChildren(1)
    } else {
      setIsChildren(0)
    }
  }

  const updateInsuranceDex = (each) => {
    setSearchInsuranceName(each)
    setDisplayInsuranceName(false)
  }

  useEffect(() => {
    if (locationName === '') {
      setZip('')
      setCity('')
    }
  }, [locationName])

  useEffect(() => {
    ;(async () => {
      let data = []
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'home/insurance/get',
      }
      let result = await NetworkLayer.getRequest(submitData)
      if (
        result.result?.data.length > 0 ||
        result.result?.customInsuranceData > 0
      ) {
        data = [].concat(
          [...result.result?.data],
          [...result.result?.customInsuranceData]
        )
      }
      setOptionsInsuranceName(data)
    })()
  }, [])

  const suspenseLoader = () => <p></p>
  return (
    <Suspense fallback={suspenseLoader()}>
      <Fragment>
        <div className="col-12">
          <div className="modal-search-container">
            <div className="search-top-fields">
              <span ref={wrapperCateRef}>
                <input
                  type="text"
                  name="find"
                  placeholder="Condition, Specialty, Treatment, or Doctor Name"
                  className="input-home"
                  autoComplete="off"
                  value={findInputValue}
                  onChange={handleChange}
                />
                <div className="find-home">Find:</div>
                {status !== statusOptions.initial && (
                  <div className="autoContainerCate">
                    <div className="autoContenrCate">
                      <ListGroup>{renderListItems()}</ListGroup>
                    </div>
                  </div>
                )}
                {hasExtendedFindInput && (
                  <div className="home-bottom-field-txt mt-4 f-wdth">
                    <span className="home-check pt-0 d-md-block f-wdth ">
                      <div className="cus-check">
                        <input
                          type="checkbox"
                          id="hipppa"
                          name="docHippa"
                          checked={isChildren ? true : false}
                          onChange={(e) => selectChildren(e)}
                        />
                        <label htmlFor="hipppa">
                          Select check box to search for children's doctors
                        </label>
                      </div>
                    </span>
                  </div>
                )}
              </span>
              <span>
                <SearchLocationInput
                  className="input-home"
                  setZip={setZip}
                  setCity={setCity}
                  locationName={locationName}
                  setLocationName={setLocationName}
                />
                <div className="find-home">Near:</div>
              </span>
              <span ref={wrapperInsuranceRef}>
                <input
                  type="text"
                  name="insurance_name"
                  onClick={() => {
                    setDisplayInsuranceName(!displayInsuranceName)
                  }}
                  placeholder="Insurance Name"
                  autoComplete="off"
                  className="input-home"
                  value={searchInsuranceName}
                  onChange={(event) => {
                    setSearchInsuranceName(event?.target?.value)
                  }}
                />
                <ListGroup>
                  {displayInsuranceName && optionsInsuranceName.length > 0 && (
                    <div className="autoContainerInsuranceName">
                      <div className="autoContentInsuranceName">
                        {optionsInsuranceName
                          .filter(
                            ({ name }) =>
                              name
                                .toLowerCase()
                                .indexOf(searchInsuranceName.toLowerCase()) > -1
                          )
                          .map((value, i) => renderInsuranceListItem(value, i))}
                      </div>
                    </div>
                  )}
                </ListGroup>
              </span>
            </div>
            <div className="modal-search-container">
              <input
                type="button"
                onClick={() => {
                  redirectToSearchResult()
                }}
                className="modal-search-button"
                value="Search"
              />
            </div>
          </div>
        </div>
      </Fragment>
    </Suspense>
  )
}

ModalProvidersSearch.defaultProps = {
  filter: null,
  setFilter: null,
}

export default memo(ModalProvidersSearch)
