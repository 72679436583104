import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { PatientLinks } from '../../linkFile'

const ProfileLinks = (props) => {
  const history = useHistory()
  const redirectTo = (e) => {
    e.preventDefault()
    let previousPage = e.currentTarget.getAttribute('data-next-page')
    history.push({
      pathname: `${PatientLinks.accountprofile}/${previousPage}`,
    })
  }

  return (
    <Fragment>
      <Container>
        <Container className="my-account-wrap">
          <Row>
            <div className="col-12 mb-md-3">
              <div className="my-acc-info-box">
                <h3>Profile Information</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="profile-name"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Name</a>
                  </li>
                  <li
                    data-next-page="profile-birthday"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Birthday</a>
                  </li>
                  <li
                    data-next-page="preferred-pronoun"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Sex/Preferred Pronoun</a>
                  </li>
                </ul>
              </div>
              <div className="my-acc-info-box">
                <h3>Insurance Information</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="insurance-medical"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Medical Insurance</a>
                  </li>
                  <li
                    data-next-page="insurance-dental"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Dental Insurance</a>
                  </li>
                  <li
                    data-next-page="insurance-vision"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Vision Insurance</a>
                  </li>
                  <li
                    data-next-page="insurance-child"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Child/Dependent Medical Insurance</a>
                  </li>
                </ul>
              </div>
              <div className="my-acc-info-box mb-md-5">
                <h3>Contact Information</h3>
                <ul className="account-info-list">
                  <li
                    data-next-page="profile-phone"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Phone #</a>
                  </li>
                  <li
                    data-next-page="profile-email"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Email Address</a>
                  </li>
                  <li
                    data-next-page="profile-address"
                    onClick={(e) => {
                      redirectTo(e)
                    }}
                  >
                    <a href="\#">Address</a>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default ProfileLinks
