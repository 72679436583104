const ProviderLinks = {
  home: '/',
  login: '/provider/login',
  register: '/provider/register/:type/:id?',
  register_step1: '/provider/register/account-information',
  register_step2: '/provider/register/medical-specialty',
  register_step3: '/provider/register/practice-information',
  register_step4: '/provider/register/select-plan',
  register_step5: '/provider/register/payment/',
  search_result: '/provider/search-result',
  profile: '/profile',
  dashboard: '/provider/dashboard',
  edit_address: '/provider/edit-address',
  add_address: '/provider/add-address',
  freeprofile: '/provider/free-profile',
  myaccount: '/provider/my-account/:page',
  accountprofile: '/provider/my-account/profile',
  accountsecurity: '/provider/my-account/security',
  account_profile: '/provider/my-account/:page/:type/:cardId?',
  refer_friend: '/provider/refer-friend',
  reactive_account: '/provider/reactive-account',
  subscription_canceled: '/provider/subscription-canceled',
  payment_receipt: '/provider/payment-receipt/:planId',
  twofactauth: '/provider/two-step-verification',
  forgot_pass: '/forgot-password',
  reset_pass: '/reset-password/:token',
  reset_pass_screen: '/reset-password-link-send',
  gmaps: '/provider/gmaps',
  notification: '/provider/notifications',
  team_register: '/team/register/:memberId',
  team_login: '/team/login',
  schedule_now: '/schedule-now/:providerId',
}

const PatientLinks = {
  // Patient Links
  home: '/',
  login: '/provider/login',
  register: '/patient/register/:type',
  register_step1: '/patient/register/account-information',
  register_step2: '/patient/register/demographic-information',
  register_step3: '/patient/register/insurance-information',
  appointment_success: '/patient/appointment-request-success',
  aboutus: '/aboutus',
  contactus: '/contactus',
  error_404: '/error-404',
  privacy_policy: '/privacy-policy',
  acceptable_policy: '/acceptable-policy',
  additional_terms: '/additional-terms',
  term_of_user: '/term-of-use',
  profile_deleted: '/profile-deleted',
  how_it_works: '/how-docscheduler-works',
  dashboard: '/patient/dashboard',
  searchby: '/patient/searchby/:type',
  redirection: '/leaving-docscheduler',
  myaccount: '/patient/my-account/:page',
  accountprofile: '/patient/my-account/profile',
  accountsecurity: '/patient/my-account/security',
  account_profile: '/patient/my-account/:page/:type/:cardId?',

  notification: '/patient/notifications',
  health_grade: '/patient/health-grade',
  health_grade_result: '/patient/health-grade-result',
  care_team: '/patient/care-team',
  doctor_search_result: '/patient/doctor-search-result',
  doctor_profile: '/patient/doctor-profile',
  accept_cookies: '/patient/accept-cookies',
  jot_form: '/patient/jot-form',
}

const AdminLinks = {
  admin_root: '/admin',
  admin_login: '/admin/login',
  admin_dashboard: '/admin/dashboard',
  admin_provider_management: '/admin/provider/management',
  admin_revenue_management: '/admin/revenue/management',
  admin_patient_management: '/admin/patient/management',
  admin_provider_detail: '/admin/provider/detail',
  admin_patient_detail: '/admin/patient/detail',
  admin_patient_add_detail: '/admin/patient/adddetail',
  admin_provider_add_detail: '/admin/provider/adddetail',
  admin_speciality: '/admin/speciality',
  admin_insurance: '/admin/insurance',
  admin_content_management: '/admin/content-management',
  admin_rating_review: '/admin/rating-review',
  admin_rating_management: '/admin/rating-review/management',
  admin_notification: '/admin/notification',
  admin_appointments: '/admin/manage-appointments',
  admin_subscription: '/admin/manage-subscription',
  admin_manage_sub_admin: '/admin/manage-admin',
  admin_revenue: '/admin/revenue',
  admin_verify: '/admin/verify',
  admin_doc_assist: '/admin/doc-assist',
  upload_locations: '/admin/upload-locations',
}

export { AdminLinks, PatientLinks, ProviderLinks }
