import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import SearchByLanguage from '../../components/searchBy/searchBy'
import SearchByCity from '../../components/searchBy/searchByCity'
import Footer from '../../global/footer/footer'
import Header from '../../global/header/header'

const SearchBy = () => {
  const history = useHistory()
  const { type } = useParams()
  const [pageHeading, setPageHeading] = useState(null)
  const field = {
    callApi: false,
    cityOrZip: '',
    speciality: [],
    languages: [],
    insurances: [],
    focusAreas: '',
    searchValue: '',
    insurancesType: '',
  }
  const [filter, setfilter] = useState(field)

  const renderDom = useCallback(() => {
    switch (type) {
      case 'specialty':
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        )
      case 'insurance':
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        )
      case 'language':
        return (
          pageHeading && (
            <SearchByLanguage
              filter={filter}
              setfilter={setfilter}
              type={type}
              pageHeading={pageHeading}
            />
          )
        )
      case 'location':
        return (
          pageHeading && (
            <SearchByCity
              filter={filter}
              setfilter={setfilter}
              pageHeading={pageHeading}
            />
          )
        )
      default:
        return null
    }
  }, [filter, type, pageHeading])

  useEffect(() => {
    history.listen(() => {
      setfilter((preState) => ({
        ...preState,
        callApi: true,
        cityOrZip: '',
        speciality: [],
        languages: [],
        insurances: [],
        focusAreas: '',
        searchValue: '',
        insurancesType: '',
      }))
    })
  }, [history])

  useEffect(() => {
    switch (type) {
      case 'specialty':
        setPageHeading({
          title: 'Search Doctor by Specialty',
          subTitle: 'Doctor Specialties',
        })
        break
      case 'insurance':
        setPageHeading({
          title: 'Search Doctor by Insurance',
          subTitle: 'Insurances',
        })
        break
      case 'language':
        setPageHeading({
          title: 'Search Doctor by Language',
          subTitle: "Doctor's Languages Spoken",
        })
        break
      case 'location':
        setPageHeading({
          title: 'Search Local Doctor by City',
          subTitle: '',
        })
        break
      default:
        history.push('/')
    }
  }, [type, history])

  return (
    <Fragment>
      {/* header */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Search By {type} </title>
        <meta
          name="description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, order medicines, book doctor appointments &amp; lab tests, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <link
          rel="canonical"
          href={`https://www.docscheduler.com/patient/searchby/${type}`}
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/patient/searchby/${type}`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta
          property="og:title"
          content={`DocScheduler | Search By ${type}`}
        />
        <meta
          name="twitter:title"
          content={`DocScheduler | Search By ${type}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <meta
          name="twitter:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
      </Helmet>
      <Container fluid className="header px-0">
        <Header />
      </Container>
      <Container className="home-wrap">{renderDom()}</Container>
      <Footer />
    </Fragment>
  )
}

export default memo(SearchBy)
