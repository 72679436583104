import React, { Fragment, useContext, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'
import { Nav, Accordion, useAccordionToggle } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import AuthContext from '../../reactLayer/auth/AuthContext'
import { Link } from 'react-router-dom'
import AdminHeader from '../../components/admin/admin-header'
import AdminNav from '../../components/admin/admin-nav'
import { ReactComponent as SelectIcon } from '../../admin/assets/images/list-triangle.svg'
import SubAdmin from '../../components/admin/sub-admin/sub-admin'

const ManageSubAdmin = () => {
  const authContext = useContext(AuthContext)

  return (
    <Fragment>
      <AdminHeader />
      <div className="outerWrapper">
        <AdminNav />
        <Container
          fluid
          className={
            authContext.adminMenuActive + ' admin-dashboard right-admin-section'
          }
        >
          <SubAdmin />
        </Container>
      </div>
    </Fragment>
  )
}

export default ManageSubAdmin
