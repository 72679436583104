import arrayMove from 'array-move'
import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Msgmodal from '../../global/modal-response-msg/MsgResponseModal'
import { ProviderLinks } from '../../linkFile'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Global from '../../reactLayer/global'
import InputValidation from '../../reactLayer/validation'

const Manageaddress = (props) => {
  const authContext = useContext(AuthContext)
  let id = null

  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const practiceDataStart = {
    id: {
      value: '',
      validation: [''],
      errorMsg: [''],
      errorKey: 'id',
    },
    pName: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'pName',
    },
    pAddress: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'pAddress',
    },
    city: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'city',
    },
    state: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'state',
    },
    zipcode: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: ['This field is required', 'Invalid Zip Code'],
      errorKey: 'zipcode',
    },
    pNumber: {
      value: '',
      validation: ['blank', 'indianmobile'],
      errorMsg: ['This field is required', 'Invalid Number'],
      errorKey: 'pNumber',
    },
    extension: {
      value: '',
      validation: [],
      errorMsg: ['This field is required'],
      errorKey: 'extension',
    },
    link: {
      value: '',
      validation: ['weblink'],
      errorMsg: ['Invalid Link'],
      errorKey: 'link',
    },
    tZone: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'tZone',
    },
    openTime: {
      value: [],
      validation: ['arrayempty'],
      errorMsg: ['This field is required'],
      errorKey: 'openTime',
    },
    cityTemp: [],
    zipCodeTemp: [],
  }
  const [states, setStates] = useState([])
  const [showBase, setshowBase] = useState(true)
  const [cities, setCities] = useState([])
  const [filterCities, setfilterCities] = useState([])
  const [zipCodes, setZipCodes] = useState([])
  const timeList = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ]
  const [practiceData, setpracticeData] = useState([practiceDataStart])
  const [practiceHours, setpracticeHours] = useState([])

  const addPracticeFileds = (e) => {
    e.preventDefault()
    let tempData = practiceData
    tempData.push(practiceDataStart)
    setpracticeData([...tempData])
  }
  const changeOpenTime = (e) => {
    let name = e.currentTarget.getAttribute('data-name')
    let day = e.currentTarget.getAttribute('data-day')
    let index = e.currentTarget.getAttribute('data-index')
    let value = e.currentTarget.value
    let practHours = practiceHours
    let tempPH = practHours[index][day]
    tempPH = { ...tempPH, ...{ [name]: value, error: false } }
    practHours[index][day] = tempPH
    setpracticeHours([...practHours])
    // console.log('practice HOurs', practHours);
  }

  const openTimehandle = (e, varName = 'practiceData') => {
    let index,
      name,
      value,
      tempData = []
    let temppracticehours = practiceHours
    //  return false;
    if (varName === 'practiceData') {
      index = e.currentTarget.getAttribute('data-index')
      name = e.currentTarget.getAttribute('data-name')
      value = e.currentTarget.value
      tempData = practiceData
      // console.log('check array', tempData[index][name]['value']);
      let arrayIndex = tempData[index][name]['value'].indexOf(value)
      if (arrayIndex > '-1') {
        tempData[index][name]['value'].splice(arrayIndex, 1)
        tempData[index][name][name] = ''
        delete temppracticehours[index][value]
      } else {
        tempData[index][name]['value'].push(value)
        tempData[index][name][name] = ''
        temppracticehours[index] = {
          ...temppracticehours[index],
          ...{
            [value]: {
              day: value,
              start: '8:00 AM',
              end: '5:00 PM',
              error: false,
            },
          },
        }
      }
      setpracticeHours([...temppracticehours])
      setpracticeData([...tempData])
      // console.log('practiceHours', practiceHours);
    }
  }
  const modifyPracticeData = async (e, varName = 'practiceData') => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    if (varName === 'practiceData') {
      tempData = practiceData
      if (name == 'pNumber') {
        tempData[index][name]['value'] = Global.phoneFormator(value)
      } else {
        tempData[index][name]['value'] = value
      }
      tempData[index][name][name] = ''
      if (name === 'city') {
        tempData[index][name]['value'] = value
        tempData[index]['zipcode']['value'] = ''
        tempData[index]['zipCodeTemp'] = JSON.parse(
          e.target.selectedOptions[0].getAttribute('data-zipcode')
        )
      } else if (name === 'state') {
        tempData[index][name]['value'] = value
        authContext.setLoader()
        let cityResponse = await Global.getCities(value)
        authContext.unsetLoader()
        tempData[index]['zipcode']['value'] = ''
        tempData[index]['cityTemp'] = cityResponse
        tempData[index]['zipCodeTemp'] = []
      }
      setpracticeData([...tempData])
    }
  }
  const formSubmit = async () => {
    // console.log('practive Data', practiceData);
    let temppracticeData = practiceData
    let flag = true
    let setFocus = false
    let PractHours = practiceHours
    let practData = []

    temppracticeData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)
      let tempPractHours = PractHours[index]
      let practHoursIndex = []
      for (const prop in tempPractHours) {
        practHoursIndex.push({
          day: tempPractHours[prop]['day'],
          start: tempPractHours[prop]['start'],
          end: tempPractHours[prop]['end'],
        })
        let start = Global.convertTime12to24(tempPractHours[prop]['start'])
        let end = Global.convertTime12to24(tempPractHours[prop]['end'])
        // console.log('start==>',start,'end==>',end);
        if (start >= end) {
          tempPractHours[prop]['error'] =
            'Start time should be less than close time'
          PractHours[index] = tempPractHours
          // if(!flag && !setFocus) {
          //     setFocus = true;
          //     console.log('check how may times');
          //     let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({block: "start", behavior: "smooth"});

          // }
        }
      }

      let ms = {
        id: currentValue.id.value,
        practiceName: currentValue.pName.value.trim(),
        practiceAddress: currentValue.pAddress.value.trim(),
        city: currentValue.city.value,
        state: currentValue.state.value,
        zipCode: currentValue.zipcode.value.trim(),
        phoneNumber: currentValue.pNumber.value.trim(),
        extension: currentValue.extension.value.trim(),
        website: currentValue.link.value.trim(),
        timeZone: currentValue.tZone.value.trim(),
        practiceHours: practHoursIndex, //[{"day":"Monday","start":"8 AM","end":"8 PM"},{"day":"Tuesday","start":"8 AM","end":"8 PM"},{"day":"Wednesday","start":"8 AM","end":"8 PM"}],
      }
      practData.push(ms)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          temppracticeData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            let element = document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .scrollIntoView({ block: 'start', behavior: 'smooth' })
          }
        }
        setpracticeData([...temppracticeData])
      }
      // console.log('medical current value ', practiceData);
    })
    if (flag) {
      authContext.setLoader()
      const fData = {
        practiceInformation: practData,
      }

      // console.log('fData', fData);
      // console.log(fData,JSON.stringify(fData));
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/practice-information/update',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      // console.log('result', result);
      if (result.body.status) {
        props.getProfile && props.getProfile()
        setmodalProps({
          type: 'success',
          msg: result.body.message,
          closeredirection: ProviderLinks.dashboard,
        })
        authContext.showResponseModal()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
      setTimeout(() => {
        authContext.hideModal()
      }, 2000)
    }
  }
  const getTempCities = async (stateId) => {
    let cityResponse = await Global.getCities(stateId)
    return cityResponse
  }
  const setPracticeInfo = async (pInfoData) => {
    authContext.setLoader()
    let pHours = []
    let practData = []

    let ddaa = await Promise.all(
      pInfoData.map(async (pInfo, ind) => {
        // console.log('-------pInfo------', pInfo);
        try {
          let temp_zip = []
          let temp_city = []
          if (pInfo.state) {
            let cityResponse = await getTempCities(pInfo.state)
            temp_city = cityResponse
            if (
              pInfo &&
              pInfo.city &&
              pInfo.zipCode &&
              cityResponse.citylist &&
              Object.keys(cityResponse.citylist).length > 0
            ) {
              for (const key in cityResponse.citylist) {
                if (key.toLowerCase() == pInfo.cities.name.toLowerCase()) {
                  temp_zip = cityResponse.citylist[key]
                }
              }
            }
          }
          let openT = []
          pInfo.practiceHours.map((ph, i) => {
            openT.push(ph.day)
            pHours[ind] = { ...pHours[ind], ...{ [ph['day']]: ph } }
          })
          practData.push({
            id: {
              value: pInfo.id,
              validation: [''],
              errorMsg: [''],
              errorKey: 'id',
            },
            pName: {
              value: pInfo.practiceName,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'pName',
            },
            pAddress: {
              value: pInfo.practiceAddress,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'pAddress',
            },
            city: {
              value: pInfo.city,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'city',
            },
            state: {
              value: pInfo.state,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'state',
            },
            zipcode: {
              value: pInfo.zipCode,
              validation: ['blank', 'number'],
              errorMsg: ['This field is required', 'Invalid Zip Code'],
              errorKey: 'zipcode',
            },
            pNumber: {
              value: pInfo.phoneNumber,
              validation: ['blank', 'indianmobile'],
              errorMsg: ['This field is required', 'Invalid Number'],
              errorKey: 'pNumber',
            },
            extension: {
              value: pInfo.extension,
              validation: [],
              errorMsg: ['This field is required'],
              errorKey: 'extension',
            },
            link: {
              value: pInfo.website,
              validation: ['weblink'],
              errorMsg: ['Invalid Link'],
              errorKey: 'link',
            },
            tZone: {
              value: pInfo.timeZone,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'tZone',
            },
            openTime: {
              value: openT ? openT : [],
              validation: ['arrayempty'],
              errorMsg: ['This field is required'],
              errorKey: 'openTime',
            },
            cityTemp: temp_city,
            zipCodeTemp: temp_zip,
          })
          return practData
        } catch (e) {
          console.log(e, 'error to get city or zipcode')
        }
      })
    )
    setpracticeHours([...pHours])
    setpracticeData([...practData])
    setshowBase(true)
    authContext.unsetLoader()
  }
  const getState_and_getCity = async () => {
    let stateResponse = await Global.getStates()
    setStates(stateResponse)
    // let cityResponse = await Global.getCities();
    // setCities(cityResponse);
    // setfilterCities(cityResponse);
    let header = authContext.token
  }
  const getPracticeInfo = async () => {
    authContext.setLoader()
    const submitData = {
      headers: {
        // 'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'providers/practice-information/get',
    }
    let result = await NetworkLayer.getRequest(submitData)
    if (result.status && result.result.length) {
      setPracticeInfo(result.result)
    } else {
      authContext.unsetLoader()
    }
  }
  const swapItem = (result) => {
    let tempPracticeHours = [],
      tempData = []
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      tempData = practiceData
      tempPracticeHours = practiceHours
      tempData = arrayMove(
        tempData,
        result.source.index,
        result.destination.index
      )
      tempPracticeHours = arrayMove(
        tempPracticeHours,
        result.source.index,
        result.destination.index
      )

      setpracticeHours(tempPracticeHours)
      setpracticeData(tempData)
    }
  }
  const deleteData = async () => {
    if (id) {
      authContext.setLoader()

      const fData = {
        id: id,
      }
      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.token,
        },
        url: 'providers/practice-information/delete',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      if (result.body.status) {
        let rd = ProviderLinks.add_address
        if (props.match.path === ProviderLinks.dashboard) {
          rd = ProviderLinks.dashboard
        }
        setmodalProps({
          type: 'success',
          msg: result.body.message,
          closeredirection: rd,
        })
        authContext.showResponseModal()
        getPracticeInfo()
      } else {
        setmodalProps({
          type: 'error',
          msg: result.body.message,
        })
        authContext.showResponseModal()
      }
    }
  }
  const askDelete = (deleteId) => {
    id = deleteId
    setmodalProps({
      type: 'error',
      msg: 'Are you sure to delete address',
      btnname: 'Yes',
      action: deleteData,
      btncancel: 'No',
      autoclose: 'false',
    })
    authContext.showResponseModal()
  }
  const DeleteSection = (props) => {
    if (practiceData.length > 1) {
      return (
        <>
          <div className="edit-buttons-wrap">
            <div>
              <img
                onClick={() => askDelete(props.id)}
                src="/assets/images/del-icon.png"
                alt=""
              />
            </div>
            <div>
              <img src="/assets/images/icon-pop.png" alt="" />
            </div>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
  useEffect(() => {
    getState_and_getCity()
    if (props.tabvisibleComponent == 'editLocation') {
      setshowBase(false)
      getPracticeInfo()
    }
  }, [])

  return (
    <Fragment>
      {/* Form start step 3 */}
      {/* <HeaderMain /> */}
      <Container fluid className="provider-steps-wrap">
        <Container className="provider-form-inner" key={'practiceFieldsButton'}>
          <Row className="mb-4 mt-4 ">
            <div className="col-md-12 mb-3">
              <h2 className="mb-4">
                {props.tabvisibleComponent == 'editLocation'
                  ? 'Edit Address'
                  : 'Add Address'}
              </h2>
            </div>
          </Row>
          <Row>
            {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
          </Row>
          {showBase ? (
            <>
              <DragDropContext onDragEnd={swapItem}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <ul
                      className="focus-area-list"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {practiceData.map((data, index) => (
                        //  <PracticeFileds {...data} index={index}  />
                        <Draggable
                          key={'practiceData-' + index}
                          draggableId={'practiceData-' + index}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              className="row"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Row
                                className="position-relative dashboard-modal-wrap"
                                key={data + '-main-' + index}
                              >
                                <DeleteSection id={data.id.value} />
                                <div className="col-md-12 mb-3">
                                  <label className="pro-label">
                                    Practice Name
                                  </label>
                                  <input
                                    type="text"
                                    name="pName"
                                    placeholder=" "
                                    autoComplete="off"
                                    className={
                                      data.pName.pName
                                        ? 'input-pro  error-border'
                                        : 'input-pro'
                                    }
                                    value={data.pName.value}
                                    data-index={index}
                                    data-name="pName"
                                    onChange={(e) => {
                                      modifyPracticeData(e, 'practiceData')
                                    }}
                                  />
                                  <small className="error-input">
                                    {data.pName.pName}
                                  </small>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="pro-label">
                                    Practice Address
                                  </label>
                                  <input
                                    type="text"
                                    name="pAddress"
                                    placeholder=" "
                                    autoComplete="off"
                                    className={
                                      data.pAddress.pAddress
                                        ? 'input-pro  error-border'
                                        : 'input-pro'
                                    }
                                    value={data.pAddress.value}
                                    data-index={index}
                                    data-name="pAddress"
                                    onChange={(e) => {
                                      modifyPracticeData(e, 'practiceData')
                                    }}
                                  />
                                  <small className="error-input">
                                    {data.pAddress.pAddress}
                                  </small>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label className="pro-label">State</label>
                                  <div className="myform">
                                    <select
                                      className={
                                        data.state.state
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.state.value}
                                      data-index={index}
                                      data-name="state"
                                      onChange={(e) => {
                                        modifyPracticeData(e, 'practiceData')
                                      }}
                                    >
                                      <option value="">Select State</option>
                                      {states.map((state, state_index) => (
                                        <option
                                          key={state.id + '-state-' + index}
                                          value={state.id}
                                        >
                                          {state.name}
                                        </option>
                                      ))}
                                    </select>
                                    <small className="error-input">
                                      {data.state.state}
                                    </small>
                                  </div>
                                </div>
                                <div className="col-md-5 mb-3">
                                  <label className="pro-label">City</label>
                                  <div className="myform">
                                    <select
                                      className={
                                        data.city.city
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.city.value}
                                      data-index={index}
                                      data-name="city"
                                      onChange={(e) => {
                                        modifyPracticeData(e, 'practiceData')
                                      }}
                                    >
                                      <option value="">Select City</option>
                                      {data.cityTemp.citynames &&
                                      data.cityTemp.citynames.length &&
                                      data.cityTemp.citylist
                                        ? Object.keys(
                                            data.cityTemp.citylist
                                          ).map((key) => (
                                            <option
                                              key={
                                                data.cityTemp.citylist[key][0]
                                                  .id + '-city'
                                              }
                                              data-zipcode={JSON.stringify(
                                                data.cityTemp.citylist[key]
                                              )}
                                              value={
                                                data.cityTemp.citylist[key][0]
                                                  .id
                                              }
                                            >
                                              {key}
                                            </option>
                                          ))
                                        : ''}
                                    </select>
                                    <small className="error-input">
                                      {data.city.city}
                                    </small>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                  <label className="pro-label">Zip Code</label>
                                  <div className="myform">
                                    <select
                                      name="zipcode"
                                      className={
                                        data.zipcode.zipcode
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.zipcode.value}
                                      data-index={index}
                                      data-name="zipcode"
                                      onChange={(e) => {
                                        modifyPracticeData(e, 'practiceData')
                                      }}
                                    >
                                      {data.zipCodeTemp.map((zip, key) => {
                                        return (
                                          <option
                                            key={key}
                                            value={zip.zip_code}
                                            data-city={zip.id}
                                          >
                                            {zip.zip_code}
                                          </option>
                                        )
                                      })}
                                    </select>
                                    <small className="error-input">
                                      {data.zipcode.zipcode}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <label className="pro-label">
                                    Patient Scheduling Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    name="pS-number"
                                    placeholder="(###) ###-####"
                                    autoComplete="off"
                                    className={
                                      data.pNumber.pNumber
                                        ? 'input-pro  error-border'
                                        : 'input-pro'
                                    }
                                    value={data.pNumber.value}
                                    data-index={index}
                                    data-name="pNumber"
                                    onChange={(e) => {
                                      modifyPracticeData(e, 'practiceData')
                                    }}
                                  />
                                  <small className="error-input">
                                    {data.pNumber.pNumber}
                                  </small>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="pro-label">Extension</label>
                                  <input
                                    type="text"
                                    name="ext"
                                    placeholder=""
                                    autoComplete="off"
                                    className={
                                      data.extension.extension
                                        ? 'input-pro  error-border'
                                        : 'input-pro'
                                    }
                                    value={data.extension.value}
                                    data-index={index}
                                    data-name="extension"
                                    onChange={(e) => {
                                      modifyPracticeData(e, 'practiceData')
                                    }}
                                  />
                                  <small className="error-input">
                                    {data.extension.extension}
                                  </small>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="pro-label">
                                    Link to Website
                                  </label>
                                  <input
                                    type="text"
                                    name="link"
                                    autoComplete="off"
                                    className={
                                      data.link.link
                                        ? 'input-pro input-blue error-border'
                                        : 'input-pro input-blue'
                                    }
                                    value={data.link.value}
                                    data-index={index}
                                    data-name="link"
                                    onChange={(e) => {
                                      modifyPracticeData(e, 'practiceData')
                                    }}
                                  />
                                  <small className="error-input">
                                    {data.link.link}
                                  </small>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <label className="pro-label">Time Zone</label>
                                  <div className="myform">
                                    <select
                                      className={
                                        data.tZone.tZone
                                          ? 'select  error-border'
                                          : 'select'
                                      }
                                      value={data.tZone.value}
                                      data-index={index}
                                      data-name="tZone"
                                      onChange={(e) => {
                                        modifyPracticeData(e, 'practiceData')
                                      }}
                                    >
                                      <option value="">Time Zone</option>
                                      <option value="HST">
                                        Hawaii Standard Time (HST)
                                      </option>
                                      <option value="AKST">
                                        Alaska Standard Time (AKST)
                                      </option>
                                      <option value="PST">
                                        Pacific Standard Time (PST)
                                      </option>
                                      <option value="MST">
                                        Mountain Standard Time (MST)
                                      </option>
                                      <option value="CST">
                                        Central Standard Time (CST)
                                      </option>
                                      <option value="EST">
                                        Eastern Standard Time (EST)
                                      </option>
                                    </select>
                                    <small className="error-input">
                                      {data.tZone.tZone}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-2">
                                  <label className="pro-label">
                                    Practice Office Hours
                                  </label>
                                </div>
                                <div className="col-md-12 mb-1">
                                  {[
                                    'Monday',
                                    'Tuesday',
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                    'Sunday',
                                  ].map((d, ind) => (
                                    <div
                                      className="practice-hour-box"
                                      key={d + '-main-' + index}
                                    >
                                      <span>
                                        <div className="cus-check-rev">
                                          <input
                                            type="checkbox"
                                            id={d + '-' + index}
                                            name="lang"
                                            data-index={index}
                                            data-name="openTime"
                                            value={d}
                                            onChange={(e) => openTimehandle(e)}
                                            checked={
                                              data.openTime.value.indexOf(d) >
                                              '-1'
                                                ? true
                                                : false
                                            }
                                          />
                                          <label htmlFor={d + '-' + index}>
                                            {d}
                                          </label>
                                        </div>
                                      </span>
                                      {data.openTime.value.indexOf(d) > '-1' &&
                                      practiceHours[index][d] ? (
                                        <>
                                          <span>
                                            <div className="myform">
                                              <select
                                                className="select"
                                                value={
                                                  practiceHours[index][d][
                                                    'start'
                                                  ]
                                                }
                                                data-day={d}
                                                data-name="start"
                                                data-index={index}
                                                onChange={(e) => {
                                                  changeOpenTime(e)
                                                }}
                                              >
                                                {timeList.map((t, ti) => (
                                                  <option
                                                    key={
                                                      'start-' + index + '-' + t
                                                    }
                                                    value={t}
                                                  >
                                                    {t}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </span>
                                          <span>to</span>
                                          <span>
                                            <div className="myform">
                                              <select
                                                className="select"
                                                value={
                                                  practiceHours[index][d]['end']
                                                }
                                                data-day={d}
                                                data-name="end"
                                                data-index={index}
                                                onChange={(e) => {
                                                  changeOpenTime(e)
                                                }}
                                              >
                                                {timeList.map((t, ti) => (
                                                  <option
                                                    key={
                                                      'end-' + index + '-' + t
                                                    }
                                                    value={t}
                                                  >
                                                    {t}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </span>
                                          <small className="error-input time-error">
                                            {practiceHours[index][d]['error']}
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          <span>Not Working Day</span>{' '}
                                        </>
                                      )}
                                    </div>
                                  ))}
                                  <small className="error-input time-error">
                                    {data.openTime.openTime}
                                  </small>
                                </div>
                              </Row>
                            </li>
                          )}
                        </Draggable>
                      ))}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="col-md-12 mb-5 text-center">
                <div className="next-prev-btn-pro">
                  <Link
                    to={ProviderLinks.dashboard}
                    onClick={(e) => {
                      authContext.hideModal()
                    }}
                    data-prev-page="medical-specialty"
                    className="prev-btn-pro"
                  >
                    Cancel
                  </Link>
                  <input
                    type="button"
                    className="next-btn-pro"
                    onClick={(e) => {
                      formSubmit()
                    }}
                    value="Save"
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Container>
      </Container>
      {/* <Footer /> */}
    </Fragment>
  )
}

export default Manageaddress
