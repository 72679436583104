import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

import Digitalscorecard from '../../components/digital-scorecard/digital-scorecard'
import ProviderStep4 from '../../components/provider-steps/provider-step4'
import Rating from '../../components/rating-review/rating-review'
import Recentreview from '../../components/recent-review/recent-review'

const DigitalPage = (props) => {
  const authContext = useContext(AuthContext)
  const [digitalData, setdigitalData] = useState({})
  const profile = props.userprofile ? props.userprofile : ''

  const getdigitalData = async () => {
    authContext.setLoader()
    const data = {}
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.token,
      },
      url: 'providers/digital-scorecard/get',
      body: JSON.stringify(data),
    }
    let result = await NetworkLayer.postData(submitData)
    if (result.body.status) {
      setdigitalData(result.body.result)
    }
    authContext.unsetLoader()
  }

  useEffect(() => {
    getdigitalData()
  }, [])
  return (
    <Fragment>
      {profile &&
      profile.providerCurrentPlan &&
      profile.providerCurrentPlan.providerCurrentServices &&
      profile.providerCurrentPlan.providerCurrentServices.indexOf(5) > '-1' ? (
        <>
          <Digitalscorecard
            totalReviews={
              digitalData.totalReviewsCount ? digitalData.totalReviewsCount : 0
            }
            totalReviewsAverageRating={
              digitalData.totalReviewsAvg ? digitalData.totalReviewsAvg : 0
            }
            recentReviewsAverageRating={
              digitalData.totalReviewsRecentlyAvg
                ? digitalData.totalReviewsRecentlyAvg
                : 0
            }
            recentReviews={
              digitalData.totalReviewsCountRecently
                ? digitalData.totalReviewsCountRecently
                : 0
            }
          />
          <Recentreview
            lastUpdate={
              digitalData.totalReviewsCountRecently &&
              digitalData.totalReviewsCountRecently.lastReviewDate
                ? digitalData.totalReviewsCountRecently.lastReviewDate
                : 'N/A'
            }
            recentReview={
              digitalData.totalReviewsCountRecently
                ? digitalData.totalReviewsCountRecently
                : 0
            }
            positive={
              digitalData.totalReviewsRecently &&
              digitalData.totalReviewsRecently.postiveCount
                ? digitalData.totalReviewsRecently.postiveCount
                : 0
            }
            negative={
              digitalData.totalReviewsRecently &&
              digitalData.totalReviewsRecently.negativeCount
                ? digitalData.totalReviewsRecently.negativeCount
                : 0
            }
          />
          <Rating
            sourceList={digitalData.allSource ? digitalData.allSource : []}
            ratingReviews={digitalData.totalReviews}
          />
        </>
      ) : (
        <>
          <Container className="appointment-wrap modal-wrapper">
            <Row>
              <div className="col-12 profile-plans">
                <div className="provider-step4-wrap">
                  <ProviderStep4 title={'Manage Subscriptions'} />
                </div>
              </div>
            </Row>
          </Container>
        </>
      )}
    </Fragment>
  )
}

export default DigitalPage
