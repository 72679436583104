const currentYear = new Date().getFullYear()

export const monthList = [
  { id: 1, name: 'January', days: 31 },
  { id: 2, name: 'February', days: currentYear % 4 === 0 ? 29 : 28 },
  { id: 3, name: 'March', days: 31 },
  { id: 4, name: 'April', days: 30 },
  { id: 5, name: 'May', days: 31 },
  { id: 6, name: 'June', days: 30 },
  { id: 7, name: 'July', days: 31 },
  { id: 8, name: 'August', days: 31 },
  { id: 9, name: 'September', days: 30 },
  { id: 10, name: 'October', days: 31 },
  { id: 11, name: 'November', days: 30 },
  { id: 12, name: 'December', days: 31 },
]

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
