import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

export const LayoutContainer = ({ children, ...rest }: BoxProps) => {
  return (
    <Box maxWidth={1440} mx="auto" boxSizing="content-box" {...rest} px={8}>
      {children}
    </Box>
  )
}
