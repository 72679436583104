import 'bootstrap/dist/css/bootstrap.css'
import moment from 'moment'
import 'moment-timezone'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Spinner } from 'react-bootstrap'
import NetworkLayer from '../../reactLayer/Network/Network'
import Global from '../../reactLayer/global'

const SchedulingTime = (props) => {
  const { isLoading, locationId, startDate, item } = props
  const [isLoadingState, setIsLoadingState] = useState(isLoading)
  const [slots, setslots] = useState([])
  const [showallslots, setshowallslots] = useState('')
  const [showMore, setshowMore] = useState(false)
  const [maxSlots, setMaxSlots] = useState(0)
  const [timeZone, setTimeZone] = useState()
  const dateWrapper = useRef(null)

  const bookSlot = (sl, index) => {
    if (!sl.isDisabled && !sl.isBooked) {
      let selectedDate,
        slotInfo = {}
      selectedDate = Global.getYearMonthDate(Global.addDays(startDate, index))
      slotInfo = sl
      slotInfo.locationId = locationId
      slotInfo.disabledLocation = true
      slotInfo.date =
        selectedDate.year + '-' + selectedDate.month + '-' + selectedDate.date
      props.bookAppointment && props.bookAppointment(slotInfo, item)
    }
  }

  const sm = useCallback(async (smv = false) => {
    setshowMore(smv)
  }, [])

  useEffect(() => {
    setIsLoadingState(true)
    const doctorSchedulingTime = async (locationId, startDate) => {
      let data = {
        startFrom: moment(startDate).format('YYYY-MM-DD'),
        locationId: locationId,
      }
      let url = 'appointment/schedule'
      const submitData = {
        url: url,
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      // console.log('on schedule page results', result);
      if (result.body.status) {
        let totalSlots = 0
        setTimeZone(result.body.result.location.timezone)
        if (result.body.result.slots) {
          result.body.result.slots.map((slot) => {
            if (slot.slots.length > 0 && totalSlots < slot.slots.length) {
              totalSlots = slot.slots.length
            }
            return null
          })
        }
        setMaxSlots(totalSlots)
        setslots(result.body.result.slots)
        setIsLoadingState(false)
      } else {
        setslots([])
        setIsLoadingState(false)
      }
    }
    locationId && startDate && doctorSchedulingTime(locationId, startDate)
  }, [locationId, startDate])

  useMemo(() => {
    return () => {
      setshowallslots('')
    }
  }, [])

  const isTimeslotDisabled = (timeslot, doctorTimezone, date) => {
    // Get the current date and time in the browser's local timezone
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const browserCurrentDateTime = new Date().toLocaleString('en-US', {
      timeZone: browserTimezone,
    })

    // Combine the date and timeslot into a single date object in the doctor's timezone
    const doctorDateTime = new Date(`${date} ${timeslot} ${doctorTimezone}`)

    // Convert the doctor's datetime to the browser's local timezone
    const browserDateTime = new Date(
      doctorDateTime.toLocaleString('en-US', { timeZone: browserTimezone })
    )

    // Calculate the time difference between the browser's current date and time and the doctor's timeslot
    const timeDifference = browserDateTime - new Date(browserCurrentDateTime)

    // Check if the timeslot is in the past or up to 2 hours in the future
    return timeDifference <= 0 || timeDifference <= 2 * 60 * 60 * 1000
  }

  return (
    <div
      key={locationId + '-Wrapper'}
      className="col-12 px-0 scheduling-component-main"
    >
      {isLoadingState ? (
        <div className="calenderLoading">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          key={locationId + 'lid'}
          className={showallslots + ' date-area date-area-patient'}
          ref={dateWrapper}
        >
          {slots?.map((slot, m_index) => (
            <div className="date-column" key={m_index}>
              {[...Array(maxSlots).keys()].map((x, i) => {
                const sl = slot.slots[i]
                if (sl) {
                  return (
                    <button
                      className="date-list"
                      onClick={() => bookSlot(sl, m_index)}
                      key={sl.startTime}
                      disabled={
                        sl.isDisabled ||
                        sl.isBooked ||
                        sl.isRequested ||
                        isTimeslotDisabled(sl.startTime, timeZone, slot.SDate)
                      }
                    >
                      {!i && !showMore && sm(true)}
                      {sl.startTime}
                    </button>
                  )
                } else
                  return (
                    <button key={i} className="date-list disabled">
                      <strong>---</strong>
                    </button>
                  )
              })}
            </div>
          ))}
        </div>
      )}
      {slots && slots.length ? (
        <>
          <div
            className={
              showallslots === ''
                ? 'date-area-more'
                : 'date-area-more date-area-less'
            }
          >
            {slots &&
              slots.length &&
              slots.map((slot, m_index) => (
                <div
                  key={m_index}
                  className="date-column"
                  onClick={(e) =>
                    slot.slots.length > 2
                      ? setshowallslots(
                          showallslots === '' ? 'show-height1' : ''
                        )
                      : e.preventDefault()
                  }
                >
                  <div
                    className={
                      slot.slots.length > 2
                        ? 'down-arrow-time'
                        : 'down-arrow-time1'
                    }
                  >
                    <div></div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

SchedulingTime.defaultProps = {
  isLoading: false,
  locationId: '',
  startDate: '',
}

export default memo(SchedulingTime)
