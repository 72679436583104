import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import Custommodal from '../../../global/modal/modal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import DeleteSpecialityModal from './delete-speciality-modal'
import EditSpecialityModal from './edit-speciality-modal'
import SpecialitySearch from './speciality-search'

let searchText = ''
const MedicalSchoolList = () => {
  const authContext = useContext(AuthContext)
  const [totalCount, settotalCount] = useState(0)
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [viewdata, setViewdata] = useState()
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const modalList = ['editSpeModal', 'delSpeModal']
  const openProfileTabModal = (modalName, value) => {
    setViewdata(value)
    settabvisibleComponent(modalName)
    authContext.showModal()
  }

  const getList = async (value) => {
    authContext.setLoader()
    // FORM DATA
    let formData = {}
    if (searchText) {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        searchValue: searchText,
        category: 'medical',
      }
    } else {
      formData = {
        offset: filter.offset,
        limit: filter.limit,
        category: 'medical',
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/schools/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    try {
      let result = await NetworkLayer.postData(submitData)
      setData(result?.body?.result.rows)
      settotalCount(result?.body?.result?.count)
    } catch (err) {
      console.log('ERROR :', err)
    }

    authContext.unsetLoader()
    settabvisibleComponent('')
  }

  const searchFilter = (value) => {
    setfilter({ offset: 0, limit: 10 })
    searchText = value
    getList(value)
  }

  useEffect(() => {
    getList()
  }, [filter.offset])

  // PAGINATION CALLBACK
  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  useEffect(() => {
    getList()
  }, [])
  return (
    <Fragment>
      <Loadermodal />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'editSpeModal' ? (
              <EditSpecialityModal
                viewdata={viewdata}
                getList={getList}
                type={'Speciality'}
                subtype={'Medical'}
                isschool={1}
              />
            ) : tabvisibleComponent === 'delSpeModal' ? (
              <DeleteSpecialityModal
                viewdata={viewdata}
                getList={getList}
                type={'Speciality'}
                subtype={'Medical'}
                isschool={1}
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <div className="speciality-wrap">
        <SpecialitySearch
          search={searchFilter}
          type={'Speciality'}
          subtype={'Medical'}
          isschool={1}
          getList={getList}
        />
        <div className="table-report-scroll mb-4">
          <table className="table mb-2">
            <thead>
              <tr>
                <th className="data-sort">
                  <span className="sort-outer">
                    State
                    <span className="data-sort-icon">
                      <DataSortIcon />
                    </span>
                  </span>
                </th>
                <th className="data-sort">
                  <span className="sort-outer">
                    School
                    <span className="data-sort-icon">
                      <DataSortIcon />
                    </span>
                  </span>
                </th>
                <th className="data-sort">
                  <span className="sort-outer">
                    City
                    <span className="data-sort-icon">
                      <DataSortIcon />
                    </span>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length == 0 && (
                <tr>
                  <td>No Such Schools Found</td>
                </tr>
              )}
              {data &&
                data.map((value, index) => (
                  <tr key={value.id}>
                    <td>{value?.states?.name}</td>
                    <td>
                      <div className="comment-school">{value?.name}</div>
                    </td>
                    <td>{value?.cities?.name}</td>
                    <td>
                      <Link
                        onClick={() => {
                          openProfileTabModal('editSpeModal', value)
                        }}
                        className="table-action view-all"
                      >
                        Edit
                      </Link>
                      <Link
                        onClick={() => {
                          openProfileTabModal('delSpeModal', value)
                        }}
                        className="table-action view-all"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* End of first table */}
        {/* <Paging /> */}
        <div className="report-page mt-4">
          <Pagination
            total={totalCount}
            limit={filter.limit}
            range={4}
            offset={filter.offset}
            callBack={changeLimit}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default MedicalSchoolList
