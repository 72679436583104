import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as DataSortIcon } from '../../../admin/assets/images/drop-arrow.svg'
import Pagination from '../../../components/admin/pagination/pagination'
import Loadermodal from '../../../global/loader-modal/loader-modal'
import Custommodal from '../../../global/modal/modal'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import NetworkLayer from '../../../reactLayer/Network/Network'
import ManagementFilter from './filter-appointment-history'
import PatientAppointmentModal from './patient-appointment-modal'

function camelCase(str) {
  return str.substring(0, 1).toUpperCase() + str.substring(1)
}

const PatientAppointmentHistory = (props) => {
  const authContext = useContext(AuthContext)
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [appointmentDetails, setAppointmentDetails] = useState()
  const modalList = ['appointModal']
  const openProfileTabModal = (modalName, value) => {
    setAppointmentDetails(value)
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const [sort, setsort] = useState('ASC')
  const [sortfield, setSortName] = useState('Name')
  const [filter, setfilter] = useState({
    offset: 0,
    limit: 10,
  })
  const [totalCount, settotalCount] = useState(0)
  const [data, setData] = useState([])

  const getList = async (value) => {
    authContext.setLoader()
    // FORM DATA
    let formData = {}
    if (value) {
      formData = {
        userId: props.data?.patientInfo?.accountInfo?.id,
        offset: filter.offset,
        limit: filter.limit,
        searchValue: value,
        sortOrder: sort,
        sortBy: sortfield,
      }
    } else {
      formData = {
        userId: props.data?.patientInfo?.accountInfo?.id,
        offset: filter.offset,
        limit: filter.limit,
        sortOrder: sort,
        sortBy: sortfield,
      }
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/appointment-history/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setData(result?.body?.result?.rows)
    settotalCount(result?.body?.result?.count)
    authContext.unsetLoader()
  }

  const deleteProvider = async (id) => {
    // FORM DATA
    const formData = {
      id: id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/appointment-history/delete',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    if (window.confirm('Are you sure you want to delete this appointment?')) {
      authContext.setLoader()
      try {
        let result = await NetworkLayer.postData(submitData)
        if (result.body.status) {
          getList()
        }
      } catch (err) {
        console.log(err)
      }
      authContext.unsetLoader()
    }
  }

  const searchFilter = (value) => {
    getList(value)
  }

  const changeSorting = (e) => {
    let name = e.currentTarget.getAttribute('name')
    setSortName(name)
    setsort(sort == 'ASC' ? 'DESC' : 'ASC')
  }

  const changeLimit = (page) => {
    setfilter({ ...filter, ...{ offset: parseInt(page) } })
  }

  useEffect(() => {
    getList()
  }, [filter.offset, sort])

  return (
    <Fragment>
      <Loadermodal />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'appointModal' && appointmentDetails ? (
              <PatientAppointmentModal
                data={appointmentDetails}
                getList={getList}
              />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}

      <ManagementFilter search={searchFilter} patientId={props.patientId} />
      {/* table start here */}
      <div className="table-report-scroll mt-0">
        <table className="table">
          <thead>
            <tr>
              <th className="data-sort">
                <span className="sort-outer">
                  Provider Name
                  <span
                    className="data-sort-icon"
                    name="Name"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th className="data-sort">
                <span className="sort-outer">
                  Date &amp; Time
                  <span
                    className="data-sort-icon"
                    name="createdAt"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th>Contact No.</th>
              <th>Location</th>
              <th className="data-sort">
                <span className="sort-outer">
                  Appointment Type
                  <span
                    className="data-sort-icon"
                    name="visitType"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th className="data-sort">
                <span className="sort-outer">
                  Rating
                  <span
                    className="data-sort-icon"
                    name="Name"
                    onClick={changeSorting}
                  >
                    <DataSortIcon />
                  </span>
                </span>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length == 0 && (
              <tr>
                <td>No Such Appointment History Found</td>
              </tr>
            )}
            {data &&
              data.map((value, index) => (
                <tr key={index}>
                  <td style={{ 'min-width': '150px' }}>
                    <Link>{`${value.providerPracticeLocations?.users?.providers?.firstName} ${value.providerPracticeLocations?.users?.providers?.lastName}`}</Link>
                  </td>
                  <td>
                    {value.appointmentDate
                      ? value.appointmentDate
                      : `${value.appointmentDayType}, ${camelCase(
                          value.preferAppointmentTime
                        )}`}
                  </td>
                  <td>{value.providerPracticeLocations?.phoneNumber}</td>
                  <td>{`${value?.providerPracticeLocations?.cities?.name}, ${value?.providerPracticeLocations?.states?.code}`}</td>
                  <td>{value.visitType}</td>
                  <td>
                    <span className="rating">
                      <span
                        className={`rating${Math.round(
                          value?.ratingReviewsCount
                        )}`}
                      ></span>
                    </span>
                  </td>
                  <td>
                    <div className="bookbtn-wrap">
                      {(value?.providerPracticeLocations?.users
                        ?.userCurrentPlans?.planPurchaseDetails?.planId == 3 ||
                        value?.providerPracticeLocations?.users
                          ?.userCurrentPlans?.planPurchaseDetails?.planId ==
                          4) &&
                        value?.providerPracticeLocations?.users?.providers
                          ?.allowOnline === 1 && (
                          <Link
                            className="bookbtn"
                            onClick={() => {
                              openProfileTabModal('appointModal', value)
                            }}
                          >
                            Book Appointment
                          </Link>
                        )}
                      {(value?.providerPracticeLocations?.users
                        ?.userCurrentPlans?.planPurchaseDetails?.planId == 3 ||
                        value?.providerPracticeLocations?.users
                          ?.userCurrentPlans?.planPurchaseDetails?.planId ==
                          4) &&
                        value?.providerPracticeLocations?.users?.providers
                          ?.allowOnline === 0 && (
                          <Link
                            className="bookbtn"
                            onClick={() => {
                              openProfileTabModal('appointModal', value)
                            }}
                          >
                            Request Callback
                          </Link>
                        )}

                      <Link className="delicon">
                        <img
                          src="/assets/images/delete-icon.svg"
                          alt="Delete"
                          onClick={() => {
                            deleteProvider(value.id)
                          }}
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="report-page mt-4">
        <Pagination
          total={totalCount}
          limit={filter.limit}
          range={4}
          offset={filter.offset}
          callBack={changeLimit}
        />
      </div>
    </Fragment>
  )
}

export default PatientAppointmentHistory
