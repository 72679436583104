import 'bootstrap/dist/css/bootstrap.css'
import { Fragment, useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AuthContext from '../../reactLayer/auth/AuthContext'

import useIdleTimeout from '../../hooks/useIdleTimeout'
import { PatientLinks, ProviderLinks } from '../../linkFile'
import StorageAdaptation from '../../reactLayer/storage/storage'
import HeaderWithLogin from './header'
import HeaderWithoutLogin from './home-page-header'

const Header = (props) => {
  const authContext = useContext(AuthContext)

  const handleIdle = () => {
    let userData = StorageAdaptation.getLocalStorage('user', false, 'local')
    if (
      userData &&
      userData.accountInfo &&
      userData.accountInfo.roleId &&
      userData.accountInfo.roleId === 4
    ) {
      window.localStorage.setItem('role', 4)
      StorageAdaptation.setLocalStorage(
        'tokenExpire',
        'patient',
        'false',
        'session'
      )
      StorageAdaptation.removeLocalStorage('user', 'local')
      StorageAdaptation.removeLocalStorage('token', 'local')
      window.location.href = PatientLinks.home
    } else if (
      userData &&
      userData.accountInfo &&
      userData.accountInfo.roleId &&
      userData.accountInfo.roleId === 3
    ) {
      window.localStorage.setItem('role', 3)
      StorageAdaptation.setLocalStorage(
        'tokenExpire',
        'provider',
        'false',
        'session'
      )
      StorageAdaptation.removeLocalStorage('user', 'local')
      StorageAdaptation.removeLocalStorage('token', 'local')
      window.location.href = ProviderLinks.login
    } else {
      StorageAdaptation.removeLocalStorage('user', 'local')
      StorageAdaptation.removeLocalStorage('token', 'local')
      window.location.href = PatientLinks.home
    }
  }

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 3600 })
  useEffect(() => {
    return () => {
      idleTimer.reset()
    }
  })

  return (
    <Fragment>
      <Container className="header px-0" style={{ maxWidth: '100%' }}>
        {authContext.user === null || !authContext.user?.isProfileCompleted ? (
          <HeaderWithoutLogin
            mobileMenuVisible={props.mobileMenuVisible}
            setMobileMenuVisible={props.setMobileMenuVisible}
            {...props}
          />
        ) : (
          <HeaderWithLogin {...props} />
        )}
      </Container>
    </Fragment>
  )
}

export default Header
