import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Msgmodal from '../../../global/modal-response-msg/MsgResponseModal'
import NetworkLayer from '../../../reactLayer/Network/Network'
import AuthContext from '../../../reactLayer/auth/AuthContext'
import Global from '../../../reactLayer/global'
import InputValidation from '../../../reactLayer/validation'

const PracticeInformation = (props) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [modalProps, setmodalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })
  const practiceDataStart = {
    pName: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'pName',
    },
    pAddress: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'pAddress',
    },
    city: {
      cityName: '',
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'city',
    },
    state: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'state',
    },
    zipcode: {
      value: '',
      validation: ['blank', 'number'],
      errorMsg: ['This field is required', 'Invalid Zip Code'],
      errorKey: 'zipcode',
    },
    pNumber: {
      value: '',
      validation: ['blank', 'indianmobile'],
      errorMsg: ['This field is required', 'Invalid Number'],
      errorKey: 'pNumber',
    },
    extension: {
      value: '',
      validation: [],
      errorMsg: ['This field is required'],
      errorKey: 'extension',
    },
    link: {
      value: '',
      validation: [],
      errorMsg: ['This field is required', 'Invalid Link'],
      errorKey: 'link',
    },
    tZone: {
      value: '',
      validation: [],
      errorMsg: ['This field is required'],
      errorKey: 'tZone',
    },
    openTime: {
      value: [],
      validation: ['arrayempty'],
      errorMsg: ['This field is required'],
      errorKey: 'openTime',
    },
  }

  useEffect(() => {
    props?.providerDetails?.practiceInfo?.providerPracticeLocations &&
      props?.providerDetails?.practiceInfo?.providerPracticeLocations.map(
        (result, index) => {
          let tempData = []
          tempData.push({
            pName: {
              value: result?.practiceName,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'pName',
            },
            pAddress: {
              value: result?.practiceAddress,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'pAddress',
            },
            city: {
              value: result?.city,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'city',
            },
            state: {
              value: result?.state,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'state',
            },
            zipcode: {
              value: result?.zipCode,
              validation: ['blank', 'number'],
              errorMsg: ['This field is required', 'Invalid Zip Code'],
              errorKey: 'zipcode',
            },
            pNumber: {
              value: result?.phoneNumber,
              validation: ['blank', 'indianmobile'],
              errorMsg: ['This field is required', 'Invalid Number'],
              errorKey: 'pNumber',
            },
            extension: {
              value: result?.extension,
              validation: [],
              errorMsg: ['This field is required'],
              errorKey: 'extension',
            },
            link: {
              value: result?.website,
              validation: [],
              errorMsg: ['This field is required', 'Invalid Link'],
              errorKey: 'link',
            },
            tZone: {
              value: result?.timeZone,
              validation: ['blank'],
              errorMsg: ['This field is required'],
              errorKey: 'tZone',
            },
            openTime: {
              value: [result?.practiceHours],
              validation: ['arrayempty'],
              errorMsg: ['This field is required'],
              errorKey: 'openTime',
            },
            cityTemp: '',
          })
          setpracticeData([...tempData])
        }
      )
  }, [])

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [filterCities, setfilterCities] = useState([])
  const timeList = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ]
  const [insuranceList, setinsuranceList] = useState([])
  const [selectall, setselectall] = useState(false)
  const [practiceData, setpracticeData] = useState([practiceDataStart])
  const [appointmetOptions, setappointmetOptions] = useState([])
  const [practiceHours, setpracticeHours] = useState([])
  const [insuranceData, setinsuranceData] = useState([])

  const [custIns, setcustIns] = useState({
    custIns: {
      value: '',
      validation: ['blank'],
      errorMsg: ['This field is required'],
      errorKey: 'custIns',
      custIns: false,
    },
  })

  const addCustomInsurance = (e) => {
    let tempCustIns = custIns
    let validationResult = InputValidation(custIns)
    if (Object.keys(validationResult).length > 0) {
      for (const prop in validationResult) {
        tempCustIns[prop][prop] = `${validationResult[prop]}`
      }
      setcustIns({ ...tempCustIns })
    } else {
      let value = tempCustIns['custIns']['value']
      tempCustIns['custIns']['value'] = ''
      setcustIns({ ...tempCustIns })
      let tempinsData,
        tempInsList = insuranceList
      tempinsData = insuranceData
      tempinsData.push(value)
      setinsuranceData([...tempinsData])
      tempInsList.push({ id: value, name: value })
      setinsuranceList([...tempInsList])
    }
  }
  const DeleteElement = (elementName, index) => {
    let tempData = []
    if (elementName === 'practiceData') {
      tempData = practiceData
      tempData.splice(index, 1)
      setpracticeData([...tempData])
    }
  }
  const DeleteSection = (props) => {
    return (
      <>
        <div
          onClick={() => DeleteElement(props.name, props.index)}
          className="delete-icon"
          alt="Delete"
        ></div>
      </>
    )
  }
  const addPracticeFileds = (e) => {
    e.preventDefault()
    let tempData = practiceData
    tempData.push(practiceDataStart)
    setpracticeData([...tempData])
  }

  const selectAll = (e) => {
    if (e.currentTarget.checked === true) {
      setselectall(true)
      let selectedIns = []
      insuranceList.map((insList) => {
        selectedIns.push(insList.name)
      })
      setinsuranceData([...selectedIns])
    } else {
      setselectall(false)
      setinsuranceData([...[]])
    }
  }
  const changeOpenTime = (e) => {
    let name = e.currentTarget.getAttribute('data-name')
    let day = e.currentTarget.getAttribute('data-day')
    let index = e.currentTarget.getAttribute('data-index')
    let value = e.currentTarget.value
    let practHours = practiceHours
    let tempPH = practHours[index][day]
    tempPH = { ...tempPH, ...{ [name]: value, error: false } }
    practHours[index][day] = tempPH
    setpracticeHours([...practHours])
  }
  const changeAppointmetOptions = (e) => {
    let name = e.currentTarget.getAttribute('data-name')
    let tempChangeOptions = appointmetOptions
    let arrayIndex = tempChangeOptions.indexOf(name)
    if (arrayIndex > '-1') {
      tempChangeOptions.splice(arrayIndex, 1)
    } else {
      tempChangeOptions.push(name)
    }
    setappointmetOptions([...tempChangeOptions])
  }
  const openTimehandle = (e, varName = 'practiceData') => {
    let index,
      name,
      value,
      tempData = []
    let temppracticehours = practiceHours
    //  return false;
    if (varName === 'practiceData') {
      index = e.currentTarget.getAttribute('data-index')
      name = e.currentTarget.getAttribute('data-name')
      value = e.currentTarget.value
      tempData = practiceData
      // console.log('check array', tempData[index][name]['value']);
      let arrayIndex = tempData[index][name]['value'].indexOf(value)
      if (arrayIndex > '-1') {
        tempData[index][name]['value'].splice(arrayIndex, 1)
        tempData[index][name][name] = ''
        delete temppracticehours[index][value]
      } else {
        tempData[index][name]['value'].push(value)
        tempData[index][name][name] = ''
        temppracticehours[index] = {
          ...temppracticehours[index],
          ...{
            [value]: {
              day: value,
              start: '8:00 AM',
              end: '5:00 PM',
              error: false,
            },
          },
        }
      }
      setpracticeHours([...temppracticehours])
      setpracticeData([...tempData])
    } else if (varName === 'insuranceData') {
      name = e.currentTarget.getAttribute('data-name')
      value = e.currentTarget.value
      tempData = insuranceData

      let arrayIndex = tempData.indexOf(value)
      if (arrayIndex > '-1') {
        tempData.splice(arrayIndex, 1)
      } else {
        tempData.push(value)
      }
      setinsuranceData([...tempData])
    }
  }
  const modifyPracticeData = async (e, varName = 'practiceData') => {
    let index,
      name,
      value,
      tempData = []
    index = e.currentTarget.getAttribute('data-index')
    name = e.currentTarget.getAttribute('data-name')
    value = e.currentTarget.value
    //  return false;
    if (varName === 'practiceData') {
      tempData = practiceData
      if (name == 'pNumber') {
        tempData[index][name]['value'] = Global.phoneFormator(value)
      } else {
        tempData[index][name]['value'] = value
      }
      tempData[index][name][name] = ''
      if (name === 'city') {
        tempData[index][name]['value'] = value
        tempData[index]['zipcode']['value'] = ''
        cities.filter((city, city_index) => {
          if (city.id == value) {
            tempData[index]['state']['value'] = city.stateId
            tempData[index]['state']['state'] = ''
            return true
          }
        })
        tempData[index]['zipcode']['zipCodes'] = JSON.parse(
          e.target.selectedOptions[0].getAttribute('data-zipcode')
        )
        //setSelectedCity(value)
      } else if (name === 'state') {
        tempData[index][name]['value'] = value
        tempData[index]['city']['value'] = ''
        tempData[index]['zipcode']['value'] = ''
        authContext.setLoader()
        let cityResponse = await Global.getCities(value)
        authContext.unsetLoader()
        tempData[index]['city']['cities'] = cityResponse
      } else if (name === 'link') {
        tempData[index][name]['value'] = value.trim()
      } else if (name === 'zipcode') {
        tempData[index]['zipcode']['value'] = value
        //setCityTemp(e.target.selectedOptions[0].getAttribute('data-city'))
      }
      setpracticeData([...tempData])
    }
    // formSubmit();
  }

  const formSubmit = async () => {
    let temppracticeData = practiceData
    let flag = true
    let setFocus = false
    let PractHours = practiceHours
    let practData = []
    let tempTimeZone = temppracticeData[0].tZone.value
    temppracticeData.forEach((currentValue, index) => {
      let validationResult = InputValidation(currentValue)
      let tempPractHours = PractHours[index]
      let practHoursIndex = []
      for (const prop in tempPractHours) {
        practHoursIndex.push({
          day: tempPractHours[prop]['day'],
          start: tempPractHours[prop]['start'],
          end: tempPractHours[prop]['end'],
        })
        let start = Global.convertTime12to24(tempPractHours[prop]['start'])
        let end = Global.convertTime12to24(tempPractHours[prop]['end'])
        if (start >= end) {
          tempPractHours[prop]['error'] =
            'Start time should be less than close time'
          PractHours[index] = tempPractHours
          // if(!flag && !setFocus) {
          //     setFocus = true;
          //     console.log('check how may times');
          //     let element = document.querySelectorAll(`[data-index="${index}"][data-name="${prop}"]`)[0].scrollIntoView({block: "start", behavior: "smooth"});

          // }
        }
      }

      let ms = {
        practiceName: currentValue.pName.value.trim(),
        practiceAddress: currentValue.pAddress.value.trim(),
        city: currentValue.city.value,
        state: currentValue.state.value,
        zipCode: currentValue.zipcode.value.trim(),
        phoneNumber: currentValue.pNumber.value.trim(),
        extension: currentValue.extension.value.trim(),
        website: currentValue.link.value.trim(),
        timeZone: currentValue.tZone.value.trim()
          ? currentValue.tZone.value.trim()
          : tempTimeZone,
        practiceHours: practHoursIndex, //[{"day":"Monday","start":"8 AM","end":"8 PM"},{"day":"Tuesday","start":"8 AM","end":"8 PM"},{"day":"Wednesday","start":"8 AM","end":"8 PM"}],
        sortOrder: index + 1,
      }

      practData.push(ms)
      if (Object.keys(validationResult).length > 0) {
        flag = false
        for (const prop in validationResult) {
          temppracticeData[index][prop][prop] = `${validationResult[prop]}`
          if (!flag && !setFocus) {
            setFocus = true
            let element = document
              .querySelectorAll(
                `[data-index="${index}"][data-name="${prop}"]`
              )[0]
              .focus()
          }
        }
        setpracticeData([...temppracticeData])
      }
      // console.log('medical current value ', practiceData);
    })
    if (flag) {
      authContext.setLoader()
      const fData = {
        userId: props.providerDetails?.accountInfo?.id,
        practiceInformation: practData,
        isAcceptingNewPatient:
          appointmetOptions.indexOf('isAcceptingNewPatient') > '-1' ? 1 : 0,
        hasVirtualVisit:
          appointmetOptions.indexOf('hasVirtualVisit') > '-1' ? 1 : 0,
        allowedPatient:
          appointmetOptions.indexOf('allowedPatient') > '-1' ? 1 : 0,
        allowOnline: appointmetOptions.indexOf('allowOnline') > '-1' ? 1 : 0,
        insuranceAccepted: insuranceData,
      }

      const submitData = {
        headers: {
          'Content-Type': 'application/json',
          'access-token': authContext.adminToken,
        },
        url: 'admin/providers/practice-information/update',
        body: JSON.stringify(fData),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()

      if (result.body.code == 200) {
        props.callBack && props.callBack()
        successMsg('success', result.body.message)
      } else {
        successMsg('error', result.body.message)
      }
    }
  }

  const successMsg = (type, message) => {
    setmodalProps({
      type: type,
      msg: message,
    })
    authContext.showResponseModal()
  }
  const setCitiesInUi = async (pInfoData, ind, practData) => {
    let cityResponse = await Global.getCities(pInfoData[ind].state)

    practData[ind].city.cities = cityResponse

    if (pInfoData[ind].cities.name) {
      practData[ind].zipcode.zipCodes =
        cityResponse.citylist[pInfoData[ind].cities.name]
    }
    setpracticeData([...practData])
  }

  const setPracticeInfo = (pInfoData) => {
    let pHours = []
    let practData = []

    pInfoData.map((pInfo, ind) => {
      let openT = []
      pInfo.practiceHours.map((ph, i) => {
        openT.push(ph.day)
        pHours[ind] = { ...pHours[ind], ...{ [ph['day']]: ph } }
      })
      practData.push({
        pName: {
          value: pInfo.practiceName,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: 'pName',
        },
        pAddress: {
          value: pInfo.practiceAddress,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: 'pAddress',
        },
        city: {
          cityName: pInfo.cities.name,
          value: pInfo.city,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: 'city',
          cities: { citylist: [] },
        },
        state: {
          value: pInfo.state,
          validation: ['blank'],
          errorMsg: ['This field is required'],
          errorKey: 'state',
        },
        zipcode: {
          value: pInfo.zipCode,
          validation: ['blank', 'number'],
          errorMsg: ['This field is required', 'Invalid Zip Code'],
          errorKey: 'zipcode',
          zipCodes: [],
        },
        pNumber: {
          value: pInfo.phoneNumber,
          validation: ['blank', 'indianmobile'],
          errorMsg: ['This field is required', 'Invalid Number'],
          errorKey: 'pNumber',
        },
        extension: {
          value: pInfo.extension,
          validation: [],
          errorMsg: ['This field is required'],
          errorKey: 'extension',
        },
        link: {
          value: pInfo.website,
          validation: [],
          errorMsg: ['This field is required', 'Invalid Link'],
          errorKey: 'link',
        },
        tZone: {
          value: pInfo.timeZone ? pInfo.timeZone : 'EST',
          validation: [],
          errorMsg: ['This field is required'],
          errorKey: 'tZone',
        },
        openTime: {
          value: openT ? openT : [],
          validation: ['arrayempty'],
          errorMsg: ['This field is required'],
          errorKey: 'openTime',
        },
      })
    })

    setpracticeHours([...pHours])
    setpracticeData([...practData])

    setTimeout(() => {
      pInfoData.map((pInfo, ind) => {
        setCitiesInUi(pInfoData, ind, practData)
      })
    }, 1000)
  }
  const getState_and_getCity = async (locationData) => {
    let stateResponse = await Global.getStates()
    setStates(stateResponse)

    /*let cityResponse = await Global.getCities(locationData.state);
        setfilterCities(cityResponse);
        
        if(cityResponse.citylist[locationData.cities.name]){
        let currentObject = cityResponse.citylist[locationData.cities.name].find(x => x.zip_code === locationData.zipCode)
        setSelectedCity(cityResponse.citylist[locationData.cities.name][0].id)
        setCityTemp(currentObject.id)
        setZipCodes(cityResponse.citylist[locationData.cities.name])
        }*/
    let header = authContext.token
    let insResponse = await Global.getInsurance(header)
    setinsuranceList(insResponse)
  }
  useEffect(() => {
    if (
      props.providerDetails &&
      props.providerDetails?.practiceInfo &&
      Object.keys(props.providerDetails.practiceInfo).length > 0
    ) {
      let practiceInfo = props.providerDetails?.practiceInfo
      if (
        practiceInfo.insuranceAccepted &&
        practiceInfo.insuranceAccepted.length
      ) {
        setinsuranceData([...practiceInfo.insuranceAccepted])
      }

      if (
        practiceInfo.providerPracticeLocations &&
        practiceInfo.providerPracticeLocations.length
      ) {
        setPracticeInfo(practiceInfo.providerPracticeLocations)
      }
      let appoint = []
      if (practiceInfo.allowOnline) {
        appoint.push('allowOnline')
      }
      if (practiceInfo.hasVirtualVisit) {
        appoint.push('hasVirtualVisit')
      }
      if (practiceInfo.isAcceptingNewPatient) {
        appoint.push('isAcceptingNewPatient')
      }
      if (practiceInfo.allowedPatient) {
        appoint.push('allowedPatient')
      }
      if (practiceInfo.website) {
        appoint.push('website')
      }
      setappointmetOptions([...appoint])
    }
    let locationData =
      props.providerDetails?.practiceInfo?.providerPracticeLocations[0]
    getState_and_getCity(locationData)
  }, [])

  const goBack = () => {
    history.push({
      pathname: '../../provider/management',
    })
  }

  return (
    <Fragment>
      {/* Form start step 3 */}

      {/* <Container className="provider-form-inner" key={'practiceFieldsButton'}> */}
      <Row>
        {authContext.responseModal ? <Msgmodal {...modalProps} /> : <></>}
      </Row>
      <Row>
        <div className="col-12 col-lg-9">
          {practiceData.map((data, index) => (
            //  <PracticeFileds {...data} index={index}  />
            <Row
              className="position-relative provider-steps-wrap provider-form-edit"
              key={data + '-main-' + index}
            >
              {index > 0 ? (
                <DeleteSection name="practiceData" index={index} />
              ) : (
                <></>
              )}
              <div className="col-md-12 mb-3">
                <label className="pro-label">Practice Name</label>
                <input
                  type="text"
                  name="pName"
                  placeholder=" "
                  autoComplete="off"
                  className={
                    data.pName.pName ? 'input-pro  error-border' : 'input-pro'
                  }
                  value={data.pName.value}
                  data-index={index}
                  data-name="pName"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pName.pName}</small>
              </div>

              <div className="col-md-12 mb-3">
                <label className="pro-label">Practice Address</label>
                <input
                  type="text"
                  name="pAddress"
                  placeholder=" "
                  autoComplete="off"
                  className={
                    data.pAddress.pAddress
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  value={data.pAddress.value}
                  data-index={index}
                  data-name="pAddress"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pAddress.pAddress}</small>
              </div>
              <div className="col-md-4 mb-3">
                <label className="pro-label">State</label>
                <div className="myform">
                  <select
                    className={
                      data.state.state ? 'select  error-border' : 'select'
                    }
                    value={data.state.value}
                    data-index={index}
                    data-name="state"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select State</option>
                    {states.map((state, state_index) => (
                      <option
                        key={state.id + '-state-' + index}
                        value={state.id}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                  <small className="error-input">{data.state.state}</small>
                </div>
              </div>
              <div className="col-md-5 mb-3">
                <label className="pro-label">City</label>
                <div className="myform">
                  <select
                    className={
                      data.city.city ? 'select  error-border' : 'select'
                    }
                    value={data.city.value}
                    data-index={index}
                    data-name="city"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select City</option>
                    {data.city.cities
                      ? Object.keys(data.city.cities.citylist).map((key) => (
                          <option
                            key={data.city.cities.citylist[key][0].id + '-city'}
                            data-zipcode={JSON.stringify(
                              data.city.cities.citylist[key]
                            )}
                            value={data.city.cities.citylist[key][0].id}
                          >
                            {key}
                          </option>
                        ))
                      : ''}
                  </select>
                  <small className="error-input">{data.city.city}</small>
                </div>
              </div>

              <div className="col-md-3 mb-3">
                <label className="pro-label">Zip Code</label>
                <div className="myform">
                  <select
                    className={
                      data.zipcode.zipcode ? 'select  error-border' : 'select'
                    }
                    value={data.zipcode.value}
                    data-index={index}
                    data-name="zipcode"
                    onChange={(e) => {
                      modifyPracticeData(e, 'practiceData')
                    }}
                  >
                    <option value="">Select Zip Code</option>
                    {data.zipcode.zipCodes
                      ? data.zipcode.zipCodes.map((zip, zipIndex) => (
                          <option
                            key={zip.id + '-zip-' + zipIndex}
                            data-city={zip.id}
                            value={zip.zip_code}
                          >
                            {zip.zip_code}
                          </option>
                        ))
                      : ''}
                  </select>
                  <small className="error-input">{data.zipcode.zipcode}</small>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="pro-label">
                  Patient Scheduling Phone Number
                </label>
                <input
                  type="text"
                  name="pS-number"
                  placeholder="(###) ###-####"
                  autoComplete="off"
                  className={
                    data.pNumber.pNumber
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  value={data.pNumber.value}
                  data-index={index}
                  data-name="pNumber"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">{data.pNumber.pNumber}</small>
              </div>
              <div className="col-md-6 mb-3">
                <label className="pro-label">Extension</label>
                <input
                  type="text"
                  name="ext"
                  placeholder=""
                  autoComplete="off"
                  className={
                    data.extension.extension
                      ? 'input-pro  error-border'
                      : 'input-pro'
                  }
                  value={data.extension.value}
                  data-index={index}
                  data-name="extension"
                  onChange={(e) => {
                    modifyPracticeData(e, 'practiceData')
                  }}
                />
                <small className="error-input">
                  {data.extension.extension}
                </small>
              </div>

              {/* <div className="col-md-12 mb-3">
                        <label className="pro-label">Time Zone</label>
                        <div className="myform">      
                            <select
                                className={ data.tZone.tZone ? "select  error-border" : "select"} 
                                value={data.tZone.value} 
                                data-index={index} 
                                data-name="tZone" 
                                onChange={ (e) => {modifyPracticeData(e , 'practiceData')}}
                            >
                                <option value="">Time Zone</option>
                                <option value="HST">Hawaii Standard Time (HST)</option>
                                <option value="AKST">Alaska Standard Time (AKST)</option>
                                <option value="PST">Pacific Standard Time (PST)</option>
                                <option value="MST">Mountain Standard Time (MST)</option>
                                <option value="CST">Central Standard Time (CST)</option>
                                <option value="EST">Eastern Standard Time (EST)</option>
                            </select>
                            <small className="error-input">{ data.tZone.tZone }</small>
                        </div>
                    </div>  */}

              <div className="col-md-12 mb-2">
                <label className="pro-label">Practice Office Hours</label>
              </div>
              <div className="col-md-12 mb-1">
                {[
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ].map((d, ind) => (
                  <div className="practice-hour-box" key={d + '-main-' + index}>
                    <span>
                      <div className="cus-check-rev">
                        <input
                          type="checkbox"
                          id={d + '-' + index}
                          name="lang"
                          data-index={index}
                          data-name="openTime"
                          value={d}
                          onChange={(e) => openTimehandle(e)}
                          checked={
                            data.openTime.value.indexOf(d) > '-1' ? true : false
                          }
                        />
                        <label htmlFor={d + '-' + index}>{d}</label>
                      </div>
                    </span>
                    {data.openTime.value.indexOf(d) > '-1' ? (
                      <>
                        <span>
                          <div className="myform">
                            <select
                              className="select"
                              value={practiceHours[index][d]['start']}
                              data-day={d}
                              data-name="start"
                              data-index={index}
                              onChange={(e) => {
                                changeOpenTime(e)
                              }}
                            >
                              {timeList.map((t, ti) => (
                                <option
                                  key={'start-' + index + '-' + t}
                                  value={t}
                                >
                                  {t}
                                </option>
                              ))}
                            </select>
                          </div>
                        </span>
                        <span>to</span>
                        <span>
                          <div className="myform">
                            <select
                              className="select"
                              value={practiceHours[index][d]['end']}
                              data-day={d}
                              data-name="end"
                              data-index={index}
                              onChange={(e) => {
                                changeOpenTime(e)
                              }}
                            >
                              {timeList.map((t, ti) => (
                                <option
                                  key={'end-' + index + '-' + t}
                                  value={t}
                                >
                                  {t}
                                </option>
                              ))}
                            </select>
                          </div>
                        </span>
                        <small className="error-input time-error">
                          {practiceHours[index][d]['error']}
                        </small>
                      </>
                    ) : (
                      <>
                        <span>Not Working Day</span>{' '}
                      </>
                    )}
                  </div>
                ))}
                <small className="error-input time-error">
                  {data.openTime.openTime}
                </small>
              </div>
            </Row>
          ))}
          <Row className="provider-steps-wrap">
            <div className="col-md-12 mb-4">
              <div className="form-add-link">
                <a
                  onClick={(e) => {
                    addPracticeFileds(e)
                  }}
                  href="/#"
                >
                  + Add Another Practice Location
                </a>
              </div>
            </div>
          </Row>
          <Row className="provider-steps-wrap">
            <div className="col-md-12 mb-4">
              <div className="medical-info">
                <span>Appointment Request Options</span>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="pro-label">Link to Website</label>
              <input
                type="text"
                data-index="0"
                name="link"
                data-name="link"
                autoComplete="off"
                className="input-pro input-blue"
                onChange={(e) => {
                  modifyPracticeData(e, 'practiceData')
                }}
                defaultValue={practiceData[0].link.value}
                // className={ data.link.link ? "input-pro input-blue error-border" : "input-pro input-blue"}
                // value={data.link.value}
                // data-index={index}
                // data-name="link"
                // onChange={ (e) => {modifyPracticeData(e , 'practiceData')}}
              />
              <small className="error-input">{practiceData[0].link.link}</small>
            </div>

            <div className="col-md-12 mb-4">
              <div className="appoint-req-box">
                <span className="info-hg">
                  Are You Accepting New Patients?{' '}
                  <div className="info-icon"></div>
                  {/* <div className="info-callout"><strong>Body Mass Index (BMI) -</strong> </div> */}
                </span>
                <span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="switch-btn1"
                      data-name="isAcceptingNewPatient"
                      value={1}
                      onChange={(e) => changeAppointmetOptions(e)}
                      checked={
                        appointmetOptions.indexOf('isAcceptingNewPatient') >
                        '-1'
                          ? true
                          : false
                      }
                    />
                    <div className="slider round"></div>
                  </label>
                </span>
              </div>
              <div className="appoint-req-box">
                <span>
                  Virtual Visit Capability? <div className="info-icon"></div>
                </span>
                <span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="switch-btn2"
                      data-name="hasVirtualVisit"
                      value={1}
                      onChange={(e) => changeAppointmetOptions(e)}
                      checked={
                        appointmetOptions.indexOf('hasVirtualVisit') > '-1'
                          ? true
                          : false
                      }
                    />
                    <div className="slider round"></div>
                  </label>
                </span>
              </div>
              <div className="appoint-req-box">
                <span>
                  Allow Patients to Send You Callback Requests{' '}
                  <div className="info-icon"></div>
                </span>
                <span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="switch-btn3"
                      data-name="allowedPatient"
                      value={1}
                      onChange={(e) => changeAppointmetOptions(e)}
                      checked={
                        appointmetOptions.indexOf('allowedPatient') > '-1'
                          ? true
                          : false
                      }
                    />
                    <div className="slider round"></div>
                  </label>
                </span>
              </div>
              <div className="appoint-req-box">
                <span>
                  {' '}
                  Allow Online Patient Scheduling?{' '}
                  <div className="info-icon"></div>
                </span>
                <span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="switch-btn4"
                      data-name="allowOnline"
                      value={1}
                      onChange={(e) => changeAppointmetOptions(e)}
                      checked={
                        appointmetOptions.indexOf('allowOnline') > '-1'
                          ? true
                          : false
                      }
                    />
                    <div className="slider round"></div>
                  </label>
                </span>
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <div className="medical-info">
                <span>Major Insurances Accepted</span>
              </div>
            </div>
            <div className="col-md-12 mb-2">
              <div className="language-wrap-step2 language-wrap-step3">
                <div className="cus-check-rev">
                  <input
                    type="checkbox"
                    id="sAll"
                    name="lang"
                    onChange={(e) => selectAll(e)}
                    checked={selectall ? true : false}
                  />
                  <label htmlFor="sAll">Select All</label>
                </div>
                {insuranceList.map((d, ind) => (
                  <div className="cus-check-rev" key={d.name + 'insList' + ind}>
                    <input
                      type="checkbox"
                      id={d.name}
                      name="insurance"
                      data-name="insuranceData"
                      value={d.name}
                      onChange={(e) => openTimehandle(e, 'insuranceData')}
                      checked={
                        insuranceData.indexOf(d.name) > '-1' ? true : false
                      }
                    />
                    <label htmlFor={d.name}>{d.name}</label>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="col-md-12 mb-5" >
                            <label className="pro-label">Add Custom Insurance</label>
                            <div className="add-custom-ins">
                                <div>
                                    <input type="text" name="addCustomIns" placeholder="Custom Insurance Name" autoComplete="off"
                                        className="input-pro" className={custIns.custIns.custIns ? "input-pro error-border" : "input-pro"}
                                        value={custIns.custIns.value}
                                        data-name="addCustomIns"
                                        onChange={(e) => {
                                            setcustIns({
                                                custIns: {
                                                    value: e.currentTarget.value,
                                                    validation: ['blank'],
                                                    errorMsg: ['This field is required'],
                                                    errorKey: 'custIns',
                                                    custIns: false
                                                },
                                            })
                                        }}
                                    />
                                    <small className="error-input">{custIns.custIns.custIns}</small>
                                </div>
                                <div>
                                    <input type="button" className="next-btn-pro" onClick={(e) => { addCustomInsurance(e) }} value="Add" />
                                </div>
                            </div>
                        </div>
 */}

            {/* Language head */}
            <div className="col-md-12 text-center mb-3">
              <div className="next-prev-btn-pro">
                <input
                  type="button"
                  className="prev-btn-pro"
                  data-prev-page="medical-specialty"
                  onClick={goBack}
                  value="Cancel"
                />
                <input
                  type="button"
                  className="next-btn-pro"
                  onClick={(e) => {
                    formSubmit(e)
                  }}
                  value="Save"
                />
              </div>
            </div>
          </Row>
        </div>
      </Row>
      {/* </Container> */}
    </Fragment>
  )
}

export default PracticeInformation
