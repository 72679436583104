import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useEffect, useRef } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import Freeprofile from '../../components/free-profile-component/free-profile-component'
import Youtubevideo from '../../components/youtube-video/youtube-video'
import Accordion from '../../global/accordion/accordion'
import Footer from '../../global/footer/footer'
import HomeHeader from '../../global/header/header'
import { PatientLinks, ProviderLinks } from '../../linkFile'

const FreeProfile = (props) => {
  const history = useHistory()
  const redirectToCreatAccount = (e) => {
    e && e.preventDefault()
    history.push({
      pathname: ProviderLinks.register_step1,
    })
  }

  const commentSection = useRef()
  const howWorks = useRef()
  const gotoCommentSection = (section) =>
    window.scrollTo({
      top: section,
      behavior: 'smooth',
    })

  useEffect(() => {
    if (
      props.location &&
      props.location.query &&
      props.location.query === 'howWorks'
    ) {
      gotoCommentSection(howWorks.current.offsetTop)
    } else if (
      props.location &&
      props.location.query &&
      props.location.query === 'commentSection'
    ) {
      gotoCommentSection(commentSection.current.offsetTop)
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          DocScheduler | Create your Doctor Profile of your clinic in
          DocScheduler easliy anytime and free{' '}
        </title>
        <meta
          name="description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile "
        />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/provider/free-profile"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/provider/free-profile`}
        />
        <meta
          name="twitter:card"
          content="Create your Doctor Profile of your clinic in DocScheduler easliy anytime and free"
        />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta
          property="og:title"
          content="DocScheduler | Create your Doctor Profile of your clinic in DocScheduler easliy anytime and free"
        />
        <meta
          name="twitter:title"
          content="DocScheduler | Create your Doctor Profile of your clinic in DocScheduler easliy anytime and free"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile"
        />
        <meta
          name="twitter:description"
          content="Connect with your patients, help them find you easily, and build   clinic&#x27;s brand with DocScheduler Profile"
        />
      </Helmet>
      <Container fluid className="header px-0">
        <HomeHeader />
      </Container>
      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
              <div className="col-12">
                <h2>Health Care Providers - FAQs &amp; 60 Day Free Trial</h2>
              </div>
              <div className="col-12">
                <Freeprofile redirectToCreatAccount={redirectToCreatAccount} />
              </div>
            </Row>
          </Container>
          <div className="video-howorks" ref={howWorks}>
            <Youtubevideo redirectToCreatAccount={redirectToCreatAccount} />
          </div>
          <Container>
            <Row ref={commentSection}>
              <div className="col-12">
                <Accordion />
              </div>
              <div className="col-sm-8 col-md-6 mb-5 submission next-btn-pro">
                <a
                  href="/#"
                  className=""
                  onClick={(e) => redirectToCreatAccount(e)}
                >
                  Try 60 Days Free
                </a>
              </div>
              <div className="col-12 text-center">
                <div className="col-12text-center questions">
                  Have additional questions?
                </div>
                <a className="col-sm-8 col-md-6 text-center question-contactus">
                  <h2>
                    <Link to={PatientLinks.contactus}>
                      <img
                        src="/assets/images/blue-mail-icon.png"
                        className="mail-icon-blue"
                      />
                      Contact Us
                    </Link>
                  </h2>
                </a>
              </div>
            </Row>
          </Container>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default FreeProfile
