import 'bootstrap/dist/css/bootstrap.css'
import React, { Fragment, useState } from 'react'
// Component Improt From Here
import { Helmet } from 'react-helmet'
import ProviderLogin from '../../components/provider-login/provider-login'
import Twostep from '../../components/provider-login/twostep'
import Footer from '../../global/footer/footer'
import HeaderMain from '../../global/header/header-main'
import Custommodal from '../../global/modal/modal'

const ProviderRegister = () => {
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const modalList = ['login', 'twostep']

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | Account Login</title>
        <meta
          name="description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <link
          rel="canonical"
          href="https://www.docscheduler.com/provider/login"
        />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/provider/login`}
        />
        <meta name="twitter:card" content="Account Login" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content="DocScheduler | Account Login" />
        <meta name="twitter:title" content="DocScheduler | Account Login" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
        <meta
          name="twitter:description"
          content="Say Hello to top doctors via video consultation, get digital prescriptions, book doctor appointments, find doctors near you, get health packages, ask a free health question to doctors"
        />
      </Helmet>
      <HeaderMain />
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'login' ? (
              <ProviderLogin
                ltype={'patient'}
                settabvisibleComponent={settabvisibleComponent}
              />
            ) : tabvisibleComponent === 'twostep' ? (
              <Twostep ltype={'patient'} />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
      <ProviderLogin
        ltype={'provider'}
        settabvisibleComponent={settabvisibleComponent}
      />
      <Footer />
    </Fragment>
  )
}

export default ProviderRegister
