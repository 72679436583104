import React, { useContext, useState } from 'react'
import '../register/TeamRegister.scss'

import TwoStep from '../../components/provider-login/twostep'
import NetworkLayer from '../../reactLayer/Network/Network'
import AuthContext from '../../reactLayer/auth/AuthContext'

const TeamLogin = () => {
  const authContext = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [shouldVerify, setShouldVerify] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Perform form validation
    const validationErrors = {}

    if (email.trim() === '') {
      validationErrors.lastName = 'E-mail is required'
    }

    if (password.trim() === '') {
      validationErrors.password = 'Password is required'
    }

    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      authContext.setLoader()

      let data = {
        email: email,
        password: password,
      }

      const submitData = {
        url: 'providers/login',
        body: JSON.stringify(data),
      }
      let result = await NetworkLayer.postData(submitData)
      authContext.unsetLoader()
      let user = result.body.result
      user.token = result.body.result.token
      authContext.setToken(user.token)
      if (
        result.body.message ===
        'A verification code was resent to your email address'
      ) {
        setShouldVerify(true)
      }
      // history.push({ pathname: ProviderLinks.dashboard })
    }
  }

  return (
    <>
      <div className="form-wrapper">
        {!shouldVerify ? (
          <form onSubmit={handleSubmit}>
            <div>
              <label className="reg-label" htmlFor="email">
                Email
              </label>
              <input
                className="reg-input"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>

            <div>
              <label className="reg-label" htmlFor="password">
                Password
              </label>
              <input
                className="reg-input"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </div>

            <button className="reg-button" type="submit">
              Sign In
            </button>
          </form>
        ) : (
          <TwoStep ltype={'team-member'} />
        )}
      </div>
    </>
  )
}

export default TeamLogin
