import React, { Fragment, useContext, useState, useEffect } from 'react'
import AuthContext from '../../reactLayer/auth/AuthContext'
import Custommodal from '../../global/modal/modal'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Row } from 'react-bootstrap'

import HomeHeader from '../../global/header/header'
import Footer from '../../global/footer/footer'
import DoctorReviewModal from '../../components/doctor-review-modal/doctor-review-modal'
import NoResultsModal from '../../components/search-results/no-result-modal'
import NetworkLayer from '../../reactLayer/Network/Network'
import { Helmet } from 'react-helmet'

const AboutUs = () => {
  const authContext = useContext(AuthContext)
  const [tabvisibleComponent, settabvisibleComponent] = useState('')
  const [pageTitle, setPageTitle] = useState('Doc Scheduler')
  const [metaDesc, setMetaDesc] = useState('')
  const modalList = ['noResult']
  const openProfileTabModal = (modalName) => {
    settabvisibleComponent(modalName)
    authContext.showModal()
  }
  const [data, setData] = useState([])

  const getDetails = async (id) => {
    authContext.setLoader()

    let formData = {
      id: 5,
    }
    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
      },
      url: 'content-management/get',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    setPageTitle(result.body.result.meta_title)
    setMetaDesc(result.body.result.meta_description)
    setData(result.body?.result)
    authContext.unsetLoader()
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DocScheduler | {pageTitle}</title>
        <meta
          name="description"
          content="DocScheduler &#x27;s mission and culture"
        />
        <link rel="canonical" href="https://www.docscheduler.com/aboutus" />
        <meta property="og:site_name" content="DocScheduler" />
        <meta property="og:site" content="www.docscheduler.com" />
        <meta
          property="og:url"
          content={`https://www.docscheduler.com/aboutus`}
        />
        <meta name="twitter:card" content="Dashboard" />
        <meta name="twitter:site" content="@DocScheduler" />
        <meta property="og:title" content={`DocScheduler | ${pageTitle}`} />
        <meta name="twitter:title" content={`DocScheduler | ${pageTitle}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          name="twitter:image"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.docscheduler.com/assets/images/logo-icon.svg"
        />
        <meta
          property="og:description"
          content="DocScheduler &#x27;s mission and culture"
        />
        <meta
          name="twitter:description"
          content="DocScheduler &#x27;s mission and culture"
        />
      </Helmet>
      {modalList.indexOf(tabvisibleComponent) > '-1' ? (
        <Custommodal
          unsetComponent={settabvisibleComponent}
          componentName={
            tabvisibleComponent === 'noResult' ? <NoResultsModal /> : <></>
          }
        />
      ) : (
        <></>
      )}
      {/* <DoctorReviewModal/> */}
      {/* header */}
      <Container fluid className="header px-0">
        <HomeHeader />
      </Container>

      <Container fluid className="free-profile">
        <Row>
          <Container>
            <Row>
              <div className="col-12">
                {/* <h2 onClick={() => {openProfileTabModal("noResult")}}>DocScheduler's Mission</h2> */}
                <h2>{data.page_title}</h2>
              </div>

              <div
                className="aboutus-content"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              ></div>
            </Row>
          </Container>
        </Row>
      </Container>

      <Footer />
    </Fragment>
  )
}

export default AboutUs
