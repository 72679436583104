import type { ComponentWithAs, IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'

function createIcon(svg: React.FC): ComponentWithAs<'svg', IconProps> {
  // eslint-disable-next-line react/display-name
  return (props) => <Icon {...(props as Record<string, unknown>)} as={svg} />
}

export const UserCheckIcon = createIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M16 18L18 20L22 16M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export const VirtualRecorderIcon = createIcon((props) => (
  // <svg
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   {...props}
  // >
  //   <path
  //     d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z"
  //     stroke="currentColor"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
  //     stroke="currentColor"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export const UserCheckIcon2 = createIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M16 18L18 20L22 16M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export const CalendarIcon = createIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export const SliderIcon = createIcon((props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m3 8h12m0 0c0 1.65686 1.3431 3 3 3s3-1.34315 3-3-1.3431-3-3-3-3 1.34315-3 3zm-6 8h12m-12 0c0 1.6569-1.34315 3-3 3s-3-1.3431-3-3 1.34315-3 3-3 3 1.3431 3 3z"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
))

export const ChevronLeftIcon = createIcon((props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15 6-6 6 6 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
))

export const LogoIcon = createIcon((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.961"
    height="24.1"
    viewBox="0 0 23.961 24.1"
  >
    <g transform="translate(-212.32 -232.6)">
      <path
        d="M226.9,256.7h-5.3a.645.645,0,0,1-.6-.6V233.2a.645.645,0,0,1,.6-.6h5.3a.645.645,0,0,1,.6.6v22.9A.645.645,0,0,1,226.9,256.7Z"
        fill="#077db4"
      />
      <path
        d="M214.8,252.6l-2.4-4.7a.553.553,0,0,1,.3-.8L233,236.5a.553.553,0,0,1,.8.3l2.4,4.7a.553.553,0,0,1-.3.8l-20.3,10.6A.63.63,0,0,1,214.8,252.6Z"
        fill="#077db4"
      />
      <path
        d="M212.7,240.8l2.6-4.6a.54.54,0,0,1,.8-.2l19.8,11.4a.54.54,0,0,1,.2.8l-2.6,4.6a.54.54,0,0,1-.8.2l-19.8-11.4A.524.524,0,0,1,212.7,240.8Z"
        fill="#077db4"
      />
      <path
        d="M224.7,250.3l-1.8-8.4-1.3,3.6h-2.4v-.7h1.9l2-5.4,1.7,8,1.6-5.6,1.1,3h1.9v.7l-2.4-.1-.5-1.4Z"
        transform="translate(0 3)"
        fill="#fff"
      />
    </g>
  </svg>
))

export const ChipInputIcon = createIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 21H13M3 21L8.16787 19.0124C8.49841 18.8852 8.66368 18.8217 8.81831 18.7387C8.95566 18.6649 9.08659 18.5798 9.20974 18.4843C9.34839 18.3767 9.4736 18.2515 9.72403 18.001L20.2285 7.49657C21.2572 6.46792 21.2572 4.80014 20.2285 3.77149C19.1999 2.74284 17.5321 2.74284 16.5034 3.77149L5.99895 14.276C5.74853 14.5264 5.62331 14.6516 5.51572 14.7902C5.42016 14.9134 5.33507 15.0443 5.26134 15.1817C5.17834 15.3363 5.11477 15.5016 4.98764 15.8321L3 21ZM3 21L4.91667 16.0167C5.05382 15.6601 5.1224 15.4818 5.24002 15.4002C5.34281 15.3288 5.47001 15.3018 5.59293 15.3253C5.73359 15.3521 5.86867 15.4872 6.13883 15.7574L8.24265 17.8612C8.51281 18.1314 8.64789 18.2664 8.67475 18.4071C8.69823 18.53 8.67123 18.6572 8.59986 18.76C8.51819 18.8776 8.33989 18.9462 7.98329 19.0834L3 21Z"
      stroke="#077DB4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export const ChipInputXIcon = createIcon((props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 7L7 17M7 7L17 17"
      stroke="#077DB4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
