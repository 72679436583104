export const PatientFavorite = ({ size = 'large' }) => {
  const isLarge = size === 'large'

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        backgroundColor: '#d5e1ed',
        borderRadius: '8px',
        border: '1px solid transparent',
        padding: isLarge ? '8px' : '4px',
      }}
    >
      <img
        src="/assets/images/logo-icon.svg"
        className="logo-icon"
        style={{
          width: isLarge ? '22px' : '18px',
        }}
      />
      <span
        style={{
          color: '#077db4',
          fontSize: isLarge ? '16px' : '12px',
          paddingTop: '0',
        }}
      >
        Patient Favorite
      </span>
    </div>
  )
}

export const isPatientFavorite = (rating, reviews) => {
  return rating > 4.8 && reviews > 25
}
