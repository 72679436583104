import NetworkLayer from '../../reactLayer/Network/Network'
import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../reactLayer/auth/AuthContext'

export const QuestionForm = ({ currentQuestion, type, fetchList }) => {
  const authContext = useContext(AuthContext)

  const [question, setQuestion] = useState({ type: type })

  useEffect(() => {
    setQuestion(currentQuestion || { description: '', type: type })
  }, [currentQuestion])

  const [modalProps, setModalProps] = useState({
    type: '',
    msg: '',
    btnname: '',
    redirection: '',
  })

  const deleteQuestion = async (id) => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/qa/questions',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.deleteData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setModalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      fetchList()
    } else {
      setModalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const saveQuestion = async () => {
    authContext.setLoader()

    // FORM DATA
    const formData = {
      id: question.id,
      type: question.type,
      description: question.description,
    }

    // STRUCTURE LOGIN REQUEST DATA
    const submitData = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': authContext.adminToken,
      },
      url: 'admin/qa/questions',
      body: JSON.stringify(formData),
    }

    // SEND REQUEST
    let result = await NetworkLayer.postData(submitData)
    authContext.unsetLoader()

    if (result.body.status) {
      setModalProps({
        type: 'success',
        msg: result.body.message,
      })
      authContext.showResponseModal()
      fetchList()
    } else {
      setModalProps({
        type: 'error',
        msg: result.body.message,
      })
      authContext.showResponseModal()
    }
  }

  const onChange = (e, type) => {
    const value = e?.currentTarget?.value
    setQuestion((prevState) => {
      return {
        ...prevState,
        ...{ [type]: value },
      }
    })
  }

  const title = currentQuestion
    ? `Edit ${type} question`
    : `New ${type} question`

  return (
    <div>
      <div>{title}</div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
        className="meta-descr-box mb-3"
      >
        <div style={{ width: '40%' }}>
          <label htmlFor="title" className="pro-label">
            Question
          </label>
          <input
            type="text"
            name="title"
            className="input-pro"
            value={question?.description}
            onChange={(e) => {
              onChange(e, 'description')
            }}
          />
        </div>
        <div style={{ width: '20%' }}>
          <label htmlFor="actionType" className="pro-label">
            Question Type
          </label>
          <select
            className="input-pro"
            onChange={(e) => {
              onChange(e, 'type')
            }}
            disabled={type}
          >
            <option
              value="Provider"
              selected={question?.type == 'provider' ? 'Checked' : ''}
            >
              Provider
            </option>
            <option
              value="Patient"
              selected={question?.type == 'patient' ? 'Checked' : ''}
            >
              Patient
            </option>
            <option
              value="Admin"
              selected={question?.type == 'admin' ? 'Checked' : ''}
            >
              Admin
            </option>
          </select>
        </div>
      </div>
      <div className="content-style answer">
        <span
          style={{
            color: '#077db4',
            cursor: 'pointer',
          }}
          onClick={saveQuestion}
        >
          Save Change
        </span>
        {currentQuestion && (
          <>
            <span>&nbsp;/&nbsp;</span>
            <span
              style={{
                color: '#077db4',
                cursor: 'pointer',
              }}
              onClick={() => deleteQuestion(question.id)}
            >
              Delete Question
            </span>
          </>
        )}
      </div>
    </div>
  )
}
