import { Flex } from '@chakra-ui/react'
import { Stars } from './Stars'
import { Typography } from '../../style/typography'

interface Props {
  reviewStars: number
  totalReviewAvg: number
  totalReviewCount: number
}

export const DoctorReview: React.FC<Props> = ({
  reviewStars,
  totalReviewAvg,
  totalReviewCount,
}) => {
  const average = String(totalReviewAvg)

  return (
    <Flex direction="column" gap={2}>
      <Stars
        rating={reviewStars ? parseFloat(String(reviewStars)).toFixed(1) : 0}
      />
      <Typography fontSize="14px" fontWeight="bold">
        ({totalReviewAvg ? parseFloat(average).toFixed(1) : 0}){' '}
        {totalReviewCount ? totalReviewCount : 0} Reviews
      </Typography>
    </Flex>
  )
}
